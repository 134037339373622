import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/posrapor';
import { DataTable, Filter, Progress, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from "lib";
import { formatDate, formatCurrency, cardHarmony, businessCardTypes } from 'helpers';
import i18n from 'plugins/i18n';
import { defaultFilters } from 'lib/constants';
import ReportLineDetail from './Detail';
import moment from 'moment';
import _ from 'lodash';

class ReportLinesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.posrapor.ReportLineDetails(),
    };
    this.id = props.match.params.id;
  };

  componentDidMount() {
    this.getReportLineDetails();
    utils.posrapor.getBanks();
    utils.posrapor.getCurrencies();
    utils.common.checkAccessToUpdateOrDelete('BankDailyReportLineDetail');
    utils.posrapor.getCreditCardBanks();
    utils.posrapor.getCreditCardTypes();
    utils.posrapor.getCreditCardPrograms();
    utils.posrapor.getCreditCardOrganizationTypes();
    utils.posrapor.getCompanyNames();
  };

  getReportLineDetails = () => {
    let _filter = this.props.currentFilter['posrapor/report-line-details'];
    let filter = _.cloneDeep(this.state.filter);

    if (this.id) {
      filter.filter = { ...filter.filter, BankDailyReportLineId: { type: 'guid', value: this.id } }
    }
    if (!(_filter && _filter.fields && _filter.fields[0] && _filter.fields[0].length)) {
      const oneWeekBefore = moment().add(-14, 'day').startOf('day');
      this.filterRef.filterChange(oneWeekBefore.format('YYYY-MM-DDT00:00:00Z'), 0);
      filter.filter.and
        ? filter.filter.and.push({ 'BankDailyReportLine/TransactionDate': { ge: oneWeekBefore._d } })
        : filter.filter.and = [{ 'BankDailyReportLine/TransactionDate': { ge: oneWeekBefore._d } }]
    }

    this.props.getReportLineDetails(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getReportLineDetails);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getReportLineDetails);
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getReportLineDetails);
  };

  getCreditCardBanksName = (row) => this.props.creditCardBanks.data.find(x => x.id === row.BankDailyReportLine.CreditCardBankId)?.name || i18n.t('lbl.unknown');;

  getCreditCardTypeName = (row) => this.props.creditCardTypes.data.find(x => x.id === row.BankDailyReportLine.CreditCardTypeId)?.name || i18n.t('lbl.unknown');;

  getCreditCardProgramsName = (row) => this.props.creditCardPrograms.data.find(x => x.id === row.BankDailyReportLine.CreditCardProgramId)?.name || i18n.t('lbl.unknown');;

  getCreditCardOrganizationTypesName = (row) => this.props.creditCardOrganizationTypes.data.find(x => x.id === row.BankDailyReportLine.CreditCardOrganizationTypeId)?.name || i18n.t('lbl.unknown');;

  getBusinessCardTypesName = (row) => i18n.t(`lbl.${businessCardTypes.find(x => x.value === row.BankDailyReportLine.IsBusinessCardId)?.name || 'unknown'}`);

  getIsOnUsNotOnUs = (row) => i18n.t(`lbl.${cardHarmony.find(x => x.id === row.BankDailyReportLine.OnUs)?.name || 'unknown'}`);


  render() {
    const { reportLineDetails, banks, creditCardBanks, creditCardTypes, creditCardPrograms, creditCardOrganizationTypes, currencies, companyNames } = this.props;
    const { filter } = this.state;

    const filters = [
      { label: i18n.t('posrapor.transactionDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['BankDailyReportLine/TransactionDate'], uniqueKey: '#TransactionDateStart', disabled: '3', allowClear: false },
      { label: i18n.t('posrapor.transactionDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['BankDailyReportLine/TransactionDate'], uniqueKey: '#TransactionDateEnd', disabled: '2' },
      { label: i18n.t('posrapor.posBank'), type: 'select', options: banks.data, value: 'eftCode', name: (bankData) => utils.posrapor.getBankName({ bankData }), keys: ['BankDailyReportLine/Pos/PosraporAccount/Bank/EftCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.cardBanks'), type: 'select', options: creditCardBanks.data, value: 'id', name: 'name', keys: ['BankDailyReportLine/CreditCardBankId'], showAll: true, multiple: true },
      { label: i18n.t('posrapor.cardType'), type: 'select', options: creditCardTypes.data, value: 'id', name: 'name', keys: ['BankDailyReportLine/CreditCardTypeId'], multiple: true },
      { label: i18n.t('posrapor.cardProgram'), type: 'select', options: creditCardPrograms.data, value: 'id', name: 'name', keys: ['BankDailyReportLine/CreditCardProgramId'], multiple: true },
      { label: i18n.t('posrapor.cardKind'), type: 'select', options: creditCardOrganizationTypes.data, value: 'id', name: 'name', keys: ['BankDailyReportLine/CreditCardOrganizationTypeId'], multiple: true },
      { label: i18n.t('posrapor.isOnUs'), type: 'select', options: cardHarmony, value: 'id', lang: 'lbl', name: 'name', keys: ['BankDailyReportLine/OnUs'], showAll: true, uniqueKey: '#cardHarmony', multiple: true },
      { label: i18n.t('lbl.businessCard'), type: 'select', options: businessCardTypes, lang: 'lbl', value: 'value', name: 'name', keys: ['BankDailyReportLine/IsBusinessCardId'], showAll: true, uniqueKey: '#IsBusinessCardId', multiple: true },
      { label: i18n.t('lbl.installmentNumber', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['InstallmentNumber'], uniqueKey: '#InstallmentNumberMin' },
      { label: i18n.t('lbl.installmentNumber', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['InstallmentNumber'], uniqueKey: '#InstallmentNumberMax' },
      { label: i18n.t('lbl.installmentCount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['BankDailyReportLine/InstallmentCount'], uniqueKey: '#InstallmentCountMin' },
      { label: i18n.t('lbl.installmentCount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['BankDailyReportLine/InstallmentCount'], uniqueKey: '#InstallmentCountMax' },
      { label: i18n.t('lbl.blockedDays', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['BlockedDays'], uniqueKey: '#BlockedDays' },
      { label: i18n.t('lbl.blockedDays', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['BlockedDays'], uniqueKey: '#BlockedDays' },
      { label: i18n.t('lbl.tenantInstallmentCount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['BankDailyReportLine/TenantInstallmentCount'], uniqueKey: '#TenantInstallmentCountMin' },
      { label: i18n.t('lbl.tenantInstallmentCount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['BankDailyReportLine/TenantInstallmentCount'], uniqueKey: '#TenantInstallmentCountMax' },
      { label: i18n.t('lbl.commitDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['BankDailyReportLine/CommitDate'], uniqueKey: '#CommitDate' },
      { label: i18n.t('lbl.commitDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['BankDailyReportLine/CommitDate'], uniqueKey: '#CommitDate' },
      { label: i18n.t('lbl.valorDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['ValorDate'], uniqueKey: '#ValorDateStart', disabled: '5' },
      { label: i18n.t('lbl.valorDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['ValorDate'], uniqueKey: '#ValorDateEnd', disabled: '4' },
      { label: i18n.t('posrapor.searchByNumbers'), type: 'input', keys: ['BankDailyReportLine/Pos/MerchantId', 'BankDailyReportLine/Pos/TerminalId', 'BankDailyReportLine/TransactionId', 'BankDailyReportLine/ProvisionCode'], contains: true, uniqueKey: '#search' },
      { label: i18n.t('posrapor.netAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['NetAmount'], uniqueKey: '#NetAmountMin' },
      { label: i18n.t('posrapor.netAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['NetAmount'], uniqueKey: '#NetAmountMax' },
      { label: i18n.t('posrapor.pureAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['PureAmount'], uniqueKey: '#PureAmountMin' },
      { label: i18n.t('posrapor.pureAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['PureAmount'], uniqueKey: '#PureAmountMax' },
      { label: i18n.t('posrapor.commisionAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['BankDailyReportLine/ServiceCommisionAmount'], uniqueKey: '#CommissionAmountMin' },
      { label: i18n.t('posrapor.commisionAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['BankDailyReportLine/ServiceCommisionAmount'], uniqueKey: '#CommissionAmountMax' },
      { label: i18n.t('lbl.currencyCode'), keys: ['BankDailyReportLine/Pos/Currencies/Code'], type: 'select', options: currencies.data, name: "currencyName", value: "currencyCode", multiple: true },
      { label: i18n.t('posrapor.merchantName'), keys: ['BankDailyReportLine/Pos/CompanyName'], type: 'select', options: companyNames.data, name: "companyName", value: "companyName", search: true, multiple: true },
    ];
    const columns = [
      { label: i18n.t('posrapor.posBank'), key: 'BankDailyReportLine/Pos/PosraporAccount/Bank/Name', render: (data) => utils.posrapor.getBankName({ data, dataField: 'BankDailyReportLine.Pos.PosRaporAccount.Bank.EftCode' }), checkField: false },
      { label: i18n.t('posrapor.cardBank'), key: 'BankDailyReportLine/CreditCardBankId', render: this.getCreditCardBanksName, checkField: false },
      { label: i18n.t('posrapor.cardType'), key: 'BankDailyReportLine/CreditCardTypeId', render: this.getCreditCardTypeName, checkField: false },
      { label: i18n.t('posrapor.cardProgram'), key: 'BankDailyReportLine/CreditCardProgramId', render: this.getCreditCardProgramsName, checkField: false },
      { label: i18n.t('posrapor.cardKind'), key: 'BankDailyReportLine/CreditCardOrganizationTypeId', render: this.getCreditCardOrganizationTypesName, checkField: false },
      { label: i18n.t('posrapor.isOnUs'), key: 'BankDailyReportLine/OnUs', render: this.getIsOnUsNotOnUs, checkField: false },
      { label: i18n.t('lbl.businessCard'), key: 'BankDailyReportLine/IsBusinessCardId', render: this.getBusinessCardTypesName, checkField: false },
      { label: i18n.t('posrapor.merchantName'), key: 'BankDailyReportLine/Pos/CompanyName', checkField: false },
      { label: i18n.t('posrapor.merchant'), key: 'BankDailyReportLine/Pos/MerchantId', checkField: false },
      { label: i18n.t('posrapor.terminalNo'), key: 'BankDailyReportLine/Pos/TerminalId', checkField: false },
      { label: i18n.t('posrapor.transactionNo'), key: 'BankDailyReportLine/TransactionId', checkField: false },
      { label: i18n.t('posrapor.provisionCode'), key: 'BankDailyReportLine/ProvisionCode', checkField: false },
      { label: i18n.t('posrapor.cardNumber'), key: 'BankDailyReportLine/CardNumber', checkField: false },
      { label: i18n.t('posrapor.transactionDate'), key: 'BankDailyReportLine/TransactionDate', render: ({ BankDailyReportLine }) => formatDate(BankDailyReportLine.TransactionDate, null, null, null, false), checkField: false },
      { label: i18n.t('posrapor.transactionTime'), key: 'BankDailyReportLine/TransactionTime', render: ({ BankDailyReportLine }) => moment(BankDailyReportLine.TransactionDate).format('HH:mm'), checkField: false, sort: false },
      { label: i18n.t('lbl.valorDate'), key: 'ValorDate', render: ({ ValorDate }) => formatDate(ValorDate, null, null, null, false) },
      { label: i18n.t('lbl.blockedDays'), key: 'BlockedDays' },
      { label: i18n.t('posrapor.pureAmount'), key: 'BankDailyReportLine/PureAmount', render: ({ BankDailyReportLine }) => formatCurrency({ data: BankDailyReportLine.PureAmount, currencyCode: BankDailyReportLine.Pos.Currencies.Code, withSign: false }), checkField: false },
      { label: i18n.t('posrapor.netAmount'), key: 'BankDailyReportLine/NetAmount', render: ({ BankDailyReportLine }) => formatCurrency({ data: BankDailyReportLine.NetAmount, currencyCode: BankDailyReportLine.Pos.Currencies.Code, withSign: false }), checkField: false },
      { label: i18n.t('lbl.installmentNumber'), key: 'InstallmentNumber', render: ({ InstallmentNumber, BankDailyReportLine }) => <Progress used={InstallmentNumber} limit={BankDailyReportLine.TenantInstallmentCount} positive /> },
      { label: i18n.t('lbl.installmentAmount'), key: 'PureAmount', render: ({ PureAmount, BankDailyReportLine }) => formatCurrency({ data: PureAmount, currencyCode: BankDailyReportLine.Pos.Currencies.Code, withSign: false }) },
      { label: i18n.t('lbl.installmentNetAmount'), key: 'NetAmount', render: ({ NetAmount, BankDailyReportLine }) => formatCurrency({ data: NetAmount, currencyCode: BankDailyReportLine.Pos.Currencies.Code, withSign: false }) },
      { label: i18n.t('lbl.installmentCount'), key: 'BankDailyReportLine/InstallmentCount', checkField: false },
      { label: i18n.t('lbl.tenantInstallmentCount'), key: 'BankDailyReportLine/TenantInstallmentCount', checkField: false },
      { label: i18n.t('lbl.commissionAmount'), key: 'CommisionAmount', render: ({ CommisionAmount, BankDailyReportLine }) => formatCurrency({ data: CommisionAmount, currencyCode: BankDailyReportLine.Pos.Currencies.Code, withSign: false }), checkField: false },
      { label: i18n.t('lbl.commission'), key: 'Commision', render: ({ Commision, BankDailyReportLine }) => formatCurrency({ data: Commision, currencyCode: BankDailyReportLine.Pos.Currencies.Code, withSign: false }) },
      { label: i18n.t('lbl.chip'), key: 'Chip' },
      { label: i18n.t('lbl.point'), key: 'Point' },
      { label: i18n.t('lbl.award'), key: 'Award' },
      { label: i18n.t('lbl.authCode'), key: 'BankDailyReportLine/AuthCode', checkField: false },
      { label: i18n.t('lbl.commitDate'), key: 'BankDailyReportLine/CommitDate', render: ({ BankDailyReportLine }) => formatDate(BankDailyReportLine.CommitDate, null, null, null, false), checkField: false },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...reportLineDetails, single: reportLineDetails.list } }}>
            <Filter filters={filters} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
            <DataTable
              count={reportLineDetails.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="BankDailyReportLineDetail"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={reportLineDetails.list.data}
              loading={reportLineDetails.list.loading}
              title={i18n.t('route.posrapor.reportLineDetails')}
              getData={this.getReportLineDetails}
              Component={ReportLineDetail}
              dialogTitle={i18n.t('lbl.reportLine')}
              deleteOptions={{ stateKey: "reportLineDetails", url: "BankDailyReportLineDetail" }}
              excel={{ url: "ExportBankDailyReportLineDetail", filter: { filter: filter.filter, expand: filter.expand, orderBy: filter.orderBy }, module: "posrapor" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor, common }) => ({
  reportLineDetails: posrapor.reportLineDetails,
  banks: posrapor.filter.banks,
  currencies: posrapor.filter.currencies,
  creditCardBanks: posrapor.filter.creditCardBanks,
  creditCardTypes: posrapor.filter.creditCardTypes,
  creditCardPrograms: posrapor.filter.creditCardPrograms,
  creditCardOrganizationTypes: posrapor.filter.creditCardOrganizationTypes,
  companyNames: posrapor.filter.companyNames,
  currentFilter: common.currentFilter
});
const mapDispatchToProps = (dispatch) => ({
  getReportLineDetails: (filter) => dispatch(actions.getAll({ filter, url: endpoints.posrapor.reportLineDetail, key: 'reportLineDetails' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportLinesDetails);