import React from 'react';
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/cek';
import _ from 'lodash';


export default class TosUtils {

  static getBankName({ data = null, dataField = 'BankCode', listKeys = 'tenantBanks', listField = 'Code', bankData = null, className = '' }) {
    let list, bank;
    if (!bankData) {
      list = _.get(store.getState().cek.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === _.get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall || bank.LogoSmall} className={`bank-icon ${className}`} alt="" /> {bank.name || bank.Name}</> : '';
  }

  static getTenantBanks(field = 'TenantId') {
    const filter = {
      orderBy: ['Name asc'],
      filter: { [field]: { any: { TenantId: { type: 'guid', value: store.getState().auth.data.tenantId } } } }
    }
    dispatchers.getTenantBanks(filter);
  }

  static getOperationStatus(_filter) {
    let filter = { orderBy: ['Name asc'] }
    if(_filter)
      filter = {...filter, ..._filter};
    dispatchers.getOperationStatus(filter);
  }
}

const dispatchers = {
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.cek.tenantBanks, key: 'tenantBanks', isNewODataStructure: true })),
  getOperationStatus: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.cek.operationStatus, key: 'operationStatuses', isNewODataStructure: true })),
}
