import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { CekParameterModel } from 'models';
import actions from 'store/actions/cek';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import { serviceTypes } from 'helpers/tos';

class Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new CekParameterModel(),
      InitialState: new CekParameterModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.parameters.single.data && !state.data.Id) {
      const data = new CekParameterModel({ ...props.parameters.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.parameters.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getParameters(this.id);
    else this.setState({ loading: false });
    utils.common.getTenantBanks();
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData } = this.props;
    const data = _.cloneDeep(this.state.data);
    data.Port = parseInt(data.Port);
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { parameters, dialogCloseRequest, renderDialogButtons } = this.props;

    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={parameters.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={parameters.single.loading} form="parameter" htmlType="submit" className="success #save" icon="save" loading={parameters.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      parameters.single.saving,
      parameters.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { tenantBanksCek, tenantBanksCommon, parameters, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    const tenantBanks = _.intersectionWith(tenantBanksCek.data, tenantBanksCommon.data, ({ Code }, { bankEftCode }) => bankEftCode === Code);

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...parameters, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="parameter">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bankName')}
                        options={tenantBanks}
                        optVal="Code"
                        disabled={this.props.id}
                        optName={(bankData) => utils.cek.getBankName({ bankData })}
                        onChange={(e) => this.update('BankCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ServiceTypeId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ServiceTypeId
                    })(
                      <Select
                        className="#type"
                        label={i18n.t('lbl.serviceType')}
                        optVal="id"
                        options={serviceTypes}
                        optName="name"
                        allowClear={false}
                        disabled
                        lang="lbl"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankTenantCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankTenantCode
                    })(
                      <Input className="#BankTenantCode" label={i18n.t('lbl.companyCode')} onChange={(e) => this.update('BankTenantCode', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('Title', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.Title
                    })(
                      <Input className="#Title" label={i18n.t('lbl.companyTitle')} onChange={(e) => this.update('Title', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ChequeCode
                    })(
                      <Input className="#ChequeCode" label={i18n.t('lbl.institutionCode')} onChange={(e) => this.update('ChequeCode', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={18} sm={9}>
                  <Form.Item>
                    {getFieldDecorator('Host', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.Host
                    })(
                      <Input className="#Host" label={i18n.t('lbl.hostName')} onChange={(e) => this.update('Host', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={6} sm={3}>
                  <Form.Item>
                    {getFieldDecorator('Port', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.Port
                    })(
                      <Input className="#Port" maxLength={5} label={i18n.t('lbl.portName')} onChange={(e) => this.update('Port', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('UploadFolder', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.UploadFolder
                    })(
                      <Input className="#UploadFolder" label={i18n.t('lbl.upFolder')} onChange={(e) => this.update('UploadFolder', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('DownloadFolder', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.DownloadFolder
                    })(
                      <Input className="#DownloadFolder" label={i18n.t('lbl.downFolder')} onChange={(e) => this.update('DownloadFolder', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('UserName', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.UserName
                    })(
                      <Input className="#UserName" label={i18n.t('lbl.userName')} onChange={(e) => this.update('UserName', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('Password', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.Password
                    })(
                      <Input type="password" className="#Password" label={i18n.t('lbl.password')} onChange={(e) => this.update('Password', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ cek, common }) => ({
  parameters: cek.parameters,
  tenantBanksCek: cek.filter.tenantBanks,

  tenantBanksCommon: common.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (id, callback) => dispatch(actions.get({ url: endpoints.cek.chequeAccount, key: 'parameters', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.cek.chequeAccount, key: 'parameters', data }, callback)),
  clearState: () => dispatch(actions.clearState("parameters"))
});
const ParameterForm = Form.create()(Parameter);
export default connect(mapStateToProps, mapDispatchToProps)(ParameterForm);