import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { DataTable, Filter, ActionButtons, FadeIn } from 'components/UIComponents';
import { defaultFilters, baseUrls } from "lib/constants";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import Detail from './Detail';
import utils from 'lib';

class VoucherTypes extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin.VoucherTypeInitializers(),
  };

  componentDidMount() {
    this.getVoucherTypes();
    utils.admin.getErpTypes();
    utils.common.checkAccessToUpdateOrDelete("VoucherTypes");
  };

  componentWillUnmount() {
    utils.admin.clearFilter("erpTypes")
  }

  getVoucherTypes = () => {
    let { filter } = this.state;
    this.props.getVoucherTypes(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getVoucherTypes);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getVoucherTypes);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getVoucherTypes);
  }

  getErpTypeName = row => {
    let { erpTypes } = this.props;
    const erpType = erpTypes.list && erpTypes.list.data && erpTypes.list.data.find(x => x.id === row.erpType);
    return erpType && erpType.name;
  }

  render() {
    let { voucherTypes, erpTypes } = this.props;
    let columns = [
      {
        render: (row) => <ActionButtons
          url="VoucherTypes"
          editUrl="openDialog"
          item={row}
          hasDelete
          getDatas={this.getVoucherTypes}
          openDialog={this.datatable && this.datatable.openDialog}
        />, toggle: false, sort: false, notIncluded: true, checkField: false
      },
      { label: i18n.t('lbl.id'), key: 'Id' },
      { label: i18n.t('lbl.erpVoucherCode'), key: 'Code' },
      { label: i18n.t('lbl.erpVoucherName'), key: 'Name' },
      { label: i18n.t('lbl.erpType'), key: 'erpType', render: this.getErpTypeName },
    ];
    let filters = [
      { label: i18n.t('lbl.erpType'), type: 'select', options: erpTypes.data, value: 'id', name: 'name', keys: ['ErpTypeKey'], uniqueKey: '#erpType', multiple: true },
    ];
    return (
      <FadeIn className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="VoucherTypes"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={voucherTypes.list.data}
          loading={voucherTypes.list.loading}
          title={i18n.t('route.admin.voucherTypes')}
          getData={this.getVoucherTypes}
          count={voucherTypes.list.count}
          newButton="openDialog"
          Component={Detail}
          dialogTitle={i18n.t('lbl.voucherTypes')}
          deleteOptions={{ stateKey: "voucherTypes", url: "VoucherTypes", baseUrl: baseUrls.tenant }}
        />
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  voucherTypes: admin.voucherTypes,
  erpTypes: admin.filter.erpTypes,
});
const mapDispatchToProps = (dispatch) => ({
  getVoucherTypes: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tenant.voucherTypes, key: 'voucherTypes', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(VoucherTypes);