import React, { useState } from 'react'
import { Tabs, Row, Col, Button, Form } from 'antd';
import i18n from 'plugins/i18n';
import { FormElements, FadeIn, Typography } from 'components/UIComponents';
import { httpMethods } from 'lib/constants';
import endpoints from 'config/endpoints';
import { connect } from 'react-redux';
import actions from 'store/redux/netekstre';
import { phoneNormalizer } from 'helpers';
import { withRouter } from 'react-router-dom';
// import QRCode from 'qrcode.react';

const SendingUrl = ({ form, sendUrlForTransactionComplete, parameters, setPage, history }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ phone: undefined, email: undefined, channelType: "sms" });
  const [saving, setSaving] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      if (!error) {
        setSaving(true);
        const _data = { ...parameters, ...data }
        if (_data.phone) _data.phone = phoneNormalizer(_data.phone);
        sendUrlForTransactionComplete(_data, ({errors, referenceCode}) => {
          if (!(errors && errors.length > 0)) {
            history.push(`/kredi/application-result/${referenceCode}`)
          }
          setSaving(false)
        })
      }
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Row className="centered mt-40 mobile-text-center">
        <Col xs={24} md={12}>
          <Tabs type="card" tabBarGutter={12} destroyInactiveTabPane onChange={e => updateData(data => ({ ...data, channelType: e }))} className="apply-credit">
            <Tabs.TabPane tab={i18n.t('lbl.phone')} key="sms">
              <FadeIn>

                <Typography.P className="text-center" size="normal">
                  {i18n.t('lbl.sendingUrlUserInfo')}
                </Typography.P>
                <Row>
                  <Col xs={24} md={{ offset: 5, span: 14 }}>
                    {
                      getFieldDecorator('phone', {
                        rules: [
                          { required: data.type === "phone", message: i18n.t('msg.required') },
                        ],
                        initialValue: data.phone
                      })(
                        <FormElements.Phone
                          label={i18n.t("lbl.gsm")}
                          prefix="gsm"
                          onChange={e => updateData(data => ({ ...data, phone: e }))}
                          mobile
                        />
                      )}
                  </Col>

                  <Col xs={24} md={24}>
                    <Row className="mt-50 mobile-text-center mobile-margin-left-right-0" gutter={44}>
                      <Col xs={24} md={12} className="text-right">
                        <Button ghost type="default" htmlType="button" size="large" onClick={() => setPage({ current: "Application" })}>
                          {i18n.t('btn.cancel')}
                        </Button>
                      </Col>
                      <Col xs={24} md={12} className="mobile-text-center">
                        <Button type="primary" htmlType="submit" size="large" loading={saving}>
                          {i18n.t('btn.continue')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </FadeIn>
            </Tabs.TabPane>
            <Tabs.TabPane tab={i18n.t('lbl.email')} key="email">
              <FadeIn>

                <Typography.P className="text-center" size="normal">
                  {i18n.t('lbl.sendingUrlUserInfo')}
                </Typography.P>

                <Row>
                  <Col xs={24} md={{ offset: 5, span: 14 }}>
                    {
                      getFieldDecorator('email', {
                        rules: [
                          { required: data.type === "email", message: i18n.t('msg.required') },
                          { type: 'email', message: i18n.t('msg.emailInvalid') }
                        ],
                        initialValue: data.email
                      })(
                        <FormElements.Input
                          label={i18n.t("lbl.email")}
                          prefix="envelope"
                          onChange={e => updateData(data => ({ ...data, email: e }))}
                        />
                      )}
                  </Col>

                  <Col xs={24} md={24}>
                    <Row className="mt-50 mobile-text-center mobile-margin-left-right-0" gutter={44}>
                      <Col xs={24} md={12} className="text-right">
                        <Button ghost type="default" htmlType="button" size="large" onClick={() => setPage({ current: "Application" })}>
                          {i18n.t('btn.cancel')}
                        </Button>
                      </Col>
                      <Col xs={24} md={12}>
                        <Button type="primary" htmlType="submit" size="large" loading={saving}>
                          {i18n.t('btn.continue')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </FadeIn>
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab={i18n.t('lbl.qrCode')} key="qr" className="qr">

            <Typography.P className="text-center" size="normal">
              {i18n.t('lbl.sendingUrlUserInfo')}
            </Typography.P>

            <Row>
              <Col xs={24} md={{ offset: 5, span: 14 }} className="text-center">
                <QRCode value="https://google.com" />
              </Col>
            </Row>

            <Row>
              <Col xs={24} md={{ offset: 3, span: 18 }}>
                <Typography.P className="text-center" size="normal">
                  {i18n.t('lbl.qrDescription')}
                </Typography.P>
              </Col>
            </Row>

          </Tabs.TabPane>*/}
          </Tabs>
        </Col>
      </Row>
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => ({
  sendUrlForTransactionComplete: (data, callback) => dispatch(actions.globalRequest({ url: endpoints.kredi.sendUrlForTransactionComplete, data }, httpMethods.post, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(Form.create()(SendingUrl)));