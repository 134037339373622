import React, { Component, Suspense, lazy } from 'react';
import { Card, Tabs } from 'antd';
import { Loading } from 'components/UIComponents';
import i18n from 'plugins/i18n';
import utils from 'lib';
import { levels } from 'lib/constants';

const Definition = lazy(() => import('./Components/Definition'));
const ModuleInfo = lazy(() => import('./Components/ModuleInfo'));
const AccessRights = lazy(() => import('./Components/AccessRights'));
const Documents = lazy(() => import('./Components/Documents'));
const Licences = lazy(() => import('./Components/Licences'));
const Users = lazy(() => import('./Components/Users'));

class Tenant extends Component {
  state = { current: 'definition', id: this.props.match.params.id, parentTenantId: null, renderKey: 0 };

  componentDidMount() {
    const { history } = this.props
    if (!history.location.state) history.push('/admin/tenants');
  }

  componentWillUnmount() {
    utils.admin.clearState('tenants', { deep: true });
  }

  onTabChange = (key, data, parentTenantId, renderKey) => {
    const { id, parentTenantId: parentId, renderKey: currentKey } = this.state;
    let _state = {
      current: key,
      id: data || id,
      parentTenantId: parentTenantId || parentId
    }
    if (renderKey) _state.renderKey = currentKey + 1;
    if (id || data) this.setState(_state)
  }

  getTitle = () => {
    const { id } = this.state;
    const { history } = this.props;
    return i18n.t(`lbl.${id ? 'edit' : 'new'}Tenant`, { tenantType: i18n.t(`lbl.${levels.find(x => x.id === history.location.state?.level)?.name}`) })
  }


  render() {
    const { current, id, parentTenantId, renderKey: key } = this.state;
    const { history } = this.props;
    return (
      <div className="page-content">
        <Card title={<span>{this.getTitle()}</span>}>
          <Tabs activeKey={current} className="no-hover" onChange={this.onTabChange} onTabClick={this.onTabClick}>

            <Tabs.TabPane key="definition" tab={i18n.t(`lbl.firmInformation`)}>
              <Suspense fallback={<Loading />}>
                <Definition  {...{ id, onTabChange: this.onTabChange, history }} />
              </Suspense>
            </Tabs.TabPane>

            <Tabs.TabPane key="moduleInfo" tab={i18n.t(`lbl.moduleInfo`)}>
              <Suspense fallback={<Loading />}>
                <ModuleInfo  {...{ id, onTabChange: this.onTabChange, key }} />
              </Suspense>
            </Tabs.TabPane>

            <Tabs.TabPane key="accessRights" tab={i18n.t(`lbl.accessRights`)}>
              <Suspense fallback={<Loading />}>
                <AccessRights  {...{ id, onTabChange: this.onTabChange, history, parentTenantId, key }} />
              </Suspense>
            </Tabs.TabPane>

            <Tabs.TabPane key="licences" tab={i18n.t(`lbl.licenceInformation`)}>
              <Suspense fallback={<Loading />}>
                <Licences {...{ id, onTabChange: this.onTabChange, parentTenantId }} />
              </Suspense>
            </Tabs.TabPane>


            <Tabs.TabPane key="documents" tab={i18n.t('lbl.documents')}>
              <Suspense fallback={<Loading />}>
                <Documents {...{ id, onTabChange: this.onTabChange }} />
              </Suspense>
            </Tabs.TabPane>

            <Tabs.TabPane key="users" tab={i18n.t(`lbl.firmUsers`)}>
              <Suspense fallback={<Loading />}>
                <Users {...{ id, onTabChange: this.onTabChange, history }} />
              </Suspense>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    );
  };
};

export default Tenant;