import React from 'react'
import { Button as AntButton, Icon } from "antd";
import ReactSVG from 'react-svg';
import icons from 'assets/img/icons';

const Button = ({ block, className = "", customIcon, customIconPlacement = "right", children, htmlType, loading, customLoading, disabled, onClick, nextGen, type, color }) => {
  return (
    <AntButton
      {...{
        type,
        onClick,
        block,
        className: `${className} ${nextGen ? 'nextGen' : ''} icon-align__${customIconPlacement} ${color}`,
        htmlType,
        loading,
        disabled: disabled || customLoading
      }}
    >
      {children}
      {
        customIcon &&
        <div className={`custom-icon ${customIconPlacement}`}>
          {
            customLoading ?
              <Icon type="loading" spin />
              :
              <ReactSVG src={icons[customIcon]} />

          }
        </div>
      }
    </AntButton>
  )
}

export default Button;
