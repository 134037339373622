import config from 'config'
import { projectTypes } from 'lib/constants';

export default {
  path: '/admin',
  name: 'admin',
  component: require('components/Admin/Layout').default,
  routes: [
    {
      path: '/tenants',
      name: 'tenants',
      access: 'Tenants',
      component: require('components/Admin/Views/Tenants/List').default,
    },
    {
      path: '/tenant/:id?',
      name: 'tenant',
      access: 'Tenants',
      component: require('components/Admin/Views/Tenants/Detail').default,
      visible: false
    },
    {
      path: '/packages',
      name: 'packages',
      access: 'LicencePackages',
      visible: config.projectType === projectTypes.netfinans,
      component: require('components/Admin/Views/Packages/List').default,
    },
    {
      path: '/jobs',
      name: 'jobManagement',
      access: 'TenantJob',
      visible: config.projectType === projectTypes.netfinans,
      component: require('components/Admin/Views/JobManagement/List').default,
    },
    {
      path: '/bank-file-types',
      name: 'bankFileTypes',
      access: ['BankFileType'],
      component: require('components/Admin/Views/BankFileTypes/List').default,
    },
    {
      path: '/definitions',
      name: 'definitions',
      access: ['VoucherTypes', 'GeneralCategories', 'TypeCodes', 'ErpTypes', 'AdminGeneralRules'],
      visible: config.projectType === projectTypes.netfinans,
      component: require('components/Admin/Views/Definitions').default,
      routes: [
        {
          path: '/categories',
          name: 'generalCategories',
          access: 'GeneralCategories',
          component: require('components/Admin/Views/Definitions/GeneralCategories/List').default,
        },
        {
          path: '/erptypes',
          name: 'erpTypes',
          access: 'ErpTypes',
          component: require('components/Admin/Views/Definitions/ErpTypes/List').default,
        },
        {
          path: '/typecodes',
          name: 'typeCodes',
          access: 'TypeCodes',
          component: require('components/Admin/Views/Definitions/TypeCodes/List').default,
        },
        {
          path: '/vouchertypes',
          name: 'voucherTypes',
          access: 'VoucherTypes',
          component: require('components/Admin/Views/Definitions/VoucherTypes/List').default,
        },
        {
          path: '/rules',
          name: 'rules',
          access: 'AdminGeneralRules',
          component: require('components/Admin/Views/Definitions/GeneralRules/List').default,
        }
      ]
    },
    // {
    //   path: '/reports',
    //   name: 'reports',
    //   component: require('components/Admin/Views/Reports').default,
    //   access: ['LicenseStatusReports'],
    //   visible: config.projectType === projectTypes.netfinans,
    //   routes: [
    //     {
    //       path: '/licence',
    //       name: 'licence',
    //       access: 'LicenseStatusReports',
    //       component: require('components/Admin/Views/Reports/LicenceStatusReports').default,
    //     },
    //     {
    //       path: '/tenantsLicence',
    //       name: 'tenantsLicence',
    //       access: 'TenantLicenceStatuses',
    //       component: require('components/Admin/Views/Reports/TenantLicenceStatusReports').default,
    //     }
    //   ]
    // },

    //SETTINGS
    {
      path: '/settings',
      name: 'settings',
      component: require('components/Admin/Views/Settings').default,
      access: ['Tenants', 'AdminTenantBanks', 'SubTenantCategories', 'AdminCurrentAccounts', 'AccountParameter', 'SubPosRaporAccountParameters', 'SubTenantJob', 'AdminGeneralRules', 'AdminRules'],
      routes: [
        {
          path: '/banks',
          name: 'banks',
          access: ['Tenants', 'AdminTenantBanks'],
          component: require('components/Admin/Views/Settings/Banks').default,
        },
        {
          path: '/rules',
          name: 'rules',
          component: require('components/Admin/Views/Settings/GeneralRules').default,
        },
        {
          path: '/categories',
          name: 'allCategories',
          access: ['Tenants', 'SubTenantCategories'],
          component: require('components/Admin/Views/Settings/GeneralCategories').default,
        },
        {
          path: '/current-accounts',
          name: 'currentAccounts',
          access: ['Tenants', 'AdminCurrentAccounts'],
          component: require('components/Admin/Views/Settings/CurrentAccounts').default,
        },
        {
          path: '/dbs-parameters',
          name: 'dbsParameters',
          access: ['Tenants', 'AccountParameter'],
          component: require('components/Admin/Views/Settings/DbsParameters').default,
        },
        {
          path: '/psr-parameters',
          name: 'psrParameters',
          access: ['Tenants', 'SubPosRaporAccountParameters'],
          component: require('components/Admin/Views/Settings/PsrParameters').default,
        },
        {
          path: '/job',
          name: 'jobManagement',
          access: ['Tenants', 'SubTenantJob'],
          component: require('components/Admin/Views/Settings/JobManagement').default,
        },
        {
          path: '/customers',
          name: 'customers',
          access: ['Tenants', 'Customers'],
          component: require('components/Admin/Views/Settings/Customers').default,
        },
      ]
    },
  ]
};