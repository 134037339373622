import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select, DatePicker } from 'components/UIComponents/NetbankaComponents';
import { PaymentListsModel } from 'models';
import actions from 'store/actions/tos';
import endpoints from 'config/endpoints';
import { Loading } from 'components/UIComponents';
import _ from 'lodash';
import i18n from 'plugins/i18n';
import utils from 'lib';

class DeletePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new PaymentListsModel(),
      initialState: new PaymentListsModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.paymentList.single.data && !state.data.Id) {
      const data = new PaymentListsModel({ ...props.paymentList.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.paymentList.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getOrders(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    let { paymentList, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={paymentList.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={paymentList.single.loading} htmlType="submit" className="success #save" icon="save" loading={paymentList.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      paymentList.single.saving,
      paymentList.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { tenantBanks, orderStatus } = this.props;
    this.renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    value={data.BankCode}
                    className="#bank"
                    label={i18n.t('lbl.bankName')}
                    options={tenantBanks.data}
                    optVal="Code"
                    optName={(bankData) => utils.tos.getBankName({ bankData })}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker disabled label={i18n.t('lbl.paymentDate')} className="#PaymentDate" value={data.PaymentDate} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    className="#OperationStatus"
                    label={i18n.t('lbl.orderStatus')}
                    options={orderStatus.data}
                    optVal="Id"
                    optName="Name"
                    value={data.OperationStatusId}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#TotalCount" label={i18n.t('lbl.totalCount')} value={data.TotalCount} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#TotalAmount" label={i18n.t('lbl.totalAmount')} value={data.TotalAmount} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#CurrencyCode" label={i18n.t('lbl.currencyCode')} value={data.CurrencyCode} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#Description" label={i18n.t('lbl.description')} value={data.Description} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ tos }) => ({
  paymentList: tos.paymentList,
  tenantBanks: tos.filter.tenantBanks,
  orderStatus: tos.filter.orderStatus,
});
const mapDispatchToProps = (dispatch) => ({
  getOrders: (id, callback) => dispatch(actions.get({ url: endpoints.tos.tosOrder, key: 'paymentList', id }, callback)),
  clearState: () => dispatch(actions.clearState("paymentList"))
});
const DeletePaymentForm = Form.create()(DeletePayment);
export default connect(mapStateToProps, mapDispatchToProps)(DeletePaymentForm);