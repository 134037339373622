import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Row, Col, Form } from 'antd';
import { Input } from 'components/UIComponents/NetbankaComponents';
import actions from 'store/actions/common';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class Profile extends Component {
  state = {
    formVisible: false
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password'))
      callback(i18n.t('msg.passwordMatchError'));
    else
      callback();
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && form.getFieldValue('rePassword'))
      form.validateFields(['rePassword'], { force: true });
    callback();
  };


  save = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(error => {
      if (!error) {
        const data = { ...this.props.form.getFieldsValue(), id: this.props.auth.data.memberId };
        this.props.updatePassword(data, () =>
          this.setState({ formVisible: false })
        );
      }
    });
  };

  render() {
    const { auth, users } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="page-content">
        <Card bordered={false} className="no-border" title={
          <React.Fragment>
            <Row gutter={20}>
              <Col span={12} className="font-20">
                {i18n.t('lbl.userInfo')}
              </Col>
              <Col span={12} className="text-right">
                {!this.state.formVisible &&
                  <Button className="#changePassword" icon="key" onClick={() => this.setState({ formVisible: true })}>
                    {i18n.t('btn.changePassword')}
                  </Button>
                }
              </Col>
            </Row>
          </React.Fragment>
        }>
          <Row gutter={20}>
            <Col xs={12} sm={6}>
              <p className="bold">{i18n.t('lbl.username')}</p>
              <p className="bold">{i18n.t('lbl.email')}</p>
              <p className="bold">{i18n.t('lbl.company')}</p>
            </Col>
            <Col xs={12} sm={8} lg={6}>
              <p>{auth.data.userName}</p>
              <p>{auth.data.email}</p>
              <p>{auth.data.tenantName}</p>
            </Col>
            <Col xs={24} sm={10} lg={12}>
              {this.state.formVisible &&
                <Form onSubmit={this.save}>
                  <Row gutter={20}>
                    <Col xs={24} lg={12}>
                      <Form.Item>
                        {getFieldDecorator('currentPassword', {
                          rules: [{ required: true, message: i18n.t('msg.required') }]
                        })(
                          <Input className="#oldPassword" type="password" label={i18n.t('lbl.oldPassword')} />
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('password', {
                          rules: [
                            { required: true, message: i18n.t('msg.required') },
                            { validator: this.validateToNextPassword }
                          ]
                        })(
                          <Input className="#newPassword" type="password" label={i18n.t('lbl.newPassword')} />
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('rePassword', {
                          rules: [
                            { required: true, message: i18n.t('msg.required') },
                            { validator: this.compareToFirstPassword }
                          ]
                        })(
                          <Input className="#confirmPassword" type="password" label={i18n.t('lbl.confirmPassword')} />
                        )}
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={20}>
                          <Col span={12}>
                            <Button className="error full-width #error" icon="close" onClick={() => this.setState({ formVisible: false })}>
                              {i18n.t('btn.cancel')}
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Button className="success full-width #save" htmlType="submit" icon="save" loading={users.saving}>
                              {i18n.t('btn.save')}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              }
            </Col>
          </Row>
        </Card>
      </div>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({ auth, users: common.users.single });
const mapDispatchToProps = (dispatch) => ({
  updatePassword: (data, callback) => dispatch(actions.post({ url: endpoints.auth.changePassword, data, key: "users", options: { errors: { showMessage: true } } }, callback)),
});
const ProfileForm = Form.create()(Profile);
export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);