export default {
  signIn: (state) => {
    const data = { ...state, loading: true }
    return data;
  },
  signInSuccess: ({ payload }) => {
    const data = { loading: false, error: null, isLoggedIn: true, data: payload }
    return data;
  },
  signInFailure: ({ payload }) => {
    const data = { loading: false, error: payload.error, isLoggedIn: payload.isLoggedIn, data: null }
    return data;
  },
  verifyTwoFactor: (state) => {
    const data = { ...state, loading: true }
    return data;
  },
  verifyTwoFactorSuccess: ({ payload }) => {
    const data = { loading: false, error: null, isLoggedIn: true, data: payload }
    return data;
  },
  verifyTwoFactorFailure: ({ payload }) => {
    const data = { loading: false, error: payload.error, isLoggedIn: payload.isLoggedIn, data: null }
    return data;
  },
  getAccount: () => {
    const data = { fetching: true }
    return data;
  },
  getAccountSuccess: ({ payload }) => {
    const data = { fetching: false, error: null, data: payload }
    return data;
  },
  getAccountFailure: ({ payload }) => {
    const data = { fetching: false, payload: null, error: payload.error }
    return data;
  },
  logout: (state, { payload }) => {
    const data = { data: state.data, payload, loggingOut: true }
    return data;
  },
  logoutSuccess: (state, { payload }) => {
    const data = { data: state.data, payload, loggingOut: true }
    return data;
  },
  logoutFailure: (state, { payload }) => {
    const data = { data: state.data, payload, loggingOut: false }
    return data;
  },
  resetPassword: (state) => {
    const data = { ...state, loading: true }
    return data;
  },
  resetPasswordSuccess: () => {
    const data = { loading: false, error: null, isLoggedIn: null, data: 200}
    return data;
  },
  resetPasswordFailure: ({ payload }) => {
    const data = { loading: false, error: payload.error, isLoggedIn: null, data: null }
    return data;
  },
  doBlockedUserToActive: (state) => {
    const data = { ...state, loading: true }
    return data;
  },
  doBlockedUserToActiveSuccess: () => {
    const data = { loading: false, error: null, isLoggedIn: null, data: 200}
    return data;
  },
  doBlockedUserToActiveFailure: ({ payload }) => {
    const data = { loading: false, error: payload.error, isLoggedIn: null, data: null }
    return data;
  },
  resendOtp: (state) => {
    const data = { ...state, loading: true }
    return data;
  },
  resendOtpSuccess: () => {
    const data = { loading: false, error: null, isLoggedIn: null, data: 200}
    return data;
  },
  resendOtpFailure: ({ payload }) => {
    const data = { loading: false, error: payload.error, isLoggedIn: null, data: null }
    return data;
  },
}