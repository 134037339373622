

export default {
  path: '/common',
  name: 'common',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/profile',
      name: 'profile',
      component: require('components/Modules/Common/Profile').default,
    },
    {
      path: '/tenant',
      name: 'tenantInfo',
      component: require('components/Modules/Common/Tenant/Detail').default,
    },
    {
      path: '/definitions',
      name: 'definitions',
      component: require('components/Modules/Common/Definitions').default,
      access: ['CurrentAccounts', 'BankAccounts', 'Users', 'Roles'],
      routes: [
        {
          path: '/current-accounts',
          name: 'currentAccounts',
          component: require('components/Modules/Common/Definitions/CurrentAccounts/List').default,
          access: 'CurrentAccounts',
        },
        {
          path: '/banks',
          name: 'banks',
          access: 'TenantBanks',
          component: require('components/Modules/Common/Definitions/TenantBanks/List').default,
        },
        {
          path: '/bank-accounts',
          name: 'bankAccounts',
          access: 'BankAccounts',
          component: require('components/Modules/Common/Definitions/BankAccounts/List').default,
        },
        {
          path: '/member-management',
          name: 'memberManagement',
          access: ['Roles', 'Users'],
          component: require('components/Modules/Common/Definitions/MemberManagement').default,
          routes: [
            {
              path: '/roles',
              name: 'roles',
              access: 'Roles',
              component: require('components/Modules/Common/Definitions/MemberManagement/Roles/List').default,
            },
            {
              path: '/users',
              name: 'users',
              access: 'Users',
              component: require('components/Modules/Common/Definitions/MemberManagement/Users/List').default,
            },
            {
              path: '/customers',
              name: 'customers',
              // access: 'Customers',
              component: require('components/Modules/Common/Definitions/MemberManagement/Customers/List').default,
            },
          ]
        },
      ]
    },
    {
      path: '/settings',
      name: 'settings',
      access: 'GeneralParameters',
      component: require('components/Modules/Common/Settings').default,
    },
    {
      path: '/changelog',
      name: 'changelog',
      component: require('components/Modules/Common/ChangeLog').default,
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: require('components/Modules/Common/Notifications').default,
    },
  ]
};