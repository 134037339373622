import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { chartOptions } from 'lib/constants';
import { Loading } from 'components/UIComponents';
import { Card, Row, Col, Radio } from 'antd';
import _ from 'lodash';
import { formatChartData, convertFieldsToCamelize } from 'helpers';
import i18n from 'plugins/i18n';
import { chartTypes } from 'models';
const actions = {};

class PosraporOnusNotOnus extends Component {
  state = {
    dataIndex: undefined,
    data: null,
    wait: this.props.getWithQueue,
    Day: this.props.range?.defaultSelected
  }

  componentDidUpdate(prevProps) {
    if (this.props.currencyCode !== prevProps.currencyCode)
      this.getChartData();
  };

  componentDidMount() {
    if (!this.props.getWithQueue)
      this.getChartData();
  }

  getCharts = (e) => {
    this.setState({ Day: parseInt(e.target.value) }, () => {
      this.getChartData();
    });
  }

  getChartData = async () => {
    const { currencyCode, currentModule } = this.props;
    const queryPayload = { currencyCode };
    if (this.state.Day)
      queryPayload.Day = this.state.Day;
    const args = [currentModule, { currencyCode }, queryPayload];

    return new Promise(async resolve => {
      this.setState({ wait: false });
      if (!actions[currentModule]) {
        const _module = await import(`store/actions/${currentModule}`);
        actions[currentModule] = _module.default;
        this.props.getChartData(...args, resolve);
      }
      else {
        this.props.getChartData(...args, resolve);
      }
    })
  }

  render() {
    const { data } = this.props.stackedBarChartOnus.single;
    const { dataIndex, wait, Day } = this.state;
    const { stackedBarChartOnus, auth, accessRight, range, className, title } = this.props;
    const chartData = data ? _.cloneDeep(formatChartData({ series: data.series, labels: data.xAxis, options: { positive: true }, type: chartTypes.stackedBarChart })) : {};

    return (
      <div className="mt-50">
        <>
          <Row gutter={20} className={`${className}`}>
            <Col span={24} sm={12}>
              <h1 className="title">{title}</h1>
            </Col>
            <Col xs={24} sm={12}>
              <Radio.Group className="pull-right" buttonStyle="solid" value={parseInt(Day)} onChange={this.getCharts}>
                {
                  range?.ranges.map(x => (
                    <Radio.Button key={x.uniqueName} value={x.value} className={x.uniqueName}>{x.title}</Radio.Button>
                  ))
                }
              </Radio.Group>
            </Col>
            <Col span={24} className="mb-50">
              <Loading loading={stackedBarChartOnus.loading || wait} className="loading-card">
                <Card bodyStyle={{ height: '300px', paddingLeft: 0 }}>
                  {
                    (!chartData || chartData === {} || (accessRight && !auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method))) ?
                      <div className="no-access-icon">
                        <div className="no-access-right">
                          <i className="fas fa-exclamation-triangle"></i>
                          {i18n.t('msg.noAccessRightChart')}
                        </div>
                      </div>
                      :
                      <Bar
                        ref="stackedChart"
                        data={chartData}
                        options={chartOptions.stackedBarChart({ dataIndex, component: this, showLegend: true })}
                      />
                  }
                </Card>
              </Loading>
            </Col>
          </Row>
        </>
      </div >
    )
  }
}

const mapStateToProps = (state, { toCamel }) => ({
  auth: state.auth,
  stackedBarChartOnus: toCamel ? convertFieldsToCamelize(state[state.common.currentModule].stackedBarChartOnus) : state[state.common.currentModule].stackedBarChartOnus,
  currentModule: state.common.currentModule,
});

const mapDispatchToProps = (dispatch, { endpoint, method }) => ({
  getChartData: (currentModule, data, parameters, callback) => dispatch(actions[currentModule][method]({ data, url: endpoint, key: 'stackedBarChartOnus', parameters }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PosraporOnusNotOnus);