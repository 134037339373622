import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { Form, Row, Col } from 'antd';
import endpoints from 'config/endpoints';
import Loading from '../Loading';
import { Input } from '../NetbankaComponents';
import i18n from 'plugins/i18n';
import _ from "lodash";

class ExchangeRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentSourceType: props.sourceType.value,
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    this.setData();
  }

  getData = () => {
    const { sourceType } = this.props;
    if (sourceType.value === "1") {
      this.props.getCurrencyRates({ Type: 1 });
      this.props.getBankCurrencies({ getMultiTenant: false });
      this.props.getTenantExchangeType();
    }
    else if (sourceType.value === "2") this.props.getTenantCurrencies();
  }

  setData = () => {
    const { sourceType, currencies, currencyRates, tenantExchangeType, fixedExChangeTenantCurrencies } = this.props;
    const { data, currentSourceType } = this.state;

    if (currentSourceType !== sourceType.value)
      this.setState({ currentSourceType: sourceType.value, data: [] }, this.getData)

    if (!data.length || (currentSourceType !== sourceType.value)) {
      const _data = [];
      if (   /// Merkez Bankası ise
        sourceType.value === "1" &&
        currencies && currencies.single && currencies.single.data && currencies.single.data.length > 1 &&
        currencyRates && currencyRates.single && currencyRates.single.data && currencyRates.single.data.exchangeRates && currencyRates.single.data.exchangeRates.length > 0
      ) {
        const isEffectiveCurrencyCode = this.isUseEffective(tenantExchangeType);
        currencies.single.data.forEach(x => {
          const item = currencyRates.single.data.exchangeRates.find(y => y.from === x);
          if (item) _data.push({
            currencyCode: item.from,
            currencyId: item.from,
            exchangeRate: isEffectiveCurrencyCode ? item.effectiveSelling : item.selling,
            disabled: true
          })
        })
        this.setState({ data: _data })
      }

      else if ( /// Manuel İşlem ise
        sourceType.value === "2" &&
        fixedExChangeTenantCurrencies && fixedExChangeTenantCurrencies.list && fixedExChangeTenantCurrencies.list.data && fixedExChangeTenantCurrencies.list.data.length > 0
      ) {
        const _data = fixedExChangeTenantCurrencies.list.data.map(item => {
          return {
            currencyCode: item.currencyCode,
            currencyId: item.currencyId,
            exchangeRate: item.exchangeRate || 1,
            disabled: false
          }
        })
        this.setState({ data: _data })
      }
    }
  }

  isUseEffective = ({ data }) => {
    if (data) {
      switch (data.item) {
        case "Effective": return true;
        case "Normal": return false;
        default: return null;
      }
    }
    return null;
  }

  update = (key, value, currencyId) => {
    let data = _.cloneDeep(this.state.data);
    let item = data.find(x => x.currencyId === currencyId);
    item[key] = value;
    this.setState({ data });
  };

  save = (callback) => {
    const { form, saveTenantExchangeType } = this.props;
    const data = this.state.data.map(x => ({ ...x, exchangeRate: parseFloat(x.exchangeRate.toString().replace(',', '.')) }));

    form.validateFields(error => {
      if (!error) {
        saveTenantExchangeType(data, callback);
      }
    });
  };

  render() {
    const { fixedExChangeTenantCurrencies, currencyRates, currencies } = this.props;
    const { data } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Loading loading={(fixedExChangeTenantCurrencies.list.loading || currencyRates.single.loading) || (fixedExChangeTenantCurrencies.single.saving)}>
        <>
        {
          ((currencies && currencies.single && currencies.single.data && currencies.single.data.length > 0 &&
            currencyRates && currencyRates.single && currencyRates.single.data && currencyRates.single.data.exchangeRates && currencyRates.single.data.exchangeRates.length > 0)
            ||
            (fixedExChangeTenantCurrencies && fixedExChangeTenantCurrencies.list && fixedExChangeTenantCurrencies.list.data && fixedExChangeTenantCurrencies.list.data.length > 0))
          &&
          <Form>
            <Row gutter={20}>
              {data.map((x, i) => (
                <Col key={x.currencyId} xs={24} sm={6}>
                  <Form.Item>
                    {
                      getFieldDecorator(x.currencyCode, {
                        rules: [
                          { required: true, message: i18n.t('msg.required') },
                          { validator: (rule, value, callback) => value === "0" ? callback(true) : callback(), message: i18n.t('msg.noAcceptZero') },
                          { validator: (rule, value, callback) => value === "-" || value === "+" ? callback(true) : callback(), message: i18n.t('msg.signErrorNull') },
                          { validator: (rule, value, callback) => parseFloat(value.toString().replace(',', '.')) < 0 ? callback(true) : callback(), message: i18n.t('msg.leMin') },
                          { validator: (rule, value, callback) => value[value.length - 1] === (".") || value[value.length - 1] === (",") ? callback(true) : callback(), message: i18n.t('msg.notEndDotOrComma') },
                        ],
                        initialValue: x.exchangeRate
                      })(
                        <Input type="amount" label={x.currencyCode} disabled={x.disabled} onChange={(e) => this.update("exchangeRate", e, x.currencyId)} className={`#${x.currencyCode}`} />
                      )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        }
        </>
      </Loading>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  fixedExChangeTenantCurrencies: common.fixedExChangeTenantCurrencies,
  currencyRates: common.currencyRates,
  currencies: common.currencies,
  tenantExchangeType: common.filter.tenantExchangeType,
});
const mapDispatchToProps = (dispatch) => ({
  getTenantCurrencies: (filter) => dispatch(actions.getAll({ url: endpoints.tenant.tenantCurrenciesGetTenantFixedExChangeRates, key: 'fixedExChangeTenantCurrencies', filter })),
  saveTenantExchangeType: (data, callback) => dispatch(actions.put({ url: endpoints.tenant.tenantCurrenciesSetTenantFixedExChangeRates, data, key: 'fixedExChangeTenantCurrencies' }, callback)),
  getCurrencyRates: (parameters) => dispatch(actions.get({ url: endpoints.rates.exchangeRates, parameters, key: 'currencyRates', })),
  getBankCurrencies: (data) => dispatch(actions.getWithPost({ url: endpoints.tenant.bankAccountsGetCurrencies, key: 'currencies', data })),
  getTenantExchangeType: (parameters) => dispatch(actions.getFilter({ url: endpoints.lookups.tenantExchangeType, parameters, key: 'tenantExchangeType', })),

});
const ExchangeRatesForm = Form.create()(ExchangeRates);
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRatesForm);