import React, { Component } from 'react';
import { Button, Modal, Icon, Checkbox, notification } from 'antd';
import actions from 'store/actions/netekstre';
import { connect } from 'react-redux';
import GlobalLoading from "./GlobalLoading";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import utils from 'lib';

class ConfirmPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      data: {
        TransactionIdList: [],
        IncludeCompleteTransaction: false
      },
      loading: false,
    }
  };


  save = () => {
    let { data } = this.state;
    let { selectedItems, selectAll, filter } = this.props;
    data.TransactionIdList = selectAll ? [] : selectedItems;
    this.setState({ loading: true });
    this.props.postModal(data, selectAll ? filter : null, (response) => {
      if (response) {
        this.setState({ loading: false, modalVisible: false });
        utils.common.clearSelectedItems();
        this.props.onStartSync(false);
      }
      else {
        this.setState({ loading: false, modalVisible: false });
        notification.error({ message: i18n.t('msg.unknownError') });
      }
    });
  }


  render() {
    let { modalVisible, data, loading } = this.state;
    let { selectedItems, selectAll, accessRights, disabled } = this.props;
    return (
      <React.Fragment>
        {
          accessRights.find(x => x.endPoint === 'SendManuelTransactions' && x.method === 'PUT') &&
          <Button
            loading={loading}
            onClick={() => this.setState({ modalVisible: true })}
            disabled={(selectedItems.length === 0 && !selectAll) || loading || disabled}
            className="pull-right mr-10 #confirmButton btn-collapse"
            type="default"
            size="small"
          >
            {!loading && <Icon type="swap" />} {i18n.t('btn.sync')}
          </Button>
        }
        <Modal
          title={i18n.t('lbl.synchronize')}
          visible={modalVisible}
          onOk={this.save}
          okButtonProps={{ className: '#modalOk', icon: 'swap' }}
          cancelButtonProps={{ className: '#modalCancel', icon: 'close' }}
          okText={i18n.t('btn.sync')}
          cancelText={i18n.t('btn.cancel')}
          onCancel={() => { this.setState({ modalVisible: false }) }}
        >
          {
            loading ?
              <GlobalLoading />
              :
              <Checkbox
                checked={data.IncludeCompleteTransaction}
                onChange={(e) => this.setState({ data: { ...data, IncludeCompleteTransaction: e.target.checked } })}
              >
                {i18n.t('lbl.syncSuccessfulAgain')}
              </Checkbox>
          }
        </Modal>
      </React.Fragment>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ accessRights: auth.data.accessRights });
const mapDispatchToProps = (dispatch) => ({
  postModal: (data, filter, callback) => dispatch(actions.put({ url: endpoints.nte.bankAccountTransactionsSendManuelTransactions, data, filter: { filter } }, callback))
})
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);