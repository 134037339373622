import React from 'react'
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/netekstre';
import { get } from 'lodash'

export default class NetekstreUtils {

  static getCurrencies() {
    dispatchers.getCurrencies({ orderBy: ['currencyName asc'] });
  }

  static getBankName({ data = null, dataField = 'bankEftCode', listKeys = 'tenantBanks', listField = 'eftCode', bankData = null }) {
    let list, bank;
    if (!bankData) {
      list = get(store.getState().netekstre.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall || bank.LogoSmall} className="bank-icon" alt="" /> {bank.name || bank.Name}</> : '';
  }

  static getTenantBanks() {
    dispatchers.getTenantBanks({ orderBy: ['name asc'] });
  }

  static getBanks() {
    dispatchers.getBanks({ orderBy: ['name asc'] });
  }

  static getTransferStatus() {
    dispatchers.getTransferStatus();
  }
  
  static getLookupsTransferStatus() {
    dispatchers.getLookupsTransferStatus();
  }

  static getCurrentAccounts() {
    dispatchers.getCurrentAccounts();
  }

  static getVoucherTypeCodes() {
    dispatchers.getVoucherTypeCodes();
  }

  static getBankAccounts(filter) {
    dispatchers.getBankAccounts(filter);
  }

  static getBankAccountsOData(filter, callback) {
    dispatchers.getBankAccountsOData(filter, callback);
  }

  static getCategories(type, id) {
    let filter = { filter: { or: [{ ParentId: { ne: null }, CategoryType: id }, { CategoryType: 0 }] } };
    dispatchers.getCategories(`${type.toLowerCase()}Categories`, filter);
  }

  static getTransactionCategories(filter) {
    dispatchers.getTransactionCategories(filter);
  }

  static getTransferTypes() {
    dispatchers.getTransferTypes();
  }

  static getTypeCodes(level) {
    let filter = { filter: { level } }
    dispatchers.getTypeCodes(filter);
  }
}


const dispatchers = {
  getCurrencies: (filter) => store.dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies', filter })),
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.tenantBanks, key: 'tenantBanks', filter })),
  getCurrentAccounts: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.currentAccounts, key: 'currentAccounts', filter })),
  getBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.banks, key: 'banks', filter })),
  getTransferStatus: () => store.dispatch(actions.getFilter({ url: endpoints.nte.transferStatusGetRuleTransferStatus, key: 'transferStatus' })),
  getLookupsTransferStatus: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.transferStatus, key: 'transferStatus' })),
  getVoucherTypeCodes: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.voucherTypeCodes, key: 'voucherTypes' })),
  getCategories: (key, filter) => store.dispatch(actions.getFilter({ url: endpoints.nte.tenantCategories, key, filter, isNewODataStructure: true })),
  getTypeCodes: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.typeCodes, key: `typeCodes${filter.filter.level}` })),
  getBankAccounts: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.bankAccounts, key: 'bankAccounts' })),
  getBankAccountsOData: (filter, callback) => store.dispatch(actions.getFilter({ filter, url: endpoints.tenant.bankAccounts, key: 'bankAccounts', isNewODataStructure: true }, callback)),
  getTransactionCategories: (filter) => store.dispatch(actions.getFilter({ url: endpoints.nte.tenantCategories, key: 'transactionCategories', isNewODataStructure: true, filter })),
  getTransferTypes: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.nte.bankAccountsGetTransactionTransferTypes, key: 'transactionTransferTypes' })),
}
