import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import { Button, Tooltip, Icon } from 'antd';
import endpoints from 'config/endpoints';
import { regexTypes } from 'lib/constants';

class RuleDescription extends Component {
  constructor(props) {
    super(props);
    const field = props.admin ? 'GeneralRuleItems' : 'RuleItems';
    this.state = {
      loading: false,
      isLoaded: false,
      filter: {
        filter: {
          TypeCode1: props.item[field].find(({ RegexType }) => RegexType === regexTypes.TypeCode1) && props.item[field].find(({ RegexType }) => RegexType === regexTypes.TypeCode1).RegexPattern,
          BankEftCode: props.item[field].find(({ RegexType }) => RegexType === regexTypes.Bank) && props.item[field].find(({ RegexType }) => RegexType === regexTypes.Bank).RegexPattern
        },
        select: ['Description1'],
        orderBy: ['transactionDate desc']
      },
      data: []
    };
  };

  toggle = (value) => {
    const { loading, isLoaded, filter } = this.state
    if (value && !isLoaded && !loading) {
      this.setState({ loading: true });
      this.props.getDescription(filter, (response) => {
        if (response) this.setState({ loading: false, isLoaded: true, data: response.value });
      });
    }
  };

  render() {
    let { data, loading } = this.state;
    return (
      <div>
        <Tooltip openClassName="#ruleDescription" title={
          loading ?
            <Icon type="loading" />
            :
            <React.Fragment>
              {
                data && data.length &&
                data.filter(x => x.Description1 !== '').slice(-5).map((x, i) => (
                  <p key={i} className="mb-0">{x.Description1}</p>
                ))}
            </React.Fragment>
        } placement="right" onVisibleChange={this.toggle}>
          <Button className="#info info"><i className="fas fa-ellipsis-h"></i></Button>
        </Tooltip>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({ netekstre });
const mapDispatchToProps = (dispatch) => ({
  getDescription: (filter, callback) => dispatch(actions.getAll({ url: endpoints.nte.bankAccountTransactions, filter }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RuleDescription);