import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/tos';
import reducers from 'store/reducers/tos';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllTos: ['payload', 'callback'],
  getAllTosSuccess: ['payload'],
  getAllTosFailure: ['payload'],
  getTos: ['payload', 'callback'],
  getTosSuccess: ['payload'],
  getTosFailure: ['payload'],
  postTos: ['payload', 'callback'],
  postTosSuccess: ['payload'],
  postTosFailure: ['payload'],
  putTos: ['payload', 'callback'],
  putTosSuccess: ['payload'],
  putTosFailure: ['payload'],
  deleteTos: ['payload', 'callback'],
  deleteTosSuccess: ['payload'],
  deleteTosFailure: ['payload'],
  patchTos: ['payload', 'callback'],
  patchTosSuccess: ['payload'],
  patchTosFailure: ['payload'],
  clearStateTos: ['key'],
  getWithPostTos: ['payload'],
  getWithPostTosSuccess: ['payload'],
  getWithPostTosFailure: ['payload'],
  getFilterTos: ['payload', 'isNewODataStructure'],
  getFilterTosSuccess: ['payload', 'isNewODataStructure'],
  getFilterTosFailure: ['payload'],
});
export const TosTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state, action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state, action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state, action)
  return state.merge(data);
};



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_TOS]: getAll,
  [Types.GET_ALL_TOS_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_TOS_FAILURE]: getAllFailure,
  [Types.GET_TOS]: get,
  [Types.GET_TOS_SUCCESS]: getSuccess,
  [Types.GET_TOS_FAILURE]: getFailure,
  [Types.POST_TOS]: post,
  [Types.POST_TOS_SUCCESS]: postSuccess,
  [Types.POST_TOS_FAILURE]: postFailure,
  [Types.PUT_TOS]: put,
  [Types.PUT_TOS_SUCCESS]: putSuccess,
  [Types.PUT_TOS_FAILURE]: putFailure,
  [Types.DELETE_TOS]: _delete,
  [Types.DELETE_TOS_SUCCESS]: deleteSuccess,
  [Types.DELETE_TOS_FAILURE]: deleteFailure,
  [Types.PATCH_TOS]: patch,
  [Types.PATCH_TOS_SUCCESS]: patchSuccess,
  [Types.PATCH_TOS_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_TOS]: clearState,
  [Types.GET_WITH_POST_TOS]: getWithPost,
  [Types.GET_WITH_POST_TOS_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_TOS_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_TOS]: getFilter,
  [Types.GET_FILTER_TOS_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_TOS_FAILURE]: getFilterFailure,
});