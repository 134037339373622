import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Divider, Switch } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { RecipientAccountModel } from 'models';
import actions from 'store/actions/tos';
import { FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import utils from 'lib';

class AddNewAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new RecipientAccountModel(),
      linkToCurrentAccount: false,
    };
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  onSwitchChange = (checked) => {
    const { currentAccounts, getCurrentAccounts } = this.props
    this.setState({ linkToCurrentAccount: checked, data: { ...this.state.data, currentAccountId: null } }, () => {
      if (checked && (!currentAccounts.data.length)) getCurrentAccounts()
    });
  }

  save = () => {
    return new Promise((resolve) => {
      const { form, saveData } = this.props;
      const { data } = this.state
      form.validateFields(error => {
        if (!error) {
          saveData(data, response => resolve(response.data));
        };
      });
    });
  }

  onSearch = e => {
    const _this = this;
    if (e && e !== '' && e.length > 2) {
      const filter = { filter: { or: { name: { contains: e } } } }
      _this.props.getCurrentAccounts(filter);
    }
  }

  render() {
    const { recipientAccounts, tenantBanks, currentAccounts } = this.props;
    const { data, linkToCurrentAccount } = this.state;
    const { getFieldDecorator } = this.props.form;


    return (
      <FadeIn className="page-content">
        <CheckAccessRight {...{ ...recipientAccounts }}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('bankEftCode', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.bankEftCode
                  })(
                    <Select
                      className="#bank"
                      label={i18n.t('lbl.bank')}
                      options={tenantBanks.data}
                      optVal="Code"
                      optName={(bankData) => utils.common.getBankName({ bankData })}
                      onChange={(e) => this.update('bankEftCode', e)}
                      disabled={!!this.id}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.name
                  })(
                    <Input className="#name" label={i18n.t('lbl.accountName')} onChange={(e) => this.update('name', e)} />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('accountNumber', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.accountNumber
                  })(
                    <Input className="#accountNumber" label={i18n.t('lbl.accountNumber')} onChange={(e) => this.update('accountNumber', e)} />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#branchNumber" label={i18n.t('lbl.branchNumber')} value={data.branchNumber} onChange={(e) => this.update('branchNumber', e)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#branchName" label={i18n.t('lbl.branchName')} value={data.branchName} onChange={(e) => this.update('branchName', e)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('iban', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.iban
                  })(
                    <Input className="#iban" label={i18n.t('lbl.iban')} type="iban" onChange={(e) => this.update('iban', e)} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider>{i18n.t('lbl.currentAccount')}</Divider>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <Form.Item className="mt-5">
                  <Switch checked={linkToCurrentAccount || !!data.currentAccountId} onChange={this.onSwitchChange} loading={currentAccounts.loading} /> {i18n.t('lbl.linkToCurrentAccount')}
                </Form.Item>
              </Col>
              {
                linkToCurrentAccount &&
                <Col xs={24} md={12}>
                  <Form.Item>
                    {getFieldDecorator('currentAccountId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.currentAccountId
                    })(
                      <Select
                        loading={currentAccounts.loading}
                        showSearch
                        onSearch={this.onSearch}
                        className="#CurrentAccountId"
                        label={i18n.t('lbl.currentAccount')}
                        options={currentAccounts.data}
                        optVal="Id"
                        optName="Name"
                        onChange={(e) => this.update('currentAccountId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
              }
            </Row>
          </Form>
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ tos, common }) => ({
  recipientAccounts: tos.recipientAccounts,
  tenantBanks: tos.filter.tenantBanks,
  currentAccounts: tos.filter.currentAccounts,
});
const mapDispatchToProps = (dispatch) => ({
  saveData: (data, callback) => dispatch(actions.post({ url: endpoints.tos.recipientAccounts, key: 'recipientAccounts', data }, callback)),
  getCurrentAccounts: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.lookups.currentAccounts, key: 'currentAccounts', isNewODataStructure: false })),
  clearState: () => dispatch(actions.clearState("recipientAccounts")),
});
const AddNewAccountForm = Form.create()(AddNewAccount);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddNewAccountForm);