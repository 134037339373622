export const serviceTypes = [
  // { id: 1, name: 'ftp' },
  { id: 2, name: 'sftp' },
  // { id: 3, name: 'ws' },
]

export function isEditableOrderTos(orderStatusId){
  const notEditableIdList = [13,14,16,17];
  return !notEditableIdList.includes(orderStatusId);
}

export function isEditableInvoice(invoiceStatusId){
  const notEditableIdList = [2,3,5,6,7,8,9,10,11];
  return !notEditableIdList.includes(invoiceStatusId);
}