export const serviceTypes = [
  { id: 2, name: 'sftp' }
]

export function isEditableOrderCek(id){
  const notEditableIdList = [13,14,16,17];
  return !notEditableIdList.includes(id);
}

export function isEditableCheques(chequeStatusId){
  const notEditableIdList = [2,3,5,6,7,8,9,10,11];
  return !notEditableIdList.includes(chequeStatusId);
}