
import React from 'react';
import { Profile, HeaderAddons } from './'

export default function HeaderRight({ addons }) {

  return (
    <div className="header-right">
      <HeaderAddons  {...{ addons }} />
      <Profile />
    </div>
  )
}