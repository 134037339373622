import i18n from 'plugins/i18n';

export const posraporServiceTypes = [
  { id: 1, name: 'ftp' },
  { id: 2, name: 'sftp' },
  { id: 3, name: 'ws' },
]

export const cardHarmony = [
  { id: 1, name: 'onUs' },
  { id: 2, name: 'notOnUs' },
  { id: 3, name: 'unknown' }
]

export const posKinds = [
  { id: 1, name: 'physical' },
  { id: 2, name: 'virtual' }
]

export const transactionTypes = [
  { id: 40, name: 'sales' },
  { id: 60, name: 'refund' }
]

export const businessCardTypes = [
  { value: 1, name: 'unknown' },
  { value: 3, name: 'yes' },
  { value: 2, name: 'no' }
]

export const getInstallmentCount = (count) => {
  let installmentCount = [];
  installmentCount.push({ id: 1, name: i18n.t('posrapor.inAdvance') });
  for (let i = 2; i < count + 1; i++) {
    installmentCount.push({ id: i, name: i18n.t('lbl.installment', { installment: i }) })
  }
  return installmentCount
}