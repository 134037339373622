import _ from 'lodash'

export default {
  //#region [GET]
  getAll: (state, { payload }) => {
    let data = {};
    const { key } = payload;

    if (key) {
      if (key === "gridSettings" && state && state.gridSettings && state.gridSettings.list && state.gridSettings.list.initialized) {
        return data;
      }
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.list = { data: [], loading: true, count: null, error: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getAllSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.list = {
        data: payload.isNewODataStructure ? payload.data.value : payload.data,
        loading: false,
        count: payload.isNewODataStructure ? payload.data['@odata.count'] : null,
        error: null,
        statusCode: payload.statusCode
      }
      if (payload.key === 'gridSettings') data.list.initialized = true;
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getAllFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.list = {
        data: [],
        loading: false,
        count: null,
        error: payload.error,
        statusCode: payload.statusCode
      }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  get: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      if (data) data.single = { data: null, loading: true, error: null, saving: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      if (data) data.single = { data: payload.data, loading: false, error: null, saving: null, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      if (data) data.single = { data: null, loading: false, error: payload.error, saving: null, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getFilter: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: [], loading: true, error: null }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getFilterSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: payload.isNewODataStructure ? payload.data.value : payload.data, loading: false, error: null }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getFilterFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: [], loading: false, error: payload.error }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },
  getWithPost: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: true, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [POST]
  post: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  postSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  postFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.statusCode && payload.statusCode === 406 ? _.cloneDeep(_.get(state, keys)).single.data : null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [PUT]
  put: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  putSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  putFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.statusCode && payload.statusCode === 406 ? _.cloneDeep(_.get(state, keys)).single.data : null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [DELETE]
  delete: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  deleteSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  deleteFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [PATCH]
  patch: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: _.cloneDeep(_.get(state, keys)).single.data, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  patchSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  patchFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: _.cloneDeep(_.get(state, keys)).single.data, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (state, { key }) => {
    let data = {};
    if (key) {
      const keys = _.split(key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  clearExcelFormat: (state) => {
    let data = {};
    data = _.cloneDeep(_.get(state, "tenantExcelFormats"));
    data.list = { data: [], loading: true, count: null, error: null, statusCode: null }
    data = { tenantExcelFormats: data };
    return data;
  },


  setSelectedItems: (state, { payload }) => {
    let _dataTableSelectedItems = _.cloneDeep(state.dataTableSelectedItems);
    if (typeof payload === "string" || typeof payload === "number") {
      let item = _dataTableSelectedItems.find(x => x === payload);
      item ? _dataTableSelectedItems.splice(_dataTableSelectedItems.indexOf(payload), 1) : _dataTableSelectedItems.push(payload);
    }
    else if (Array.isArray(payload)) {
      let item = "";
      payload.forEach(x => {
        item = _dataTableSelectedItems.find(y => y === x);
        item ? _dataTableSelectedItems.splice(_dataTableSelectedItems.indexOf(x), 1) : _dataTableSelectedItems.push(x);
      })
    }
    else if (typeof payload === "boolean") {
      _dataTableSelectedItems = [];
    }
    const data = { dataTableSelectedItems: _dataTableSelectedItems }
    return data;
  },

  updateFilter: ({ currentFilter }, { payload }) => {
    let filter = currentFilter[payload.key] ? window._.cloneDeep(currentFilter[payload.key]) : {};
    if (payload.filter) filter.filter = payload.filter;
    if (payload.fields) filter.fields = payload.fields;
    filter.skip = payload.skip ? payload.skip : 0;
    filter.page = payload.page ? payload.page : 1;
    const data = { currentFilter: { ...currentFilter, [payload.key]: filter } }
    return data;
  },

  seenVersion: (state) => {
    const data = { ...state.data, hasSeenVersion: true };
    return data;
  },

  setDatatableInlineValue: ({ datatableInline }, { payload }) => {
    let data = _.cloneDeep(datatableInline);
    data.single = { ...data.single, data: { ...data.single.data, ...payload.data } }
    return { datatableInline: data };
  },

  clearDatatableInlineValue: ({ datatableInline }) => {
    let data = _.cloneDeep(datatableInline);
    data.single = { ...data.single, data: null }
    return { datatableInline: data };
  },


  setDatatableBulkValue: ({ datatableInline }, { payload, index }) => {
    let data = _.cloneDeep(datatableInline), lineData;
    let listData = data.list.data;

    const indexedData = listData.findIndex(x => x.index === index);
    const isExistData = indexedData > -1;

    lineData = data.list.data.find(x => x.index === index) || {};
    lineData = { ...lineData, ...payload.data }

    if (isExistData) listData[indexedData] = lineData;
    else listData.push(lineData);
    data.list = { ...data.list, data: listData }

    return { datatableInline: data };
  },

  clearDatatableBulkValues: ({ datatableInline }) => {
    let data = _.cloneDeep(datatableInline);
    data.list = { ...data.list, data: [] }
    return { datatableInline: data };
  },

  clearFilter: (state, { key }) => {
    let data = {};
    if (key) {
      data = { data: [], loading: false, error: null }
      data = { filter: { ...state.filter, [key]: { ...data } } }
    }
    return data;
  },
  //#endregion

}