// import TenantBank from "components/Modules/NetEkstre/TenantBanks/Detail"

const stateKeys = [
  "parameters",
  "paymentList",
  "creatingInstructions",
  "reportings",
  "orders",
  "orderDetails",
  "recipientAccounts",
]

const filterKeys = [
  "tenantBanks",
  "orderStatus",
  "invoiceStatus",
  "selectedItems",
  "orderResponse",
  "currencies",
  "recipientAccounts",
  "currentAccounts",
  "recipientAccounts"
]

//#region State Generating - SHOULD NOT NE CHANGED 
const state = { filter: {} }
stateKeys.forEach(key => {
  state[key] = {
    list: { data: [], loading: null, count: null, error: null, statusCode: null },
    single: { data: null, saving: null, loading: null, error: null, statusCode: null }
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], error: null, loading: null
  }
})
export default state;
//#endregion

// export default {
//   creatingInstructions: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//     count: null
//   },
//   instructionLists: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   orders: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   reportings: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   parameters: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   customerInfos: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   currencies: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   customers: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   tenantCurrencies: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   tenantBanks: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   orderStatus: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   invoiceStatus: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
// }