import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox } from 'antd';
import { Input, Select, Textarea } from 'components/UIComponents/NetbankaComponents';
import { PosraporParameterModel } from 'models/posrapor';
import { posraporServiceTypes } from 'helpers';
import actions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new PosraporParameterModel(),
      InitialState: new PosraporParameterModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.parameters.single.data && !state.data.id) {
      const data = new PosraporParameterModel({ ...props.parameters.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.parameters.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };


  componentDidMount() {
    const { endpoint, getParameters, tenantId } = this.props;
    const parameters = tenantId && { TenantId: tenantId };
    if (this.id) getParameters(this.id, parameters, endpoint);
    else this.setState({ loading: false });

    if (tenantId) utils.posrapor.getBanks({ TenantId: tenantId });
    // utils.posrapor.getBankFileTypes();
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };


  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData, endpoint, tenantId } = this.props;
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        const parameters = tenantId && { TenantId: tenantId }
        const _data = this.state.data;
        _data.port = parseInt(_data.port);
        saveData(_data, method, endpoint, parameters, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { parameters, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={parameters.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={parameters.single.loading} form="parameter" htmlType="submit" className="success #save" icon="save" loading={parameters.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      parameters.single.saving,
      parameters.single.loading
    )
  }

  render() {
    const { banks, dialogCloseRequest, parameters, bankFileTypes } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, data } = this.state;
    const { renderDialogButtons } = this;

    const bankFileTypesData = bankFileTypes.data;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...parameters, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="parameter">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('posrapor.bankName')}
                        options={banks.data}
                        optVal="eftCode"
                        optName={(bankData) => utils.posrapor.getBankName({ bankData })}
                        onChange={(e) => this.update('bankEftCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                {
                  this.id &&
                  <Col span={12}>
                    <Form.Item>
                      <Checkbox checked={data.posRaporAccountIsActive} onChange={(e) => this.update('posRaporAccountIsActive', e.target.checked)} /> {i18n.t('posrapor.posRaporAccountIsActive')}
                    </Form.Item>
                  </Col>
                }
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('protocolType', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.protocolType
                    })(
                      <Select
                        className="#type"
                        label={i18n.t('posrapor.serviceTypes')}
                        options={posraporServiceTypes}
                        optVal="id"
                        optName="name"
                        allowClear={false}
                        onChange={(e) => this.update('protocolType', e)}
                        lang="posrapor"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('path', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.path
                    })(
                      <Input className="#path" label={i18n.t('posrapor.path')} onChange={(e) => this.update('path', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('downloadPath', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.downloadPath
                    })(
                      <Input className="#downloadPath" label={i18n.t('posrapor.downloadPath')} onChange={(e) => this.update('downloadPath', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('filePattern', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.filePattern
                    })(
                      <Input className="#filePattern" label={i18n.t('posrapor.filePattern')} onChange={(e) => this.update('filePattern', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('downloadFilePattern', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.downloadFilePattern
                    })(
                      <Input className="#downloadFilePattern" label={i18n.t('posrapor.downloadFilePattern')} onChange={(e) => this.update('downloadFilePattern', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('host', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.host
                    })(
                      <Input className="#host" label={i18n.t('posrapor.host')} onChange={(e) => this.update('host', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('port', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.port
                    })(
                      <Input className="#port" label={i18n.t('posrapor.port')} onChange={(e) => this.update('port', e)} />
                    )}
                  </Form.Item>
                </Col>
                {
                  data.protocolType === posraporServiceTypes.find(x => x.name === "ws").id &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      {getFieldDecorator('url', {
                        rules: [{ required: true, message: i18n.t('msg.required') }],
                        initialValue: data.url
                      })(
                        <Input className="#url" label={i18n.t('posrapor.url')} onChange={(e) => this.update('url', e)} />
                      )}
                    </Form.Item>
                  </Col>
                }
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ftpUser', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ftpUser
                    })(
                      <Input className="#ftpUser" label={i18n.t('posrapor.ftpUser')} onChange={(e) => this.update('ftpUser', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.password
                    })(
                      <Input type="password" className="#password" label={i18n.t('posrapor.password')} onChange={(e) => this.update('password', e)} />
                    )}
                  </Form.Item>
                </Col>
                {/* BANK FILE TYPES */}
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankFileTypeId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankFileTypeId
                    })
                    (
                      <Select
                        className="#bank"
                        label={i18n.t('posrapor.fileFormat')}
                        options={bankFileTypesData}
                        optVal="id"
                        optName={(bankData) => utils.posrapor.getBankFileTypeName({ bankData })}
                        onChange={(e) => this.update('bankFileTypeId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('spaceMapping', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.spaceMapping
                    })(
                      <Textarea rows={4} className="#spaceMapping" label={i18n.t('posrapor.spaceMapping')} onChange={(e) => this.update('spaceMapping', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor }) => ({
  parameters: posrapor.parameters,
  banks: posrapor.filter.banks,
  bankFileTypes: posrapor.filter.bankFileTypes
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (id, parameters, endpoint) => dispatch(actions.get({ url: endpoint || endpoints.posrapor.parameter, parameters, key: 'parameters', id })),
  saveData: (data, method, endpoint, parameters, callback) => dispatch(actions[method]({ url: endpoint || endpoints.posrapor.parameter, parameters, key: 'parameters', data }, callback)),
  clearState: () => dispatch(actions.clearState("parameters"))
});
const ParameterForm = Form.create()(Parameter);
export default connect(mapStateToProps, mapDispatchToProps)(ParameterForm);
