import React, { useState } from 'react';
import { Modal } from "antd";

const Inline = ({ text, title, nextGen, headerTitle }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className={`agreement-viewer inline ${nextGen ? 'nextGen' : ''}`}>
      <label onClick={() => setVisible(true)}>{title}</label>
      <Modal
        {...{
          title: headerTitle || title,
          visible,
          onCancel: () => setVisible(false),
          footer: null,
          width: "40%",
          className: "agreement-viewer__modal",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Modal>
    </div>
  )
}

export default Inline;