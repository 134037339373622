import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { DataTable, Filter, ActionButtons } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { defaultFilters, baseUrls } from 'lib/constants';
import utils from 'lib';
import Role from './Detail';

class Roles extends Component {
  state = {
    loading: false,
    filter: defaultFilters.common.Roles(),
    currentBank: null
  };

  componentDidMount() {
    this.getRoles();
    utils.common.getTenantBanks();
    utils.common.getBankAccounts();
    utils.common.checkAccessToUpdateOrDelete('Roles')
  };

  getRoles = () => {
    let { filter } = this.state;
    this.props.getRoles(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getRoles();
    });
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getRoles();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getRoles());
  }

  onSelect = (value) => {
    this.setState({ currentBank: value });
  };

  render() {
    const { currentBank } = this.state;
    const { roles, tenantBanks, bankAccounts } = this.props;
    const _currentBank = tenantBanks.data && tenantBanks.data.length && currentBank ? currentBank.map(x => tenantBanks.data.find(y => y.id === x)) : null;
    const _accounts = bankAccounts.data ? (_currentBank && _currentBank.length) ? bankAccounts.data.filter(x => _currentBank.find(y => y.bankEftCode === x.bankEftCode)) : bankAccounts.data : [];

    const filters = [
      {
        label: i18n.t('lbl.bank'),
        options: tenantBanks.data,
        type: 'select',
        value: 'id',
        name: (bankData) => utils.common.getBankName({ bankData }),
        keys: ['RoleTenantBanks'],
        any: 'TenantBankId',
        showAll: true,
        callback: this.onSelect,
        clear: ['1'],
        uniqueKey: '#bank',
        multiple: true,
        guid: true
      },
      {
        label: i18n.t('lbl.accountName'),
        search: true,
        type: 'select',
        options: _accounts,
        showSearch: true,
        showAll: true,
        value: 'id',
        name: 'name',
        keys: ['RoleBankAccounts'],
        any: 'BankAccountId',
        uniqueKey: '#accountName',
        multiple: true,
        guid: true
      },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="Roles" editUrl item={row} hasDelete getDatas={this.getRoles} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.name'), key: 'Name' },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          onPageChange={this.onPageChange}
          access="Roles"
          newButton="openDialog"
          history={this.props.history}
          onSort={this.onSort}
          columns={columns}
          data={roles.list.data}
          count={roles.list.count}
          loading={roles.list.loading}
          title={i18n.t('route.common.roles')}
          Component={Role}
          dialogTitle={i18n.t('lbl.role')}
          getData={this.getRoles}
          deleteOptions={{
            stateKey: "roles",
            url: "Roles",
            baseUrl: baseUrls.tenant
          }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  roles: common.roles,
  tenantBanks: common.filter.tenantBanks,
  bankAccounts: common.filter.bankAccounts,
});
const mapDispatchToProps = (dispatch) => ({
  getRoles: (filter) => dispatch(actions.getAll({ url: endpoints.tenant.roles, key: 'roles', filter, isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Roles);