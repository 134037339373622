import React, { Component } from 'react';
import { Button, Modal, Icon, notification } from 'antd';
import actions from 'store/actions/netekstre';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import _ from 'lodash'
import i18n from 'plugins/i18n';
import utils from 'lib';

class ConfirmPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      data: {
        transactionIdList: []
      },
      loading: false,
    }
  };


  save = () => {
    let { data } = this.state;
    let { selectedItems, selectAll } = this.props;
    let filter = _.cloneDeep(this.props.filter);
    filter.and ? filter.and.push({ transferStatusId: 6 }) : filter.and = [{ transferStatusId: 6 }];
    data.TransactionIdList = selectAll ? [] : selectedItems;
    this.setState({ loading: true });
    this.props.postModal(data.TransactionIdList, selectAll ? filter : null, (response) => {
      if (response) {
        this.setState({ loading: false, modalVisible: false });
        utils.common.clearSelectedItems();
        this.props.onStartApprove(false);
      }
      else {
        this.setState({ loading: false, modalVisible: false });
        notification.error({ message: i18n.t('msg.unknownError') });
      }
    });
  }


  render() {
    const { modalVisible, loading } = this.state;
    const { selectedItems, accessRights, selectAll, disabled } = this.props;
    return (
      <React.Fragment>
        {
          accessRights.find(x => x.endPoint === 'ApproveTransactionBulk' && x.method === 'PUT') &&
          <Button
            onClick={() => this.setState({ modalVisible: true })}
            disabled={(selectedItems.length === 0 && !selectAll) || disabled}
            className="pull-right mr-10 #confirmButton btn-collapse"
            type="default"
            size="small"
          >
            <Icon type="check" /> {i18n.t('btn.confirmation')}
          </Button>
        }
        <Modal
          title={i18n.t('lbl.confirmation')}
          visible={modalVisible}
          onOk={this.save}
          okButtonProps={{ className: '#modalOk', icon: 'save', loading }}
          cancelButtonProps={{ className: '#modalCancel', icon: 'close', disabled: loading }}
          okText={i18n.t('btn.confirm')}
          cancelText={i18n.t('btn.cancel')}
          onCancel={() => { this.setState({ modalVisible: false }) }}
        >
          {
            i18n.t('lbl.doYouConfirm')
          }
        </Modal>
      </React.Fragment>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ accessRights: auth.data.accessRights });
const mapDispatchToProps = (dispatch) => ({
  postModal: (data, filter, callback) => dispatch(actions.put({ url: endpoints.nte.bankAccountTransactionsApproveTransactionBulk, data, filter: { filter } }, callback))
})
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);