import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Tooltip, Modal } from 'antd';
import actions from 'store/actions/cek';
import { ActionButtons, DataTable, Instruction, FadeIn, CheckAccessRight, Filter } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters } from 'lib/constants';
import i18n from 'plugins/i18n';
import utils from "lib";
import _ from 'lodash';
import CreateCheque from "./Detail";
import { ChequeCreateOrSendOrderModel } from "models";

const persistentFilter = { OperationStatusId: { eq: 1 } }

class Cheques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.cek.Cheques(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      options: [],
      chequeSendingOrCreating: false,
    };
  };

  componentDidMount() {
    this.getCheques();
    utils.cek.getTenantBanks('Cheque');
    this.getChequeTotal();
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.options.length)
      return {
        options: _.cloneDeep(props.tenantBanks.data.map(x => {
          return { value: x.Code, label: x.Name, type: 'BankCode', isLeaf: false }
        }
        ))
      }
    return null;
  }

  getChequeTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll || !!this.id) {
      let filter = {};
      filter.filter = {
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }

      this.props.getChequeTotal(filter, response => {

        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(cheque =>
            _amount += cheque.Amount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
        else {
          this.setState({ totalsLoading: false })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  getCheques = callback => {
    let { filter } = this.state;
    this.setState({ totalsLoading: true })
    this.props.getCheques(filter, callback);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getCheques();
    });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getCheques(response => {
        this.getChequeTotal(response);
      });
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getCheques());
  }

  additionalButtons = () => {
    let buttons = [
      <div>
        <Icon type="plus" className="mr-5" />
        {i18n.t('lbl.downloadExampleFile')}
      </div>,
      <div>
        <Icon type="plus" className="mr-5" />
        {i18n.t('lbl.uploadChequeWithFile')}
      </div>
    ];

    return buttons;
  };

  callbackDelete = () => {
    this.getCheques(response => {
      this.getChequeTotal(response);
    });
  }

  loadData = selectedOptions => {
    let targetOption = {};
    targetOption = selectedOptions[selectedOptions.length - 1];

    if (targetOption.type === "BankCode") {
      targetOption.loading = true;
      const filter = { filter: { 'TenantBank/Bank/EftCode': targetOption.value, BranchNumber: { ne: null } }, }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.branchName'), type: 'BranchNumber', disabled: true },
          ...response.value.filter((k, i, j) => j.findIndex(t => (t.id === k.id)) === i).map(x => ({ label: `${x.BranchName} (${x.BranchNumber})`, value: x.BranchNumber, type: 'BranchNumber', isLeaf: false }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }

    if (targetOption.type === "BranchNumber") {
      targetOption.loading = true;
      const filter = {
        filter: {
          'TenantBank/Bank/EftCode': selectedOptions.find(x => x.type === "BankCode").value,
          BranchNumber: selectedOptions.find(x => x.type === "BranchNumber").value,
        },
      }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.accountNumber'), type: 'AccountNumber', disabled: true },
          ...response.value.map(x => ({ label: `${x.AccountNumber} (${x.CurrencyCode})`, value: x.AccountNumber, type: 'AccountNumber' }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }
  }

  orderOperation = (data, method) => {
    const bankData = this.props.bankAccounts.data.find(x => x.AccountNumber === data.BankAccount[2])
    let _data = {
      ChequesIdList: this.props.selectedItems,
      Description: data.Description,
      Amount: data.Amount,
      TenantPaymentAccountId: bankData.Id,
    }
    this.setState({ chequeSendingOrCreating: true })
    _data = new ChequeCreateOrSendOrderModel(_data)

    this.props[method](_data, (response) => {
      this.setState({ chequeSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 || response.statusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: (response && response.Message) ? response.Message : status === 'success' ? i18n.t('msg.success') : i18n.t('msg.error'),
          content: status === 'success' ? i18n.t(`dbs.${method}Response`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.getCheques(response => {
              this.getChequeTotal(response);
              utils.common.clearSelectedItems();
            });
          }
        })
      }
    })
  }

  render() {
    const { cheques, tenantBanks } = this.props;
    const { options, chequeSendingOrCreating, totalsLoading, totalAmount, totalCount } = this.state;
    const _options = [{ value: Math.random(), label: i18n.t('lbl.bank'), type: 'BankCode', disabled: true }, ...options];
    const filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.cek.getBankName({ bankData }), keys: ['BankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.startDate'), type: 'date', date: 'ge', keys: ['ChequeDate'], uniqueKey: '#startDate', disabled: '1', allowClear: false },
      { label: i18n.t('lbl.endDate'), type: 'date', date: 'le', keys: ['ChequeDate'], uniqueKey: '#endDate', disabled: '1' },
      { label: i18n.t('lbl.minAmount'), type: 'number', operator: 'ge', keys: ['amount'], uniqueKey: '#minAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.maxAmount'), type: 'number', operator: 'le', keys: ['amount'], uniqueKey: '#maxAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.search'), type: 'input', placeholder: i18n.t('lbl.search'), keys: ['accountNumber', 'branchNumber', 'iban', 'branchName'], contains: true, uniqueKey: '#search', } // keyleri değiştir unutma!
    ]
    const instructions = [
      {
        label: i18n.t('lbl.bank/branch/account'),
        key: "BankAccount",
        type: "cascade",
        options: _options,
        required: true,
        loadData: this.loadData,
      },
      { label: i18n.t('lbl.description'), key: 'Description', type: 'input' },
      { label: i18n.t('lbl.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('lbl.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: (data) => this.orderOperation(data, 'createPayroll'), disabled: (cheques.list.count === 0 || chequeSendingOrCreating), access: 'PayRoll.Create' },
      { label: i18n.t('btn.sendOrder'), type: 'button', className: 'success', onClick: (data) => this.orderOperation(data, 'sendPayroll'), disabled: (cheques.list.count === 0 || chequeSendingOrCreating), access: 'PayRoll.Send' },
    ]
    const columns = [
      { render: (row) => <ActionButtons url="Cheque" editUrl item={row} hasDelete getDatas={this.callbackDelete} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankCode'), key: 'BankCode', render: (data) => utils.cek.getBankName({ data }) },
      { label: i18n.t('lbl.chequeUserNameSurname'), key: 'ChequeName' },
      { label: i18n.t('lbl.vknTckn'), key: 'ChequeUserVkn' },
      { label: i18n.t('lbl.chequeNumber'), key: 'ChequeCode' },
      { label: i18n.t('lbl.kesideCity'), key: 'City' },
      { label: i18n.t('lbl.kesideDate'), key: 'ChequeDate' },
      { label: i18n.t('lbl.description'), key: 'Description' },
      { label: i18n.t('lbl.amount'), key: 'Amount' },
    ];

    return (
      <div className="page-content" >
        <FadeIn>
          <CheckAccessRight {...{ ...cheques }}>
            <Filter filters={filters} onFilter={this.onFilter} />
            <Instruction {...{ instructions, loading: totalsLoading || chequeSendingOrCreating }} />
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              count={cheques.list.count}
              access="Cheque"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={cheques.list.data}
              loading={cheques.list.loading}
              title={i18n.t('lbl.createCheque')}
              newButton="openDialog"
              additionalButtons={this.additionalButtons()}
              getData={() => this.getCheques(this.getChequeTotal())}
              Component={CreateCheque}
              dialogTitle={i18n.t('lbl.Cheque')}
              checkbox
              onCheck={this.getChequeTotal}
              checkAll={cheques.list.data && cheques.list.data.length > 0}
              deleteOptions={{ stateKey: "cheques", url: "Cheque" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };

};

const mapStateToProps = ({ cek, netekstre, common }) => ({
  cheques: cek.cheques,
  tenantBanks: cek.filter.tenantBanks,
  bankAccounts: netekstre.filter.bankAccounts,
  selectedItems: common.dataTableSelectedItems
});
const mapDispatchToProps = (dispatch) => ({
  getCheques: (filter, callback) => dispatch(actions.getAll({ filter: { ...filter, filter: { ...filter.filter, ...persistentFilter } }, url: endpoints.cek.cheques, key: 'cheques' }, callback)),
  getChequeTotal: (filter, callback) => dispatch(actions.getAll({ filter: { ...filter, filter: { ...filter.filter, ...persistentFilter } }, url: endpoints.cek.cheques }, callback)),
  createPayroll: (data, callback) => dispatch(actions.post({ url: endpoints.cek.createPayroll, data, customNotification: true, options: { errors: { showNotification: false } } }, callback)),
  sendPayroll: (data, callback) => dispatch(actions.post({ url: endpoints.cek.sendPayroll, data, customNotification: true, options: { errors: { showNotification: false } } }, callback)),
  clearState: () => dispatch(actions.clearState("cheques"))
});
export default connect(mapStateToProps, mapDispatchToProps)(Cheques);