import React from 'react'
import { Layout, Row, Col } from "antd";
import { HeaderRight } from 'components/LayoutComponents';
import PageLock from 'components/LayoutComponents/addons/PageLock'
import Notifications from 'components/LayoutComponents/addons/Notifications'


const Header = () => {
  return (
    <Layout.Header className="header-modules mobile-hide">
      <Row className="header-row" type="flex" align="middle">
        <Col md={24} className="text-right full-height">
          <HeaderRight addons={[<PageLock />, <Notifications />]} />
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default Header