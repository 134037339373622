import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import posraporRoutes from 'plugins/routes/modules/posrapor';

class Reports extends Component {
  render() {
    const reports = posraporRoutes.routes.find(x => x.name === 'reporting');
    let { accessRights } = this.props;
    return (
      <React.Fragment>
        <Switch>
          {reports.routes.map((route, i) => {
            if ((!route.access) ||
              (route.access && typeof route.access === 'string' && accessRights.length > 0 && accessRights.find(x => x.endPoint === route.access)) ||
              (route.access && Array.isArray(route.access) && accessRights.length > 0 && accessRights.some(x => route.access.includes(x.endPoint)))
            )
              return <Route path={`${posraporRoutes.path}${reports.path}${route.path}`} render={(props) => <route.component {...props} accessRights={accessRights} />} key={`rd-${i}`} />
            else
              return null
          })}
          <Redirect from="/posrapor" to="/posrapor/dashboard" />
        </Switch>
      </React.Fragment>
    );
  };
};

export default Reports;