import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { Checkbox, Tooltip, Icon } from 'antd';
import { levels, statusTypes, defaultFilters, tenantLevels } from 'lib/constants';
import { DataTable, Filter, ActionButtons, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class Tenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.admin.Tenants(),
      currentPartnerIds: null,
      contractualType: null,
    };
    this.icons = ['', 'fas fa-handshake', 'fas fa-sitemap', 'fas fa-building'];
  };

  componentDidMount() {
    this.getTenants();
    this.props.getTenantsChild();
  };

  getTenants = () => {
    const { filter } = this.state;
    this.props.getTenants(filter);
  };

  onFilter = newFilter => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getTenants);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getTenants);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getTenants);
  };

  onChangeContractualType = type => this.setState({ contractualType: type })

  getAdditionalButtons = () => {
    const { history, data, accessRights } = this.props;
    const hasAccessRights = accessRights && accessRights.find(x => x.endPoint === "Tenants" && x.method === "POST")

    return data && hasAccessRights ?
      [
        data.level !== tenantLevels.tenant ?
          <div onClick={() => history.push({ pathname: '/admin/tenant', state: { level: tenantLevels.tenant } })}>
            <Icon type="plus" />
            {i18n.t('lbl.newCompany')}
          </div> : null,
        (data.level === tenantLevels.siteOwner || data.level === tenantLevels.partner) ?
          <div onClick={() => history.push({ pathname: '/admin/tenant', state: { level: tenantLevels.group } })}>
            <Icon type="plus" />
            {i18n.t('lbl.newGroupTenant')}
          </div> : null,
        data.level === tenantLevels.siteOwner ?
          <div onClick={() => history.push({ pathname: '/admin/tenant', state: { level: tenantLevels.partner } })}>
            <Icon type="plus" />
            {i18n.t('lbl.newPartner')}
          </div> : null
      ] : []
  }

  onTenantSelect = (item) => {
    const { onTenantSelect } = this.props;
    onTenantSelect(item);
    this.filterRef.setState({ activeKey: [] })
  }

  render() {
    const { tenants, tenantsFilter, data, datatableTitle, hasParent, datatableCollapsed } = this.props;
    const { currentPartnerIds } = this.state;
    const levelLabels = ['', ...levels.map(x => x.name)];
    const _levels = data.level === tenantLevels.siteOwner ? levels : levels.filter(x => x.id > tenantLevels.partner);
    const _partnerTenants = tenantsFilter.data ? tenantsFilter.data.filter(x => x.level === tenantLevels.partner) : [];
    const _groupTenants = tenantsFilter.data ? (currentPartnerIds && currentPartnerIds.length) ? tenantsFilter.data.filter(x => x.level === tenantLevels.group && currentPartnerIds.find(y => y === x.parentId)) : tenantsFilter.data.filter(x => x.level === tenantLevels.group) : [];

    const filters = [
      { label: i18n.t('lbl.companyNameCode'), type: 'input', keys: ['name', 'code'], contains: true, uniqueKey: '#name' },
      data.level === tenantLevels.siteOwner ? { label: i18n.t('lbl.partnerFirm'), type: 'select', options: _partnerTenants, value: 'id', name: 'name', keys: ['partnerTenantId', 'id'], uniqueKey: '#partner', multiple: true, callback: (e) => this.setState({ currentPartnerIds: e }), callbackTimeout: 10, guid: true } : null,
      (data.level === tenantLevels.siteOwner || data.level === tenantLevels.partner) ? { label: i18n.t('lbl.groupFirm'), type: 'select', options: _groupTenants, value: 'id', name: 'name', keys: ['groupTenantId', 'id'], uniqueKey: '#group', guid: true, multiple: true } : null,
      { label: i18n.t('lbl.erp'), type: 'select', options: [{ id: 'true', name: i18n.t('lbl.true') }, { id: 'false', name: i18n.t('lbl.false') }], value: 'id', name: 'name', keys: ['canUseErpService'], uniqueKey: '#erp', boolean: true },
      { label: i18n.t('lbl.mobile'), type: 'select', options: [{ id: 'true', name: i18n.t('lbl.true') }, { id: 'false', name: i18n.t('lbl.false') }], value: 'id', name: 'name', keys: ['canUseMobile'], uniqueKey: '#mobil', boolean: true },
      { label: i18n.t('lbl.portal'), type: 'select', options: [{ id: 'true', name: i18n.t('lbl.true') }, { id: 'false', name: i18n.t('lbl.false') }], value: 'id', name: 'name', keys: ['canUsePortal'], uniqueKey: '#portal', boolean: true },
      data.level < tenantLevels.group ? { label: i18n.t('lbl.firmType'), type: 'select', options: _levels, lang: 'lbl', value: 'id', name: 'name', keys: ['level'], uniqueKey: '#level' } : null,
      { label: i18n.t('lbl.licenceEndDateStart'), type: 'date', date: 'ge', keys: ['licenceStatus/licenceEndDate'], uniqueKey: '#licenceEndDateStart', col: { lg: 3 } },
      { label: i18n.t('lbl.licenceEndDateEnd'), type: 'date', date: 'le', keys: ['licenceStatus/licenceEndDate'], uniqueKey: '#licenceEndDateEnd', col: { lg: 3 } },
      // { label: i18n.t('lbl.contractualType'), type: 'select', options: contractualTypes, lang: 'lbl', value: 'id', name: 'name', keys: ['contractualType'], uniqueKey: '#contractualType', callback: this.onChangeContractualType, callbackTimeout: 10 },
      // { label: i18n.t('lbl.workType'), type: 'select', options: workTypes, lang: 'lbl', value: 'id', name: 'name', keys: ['dataType'], uniqueKey: '#workType' },
      { label: i18n.t('lbl.status'), type: 'select', options: statusTypes, lang: 'lbl', value: 'id', name: 'name', keys: ['status'], uniqueKey: '#status' },
    ];

    const columns = [
      { render: (row) => <ActionButtons url="Tenants" editUrl={!hasParent && 'admin/tenant'} item={row} getDatas={this.getTenants} />, toggle: false, sort: false, notIncluded: true, key: 'id' },
      { label: '', key: 'level', render: (row) => <Tooltip title={i18n.t(`lbl.${levelLabels[row.level]}`)}><i className={this.icons[row.level]} /></Tooltip> },
      { label: i18n.t('lbl.companyName'), key: 'name' },
      { label: i18n.t('lbl.companyCode'), key: 'code' },
      { label: i18n.t('lbl.packageName'), key: 'packageName' },
      (data.level === tenantLevels.siteOwner || data.level === tenantLevels.partner) ? { label: i18n.t('lbl.groupTenant'), key: 'groupTenant' } : null,
      data.level === tenantLevels.siteOwner ? { label: i18n.t('lbl.partnerTenant'), key: 'partnerTenant' } : null,
      { label: i18n.t('lbl.status'), key: 'status', render: (row) => row.status === 1 ? i18n.t('lbl.active') : i18n.t('lbl.inactive') },
      { label: i18n.t('lbl.erp'), key: 'canUseErpService', render: (row) => <Checkbox checked={row.canUseErpService} /> },
      { label: i18n.t('lbl.mobile'), key: 'canUseMobile', render: (row) => <Checkbox checked={row.canUseMobile} /> },
      { label: i18n.t('lbl.portal'), key: 'canUsePortal', render: (row) => <Checkbox checked={row.canUsePortal} /> },
    ];

    return (
      <FadeIn className="page-content">
        <CheckAccessRight {...{ ...tenants }}>
          <Filter filters={filters.filter(x => x)} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            access="Tenants"
            history={this.props.history}
            excel={{ url: "ExportTenants", module: "tenant" }}
            additionalButtons={this.getAdditionalButtons()}
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={tenants.list.data}
            loading={tenants.list.loading}
            title={datatableTitle || i18n.t('route.admin.tenants')}
            getData={this.getTenants}
            count={tenants.list.count}
            usePage={hasParent ? null : { execute: (item) => ({ pathname: `/admin/tenant/${item.id || item.Id}`, state: { level: item.level } }) }}
            onRowClick={hasParent ? this.onTenantSelect : null}
            onHeaderClick={hasParent ? this.onTenantSelect : null}
            deleteOptions={{ stateKey: "tenants" }}
            collapsed={datatableCollapsed}
            hasParent={hasParent}
            reduxFilterKey={hasParent && "admin/tenants"}
            clickable
          />
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ auth, admin }) => ({
  data: auth.data,
  tenants: admin.tenants,
  tenantsFilter: admin.filter.tenants,
});
const mapDispatchToProps = (dispatch) => ({
  getTenants: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tenant.tenants, key: 'tenants' })),
  getTenantsChild: () => dispatch(actions.getFilter({ url: endpoints.tenant.tenantsChild, key: 'tenants' })),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Tenants);