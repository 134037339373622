
import React, { useEffect, useState, useRef } from 'react'
import { Table, Button, Modal, Icon } from 'antd';
import i18n from 'plugins/i18n';
import endpoints from 'config/endpoints';
import actions from 'store/redux/netekstre';
import { connect } from 'react-redux';
import { httpMethods } from 'lib/constants';
import ReactSVG from 'react-svg';
import Details from 'assets/img/icons/details.svg';

const InstallmentPlan = ({ products, bank, amount, banks, getBankPlan }) => {
  const [plans, setPlans] = useState([]);
  const [visible, setVisible] = useState(false);
  const getTimeOut = useRef(null);

  useEffect(() => {
    const data = { products, amount: parseFloat(amount), bank };
    if (data.amount >= 1000 && data.bank) {
      clearTimeout(getTimeOut.current);
      getTimeOut.current = setTimeout(() => {
        getBankPlan(data, response => { if (!(response.errors && response.errors.length > 0)) mapPlans(response) });
      }, 1000);
    }
  }, [getBankPlan, amount, products, bank])

  const mapPlans = (response = []) => {
    let _plans = [];
    response &&
      response.map(item =>
        _plans.push(
          {
            installment: item.maturity,
            maturity: `${item.maturity} ${i18n.t('lbl.month')}`,
            installmentAmount: `${item.installmentAmount.toFixed(2)} ₺`,
            totalInstallmentAmount: `${item.totalInstallmentAmount.toFixed(2)} ₺`,
            bankRate: `%${item.bankRate}`,
          }
        )
      )
    setPlans(_plans)
  }

  const bankData = banks.data ? banks.data.find(x => x.id === bank) : null;
  const reviewInstallments = [3, 6, 9, 12, 15, 24, 36];
  const tableData = {
    columns: [
      { title: i18n.t('lbl.maturity'), dataIndex: 'maturity', key: 'maturity' },
      { title: i18n.t('lbl.installmentAmount'), dataIndex: 'installmentAmount', key: 'installmentAmount' },
      { title: i18n.t('lbl.totalInstallmentAmount'), dataIndex: 'totalInstallmentAmount', key: 'totalInstallmentAmount' },
    ],
    detailColumns: [
      { title: i18n.t('lbl.maturity'), dataIndex: 'maturity', key: 'maturity' },
      { title: i18n.t('lbl.installmentAmount'), dataIndex: 'installmentAmount', key: 'installmentAmount' },
      { title: i18n.t('lbl.totalInstallmentAmount'), dataIndex: 'totalInstallmentAmount', key: 'totalInstallmentAmount' },
      { title: i18n.t('lbl.maturityRate'), dataIndex: 'bankRate', key: 'bankRate' },
    ]
  }

  return (
    bankData ?
      <div className="installment-plan__wrapper">
        <div className="table-content">
          <div className="installment-header" style={{ backgroundColor: bankData.colorCode }}>
            <img src={bankData.logoMedium} alt={bankData.name} />
          </div>
          <Table
            loading={{ indicator: <Icon type="loading" />, spinning: plans.length === 0 }}
            rowKey="maturity"
            dataSource={plans.filter(x => reviewInstallments.find(y => y === x.installment))}
            pagination={false}
            columns={tableData.columns}
          />
        </div>
        <Button onClick={() => setVisible(true)}>
          <ReactSVG src={Details} /> {i18n.t('btn.detail')}
        </Button>
        <Modal
          {...{
            visible,
            centered: true,
            footer: null,
            className: 'installment-plan__modal',
            onCancel: () => setVisible(false),
            closeIcon: <span />,
            title: <div className="installment-header" style={{ backgroundColor: bankData.colorCode }}> <img src={bankData.logoMedium} alt={bankData.name} /></div>
          }}
        >
          <Table
            className="table-content"
            loading={{ indicator: <Icon type="loading" />, spinning: plans.length === 0 }}
            rowKey="maturity"
            dataSource={plans}
            pagination={false}
            columns={tableData.detailColumns}
            scroll={{ y: 500 }}
          />
        </Modal>
      </div>
      :
      null
  )
}

const mapStateToProps = ({ global }) => ({
  banks: global.filter.krediBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getBankPlan: (data, callback) => dispatch(actions.globalRequest({ url: endpoints.kredi.getBankPlan, data }, httpMethods.post, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InstallmentPlan);
