export class PosraporParameterModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.ftpUser = data && data.ftpUser ? data.ftpUser : null;
    this.port = data && data.port ? data.port : null;
    this.password = data && data.password ? data.password : null;
    this.protocolType = data && data.protocolType ? data.protocolType : null;
    this.host = data && data.host ? data.host : null;
    this.path = data && data.path ? data.path : null;
    this.filePattern = data && data.filePattern ? data.filePattern : null;
    this.downloadFilePattern = data && data.downloadFilePattern ? data.downloadFilePattern : null;
    this.downloadPath = data && data.downloadPath ? data.downloadPath : null;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : null;
    this.spaceMapping = data && data.spaceMapping ? data.spaceMapping : null;
    this.posRaporAccountId = data && data.posRaporAccountId ? data.posRaporAccountId : undefined;
    this.posRaporAccountIsActive = data && data.posRaporAccountIsActive ? data.posRaporAccountIsActive : true;
    this.bankFileTypeId = data && data.bankFileTypeId ? data.bankFileTypeId : null;
  };
};

export class ReportLineModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.merchantId = data && data.merchantId ? data.merchantId : undefined;
    this.terminalId = data && data.terminalId ? data.terminalId : undefined;
    this.transactionId = data && data.transactionId ? data.transactionId : undefined;
    this.provisionCode = data && data.provisionCode ? data.provisionCode : undefined;
    this.transactionDate = data && data.transactionDate ? data.transactionDate : undefined;
    this.pureAmount = data && data.pureAmount ? data.pureAmount : 0;
    this.netAmount = data && data.netAmount ? data.netAmount : 0;
    this.installmentCount = data && data.installmentCount ? data.installmentCount : 0;
    this.serviceCommisionRate = data && data.serviceCommisionRate ? data.serviceCommisionRate : 0;
    this.commisionAmount = data && data.commisionAmount ? data.commisionAmount : 0;
    this.posId = data && data.posId ? data.posId : undefined;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : undefined;
    this.cardNumber = data && data.cardNumber ? data.cardNumber : null;
    this.authCode = data && data.authCode ? data.authCode : null;
    this.commitDate = data && data.commitDate ? data.commitDate : null;
    this.companyName = data && data.companyName ? data.companyName : null
  };
};

export class ReportLineDetailModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.merchantId = data && data.merchantId ? data.merchantId : undefined;
    this.terminalId = data && data.terminalId ? data.terminalId : undefined;
    this.provisionCode = data && data.provisionCode ? data.provisionCode : undefined;
    this.transactionDate = data && data.transactionDate ? data.transactionDate : undefined;
    this.pureAmount = data && data.pureAmount ? data.pureAmount : 0;
    this.netAmount = data && data.netAmount ? data.netAmount : 0;
    this.installmentNumber = data && data.installmentNumber ? data.installmentNumber : 0;
    this.commisionAmount = data && data.commisionAmount ? data.commisionAmount : 0;
    this.serviceCommisionAmount = data && data.serviceCommisionAmount ? data.serviceCommisionAmount : 0;
    this.serviceCommisionRate = data && data.serviceCommisionRate ? data.serviceCommisionRate : 0;
    this.referenceNumber = data && data.referenceNumber ? data.referenceNumber : undefined;
    this.bankName = data && data.bankName ? data.bankName : undefined;
    this.authCode = data && data.authCode ? data.authCode : null;
    this.commitDate = data && data.commitDate ? data.commitDate : null;
    this.companyName = data && data.companyName ? data.companyName : null
  };
};

export class PosListModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.merchantId = data && data.merchantId ? data.merchantId : undefined;
    this.terminalId = data && data.terminalId ? data.terminalId : undefined;
    this.branch = data && data.branch ? data.branch : null;
    this.accountNumber = data && data.accountNumber ? data.accountNumber : null;
    this.currencyType = data && data.currencyType ? data.currencyType : null;
    this.iban = data && data.iban ? data.iban : null;
    this.virtualPosId = data && data.virtualPosId ? data.virtualPosId : null;
    this.erpPaymentSetCode = data && data.erpPaymentSetCode ? data.erpPaymentSetCode : null;
    this.erpPosCode = data && data.erpPosCode ? data.erpPosCode : null;
    this.erpTenantCode = data && data.erpTenantCode ? data.erpTenantCode : null;
    this.posRaporAccountName = data && data.posRaporAccountName ? data.posRaporAccountName : null;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : null
    this.posType = data && data.posType ? data.posType : undefined
    this.vendorType = data && data.vendorType ? data.vendorType : undefined
    this.currentAccountCode = data && data.currentAccountCode ? data.currentAccountCode : null
    this.companyName = data && data.companyName ? data.companyName : null
  };
};

export class BankFileTypeModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.bankId = data && data.bankId ? data.bankId : undefined;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : "";
    this.description = data && data.description ? data.description : "";
    this.headerColumns = data && data.headerColumns ? data.headerColumns : "";
    this.dynamicPropertyHeader = data && data.dynamicPropertyHeader ? data.dynamicPropertyHeader : "";
  };
};

export class PosraporAccountModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.isActive = data && data.isActive ? data.isActive : true;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : undefined;
    this.name = data && data.name ? data.name : undefined;
  };
};



