import InstallmentTable from "./InstallmentTable";
import InstallmentBox from './InstallmentBox';
import InstallmentInfoBanner from './InstallmentInfoBanner';
import InstallmentPlan from './InstallmentPlan';

export default class Installments {
  static InstallmentTable = InstallmentTable;
  static InstallmentBox = InstallmentBox;
  static InstallmentInfoBanner = InstallmentInfoBanner;
  static InstallmentPlan = InstallmentPlan;
}