import utils from 'lib';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bar, Doughnut } from 'react-chartjs-2';
import { chartOptions } from 'lib/constants';
import { Loading } from 'components/UIComponents';
import { Card, Row, Col, Radio } from 'antd';
import _ from 'lodash';
import { formatChartData, convertFieldsToCamelize } from 'helpers';
import i18n from 'plugins/i18n';
import { chartTypes } from 'models';
const actions = {};

const unknownType = "Belirsiz";

class PosraporCardTypes extends Component {
  state = {
    dataIndex: undefined,
    hiddenNames: [],
    data: null,
    wait: this.props.getWithQueue,
    Day: this.props.range?.defaultSelected
  }

  componentDidUpdate(prevProps) {
    if (this.props.currencyCode !== prevProps.currencyCode)
      this.getChartData()
  };

  componentDidMount() {
    if (!this.props.getWithQueue)
      this.getChartData();
  }

  getCharts = (e) => {
    this.setState({ Day: parseInt(e.target.value) }, () => {
      this.getChartData();
    });
  }

  getChartData = async () => {
    const { currencyCode, currentModule } = this.props;
    const queryPayload = { currencyCode };
    if (this.state.Day)
      queryPayload.Day = this.state.Day;
    const args = [currentModule, { currencyCode }, queryPayload];

    return new Promise(async resolve => {
      this.setState({ wait: false });
      utils.posrapor.getCreditCardTypes();
      if (!actions[currentModule]) {
        const _module = await import(`store/actions/${currentModule}`);
        actions[currentModule] = _module.default;
        this.props.getChartData(...args, resolve);
      }
      else {
        this.props.getChartData(...args, resolve);
      }
    })
  }

  updateDataset = name => this.setState({ hiddenNames: _.xor(this.state.hiddenNames, [name]) })

  getCreditCardTypes = ({ data }) => {
    const { creditCardTypes, auth } = this.props;
    const { hiddenNames, wait } = this.state;
    const creditCardTypesData = _.cloneDeep(creditCardTypes.data)
    let totalData = data?.datasets?.filter(x => !hiddenNames.includes(x.label)).reduce((a, b) => a + b.data.reduce((x, y) => x + y, 0), 0);

    if (creditCardTypesData.length) creditCardTypesData.push(creditCardTypesData.splice(creditCardTypesData.indexOf(creditCardTypesData.find(x => x.name === unknownType)), 1)[0])
    return (

      auth.data.accessRights.find(x => x.endPoint === 'BankDailyReportLineCreditCardType' && x.method === 'GET') ?
        <div className="credit-card-types__wrapper">
          {
            creditCardTypes.loading || wait
              ? <Loading size="sm" />
              : creditCardTypesData.map((x, i) => {
                const cardData = data?.datasets?.find(y => y.label === x.name);
                const cardTotalData = !hiddenNames.includes(cardData?.label) ? cardData?.data?.reduce((a, b) => a + b, 0) : 0;
                if (totalData === 0) totalData = 100;

                return (
                  <React.Fragment key={i}>
                    <Row gutter={20}>
                      <Col xs={24}>
                        <Card key={x.id} onClick={() => this.updateDataset(x.name)} className={`${hiddenNames.includes(x.name) ? 'hidden-card-type' : ''}`}>
                          <div id={`legend-${i}-item`} className="legend-item">
                            <div className="card-type">
                              {
                                x.name === unknownType
                                  ? <p className="card-meta">{i18n.t('lbl.unknown')}</p>
                                  : <img className="card-type-logo" src={require(`assets/img/cardTypes/${x.name}.svg`)} alt={x.name} />
                              }
                            </div>
                            <div className="card-type-chart">
                              <Doughnut
                                data={{
                                  datasets: [{ data: [cardTotalData, totalData - cardTotalData], backgroundColor: [cardData?.baseColor, cardData?.baseColor + '33'], }]
                                }}
                                options={{
                                  responsive: true,
                                  legend: { display: false },
                                  tooltips: { enabled: false },
                                  hover: { mode: null },
                                  plugins: { labels: false },
                                  elements: { arc: { borderWidth: 0 } },
                                  cutoutPercentage: 60
                                }} />
                            </div>
                          </div>
                          <span style={{ color: cardData?.baseColor }} className="hover-data">{((cardTotalData / totalData) * 100).toFixed(2)} %</span>
                        </Card >
                      </Col>
                    </Row>
                  </React.Fragment>
                )
              }
              )}
        </div >
        : null
    )
  }

  render() {
    const { data } = this.props.stackedBarChartData.single;
    const { dataIndex, hiddenNames, wait, Day } = this.state;
    const CreditCardTypes = this.getCreditCardTypes;
    const { stackedBarChartData, auth, accessRight, range, className, title } = this.props;
    const chartData = data ? _.cloneDeep(formatChartData({ series: data.series, labels: data.xAxis, options: { positive: true }, type: chartTypes.stackedBarChart })) : {};

    return (
      <div>
        <>
          <Row gutter={20} className={`${className}`}>
            <Col span={24} sm={12}>
              <h1 className="title">{title}</h1>
            </Col>
            <Col xs={24} sm={12}>
              <Radio.Group className="pull-right" buttonStyle="solid" value={parseInt(Day)} onChange={this.getCharts}>
                {
                  range?.ranges.map(x => (
                    <Radio.Button key={x.uniqueName} value={x.value} className={x.uniqueName}>{x.title}</Radio.Button>
                  ))
                }
              </Radio.Group>
            </Col>
            <Col span={24} className="mb-50">
              <CreditCardTypes data={chartData} />
              <Loading loading={stackedBarChartData.loading || wait} className="loading-card">
                <Card bodyStyle={{ height: '300px', paddingLeft: 0 }}>
                  {
                    (!chartData || chartData === {} || (accessRight && !auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method))) ?
                      <div className="no-access-icon">
                        <div className="no-access-right">
                          <i className="fas fa-exclamation-triangle"></i>
                          {i18n.t('msg.noAccessRightChart')}
                        </div>
                      </div>
                      :
                      <Bar
                        ref="creditCardTypeChart"
                        data={{ labels: chartData?.labels, datasets: chartData?.datasets?.filter(x => !hiddenNames.includes(x.label)) }}
                        options={chartOptions.stackedBarChart({ dataIndex, component: this })}
                      />
                  }
                </Card>
              </Loading>
            </Col>
          </Row>
        </>
      </div >
    )
  }
}

const mapStateToProps = (state, { toCamel }) => ({
  creditCardTypes: state.posrapor.filter.creditCardTypes,
  auth: state.auth,
  stackedBarChartData: toCamel ? convertFieldsToCamelize(state[state.common.currentModule].stackedBarChartData) : state[state.common.currentModule].stackedBarChartData,
  currentModule: state.common.currentModule,
});

const mapDispatchToProps = (dispatch, { endpoint, method }) => ({
  getChartData: (currentModule, data, parameters, callback) => dispatch(actions[currentModule][method]({ data, url: endpoint, key: 'stackedBarChartData', parameters }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PosraporCardTypes);
