import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { GeneralCategoriesModel } from 'models';
import { httpMethods, categoryTypesLookup } from 'lib/constants';
import actions from 'store/actions/admin';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class GeneralCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new GeneralCategoriesModel(),
      InitialState: new GeneralCategoriesModel(),
    };
    this.id = props.id;
  };

  componentDidMount() {
    if (this.id) this.props.getCategoryInitializer(this.id);
    else this.setState({ loading: false });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.generalCategories.single.data && !state.data.id) {
      const data = new GeneralCategoriesModel(props.generalCategories.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.generalCategories.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };


  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, (response) => {
          if (response) {
            this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
          }
        });
      }
    });
  };

  renderDialogButtons = checkAccessRight => {
    const { generalCategories, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRight
      ||
      <div>
        <Button disabled={generalCategories.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={generalCategories.single.loading} form="categoryInitializer" htmlType="submit" className="success #save" icon="save" loading={generalCategories.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      generalCategories.single.saving,
      generalCategories.single.loading
    )
  }


  render() {
    const { loading, data } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { generalCategories, dialogCloseRequest } = this.props;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...generalCategories, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="categoryInitializer">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#name" label={i18n.t('lbl.categoryName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('categoryCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.categoryCode
                    })(
                      <Input className="#categoryCode" label={i18n.t('lbl.code')} onChange={(e) => this.update('categoryCode', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('categoryType', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.categoryType
                    })(
                      <Select label={i18n.t('lbl.transactionCategoryType')} options={categoryTypesLookup} lang="lbl" optName="name" optVal="id" onChange={(e) => this.update('categoryType', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ admin }) => ({ generalCategories: admin.generalCategories });
const mapDispatchToProps = (dispatch) => ({
  getCategoryInitializer: (id, callback) => dispatch(actions.get({ url: endpoints.nte.generalCategories, key: 'generalCategories', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.generalCategories, key: 'generalCategories', data }, callback)),
  clearState: () => dispatch(actions.clearState("generalCategories")),
});
const GeneralCategoriesForm = Form.create()(GeneralCategories);
export default connect(mapStateToProps, mapDispatchToProps)(GeneralCategoriesForm);