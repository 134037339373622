import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import reducers from 'store/reducers/auth';
import state from 'store/states/auth';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  signIn: ['payload', 'callback'],
  signInSuccess: ['payload'],
  signInFailure: ['payload'],
  verifyTwoFactor: ['payload', 'callback'],
  verifyTwoFactorSuccess: ['payload'],
  verifyTwoFactorFailure: ['payload'],
  getAccount: ['callback'],
  getAccountSuccess: ['payload'],
  getAccountFailure: ['payload'],
  logout: ['payload'],
  logoutSuccess: ['payload'],
  logoutFailure: ['payload'],
  forgotPassword: ['payload', 'callback'],
  resetPassword: ['payload', 'callback'],
  resetPasswordSuccess: ['payload', 'callback'],
  resetPasswordFailure: ['payload', 'callback'],
  doBlockedUserToActive: ['payload'],
  doBlockedUserToActiveSuccess: ['payload'],
  doBlockedUserToActiveFailure: ['payload'],
  resendOtp: ['payload', 'callback'],
  resendOtpSuccess: ['payload', 'callback'],
  resendOtpFailure: ['payload', 'callback'],
});
export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


/* ------------- Reducers ------------- */
export const signIn = (state) => {
  const data = reducers.signIn(state)
  return state.merge(data);
};

export const signInSuccess = (state, action) => {
  const data = reducers.signInSuccess(action)
  return state.merge(data);
};

export const signInFailure = (state, action) => {
  const data = reducers.signInFailure(action)
  return state.merge(data);
};

export const verifyTwoFactor = (state) => {
  const data = reducers.verifyTwoFactor(state)
  return state.merge(data);
};

export const verifyTwoFactorSuccess = (state, action) => {
  const data = reducers.verifyTwoFactorSuccess(action)
  return state.merge(data);
};

export const verifyTwoFactorFailure = (state, action) => {
  const data = reducers.verifyTwoFactorFailure(action)
  return state.merge(data);
};

export const getAccount = (state) => {
  const data = reducers.getAccount()
  return state.merge(data);
};

export const getAccountSuccess = (state, action) => {
  const data = reducers.getAccountSuccess(action)
  return state.merge(data);
};

export const getAccountFailure = (state, action) => {
  const data = reducers.getAccountFailure(action)
  return state.merge(data);
};

export const logout = (state, action) => {
  const data = reducers.logout(state, action)
  return state.merge(data);
};

export const logoutSuccess = (state, action) => {
  const data = reducers.logoutSuccess(state, action)
  return state.merge(data);
};

export const logoutFailure = (state, action) => {
  const data = reducers.logoutFailure(state, action)
  return state.merge(data);
};

export const doBlockedUserToActive = (state) => {
  const data = reducers.doBlockedUserToActive(state)
  return state.merge(data);
};

export const doBlockedUserToActiveSuccess = (state) => {
  const data = reducers.doBlockedUserToActiveSuccess()
  return state.merge(data);
};

export const doBlockedUserToActiveFailure = (state, action) => {
  const data = reducers.doBlockedUserToActiveFailure(action)
  return state.merge(data);
};

export const resetPassword = (state) => {
  const data = reducers.resetPassword(state)
  return state.merge(data);
};

export const resetPasswordSuccess = (state) => {
  const data = reducers.resetPasswordSuccess()
  return state.merge(data);
};

export const resetPasswordFailure = (state, action) => {
  const data = reducers.resetPasswordFailure(action)
  return state.merge(data);
};
export const resendOtp = (state) => {
  const data = reducers.resendOtp(state)
  return state.merge(data);
};

export const resendOtpSuccess = (state) => {
  const data = reducers.resendOtpSuccess();
  return state.merge(data);
};

export const resendOtpFailure = (state, action) => {
  const data = reducers.resendOtpFailure(action)
  return state.merge(data);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN]: signIn,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,
  [Types.VERIFY_TWO_FACTOR]: verifyTwoFactor,
  [Types.VERIFY_TWO_FACTOR_SUCCESS]: verifyTwoFactorSuccess,
  [Types.VERIFY_TWO_FACTOR_FAILURE]: verifyTwoFactorFailure,
  [Types.GET_ACCOUNT]: getAccount,
  [Types.GET_ACCOUNT_SUCCESS]: getAccountSuccess,
  [Types.GET_ACCOUNT_FAILURE]: getAccountFailure,
  [Types.LOGOUT]: logout,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.DO_BLOCKED_USER_TO_ACTIVE]: doBlockedUserToActive,
  [Types.DO_BLOCKED_USER_TO_ACTIVE_SUCCESS]: doBlockedUserToActiveSuccess,
  [Types.DO_BLOCKED_USER_TO_ACTIVE_FAILURE]: doBlockedUserToActiveFailure,
  [Types.RESEND_OTP]: resendOtp,
  [Types.RESEND_OTP_SUCCESS]: resendOtpSuccess,
  [Types.RESEND_OTP_FAILURE]: resendOtpFailure,
});
