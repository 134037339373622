export class TosParameterModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.Description = data && data.Description ? data.Description : "";
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.TOSCode = data && data.TOSCode ? data.TOSCode : undefined;
    this.TOSCode2 = data && data.TOSCode2 ? data.TOSCode2 : undefined;
    this.ServiceTypeId = data && data.ServiceTypeId ? data.ServiceTypeId : undefined;
    this.Host = data && data.Host ? data.Host : undefined;
    this.Port = data && data.Port ? data.Port : undefined;
    this.UserName = data && data.UserName ? data.UserName : undefined;
    this.Password = data && data.Password ? data.Password : undefined;
    this.UploadFolder = data && data.UploadFolder ? data.UploadFolder : undefined;
    this.DownloadFolder = data && data.DownloadFolder ? data.DownloadFolder : undefined;
    this.Title = data && data.Title ? data.Title : undefined;
    this.BankTenantCode = data && data.BankTenantCode ? data.BankTenantCode : undefined;
    this.Url = data && data.Url ? data.Url : undefined;
  };
};
export class TosCustomerInfoModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.CurrentAccountId = data && data.CurrentAccountId ? data.CurrentAccountId : undefined;
    this.CustomerTosCode = data && data.CustomerTosCode ? data.CustomerTosCode : undefined;
    this.CustomerTosName = data && data.CustomerTosName ? data.CustomerTosName : undefined;
    this.Limit = data && data.Limit ? data.Limit : undefined;
    this.Risk = data && data.Risk ? data.Risk : undefined;
    this.InvoiceCount = data && data.InvoiceCount ? data.InvoiceCount : undefined;
    this.InvoiceSum = data && data.InvoiceSum ? data.InvoiceSum : undefined;
    this.Availability = data && data.Availability ? data.Availability : undefined;
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : undefined;
    this.Deleted = data && data.Deleted ? data.Deleted : undefined;
    this.Published = data && data.Published ? data.Published : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;

  };
};

export class CreatePaymentModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.OpponentAccountId = data && data.OpponentAccountId ? data.OpponentAccountId : undefined;
    this.Amount = data && data.Amount ? data.Amount : "";
    this.Description = data && data.Description ? data.Description : undefined;
  };
};

export class PaymentListsModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.PaymentDate = data && data.PaymentDate ? data.PaymentDate : undefined;
    this.OperationStatusId = data && data.OperationStatusId ? data.OperationStatusId : undefined;
    this.TotalCount = data && data.TotalCount ? data.TotalCount : undefined;
    this.TotalAmount = data && data.TotalAmount ? data.TotalAmount : undefined;
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : undefined;
    this.Description = data && data.Description ? data.Description : undefined;
  };
};

export class TosCreateOrSendOrderModel {
  constructor(data = null) {
    this.Description = data && data.Description ? data.Description : "";
    this.PaymentDate = data && data.PaymentDate ? data.PaymentDate : "";
    this.TenantPaymentAccountId = data && data.TenantPaymentAccountId ? data.TenantPaymentAccountId : undefined;
    this.OrderDetailIdList = data && data.OrderDetailIdList ? data.OrderDetailIdList : undefined;
    this.TosOrderId = data && data.TosOrderId ? data.TosOrderId : undefined;
  };
};

export class ReportingModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : '';
    this.Description = data && data.Description ? data.Description : '';
    this.Title = data && data.Title ? data.Title : '';
    this.Amount = data && data.Amount ? data.Amount : '';
    this.PaymentDate = data && data.PaymentDate ? data.PaymentDate : '';
    this.BankBranchCode = data && data.BankBranchCode ? data.BankBranchCode : null;
    this.AccountNumber = data && data.AccountNumber ? data.AccountNumber : null;
    this.ErpCode = data && data.ErpCode ? data.ErpCode : null;
    this.ErpDescription = data && data.ErpDescription ? data.ErpDescription : null;
    this.CanBeManuelTransferred = data && data.CanBeManuelTransferred ? data.CanBeManuelTransferred : null;
    this.CurrentAccountId = data && data.CurrentAccountId ? data.CurrentAccountId : undefined;
    this.TosOrderId = data && data.TosOrderId ? data.TosOrderId : '';
    this.BankStatusId = data && data.BankStatusId ? data.BankStatusId : null;
    this.OperationStatusId = data && data.OperationStatusId ? data.OperationStatusId : null;
    this.OperationStatusMessage = data && data.OperationStatusMessage ? data.OperationStatusMessage : undefined;
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : null;
    this.ErpStatusId = data && data.ErpStatusId ? data.ErpStatusId : null;
    this.DeletedUserId = data && data.DeletedUserId ? data.DeletedUserId : null;
    this.DeletedTime = data && data.DeletedTime ? data.DeletedTime : null;
    this.TenantId = data && data.TenantId ? data.TenantId : null;
    this.UpdatedUserId = data && data.UpdatedUserId ? data.UpdatedUserId : null;
    this.UpdatedTime = data && data.UpdatedTime ? data.UpdatedTime : undefined;
    this.InsertedUserId = data && data.InsertedUserId ? data.InsertedUserId : '';
    this.InsertedTime = data && data.InsertedTime ? data.InsertedTime : null;
  };
};

export class RecipientAccountModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : '';
    this.name = data && data.name ? data.name : '';
    this.accountNumber = data && data.accountNumber ? data.accountNumber : '';
    this.branchNumber = data && data.branchNumber ? data.branchNumber : '';
    this.branchName = data && data.branchName ? data.branchName : '';
    this.iban = data && data.iban ? data.iban : '';
    this.currencyCode = data && data.currencyCode ? data.currencyCode : 'TRY';
    this.currentAccountId = data && data.currentAccountId ? data.currentAccountId : undefined;
    
    this.erpCode = data && data.erpCode ? data.erpCode : '';
  };
};

