import React from 'react';
import i18n from 'plugins/i18n';
import { connect } from "react-redux";

const SlidingCurrencyItem = ({item, isEffectiveCurrencyCode}) => {
  const _buying = isEffectiveCurrencyCode ? item.effectiveBuying : item.buying;
  const _selling = isEffectiveCurrencyCode ? item.effectiveSelling : item.selling;
  return (
    <div className="sliding-currency-item">
      <div className={`currency-flag currency-flag-${item.from && item.from.toLowerCase()}`}></div>
      <span>{item.from}</span>
      <div className="currency-line">{i18n.t('lbl.buying')}: <span>{_buying.toFixed(4)}</span> - {i18n.t('lbl.selling')}: <span>{_selling.toFixed(4)}</span></div>
    </div>
  )
}

const mapStateToProps = ({ common }) => ({  ...common });
export default connect(mapStateToProps)(SlidingCurrencyItem);