import React from 'react';
import { Modal, Tag, Button } from 'antd';
import actions from 'store/actions/common';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class Version extends React.Component {
  state = { visible: false, version: [] }

  componentDidMount() {
    this.props.getVersion((response) =>
      this.setState({ version: response })
    )
  }

  versionOnClick = () => {
    let { hasSeenVersion, userId } = this.props;
    this.setState({ visible: true }, () => {
      if (!hasSeenVersion) {
        this.props.seenVersion(userId, () => {
          this.props.updateAuthData();
        });
      }
    });

  }

  render() {
    let { visible, version } = this.state;
    let { hasSeenVersion } = this.props;
    return (
      <div className="versioning">
        <span onClick={this.versionOnClick} className={`${hasSeenVersion ? '' : 'new'}`}>
        {version && version[0] && version[0].version && `v${version[0].version}`}
        </span>

        <Modal
          title={i18n.t('lbl.versioning', { version: version && version[0] && version[0].version })}
          visible={visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          width={'50%'}
        >
          {
            version && version[0] &&
            <>
              <Tag className="change-log grey">{version[0].releaseDate}</Tag>
              <ul>
                {
                  version[0].changes &&
                  version[0].changes.length > 0 &&
                  version[0].changes.map((change, j) => {
                    let typeClass = "";
                    switch (change.type) {
                      case 'Düzeltme': typeClass = 'bug'; break;
                      case 'Geliştirme': typeClass = 'improving'; break;
                      case 'Özellik': typeClass = 'feature'; break;
                      default: break;
                    }
                    return <li key={j} className="change-log-item">
                      <Tag className={`change-log ${typeClass}`}> {i18n.t(`lbl.${typeClass}`)} </Tag><span>{change.description}</span>
                    </li>
                  })
                }
                <Link to="/common/changelog">
                  <Button onClick={() => this.setState({ visible: false })} type="link">{i18n.t('btn.more')}</Button>
                </Link>
              </ul>
            </>
          }
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = ({ auth }) => ({
  hasSeenVersion: auth.data.hasSeenVersion,
  userId: auth.data.userId
});
const mapDispatchToProps = (dispatch) => ({
  getVersion: (callback) => dispatch(actions.getVersion(callback)),
  seenVersion: (userId, callback) => dispatch(actions.get({ url: endpoints.tenant.seenVersion, id: userId }, callback)),
  updateAuthData: () => dispatch(actions.seenVersion()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Version);