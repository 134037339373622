import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox } from 'antd';
import { Input, Select, InputPhone } from 'components/UIComponents/NetbankaComponents';
import { UserModel } from 'models';
import actions from 'store/actions/common';
import { compareFields } from 'helpers';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods, userRepresentativeTypeBoolean, userRepresentativeTypeInt } from 'lib/constants';


class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new UserModel(),
      InitialState: new UserModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.users.single.data && props.users.single.saving === null && !state.data.id) {
      const data = new UserModel(_.cloneDeep(props.users.single.data));
      data.userType = userRepresentativeTypeInt[data.userType];
      return { data: data, InitialState: data, loading: false }
    }
    if (props.dialogClosing && !props.users.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) {
      this.props.getUser(this.id)
    }
    else {
      this.props.getRoles((response) => this.setCallbackData('roles', 'roleId', response));
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  setCallbackData = (field, prop, values) => {
    const { data } = this.state;
    data[field] = values.map(x => { x[prop] = x.id; delete x.id; return x; });
    this.setState({ data });
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  updateData = (field, prop, e) => {
    const { data } = this.state;
    const _item = data[field].find(x => x[prop].toString() === e[0].toString())
    _item.checked = !_item.checked;
    this.setState({ data });
  };

  save = (e) => {
    const { data } = this.state;
    const { users, dialogCloseRequest } = this.props;
    const _data = _.cloneDeep(data);

    e.preventDefault();
    this.props.form.validateFields(error => {
      if (!error) {
        _data.roles = compareFields('roles', _data, this.id ? users : null);
        _data.userType = userRepresentativeTypeBoolean[_data.userType];
        const method = this.id ? httpMethods.put : httpMethods.post;
        this.props.saveData(_data, method, (response) => {
          if (response)
            this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    const { users, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={users.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={users.single.loading} form="users" htmlType="submit" className="success #save" icon="save" loading={users.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      users.single.saving,
      users.single.loading
    )
  }

  render() {
    const { users, dialogCloseRequest, modules } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, data } = this.state;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...users, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="users">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input label={i18n.t('lbl.name')} onChange={(e) => this.update('name', e)} className="#name" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('surname', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.surname
                    })(
                      <Input label={i18n.t('lbl.surname')} onChange={(e) => this.update('surname', e)} className="#surname" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: i18n.t('msg.required') },
                        { type: 'email', message: i18n.t('msg.emailInvalid') }
                      ],
                      initialValue: data.email
                    })(
                      <Input disabled={this.id} label={i18n.t('lbl.email')} onChange={(e) => this.update('email', e)} className="#email" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('phone', {
                      initialValue: data.phone
                    })(
                      <InputPhone label={i18n.t('lbl.phone')} onChange={(e) => this.update('phone', e)} className="#phone" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('mobilePhone', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.gsm
                    })(
                      <InputPhone label={i18n.t('lbl.mobilePhone')} mobile onChange={(e) => this.update('gsm', e)} className="#mobilePhone" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.description')} value={data.description} onChange={(e) => this.update('description', e)} className="#description" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('userRoles', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: this.id ? data.roles.filter(x => x.checked).map(x => x.roleId) : []
                    })(
                      <Select
                        mode="multiple"
                        className="#userRoles"
                        label={i18n.t('lbl.roles')}
                        options={data.roles}
                        optVal="roleId"
                        optName="name"
                        onChange={(e, diff) => this.updateData('roles', "roleId", diff)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('modules', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.modules
                    })(
                      <Select
                        mode="multiple"
                        className="#modules"
                        label={i18n.t('lbl.modules')}
                        options={modules.filter(x => x.name !== 'Ortak') || []}
                        optName="name"
                        optVal="name"
                        onChange={(e) => this.update('modules', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={9}>
                  <Form.Item>
                    <Checkbox className="#userType" checked={data.userType} onChange={(e) => this.update('userType', e.target.checked)}>{i18n.t('lbl.isRepresentative')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item>
                    <Checkbox className="#isActive" checked={data.isActive} onChange={(e) => this.update('isActive', e.target.checked)}>{i18n.t('lbl.isActive')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  modules: auth.data.modules,
  
  users: common.users,
  roles: common.filter.roles
});
const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(actions.get({ url: endpoints.tenant.users, key: 'users', id })),
  getRoles: (callback) => dispatch(actions.getFilter({ url: endpoints.lookups.roles, key: 'roles' }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tenant.users, key: 'users', data }, callback)),
  clearState: () => dispatch(actions.clearState("users"))
});
const UserForm = Form.create()(User);
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);