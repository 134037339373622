const stateKeys = [
  "generalCategories",
  "erpTypes",
  "typeCodes",
  "tenantBanks",
  "voucherTypes",
  "licenseStatusReports",
  "tenantLicenseStatusReports",
  "jobs",
  "generalRules",
  { tenants: ["definition", "moduleInfo", "accessRights", "licencePackages", "documents", "users"] },
  { packages: ["definitions", "modulePackages"] },
]

const filterKeys = [
  "movementCategories",
  "erpTypes",
  "typeCodes",
  "tenantBanks",
  "voucherTypes",
  "tenants",
  "canUseEnvironment",
  "accessCategories",
  "tenantPackages",
  "roles",
  "logTypes",
  "transferStatus",
  "typeCodes1",
  "typeCodes2",
  "erpTypes",
  "incomingCategories",
  "outgoingCategories",
  "regexFields",
  "bankAccounts",
  "tenantLicenseStatusReports",
  "jobs",
  "banks",
  "documentStatuses",
  "tenantStatuses",
  "modules",
  "tenantLevels",
]

//#region State Generating - SHOULD NOT BE CHANGED 
const getConstantStateData = () => ({
  list: { data: [], loading: null, count: null, error: null, statusCode: null },
  single: { data: null, saving: null, loading: null, error: null, statusCode: null }
})

const state = { filter: {} }
stateKeys.forEach(key => {
  if (typeof key === "string")
    state[key] = getConstantStateData();
  else {
    const [mainKey] = Object.keys(key);
    state[mainKey] = getConstantStateData();
    key[mainKey].forEach(innerKey => {
      state[mainKey] = {
        ...state[mainKey],
        [innerKey]: getConstantStateData()
      }
    })
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], enum: null, error: null, loading: null
  }
})
export default state;
//#endregion
