import React, { Component } from 'react';
// import anime from 'animejs';
import { Icon } from 'antd';

class GlobalLoading extends Component {
  // componentDidMount() {
  //   anime({
  //     targets: '.netbanka-loading path',
  //     strokeDashoffset: [anime.setDashoffset, 0],
  //     easing: 'easeInOutSine',
  //     duration: 1000,
  //     delay: function (el, i) { return i * 100 },
  //     direction: 'alternate',
  //     loop: true
  //   });
  // };

  render() {
    return (
      <div className="netbanka-loading">
      <Icon type="loading" spin />
        {/* <svg width="129px" height="22px" viewBox="0 0 129 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Graphics" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-160.000000, -456.000000)" id="Group" strokeWidth="0.5">
              <g transform="translate(160.000000, 457.000000)">
                <path d="M13.002,11.8324831 L13.002,0.122 L17.198,0.122 L17.198,20.2467151 L16.9513065,20.2499781 L14.8311459,20.278 L14.652,20.278 L14.4546844,20.181514 L4.598,7.51246043 L4.598,20.25 L0.402,20.25 L0.402,0.15 L3.648,0.15 L3.84413969,0.244986377 L13.002,11.8324831 Z" id="Path" stroke="#243B55"></path>
                <path d="M23.2942369,14.566 C23.6313436,16.050239 25.0356403,17.006 27.112,17.006 C28.4142851,17.006 29.9461259,16.5007331 30.6281496,15.7943514 L30.8037349,15.6124952 L33.343351,18.1195521 L33.1720801,18.2974104 C31.7538587,19.7701787 29.3895671,20.614 27.056,20.614 C22.2305085,20.614 19.218,17.6338841 19.218,13 C19.218,8.58608069 22.2613589,5.526 26.804,5.526 C31.7812989,5.526 34.5501552,8.72749002 34.0249121,14.3392972 L34.0036936,14.566 L23.2942369,14.566 Z" id="Path" stroke="#243B55"></path>
                <path d="M22.8113373,11.4203136 C23.3280759,9.58302062 24.8776068,8.55 26.916,8.55 C29.0698175,8.55 30.51456,9.58891596 30.7480695,11.4569913 L30.7831956,11.738 L22.721988,11.738 L22.8113373,11.4203136 Z" id="Path" stroke="#243B55"></path>
                <path d="M40.886,5.974 L44.694,5.974 L44.694,9.414 L40.858,9.414 L40.858,15.128 C40.858,16.2535966 41.4235517,16.838 42.4,16.838 C42.8751596,16.838 43.4521774,16.6884028 43.9401966,16.4443932 L44.1995278,16.3147276 L45.3144502,19.7250782 L45.0968477,19.8121192 C44.0911855,20.2143841 43.2501524,20.3890601 42.1853393,20.4178255 C38.9461016,20.5389185 36.97,18.6108193 36.97,15.128 L36.97,9.414 L34.394,9.414 L34.394,5.974 L36.97,5.974 L36.97,2.44322404 L40.886,2.02594535 L40.886,5.974 Z" id="Path" stroke="#243B55"></path>
                <path d="M50.522,11.266 L56.428,11.266 C58.3935607,11.266 60.346,12.4768879 60.346,14.204 C60.346,15.9619256 58.6247502,16.806 56.428,16.806 L50.522,16.806 L50.522,11.266 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M50.522,3.51 L56.428,3.51 C58.549319,3.51 59.758,4.75423047 59.758,6.168 C59.758,7.62612837 58.6472144,8.518 56.428,8.518 L50.522,8.518 L50.522,3.51 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M61.2419571,9.83229971 C62.9768899,10.640193 64.014,12.56638 64.014,14.232 C64.014,18.3571677 61.211721,20.25 56.428,20.25 L46.882,20.25 L46.882,0.15 L56.428,0.15 C60.7331637,0.15 63.426,2.22442334 63.426,6.084 C63.426,7.66272397 62.6887309,8.99992208 61.2419571,9.83229971 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M76.7441283,7.37647259 L76.8282737,5.946 L80.59,5.946 L80.59,20.25 L76.8899668,20.25 L76.764368,18.7428143 C75.8263867,19.9153082 74.1003041,20.6404334 72.4737123,20.6699941 C68.0334561,20.7004068 65.11,17.8577092 65.11,13.084 C65.11,8.4196021 68.1385459,5.57931721 72.556,5.61 C74.2236805,5.61 75.804872,6.28427608 76.7441283,7.37647259 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M72.864,8.662 C78.5733333,8.662 78.5733333,17.534 72.864,17.534 C70.352282,17.534 68.526,15.7509948 68.526,13.084 C68.526,10.4218167 70.3462025,8.662 72.864,8.662 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M86.6056046,7.52331564 C87.9713714,6.29896205 89.3386685,5.722 90.952,5.722 C94.5270351,5.722 97.054,8.47488559 97.054,12.748 L97.054,20.25 L93.138,20.25 L93.138,12.776 C93.138,10.685737 91.9902861,9.33 90.056,9.33 C88.1643326,9.33 86.778,10.9043099 86.778,12.944 L86.778,20.25 L82.89,20.25 L82.89,5.918 L86.4139251,5.918 L86.6056046,7.52331564 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M103.326,11.0526814 L107.532421,6.03538358 L107.724,5.946 L112.062,5.946 L112.062,6.392 L111.99844,6.55855334 L106.520505,12.6905702 L112.781018,19.5798683 L112.846,19.748 L112.846,20.25 L108.48,20.25 L108.290966,20.1636035 L103.326,14.4268813 L103.326,20.25 L99.41,20.25 L99.41,0.178 L103.326,0.178 L103.326,11.0526814 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M124.792128,7.37647259 L124.876274,5.946 L128.638,5.946 L128.638,20.25 L124.937967,20.25 L124.812368,18.7428143 C123.874387,19.9153082 122.148304,20.6404334 120.521712,20.6699941 C116.081456,20.7004068 113.158,17.8577092 113.158,13.084 C113.158,8.4196021 116.186546,5.57931721 120.604,5.61 C122.27168,5.61 123.852872,6.28427608 124.792128,7.37647259 Z" id="Path" stroke="#60EFB1"></path>
                <path d="M120.912,8.662 C126.621333,8.662 126.621333,17.534 120.912,17.534 C118.400282,17.534 116.574,15.7509948 116.574,13.084 C116.574,10.4218167 118.394203,8.662 120.912,8.662 Z" id="Path" stroke="#60EFB1"></path>
              </g>
            </g>
          </g>
        </svg> */}
      </div >
    );
  };
};

export default GlobalLoading;