import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { DataTable, ActionButtons, Filter, FadeIn } from 'components/UIComponents';
import { defaultFilters, typeCodeLevels, baseUrls } from "lib/constants";
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import Detail from './Detail';
import utils from 'lib';

class TypeCodes extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin.TypeCodeInitializers(),
  };

  componentDidMount() {
    this.getTypeCodes();
    setTimeout(() => {
      this.props.getFilterTypeCodes({ orderBy: ['code asc'] });
    }, 2000);
    utils.admin.getTenantBanks();
    utils.common.checkAccessToUpdateOrDelete("TypeCodes");
  };

  componentWillUnmount() {
    utils.admin.clearFilter("typeCodes")
  }

  getTypeCodes = () => {
    let { filter } = this.state;
    this.props.getTypeCodes(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getTypeCodes);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getTypeCodes);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getTypeCodes);
  }

  render() {
    let { typeCodes, tenantBanks, filterTypeCodes } = this.props;
    let _filterTypeCodes = filterTypeCodes.data ? _.uniqBy(filterTypeCodes.data, 'code') : []
    let columns = [
      {
        render: (row) => <ActionButtons
          url="TypeCodes"
          editUrl
          item={row}
          hasDelete
          getDatas={this.getTypeCodes}
          openDialog={this.datatable && this.datatable.openDialog}
        />, toggle: false, sort: false, notIncluded: true, checkField: false
      },
      { label: i18n.t('lbl.id'), key: 'Id' },
      { label: i18n.t('lbl.typeCode'), key: 'Code' },
      { label: i18n.t('lbl.typeCodeType'), key: 'Level' },
      { label: i18n.t('lbl.bank'), key: 'BankEftCode', render: data => utils.admin.getBankName({ data, listField: "bankEftCode", dataField: "BankEftCode" }) },
      { label: i18n.t('lbl.description'), key: 'Name' },
    ];
    let filters = [
      { label: i18n.t('lbl.bank'), options: tenantBanks.data, type: 'select', value: 'bankEftCode', name: (bankData) => utils.admin.getBankName({ bankData }), keys: ['bankEftCode'], showAll: true, callback: this.onSelect, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.typeCode'), type: 'select', options: _filterTypeCodes, value: 'code', name: 'code', keys: ['code'], uniqueKey: '#code', multiple: true },
      { label: i18n.t('lbl.typeCodeType'), type: 'select', options: typeCodeLevels, value: 'id', name: 'name', keys: ['level'], uniqueKey: '#codeType', multiple: true },
      { label: i18n.t('lbl.description'), type: 'input', keys: ['name'], contains: true, uniqueKey: '#name' },
    ];
    return (
      <FadeIn className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="TypeCodes"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={typeCodes.list.data}
          loading={typeCodes.list.loading}
          title={i18n.t('route.admin.typeCodes')}
          getData={this.getTypeCodes}
          count={typeCodes.list.count}
          newButton="openDialog"
          Component={Detail}
          dialogTitle={i18n.t('lbl.typeCode')}
          deleteOptions={{ stateKey: "typeCodes", url: "TypeCodes", baseUrl: baseUrls.nte }}
        />
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  typeCodes: admin.typeCodes,
  filterTypeCodes: admin.filter.typeCodes,
  tenantBanks: admin.filter.tenantBanks
});
const mapDispatchToProps = (dispatch) => ({
  getTypeCodes: (filter) => dispatch(actions.getAll({ filter, url: endpoints.nte.typeCodes, key: 'typeCodes', isNewODataStructure: true })),
  getFilterTypeCodes: (filter) => dispatch(actions.getFilter({ url: endpoints.lookups.typeCodes, key: 'typeCodes', filter })),
});
export default connect(mapStateToProps, mapDispatchToProps)(TypeCodes);