export const GET_ALL_TOS = "GET_ALL_TOS";
export const GET_ALL_TOS_SUCCESS = "GET_ALL_TOS_SUCCESS";
export const GET_ALL_TOS_FAILURE = "GET_ALL_TOS_FAILURE";
export const GET_TOS = "GET_TOS";
export const GET_TOS_SUCCESS = "GET_TOS_SUCCESS";
export const GET_TOS_FAILURE = "GET_TOS_FAILURE";
export const POST_TOS = "POST_TOS";
export const POST_TOS_SUCCESS = "POST_TOS_SUCCESS";
export const POST_TOS_FAILURE = "POST_TOS_FAILURE";
export const PUT_TOS = "PUT_TOS";
export const PUT_TOS_SUCCESS = "PUT_TOS_SUCCESS";
export const PUT_TOS_FAILURE = "PUT_TOS_FAILURE";
export const DELETE_TOS = "DELETE_TOS";
export const DELETE_TOS_SUCCESS = "DELETE_TOS_SUCCESS";
export const DELETE_TOS_FAILURE = "DELETE_TOS_FAILURE";
export const PATCH_TOS = "PATCH_TOS";
export const PATCH_TOS_SUCCESS = "PATCH_TOS_SUCCESS";
export const PATCH_TOS_FAILURE = "PATCH_TOS_FAILURE";
export const GET_FILTER_TOS = "GET_FILTER_TOS";
export const GET_FILTER_TOS_SUCCESS = "GET_FILTER_TOS_SUCCESS";
export const GET_FILTER_TOS_FAILURE = "GET_FILTER_TOS_FAILURE";
export const CLEAR_STATE_TOS = "CLEAR_STATE_TOS";
export const GET_WITH_POST_TOS = "GET_WITH_POST_TOS";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_TOS, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_TOS_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_TOS_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_TOS, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_TOS_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_TOS_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_TOS, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_TOS_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_TOS_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_TOS, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_TOS_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_TOS_FAILURE, payload })
  },

  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_TOS, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_TOS, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_TOS_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_TOS_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_TOS, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_TOS_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_TOS_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_TOS, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_TOS_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_TOS_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key) => {
    return ({ type: CLEAR_STATE_TOS, key })
  }
  //#endregion
}

export default actions;