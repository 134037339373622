export default {
  path: '/netahsilat',
  name: 'netahsilat',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/pos/commissions/:id?',
      name: 'commissions',
      visible: false,
      access: 'TenantPos',
      component: require('components/Modules/Netahsilat/Pos/Commissions').default,
    },
    {
      path: '/pos',
      name: 'posList',
      access: 'TenantPos',
      component: require('components/Modules/Netahsilat/Pos/List').default,
    },
    {
      path: '/payment',
      name: 'payment',
      access: 'Transaction',
      component: require('components/Modules/Netahsilat/Payment/List').default,
    },
    {
      path: '/netlink',
      name: 'netlinkTransaction',
      access: 'NetLinkTransaction',
      component: require('components/Modules/Netahsilat/NetLinkTransaction/List').default,
    },
    {
      path: '/pay',
      name: 'doPay',
      component: require('components/Modules/Netahsilat/Pay/index').default,
    },
    {
      path: '/parameters',
      name: 'paymentParameters',
      component: require('components/Modules/Netahsilat/PaymentParameters/index').default,
    },
  ]
};