import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { Checkbox } from 'antd';
import { status, contractualTypes, defaultFilters, baseUrls } from 'lib/constants';
import { DataTable, Filter, ActionButtons, FadeIn, CheckAccessRight } from "components/UIComponents";
import endpoints from 'config/endpoints';
import _ from 'lodash'
import i18n from 'plugins/i18n';
import Detail from './Detail';
import utils from 'lib';

class Packages extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin.PackagesInitializer()
  };

  componentDidMount() {
    this.getPackages();
    utils.common.checkAccessToUpdateOrDelete("LicencePackages");
  };

  getPackages = () => {
    let { filter } = this.state;
    this.props.getPackages(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getPackages();
    });
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getPackages();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getPackages());
  }

  render() {
    const { packages } = this.props;
    const filters = [
      { label: i18n.t('lbl.packageName'), type: 'input', keys: ['name'], contains: true, uniqueKey: '#name' },
      { label: i18n.t('lbl.code'), type: 'input', keys: ['Code'], contains: true, uniqueKey: '#code' },
      { label: i18n.t('lbl.packageStatus'), type: 'select', options: status, lang: 'lbl', value: 'value', name: 'name', keys: ['isActive'], showAll: true, uniqueKey: '#isActive', boolean: true },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="LicencePackages" state="packages" editUrl="admin/package" item={row} hasDelete getDatas={this.getPackages} openDialog={this.datatable?.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.packageName'), key: 'Name' },
      { label: i18n.t('lbl.code'), key: 'Code' },
      { label: i18n.t('lbl.packageStatus'), key: 'IsActive', render: (row) => <Checkbox checked={row.IsActive} /> },{ label: i18n.t('lbl.contractualType'), key: 'ContractualType', render: (row) => row.ContractualType ? i18n.t(`lbl.${_.find(contractualTypes, ['id', row.ContractualType]).name}`) : null },
    ];

    return (
      <FadeIn className="page-content">
        <CheckAccessRight {...{ ...packages }}>
          <Filter filters={filters} onFilter={this.onFilter} />
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            access="LicencePackages"
            history={this.props.history}
            newButton="openDialog"
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={packages.list.data}
            loading={packages.list.loading}
            title={i18n.t('route.admin.packages')}
            getData={this.getPackages}
            count={packages.list.count}
            Component={Detail}
            dialogTitle={i18n.t('lbl.packages')}
            deleteOptions={{ stateKey: "packages", url: "LicencePackages", baseUrl: baseUrls.tenant }}
          />
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  packages: admin.packages
});
const mapDispatchToProps = (dispatch) => ({
  getPackages: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tenant.licencePackages, key: 'packages', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Packages);