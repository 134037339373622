import React, { useState, useEffect } from 'react'
import { KrediTenantModel } from 'models';
import { FadeIn, Typography, FormElements } from 'components/UIComponents';
import { Form, Row, Col, Button } from 'antd';
import i18n from 'plugins/i18n';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import actions from 'store/redux/netekstre';

const TenantInfo = ({ onChange, form, getCities, getDistricts, getNeighborhoods, cities, districts, neighborhoods, clearState }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState(new KrediTenantModel());
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getCities();
  }, [getCities])

  const getLookup = ({ type, id = null }) => {
    switch (type) {
      case "districts":
        if (id) getDistricts(id)
        else {
          clearState(`filter.${type}`)
          clearState('filter.neighborhoods')
        }
        break;
      case "neighborhoods":
        if (id) getNeighborhoods(id)
        else clearState(`filter.${type}`)
        break;
      default: break;
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      if (!error) {
        setSaving(true);
        setSaving(false);
        onChange('+', data);
      }
    })
  }

  return (
    <FadeIn>
      <Form onSubmit={onSubmit}>
        <Row className="pl-30 pr-30 pb-40" gutter={44}>
          <Col xs={12} md={24} className="mb-35">
            <Typography.H size={3} color="#273c74">
              {i18n.t('lbl.tenantInfo')}
            </Typography.H>
            <Typography.P className="mt-15" size="normal">
              {i18n.t('lbl.pleaseEnterTenantInformation')}
            </Typography.P>
          </Col>

          <Col xs={12} md={12}>
            <Form.Item>
              {
                getFieldDecorator('iban', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.iban
                })(
                  <FormElements.Iban
                    label={i18n.t('lbl.ibanNo')}
                    prefix="banks"
                    onChange={(e) => updateData((data) => ({ ...data, iban: e }))}
                  />
                )}
            </Form.Item>
          </Col>

          <Col xs={12} md={12}>
            <Form.Item>
              {
                getFieldDecorator('name', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.name
                })(
                  <FormElements.Input
                    label={i18n.t('lbl.memberTenantName')}
                    prefix="manager"
                    onChange={(e) => updateData((data) => ({ ...data, name: e }))}
                  />
                )}
            </Form.Item>
          </Col>

          <Col xs={12} md={12}>
            <Form.Item>
              {
                getFieldDecorator('taxNumber', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.taxNumber
                })(
                  <FormElements.Input
                    label={i18n.t('lbl.taxNo')}
                    prefix="writing"
                    onChange={(e) => updateData((data) => ({ ...data, taxNumber: e }))}
                  />
                )}
            </Form.Item>
          </Col>

          <Col xs={12} md={12}>
            <Form.Item>
              {
                getFieldDecorator('taxOffice', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.taxOffice
                })(
                  <FormElements.Input
                    label={i18n.t('lbl.taxOffice')}
                    prefix="house"
                    onChange={(e) => updateData((data) => ({ ...data, taxOffice: e }))}
                  />
                )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="pl-30 pr-30" gutter={44}>
          <Col xs={24} md={24} className="mb-35">
            <Typography.H size={3} color="#273c74">
              {i18n.t('lbl.tenantContactInfo')}
            </Typography.H>
            <Typography.P className="mt-15" size="normal">
              {i18n.t('lbl.pleaseEnterTenantContactInformation')}
            </Typography.P>
          </Col>

          <Col xs={24} md={12}>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item>
                  {getFieldDecorator('cityId', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: null,
                  })(
                    <FormElements.Select
                      label={i18n.t('lbl.city')}
                      options={cities.data}
                      optName="name"
                      optVal="id"
                      prefix="marker"
                      loading={cities.loading}
                      onChange={(id) => { updateData((data) => ({ ...data, cityId: id })); getLookup({ type: 'districts', id }) }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item>
                  {getFieldDecorator('districtId', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: null,
                  })(
                    <FormElements.Select
                      label={i18n.t('lbl.district')}
                      options={districts.data}
                      optName="name"
                      optVal="id"
                      prefix="marker"
                      loading={districts.loading}
                      onChange={(id) => { updateData((data) => ({ ...data, districtId: id })); getLookup({ type: 'neighborhoods', id }) }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item>
                  {getFieldDecorator('neighborhoodId', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: null,
                  })(
                    <FormElements.Select
                      label={i18n.t('lbl.neighborhood')}
                      options={neighborhoods.data}
                      optName="name"
                      optVal="id"
                      prefix="marker"
                      loading={neighborhoods.loading}
                      onChange={(e) => updateData((data) => ({ ...data, neighborhoodId: e }))}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <Row>
              <Form.Item>
                {
                  getFieldDecorator('address', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.address
                  })(
                    <FormElements.Textarea
                      label={i18n.t('lbl.address')}
                      prefix="map"
                      onChange={(e) => updateData((data) => ({ ...data, address: e }))}
                    />
                  )}
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row className="pl-30 pr-30" gutter={44}>
          <Col xs={24} md={12} className="text-right">
            <Button ghost type="default" htmlType="button" size="large" onClick={() => onChange('-')}>
              {i18n.t('btn.cancel')}
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Button type="primary" htmlType="submit" size="large" loading={saving}>
              {i18n.t('btn.continue')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FadeIn>
  )
}
const mapStateToProps = ({ global }) => ({
  cities: global.filter.cities,
  districts: global.filter.districts,
  neighborhoods: global.filter.neighborhoods,
});
const mapDispatchToProps = (dispatch) => ({
  getCities: () => dispatch(actions.globalRequest({ url: endpoints.info.cities, notify: false, key: 'filter.cities' })),
  getDistricts: (id) => dispatch(actions.globalRequest({ url: endpoints.info.district, notify: false, key: 'filter.districts', id })),
  getNeighborhoods: (id) => dispatch(actions.globalRequest({ url: endpoints.info.neighborhood, notify: false, key: 'filter.neighborhoods', id })),
  clearState: (key) => dispatch(actions.clearState(key)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(TenantInfo));