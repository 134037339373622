import React, { Component } from 'react';
import { Button, Modal, Icon, Form, Tooltip } from 'antd';
import actions from 'store/actions/netekstre';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import utils from 'lib';

class SynchronizeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      date: null,
      loading: false,
    }
  };

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    // let { data } = this.state;
    //  let _data = _.cloneDeep(data)
    //  let { selectedItems, auth } = this.props;
    // _data.tenantId = auth.data.tenantId;
    //  selectedItems.map(item =>
    //    _data.jobRulesModel.push({ id: 0, ruleId: item })
    //  )
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({ loading: true });
        this.props.postModal((response) => {
          if (response) {
            this.setState({ loading: false, modalVisible: false });
            this.props.onStartSync(false);
          }
          else {
            this.setState({ loading: false, modalVisible: false });
          }
          utils.common.clearSelectedItems();
        });
      }
    })
  }

  render() {
    let { modalVisible, loading } = this.state;
    let { selectedItems, selectAll, disabled, currentModule } = this.props;
    return (
      <React.Fragment>
        <Tooltip placement="top" title={i18n.t('msg.maxRule')}>
          <Button
            loading={loading}
            onClick={() => this.setState({ modalVisible: true })}
            // disabled={(selectedItems.length === 0 && !selectAll) || loading || disabled || selectedItems.length > 5}
            className="pull-right mr-10 #synchronize btn-collapse"
            type={!((selectedItems.length === 0 && !selectAll) || loading || disabled || selectedItems.length > 5) ? 'default' : undefined}
            size="small"
          >
            {!loading && <Icon type="swap" />}
            {i18n.t('btn.syncRule')}
          </Button>
        </Tooltip>
        <Modal
          title={i18n.t('lbl.syncRule')}
          visible={modalVisible}
          onOk={this.save}
          okButtonProps={{ className: '#okModal', icon: 'save' }}
          cancelButtonProps={{ className: '#cancelModal', icon: 'close' }}
          okText={i18n.t('btn.synchronize')}
          cancelText={i18n.t('btn.cancel')}
          onCancel={() => { this.setState({ modalVisible: false }) }}
          className={currentModule}
        >
        </Modal>
      </React.Fragment>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  currentModule: common.currentModule
});
const mapDispatchToProps = (dispatch) => ({
  postModal: (callback) => dispatch(actions.post({ url: endpoints.nte.syncGeneralRules }, callback))
})
const SynchronizeModalForm = Form.create()(SynchronizeModal);
export default connect(mapStateToProps, mapDispatchToProps)(SynchronizeModalForm);

