import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox, Card } from 'antd';
import { Input, Select, InputPhone } from 'components/UIComponents/NetbankaComponents';
import { CustomerModel } from 'models';
import actions from 'store/actions/common';
import { Loading, CheckAccessRight, Typography } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import { cloneDeep, isTckn } from 'helpers';
import utils from 'lib';

class Customer extends Component {
  state = {
    data: new CustomerModel(),
    InitialState: new CustomerModel(),
  };

  static getDerivedStateFromProps(props, state) {
    if (props.customers.data && props.customers.saving === null && !state.data.id) {
      const data = new CustomerModel(cloneDeep(props.customers.data));
      return { data, InitialState: data }
    }
    if (props.dialogClosing && !props.customers.saving)
      props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    const { id, getCustomers, tenantId } = this.props;
    const parameters = tenantId && { TenantId: tenantId };
    if (id) getCustomers(id, parameters);
    utils.common.getUsers();
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    const { data } = this.state;
    const { dialogCloseRequest, id, saveData, form, tenantId } = this.props;

    form.validateFields(error => {
      if (!error) {
        const method = id ? httpMethods.put : httpMethods.post;
        const parameters = tenantId && { TenantId: tenantId }
        saveData(data, method, parameters, (response) => {
          if (response)
            this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    const { customers, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={customers.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={customers.loading} form="customers" htmlType="submit" className="success #save" icon="save" loading={customers.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      customers.saving,
      customers.loading
    )
  }

  render() {
    const { customers, users, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { data } = this.state;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...customers, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={customers.loading}>
            <Form onSubmit={this.save} id="customers">
              <Row gutter={20}>
                <Col xs={24} className="mb-10">
                  <Card className="detail-cards">
                    <Typography.P className="text-center" >{i18n.t('descriptions.customerDetail')}</Typography.P>
                  </Card>
                </Col>

                <Col xs={24}>
                  <Form.Item>
                    <Checkbox className="#isActive" checked={data.isActive} onChange={(e) => this.update('isActive', e.target.checked)}>{i18n.t('lbl.isActive')}</Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input label={i18n.t('lbl.name')} onChange={(e) => this.update('name', e)} className="#name" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('surname', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.surname
                    })(
                      <Input label={i18n.t('lbl.surname')} onChange={(e) => this.update('surname', e)} className="#surname" />
                    )}
                  </Form.Item>
                </Col>
                {
                  data.isForeignNational ?
                    <>
                      <Col xs={24} sm={12}>
                        <Form.Item>
                          {getFieldDecorator('passportNo', {
                            rules: [{ required: data.isForeignNational, message: i18n.t('msg.required') }],
                            initialValue: data?.passportNo
                          })(
                            <Input className="#passportNo" label={i18n.t('lbl.passportNo')} onChange={val => this.update('passportNo', val)} />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={5}>
                        <Form.Item>
                          {getFieldDecorator('countryCode', {
                            rules: [{ required: data.isForeignNational, message: i18n.t('msg.required') }],
                            initialValue: data?.countryCode
                          })(
                            <Input className="#countryCode" label={i18n.t('lbl.countryCode')} onChange={val => this.update('countryCode', val)} />
                          )}
                        </Form.Item>
                      </Col>
                    </>
                    :
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('tckn', {
                          rules: [
                            { required: false, message: i18n.t('msg.required') },
                            { validator: (rule, value, callback) => isTckn({ callback, value }), message: i18n.t("msg.invalidFormat") }
                          ],
                          initialValue: data?.tckn || ''
                        })(
                          <Input className="#tckn" type="stringNumber" maxLength={11} label={i18n.t('lbl.tckn')} onChange={val => this.update('tckn', val)} />
                        )}
                      </Form.Item>
                    </Col>
                }
                <Col xs={24} sm={7}>
                  <Form.Item>
                    <Checkbox className="#isForeignNational" checked={data.isForeignNational} onChange={(e) => this.update('isForeignNational', e.target.checked)}>{i18n.t('lbl.foreignNational')}</Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('userCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.userCode
                    })(
                      <Input label={i18n.t('lbl.userCode')} onChange={(e) => this.update('userCode', e)} className="#userCode" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('customerRepresentativeId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.customerRepresentativeId
                    })(
                      <Select
                        className="#customerRepresentativeId"
                        label={i18n.t('lbl.customerRepresentatives')}
                        options={users.data}
                        optVal="id"
                        optName={({ name, surname }) => `${name} ${surname}`}
                        onChange={e => this.update('customerRepresentativeId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: i18n.t('msg.required') },
                        { type: 'email', message: i18n.t('msg.emailInvalid') }
                      ],
                      initialValue: data.email
                    })(
                      <Input disabled={data.isVerifiedEmail} label={i18n.t('lbl.email')} onChange={(e) => !data.isVerifiedEmail && this.update('email', e)} className="#email" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('phone', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.phone
                    })(
                      <InputPhone disabled={data.isVerifiedPhone} label={i18n.t('lbl.phone')} mobile onChange={(e) => !data.isVerifiedPhone && this.update('phone', e)} className="#phone" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  customers: common.customers.single,
  users: common.filter.users,
});
const mapDispatchToProps = (dispatch, { endpoint }) => ({
  getCustomers: (id, parameters) => dispatch(actions.get({ url: endpoint || endpoints.tenant.customers, parameters, key: 'customers', id })),
  saveData: (data, method, parameters, callback) => dispatch(actions[method]({ url: endpoint || endpoints.tenant.customers, parameters, key: 'customers', data }, callback)),
  clearState: () => dispatch(actions.clearState("customers"))
});
const CustomerForm = Form.create()(Customer);
export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);