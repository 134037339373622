import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { DataTable, Filter, ActionButtons, FadeIn } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from "lib/constants";
import i18n from 'plugins/i18n';
import Detail from './Detail';
import utils from 'lib';

class ErpTypes extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin.ErpInitializers()
  };


  componentDidMount() {
    this.getErpTypes();
    utils.admin.getErpTypes();
    utils.common.checkAccessToUpdateOrDelete("ErpTypes");
  };

  componentWillUnmount() {
    utils.admin.clearFilter("erpTypes");
  }

  getErpTypes = () => {
    let { filter } = this.state;
    this.props.getErpTypes(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getErpTypes);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getErpTypes);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getErpTypes);
  }

  render() {
    let { erpTypes, erpTypesFilter } = this.props;
    let columns = [
      {
        render: (row) => <ActionButtons
          url="ErpTypes"
          editUrl
          item={row}
          hasDelete
          getDatas={this.getErpTypes}
          openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, checkField: false
      }, //FIXME:OPEN DIALOG
      { label: i18n.t('lbl.id'), key: 'Id' },
      { label: i18n.t('lbl.name'), key: 'Name' },
      { label: i18n.t('lbl.description'), key: 'Description' },
    ];
    let filters = [
      { label: i18n.t('lbl.erpType'), type: 'select', options: erpTypesFilter.data || [], value: 'id', name: 'name', keys: ['Key'], uniqueKey: '#code', multiple: true },
    ];


    return (
      <FadeIn className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="ErpTypes" //FIXME:
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={erpTypes.list.data}
          loading={erpTypes.list.loading}
          title={i18n.t('route.admin.erpTypes')}
          getData={this.getErpTypes}
          count={erpTypes.list.count}
          newButton="openDialog"
          Component={Detail}
          dialogTitle={i18n.t('lbl.erpType')}
          deleteOptions={{ stateKey: "erpTypes", url: "ErpTypes", baseUrl: baseUrls.tenant }}
        />
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  erpTypes: admin.erpTypes,
  erpTypesFilter: admin.filter.erpTypes,
});
const mapDispatchToProps = (dispatch) => ({
  getErpTypes: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tenant.erpTypes, key: 'erpTypes', isNewODataStructure: true})),
});
export default connect(mapStateToProps, mapDispatchToProps)(ErpTypes);