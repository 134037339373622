import React, { Component } from 'react';
import { Button, Table, Tooltip, Popconfirm, Drawer } from "antd";
import _ from "lodash";
import { v4 as uuid } from 'uuid';
import { Description } from 'components/UIComponents'
import SplitDetail from './SplitDetail'
import { TransactionModel } from 'models';
import { formatCurrency, formatDate, formatIBAN } from 'helpers';
import i18n from 'plugins/i18n';

class SplitTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: _.cloneDeep(props.data),
      visible: false,
      currentData: null,
      isEqual: null,
      isZero: null,
      addNew: true,
    }
  }

  componentDidMount() {
    let { data } = this.state;
    if (data.children.length > 0) {
      let total = data.children.filter(x => x.modelState !== 3).reduce((a, b) => a + b.amount, 0);
      data.children.push({ id: -1, className: "text-right", description1: i18n.t('lbl.total'), currencyCode: data.currencyCode, amount: total, noActions: true })
      this.setState({
        data
      }, () => this.calcAmount())
    }
  }

  getColumns = () => {
    return [
      { title: '', dataIndex: 'id', render: (row, data) => this.props.data.id !== row ? this.getButtons(row, data) : undefined },
      { title: i18n.t('lbl.bank'), dataIndex: 'bankName', render: this.getBankName },
      { title: i18n.t('lbl.transactionDate'), dataIndex: 'transactionDate', render: (row) => formatDate(row) },
      { title: i18n.t('lbl.description'), dataIndex: 'description1', render: (row) => <Description text={row} /> },
      { title: i18n.t('lbl.amount'), dataIndex: 'amount', sorter: (a, b) => a.amount - b.amount, render: (row, data) => formatCurrency({ data: row, currencyCode: data.currencyCode, withColor: true }) },
      { title: i18n.t('lbl.bankAccountBalance'), dataIndex: 'bankAccountBalance', render: (row, data) => formatCurrency({ data: row, currencyCode: data.currencyCode, withColor: false }) },
      { title: i18n.t('lbl.accountName'), dataIndex: 'bankAccountName' },
      { title: i18n.t('lbl.typeOne'), dataIndex: 'typeCode1' },
      { title: i18n.t('lbl.typeTwo'), dataIndex: 'typeCode2' },
      { title: i18n.t('lbl.tc'), dataIndex: 'tcknNumber' },
      { title: i18n.t('lbl.taxNumber'), dataIndex: 'taxNumber' },
      { title: i18n.t('lbl.iban'), dataIndex: 'iban', render: (row) => formatIBAN(row) },
      { title: i18n.t('lbl.currentAccountCode'), dataIndex: 'currentAccountCode' },
      { title: i18n.t('lbl.bankAccountCode'), dataIndex: 'bankAccountCode', },
      { title: i18n.t('lbl.accountPlanCode'), dataIndex: 'accountPlanCode' },
      { title: i18n.t('lbl.voucherType'), dataIndex: 'voucherTypeName' },
      { title: i18n.t('lbl.erpCode'), dataIndex: 'erpCode' },
      { title: i18n.t('lbl.workplace'), dataIndex: 'departmentNumber' },
      { title: i18n.t('lbl.department'), dataIndex: 'divisionNumber' },
      { title: i18n.t('lbl.category'), dataIndex: 'categoryName' },
    ];
  }

  getButtons = (row, data) => {
    return (
      !data.noActions &&
      <Button.Group>
        <Tooltip placement="bottom" title={i18n.t('btn.delete')}>
          <Popconfirm title={i18n.t('msg.deleteConfirm')} onConfirm={() => this.deleteItem(data)} okText={i18n.t('btn.yes')} cancelText={i18n.t('btn.cancel')}>
            <Button className="#delete" icon="delete" size="small" />
          </Popconfirm>
        </Tooltip>
        <Button className="#edit" icon="edit" size="small" onClick={() => this.editItem(data)} />
      </Button.Group>
    )
  }

  getBankName = (row, data) => {
    let { helpersData } = this.props;
    let bank = helpersData && data ? helpersData.find(x => x.bankEftCode === data.bankEftCode) : null;
    return bank ? <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment> : '';
  };

  getData = () => {
    let { data } = this.props;
    let _data;
    if (data) {
      _data = [];
      _data.push(data);
    }
    return _data;
  }

  calcAmount = () => {
    let { data } = this.state;
    if (data.children.find(x => x.id === -1)) {
      data.children.find(x => x.id === -1).amount = data.children.filter(x => isNaN(x.id) && x.modelState !== 3).reduce((a, b) => a + b.amount, 0)
    }
    else {
      let total = data.children.filter(x => x.modelState !== 3).reduce((a, b) => a + b.amount, 0);
      data.children.push({ id: -1, className: "text-right", description1: i18n.t('lbl.total'), currencyCode: data.currencyCode, amount: total, noActions: true })
    }
    let total = data.children.find(x => x.id === -1) ? data.children.find(x => x.id === -1).amount : 0;
    let isCalculated = !!data.children.find(x => x.id === -2);
    if (total.toFixed(2) === "0.00") {
      let equality = data.children.indexOf(data.children.find(x => x.id === -2));
      equality !== -1 && data.children.splice(equality, 1);
      this.setState({ addNew: true })
      this.equality(true, true);
    }
    else if (total.toFixed(2) === data.amount.toFixed(2)) {
      let equality = data.children.indexOf(data.children.find(x => x.id === -2));
      equality !== -1 && data.children.splice(equality, 1);
      let isEqual = data.children.filter(x => isNaN(x.id) && x.modelState !== 3).length > 1;
      this.setState({ addNew: false })
      this.equality(isEqual, false);
    }
    else {
      this.equality(false, false);
      let diff = total - data.amount;
      this.setState({ addNew: true })
      diff = Math.sign(data.amount) === -1 ? -diff : diff;
      let desc = Math.sign(diff) === -1 ? i18n.t('msg.missing') : i18n.t('msg.much');
      if (isCalculated) {
        data.children.find(x => x.id === -2).amount = diff;
        data.children.find(x => x.id === -2).description1 = desc
      }
      else {
        data.children.push({ id: -2, currencyCode: data.currencyCode, className: "text-right", description1: desc, amount: diff, noActions: true })
      }
    }

    this.setState({
      data
    })
  }

  equality = (isEqual, isZero) => {
    let { data } = this.state;
    this.props.onChange(isEqual, isZero, data)
    this.setState({ isEqual, isZero })
  }

  addItem = () => {
    let _data = _.cloneDeep(this.state.data);
    _data.amount = (_data.amount - _data.children.filter(x => isNaN(x.id) && x.modelState !== 3).reduce((a, b) => a + b.amount, 0)).toFixed(2);
    delete _data.children;
    _data.id = uuid.v4();
    this.setState({
      visible: true,
      currentData: new TransactionModel(_data),
    })
  }

  editItem = (data) => {
    data.tempId = uuid.v4();
    this.setState({
      currentData: data,
      visible: true
    })
  }

  deleteItem = (item) => {
    let { data } = this.state;
    let index = data.children.indexOf(item);
    item.id > 0 ? data.children[index].modelState = 3 : data.children.splice(index, 1)
    this.setState({
      data
    }, () => this.calcAmount())
  }

  onSave = (item) => {
    let { data, currentData } = this.state;
    item.amount = parseFloat(item.amount)
    let index = data.children.indexOf(data.children.find(x => x.tempId && x.tempId === currentData.tempId));

    if (index === -1) {
      item.modelState = 1;
      data.children.insert(data.children.length - 2, item)
    }
    else {
      delete item.tempId;
      item.modelState = item.id && item.id > 0 ? 2 : 1;
      data.children[index] = item;
    }
    this.setState({
      data,
      visible: false
    }, () => this.calcAmount())
  }

  save = () => {
    this.props.save(this.state.data)
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  render() {
    let { data, isEqual, isZero, addNew, visible, currentData } = this.state;
    let _data = { ...data, children: data.children.filter(x => x.modelState !== 3) }
    let diff = data.children.find(x => x.id === -2) ? data.children.find(x => x.id === -2).amount : 0;
    let _currentDataAmount = currentData ? (currentData.amount || currentData.Amount) : null;
    let _amount = data.amount || data.Amount;
    let maxAmount = _currentDataAmount ? parseFloat(_currentDataAmount + (Math.sign(_amount) === -1 ? parseFloat(diff) : parseFloat(-diff))) : 0;

    return (
      <div className="split-table">
        <Table
          columns={this.getColumns()}
          dataSource={[_data]}
          rowKey={(row) => isNaN(row.id) ? row.id : Math.abs(row.id).toString()}
          size="small"
          scroll={{ x: true }}
          pagination={false}
          indentSize={0}
          defaultExpandAllRows={true}
          expandIcon={() => <span></span>}
        />
        {
          !(isEqual && !isZero) && addNew &&
          <div className={`add-new-record ${data.children.length > 0 ? 'has-child' : ''}`}>
            <Button.Group>
              <Button onClick={this.addItem} className="#add ant-btn-collapse" icon="plus" size="small">{i18n.t('btn.addSubTransaction')}</Button>
            </Button.Group>
          </div>
        }
        <Drawer
          title={i18n.t('lbl.bankTransactionSplit')}
          placement="right"
          closable
          onClose={this.onClose}
          visible={visible}
          height="auto"
          getContainer=".module-content"
          destroyOnClose
          width={500}
        >
          <SplitDetail key="string" maxAmount={maxAmount} split={currentData} onSave={this.onSave} onClose={this.onClose} />
        </Drawer>
      </div>
    );
  };
};

export default SplitTable;