import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/redux/netekstre';
import { DataTable, Filter, ActionButtons } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { formatDate, formatCurrency } from 'helpers';
import { getKey } from 'lib/constants/defaultFilters';

const ApplicationList = ({ currentFilter, tableSettings, applications, getAllApplications, history, getBanks, banks }) => {
  let datatable = useRef();
  let key = getKey();

  const [filter, setFilter] = useState({
    filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
    skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
    top: tableSettings ? tableSettings.pageSizes : 10,
    count: true,
    expand: [
      { TransactionProducts: { expand: "Product" } },
      "TenantPos",
      { TenantCreditInfo: { expand: "Tenant" } },
    ]

  });

  useEffect(() => {
    getAllApplications(filter);
    //getBanks();
  }, [filter, getAllApplications, getBanks]);

  const getApplications = () => {
    getAllApplications(filter);
  };

  const onFilter = (newFilter) => {
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    datatable.pageUpdate(1);
    setFilter({ ...filter })
  };

  const onSort = (key, type) => {
    setFilter({ filter: { ...filter, orderBy: [`${key} ${type}`] } })
  };

  const onPageChange = (skip, top) => {
    filter.skip = skip;
    filter.top = top;
    setFilter({ ...filter })
  };

  let filters = [
    {
      label: i18n.t('lbl.bank'), type: 'select', options: banks.singleData, value: 'id', name: "name", keys: ['TenantPos/Id'], showAll: true, uniqueKey: '#banks', multiple: true, guid: true,
      col: { lg: 4, sm: 24 }
    },
    { label: i18n.t('lbl.applicationDate', { add: `(${i18n.t('lbl.start')})` }), type: 'date', date: 'ge', keys: ['CreatedDate'], uniqueKey: '#CreatedDateStart', disabled: '2', col: { lg: 5, sm: 24 } },
    { label: i18n.t('lbl.applicationDate', { add: `(${i18n.t('lbl.end')})` }), type: 'date', date: 'le', keys: ['CreatedDate'], uniqueKey: '#CreatedDateEnd', disabled: '1', col: { lg: 5, sm: 24 } },
    { label: i18n.t('lbl.statusDate', { add: `(${i18n.t('lbl.start')})` }), type: 'date', date: 'ge', keys: ['TransactionDate'], uniqueKey: '#StatusDateStart', disabled: '2', col: { lg: 5, sm: 24 } },
    { label: i18n.t('lbl.statusDate', { add: `(${i18n.t('lbl.end')})` }), type: 'date', date: 'le', keys: ['TransactionDate'], uniqueKey: '#StatusDateEnd', disabled: '1', col: { lg: 5, sm: 24 } },
  ];
  let columns = [
    {
      render: (row) => <ActionButtons url="Transaction/GetByInitiatorUserId/{Id}" state="applications"
        editUrl="kredi/application" item={row} getDatas={getApplications} />,
      toggle: false,
      sort: false,
      notIncluded: true,
      key: 'id'
    },
    { label: i18n.t('lbl.referenceCode'), key: 'ReferenceNumber' },
    {
      label: i18n.t('lbl.applicationDate'),
      key: 'CreatedDate',
      render: ({ CreatedDate }) => formatDate(CreatedDate)
    },
    { label: i18n.t('lbl.bankName'), key: 'TenantPos.Name', checkField: false, },
    {
      label: i18n.t('lbl.transactionAmount'), key: 'PureAmount', checkField: false,
      render: ({ PureAmount }) => formatCurrency({ data: PureAmount, withSign: false })
    },

    {
      label: i18n.t('lbl.productType'),
      key: 'TransactionProducts.Product.Name',
      checkField: false,
      render: ({ TransactionProducts }) =>
        TransactionProducts.map(transactionProduct => {
          return transactionProduct.Product.Name + ", "
        })
    },
    { label: i18n.t('lbl.transactionStatus'), key: 'TransactionStatusType', checkField: false, },
    {
      label: i18n.t('lbl.transactionDate'),
      key: 'TransactionDate',
      render: ({ TransactionDate }) => formatDate(TransactionDate)
    },
    {
      label: i18n.t('lbl.moneyTransferDate'),
      key: 'TransactionStatusTypeUpdatedDate',
      render: ({ TransactionStatusTypeUpdatedDate }) => formatDate(TransactionStatusTypeUpdatedDate)
    },
    { label: i18n.t('lbl.dealerName'), key: 'TenantCreditInfo.Tenant.Name', checkField: false, }
  ];

  return (
    <>
      <Filter filters={filters} onFilter={onFilter} />
      <DataTable
        count={applications.count}
        ref={(el) => datatable = el}
        onPageChange={onPageChange}
        history={history}
        onSort={onSort}
        columns={columns}
        data={applications.data}
        loading={applications.loading}
        title={i18n.t('route.dashboard.applications')}
        getData={getApplications}
        deleteOptions={{ stateKey: "applications" }}
      />
    </>
  )
}

const mapStateToProps = ({ global }) => ({
  applications: global.kredi.applications,
  banks: global.filter.krediBanks,
  currentFilter: global.currentFilter,
  tableSettings: global.gridSettings.data ? global.gridSettings.data.find(x => x.controllerPath === 'dashboard.kredi/applications') : {}
});
const mapDispatchToProps = (dispatch) => ({
  getAllApplications: (filter) => dispatch(actions.globalRequest({
    filter,
    url: endpoints.kredi.getByInitiatorUserId,
    key: 'kredi.applications'
  })),
  getBanks: () => dispatch(actions.globalRequest({ url: endpoints.kredi.getBanks, key: 'filter.krediBanks' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationList);
