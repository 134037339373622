import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netahsilat';
import { defaultFilters, baseUrls } from 'lib/constants';
import { DataTable, ActionButtons, FadeIn } from "components/UIComponents";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { Checkbox, Tooltip, Icon, Button } from 'antd';
import Pos from './Detail'

class Poses extends Component {
  state = {
    filter: defaultFilters.netahsilat.TenantPos(),
  };

  componentDidMount() {
    this.getTenantPoses();
  };

  getTenantPoses = () => {
    const { filter } = this.state;
    this.props.getTenantPoses(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter, orderBy: [`${key} ${type}`]
      }
    }, this.getTenantPoses);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getTenantPoses);
  }

  additionalButtons = ({ Id, HasCommRate, IsDefaultPos }) => {
    const { accessRights } = this.props;

    const onClick = e => {
      e.stopPropagation();
      this.props.history.push({ pathname: `/netahsilat/pos/commissions/${Id}`, state: { HasCommRate, IsDefaultPos } });
    }

    return (
      accessRights.find(x => x.endPoint === 'PosCommRate' && x.method === 'GET') &&
      <Tooltip placement="bottom" title={i18n.t('btn.commissionRates')}>
        <Button className="#commissionsRate" size="small" onClick={onClick}>
          <Icon type="percentage" className={`comm-rate-icon ${HasCommRate ? "active" : ""}`} style={HasCommRate ? { color: '#1c9783', fontWeight: 'bold' } : {}} />
        </Button>
      </Tooltip>
    )
  }

  render() {
    const { poses, } = this.props;
    const columns = [
      {
        render: (row) =>
          <ActionButtons
            url="TenantPos"
            editUrl
            item={row}
            hasDelete
            getDatas={this.getTenantPoses}
            openDialog={this.datatable?.openDialog}
            additionalButtons={() => this.additionalButtons(row)}
          />,
        toggle: false, sort: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.logo'), key: 'Logo/LogoBig', sort: false, checkField: false, render: bank => <img src={bank.Logo.LogoBig || bank.Logo.LogoSmall} className="bank-icon big" alt={bank.Name} />, thClass: 'text-center', tdClass: 'text-center' },
      { label: i18n.t('lbl.bank'), key: 'PosName' },
      { label: i18n.t('lbl.name'), key: 'Name' },
      { label: i18n.t('lbl.description'), key: 'Description' },
      { label: i18n.t('lbl.currencyType'), key: 'CurrencyType', thClass: 'text-center', tdClass: 'text-center' },
      { label: i18n.t('lbl.isActive'), key: 'IsActive', render: (row) => <Checkbox checked={row.IsActive} />, thClass: 'text-center', tdClass: 'text-center' },
      { label: i18n.t('lbl.is3DActive'), key: 'Is3DActive', render: (row) => <Checkbox checked={row.Is3DActive} />, thClass: 'text-center', tdClass: 'text-center' },
      { label: i18n.t('lbl.defaultPos'), key: 'IsDefaultPos', render: (row) => <Checkbox checked={row.IsDefaultPos} />, thClass: 'text-center', tdClass: 'text-center' },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            access="TenantPos"
            history={this.props.history}
            newButton="openDialog"
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={poses.list.data}
            loading={poses.list.loading}
            title={i18n.t('route.netahsilat.poses')}
            getData={this.getTenantPoses}
            count={poses.list.count}
            excel={{ url: "ExportPos", module: "nth" }}
            Component={Pos}
            dialogTitle={i18n.t('lbl.pos')}
            deleteOptions={{ stateKey: "poses", url: "TenantPos", baseUrl: baseUrls.netahsilat }}
          />
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ netahsilat }) => ({
  poses: netahsilat.poses
});
const mapDispatchToProps = (dispatch) => ({
  getTenantPoses: (filter) => dispatch(actions.getAll({ filter, url: endpoints.netahsilat.tenantPos, key: 'poses', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Poses);