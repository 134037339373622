import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col, Modal } from 'antd';
import { Input } from 'components/UIComponents/NetbankaComponents';
import { CategoryModel } from 'models';
import actions from 'store/actions/netekstre';
import { Loading, FadeIn, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new CategoryModel(),
      InitialState: new CategoryModel()
    };
    this.id = props.id;
  };

  componentDidMount() {
    // this.props.getRoles();
    if (this.id) this.props.getCategory(this.id);
    else this.setState({ loading: false });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.incomingCategories.single.data && !state.data.id) {
      const data = new CategoryModel(props.incomingCategories.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }

    if (props.dialogClosing && !props.incomingCategories.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  save = (e) => {
    e.preventDefault();
    const { InitialState, data } = this.state
    const { form, tenantId, dialogCloseRequest } = this.props;
    const parameters = tenantId && { TenantId: tenantId }
    const _data = new CategoryModel(_.cloneDeep({ ...data, categoryType: 1 }));

    form.validateFields(error => {
      if (!error) {
        if (!_.isEqual(_data, InitialState)) {
          const confirmModal = Modal.confirm();

          confirmModal.update({
            title: i18n.t('msg.needToConfirmation'),
            content: i18n.t('msg.tenantCategoryUpdateWarning'),
            okText: i18n.t('btn.continue'),
            cancelText: i18n.t('btn.cancel'),
            okButtonProps: {
              onClick: () => {
                confirmModal.update({ okButtonProps: { loading: true } })
                this.saveData(_data, () => confirmModal.destroy(), parameters);
              }
            },
          })
        }
        else {
          dialogCloseRequest({ runGetData: true });
        }
      }
    });
  };

  saveData = (_data, callback, parameters) => {
    const { dialogCloseRequest } = this.props;
    const { data } = this.state;
    this.props.saveData(_data, (response) => {
      callback && callback();
      if (response)
        this.setState({ InitialState: data }, () => { dialogCloseRequest({ runGetData: true }); });
      else
        this.setState({ saving: false });
    }, parameters);
  }

  renderDialogButtons = checkAccessRights => {
    let { incomingCategories, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={incomingCategories.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={incomingCategories.single.loading} form="incomingCategories" htmlType="submit" className="success #save" icon="save" loading={incomingCategories.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      incomingCategories.single.saving,
      incomingCategories.single.loading
    )
  }


  render() {
    const { loading, data } = this.state;
    const { incomingCategories, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <FadeIn className="page-content">
        <CheckAccessRight {...{ ...incomingCategories, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="incomingCategories">
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#categoryName" label={i18n.t('lbl.categoryName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item>
                    {getFieldDecorator('categoryCode', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.categoryCode
                    })(
                      <Input className="#categoryCode" label={i18n.t('lbl.code')} onChange={(e) => this.update('categoryCode', e)} />
                    )}
                  </Form.Item>
                </Col>
                {/* <Col xs={24} md={12}>
                  <Form.Item>
                    {getFieldDecorator('roles', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.roles
                    })(
                      <Select
                        className="#roles"
                        options={roles.data}
                        optVal="id"
                        optName="name"
                        label={i18n.t('lbl.roles')}
                        onChange={(e) => this.update('roles', e)}
                        mode='multiple'
                        loading={roles.loading}
                      />
                    )}
                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({
  incomingCategories: netekstre.incomingCategoriesGeneral,

  // roles: common.filter.roles,
});
const mapDispatchToProps = (dispatch, { endpoint }) => ({
  clearState: () => dispatch(actions.clearState("incomingCategoriesGeneral")),
  getCategory: (id, callback) => dispatch(actions.get({ url: endpoint || endpoints.nte.tenantCategories, key: 'incomingCategoriesGeneral', id }, callback)),
  saveData: (data, callback, parameters) => dispatch(actions.put({ url: endpoint || endpoints.nte.tenantCategories, key: 'incomingCategoriesGeneral', data, parameters }, callback)),

  // getRoles: () => dispatch(commonActions.getFilter({ url: endpoints.lookups.roles, key: 'roles' })),
});
const CategoryForm = Form.create()(Category);
export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);