import React, { useState } from 'react';
import i18n from 'plugins/i18n';
import { Typography, FormElements, Button, FadeIn } from 'components/UIComponents';
import { Form, Icon, Modal } from 'antd';
import { connect } from 'react-redux'
import actions from 'store/actions/auth';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';


const ResetPassword = ({ form, resetPassword, history, loading }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ password: '', rePassword: '', resetPasswordGuid: queryString.parse(useLocation().search).guid });
  const [inputType, setInputType] = useState('password');
  const [resetStatus, setResetStatus] = useState(null);



  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      if (!error) {
        resetPassword(data, (response) => {
          if (response) {
            setResetStatus(true);
            Modal.success({
              title: i18n.t("msg.success"),
              content: i18n.t('msg.successfullySent'),
              centered: true,
              okText: i18n.t('btn.ok'),
              onOk: () => {
                setTimeout(() => {
                  Modal.destroyAll();
                  history.push('/auth/login');
                }, 500);
              }
            })
          }
          else {
            setResetStatus(false)
            setTimeout(() => setResetStatus(null), 2000);
          }
        });
      }
    });
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password'))
      callback(i18n.t('msg.passwordMatchError'));
    else
      callback();

  };

  const validateToNextPassword = (rule, value, callback) => {
    setTimeout(() => {
      if (value && form.getFieldValue('rePassword'))
        form.validateFields(['password'], { force: true });
      callback();
    }, 300);
  };

  return (
    <FadeIn>
      <Typography.H className="mb-0" size={1}>{i18n.t('lbl.setPassword')}</Typography.H>
      <Typography.P size="big">{i18n.t('lbl.youCanEnterANewPassword')}</Typography.P>
      <Form onSubmit={onSubmit} className="mt-80">
        <Form.Item className="mb-30">
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: i18n.t('msg.passwordRequired') },
              { validator: validateToNextPassword }
            ]
          })(
            <FormElements.Input
              suffix={
                <Icon
                  style={{ color: '#aaa' }}
                  onClick={() => setInputType(inputType === "password" ? "text" : "password")}
                  type="eye"
                />
              }
              prefix={<i className="fas fa-lock" />}
              type={inputType}
              onChange={(e) => updateData((data) => ({ ...data, password: e }))}
              label={i18n.t('lbl.newPassword')}
            />
          )}
        </Form.Item>
        <Form.Item className="mb-30">
          {getFieldDecorator('rePassword', {
            rules: [
              { required: true, message: i18n.t('msg.passwordRequired') },
              { validator: compareToFirstPassword }
            ]
          })(
            <FormElements.Input
              prefix={<i className="fas fa-lock" />}
              type={inputType}
              onChange={(e) => updateData((data) => ({ ...data, rePassword: e }))}
              label={i18n.t('lbl.confirmPassword')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            block
            htmlType="submit"
            className={`auth-button mb-20 ${resetStatus === true ? 'success' : resetStatus === false ? 'error' : ''}`}
            customIcon="right"
            customLoading={loading}
          // disabled={!data.resetPasswordGuid || data.resetPasswordGuid === ''}
          >
            {loading ? i18n.t('btn.settingPassword') : i18n.t(`btn.${resetStatus === true ? 'setPasswordSuccess' : resetStatus === false ? 'setPasswordFailed' : 'setPassword'}`)}
          </Button>
          <div className="text-center">
            <Button ghost block onClick={() => history.push('/auth/login')}>{i18n.t('btn.goToSignIn')}</Button>
          </div>
        </Form.Item>
      </Form>
    </FadeIn>
  )
}
const mapStateToProps = ({ auth }) => ({ ...auth });
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data, callback) => dispatch(actions.resetPassword(data, callback)),
});
const ResetPasswordForm = Form.create()(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
export default withRouter(ResetPasswordForm);

