import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Button, Modal } from "antd";
import i18n from "plugins/i18n";
import actions from "store/actions/common";
import utils from "lib";

class ToggleData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processStarted: false,
      loading: false,
    }
    this.parent = props.parent
  }

  startOrCancelProcess = () => {
    this.parent.setState({ ruleToggleStarted: !this.state.processStarted })
    this.setState({ processStarted: !this.state.processStarted })
  }

  onStartClick = () => {
    const { modalOptions, selectedItems } = this.props;
    const filter = { filter: this.parent.state.filter.filter };
    const modal = Modal.confirm();

    modal.update({
      title: modalOptions.title,
      content: modalOptions.content,
      okButtonProps: { className: '#okModal', icon: 'check' },
      cancelButtonProps: { className: '#cancelModal', icon: 'close' },
      okText: i18n.t('btn.confirm'),
      cancelText: i18n.t('btn.cancel'),
      onOk: () => {
        this.setState({ loading: true })
        this.props.toggleData(selectedItems, filter, () => {
          utils.common.clearSelectedItems();
          this.startOrCancelProcess();

          this.setState({ loading: false }, () => {
            modal.destroy();
            this.parent.getRules();
          })
        })
      },
    })

  }

  render() {
    const { processStarted, loading } = this.state;
    const { selectedItems, selectAll, disabled } = this.props;

    return (
      processStarted ?
        <Button
          loading={loading}
          onClick={this.onStartClick}
          className="pull-right mr-10 #synchronize btn-collapse"
          disabled={(selectedItems.length === 0 && !selectAll) || loading || disabled}
          type={!((selectedItems.length === 0 && !selectAll) || loading || disabled) ? 'default' : undefined}
          size="small"
        >
          {!loading && <Icon type="swap" />}
          {i18n.t('btn.enabledOrDisabled')}
        </Button>
        :
        null
    )
  }
}

const mapStateToProps = ({ common }) => ({
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch, { url }) => ({
  toggleData: (data, filter, callback) => dispatch(actions.post({ filter, data, url }, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ToggleData);