import React from 'react';
import { Layout, Col, Row } from "antd";
import ReactSVG from 'react-svg';
import Logo from 'assets/img/logo.svg'
import { HeaderRight } from 'components/LayoutComponents'

export default function Header() {
  return (
    <Layout.Header className="header-home">
      <Row className="header-row" type="flex" align="middle">
        <Col md={12} className="text-left">
          <ReactSVG className="brand-logo" src={Logo} />
        </Col>
        <Col md={12} className="text-right full-height">
          <HeaderRight />
        </Col>
      </Row>
    </Layout.Header>
  );
}