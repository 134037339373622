
import React, { forwardRef, useState } from 'react';
import { Input as AntInput } from "antd";
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';

const Amount = forwardRef((props, ref) => {
  const [focus, setFocus] = useState(false);

  const onChange = e => {
    let change = false;
    // eslint-disable-next-line
    var isNumber = /^[-+]?\d+[\.\,]?\d?\d?$/;
    if (e === "-" || e === "+") {
      change = true;
    }
    else if (e[0] === 0 && !e[1]) {
      change = false;
    }
    else if ((isNumber.test(e) || e.length === 0)) {
      change = true;
    }
    e = e.includes('.') ? e.replace('.', ',') : e;
    change && props.onChange && props.onChange(e);
  }
  

  const _prefix = typeof props.prefix === "string" ? <ReactSVG className="input-prefix" src={icons[props.prefix]} /> : props.prefix;
  return (
    <div className={`custom-amount__wrapper ${_prefix ? 'with-prefix' : ''}`}>
      <AntInput
        {...props}
        ref={ref}
        className={`${props.className || ''} custom-input`}
        onFocus={() => setFocus(!focus)}
        onBlur={() => setFocus(false)}
        onChange={(e) => onChange(e.target.value)}
        prefix={_prefix}
      />
      <label className={`${focus || props.value ? 'is-focus' : ''}`} htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
})

export default Amount;
