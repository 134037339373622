import React, { Component } from 'react';
import { Cascader as AntCascader } from 'antd';

class Cascader extends Component {

  render() {
    const { options, label, fieldNames, loadData, value, className, onChange } = this.props
    return (
      <div className="nb nb-cascader">
        <AntCascader {...{
          options,
          placeholder: ' ',
          fieldNames,
          loadData,
          className,
          onChange,
          value,
        }}
        />
        <label className={(value && value.toString() !== '') ? 'focused' : ''}>{label || ''}</label>
      </div>
    )
  }
}
export default Cascader

// Karşı hesap seçerken Iban/BankName/AccountNumber vs Name/Code/Title 