import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox } from 'antd';
import { Select, Input } from 'components/UIComponents/NetbankaComponents';
import { PosraporAccountModel } from 'models/posrapor';
import actions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class PosraporAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new PosraporAccountModel(),
      InitialState: new PosraporAccountModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.accounts.single.data && !state.data.id) {
      const data = new PosraporAccountModel({ ...props.accounts.single.data })
      data.isActive = props.accounts.single.data.isActive;
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.accounts.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getPosraporAccounts(this.id);
    else this.setState({ loading: false });
  };

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };


  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData } = this.props;
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };


  renderDialogButtons = checkAccessRights => {
    let { accounts, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={accounts.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={accounts.single.loading} form="accounts" htmlType="submit" className="success #save" icon="save" loading={accounts.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      accounts.single.saving,
      accounts.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { banks, dialogCloseRequest, accounts } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...accounts, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="accounts">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('posrapor.bankName')}
                        options={banks.data}
                        optVal="eftCode"
                        optName={(bankData) => utils.common.getBankName({ bankData })}
                        onChange={(e) => this.update('bankEftCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#name" label={i18n.t('posrapor.accountName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Checkbox checked={data.isActive} onChange={(e) => this.update('isActive', e.target.checked)} /> {i18n.t('lbl.isActive')}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor }) => ({
  accounts: posrapor.accounts,
  banks: posrapor.filter.banks,
});
const mapDispatchToProps = (dispatch) => ({
  getPosraporAccounts: (id, callback) => dispatch(actions.get({ url: endpoints.posrapor.posraporAccount, key: 'accounts', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.posrapor.posraporAccount, key: 'accounts', data }, callback)),
});
const PosraporAccountForm = Form.create()(PosraporAccount);
export default connect(mapStateToProps, mapDispatchToProps)(PosraporAccountForm);