import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import routes from "plugins/routes";
import { connect } from 'react-redux';
import { LoggingOut, Blur } from 'components/UIComponents';
import tr from 'antd/es/locale/tr_TR';
import en from 'antd/es/locale/en_US';
import { ConfigProvider } from 'antd';
import 'moment/locale/tr';
import ReactGA from 'react-ga';

const lang = { en, tr }

function initalGA() {
  ReactGA.initialize('UA-152522632-2');
  ReactGA.pageview(window.location);
}

function App(props) {
  initalGA()
  const [, updateState] = useState();

  useEffect(() => {
    updateState(props.i18nextLng);
  }, [props.i18nextLng])

  return (
    <div lang={props.i18nextLng.substring(0, 2)}>
      <ConfigProvider locale={lang[props.i18nextLng.substring(0, 2)]}>
        <BrowserRouter>
          <Blur>
            <LoggingOut />
            <Switch>
              {routes.map(route => (
                <Route path={route.path} render={(props) => <route.component {...props} />} key={route.name} />
              ))}
              <Redirect from="/" to="/auth" />
            </Switch>
          </Blur>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}


const mapStateToProps = ({ common }) => ({
  i18nextLng: common.i18nextLng,
});

export default connect(mapStateToProps)(App);
