import devMessages from "./devMessages";

export default {
  translation: {
    msg: {
      sendEmailLimitError: "Unable to send mail",
      sendEmailLimitErrorContent: "The number of data that can be mailed {{config}}. Email cannot be sent because you have exceeded the maximum limit. Try again by selecting the appropriate number of data.",
      downloadLimitError: "Unable to download",
      downloadLimitErrorContent: "Number of downloadable data {{config}}. Unable to download because you exceeded the maximum limit. Try again by selecting the appropriate number of data.",
      filesSent: 'File{{pluralSuffix}} sent',
      filesDownloaded: 'File{{pluralSuffix}} downloaded',
      bankNotSharingThisData: 'Bank is not sharing this data',
      requiredCategory: 'Please select at least one transaction category',
      requiredRoleOrUserOrNotify: "Role, User or 'Notify to current account' choice is required",
      timeoutSmsCodeWarning: 'Your SMS verification code has expired, you will be directed to the login screen',
      multiPersonEmailLimitContent: "You can send mail to a maximum of {{config}} different people.",
      cancelOrRefundSuccess: '{{type}} cancellation has been done successfully',
      cancelOrRefundError: '{{type}} process an error occurred',
      cancelOrRefundWarning: '{{type}} process suspended',
      anErrorOccurredOn3D: 'An unexpected error occurred during 3D payment',
      transactionSuccessfullyCompleted: 'Your transaction is successfully completed',
      transactionOnHold: 'Your transaction is on hold',
      anErrorOccurredInTransaction: 'An unexpected error occurred in your transaction',
      transactionStatusUnknown: 'The result of your transaction is unknown',
      requiredInstallmentSelection: 'Installment selection should be made',
      cvcHelpMessage: 'Please fill the 3-digit code on the back of your card.',
      fillCardAndAmountFields: 'Fill to amount and credit card fields',
      defaultPosDataSuccessfullyUpdated: 'Default Pos information successfully saved',
      paymentLinkSuccessfullySent: 'Payment link successfully sent',
      successful: 'Successful',
      successfullySavedPaymentParameters: 'Payment Parameters successfully saved',
      paymentCancelWarning: 'The payment link for the following information will be canceled. Do you confirm?',
      onlyRequestSentUsable: "Can be only used for 'Request Sent' payments",
      noLicencePackageSelect: 'No licence package select',
      notFoundModuleLicencePackages: 'Module data for the package could not be found',
      removedModuleName: 'Data of <b>{{removedModuleNames}}</b> module has been removed.',
      removedModuleNames: 'Data of <b>{{removedModuleNames}}</b> modules has been removed.',
      mustBeCheckUsingMonthlyOrYearly: "To be able to price, you must tick at least one of the monthly or yearly available features.",
      noModuleSelection: 'No module selection has been made yet',
      noPackageDefinitionForModule: 'There is no package definition detail for this module',
      unusableCuzCanceled: 'Unusable because transaction is canceled',
      onlySuccessfulTransactionUsable: 'Only successful transactions cancellable or refundable',
      notFoundPosLogos: 'Not found pos logos',
      selectPosFirst: 'You must choose a bank to view the logos',
      noPosParameters: 'No parameter found for this pos',
      thereIsNoChange: 'There is no change',
      pleaseFillRequiredFields: 'Please fill required fields',
      noUsersYet: 'No users have been added yet.',
      accessRightsNotFound: 'Access rights could not be found, access rights may not be defined for the company you are affiliated with.',
      moduleInfoNotFound: 'Module information could not be found, no application may have been selected in the tenant information.',
      activationMailSent: 'Activation mail sent',
      resendActivationMail: 'Resend activation e-mail',
      saveSuccess: 'Successfully saved',
      areYouSureToDelete: 'Are you sure you want to delete?',
      documentUploadError: 'An error occurred while uploading the document',
      deleteConfirm: 'It will be permanently deleted, do you confirm?',
      whileIfUncheckedCalculatedRulePoint: 'If not checked, the rule point will be calculated automatically.',
      noAccessRightChart: 'No Access Right Chart',
      pleaseClickContinueButton: 'Please click the continue button to create your new password.',
      addBankWithTypeCode1: 'Along with the Type Code 1 condition, the Bank condition must also be added.',
      addTypeCode1WithBank: 'Along with the Bank condition, the Type Code 1 condition must also be added.',
      tenantCategoryUpdateWarning: 'Due to the change made, the common category and common rules used will be out of use and a special category will be opened instead. Do you want to continue?',
      needToConfirmation: 'You Need To Confirm!',
      deleteCategoryWarning: '<b>Other</b> category will be assigned instead of the category you want to delete, do you confirm?',
      customRuleIsEqualToGeneralRule: 'Custom rule information derived from the common rule cannot be exactly the same as the original.',
      ruleEnabledOrDisabledDescription: 'The enabled/disabled statuses of the selected rules will be updated to be the opposite of the existing one, do you confirm?',
      ruleIsUpdated: 'Rule is Updated!',
      updateWarning: 'You made changes to the rule. Please choose between updating the existing rule or creating a new rule with your changes.',
      noAccessRightData: 'No Access Right',
      thereAreNoNotifications: "There are no notifications.",
      noNotification: "There are no new notifications to display.",
      multipleTimesNotificationWarning: "You are viewing this notification for the {{count}}. time",
      dataFetchError: 'The information could not be accessed, you can try again later.',
      maxRule: 'You can choose up to 5 rules',
      dataCreatedSuccessful: 'data(s) created successful',
      bankDeleteRuleItemInfo: 'Since you removed the bank condition, type code 1, type code 2, bank account and contact name conditions were also removed.',
      confirmUpdateThisField: 'You need to confirm to update the {{field}} field.',
      isExistSuccessfullySent: 'The operation is successful, if the e-mail address registered in the system, we will send a password renewal mail.',
      screenSaverMode: 'Screensaver Mode',
      displaysLast30DaysData: 'Reports the data of the last 30 days retrospectively from the Start Date.',
      displaysLast12MonthsData: 'Reports the data of the last 12 months retrospectively from the Start Date.',
      displaysLast5YearsData: 'Reports the data of the last 5 years retrospectively from the Start Date.',
      passwordResetConfirm: "The account password will be reset. Are you sure?",
      passwordResetSuccess: "Account password has been reset.",
      passwordUpdated: "Password has been successfully changed.",
      noAccessRightDashboard: "You are not authorized to view the Dashboard.",
      excelReady: 'Your Excel file is prepared. Click to download.',
      excelEmpty: 'Your excel file could not be produced because there is no data.',
      preparingExcelFile: 'Preparing Excel File',
      unchangeable: 'Unchangeable',
      noData: 'No Data',
      noTransactionDetails: 'No recorded transaction details were found.',
      amountEqualTotal: 'The total amount must be equal to the amount of the original transaction.',
      isSingleAndEqualTotal: 'No partitioning can be made with a single sub-transaction equal to the amount of the original transaction.',
      isTransferred: 'Splitting cannot be performed because at least one of these transactions is transferred.',
      leMin: 'No value should be entered under the lowest amount',
      geMax: 'No value should be entered over the highest amount',
      signErrorNull: 'The value should not be -/+ only',
      signError: '-/+ should not be used',
      notEndDotOrComma: 'Value should not end with a period or comma',
      noAcceptZero: 'Value should not be zero',
      invalidIBAN: 'Please enter a valid IBAN',
      missing: 'Missing',
      much: 'Much',
      noBankRule: "Please enter a bank condition first.",
      noBank: "Please add a bank condition.",
      noTypeCode1: "Please add type code 1 condition.",
      noRuleItem: "Please fill in all required fields for the condition!",
      noRuleItems: "Please enter at least one condition to define the rule!",
      reqLength: 'This field must be {{type}} {{length}} characters.',
      tokenNotFound: 'Token Not Found',
      noAccessRightManagementPanel: 'You are not authorized to access the administration panel.',
      noAccessRight: 'You are not authorized to view.',
      requiredfield: "At least one starred field is required.",
      cannotBeLessThan0: 'Value can not be less than 0',
      removingOldPassword: 'If you continue, your previous password will be lost.',
      passwordTooltip: 'If no update is made in this field, the existing value will be used.',
      addAtLeastOne: 'Please add at least one record.',
      cannotDeleteLastOne: 'This data cannot be deleted because at least one record has to remain.',
      pleaseFillOther: 'Please fill in other input first',
      screenLeaveTitle: "Do you want to leave screen?",
      screenLeaveMessage: "If you leave the screen, your changes will be canceled. Do you confirm?",
      putSuccess: "Successfully updated",
      updateSuccess: "Successfully updated",
      addSuccess: "Successfully added",
      patchSuccess: "Successfully updated",
      resentCodeSuccessfully: 'Your code has been successfully sent',
      resent: 'Code resent.',
      timedOut: 'Timed Out',
      resentCode: 'Would you like the verification code to be resent?',
      invalidFormat: 'Invalid Format',
      successfullySent: 'Successfully Sent',
      success: 'Success',
      anErrorOccurred: 'An Error Occurred',
      sorryAnErrorOccurred: 'Sorry, An Error Occurred',
      setPasswordFailed: 'Failed to setting password',
      passwordRequired: 'Password required',
      passwordMatchError: 'Passwords not match',
      invalidEmail: 'Please enter a valid email address',
      validationRequired: 'Validation Required',
      unknownError: 'An unknown error',
      wrongNameOrPassword: 'Wrong username or password',
      loginFailed: 'Login Failed',
      required: 'This field is required',
      emailInvalid: 'Invalid email',
      excelResponseStatus: "Import status from Excel",
      errorRefreshTokenForAnadolubank: 'An error occurred while refreshing the token.',
      bankReturnedCode: 'The transaction you wanted to perform on Anadolubank could not be finalized. The bank sent the following error code:  ',
      refreshTokenSuccess: 'Token refreshing is successful',
      refreshTokenSuccessDesc: 'Token has been successfully refreshed in Anadolubank parameters.',
      redirectWarning: 'You are redirecting...',
      redirectWarningMessage: "You will be directed to the bank's own service to continue this process, do you want to continue?",
      error: 'Error',
      removedUserBlockSuccessfully: 'Unblocked, you can login with your current password.',
      passwordRight: 'Your remaining password attempts: ',
      UserBlocked: 'User blocked',
      transactionTransferConfirmMessage: "Since there is a change in the filter during the transfer process, your <b>'Select All'</b> selection will be removed, do you confirm?",
      createPayrollResponse: 'Payroll has been prepared for your cheques that are suitable for the conditions you have specified.',
      excelExportLimitError: "Unable to export to Excel",
      excelExportLimitErrorContent: "Since the number of data that can be transferred to Excel exceeds the maximum limit {{config}}, transfer cannot be made. Try again by increasing the filters and decreasing the data count.",
      sorrySomeErrorsOccurred: 'Sorry, some errors occurred',

      //BE Messages
      Found: 'There are bank accounts of the bank to be deleted',
      MaximumOtpTryOverflow: 'You entered too many incorrect security codes. Please try again later.',
      BadRequest: 'Bad request',
      TooManyRequest: 'Too Many Request',
      NotFound: 'Not Found',
      SecurityCodeNotFound: "Incorrect security code. Please enter the security code sent to you.",
      TwoFactorSendSecurityCode: "Error sending security code.",
      InvalidResetPasswordCode: 'Invalid reset password code',
      SendResetPasswordCodeError: "Error sending password reset",
      InvalidOtp: 'Password is incorrect. Please enter the password sent to you. Remaining count: {{data}}',
      InvalidTckn: 'Identity verification failed',
      AlreadyHasOtp: "You already have an OTP. Try again later.",
      InternalServerError: "An unknown server error has occurred.",
      InvalidEmail: "You have entered an invalid email address",
      MemberAlreadyExist: "There is another member registered at this email address",
      InvalidPhone: "You have entered an invalid phone number",
      RePasswordIsNotEqualToPassword: "The passwords you entered do not match",
      MemberNotFound: "Member not found",
      UserNotFound: "User not found",
      TenantNotFound: "Tenant not found",
      InvalidSessionTime: "Session has expired. This period should be between 10 and 180 minutes",
      WrongUsernameOrPassword: "You entered the username or password incorrectly",
      BankAccountNotFound: "No bank account for the information you entered found",
      ApproveRequiredAgreements: "Please approve mandatory contracts",
      AgreementApproveError: "An error occurred while approving contracts",
      LoginError: "You entered the username or password incorrectly",
      InvalidCaptcha: "Invalid Captcha",
      NotAcceptable: "Invalid Model",
      WrongPasswordGuid: "Your password may already be updated. If you still want to update your password, please contact your system administrator.",
      OldPasswordUsed: 'Try again with a new password except the last 3 passwords you used before.',
      MustToBeInPassword: 'Password requirements are insufficient.',
      AlreadyUseIdentity: 'You already have this credential.',
      IdentityUseByAnotherMember: 'This ID is used by another member.',
      TwoFactorPhoneNumberNotFound: 'The phone number to send the security code to was not found.',
      TwoFactorVerificationError: 'Incorrect operation. Please try again later.',
      TenantApiServiceError: 'An error occurred while reading company parameters.',
      ErrorSendingOtp: 'An error occurred while sending OTP.',
      ActivationCodeNotFound: 'The activation code was not found.',
      TokenNotFound: 'Token not found.',
      ActivationCodeSendingError: 'An error occurred while sending the activation code.',
      OtpNotFound: 'OTP not found',
      MissingAuthorizationHeader: 'Please send your token information from the header of the request (Authorization) for authentication',
      BankNotFound: 'Bank not found.',
      BlockedUser: '{{trialCount}} times, you have requested login with the wrong username/password. Try again in {{minute}} minutes.',
      IncorrectCountOfLogins: 'Login request with incorrect user information.',
      SendInformBlockedUser: "The user was informed that his account was blocked",
      PasswordExpired: 'Your password has expired.',
      InvalidInputFormat: 'Account activation link is invalid or not available.',
      NotFoundActivePos: 'operation will not be carried out because you have no active pos.'
    },
    btn: {
      sendPaymentLink: 'Send payment link',
      impersonatedPayment: 'Impersonated payment',
      goToPayPage: 'Go to Pay Page',
      pay: 'Pay',
      seeAllInstalmentOptions: 'See all installment options',
      updateQrCode: 'Update QR Code',
      createQrCode: 'Create QR Code',
      sendToAnotherUser: 'Send To Another User',
      downloadPDFFiles: 'Download PDF Files',
      paymentDetail: 'Payment Detail',
      transactionDetail: 'Transaction Detail',
      print: 'Print',
      cancellation: 'Cancel',
      refund: 'Refund',
      paymentInfo: 'Payment Info',
      commissionRates: 'Commission rates',
      saveData: 'Save Data',
      saveNext: "Save and Next",
      uploadDocument: "Upload document",
      downloadSampleDocument: 'Download sample document',
      addManualPaymentDetail: 'Add Manual Payment Detail',
      reproductionCustomRule: 'Reproduction Custom Rule ',
      deactivate: 'Deactivate',
      activate: 'Activate',
      enabledOrDisabled: 'Enable/Disable',
      update: 'Update',
      newRule: 'New Rule',
      seeAll: "See All",
      clickToBuy: "Click to buy",
      sent: "Sent",
      bulkEdit: "Bulk Edit",
      includedInternalTransfer: 'Internal Transfers',
      addUser: 'Add New User',
      resetPassword: 'Reset Password',
      all: 'Select All',
      thisPage: 'Select This Page',
      download: 'Download',
      transferAndContinue: 'Transfer and Continue',
      approveAndContinue: 'Approve and Continue',
      startCreateOrder: 'Start Create Order',
      stopCreateOrder: 'Stop Create Order',
      addInvoice: 'Add Invoice',
      sendOrder: 'Send',
      createOrder: 'Create',
      admin: 'Management Panel',
      addSubTransaction: 'Add Sub Transaction',
      startTransfer: 'Start Transfer',
      cancelTransfer: 'Cancel Transfer',
      sync: 'Sync',
      startApprove: 'Start Approve',
      cancelApprove: 'Cancel Approve',
      confirmation: 'Approve',
      confirm: 'Confirm',
      refresh: 'Refresh',
      copy: 'Copy',
      synchronize: 'Synchronize',
      cancelSync: 'Cancel Sync',
      cancelSyncRule: 'Cancel Sync Rule',
      syncRule: 'Sync Rules',
      startSyncRule: 'Start Sync Rule',
      profile: 'Profile',
      changePassword: 'Change Password',
      more: 'More',
      goBack: 'Go Back',
      close: 'Close',
      next: "Next",
      back: "Back",
      save: 'Save',
      accountTransactions: 'View transactions for the account',
      allAssets: 'All assets',
      yes: "Yes",
      no: "No",
      createTransactionUrl: 'Create application link',
      detail: 'Detail',
      cancel: 'Cancel',
      continue: 'Continue',
      continueWithoutLogin: 'Continue Without Login',
      goToSignIn: 'Go To Sign In',
      setPasswordFailed: 'Failed',
      setPasswordSuccess: 'Successfully Set',
      settingPassword: 'Setting Password',
      setPassword: 'Set Password',
      send: 'Send',
      ok: 'OK',
      delete: 'Delete',
      edit: 'Edit',
      newRecord: 'Add New',
      excelExport: "Export to Excel",
      excelImport: "Import from Excel",
      list: 'List',
      actions: 'Actions',
      clear: 'Clear',
      en: 'English',
      tr: 'Türkçe',
      returnHome: 'Return to Home',
      signIn: 'Sign In',
      allFirm: 'All Firms',
      cumulative: 'Cumulative',
      logout: 'Logout',
      downloadErrorsExcel: "Download Errors Excel",
      getRefreshToken: "Refresh Token",
      youAreRedirecting: "Redirecting...",
      returnToSignIn: 'Return to sign in',
      resend: 'Resend',
      addCheque: 'Add Cheque',
      startCreatePayroll: 'Start Create Payroll',
      stopCreatePayroll: 'Stop Create Payroll',
    },
    lbl: {
      refund: 'Refund',
      clickTheThreeDots: 'Click on the three dots (...) for details.',
      onUs: 'OnUs',
      notOnUs: 'NotOnUs',
      format: 'Format',
      yes: "Yes",
      no: "No",
      blockedDays: 'Blocked Days {{add}}',
      commitDate: 'Commit Date {{add}}',
      authCode: 'Auth Code',
      businessCard: 'Is Business',
      customersFor: 'Customers for {{tenant}}',
      countryCode: 'Country Code',
      passportNo: 'Passport Number',
      userCode: 'User Code',
      foreignNational: 'Foreign National',
      customer: 'Customer',
      member: 'Member',
      membershipDate: 'Membership Date {{add}}',
      installmentTable: 'Installment Table',
      iWannaPayWith3DSecure: 'I want to pay with 3D secure',
      singlePayment: 'Single Payment',
      agreementAndKVKKText: 'Agreement and KVKK Text',
      agreementAndKVKKCheckboxLabel: 'I have read and approve the Contract and KVKK Agreement',
      contactInfo: 'Contact Info',
      selectYourInstalmentOptions: 'Choose the installment option that suits you',
      cvc: 'CVC',
      expiryShort: 'MM/YY',
      cardHolderFullName: 'Card Holder Full Name',
      paymentType: 'Payment Type',
      tenantCode: 'Tenant Code',
      relatedTenantCode: 'Related Tenant Code',
      defaultPos: 'Default Pos',
      useDefaultPos: 'Use default Pos',
      infoForQrOrder: 'QR Order Payment Information',
      youCanPayWithQr: 'You can pay with QR',
      paymentSet: 'Payment Set',
      emailAddress: 'E-Mail Address',
      isDisposablePayLink: 'Is Disposable Pay Link',
      period: 'Period',
      linkTimeout: 'Link Timeout',
      selectLanguage: 'Select Language',
      transactionNumber: 'Transaction Number',
      enterAmount: 'Enter Amount',
      qrOrder: 'QR Order',
      smsOrder: 'SMS Order',
      emailOrder: 'E-Mail Order',
      creditCard: 'Credit Card',
      payWith: 'Pay with {{type}}',
      payToInsteadOfPerson: 'Pay To Instead Of Person',
      searchFirm: 'Search firm',
      bankBasedOnusNotOnusChart: 'Bank Based OnUs/NotOnus Distribution Chart',
      bankBasedCreditCardTypeChart: 'Bank Based Card Type Distribution Chart',
      unknown: 'Unknown',
      cardBanks: 'Card Banks',
      cancelSend: 'Cancel Send',
      confirmSelectedRecords: 'Do you want to download the selected recordings ?',
      multiplePdfDownload: 'Multiple PDF Download',
      multiplePdfMailSend: 'Multiple PDF Email Send',
      cancelDownload: 'Cancel Download',
      isDisplayAgreement: 'Display Agreement',
      paymentPageAgreement: 'Payment Page Agreement',
      isDisplayPaymentForm: 'Display on payment form',
      paymentPageDescription: 'Payment Page Description',
      virtualKeyboard: 'Virtual Keyboard',
      isRequired: 'Required',
      isDisplay: 'Display',
      fieldName: 'Field Name',
      paymentSettings: 'Payment Settings',
      criticalBalanceLimit: 'Critical Balance Limit',
      resendPaymentInfo: 'Resend Payment Info',
      requestDate: 'Request Date',
      consumerInfo: 'Consumer Info',
      requestBy: 'Request By',
      netlinkTransaction: 'NetLink Transaction',
      sendType: 'Send Type',
      sentDate: 'Sent Date {{add}}',
      requestSent: 'Request Sent',
      pending: 'Pending',
      unsuccessful: 'Unsuccessful',
      successful: 'Successful',
      lastPaymentDate: 'Last Payment Date',
      isUserTypeAdmin: 'Is user type admin?',
      unused: 'Unused',
      monthlyTotalAmount: 'Monthly Total Amount',
      yearlyTotalAmount: 'Annual Total Amount',
      yearlyTransactionLimit: 'Annually Transaction Limit',
      monthlyTransactionLimit: 'Monthly Transaction Limit',
      invoiceType: 'Invoice Type',
      useMonthly: 'Usable on monthly',
      useYearly: 'Usable on annually',
      monthlyTotalPackageAmount: 'Monthly Total Package Amount',
      yearlyTotalPackageAmount: 'Annually Total Package Amount',
      monthlyMaintenanceAmount: 'Monthly Maintenance',
      yearlyMaintenanceAmount: 'Annually Maintenance',
      yearlyAmount: 'Yearly Amount',
      monthlyAmount: 'Monthly Amount',
      vatRate: 'Vat Rate',
      prices: 'Prices',
      packageDefinition: 'Package Definitions',
      own: 'Own',
      refundAmount: 'Refund Amount',
      performingBy: 'Performing By',
      cardType: 'Card Type',
      cardHolderName: 'Card Holder Name',
      cardInfo: 'Card Info',
      ipAddress: 'IP Address',
      provisionNumber: 'Provision Number',
      authorizationNumber: 'Authorization Number',
      paymentUserName: 'Pay From',
      paymentInfo: 'Payment Info',
      pageTotal: 'Page Total',
      sales: 'Sales',
      cancelOrRefund: 'Cancel/Refund',
      generalTotals: 'General Totals',
      referenceNumber: 'Reference Number',
      netAmount: 'Net Amount',
      operationStatus: 'Operation Status',
      operationType: 'Operation Type',
      posParameters: 'Pos BankParameters',
      posInfo: 'Pos Info',
      pos: 'Pos',
      posActive: 'Pos Active',
      useCvv: 'Use CVV',
      threeDSecureType: '3D Secure Type',
      selectPaymentLogo: 'Select the logo to be seen on the payment screen',
      plusInstallment: '+Installment',
      plusInstallmentLowerLimit: '+Installment Lower Limit',
      plusInstallmentOverLimit: '+Installment Over Limit',
      deferral: 'Deferral',
      deferralLowerLimit: 'Deferral Lower Limit',
      deferralOverLimit: 'Deferral Over Limit',
      instalmentDeferralAndPlusInstallment: 'Installment Deferral ve +Installment',
      maxLimit: 'Max Limit',
      minLimit: 'Min Limit',
      bankCommission: 'Bank Commission',
      customerCommission: 'Customer Commission',
      installment: '{{installment}} Installment',
      commRates: 'Commission Rates',
      posCommission: 'Pos Commissions',
      logo: 'Logo',
      is3DActive: 'Is 3D active?',
      currencyType: 'Currency Type',
      prevValue: 'Prev value: {{value}}',
      emptyValue: 'Empty',
      newTenant: 'Add New {{tenantType}}',
      editTenant: 'Edit {{tenantType}}',
      moduleInfo: 'Module Information',
      usableModules: 'Usable Modules',
      partnerTenants: 'Partner Tenants',
      groupTenants: 'Group Tenants',
      tenantStatus: 'Tenant Status',
      processStatus: 'Process Status',
      membershipOperations: 'Membership',
      documentNotUploaded: "Document not uploaded",
      waitingForApproval: "Waiting for approval",
      documentsIncorrectorMissing: "Documents incorrect/missing",
      approved: "Approved",
      notApproved: "Not approved",
      commercialRegistryNewspaper: 'Commercial Registry Newspaper',
      taxBoard: 'Tax Board',
      signatureCirculars: 'Signature Circulars',
      identityCard: 'Identity Card',
      fullAddress: 'Full Address',
      documents: 'Documents',
      insertedTime: 'Inserted Time',
      MICR: 'MICR',
      Cheque: 'Cheque',
      institutionCode: 'Institution Code',
      sftp: 'SFTP',
      hostName: 'Host',
      portName: 'Port',
      upFolder: 'Up Folder',
      downFolder: 'Down Folder',
      downloadExampleFile: 'Download Example File',
      uploadChequeWithFile: 'Upload Cheque With File',
      createCheque: 'Create Cheque',
      returnToSignIn: 'Return to sign in',
      getRefreshToken: "Refresh Token",
      youAreRedirecting: "Redirecting...",
      blockageAmount: 'Blockage Amount',
      currentBalance: 'Current Balance',
      creditLimit: 'Credit Limit',
      availableBalanceWithCredit: 'Available Balance With Credit',
      order: 'Order',
      suffix: 'Suffix No',
      enterRulePoint: 'Enter rule point',
      piece: 'Piece',
      monthlyDistributionOfValueDatePayments: 'Monthly Distribution of Value Date Payments Based on Amounts',
      transactionPiece: 'Transaction Piece',
      top10DealersOnLimitBasis: 'Top 10 dealers on limit basis',
      yesterdayIncomingPayments: 'Yesterday Incoming Payments',
      cardNumber: 'Card Number',
      isRepresentative: 'Is Representative?',
      doBlockedUserActiveWaitMessage: 'Please wait while your account is unblocked, after this you will be able to login.',
      pleaseWait: 'Please Wait...',
      general: 'General',
      categoriesForTenant: '{{categoryType}} {{type}} Categories for  {{tenant}}',
      customCategories: 'Custom Categories',
      generalCategory: 'General Category',
      enabledOrDisabled: 'Enabled/Disabled',
      ruleEnabledOrDisabled: 'Rule Enabled/Disabled Update',
      generalRulesForTenant: 'General Rules for {{tenant}}',
      customRulesForTenant: 'Custom Rules for {{tenant}}',
      rule: 'Rule',
      futurePayment: 'Future Payment',
      completedPayment: 'Completed Payment',
      modules: 'Modules',
      orderStatus: 'Order Status',
      unchangeable: 'Unchangeable',
      paymentDetails: 'Payment Details',
      customerBankAccountDetails: 'Customer Bank Account Details',
      customerBankAccount: 'Customer Bank Account',
      linkToCurrentAccount: 'Link to current account',
      payment: 'Payment',
      incomingPayments: 'Incoming Payments',
      remainingPayments: 'Remaining Payments',
      pendingPayments: 'Pending Payments',
      totalOperationGiro: 'Total Operation Giro',
      vendorPaymentDistribution: 'Vendor Payment Distribution',
      bankBasedTotalLimit: 'Bank Based Total Limit',
      bankBasedPaymentDistribution: 'Bank Based Payment Distribution',
      monthlyTotalPayment: 'Monthly Total Payment',
      lastYear: 'Last year',
      bankBasedTransactionDistribution: 'Bank Based Transaction Distribution',
      valorPureAmount: 'Valor Amount',
      transactionPureAmount: 'Transaction Amount',
      notifyToCustomerRepresentative: "Notify to customer representative",
      customerRepresentatives: "Customer Representatives",
      currentAccountSelectedItemText: "Current Account Field Name",
      dynamicFieldName: "Dynamic Field Name",
      matchingCondition: "Matching Condition",
      countOfViews: "Count of views",
      unread: "Unread",
      notifications: "Notifications",
      bankAddedSuccessful: 'Bank Added Successful',
      noCheckCurrentAccount: "No Check Current Account",
      currentAccountSingleMatch: "Current Account Single Match",
      currentAccountNotMatch: "Current Account Not Match",
      currentAccountMultiMatch: "Current Account Multiple Match",
      remainingPayment: "Remaining Payment",
      commission: "Commission",
      chip: "Chip",
      point: "Point",
      award: "Award",
      pleaseFillSecurityCode: "Please enter the SMS password sent to your phone",
      smsVerifyCode: "SMS Verify Code",
      pendingPayment: "Pending Payment",
      incomingPayment: "Incoming Payment",
      dailyIncoming: "Daily Incoming",
      dailyOutgoing: "Daily Outgoing",
      moduleInactive: '{{module}} module is inactive.',
      clickForProductInformation: 'Detailed information about the product and click to buy.',
      willBeAvailableSoonHeader: 'What is {{module}}?',
      willBeAvailableSoon: 'Will be available soon.',
      bankCount: 'Bank Count {{add}}',
      bankLimitCount: "Bank Limit (Min)",
      bankLimit: "Bank Limit",
      currentAccountAddedSuccessful: "Current Account Added Successful.",
      posBased: "Pos Based",
      paymentAmount: "Payment Amount",
      paymentCount: "Payment Count",
      vendor: "Vendor",
      subVendor: "Sub Vendor",
      physical: "Physical",
      virtual: "Virtual",
      key: 'Key',
      Job: 'Job',
      jobName: 'Job Name',
      cron: 'Cron',
      lastRun: 'Last Run',
      jobStatus: 'Job Status',
      lastRunDate: 'Last Run Date {{add}}',
      testDataStatus: 'Create Test Data Status',
      packages: 'Packages',
      partnertenant: 'Partner Tenant',
      true: 'True',
      false: 'False',
      firm: 'Firm',
      saved: 'Saved',
      unSaved: 'Unsaved',
      withInvoice: 'Invoiced',
      withoutInvoice: 'Unreceipted',
      jobsForTenant: "Jobs for {{tenant}}",
      psrParametersForTenant: 'Posrapor BankParameters for {{tenant}}',
      banksForTenant: 'Banks for {{tenant}}',
      currentAccountsForTenant: 'Current Accounts for {{tenant}}',
      dbsParametersForTenant: 'DBS BankParameters for {{tenant}}',
      pleaseSelectTenant: 'Please Select Tenant',
      dynamicField: 'Dynamic Field',
      'bank/branch/account': 'Bank/Branch/Account',
      erpName: 'Erp Name',
      bankBased: 'Bank Based',
      accountBased: 'Account Based',
      paymentDate: 'Payment Date {{add}}',
      totalAmount: 'Total Amount',
      totalCount: 'Total Count',
      consumerAccountNumber: 'Consumer Account Number',
      consumerTitle: 'Consumer Title',
      excelFormat: 'Excel Format',
      createPayment: 'Create Payment',
      invoice: 'Invoice',
      reporting: 'Reporting',
      creatingInstruction: 'Order',
      reportLine: 'Report Line',
      tenantInstallmentCount: 'Tenant Installment Count {{add}}',
      installmentNumber: 'Installment Number {{add}}',
      commissionAmount: '∑ Commision Amount',
      commissionRate: '∑ Commision Rate',
      installmentAmount: 'Installment Amount',
      installmentNetAmount: 'Installment Net Amount',
      valorDate: 'Valor Date {{add}}',
      installmentCount: 'Installment Count {{add}}',
      installments: 'Installments',
      userName: 'Username',
      serviceType: 'Service Type',
      account: 'Account',
      parameter: 'Parameter',
      customerInfo: 'Customer Info',
      unmatched: 'Unmatched',
      matched: 'Matched',
      value: 'Value',
      generalRule: 'General Rule',
      generalRules: 'General Rules',
      delete: "Delete",
      newRecord: "Add New",
      date: 'Date',
      incomingTotal: 'Incoming Total',
      outgoingTotal: 'Outgoing Total',
      openingBalance: 'Opening Balance',
      closingBalance: 'Closing Balance',
      detail: 'Detail',
      savedCount: 'Saved',
      unsavedCount: 'Unsaved',
      transactionCount: 'Transaction Count',
      licenceType: "Licence Type",
      accessRights: 'Authorizations',
      requiredAccessRight: "You must choose at least 1 authorization.",
      hidingDay: 'Hiding Day Count',
      vknTckn: 'VKN / TCKN',
      relatedTenant: 'Related Firm',
      maxTenantCount: 'Maksimum Tenant Count',
      tenantLogo: 'Firm Logo',
      usingErpType: 'Using Erp Type',
      distributableErpType: 'Distributable Erp Type',
      logType: 'Log Type',
      tenantPackages: 'Tenant Packages',
      isActive: "Active",
      canUsePortal: 'Can use Portal',
      canUseMobile: 'Can use Mobile',
      canUseErp: 'Can use ERP',
      canUseErpService: 'Can use ERP',
      addMinOneUser: "You must enter at least 1 user.",
      unitPrice: "Unit Price",
      totalPrice: "Total Price",
      newLicence: "Add New Licence",
      updateLicence: "Update Licence",
      firmInformation: "Firm Informations",
      licenceInformation: "Licence Informations",
      firmUsers: "Firm Users",
      editCompany: "Edit Firm",
      bankTransactionSplit: "Bank Transaction Split",
      approvedTransfer: 'I approve the transfer of the movement.',
      transaction: 'Transaction',
      downloadAsPDF: 'Download As PDF',
      sendMail: 'Send Mail',
      sendAsMail: 'Send As Mail',
      syncSuccessfulAgain: 'Would you like repeats transferred successfully? ',
      synchronize: 'Synchronize',
      confirmation: 'Confirmation',
      doYouConfirm: 'Would you like to confirm?',
      doYouConfirmSync: 'Would you like to transfer the successfully transferred again?',
      integrationStatus: 'Int. Status',
      bankAccountBalance: 'Balance',
      contactName: 'Contact Name',
      documentNumber: 'Doc. Number',
      category: 'Category',
      operator: "Operator",
      domain: 'Domain',
      equal: 'Equal',
      contains: 'Contains',
      startsWith: 'Starts With',
      endsWith: 'Ends With',
      greater: 'Greater',
      less: 'Less',
      custom: 'Custom',
      lessEqual: 'Less Equal',
      greaterEqual: 'Greater Equal',
      condition: "Condition",
      conditions: "Conditions",
      fieldDefinitions: 'Field Definitions',
      priority: 'Priority',
      notifyToCurrentAccount: 'Notify To Current Account.',
      notification: 'Notification',
      template: 'Template',
      passwordResetConfirm: "The account password will be reset. Are you sure?",
      passwordResetSuccess: "Account password has been reset.",
      passwordUpdated: "Password has been successfully changed.",
      token: 'Token',
      userCount: "User Count",
      accountCount: "Account Count",
      groupTenantUserCount: 'Group Tenant User Count',
      groupTenantAccountCount: 'Group Tenant Account Count',
      groupTenantTransactionCount: 'Group Tenant Transaction Count',
      usedInfo: 'Used Info',
      newCompany: "New Tenant",
      newGroupTenant: 'New Group Tenant',
      newPartnerTenant: 'New Partner Tenant',
      editPartner: "Edit Partner Firm",
      newPartner: "Add New Partner Firm",
      editGroupCompany: "Edit Group Firm",
      newGroupCompany: "Add New Group Firm",
      companyNameCode: "Firm Name / Code",
      partnerFirm: "Partner Firm",
      groupFirm: "Group Firm",
      users: "Users",
      active: 'Active',
      companyName: 'Firm Name',
      companyCode: 'Firm Code',
      packageName: "Package Name",
      packageStatus: "Package Status",
      accountLimit: "Account Limit",
      transactionLimit: "Transaction Limit",
      userLimit: "User Limit",
      accountLimitCount: "Account Limit (Least)",
      transactionLimitCount: "Transaction Limit (Least)",
      userLimitCount: "User Limit (least)",
      unlimited: 'Unlimited',
      groupTenant: "Group Firm",
      partnerTenant: "Partner Firm",
      tenant: 'Tenant',
      grouptenant: "Group Tenant",
      licenceEndDate: 'Licence End Date',
      licenceEndDateStart: 'Licence End Date (Start)',
      licenceEndDateEnd: 'Licence End Date (End)',
      licence: 'Licence',
      erp: "ERP",
      mobile: "Mobile",
      portal: "Portal",
      firmType: "Firm Type",
      contractualType: 'Contract Type',
      workType: 'Work Type',
      status: "Status",
      inactive: "Inactive",
      minUsedTransactionCount: 'Transactions (Least)',
      maxUsedTransactionCount: 'Transactions (Most)',
      minUsedUserCount: 'Users (Least)',
      maxUsedUserCount: 'Users (Most)',
      minUsedAccountCount: 'Accounts (Least)',
      maxUsedAccountCount: 'Accounts (Most)',
      accounts: 'Accounts',
      transactions: "Transactions",
      daily: "Daily",
      monthly: "Monthly",
      yearly: "Yearly",
      minSavedCount: 'Should Keep (Least)',
      maxSavedCount: 'Should Keep (Most)',
      minUnsavedCount: 'Should not Keep (Least)',
      maxUnsavedCount: 'Should not Keep (Most)',
      voucherTypes: 'Voucher Type',
      erpVoucherCode: 'Erp Voucher Code',
      erpVoucherName: 'Erp Voucher Name',
      typeCode: "Type Code",
      typeCodeType: "Type Code Type",
      description: "Description",
      erpType: "ERP Type",
      customRule: 'Custom Rule',
      syncRule: 'Sync Rule',
      includeCompleted: 'Include Completed',
      transferStatus: 'Transfer Status',
      incomingCategory: "{{type}} Incoming Category",
      outgoingCategory: "{{type}} Outgoing Category",
      bankAccountCode: "Bank Account Code",
      voucherType: 'Voucher Type',
      typeOne: "Type Code 1",
      typeTwo: "Type Code 2",
      accountPlanCode: "Account Plan Code",
      exampleDescription: "Example Description",
      oldPassword: 'Enter your previous password',
      userInfo: 'User Information',
      currentVersion: 'Current Version',
      feature: 'Feature',
      improving: 'Improving',
      bug: 'Bug',
      versioning: 'Version {{version}} Innovations',
      currentAccount: 'Current Account',
      companyTitle: 'Company Title',
      company: 'Company',
      person: 'Person',
      bankAccountNumbers: 'Bank Account Numbers',
      bankParameters: 'Bank Parameters',
      tenantBank: 'Bank',
      merchantCode: 'Merchant Code',
      parameters: 'Parameters',
      user: 'User',
      banks: 'Banks',
      permissions: 'Permissions',
      role: 'Role',
      mainUserGroup: 'Main User Group',
      fieldDefinition: 'Field Definition',
      required: 'Required',
      min: 'Min',
      max: 'Max',
      minAmount: 'Min Amount',
      maxAmount: 'Max Amount',
      categoryInitializer: 'Transaction Category',
      transactionCategoryType: 'Category Type',
      id: 'Id',
      list: 'List',
      automaticRule: 'Automatic Rule',
      textInput: 'Text Input',
      formulaPattern: 'Formula/Pattern',
      process: 'Process',
      target: 'Target',
      source: 'Source',
      title: 'Title',
      code: 'Code',
      thisIsATestData: 'This is a test data.',
      movementCategories: 'Transaction Categories',
      bankAccounts: 'Bank Accounts',
      add: 'Add',
      edit: 'Edit',
      transactionCategory: 'Transaction Category',
      categoryName: 'Category Name',
      outgoingCategories: 'Outgoing Categories',
      incomingCategories: 'Incoming Categories',
      roles: 'User Roles',
      transactionTransferType: 'Transaction T. Type',
      erpCode: 'Erp Code',
      card: 'Card',
      table: 'Table',
      cumulativeBalance: 'Cumulative {{currency}}',
      branchNumber: 'Branch Number',
      branchName: 'Branch Name',
      accountNumber: 'Account Number',
      balance: 'Balance',
      lastTransactionDate: 'Last Transaction Date',
      lastRequestDate: 'Last Request Date',
      search: 'Search',
      currencyBalances: "Cumulative Balances",
      currencyCode: 'Currency Date',
      accountName: 'Account Name',
      endDate: 'End Date',
      startDate: 'Start Date',
      tenantName: 'Tenant Name',
      administrator: 'Admin',
      noRecord: "No Record Found",
      statusDate: "Status Date {{add}}",
      bank: "Bank",
      start: "Start",
      end: "End",
      creditLinkSent: 'Credit Application Link Sent',
      creditWaitingApproval: 'Application Process Continues',
      applicationCompleted: 'Application Completed',
      applicationDate: 'Application Date {{add}}',
      bankName: 'Bank Name',
      referenceCode: "Reference Code: {{code}}",
      sendingUrlUserInfo: 'Enter the information of the user you want to send the application link to',
      qrDescription: "Please read the QR code on the screen to continue the application. In order to continue with the QR code, you need to have a QR code reader on your phone. If it is not installed, you can download it from AppStore or PlayStore.",
      qrCode: 'QR Code',
      feeAmount: '{{amount}}₺ of this application will be charged as a service fee.',
      creditAmount: 'Credit application amount',
      transferredAmount: 'Amount to be transferred',
      amount: 'Amount',
      maturityRate: 'Maturity Rate',
      month: 'Month',
      maturity: 'Maturity',
      totalInstallmentAmount: 'Total Amount',
      bankRate: 'Bank Amount',
      installmentOptions: 'Installment Options',
      productType: 'Product Type',
      pleaseProductType: 'Please select the type of product to be paid',
      bankSelect: 'Select Bank',
      pleaseSelectYourBankAccount: 'Please select the bank account',
      applyCredit: 'Apply Credit',
      toView: 'To View',
      pleaseSignIn: "Sign In",
      mailSuccessfullySentEmail: 'An activation link has been sent to your e-mail address. Please complete the activation processes',
      youCanEnteredSystemYourInformation: 'You can login to the system with the information you provided.',
      registrationSuccessfully: 'Your registration process has been successfully completed',
      defaultAgreementAcceptance: 'I have read and approve the contract information.',
      neighborhood: 'Neighborhood',
      district: 'District',
      city: 'City',
      address: 'Address',
      tenantContactInfo: 'Tenant Contact Info',
      pleaseEnterTenantContactInformation: 'Please enter the contact information of the company',
      taxOffice: 'Tax Office',
      taxNo: 'Tax Number',
      memberTenantName: 'Member Company Name',
      ibanNo: 'IBAN NO',
      pleaseEnterTenantInformation: "Please enter the information of the company",
      resend: 'Resend',
      remainingTime: 'Remaining Time',
      pleaseEnterVerificationCode: 'Please enter the verification code sent to the phone number you entered',
      name: 'Name',
      surname: 'Surname',
      birthDate: 'Birth Date',
      tckn: 'TCKN',
      gsm: 'GSM No',
      pleaseEnterSignatoryInformation: 'Please enter the information of the company signatory',
      passwordSettings: 'Password Settings',
      contractInfo: 'Contract Information',
      signatoryInfo: 'Signatory Information',
      tenantInfo: 'Tenant Information',
      comingSoon: 'Coming Soon',
      newPassword: 'Set password',
      confirmPassword: 'Confirm password',
      setPassword: 'Set password',
      youCanEnterANewPassword: 'You can enter a new password',
      forgotPasswordMessage: 'Enter your registered e-mail address to enter a new password. We will send the password change link to your email address',
      all: 'All',
      total: 'Total',
      record: 'Record',
      bankAccountNumber: 'Bank Account Number',
      iban: 'IBAN',
      currentAccountCode: 'Current Account Code',
      currentAccountName: 'Current Account Name',
      currentAccountType: 'Current Account Type',
      nameSurname: 'Name Surname',
      email: 'Email',
      phone: 'Phone',
      mobilePhone: 'Mobile Phone',
      tc: 'TCKN',
      taxNumber: 'Tax Number',
      onlineJobNumber: 'Online Job Number',
      filter: 'Filter',
      currency: 'Currency Rates',
      tcmbDatas: 'Datas of Central Bank',
      buying: 'Buying',
      selling: 'Selling',
      cashAsset: "Cash Assets {{add}}",
      overdraftAccountBalance: "Overdraft {{add}}",
      netAsset: "Net Assets {{add}}",
      accountBalance: "Bank Based {{isCumulative}} Account Balance Distribution",
      bankBasedDistribution: "Periodic Incoming/Outgoing Bank Based {{isCumulative}} Distribution",
      countProcess: "Process Count",
      totalProcess: "Total Process",
      transactionAmount: "Transaction Amount",
      transactionStatus: "Transaction Status",
      transactionDate: "Transaction Date {{add}}",
      moneyTransferDate: "Money Transfer Date",
      totalTransferInOut: "Total Monthly Incoming/Outgoing {{isCumulative}} Transactions",
      incoming: 'Incoming',
      outgoing: 'Outgoing',
      Giden: 'Outgoing',
      Gelen: 'Incoming',
      ocak: 'January',
      şubat: 'February',
      mart: 'March',
      nisan: 'April',
      mayıs: 'May',
      haziran: 'June',
      temmuz: 'July',
      ağustos: 'August',
      eylül: 'September',
      ekim: 'October',
      kasım: 'November',
      aralık: 'December',
      yesterday: 'Yesterday',
      today: 'Today',
      last7day: 'Last 7 Days',
      last30day: 'Last 30 Days',
      cumulative: 'Cumulative',
      welcomeMessage: 'Welcome. Please sign in',
      username: 'Username',
      password: 'Password',
      rememberMe: 'Remember Me',
      forgotPassword: 'Forgot Password',
      managementPanel: 'Management Panel',
      assets: 'My Assets',
      pendingInvoices: 'Pending Invoices',
      pendingOrders: 'Pending Orders',
      waitingTransferToERP: "Waiting Transfer To ERP",
      preConfirmCredit: 'Pre-Confirm Credit',
      totalUnpaidAmount: 'Total Unpaid Amount',
      totalCheckAmount: 'Total Check Amount',
      dealerName: "Dealer Name",
      bankCode: 'Banka',
      chequeUserNameSurname: 'Ad Soyad / Unvan',
      chequeNumber: 'Cheque Number',
      kesideCity: 'City',
      kesideDate: 'Date {{add}}',
      chequeStatus: 'Cheque Status',
      orderDescription: 'Order Description',
      createdDate: 'Created Date {{add}}',
      payrollNumber: 'Bordro No',
      titleU: 'Title',

      //BE
      PayInstead: "Pay Instead",
      PayByCard: "Pay By Card",
      PayByCustomerCard: "Pay By Customer Card"
    },
    status: {
      ToBeTransferred: "New / To Be Transferred",
      Completed: "Completed",
      Fail: "Fail",
      PriorityFail: "Priority Fail",
      NotMapping: "Not Mapping",
      DontBeTransferred: "Don't Be Transferred",
      WaitApproval: "Wait Approval",
      synchronize: 'Synchronize',
      syncStatus: 'Sync Status',
      includeCompleted: 'Include Completed',
      Split: 'Split',
      ToBeSplit: 'To Be Split',
      None: 'None',
      Child: 'Child',
      MultipleCurrentAccount: 'Multiple Current Account',
      tenant: {
        Active: 'Active',
        Passive: 'Passive',
        Blocked: 'Blocked',
        NotApproved: 'Not approved',
        WaitingForApproval: 'Waiting for approval',
        InformationMissingIncorrect: 'Information missing/incorrect',
        DocumentMissingIncorrect: 'Document missing/incorrect'
      }
    },
    routes: {
      bankFileTypes: 'Bank File Types',
      summaryReport: 'Summary Report',
      reportDetail: 'Report Detail',
      bankParameters: 'Bank Parameters',
      customers: 'Customers',
      memberManagement: 'Member Management',
      doPay: 'Pay',
      paymentParameters: 'Payment Settings',
      netlinkTransaction: 'NetLink Transactions',
      payment: 'Payments',
      posList: 'Pos List',
      tenantInfo: 'Tenant',
      allCategories: 'Categories',
      generalCategories: "General Categories",
      customCategories: "Custom Categories",
      recipientAccounts: "Recipient Accounts",
      matchingConditions: "Matching Conditions",
      tenantsLicence: "Tenant Statistic Report",
      accounts: 'Accounts',
      packages: 'Packages',
      account: 'Account',
      newCompany: "Add New Company",
      newGroupTenant: 'Add New Group Company',
      newPartnerTenant: 'Add New Partner Company',
      tenants: "Tenants",
      companies: "Companies",
      licence: "Statistic Report",
      voucherTypes: 'Voucher Types',
      typeCodes: "Type Codes",
      erpTypes: "ERP Types",
      paymentList: 'Payment List',
      paymentLists: 'Payment List',
      createPayment: 'Create Payment',
      createPayments: 'Create Payment',
      createCheque: 'Create Cheque',
      chequeList: 'Cheque Lists',
      announcements: 'Announcements',
      tos: 'Bulk Payment System',
      cek: 'Cheque',
      posrapor: 'Posrapor',
      netfinans: 'Netfinans',
      tenantBank: 'Banks',
      tenantBanks: 'Banks',
      parameter: 'Parameters',
      parameters: 'Parameters',
      customerInfo: 'Customer Infos',
      customerInfos: 'Customer Infos',
      reporting: 'Reportings',
      reportings: 'Reportings',
      creatingInstruction: 'Create Order',
      creatingInstructions: 'Create Order',
      instructionList: 'Order List',
      instructionLists: 'Order List',
      dbs: 'Direct Debiting System',
      usedInfo: 'Used Info',
      dashboard: "Dashboard",
      home: "Home",
      transactions: "Transactions",
      balances: "Balances",
      currentAccount: "Current Account",
      currentAccounts: "Current Accounts",
      bankAccount: "Bank Account",
      bankAccounts: "Bank Accounts",
      definitions: "Definitions",
      settings: "Settings",
      categories: "Transaction Categories",
      user: "User",
      users: "Users",
      rule: "Rule",
      rules: "Rules",
      ruleDefinitions: "Rule Definitions",
      generalRules: "General Rules",
      customRules: "Custom Rules",
      role: "Role",
      roles: "Roles",
      notification: "Notification",
      notifications: "Notifications",
      profile: "Profile",
      userSettings: "User Settings",
      dynamicFields: "Dynamic Fields",
      fieldDefinitions: "Field Definitions",
      createData: 'Create Data',
      reports: 'Reports',
      balance: 'Bank Transfer Report',
      average: 'Bank Average Report',
      changelog: 'Change Log',
      posLists: 'Pos List',
      reportLines: 'Card Transactions',
      reportLineDetails: 'Card Transaction Details',
      applyCredit: 'Apply Credit',
      applications: 'Applications',
      banks: "Banks",
      createTestData: "Create Test Data",
      transactionCategories: "Transaction Categories",
      reportAverage: "Bank Average Report",
      reportBalances: "Bank Transfer Report",
      createOrder: "Create Order",
      orderList: "Order List",
      dbsParameters: "DBS BankParameters",
      psrParameters: "Posrapor BankParameters",
      jobManagement: "Job BankParameters"
    },
    dbs: {
      invoice: 'Invoice',
      InvoiceNumber: 'Invoice No',
      Description: 'Description',
      OperationStatusId: 'Operation Status Id',
      CurrentAccountId: 'Current Account Id',
      CurrencyCode: 'Currency Code',
      OrderId: 'Order Id',
      UpdatedTime: 'Updated Time',
      editInvoice: 'Edit Invoice',
      newInvoice: 'Add Invoice',
      currentAccountName: 'Current Account Name',
      createdDate: 'Created Date {{add}}',
      createOrderResponse: 'Order has been prepared for your invoices that are suitable for the conditions you have specified.',
      sendOrderResponse: 'The order have been sent to the bank for your invoices in accordance with the conditions you have specified.',
      currentAccount: 'Current Account',
      match: 'Match',
      matchStatus: 'Match Status',
      BankDbsCode1: 'Tenant Code 1',
      BankDbsCode2: 'Tenant Code 2',
      success: 'Success',
      orderStatusCode: 'Order Status Code: {{code}}',
      totalCount: 'Total Count',
      totalAmount: 'Total Amount',
      createOrder: 'Create Order',
      sendOrder: 'Send Order',
      newCustomerInfo: 'Add Customer Info',
      editCustomerInfo: 'Edit Customer Info',
      risk: 'Risk {{add}}',
      forwardDatedInvoiceTotal: 'Forward Dated Invoice Total {{add}}',
      forwardDatedInvoiceCount: 'Forward Dated Invoice Count {{add}}',
      usableLimit: 'Usable Limit {{add}}',
      declaredLimit: 'Declared Limit {{add}}',
      url: 'Url',
      hostName: 'Host',
      portName: 'Port',
      upFolder: 'Up Folder',
      downFolder: 'Down Folder',
      userName: 'Username',
      password: 'Password',
      companyTitle: 'Company Title',
      companyCode: 'Company Code ',
      newParameters: 'Add Parameter',
      editParameters: 'Edit Parameter',
      serviceTypes: 'Service Types',
      sftp: 'SFTP',
      ftp: 'FTP',
      ws: 'WS',
      serviceType: 'Service Type',
      dealerCode: 'Dealer Code',
      orderDescription: 'Order Description',
      orderNumber: 'Order Number',
      orderStatusSourceCode: 'Order Status Code',
      invoiceStatus: 'Invoice Status',
      dealerCodeOrName: 'Dealer Code/Name',
      orderDetail: 'Order Detail',
      amount: 'Amount {{add}}',
      piece: 'Piece',
      orderStatus: 'Order Status',
      orderCreatedDate: 'Order Created Date {{add}}',
      invoiceNumber: 'Invoice Number',
      editCreatingOrders: 'Edit Orders',
      newCreatingOrders: 'Add Orders',
      downloadExampleFile: 'Download Example File',
      bankName: 'Bank Name',
      dealerName: 'Dealer Name',
      currency: 'Currency',
      invoiceDate: 'Invoice Date {{add}}',
      expiryDate: 'Expiry Date {{add}}',
      invoiceStatusSourceCode: 'Invoice Status (Source) Code',
      invoiceNo: 'Invoice Number',
      invoiceAmount: 'Invoice Amount',
      description: 'Description',
      operationStatuses: {
        initial: 'Initial',
        waitingProcess: 'Waiting Process'
      },
      orderStatuses: {
        new: 'New',
        canceled: 'Canceled',
        failed: 'Failed',
        erpNew: 'ERP - New',
        waitApproval: 'Wait Approval',
        approved: 'Approved',
        waitConfirmation: 'Wait Confirmation',
        willBePay: 'Will Be Pay',
        unpaid: 'Unpaid',
        partiallyPaid: 'Partially Paid',
        paid: 'Paid',
      }
    },
    tos: {
      insertedTime: 'Inserted Time',
      accountNumber: 'Account Number',
      operationStatusId: 'Operation Status Id',
      currentAccountId: 'Current Account Id',
      currencyCode: 'Currency Code',
      recipientAccounts: 'Recipient Accounts',
      min: '(Min)',
      max: '(Max)',
      startCreateOrder: 'Start Create Order',
      createNewPayment: 'Create New Payment',
      cunsomerIban: 'Cunsomer IBAN',
      cunsomerBranchCode: "Cunsomer Branch Code",
      cunsomerAccountNo: 'Cunsomer Account No',
      cunsomerTitle: 'Cunsomer Title',
      virtualPosId: 'Virtual Pos Id',
      merchantId: 'Merchant Id',
      terminalId: 'Terminal Id',
      branch: 'Branch',
      editPosList: 'Edit Pos List',
      posNo: 'Pos No',
      editInvoice: 'Edit Invoice',
      newInvoice: 'New Invoice',
      currentAccountName: 'Current Account Name',
      createdDate: 'Created Date {{add}}',
      createOrderResponse: 'Instruction has been prepared for your invoices that are suitable for the conditions you have specified.',
      sendOrderResponse: 'The instructions were sent to the bank for your invoices in accordance with the conditions you specified..',
      currentAccount: 'Current Account',
      match: 'Match',
      matchStatus: 'Match Status',
      BankDbsCode1: 'Bank Dbs Code',
      BankDbsCode2: 'Bank Dbs Code 2',
      success: 'Success',
      orderStatusCode: 'Order Status Code: {{code}}',
      totalCount: 'Total Count',
      totalAmount: 'Total Amount',
      createOrder: 'Create Order',
      sendOrder: 'Send Order',
      newCustomerInfo: 'New Customer Info',
      editCustomerInfo: 'Edit Customer Info',
      risk: 'Risk {{add}}',
      forwardDatedInvoiceTotal: 'Forward Dated Invoice Total {{add}}',
      forwardDatedInvoiceCount: 'Forward Dated Invoice Count {{add}}',
      usableLimit: 'Usable Limit {{add}}',
      declaredLimit: 'Declared Limit {{add}}',
      url: 'Url',
      hostName: 'Host',
      portName: 'Port',
      upFolder: 'Up Folder',
      downFolder: 'Down Folder',
      userName: 'User Name',
      password: 'Password',
      companyTitle: 'Company Title',
      companyCode: 'Company Code',
      newParameters: 'New BankParameters',
      editParameters: 'Edit BankParameters',
      serviceTypes: 'Service Types',
      sftp: 'SFTP',
      ftp: 'FTP',
      ws: 'WS',
      serviceType: 'Service Type',
      dealerCode: 'Dealer Code',
      orderDescription: 'Order Description',
      orderNumber: 'Order Number',
      orderStatusSourceCode: 'Order Status Source Code',
      invoiceStatus: 'Invoice Status',
      dealerCodeOrName: 'Dealer Code/Name',
      orderDetail: 'Order Detail',
      amount: 'Amount {{add}}',
      piece: 'Piece',
      orderStatus: 'Order Status',
      orderCreatedDate: 'Order Created Date {{add}}',
      invoiceNumber: 'Invoice Number',
      editCreatingOrders: 'Edit Creating Orders',
      newCreatingOrders: 'New Creating Orders',
      downloadExampleFile: 'Download Example File',
      bankName: 'Bank Name',
      dealerName: 'Dealer Name',
      currency: 'Currency',
      invoiceDate: 'Invoice Date {{add}}',
      expiryDate: 'Expiry Date {{add}}',
      paymentDate: 'Payment Date {{add}}',
      invoiceStatusSourceCode: 'Invoice Status (Source) Code',
      invoiceNo: 'Invoice No',
      invoiceAmount: 'Invoice Amount',
      description: 'Description',
      sendingAccount: 'Sending Account',
      monthlyPayments: 'Monthly Payments',
      manuelPayment: 'Manuel Payment',
      filePayment: 'Payment Registration By File',
    },
    posrapor: {
      sales: 'Sales',
      dynamicPropertyHeader: 'Dynamic Property Header',
      headerColumns: 'Header Columns',
      definition: 'Definitions',
      merchantName: 'Merchant Name',
      fileFormat: 'File Format',
      refund: 'Refund',
      creditDebitCardUsageRate: 'Credit/Debit Card Usage Rate',
      creditCardBasedTransactions: 'Card Program Based Transactions',
      installment: 'Installment',
      inAdvance: 'In Advance',
      posKind: 'Pos Kind',
      cardHarmony: 'Card Harmony',
      physical: 'Physical',
      virtual: 'Virtual',
      posBank: 'Pos Bank',
      cardBank: 'Card Bank',
      isOnUs: 'Bank Onus',
      cardType: 'Card Type',
      cardKind: 'Card Kind',
      cardProgram: 'Card Program',
      paymentStatus: 'Payment Status',
      PureAmount: 'Pure Amount {{add}}',
      transactionTime: 'Transaction Time',
      posRaporAccountIsActive: "Is active posrapor account?",
      accountName: "Account Name",
      vendorType: 'Vendor Type',
      currentAccountCode: 'Current Account Name / Code',
      posType: 'Pos Type',
      referenceNo: 'Referans Number',
      virtualPosId: 'Pos No',
      merchantId: 'Merchant No',
      terminalId: 'Terminal No',
      branch: 'Branch Name',
      editPosList: 'Edit Pos',
      posNo: 'Pos Number',
      transactionDate: "Transaction Date {{add}}",
      searchByNumbers: 'Search By Number',
      accountType: 'Account Type',
      accountNumber: 'Account Number',
      currencyType: 'Currency Type',
      iban: 'IBAN',
      erpPaymentSetCode: 'ÖS',
      erpPosCode: 'Pos Code',
      erpTenantCode: 'Dealer Code',
      id: 'Id',
      newParameters: 'Add Parameter',
      editParameters: 'Edit Parameter',
      bankName: 'Bank Name',
      serviceTypes: 'Service Types',
      ftp: 'FTP',
      sftp: 'SFTP',
      ws: 'WS',
      path: 'Path',
      downloadPath: 'Download Path',
      filePattern: 'File Pattern',
      downloadFilePattern: 'Download File Pattern',
      host: 'Host',
      port: 'Port',
      url: 'Url',
      ftpUser: 'FTP User',
      password: 'Password',
      spaceMapping: 'Space Mapping',
      userName: 'Username',
      merchant: 'Merchant No',
      terminalNo: 'Terminal Number',
      provisionCode: 'Provision Number',
      netAmount: 'Net Amount {{add}}',
      pureAmount: 'Pure Amount {{add}}',
      installmentCount: 'Installment Count',
      transactionNo: 'Transaction No',
      merchantOrTerminal: 'Merchant / Terminal No',
      detailReportLine: 'Card Transaction',
      serviceCommisionRate: 'Commission Rate {{add}}',
      commisionAmount: 'Commission Amount {{add}}',
      cardNumber: 'Card Number',
      pos: 'Pos'
    },
    route: {
      netekstre: {
        matchingConditions: "Matching Conditions",
        balances: 'Balances',
        dynamicFields: 'Dynamic Fields',
        fieldDefinitions: 'Field Definitions',
        generalRules: 'General Rules',
        customRules: 'Custom Rules',
        notifications: 'Notifications',
        transactions: 'Transactions',
        balancesReports: 'Balance Report',
        averageReports: 'Average Bank Report'
      },
      kredi: {
        applications: "My Applications"
      },
      tos: {
        paymentList: 'Payment List',
        recipientAccounts: 'Recipient Accounts',
        instructionList: "Instruction List",
        instructionLists: "Instruction List",
        parameters: 'Parameters',
        customerInfos: 'Customer Info',
        createPayment: 'Create Payment',
        reportings: 'Reportings',
      },
      admin: {
        packages: 'Packages',
        tenants: "Companies",
        licenseStatus: "Statistic Report",
        voucherTypes: 'Voucher Types',
        erpTypes: "ERP Types",
        generalCategories: 'General Categories',
        typeCodes: "Type Codes",
        rules: "Rules",
        tenantLicenseStatus: "Tenant Statistic Report",
        jobs: "Job Management"
      },
      dbs: {
        orderDetail: 'Order Detail',
        customerInfos: 'Customer Infos',
        creatingInstructions: 'Creating Instructions',
        instructionLists: 'Instruction Lists',
        instructionList: 'Instruction Lists',
        parameters: 'Parameters',
        reportings: 'Reportings',
      },
      posrapor: {
        parameters: 'Parameters',
        posLists: 'Pos List',
        accounts: 'Accounts',
        reportLines: 'Report Lines',
        reportLineDetails: "Report Line Details",
        reporting: 'Reporting',
        reportDetail: 'Report Detail',
        customers: 'Customers',
        bankFileType: 'Bank File Type'
      },
      common: {
        bankAccounts: 'Bank Accounts',
        currentAccounts: 'Current Accounts',
        tenantBanks: 'Banks',
        roles: 'Roles',
        users: 'Users',
      },
      netahsilat: {
        netlinkTransactions: 'NetLink Transactions',
        poses: 'Pos List',
        payments: 'Payments'
      }
    },
    descriptions: {
      willBeAvailableSoonModal: {
        dbs: `
        <p>Doğrudan Borçlandırma Sistemi ile geniş bayi/müşteri ağı bulunan firmaların, bayi ya da müşterilerine yapacakları mal ve hizmet satışlarına yönelik fatura tahsilatlarının otomatik olarak  gerçekleştirilmesini sağlanarak nakit yönetim hizmeti oluşturulmaktır. </p>
        <p>DBS sistemi ile firmanın bayi yada müşterilerine yaptığı satışlardan doğan alacakların, hızlı ve garantili tahsilatı sağlanmış olacaktır. Doğrudan Borçlandırma Sistemi’nde ana firmanın bankaya ilettiği tutarlar, belirtilen vade sonunda bayi/müşteri hesabındaki bakiyeden, bakiye müsait değilse tesis edilmiş kredi limitinden otomatik olarak çekilerek ana firma hesaplarına aktarılır.</p>
        <p>Havale/Çek/Senet/Teminat Mektubu takibinin yarattığı maliyet ve risk azalır. Kredili DBS’de Banka tarafından bayilere sağlanan kredi imkânı ile firmanın alacak tahsilat riski sıfırlanırken, bayi de kısa vadeli finansman ihtiyacını karşılar. Türk Lirası ve/veya tüm çevrilebilir döviz cinslerindeki faturaların tahsilatı sağlanır. Bayi teminat takibi ve maliyeti azalır, tahsilat raporları ile bayi risk takibi kolaylaşır. Otomasyon sayesinde operasyonel maliyetler düşer, operasyonel iş yükü azalır. </p>
      `,
        posrapor: `
        <p>Posrapor işletme ve kurumların pos makineleri veya sanal poslarıyla yaptıkları tahsilat işlemlerinin banka hareketlerine dair detaylı bilgi verir.</p>
        <p>Posrapor direkt olarak banka hesabınızla entegre çalışmakta olup İşlem tutarı, komisyon tutarı, komisyon oranı, taksit sayısı ve tutarları, valör tarihi, tutarları ve diğer verileri banka veri tabanından çekerek size tek platform üzerinden görüntüleme/raporlama imkanı sunar.</p>
        <p>Kredi kartı işlemlerinin takibi, raporlaması, ERP/muhasebe sistemlerine kaydı ve denetimi çeşitli zorluklar içermektedir. Özellikle de birden fazla banka ve aynı anda pos makinesi ve sanal pos kullanan işletmeler için bu zorluklar daha fazladır. Posrapor bu zorlukların tamamını ortadan kaldıran, kredi kartı tahsilatlarının tutar, taksit sayısı, komisyon oranı, net tutar, komisyon tutarı, valörü (hesaba geçiş tarihi) ve diğer bilgileriyle birlikte muhasebe sistemine otomatik olarak aktarılmasını sağlayan bir çözümdür.</p>
        <p>Tüm pos ve sanal pos işlemlerinin tek ekrandan takip edilebildiği ve otomatik olarak ERP/muhasebe sistemine aktarıldığı Posrapor ile yönetim ve denetim kolaylığının yanı sıra hata olasılığı da ortadan kalkmaktadır.</p>
      `,
        netekstre: `
        <p>Netekstre receives all your account transactions online from banks and enables you to view all transactions on a single panel. All the transactions are translated into a common data structure and transferred to the ERP systems (SAP, Oracle, Microsoft Dynamics, Logo, Netsis, Micro, Eta etc.) via web service.</p>
        <p>All bank transactions can be monitored on a single panel, special reports can be created and exported through the panel, and reports can be received via SMS and email periodically via Netekstre.</p>
        <p>Converting all transactions into a common data structure and transferring them quickly to ERP / accounting systems gives companies great benefits in terms of time and workforce.</p>
      `,
        kredi: `
        <p>Promotional launch for the <b>Kredi</b> module has not been launched yet. It will take place here to be available to you very soon.</p>
        <p>Detailed information will be available in a short time.</p>
        <p><b>Thank you for your interest and patience.</b></p>
      `,
        tos: `
        <p>Promotional launch for the <b>Tös</b> module has not been launched yet. It will take place here to be available to you very soon.</p>
        <p>Detailed information will be available in a short time.</p>
        <p><b>Thank you for your interest and patience.</b></p>
      `,
        cek: `
        <p>Promotional launch for the <b>Çek/Senet</b> module has not been launched yet. It will take place here to be available to you very soon.</p>
        <p>Detailed information will be available in a short time.</p>
        <p><b>Thank you for your interest and patience.</b></p>
      `
      },
      installmentInfo: {
        plusInstallment: 'Refers to the plus installment from the agreements made with banks.',
        paymentDeferral: 'Refers to postponement of installments from agreements made with banks.'
      },
      customerDetail: 'Please enter all the information of your customers. After adding, a password creation link will be sent to the user.'
    },
    attentions: {
      companyDocuments: "You can view or edit your documents regarding your company's commercial activities.",
      documentsWillBeConfirmedECozum: "After all the documents you upload to the system are approved by Eçözüm, your application usage becomes active.",
      uploadableDocTypes: "You can only upload documents in PDF, DOC, DOCX, JPG, JPEG or PNG format to the system.",
      docsAvailableAfterConfirmation: "Your company will be activated after all your documents are approved in the system.",

      commercialRegistryNewspaper: {
        attention1: "Upload your current Trade Registry Gazette to this area by scanning or taking a photo so that all of your company's page(s) are visible.",
        attention2: 'Trade Registry Gazette you, via e-state "Turkey Trade Registry Newspaper" can be accessed by clicking on the link in the related field.',
        attention3: 'The addresses and titles on the commercial registry newspaper and the tax plate must be the same.',
      },

      taxBoard: {
        attention1: 'The latest version should be uploaded.',
        attention2: 'You can access your tax plate from the "Tax Plate Inquiry" field on e-devlet.'
      },

      signatureCirculars: {
        attention1: "The period of validity (if any) should not be expired.",
        attention2: "All signatures you use in contracts must match the signatures in your signature circular.",
        attention3: "If the signatures of the persons you have appointed for your company will be used, the signature circular and power of attorney must be uploaded together for these persons.",
        attention4: "Within the scope of Kvkk, we kindly ask you not to upload your identity document with your signature circular. If you do, it will be rejected.",
        attention5: "If you do not have a signature circular / declaration, you can obtain it by going to the nearest notary.",
        attention6: "If you are a sole proprietorship company, you can upload a signature declaration of your name.",
        attention7: "If you give power of attorney to any person; Signature circular / declaration, power of attorney and signatory circular / declaration of attorney must be uploaded to this area.",
      },

      identityCard: {
        attention1: "The front and back side of your ID must be uploaded."
      }
    },
    const: {
      pluralSuffix: 's',
    },
    ...devMessages.en
  }
};
