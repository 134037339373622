import _ from 'lodash';
import { convertArrayToEnum } from 'helpers';

export default {
  //#region [GET]
  getAll: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.list = { data: [], loading: true, count: null, error: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getAllSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.list = {
        data: payload.isNewODataStructure ? payload.data.value : payload.data,
        loading: false,
        count: payload.isNewODataStructure ? payload.data['@odata.count'] : null,
        error: null,
        statusCode: payload.statusCode
      }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getAllFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.list = {
        data: [],
        loading: false,
        count: null,
        error: payload.error,
        statusCode: payload.statusCode
      }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  get: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: true, error: null, saving: false, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getFilter: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: [], loading: true, error: null }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getFilterSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: payload.isNewODataStructure ? payload.data.value : payload.data, loading: false, error: null }
      if (!!payload.convertEnum) data.enum = convertArrayToEnum(data.data, typeof payload.convertEnum !== 'boolean' ? payload.convertEnum : undefined);
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getFilterFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: [], loading: false, error: payload.error }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getWithPost: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: true, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [POST]
  post: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  postSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: false, statusCode: payload.statusCode, response: payload.data }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  postFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.statusCode && payload.statusCode === 406 ? _.cloneDeep(_.get(state, keys)).single.data : null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [PUT]
  put: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  putSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  putFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.statusCode && payload.statusCode === 406 ? _.cloneDeep(_.get(state, keys)).single.data : null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [DELETE]
  delete: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      if (data) data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  deleteSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      if (data) data.single = { data: null, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  deleteFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      if (data) data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [PATCH]
  patch: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  patchSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  patchFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [CUSTOM]

  clearState: (state, { key, options = { deep: false, innerKey: null, clearInnerList: false } }) => {
    let data = {};
    if (key) {
      const keys = _.split(key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};

      if (options.deep || options.innerKey) {
        const innerData = _.cloneDeep(_.get(state, keys));
        const [mainKey] = Object.keys(data);
        const innerKeys = _.filter(_.keys(innerData), x => x !== 'list' && x !== 'single');

        if (options.deep) {
          innerKeys.forEach(innerKey => {
            data[mainKey][innerKey] = {
              list: { data: [], loading: null, count: null, error: null, statusCode: null },
              single: { data: null, saving: null, loading: null, error: null, statusCode: null }
            };
          })
        }
        else if (options.innerKey) {
          data[mainKey][options.innerKey].single = { data: null, loading: false, error: null, saving: null, statusCode: null }
        }
      }
    }
    else if (!key && options.innerKey) {
      const [mainKey, innerKey] = _.split(options.innerKey, '.');
      data = _.cloneDeep(_.get(state, mainKey));
      data[innerKey].single = { data: null, loading: false, error: null, saving: null, statusCode: null };
      if (options.clearInnerList) data[innerKey].list = { data: [], loading: null, count: null, error: null, statusCode: null };
      data = { ...state, [mainKey]: data }
    }
    return data;
  },

  clearFilter: (state, { key }) => {
    let data = {};
    if (key) {
      data = { data: [], loading: false, error: null }
      data = { filter: { ...state.filter, [key]: { ...data } } }
    }
    return data;
  },
  //#endregion

}