import React from 'react';
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';
import actions from 'store/actions/auth';
import { connect } from "react-redux";

const ModuleSelector = ({ onCollapsed, collapsed, logout, onMobileClick }) => {

  return (
    <>
      <div className="module-selector admin" onClick={onMobileClick}>
        <ReactSVG className={`collapsed pointer ${collapsed ? 'on' : ''}`} src={icons.chevronLeft} onClick={onCollapsed} />

        <ReactSVG className="pointer" src={icons.logout} onClick={logout} />
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
});
export default connect(null, mapDispatchToProps)(ModuleSelector)