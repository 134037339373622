import React, { useEffect } from 'react'
import LayoutCover from "./LayoutCover";
import Content from "./Content";
import { Layout as AntLayout, Row, Col } from "antd";
import { useDispatch } from 'react-redux';
import actions from 'store/actions/auth';

const Layout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      dispatch(actions.getAccount((response) => {
        response && window.location.replace(response.level < 2 ? '/admin' : '/home')
      }))
    }
  }, [dispatch])

  return (
    <AntLayout className="register-layout fadeInComponent">
      <div className="register__inner">
        <Row className="full-height">
          <Col xs={0} sm={6} className="cover">
            <LayoutCover />
          </Col>
          <Col xs={24} sm={18} className="content">
            <Content />
          </Col>
        </Row>
      </div>
    </AntLayout>
  )
}

export default Layout;
