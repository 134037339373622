import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form } from 'antd';
import { Typography, FormElements, AgreementViewer, FadeIn } from 'components/UIComponents';
import i18n from 'plugins/i18n';
import { isTckn, phoneNormalizer } from 'helpers';
import { UserSignatoryModel } from 'models';
import _ from 'lodash';
import { connect } from 'react-redux';
import actions from 'store/redux/netekstre';
import endpoints from 'config/endpoints';
import { httpMethods } from 'lib/constants';

const SignatoryInfo = ({ setPage, form, className, getAgreement, saveSignatory }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState(new UserSignatoryModel());
  const [saving, setSaving] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [validationStatuses, setValidationStatuses] = useState([]);

  useEffect(() => {
    getAgreement('OKR1', mapAgreements);
    getAgreement('OKR2', mapAgreements);
  }, [getAgreement]);

  const mapAgreements = agreement => {
    setAgreements(agreements => [...agreements, agreement]);
    const _agreement = {
      id: agreement.id,
      acceptance: false,
      isMandatory: agreement.isMandatory
    };
    updateData(
      data =>
        new UserSignatoryModel({
          ...data,
          agreements: [...data.agreements, _agreement]
        })
    );
  };

  const updateAgreements = ({ checked }, id) => {
    let _data = data;
    let item = _data.agreements.find(x => x.id === id);
    if (item) {
      item.acceptance = checked;
    }
    updateData(_data);
    checkAgreements();
  };

  const checkAgreements = () => {
    const validationStatusArr = [];
    if (data.agreements && data.agreements.length > 0) {
      data.agreements.forEach(agg => {
        if (agg.isMandatory && !agg.acceptance) {
          validationStatusArr.push(agg.id);
        }
      });
    }
    setValidationStatuses(validationStatusArr);
  };

  const onSubmit = e => {
    e.preventDefault();
    checkAgreements();

    form.validateFields(error => {
      if (!error && validationStatuses.length === 0) {
        setSaving(true);
        let _data = _.cloneDeep(data);
        _data.phone = phoneNormalizer(_data.phone);
        saveSignatory(_data, response => {
          if (!(response.errors && response.errors.length > 0)) {
            setPage('SmsVerification');
            localStorage.setItem("user", JSON.stringify(response))
            localStorage.setItem("info", JSON.stringify({username: _data.email}))
          }
          setSaving(false);
        })
      }
    });
  };

  return (
    <FadeIn>
      <Form onSubmit={onSubmit} className={className}>
        <Row className="pl-30 pr-30" gutter={44}>
          <Col xs={24} md={24} className="mb-60">
            <Typography.H size={3} color="#273c74">
              {i18n.t("lbl.signatoryInfo")}
            </Typography.H>
            <Typography.P className="mt-15" size="normal">
              {i18n.t("lbl.pleaseEnterSignatoryInformation")}
            </Typography.P>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item>
              {getFieldDecorator("name", {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.name
              })(
                <FormElements.Input
                  label={i18n.t("lbl.name")}
                  prefix="user"
                  onChange={e => updateData(data => ({ ...data, name: e }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {getFieldDecorator("surname", {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.surname
              })(
                <FormElements.Input
                  label={i18n.t("lbl.surname")}
                  prefix="user"
                  onChange={e => updateData(data => ({ ...data, surname: e }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {getFieldDecorator("birthDate", {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.birthDate
              })(
                <FormElements.DatePicker
                  label={i18n.t("lbl.birthDate")}
                  prefix="calendar"
                  onChange={e =>
                    updateData(data => ({ ...data, birthDate: e }))
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {getFieldDecorator("tckn", {
                rules: [
                  { required: true, message: i18n.t("msg.required") },
                  {
                    validator: (rule, value, callback) =>
                      isTckn({ callback, value }),
                    message: i18n.t("msg.invalidFormat")
                  }
                ],
                initialValue: data.tckn
              })(
                <FormElements.Input
                  label={i18n.t("lbl.tckn")}
                  prefix="card"
                  onChange={e => updateData(data => ({ ...data, tckn: e }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: i18n.t("msg.required") },
                  { type: "email", message: i18n.t("msg.emailInvalid") }
                ],
                initialValue: data.email
              })(
                <FormElements.Input
                  label={i18n.t("lbl.email")}
                  prefix="envelope"
                  onChange={e => updateData(data => ({ ...data, email: e }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {getFieldDecorator("phone", {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.phone
              })(
                <FormElements.Phone
                  label={i18n.t("lbl.gsm")}
                  prefix="gsm"
                  onChange={e => updateData(data => ({ ...data, phone: e }))}
                  mobile
                />
              )}
            </Form.Item>
          </Col>

          {
            agreements && agreements.length > 0 &&
            agreements.map((agreement, index) =>
              <Col xs={24} md={24} key={agreement.id || Math.random()}>
                <Form.Item
                  className="mb-10"
                  help={validationStatuses.length > 0 && validationStatuses.includes(agreement.id) ? i18n.t("msg.required") : ""}
                  validateStatus={validationStatuses.length > 0 && validationStatuses.includes(agreement.id) ? "error" : "success"}
                >
                  <FormElements.CheckBox
                    id={agreement.id}
                    label={<AgreementViewer.Inline {...{ title: agreement.longTitle, text: agreement.longText }} />}
                    onChange={({ target }) => updateAgreements(target, agreement.id)}
                  />
                </Form.Item>
              </Col>
            )}
        </Row>
        <Row className="pl-30 pr-30 mobile-text-center" gutter={44}>
          <Col xs={24} md={12} className="text-right">
            <Button
              ghost
              type="default"
              htmlType="button"
              size="large"
              onClick={() => setSaving(false)}
            >
              {i18n.t("btn.cancel")}
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={saving}
            >
              {i18n.t("btn.continue")}
            </Button>
          </Col>
        </Row>
      </Form>
    </FadeIn >
  )
}
const mapDispatchToProps = (dispatch) => ({
  getAgreement: (id, callback) => dispatch(actions.globalRequest({ url: endpoints.info.agreement, id, notify: false }, httpMethods.get, callback)),
  saveSignatory: (data, callback) => dispatch(actions.globalRequest({ data, url: endpoints.user.signatory,  notify: false }, httpMethods.post, callback)),
});
export default connect(null, mapDispatchToProps)(Form.create()(SignatoryInfo));
