import React, { useState } from 'react'
import { Button, Form, Col, Row, Icon } from 'antd';
import { FadeIn, Typography, FormElements } from 'components/UIComponents';
import i18n from 'plugins/i18n';
import endpoints from 'config/endpoints';
import { httpMethods } from 'lib/constants';
import { connect } from 'react-redux';
import actions from 'store/redux/netekstre';

const PasswordSettings = ({ onChange, form, setPassword }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ password: '', rePassword: '', resetPasswordGuid: '' });
  const [inputType, setInputType] = useState('password');
  const [loading, setLoading] = useState(false)


  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    form.validateFields(error => {
      if (!error) {
        const headers = { Authorization: `Bearer ${localStorage.getItem("ott")}` };
        const id = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).createPasswordCode : null
        setPassword(data, id, headers, ({ errors }) => {
          if (!(errors && errors.length > 0)) {
            setLoading(false);
            onChange('+', data.password)
          }
        })
      }
    })
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(i18n.t('msg.passwordMatchError'));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    setTimeout(() => {
      if (value && form.getFieldValue('rePassword')) {
        form.validateFields(['password'], { force: true });
      }
      callback();
    }, 300);
  };


  return (
    <FadeIn>
      <Form onSubmit={onSubmit}>
        <Row className="pl-30 pr-30 pb-20" gutter={44} >
          <Col xs={12} md={24} className="mb-35" >
            <Typography.H size={3} color="#273c74">
              {i18n.t('lbl.tenantInfo')}
            </Typography.H>
            <Typography.P className="mt-15" size="normal">
              {i18n.t('lbl.pleaseEnterTenantInformation')}
            </Typography.P>
          </Col>
        </Row>

        <Row gutter={44} className="pl-30 pr-30">
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 9, offset: 3 }}>
            <Form.Item className="mb-30">
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: i18n.t('msg.passwordRequired') },
                  { validator: validateToNextPassword }
                ]
              })(
                <FormElements.Input
                  suffix={
                    <Icon
                      style={{ color: '#aaa' }}
                      onClick={() => setInputType(inputType === "password" ? "text" : "password")}
                      type="eye"
                    />
                  }
                  prefix={<i className="fas fa-lock" />}
                  type={inputType}
                  onChange={(e) => updateData((data) => ({ ...data, password: e }))}
                  label={i18n.t('lbl.newPassword')}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 9, offset: 0 }}>
            <Form.Item className="mb-30">
              {getFieldDecorator('rePassword', {
                rules: [
                  { required: true, message: i18n.t('msg.passwordRequired') },
                  { validator: compareToFirstPassword }
                ]
              })(
                <FormElements.Input
                  prefix={<i className="fas fa-lock" />}
                  type={inputType}
                  onChange={(e) => updateData((data) => ({ ...data, rePassword: e }))}
                  label={i18n.t('lbl.confirmPassword')}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="pl-30 pr-30 mt-60" gutter={44}>
          <Col xs={24} md={12} className="text-right">
            <Button ghost type="default" htmlType="button" size="large" onClick={() => onChange('-')}>
              {i18n.t('btn.cancel')}
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              {i18n.t('btn.continue')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FadeIn>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setPassword: (data, id, headers, callback) => dispatch(actions.globalRequest({ url: endpoints.auth.setpwd, id, data, headers, notify: false }, httpMethods.put, callback)),
});
export default connect(null, mapDispatchToProps)(Form.create()(PasswordSettings));
