import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/admin';
import reducers from 'store/reducers/admin';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllAdmin: ['payload', 'callback', 'isNewODataStructure'],
  getAllAdminSuccess: ['payload', 'isNewODataStructure'],
  getAllAdminFailure: ['payload'],
  getAdmin: ['payload', 'callback'],
  getAdminSuccess: ['payload', 'isNewODataStructure'],
  getAdminFailure: ['payload'],
  postAdmin: ['payload', 'callback'],
  postAdminSuccess: ['payload'],
  postAdminFailure: ['payload'],
  putAdmin: ['payload', 'callback'],
  putAdminSuccess: ['payload'],
  putAdminFailure: ['payload'],
  deleteAdmin: ['payload', 'callback'],
  deleteAdminSuccess: ['payload'],
  deleteAdminFailure: ['payload'],
  patchAdmin: ['payload', 'callback'],
  patchAdminSuccess: ['payload'],
  patchAdminFailure: ['payload'],
  clearStateAdmin: ['key', 'options'],
  clearFilterAdmin: ['key'],
  getWithPostAdmin: ['payload', 'callback'],
  getWithPostAdminSuccess: ['payload'],
  getWithPostAdminFailure: ['payload'],
  getFilterAdmin: ['payload', 'isNewODataStructure'],
  getFilterAdminSuccess: ['payload', 'isNewODataStructure'],
  getFilterAdminFailure: ['payload'],
});
export const AdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}

export const clearFilter = (state, action) => {
  const data = reducers.clearFilter(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.getWithPost(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state,action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state,action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state,action)
  return state.merge(data);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_ADMIN]: getAll,
  [Types.GET_ALL_ADMIN_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_ADMIN_FAILURE]: getAllFailure,
  [Types.GET_ADMIN]: get,
  [Types.GET_ADMIN_SUCCESS]: getSuccess,
  [Types.GET_ADMIN_FAILURE]: getFailure,
  [Types.POST_ADMIN]: post,
  [Types.POST_ADMIN_SUCCESS]: postSuccess,
  [Types.POST_ADMIN_FAILURE]: postFailure,
  [Types.PUT_ADMIN]: put,
  [Types.PUT_ADMIN_SUCCESS]: putSuccess,
  [Types.PUT_ADMIN_FAILURE]: putFailure,
  [Types.DELETE_ADMIN]: _delete,
  [Types.DELETE_ADMIN_SUCCESS]: deleteSuccess,
  [Types.DELETE_ADMIN_FAILURE]: deleteFailure,
  [Types.PATCH_ADMIN]: patch,
  [Types.PATCH_ADMIN_SUCCESS]: patchSuccess,
  [Types.PATCH_ADMIN_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_ADMIN]: clearState,
  [Types.CLEAR_FILTER_ADMIN]: clearFilter,
  [Types.GET_WITH_POST_ADMIN]: getWithPost,
  [Types.GET_WITH_POST_ADMIN_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_ADMIN_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_ADMIN]: getFilter,
  [Types.GET_FILTER_ADMIN_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_ADMIN_FAILURE]: getFilterFailure,
});