export const stackedBarChart = ({ multiTenant = false, dataIndex, component, showLegend = false }) => {
  return {
    type: 'bar',
    maintainAspectRatio: false,
    plugins: {
      labels: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
      onHover: function (e, v) { this.ctx.canvas.style.cursor = v && multiTenant ? "pointer" : "default" },
    },
    legend: {
      display: showLegend
    },
    tooltips: {
      // mode: 'index',
      // enabled: false,
      // custom: (tooltipModel) => {
      //   debugger;
      //   var tooltipEl = document.getElementById('chartjs-tooltip');
      //   if (!tooltipEl) {
      //     tooltipEl = document.createElement('div');
      //     tooltipEl.id = 'chartjs-tooltip';
      //     tooltipEl.innerHTML = "<table></table>";
      //     document.body.appendChild(tooltipEl);
      //   }
      //   if (tooltipModel.opacity === 0) {
      //     tooltipEl.style.opacity = 0;
      //     return;
      //   }

      //   // Set caret Position
      //   tooltipEl.classList.remove('above', 'below', 'no-transform');
      //   if (tooltipModel.yAlign) {
      //     tooltipEl.classList.add(tooltipModel.yAlign);
      //   } else {
      //     tooltipEl.classList.add('no-transform');
      //   }

      //   function getBody(bodyItem) {
      //     return bodyItem.lines;
      //   }

      //   // Set Text
      //   if (tooltipModel.body) {
      //     var titleLines = tooltipModel.title || [];
      //     var bodyLines = tooltipModel.body.map(getBody);

      //     var innerHtml = '<thead>';

      //     titleLines.forEach(function (title) {
      //       innerHtml += '<tr><th>' + title + '</th></tr>';
      //     });
      //     innerHtml += '</thead><tbody>';

      //     bodyLines.forEach(function (body, i) {
      //       var colors = tooltipModel.labelColors[i];
      //       var style = 'background:' + colors.backgroundColor;
      //       style += '; border-color:' + colors.borderColor;
      //       style += '; border-width: 2px';
      //       var span = '<span style="' + style + '"></span>';
      //       innerHtml += '<tr><td>' + span + body + '</td></tr>';
      //     });
      //     innerHtml += '</tbody>';

      //     var tableRoot = tooltipEl.querySelector('table');
      //     tableRoot.innerHTML = innerHtml;
      //   }
      //   // `this` will be the overall tooltip
      //   var position = component.creditCardTypeChart.chartInstance.canvas.getBoundingClientRect();

      //   // Display, position, and set styles for font
      //   tooltipEl.style.opacity = 1;
      //   tooltipEl.style.position = 'absolute';
      //   tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
      //   tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
      //   tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      //   tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
      //   tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      //   tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
      //   tooltipEl.style.backgroundColor = '#fff';
      //   tooltipEl.style.boxShadow = '0 0 5px red';
      //   tooltipEl.style.borderRadius = '4px';


      // }
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let str = tooltipItem.yLabel.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          str += str.indexOf(',') !== -1 ? '' : ',00';
          let strs = str.split(',');
          if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
          dataIndex !== tooltipItem.index && component.setState({ dataIndex: tooltipItem.index }); 
          // const result = `${data.datasets[tooltipItem.datasetIndex].label}: ${strs.join(',')}`; //? if prefer dataset label names
          const result = strs.join(',');
          return result;
        },
      },
    },
    scales: {
      yAxes: [{
        stacked: true,
        gridLines: {
          drawBorder: false,
          display: true,
          color: "transparent",
          zeroLineColor: '#d7d7d7',
          zeroLineWidth: 1
        },
        ticks: {
          color: "rgba(55, 5, 55, 1.0)",
          fontColor: "#999",
          callback: function (label, index, labels) {
            let str = label.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            str += str.indexOf(',') !== -1 ? '' : ',00';
            let strs = str.split(',');
            if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
            return strs.join(',');
          },
          beginAtZero: true
        },
      }],
      xAxes: [{
        stacked: true,
        zeroLineColor: '#d7d7d7',
        ticks: {
          autoSkip: false,
          fontColor: "#999",
        },
      }]
    },
  }
};