import React from 'react';
import i18n from 'plugins/i18n';

const NoAccessRights = () => (
  <div className="no-access-rights" >
    <i className="fas fa-4x fa-exclamation-triangle"></i>
    <h1>{i18n.t('msg.noAccessRightManagementPanel')}</h1>
  </div>
);

export default NoAccessRights;