import React, { useState } from 'react';
import { Icon, Avatar, Tooltip, Dropdown, Menu, Badge } from "antd";
import { connect } from "react-redux";
import i18n from 'plugins/i18n';
import { withRouter } from "react-router-dom";
import authActions from 'store/actions/auth';
import commonActions from 'store/actions/common';
import { Version, UsedInfo } from 'components/UIComponents';
import config from 'config';
import { projectTypes } from 'lib/constants';
import { Input } from 'components/UIComponents/NetbankaComponents';
import _ from 'lodash';


const Profile = ({ data, history, logout, changeTenant, currentModule }) => {
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const onVisibleChange = visible => {
    setVisible(visible);
  };

  const onTenantClick = (e) => {
    changeTenant(e.key, () => {
      window.location.replace('/home');
    });
  };

  return (
    <span className="header-profile">
      <span>
        <Avatar icon="user" size="large" />
      </span>

      {
        data &&
          data.tenants &&
          data.tenants.filter(x => x.id !== data.tenantId).length > 0 ?
          <Dropdown
            visible={visible}
            onVisibleChange={onVisibleChange}
            overlay={
              <div className="tenant-wrapper">
                <Input
                  className="tenant-search"
                  placeholder={i18n.t('lbl.searchFirm')}
                  onChange={setSearchTerm}
                  value={searchTerm}
                  type="text"
                />
                {
                  <div className="tenant-results">
                    <Menu onClick={onTenantClick}>
                      {
                        _.uniqBy(data.tenants, 'id').filter(x => x.id !== data.tenantId && x.name.toLowerCase().includes(searchTerm.toLowerCase())).map(tenant => {
                          return (
                            <Menu.Item key={tenant.id}>
                              <Avatar icon="user" src={tenant.logoUrl} className="mr-10" />
                              {tenant.name}
                            </Menu.Item>
                          )
                        }
                        )
                      }
                    </Menu>
                  </div>
                }
              </div>
            } trigger={['click']} placement="bottomRight">
            <Tooltip placement="left" title={data && data.tenantName}>
              <span className="header-profile__name">
                <b>{data && data.userName}</b>
                <span>{data && data.tenantName}</span>
              </span>
            </Tooltip>
          </Dropdown>
          :
          <Tooltip placement="left" title={data && data.tenantName}>
            <span className="header-profile__name">
              <b>{data && data.userName}</b>
              <span>{data && data.tenantName}</span>
            </span>
          </Tooltip>
      }


      <Dropdown overlay={
        <Menu>
          {
            config.projectType === projectTypes.netfinans &&
            <Menu.Item key="2" onClick={() => history.push('/common/profile')}>
              {i18n.t('btn.profile')}
            </Menu.Item>
          }
          {
            data &&
            data.level < 3 &&
            data.accessRights.find(x => x.endPoint === "ManagementPanel" && x.method === "GET") &&
            currentModule !== "admin" &&
            < Menu.Item key="5" onClick={() => history.push('/admin')}>
              {i18n.t('btn.admin')}
            </Menu.Item>
          }
          {
            config.projectType === projectTypes.netfinans &&
            <Menu.Item key="0" onClick={() => history.push('/home')}>
              {i18n.t('btn.returnHome')}
            </Menu.Item>
          }
          {
            config.projectType === projectTypes.netfinans &&
            currentModule === "netekstre" &&
            <Menu.Item key="1" >
              <UsedInfo />
            </Menu.Item>
          }
          <Menu.Item key="3" onClick={logout} >
            {i18n.t('btn.logout')}
          </Menu.Item>
          <Menu.Item key="4" >
            <Version />
          </Menu.Item>
        </Menu >
      } trigger={['click']} placement="bottomRight" >
        <span>
          <Badge dot>
            <Icon type="down-circle" className="dropdown-circle-icon" />
          </Badge>
        </span>
      </Dropdown >


    </span >
  )
}

const mapStateToProps = ({ auth, common }) => ({
  data: auth.data,
  currentModule: common.currentModule
})
const mapDispatchToProps = (dispatch) => ({
  getAccount: (callback) => dispatch(authActions.getAccount(callback)),
  logout: () => dispatch(authActions.logout()),
  changeTenant: (id, callback) => dispatch(commonActions.changeTenant(id, callback))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))