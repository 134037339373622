const stateKeys = [
  "accounts",
  "parameters",
  "posLists",
  "reportLines",
  "reportLineDetails",
  "report",
  "summary",
  "barChartDataTotalBankBalances",
  "barChartDataCreditCardProgram",
  "lineChart",
  "incomingTotals",
  "outgoingTotals",
  "stackedBarChartData",
  "stackedBarChartOnus",
  "creditCardOrganizationType",
  "reportDetail",
  "bankFileType"
]

const filterKeys = [
  "currentFilter",
  "banks",
  "tenantBanks",
  "currencies",
  "bankAccounts",
  "currentAccounts",
  "tenantBankAccounts",
  "creditCardOrganizationTypes",
  "creditCardPrograms",
  "creditCardBanks",
  "creditCardTypes",
  "bankFileTypes",
  "companyNames",
]

//#region State Generating - SHOULD NOT NE CHANGED 
const state = { filter: {} }
stateKeys.forEach(key => {
  state[key] = {
    list: { data: [], loading: null, count: null, error: null, statusCode: null },
    single: { data: null, saving: null, loading: null, error: null, statusCode: null }
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], error: null, loading: null
  }
})
export default state;
//#endregion


// export default {
//   accounts: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   parameters: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   banks: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   tenantBanks: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   reportLines: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   reportLineDetails: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   },
//   posLists: {
//     data: [],
//     singleData: null,
//     error: null,
//     loading: false,
//     saving: false,
//   }
// }