import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/dbs';
import { ActionButtons, DataTable, Filter, CheckAccessRight, FadeIn } from 'components/UIComponents';
import { defaultFilters, baseUrls } from "lib/constants";
import endpoints from 'config/endpoints';
import { formatCurrency, matchStatus } from 'helpers';
import { Icon } from 'antd';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { getKey } from 'lib/constants/defaultFilters';
import CustomerInfo from './Detail';

class CustomerInfos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.dbs.CustomerInfos(),
      customers: []
    };
    this.searchTimeout = null;
  }


  componentDidMount() {
    utils.dbs.getTenantBanks('DbsAccounts');
    this.getCustomerInfos();
    this.props.getCustomerInfosFilter();
    utils.common.checkAccessToUpdateOrDelete('CustomerDbsInfo');
  };

  getCustomerInfos = () => {
    const { filter } = this.state;
    this.props.getCustomerInfos(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getCustomerInfos);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getCustomerInfos);
  }


  onFilter = (newFilter) => {
    const { filter, customers } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getCustomerInfos();
      if (customers.length > 1) {
        const _filter = { filter: { Id: { type: 'guid', value: this.props.currentFilter[getKey()].fields[0] } } }
        this.props.getCustomerInfosFilter(_filter)
      }
    });
  };

  onSearch = e => {
    clearTimeout(this.searchTimeout);
    const _this = this;
    this.searchTimeout = setTimeout(() => {
      if (e && e !== '' && e.length > 2) {
        const filter = {
          filter: {
            or: [
              { CustomerDbsName: { contains: e } },
              { CustomerDbsCode: { contains: e } }
            ]
          }
        }
        _this.props.getCustomerInfosFilter(filter);
      }
    }, 500);
  }

  additionalButtons = () => {
    let buttons = [
      <div onClick={() => window.open('/static/files/DBS_Customer_Example.xlsx')}>
        <Icon type="file-excel" className="mr-5" />
        {i18n.t('dbs.downloadExampleFile')}
      </div>
    ];

    return buttons;
  };

  render() {
    const { customerInfos, customerInfosFilter, tenantBanks } = this.props;
    const { filter } = this.state;
    const filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.dbs.getBankName({ bankData }), keys: ['BankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('dbs.dealerCodeOrName'), name: (row) => row ? `${row.CustomerDbsName} (${row.CustomerDbsCode})` : '', options: customerInfosFilter.data, search: true, type: 'select', keys: ['Id'], uniqueKey: '#dealerCodeOrName', onSearch: this.onSearch, value: 'Id', guid: true },
      { label: i18n.t('dbs.matchStatus'), type: 'select', options: matchStatus, value: 'value', name: 'name', lang: 'lbl', keys: ['CurrentAccountId'], uniqueKey: '#CurrentAccountId', nullCheck: true },
      { label: i18n.t('dbs.forwardDatedInvoiceCount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['InvoiceCount'], uniqueKey: '#forwardDatedInvoiceCountMin' },
      { label: i18n.t('dbs.forwardDatedInvoiceCount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['InvoiceCount'], uniqueKey: '#forwardDatedInvoiceCountMax' },
      { label: i18n.t('dbs.forwardDatedInvoiceTotal', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['InvoiceSum'], uniqueKey: '#forwardDatedInvoiceTotalMin' },
      { label: i18n.t('dbs.forwardDatedInvoiceTotal', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['InvoiceSum'], uniqueKey: '#forwardDatedInvoiceTotalMax' },
      { label: i18n.t('dbs.declaredLimit', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['Limit'], uniqueKey: '#declaredLimitMin' },
      { label: i18n.t('dbs.declaredLimit', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['Limit'], uniqueKey: '#declaredLimitMax' },
      { label: i18n.t('dbs.usableLimit', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['Availability'], uniqueKey: '#usableLimitMin' },
      { label: i18n.t('dbs.usableLimit', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['Availability'], uniqueKey: '#usableLimitMax' },
      { label: i18n.t('dbs.risk', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['Risk'], uniqueKey: '#riskMin' },
      { label: i18n.t('dbs.risk', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['Risk'], uniqueKey: '#riskMax' },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="CustomerDbsInfo" editUrl item={row} hasDelete getDatas={this.getCustomerInfos} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('dbs.bankName'), key: 'BankCode', render: (data) => utils.dbs.getBankName({ data }) },
      { label: i18n.t('dbs.dealerName'), key: 'CustomerDbsName' },
      { label: i18n.t('dbs.dealerCode'), key: 'CustomerDbsCode' },
      { label: i18n.t('dbs.match'), key: 'CurrentAccountId', tdClass: 'text-center', render: (row) => <Icon style={{ color: row.CurrentAccountId === null ? '#D46464' : '#7ac32a', fontSize: 16 }} type={row.CurrentAccountId === null ? 'close' : 'check'} /> },
      { label: i18n.t('dbs.declaredLimit'), key: 'Limit', render: (row) => formatCurrency({ data: row.Limit, currencyCode: row.Currency ? row.Currency.Code : undefined, withSign: false }) },
      { label: i18n.t('dbs.usableLimit'), key: 'Availability', render: (row) => formatCurrency({ data: row.Availability, currencyCode: row.Currency ? row.Currency.Code : undefined, withSign: true }) },
      { label: i18n.t('dbs.forwardDatedInvoiceCount'), key: 'InvoiceCount' },
      { label: i18n.t('dbs.forwardDatedInvoiceTotal'), key: 'InvoiceSum', render: (row) => formatCurrency({ data: row.InvoiceSum, currencyCode: row.Currency ? row.Currency.Code : undefined, withSign: false }) },
      { label: i18n.t('dbs.risk'), key: 'Risk', render: (row) => formatCurrency({ data: row.Risk, currencyCode: row.Currency ? row.Currency.Code : undefined, withSign: false }) },
    ];

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...{ ...customerInfos, single: customerInfos.list } }}>
          <FadeIn>
            <Filter filters={filters} onFilter={this.onFilter} />
            <DataTable
              count={customerInfos.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="CustomerDbsInfo"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={customerInfos.list.data}
              loading={customerInfos.list.loading}
              title={i18n.t('route.dbs.customerInfos')}
              newButton="openDialog"
              getData={this.getCustomerInfos}
              Component={CustomerInfo}
              dialogTitle={i18n.t('lbl.customerInfo')}
              deleteOptions={{ stateKey: "customerInfos", url: "CustomerDbsInfo" }}
              additionalButtons={this.additionalButtons()}
              excel={{ module: "dbs", url: "CustomerDbsInfo.Excel", filter: { filter: filter.filter, expand: filter.expand } }}
              fromExcel={{ url: "CustomerDbsInfo.ReadExcel", baseUrl: baseUrls.dbs }}
            />
          </FadeIn>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs, common }) => ({
  customerInfos: dbs.customerInfos,
  customerInfosFilter: dbs.filter.customerInfos,
  tenantBanks: dbs.filter.tenantBanks,
  currentFilter: common.currentFilter
});
const mapDispatchToProps = (dispatch) => ({
  getCustomerInfos: (filter) => dispatch(actions.getAll({ filter, url: endpoints.dbs.customerDbsInfo, key: 'customerInfos' })),
  getCustomerInfosFilter: (filter, callback) => dispatch(actions.getFilter({ filter, url: endpoints.dbs.customerDbsInfoLookup, key: 'customerInfos' }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfos);