
import i18n from 'i18next';
import locales from './locales/index';
import { reactI18nextModule } from "react-i18next";

var userLanguage = localStorage.getItem("i18nextLng") || null

i18n
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'tr',
    interpolation: {
      escapeValue: false,
    },
    lng: userLanguage || "tr",
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    resources: locales
  });

export default i18n;