
import React, { forwardRef, useState } from 'react';
import { Input as AntInput } from "antd";
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';

const Input = forwardRef((props, ref) => {
  const [focus, setFocus] = useState(false);
  const _prefix = typeof props.prefix === "string" ? <ReactSVG src={icons[props.prefix]} /> : props.prefix;
  const _suffix = typeof props.suffix === "string" ? <ReactSVG src={icons[props.suffix]} /> : props.suffix;
  return (
    <div className="custom-input__wrapper">
      <AntInput
        {...props}
        ref={ref}
        onChange={(e) => props.onChange(e.target.value)}
        className={`${props.className || ''} custom-input`}
        prefix={_prefix}
        suffix={_suffix}
        onFocus={() => setFocus(!focus)}
        onBlur={() => setFocus(false)}
      />
      <label className={`${focus || props.value ? 'is-focus' : ''}`} htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
})

export default Input;
