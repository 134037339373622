import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import endpoints from 'config/endpoints';
import { httpMethods, controlTypes, declaredBankEftCodes } from 'lib/constants';
import i18n from 'plugins/i18n';
import _ from 'lodash';

import { Form, Row, Col, Button, Popconfirm, Tooltip, Modal } from 'antd';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import { Input, InputPassword } from 'components/UIComponents/NetbankaComponents';


class BankParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      data: [],
      InitialState: [],
      editingPassword: {},
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.tenantBankParameters.list.data && !state.data.length) 
      return { data: _.cloneDeep(props.tenantBankParameters.list.data) }
  
    if (props.dialogClosing && !props.tenantBanks.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    const { getTenantBanks, getTenantBankParameters } = this.props;
    if (this.id) {
      getTenantBanks(this.id, bankData =>
        bankData && getTenantBankParameters(bankData.bankEftCode)
      );
    }
  };

  componentWillUnmount() {
    this.props.clearStateNetekstre("tenantBankParameters");
  }

  getTenantBanks = () => {
    this.props.getTenantBanks({ orderBy: ['name asc'] });
  };

  updateParameter = (key, value, index) => {
    let { data } = this.state;
    if (data.length) data[index].value = value;
    this.setState({ data });
  };

  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveTenantBankParameters, tenantId } = this.props;
    const { data } = this.state;
    form.validateFields(error => {
      if (!error) {
        let tenantBankId = tenantId;
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveTenantBankParameters(data, method, () => {
          this.setState({ InitialState: this.state.data }, () => {
            dialogCloseRequest({ runGetData: true });
            this.getRefreshToken(tenantBankId);
          })
        });
      }
    });
  };

  getRefreshToken = (tenantBankId) => {
    const { bankEftCode } = this.state.data;

    if (bankEftCode === declaredBankEftCodes.Anadolubank) {
      this.setState({ refreshTokenRequestLoading: true });
      this.props.getRefreshToken(tenantBankId || this.id, (response) => {
        this.setState({ refreshTokenRequestLoading: false });
        if (response) {
          const confirmModal = Modal.confirm();
          confirmModal.update({
            title: i18n.t('msg.redirectWarning'),
            content: i18n.t('msg.redirectWarningMessage'),
            okText: i18n.t('btn.continue'),
            cancelText: i18n.t('btn.cancel'),
            onOk: () => {
              confirmModal.update({ okButtonProps: { loading: true } })
              window.location.replace(response.data);
            }
          })
        }
      })
    }
  }

  getPasswordField = (item, i) => {
    const { editingPassword } = this.state
    return (
      !editingPassword[i] && !item.required ?
        <Popconfirm
          cancelText={i18n.t('btn.cancel')}
          okText={i18n.t('btn.continue')}
          onConfirm={() => { editingPassword[i] = true; this.setState({ editingPassword }) }}
          title={i18n.t('msg.removingOldPassword')}
        >
          <Tooltip placement="bottom" title={i18n.t('msg.confirmUpdateThisField', { field: item.caption })}>
            <Button block className="blue #save" icon="lock" style={{ height: 38 }}>
              {item.caption}
            </Button>
          </Tooltip>
        </Popconfirm >
        :

        <InputPassword
          id={item.id}
          ref={el => this.password = el}
          class={`#${item.caption}`}
          label={item.caption}
          onChange={(e) => this.updateParameter(item.keyName, e, i)}
        />
    )
  }

  renderDialogButtons = checkAccessRights => {
    let { tenantBankParameters, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={tenantBankParameters.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={tenantBankParameters.single.loading} form="tenantBank" htmlType="submit" className="success #save" icon="save" loading={tenantBankParameters.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      tenantBankParameters.single.saving,
      tenantBankParameters.single.loading
    )
  }

  render() {
    const { tenantBanks, dialogCloseRequest, tenantBankParameters } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...tenantBanks, renderDialogButtons, dialogCloseRequest }}>
          <Form onSubmit={this.save} id="tenantBank" autoComplete="off">
            {
              tenantBankParameters.list.data.length > 0 &&
              <Loading loading={tenantBankParameters.list.loading}>
                <Row gutter={20}>
                  <React.Fragment>
                    {
                      _.unionBy(tenantBankParameters.list.data, "bankParameterDefinitionId").map((item, i) => (
                        <Col key={item.keyName} xs={24} sm={12}>
                          <Form.Item>
                            {getFieldDecorator(item.keyName, {
                              rules: [{ required: item.required, message: i18n.t('msg.required') }],
                              initialValue: item.value
                            })(
                              item.controlType === controlTypes.Password ?
                                this.getPasswordField(item, i)
                                :
                                <Input
                                  type={item.controlType && item.controlType === controlTypes.Password ? "password" : "text"}
                                  class={`#${item.caption}`}
                                  label={item.caption}
                                  onChange={(e) => this.updateParameter(item.keyName, e, i)}
                                />
                            )}
                          </Form.Item>
                        </Col>
                      ))}
                  </React.Fragment>
                </Row>
              </Loading>
            }
          </Form>
        </CheckAccessRight>
      </div >
    );
  }
}

const mapStateToProps = ({ netekstre, common }) => ({
  tenantBanks: common.tenantBanks,
  tenantBankParameters: netekstre.tenantBankParameters
});
const mapDispatchToProps = (dispatch) => ({
  getTenantBanks: (id, callback) => dispatch(commonActions.get({ url: endpoints.tenant.tenantBanks, key: 'tenantBanks', id }, callback)),
  getTenantBankParameters: (id) => dispatch(netekstreActions.getAll({ url: endpoints.nte.tenantBankParameterGetByEftCode, key: "tenantBankParameters", id })),
  saveTenantBankParameters: (data, method, callback) => dispatch(netekstreActions[method]({ data, url: endpoints.nte.tenantBankParameterBulk, key: "tenantBankParameters" }, callback)),
  getRefreshToken: (tenantBankId, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.anadolubankReturnUrl(tenantBankId), options: { errors: { showMessage: true } } }, callback)),
  clearStateNetekstre: (key) => dispatch(netekstreActions.clearState(key, true)),
  saveData: (data, method, endpoint, parameters, callback) => dispatch(commonActions[method]({ url: endpoint || endpoints.tenant.tenantBanks, parameters, key: 'tenantBanks', data }, callback)),
});

const BankParameterForm = Form.create()(BankParameter);
export default connect(mapStateToProps, mapDispatchToProps)(BankParameterForm);
