import dashboard from './dashboard.svg';
import bankAccounts from './bankAccounts.svg';
import banks from './banks.svg';
import createTestData from './createTestData.svg';
import currentAccounts from './currentAccounts.svg';
import definitions from './definitions.svg';
import notifications from './notifications.svg';
import reports from './reports.svg';
import rules from './rules.svg';
import settings from './settings.svg';
import transactionCategories from './transactionCategories.svg';
import transactions from './transactions.svg';
import userSettings from './userSettings.svg';
import balances from './balances.svg';
import reportAverage from './reportAverage.svg';
import reportBalances from './reportBalances.svg';
import customRules from './customRules.svg';
import generalRules from './generalRules.svg';
import roles from './roles.svg';
import users from './users.svg';
import dynamicFields from './dynamicFields.svg';
import fieldDefinitions from './fieldDefinitions.svg';
import erpTypes from './erpTypes.svg';
import typeCodes from './typeCodes.svg';
import voucherTypes from './voucherTypes.svg';
import licence from './licence.svg';
import tenants from './tenants.svg';
import packages from './packages.svg';
import tenantsLicence from './tenantsLicence.svg';
import matchingConditions from './matchingConditions.svg';
import add from './add.svg';
import circleCloseIcon from './circleCloseIcon.svg';
import bankParameters from './parameters.svg';


//////DBSicons
import customerInfos from './customerInfos.svg';
import createOrder from './createOrder.svg';
import orderList from './orderList.svg';
import parameters from './parameters.svg';
import reporting from './reporting.svg';


///common
import user from "./user.svg";
import key from "./key.svg";
import right from "./right.svg";
import logout from "./logout.svg";
import chevronLeft from "./chevron-left.svg";
import cashAsset from './cashAsset.svg';
import overdraftAccountBalance from './overdraftAccountBalance.svg';
import netAsset from './netAsset.svg';
import house from "./house.svg";
import manager from "./manager.svg";
import map from "./map.svg";
import marker from "./marker.svg";
import writing from "./writing.svg";
import tl from './tl.svg';
import changelog from "./changelog.svg";
import profile from './profile.svg';


///online kredi
import home from './home.svg';
import envelope from './envelope.svg';
import gsm from './gsm.svg';
import calendar from './calendar.svg';
import card from './card.svg';
import applications from './applications.svg';
import applyCredit from './applyCredit.svg';


///posrapor
import accounts from './accounts.svg';
import posLists from './posLists.svg';
import reportLines from './reportLines.svg';
import reportLineDetails from './reportLineDetails.svg';
import reportDetail from './reportLineDetails.svg';
import summaryReport from './reportLineDetails.svg';
import bankFileTypes from './accounts.svg';


//tos
import createPayments from './createPayments.svg';
import paymentLists from './paymentLists.svg';

//Cheque
import createCheque from './createCheque.svg';
import chequeList from './chequeList.svg';



//ui kit
import cancel from './cancel.svg';
import rightArrow from './icons-8-down-arrow.svg';
import print from './print.svg';
import redo from './redo.svg';
import creditCard from './credit-card.svg';


export default {
  dashboard,
  bankAccounts,
  banks,
  createTestData,
  currentAccounts,
  definitions,
  notifications,
  reports,
  rules,
  settings,
  categories: transactionCategories,
  allCategories: transactionCategories,
  generalCategories: parameters,
  customCategories: parameters,
  transactions,
  userSettings,
  memberManagement: userSettings,
  balances,
  reportAverage,
  reportBalances,
  customRules,
  generalRules,
  systemRules: generalRules,
  roles,
  users,
  dynamicFields,
  fieldDefinitions,
  erpTypes,
  typeCodes,
  voucherTypes,
  licence,
  tenants,
  packages,
  tenantsLicence,
  psrParameters: parameters,
  dbsParameters: parameters,
  jobManagement: transactionCategories,
  matchingConditions,
  bankParameters,
  customers: tenants,

  //dbs
  customerInfos,
  createOrder,
  orderList,
  parameters,
  reporting,
  incomingPayment: cashAsset,
  pendingPayment: cashAsset,
  remainingPayment: netAsset,

  //common
  user,
  key,
  right,
  logout,
  chevronLeft,
  cashAsset,
  overdraftAccountBalance,
  netAsset,
  house,
  manager,
  map,
  marker,
  writing,
  tl,
  changelog,
  profile,
  tenantInfo: tenants,

  //online kredi
  home,
  card,
  calendar,
  envelope,
  gsm,
  applications,
  applyCredit,

  //posrapor
  accounts,
  posLists,
  reportLines,
  reportLineDetails,
  reportDetail,
  summaryReport,
  valorPureAmount: cashAsset,
  transactionCount: overdraftAccountBalance,
  transactionPureAmount: netAsset,
  bankFileTypes,

  //tos
  createPayments,
  paymentLists,
  recipientAccounts: bankAccounts,

  //Cheque
  createCheque,
  chequeList,

  //netahsilat
  posList: posLists,
  payment: voucherTypes,
  netlinkTransaction: chequeList,
  paymentParameters: settings,
  doPay: reportLines,

  //ui kit
  cancel,
  rightArrow,
  add,
  print,
  redo,
  creditCard,
  circleCloseIcon
}