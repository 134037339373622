import React, { Component } from 'react';
import { FadeIn, Typography, FormElements } from 'components/UIComponents';
import i18n from 'plugins/i18n';
import { Form, Button, Row, Col } from 'antd';

export default class Test extends Component {
  render() {
    return (
      <FadeIn>
        <Typography.H className="mb-0 text-center" size={1}>{i18n.t('lbl.membershipOperations')}</Typography.H>
        <Form className="mt-80 block">
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12}>
              <Form.Item>
                <FormElements.Input
                  // type="password"
                  label={i18n.t('lbl.name')}
                  prefix="user"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <FormElements.Input
                  // type="password"
                  label={i18n.t('lbl.surname')}
                  prefix="user"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <FormElements.Input
                  // type="password"
                  label={i18n.t('lbl.email')}
                  prefix="user"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <FormElements.Input
                  // type="password"
                  label={i18n.t('lbl.gsm')}
                  prefix="user"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <FormElements.Input
                  // type="password"
                  label={i18n.t('lbl.password')}
                  prefix="key"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <FormElements.Input
                  // type="password"
                  label={i18n.t('lbl.confirmPassword')}
                  prefix="key"
                />
              </Form.Item>
            </Col>
          </Row>

          <Button htmlType="submit" block className="auth-button mt-20">
            {i18n.t('btn.continue')}
          </Button>
        </Form>
      </FadeIn>
    )
  }
}
