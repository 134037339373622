export const barChart = ({ multiTenant, dataIndex, component }) => {
  return {
    cornerRadius: 15,
    maintainAspectRatio: false,
    plugins: {
      labels: false
    },
    hover: {
      mode: 'nearest',
      intersect: true,
      onHover: function (e, v) { this.ctx.canvas.style.cursor = v && multiTenant ? "pointer" : "default" },
    },
    legend: {
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let str = tooltipItem.yLabel.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          str += str.indexOf(',') !== -1 ? '' : ',00';
          let strs = str.split(',');
          if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
          dataIndex !== tooltipItem.index && component.setState({ dataIndex: tooltipItem.index })
          return strs.join(',');
        },
      }
    },
    scales: {
      yAxes: [{
        gridLines: {
          drawBorder: false,
          display: true,
          color: "transparent",
          zeroLineColor: "#eee",
          zeroLineWidth: 1
        },
        ticks: {
          color: "rgba(55, 5, 55, 1.0)",
          callback: function (label, index, labels) {
            let str = label.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            str += str.indexOf(',') !== -1 ? '' : ',00';
            let strs = str.split(',');
            if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
            return strs.join(',');
          },
          beginAtZero: true
        },
      }],
      xAxes: [{ ticks: { autoSkip: false } }]
    }
  }
};