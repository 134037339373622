import React from 'react';
import { Card, Row, Col } from "antd";
import i18n from 'plugins/i18n';


const CurrencyCard = ({item, isEffectiveCurrencyCode}) => {
  const _buying = isEffectiveCurrencyCode ? item.effectiveBuying : item.buying;
  const _selling = isEffectiveCurrencyCode ? item.effectiveSelling : item.selling;
  return (
    <Card className="mb-16 currency-card">
      <Row className="currency-row text-center">
        <Col md={24} xs={12}>
          <div className={`currency-flag currency-flag-${item.from && item.from.toLowerCase()}`}></div>
          {item.from}
        </Col>
      </Row>
      <Row gutter={10} className="head-row text-center">
        <Col md={12} xs={12}>
          <span>{i18n.t('lbl.buying')}</span>
        </Col>
        <Col md={12} xs={12}>
        <span>{i18n.t('lbl.selling')}</span>
        </Col>
      </Row>
      <Row gutter={10} className="content-row text-center">
        <Col md={12} xs={12} >
          <span>{_buying.toFixed(4)}</span>
        </Col>
        <Col md={12} xs={12}>
          <span>{_selling.toFixed(4)}</span>
        </Col>
      </Row>
    </Card>
  )
}


export default CurrencyCard;