import React, { Component } from 'react';
import { Select } from 'antd';

class NBSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? (props.mode === ('tags' || 'multiple')) ? props.value.map(x => x.toString()) : props.value.toString() : undefined,
      isFocused: false,
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      if (props.mode === 'tags' || props.mode === 'multiple') {
        return {
          value: (props.options && props.value && props.options.length > 0) ? props.value.map(x => x.toString()) : undefined,
        }
      }
      else {
        return {
          value: (props.value && props.options && props.options.length > 0) ? props.value.toString() : undefined,
        }
      }
    }
    else {
      return null;
    }
  };

  handleChange = (e) => {
    let difference = window._.concat(window._.difference(e, this.state.value), window._.difference(this.state.value, e));
    this.setState({ value: !e ? e : typeof e !== 'object' ? e.toString() : e }, () => {
      if ((this.props.mode === 'tags' || this.props.mode === 'multiple')) {
        if (typeof this.props.options[0][this.props.optVal] === 'number') {
          this.props.onChange && this.props.onChange(e.map(x => parseInt(x)), difference);
        }
        else {
          this.props.onChange && this.props.onChange(e, difference);
        }
      }
      else {
        if (this.props.options[0] && typeof this.props.options[0][this.props.optVal] === 'number') {
          this.props.onChange && this.props.onChange(parseInt(e), difference);
        }
        else {
          this.props.onChange && this.props.onChange(e, difference);
        }
      }
    });
  };

  render() {
    let { value } = this.state;
    let { label, className, id, disabled, required, optName, allowClear, optVal, options, showSearch, onSearch, onFocus, mode } = this.props;
    // let _options = options ? typeof optName === 'string' && optName !== '' ? _.cloneDeep(options).sort((a, b) => a[optName].localeCompare(b[optName])) : options : [];
    return (
      <div className={`nb nb-select ${mode === 'tags' || mode === 'multiple' ? 'nb-tag' : ''}`}>
        <Select
          id={id}
          maxTagCount={1}
          value={value}
          showSearch={showSearch}
          onSearch={onSearch}
          onChange={this.handleChange}
          onFocus={() => { this.setState({ isFocused: true }); onFocus && onFocus() }}
          onBlur={() => this.setState({ isFocused: false })}
          mode={mode ? mode : 'default'}
          className={`${(value !== null && value !== undefined && value !== '' && value.length) && 'is-filled'} ${className || ''}`}
          optionFilterProp="children"
          disabled={!!disabled}
          allowClear={allowClear === false ? false : true}
          required={!!required}>
          {
            options.filter(x => x !== null).map((x, i) => (
              <Select.OptGroup key={i} label={x.label}>
                {x.options.map((item, y) => (
                  <Select.Option disabled={item.disabled} className={`#select${y + 1}`} key={Math.random()} value={optVal ? typeof optVal === 'string' ? (mode === 'tags' || mode === 'multiple') ? (item[optVal] !== null && item[optVal] !== undefined) ? item[optVal].toString() : '' : item[optVal].toString() : optVal(item) : item}>
                    {typeof optName === 'string' ? item[optName] : optName === null ? item : optName(item)}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
        </Select>
        <label className={(this.state.isFocused || (value && value.toString() !== '')) ? 'focused' : ''}>{label || ''}</label>
      </div>
    );
  };
};

export default NBSelect;