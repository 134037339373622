import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { CustomerInfoModel } from 'models';
import actions from 'store/actions/dbs';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import utils from 'lib';

class CustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new CustomerInfoModel(),
      InitialState: new CustomerInfoModel(),

    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.customerInfos.single.data && !state.data.Id) {
      const data = new CustomerInfoModel({ ...props.customerInfos.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.customerInfos.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) {
      this.props.getCustomerInfos(this.id, (response) => {
        const filter = { filter: { id: { type: 'guid', value: response.CurrentAccountId } } }
        this.props.getCurrentAccounts(filter)
      });
    }
    else
      this.setState({ loading: false });
  };

  componentWillUnmount() {
    utils.dbs.clearState('customerInfos');
    utils.dbs.clearFilter('currentAccounts');
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };


  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData } = this.props;
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        const _data = _.cloneDeep(this.state.data);
        saveData(_data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  onSearch = e => {
    const _this = this;
    if (e && e !== '' && e.length > 2) {
      const filter = { filter: { or: [{ name: { contains: e } }, { title: { contains: e } }, { code: { contains: e } }] } }
      _this.props.getCurrentAccounts(filter);
    }
  }


  renderDialogButtons = checkAccessRights => {
    let { customerInfos, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={customerInfos.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={customerInfos.single.loading} form="customerInfo" htmlType="submit" className="success #save" icon="save" loading={customerInfos.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      customerInfos.single.saving,
      customerInfos.single.loading
    )
  }

  render() {
    const { tenantBanks, currentAccounts, customerInfos, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;
    const { loading, data } = this.state;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...customerInfos, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="customerInfo">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('dbs.bankName')}
                        options={tenantBanks.data}
                        optVal="Code"
                        optName={(bankData) => utils.dbs.getBankName({ bankData })}
                        onChange={(e) => this.update('BankCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('CustomerDbsName', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CustomerDbsName
                    })(
                      <Input className="#CustomerDbsName" label={i18n.t('dbs.dealerName')} onChange={(e) => this.update('CustomerDbsName', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                  <Form.Item>
                    {getFieldDecorator('CurrentAccountId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CurrentAccountId
                    })(
                      <Select
                        loading={currentAccounts.loading}
                        showSearch
                        onSearch={this.onSearch}
                        className="#CurrentAccountId"
                        label={i18n.t('dbs.currentAccount')}
                        options={currentAccounts.data}
                        optVal="Id"
                        optName={(item) => `${item.Name} - (${item.Code})`}
                        onChange={(e) => this.update('CurrentAccountId', e)}
                      />
                      // <Select loading={currentAccounts.loading} showSearch onSearch={this.onSearch} className="#CurrentAccountId" label={i18n.t('dbs.currentAccount')} options={currentAccounts.data} optVal="Id" optName="Name" onChange={(e) => this.update('CurrentAccountId', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('CustomerDbsCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CustomerDbsCode
                    })(
                      <Input className="#CustomerDbsCode" label={i18n.t('dbs.dealerCode')} onChange={(e) => this.update('CustomerDbsCode', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  customerInfos: dbs.customerInfos,
  currentAccounts: dbs.filter.currentAccounts,
  tenantBanks: dbs.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getCustomerInfos: (id, callback) => dispatch(actions.get({ url: endpoints.dbs.customerDbsInfo, key: 'customerInfos', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.dbs.customerDbsInfo, key: 'customerInfos', data }, callback)),
  getCurrentAccounts: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.lookups.currentAccounts, key: 'currentAccounts' })),
});
const CustomerInfoForm = Form.create()(CustomerInfo);
export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoForm);