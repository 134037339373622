import React, { Component } from 'react';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { Form, Checkbox } from 'antd';
import { TextInputModel } from 'models';
import { compareFields } from 'helpers';
import actions from 'store/actions/netekstre';
import { connect } from "react-redux";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import _ from 'lodash'
const operationType = 1;

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new TextInputModel(props.fieldDefinitions.single.data)
    }
  };

  componentDidMount() {
    this.props.getTargetFields(operationType);
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  targetUpdate = (index, key, value) => {
    let { data } = this.state;
    data.targets[index][key] = value;
    this.setState({ data });
  };

  save = () => {
    return new Promise((resolve) => {
      let { data } = this.state;
      let _data = _.cloneDeep(data);
      this.props.form.validateFields(error => {
        if (!error) {
          _data = compareFields(null, new TextInputModel(_data), data.id ? new TextInputModel(this.props.fieldDefinitions.single.data) : null);
          _data.operationType = operationType;
          let method = data.id ? httpMethods.put : httpMethods.post;
          this.props.saveData(_data, method, (response) => {
            response && resolve();
          });
        }
      });
    })
  };

  render() {
    let { data } = this.state;
    let { getFieldDecorator } = this.props.form;
    let { targetFields } = this.props;
    return (
      <div>
        <Form>
          <Form.Item>
            {
              getFieldDecorator('name', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.name
              })(
                <Input className="#textInputName" onChange={(e) => this.update('name', e)} label={i18n.t("lbl.title")} />
              )
            }
          </Form.Item>
          <Form.Item>
            {
              getFieldDecorator('targets', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.targets[0] ? data.targets[0].name : ''
              })(
                <Select className="#textInputTargets" options={targetFields.data} label={i18n.t("lbl.target")} optName="description" optVal="name" onChange={(e, v) => this.update('targets', [{ name: e, dynamicPropertyId: e ? targetFields.data.find(x => x.name === e).dynamicPropertyId : null, index: 1, modelState: 2 }])} />
              )
            }
          </Form.Item>
          <Form.Item>
            {
              getFieldDecorator('pattern', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.pattern
              })(
                <Input className="#textInputPattern" onChange={(e) => this.update('pattern', e)} label={i18n.t("lbl.formulaPattern")} />
              )
            }
          </Form.Item>
          <Checkbox
            checked={data.targets[0] ? data.targets[0].required : false}
            onChange={(e) => this.targetUpdate(0, 'required', e.target.checked)}
            disabled={!data.targets[0]}
          >
            {i18n.t('lbl.required')}
          </Checkbox>
        </Form>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({
  targetFields: netekstre.filter.targetFields,
  fieldDefinitions: netekstre.fieldDefinitions
});
const mapDispatchToProps = (dispatch) => ({
  getTargetFields: (operationType) => dispatch(actions.getFilter({ url: endpoints.nte.propertyConfigsGetTargetFields(operationType), key: 'targetFields' })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.propertyConfigs, data }, callback)),
  clearState: () => dispatch(actions.clearState("fieldDefinitions")),
});
const TextInputForm = Form.create()(TextInput);
export default connect(mapStateToProps, mapDispatchToProps)(TextInputForm);