import _ from 'lodash'

export default {
  //#region [GET]
  getAll: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.list = { data: [], loading: true, count: null, error: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getAllSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.list = {
        data: payload.isNewODataStructure ? payload.data.value : payload.data,
        loading: false,
        count: payload.isNewODataStructure ? payload.data['@odata.count'] : null,
        error: null,
        statusCode: payload.statusCode
      }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getAllFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.list = {
        data: [],
        loading: false,
        count: null,
        error: payload.error,
        statusCode: payload.statusCode
      }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  get: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: true, error: null, saving: false, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  getFilter: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: [], loading: true, error: null }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getFilterSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: payload.isNewODataStructure ? payload.data.value : payload.data, loading: false, error: null }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getFilterFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      data = { data: [], loading: false, error: payload.error }
      data = { filter: { ...state.filter, [payload.key]: { ...data } } }
    }
    return data;
  },

  getWithPost: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: true, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [POST]
  post: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  postSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  postFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.statusCode && payload.statusCode === 406 ? _.cloneDeep(_.get(state, keys)).single.data : null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [PUT]
  put: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: _.cloneDeep(_.get(state, keys)).single.data, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  putSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  putFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.statusCode && payload.statusCode === 406 ? _.cloneDeep(_.get(state, keys)).single.data : null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [DELETE]
  delete: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  deleteSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  deleteFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [PATCH]
  patch: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys))
      data.single = { data: null, loading: false, error: false, saving: true, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  patchSuccess: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: payload.data, loading: false, error: null, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },

  patchFailure: (state, { payload }) => {
    let data = {};
    if (payload.key) {
      const keys = _.split(payload.key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: payload.error, saving: false, statusCode: payload.statusCode }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (state, { key, clearList = false }) => {
    let data = {};
    if (key) {
      const keys = _.split(key, '.');
      data = _.cloneDeep(_.get(state, keys));
      data.single = { data: null, loading: false, error: null, saving: null, statusCode: null };
      if(clearList) data.list =  { data: [], loading: true, count: null, error: null, statusCode: null }
      data = keys ? { [keys[0]]: keys.length > 1 ? { ...state[keys[0]], [keys[1]]: data } : data } : {};
    }
    return data;
  },
  //#endregion

}