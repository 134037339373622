import React from 'react';
import { store } from 'store/redux/createStore';
import _ from 'lodash';
import actions from 'store/actions/common';
import endpoints from 'config/endpoints';

export default class CommonUtils {

  static getBankName({ data = null, listKeys = 'banks', dataField = 'bankEftCode', listField = 'eftCode', bankData = null, className = '' }) {
    let list, bank;
    if (!bankData) {
      list = _.get(store.getState().common.filter, `${listKeys}.data`);
      bank = list && list.find(x => x[listField] === _.get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall || bank.LogoSmall} className={`bank-icon ${className}`} alt="" /> {bank.name || bank.Name}</> : '';
  }

  static getTenantBanks() {
    dispatchers.getTenantBanks({ orderBy: ['name asc'] });
  }

  static checkAccessToUpdateOrDelete(accessRight) {
    this.resetAccessToUpdateOrDelete();
    if (accessRight) {
      const hasAccess = !!store.getState().auth.data.accessRights.find(x => x.endPoint === accessRight && x.method === "PUT");
      dispatchers.changeAccessibility(hasAccess);
    }
  }

  static resetAccessToUpdateOrDelete() {
    dispatchers.changeAccessibility(false);
  }

  static getBankAccounts(filter) {
    dispatchers.getBankAccounts({ filter, orderBy: ['name asc'] });
  }

  static clearSelectedItems(callback) {
    dispatchers.setSelectedItems(callback);
  }

  static getUsers(filter) {
    dispatchers.getUsers(filter);
  }

  static clearExcelFormat() {
    dispatchers.clearExcelFormat();
  }

  static getCurrencies() {
    dispatchers.getCurrencies({ orderBy: ['currencyName asc'] });
  }

  static getCities() {
    dispatchers.getCities({ orderBy: ['name asc'] });
  }

  static getDistricts(cityId, callback) {
    dispatchers.getDistricts(cityId, { orderBy: ['name asc'] }, callback);
  }

  static getTenantModules(parameters) {
    dispatchers.getTenantModules(parameters);
  }

  static getParentTenantModules(filter, parameters) {
    filter = { filter };
    dispatchers.getParentTenantModules(filter, parameters);
  }

  static getModules(filter, parameters) {
    filter = { filter };
    dispatchers.getModules(filter, parameters);
  }

  static clearFilter(key) {
    dispatchers.clearFilter(key);
  }

  static prepareBulkData(requiredKeys, tableData, defaultFields = []) {
    return new Promise((resolve, reject) => {
      let data = [];
      const { datatableInline } = store.getState().common;

      _.forEach(tableData, (x, i) => {
        const inlineData = _.find(datatableInline.list.data, y => (x.id && y.id === x.id) || (x.id && y.id === x.Id) || y.index === i) || {};
        let editedData = _.cloneDeep(inlineData);
        delete editedData?.index; delete editedData?.id;

        _.forEach(_.keys(x), key => {
          if (requiredKeys.includes(key) && ((!x[key] && editedData[key] === undefined) || (editedData[key] === '')))
            reject({ errorMessage: "pleaseFillRequiredFields" })

          if (inlineData.id && x[key] === editedData?.[key])
            delete editedData[key];

        })
        if (!_.isEqual(editedData, {})) {
          const additionalDefaultData = Object.fromEntries(defaultFields.map(d => [d, x[d]]));
          if (inlineData.id) {
            const fields = _.map(editedData, (value, key) => ({ key, value }))
            data.push({ id: inlineData.id, fields, ...additionalDefaultData })
          }
          else {
            data.push({ ...x, ...editedData, ...additionalDefaultData })
          }
        }
      })
      if (data.length) resolve(data);
      else reject({ errorMessage: "thereIsNoChange" })
    })

  }
}

const dispatchers = {
  getCurrencies: (filter) => store.dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies', filter })),
  setSelectedItems: (callback) => store.dispatch(actions.setSelectedItems(false, callback)),
  clearExcelFormat: () => store.dispatch(actions.clearExcelFormat()),
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.tenantBanks, key: 'tenantBanks', filter })),
  changeAccessibility: (e) => store.dispatch(actions.toggleData({ key: "hasAccessRightToUpdateOrDeleteCurrentPage", data: e })),
  getBankAccounts: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.bankAccounts, key: 'bankAccounts' })),
  getUsers: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.users, key: 'users' })),
  getCities: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.info.cities, key: 'cities' })),
  getDistricts: (id, filter, callback) => store.dispatch(actions.getFilter({ filter, url: endpoints.info.district, key: 'districts', id }, callback)),
  getTenantModules: (parameters) => store.dispatch(actions.getFilter({ parameters, url: endpoints.lookups.tenantModules, key: 'tenantModules' })),
  getModules: (filter, parameters) => store.dispatch(actions.getFilter({ filter, parameters, url: endpoints.lookups.modules, key: 'modules' })),
  getParentTenantModules: (filter, parameters) => store.dispatch(actions.getFilter({ filter, parameters, url: endpoints.lookups.parentTenantModules, key: 'parentTenantModules' })),
  clearFilter: (key) => store.dispatch(actions.clearFilter(key)),
}