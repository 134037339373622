import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { DatePicker, InputNumber, Select } from 'components/UIComponents/NetbankaComponents';
import { ReportingModel } from 'models';
import actions from 'store/actions/dbs';
import endpoints from 'config/endpoints';
import { Loading } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new ReportingModel(),
      InitialState: new ReportingModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.reportings.single.data && !state.data.Id) {
      const data = new ReportingModel({ ...props.reportings.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.reportings.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getReporting(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    let { reportings, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={reportings.single.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>,
      reportings.single.saving,
      reportings.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { banks } = this.props;
    const { renderDialogButtons } = this;
    
    renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    value={data.BankCode}
                    className="#bank"
                    label={i18n.t('dbs.bankName')}
                    options={banks.data}
                    optVal="BankCode"
                    optName={(bankData) => utils.dbs.getBankName({ bankData })}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.InsertedTime} label={i18n.t('dbs.createdDate')} className="#InsertedTime" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.InvoiceDate} label={i18n.t('dbs.invoiceDate')} className="#InvoiceDate" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.DueDate} label={i18n.t('dbs.expiryDate')} className="#DueDate" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.CurrencyCode} className="#CurrencyCode" label={i18n.t('dbs.CurrencyCode')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.CurrentAccountId} className="#CurrentAccountId" label={i18n.t('dbs.CurrentAccountId')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.OperationStatusId} className="#OperationStatusId" label={i18n.t('dbs.OperationStatusId')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.InvoiceNumber} className="#InvoiceNumber" label={i18n.t('dbs.InvoiceNumber')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.Description} className="#Description" label={i18n.t('dbs.Description')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  reportings: dbs.reportings,
  banks: dbs.filter.banks,
});
const mapDispatchToProps = (dispatch) => ({
  getReporting: (id, callback) => dispatch(actions.get({ url: endpoints.dbs.reporting, key: 'reportings', id }, callback)),
  clearState: () => dispatch(actions.clearState("reportings"))
});
const ReportLineForm = Form.create()(Reporting);
export default connect(mapStateToProps, mapDispatchToProps)(ReportLineForm);