export const chartTypes = {
  barChart: "barChart",
  pieChart: "pieChart",
  totalTransfer: "TotalTransfer",
  stackedBarChart: "stackedBarChart"
}

export const summaryCardTypes = {
  cashAsset: 'cashAsset',
  overdraftAccountBalance: 'overdraftAccountBalance',
  netAsset: 'netAsset',
  transactionCount: 'transactionCount',
  transactionPureAmount: 'transactionPureAmount',
  valorPureAmount: 'valorPureAmount',
  incomingPayment: 'incomingPayment',
  pendingPayment: 'pendingPayment',
  remainingPayment: 'remainingPayment',
}