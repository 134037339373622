import React, { useEffect, useState } from 'react'
import { Row, Col, Steps } from 'antd';
import { KrediContent, Typography, Loading } from 'components/UIComponents';
import ReactSVG from 'react-svg';
import CheckIcon from 'assets/img/icons/check.svg';
import CheckMarkIcon from 'assets/img/icons/check-mark.svg';
import LoadingIcon from 'assets/img/icons/loading.svg';
import LinkIcon from 'assets/img/icons/link.svg';
import i18n from 'plugins/i18n';
import { stepsStatuses, httpMethods } from 'lib/constants';
import endpoints from 'config/endpoints';
import { connect } from 'react-redux';
import actions from 'store/redux/netekstre';
import { useParams } from 'react-router-dom';

const ResultApplication = ({ getStatus }) => {
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentProcess, setCurrentProcess] = useState(null)
  const id = useParams().id;
  const applicationStatuses = {
    creditLinkSent: 0,
    creditWaitingApproval: 1,
    applicationCompleted: 2
  }

  useEffect(() => {
    getStatus(id, response => {
      setCurrentStatus(response.currentStatus);
      setLoading(true)
    })
  }, [getStatus, id]);

  const getCurrent = () => {
    switch (currentStatus) {
      case 1:
        if (currentProcess === null) setCurrentProcess(applicationStatuses.creditLinkSent);
        return applicationStatuses.creditLinkSent;
      case 3:
        if (currentProcess === null) setCurrentProcess(applicationStatuses.applicationCompleted);
        return applicationStatuses.applicationCompleted;
      default:
        if (currentProcess === null) setCurrentProcess(applicationStatuses.creditWaitingApproval);
        return applicationStatuses.creditWaitingApproval;
    }
  }

  const getStepStatus = step => {
    if (step === currentProcess) return stepsStatuses.process;
    else if (step > currentProcess) return stepsStatuses.wait
    else return stepsStatuses.finish
  }


  return (
    <KrediContent className="bordered-top">
      <Loading loading={!loading || !currentStatus}>
        <Row>
          <Col xs={24} md={{ offset: 5, span: 14 }} className="text-center">
            <div className="result-application">
              <ReactSVG className="check-icon" src={CheckIcon} />
              <Typography.P size="big" className="semi-bold">
                {i18n.t("lbl.referenceCode", { code: id })}
              </Typography.P>

              <Steps type="navigation" current={getCurrent()}>
                <Steps.Step {...{ status: getStepStatus(applicationStatuses.creditLinkSent), description: i18n.t('lbl.creditLinkSent'), icon: <ReactSVG src={LinkIcon} /> }} />
                <Steps.Step {...{ status: getStepStatus(applicationStatuses.creditWaitingApproval), description: i18n.t('lbl.creditWaitingApproval'), icon: <ReactSVG src={LoadingIcon} /> }} />
                <Steps.Step {...{ status: getStepStatus(applicationStatuses.applicationCompleted), description: i18n.t('lbl.applicationCompleted'), icon: <ReactSVG src={CheckMarkIcon} /> }} />
              </Steps>
            </div>
          </Col>
        </Row>
      </Loading>
    </KrediContent>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getStatus: (id, callback) => dispatch(actions.globalRequest({ url: endpoints.kredi.getTransactionStatusByReferenceCode, id }, httpMethods.get, callback)),
});
export default connect(null, mapDispatchToProps)(ResultApplication);
