import React, { Fragment } from 'react'
import { Dropdown, Menu, Icon } from "antd";
import TrFlag from 'assets/img/TR.svg';
import EnFlag from 'assets/img/EN.png';
import i18n from 'i18next'
import { connect } from "react-redux";
import actions from "store/actions/common";

const flags = {
  en: EnFlag,
  tr: TrFlag
}

function HeaderAddons(props) {

  const onLangClick = (e) => {
    i18n.changeLanguage(e.key, () => localStorage.setItem('i18nextLng', e.key))
    props.dispatch(actions.changeLanguage(e.key))
  };

  return (
    <div className="header-addons">
      {
        props.addons && props.addons.length > 0 &&
        props.addons.map(addon => <Fragment key={Math.random()}>{addon}</Fragment>)
      }
      <Dropdown className="persist-addon" overlay={
        <Menu onClick={onLangClick}>
          <Menu.Item key="tr">
            {i18n.t('btn.tr')}
          </Menu.Item>
          <Menu.Item key="en">
            {i18n.t('btn.en')}
          </Menu.Item>
        </Menu>
      } trigger={['click']} placement="bottomRight">
        <span>
          <img className="lang-flag" alt="lang" src={flags[props.i18nextLng.substr(0, 2)]} />
          <span>{i18n.t(`btn.${props.i18nextLng.substr(0, 2)}`)}</span>
          <Icon type="down" />
        </span>
      </Dropdown>
    </div>
  )
}


const mapStateToProps = ({ common }) => ({
  i18nextLng: common.i18nextLng,
});

export default connect(mapStateToProps)(HeaderAddons);