import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/cek';
import reducers from 'store/reducers/cek';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllCek: ['payload', 'callback'],
  getAllCekSuccess: ['payload'],
  getAllCekFailure: ['payload'],
  getCek: ['payload', 'callback'],
  getCekSuccess: ['payload'],
  getCekFailure: ['payload'],
  postCek: ['payload', 'callback'],
  postCekSuccess: ['payload'],
  postCekFailure: ['payload'],
  putCek: ['payload', 'callback'],
  putCekSuccess: ['payload'],
  putCekFailure: ['payload'],
  deleteCek: ['payload', 'callback'],
  deleteCekSuccess: ['payload'],
  deleteCekFailure: ['payload'],
  patchCek: ['payload', 'callback'],
  patchCekSuccess: ['payload'],
  patchCekFailure: ['payload'],
  clearStateCek: ['key'],
  getWithPostCek: ['payload'],
  getWithPostCekSuccess: ['payload'],
  getWithPostCekFailure: ['payload'],
  getFilterCek: ['payload', 'isNewODataStructure'],
  getFilterCekSuccess: ['payload', 'isNewODataStructure'],
  getFilterCekFailure: ['payload'],
});
export const CekTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state, action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state, action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state, action)
  return state.merge(data);
};



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_CEK]: getAll,
  [Types.GET_ALL_CEK_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_CEK_FAILURE]: getAllFailure,
  [Types.GET_CEK]: get,
  [Types.GET_CEK_SUCCESS]: getSuccess,
  [Types.GET_CEK_FAILURE]: getFailure,
  [Types.POST_CEK]: post,
  [Types.POST_CEK_SUCCESS]: postSuccess,
  [Types.POST_CEK_FAILURE]: postFailure,
  [Types.PUT_CEK]: put,
  [Types.PUT_CEK_SUCCESS]: putSuccess,
  [Types.PUT_CEK_FAILURE]: putFailure,
  [Types.DELETE_CEK]: _delete,
  [Types.DELETE_CEK_SUCCESS]: deleteSuccess,
  [Types.DELETE_CEK_FAILURE]: deleteFailure,
  [Types.PATCH_CEK]: patch,
  [Types.PATCH_CEK_SUCCESS]: patchSuccess,
  [Types.PATCH_CEK_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_CEK]: clearState,
  [Types.GET_WITH_POST_CEK]: getWithPost,
  [Types.GET_WITH_POST_CEK_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_CEK_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_CEK]: getFilter,
  [Types.GET_FILTER_CEK_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_CEK_FAILURE]: getFilterFailure,
});