import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox } from 'antd';
import { Input, Select, DatePicker, Tooltip } from 'components/UIComponents/NetbankaComponents';
import { TransactionModel } from 'models';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { Loading } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import _ from 'lodash';
import i18n from 'plugins/i18n';

class Transaction extends Component {
  state = {
    loading: true,
    saving: false,
    data: new TransactionModel(),
    InitialState: new TransactionModel(),
    maxAmount: undefined,
    accountCodes: [],
    planCodes: []
  };

  componentDidMount() {
    let { split, notSplit, maxAmount } = this.props;

    this.getVoucherTypes();
    const _split = this.convertFieldsToCamelize(split);
    this.setState({ loading: false, data: new TransactionModel(_split), InitialState: _.cloneDeep(_split), maxAmount: notSplit ? (split.amount || split.Amount) : maxAmount }, () => {
      if (_split.accountPlanCode && _split.accountPlanCode !== '') {
        this.planCodeSearch(_split.accountPlanCode);
      }
      if (_split.currentAccountCode && _split.currentAccountCode !== '') {
        this.accountCodeSearch(_split.currentAccountCode);
      }
    })
  };

  getTenantBanks = () => {
    this.props.getTenantBanks();
  };

  getVoucherTypes = () => {
    this.props.getVoucherTypes();
  };

  planCodeSearch = (e) => {
    this.props.getPlanCodes(e, (response) => {
      this.setState({ planCodes: response })
    });
  };

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  update = (key, value) => {
    let { data } = this.state;
    // if (key === 'amount') {
    //   value = this.setLimits(key, value);
    //   // value = parseFloat(value)
    // }
    this.setState({ data: { ...data, [key]: value === undefined ? '' : value } });
  };

  // setLimits = (key, value) => {
  //   let { maxAmount } = this.state;
  //   let _value;
  //   if (Math.sign(maxAmount) !== -1) {
  //     _value = parseFloat(value) > parseFloat(maxAmount) ? maxAmount : parseFloat(value) < 0 ? 0 : value
  //   }
  //   else {
  //     _value = parseFloat(value) < parseFloat(maxAmount) ? maxAmount : parseFloat(value) > 0 ? 0 : value
  //   }
  //   return _value;
  // }

  convertFieldsToCamelize = data => {
    let camelizedData = {};
    Object.keys(data).map(key => camelizedData[String.prototype.camelize(key)] = data[key])
    return camelizedData;
  }

  validateIBAN = (rule, value, callback) => {
    if (value.length > 0 && (value.length < 26 || value.substr(0, 2) !== 'TR')) {
      callback(i18n.t('msg.invalidIBAN'));
    } else {
      callback();
    }
  };

  updateProp = (i, value) => {
    let data = _.cloneDeep(this.state.data);

    data.transactionDynamicProperties[i].Value = value ? value : '';
    this.setState({
      data
    });
  }

  onParentSave = (data) => {
    let { onSave } = this.props;
    data.amount = parseFloat(data.amount.toString().replace(',', '.'))
    this.props.form.validateFields(error => {
      if (!error) {
        onSave(data)
      }
    })
  }

  render() {
    let { data, loading, maxAmount, planCodes, accountCodes } = this.state;
    let { tenantBanks, voucherTypes, accessRights, split, notSplit, onClose, transactions } = this.props;
    let { getFieldDecorator } = this.props.form;

    let bank = tenantBanks.data ? tenantBanks.data.find(x => x.bankEftCode === data.bankEftCode) : [];
    let limits = { max: Math.sign(maxAmount) === -1 ? 0 : maxAmount, min: Math.sign(maxAmount) !== -1 ? 0 : maxAmount };
    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Form onSubmit={this.save} id="transaction">
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker label={i18n.t('lbl.transactionDate')} value={data.transactionDate} disabled className="#transactionDate" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('amount', {
                    rules: [
                      { required: true, message: i18n.t('msg.required') },
                      { validator: (rule, value, callback) => value === "0" ? callback(true) : callback(), message: i18n.t('msg.noAcceptZero') },
                      { validator: (rule, value, callback) => value[value.length - 1] === (".") || value[value.length - 1] === (",") ? callback(true) : callback(), message: i18n.t('msg.notEndDotOrComma') },
                      { validator: (rule, value, callback) => value === "-" || value === "+" ? callback(true) : callback(), message: i18n.t('msg.signErrorNull') },
                      { validator: (rule, value, callback) => parseFloat(value.toString().replace(',', '.')) > parseFloat(limits.max) ? callback(true) : callback(), message: i18n.t('msg.geMax') },
                      { validator: (rule, value, callback) => parseFloat(value.toString().replace(',', '.')) < parseFloat(limits.min) ? callback(true) : callback(), message: i18n.t('msg.leMin') },
                    ],
                    initialValue: data.amount
                  })(
                    <Input type="amount" label={i18n.t('lbl.amount')} disabled={!split || (split && notSplit)} onChange={(e) => this.update('amount', e)} className="#amount" />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.bank')} value={bank ? bank.name : ''} disabled className="#bank" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.typeOne')} value={data.typeCode1} disabled className="#typeOne" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.typeTwo')} value={data.typeCode2} disabled className="#typeTwo" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.accountName')} value={data.bankAccountName ? data.bankAccountName : data.bankAccount ? data.bankAccount.Name : ''} disabled className="#accountName" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    label={i18n.t('lbl.voucherType')}
                    options={voucherTypes.data}
                    value={data.voucherTypeId}
                    optVal="id"
                    optName="name"
                    onChange={(e) => this.update('voucherTypeId', e)}
                    className="#voucherType"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    label={i18n.t('lbl.currentAccount')}
                    showSearch
                    onSearch={this.accountCodeSearch}
                    className="#currentAccountCode"
                    options={accountCodes}
                    value={data.currentAccountCode}
                    optVal="Code"
                    optName={(e) => `${e ? e.Code + ' ' + e.Title || e.Name : ''}`}
                    onChange={(e) => this.update('currentAccountCode', e)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    label={i18n.t('lbl.accountPlanCode')}
                    showSearch
                    onSearch={this.planCodeSearch}
                    className="#accountPlanCode"
                    options={planCodes}
                    value={data.accountPlanCode}
                    optVal="Code"
                    optName={(e) => `${e && e.Code ? e.Code : ''} ${e ? e.Title || e.Name : ''}`}
                    onChange={(e) => this.update('accountPlanCode', e)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.tc')} type="stringNumber" value={data.tcknNumber} maxLength={11} onChange={(e) => this.update('tcknNumber', e)} className="#tcknNumber" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.taxNumber')} value={data.taxNumber} onChange={(e) => this.update('taxNumber', e)} className="#taxNumber" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('iban', {
                    rules: [{ validator: this.validateIBAN }],
                    initialValue: data.iban
                  })(
                    <Input label={i18n.t('lbl.iban')} type="iban" onChange={(e) => this.update('iban', e)} className="#iban" />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.erpCode')} value={data.erpCode} onChange={(e) => this.update('erpCode', e)} className="#erpCode" />
                </Form.Item>
              </Col>
              <Tooltip placement="top" maxLength={50} title={data.description1}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input disabled={!split || (split && notSplit)} label={i18n.t('lbl.description')} value={data.description1} onChange={(e) => this.update('description1', e)} className="#description1" />
                  </Form.Item>
                </Col>
              </Tooltip>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input label={i18n.t('lbl.bankAccountCode')} value={data.bankAccountCode} onChange={(e) => this.update('bankAccountCode', e)} className="#bankAccountCode" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled label={i18n.t('lbl.documentNumber')} value={data.documentId} className="#documentId" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled label={i18n.t('lbl.contactName')} value={data.contactName} className="#documentId" />
                </Form.Item>
              </Col>
              {
                data.transactionDynamicProperties.length > 0 &&
                data.transactionDynamicProperties.map((prop, i) =>
                  <Col key={(prop.Name || prop.name) + (prop.id || prop.Id)} xs={24} sm={12}>
                    <Form.Item
                      className={`${((prop.required || prop.Required) && (!(prop.value || prop.Value) || (prop.value || prop.Value).trim() === '')) ? 'has-error' : ''}`}
                      help={(prop.required || prop.Required) && (!(prop.value || prop.Value) || (prop.value || prop.Value).trim() === '') ? i18n.t('msg.required') : ''}
                    >
                      {
                        (prop.operationType || prop.PropertyConfig.OperationType) === 2 ?
                          <Select
                            options={(prop.pattern || prop.PropertyConfig.Pattern).split(';')}
                            optVal={null}
                            optName={null}
                            label={(prop.name || prop.Name)}
                            value={(prop.value || prop.Value)}
                            onChange={(e) => this.updateProp(i, e)}
                            className={`#prop${i}`}
                          />
                          :
                          <Input
                            label={(prop.name || prop.Name)}
                            value={(prop.value || prop.Value)}
                            maxLength={(prop.maxLength || prop.MaxLength)}
                            onChange={(e) => this.updateProp(i, e)}
                            className={`#prop${i}`}
                          />
                      }
                    </Form.Item>
                  </Col>
                )
              }
              {
                accessRights.find(x => x.endPoint === 'ApproveTransaction') &&
                data.transferStatus === 'WaitApproval' && !notSplit &&
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Checkbox checked={data.approvedTransfer} className="#approvedTransfer" onChange={(e) => this.update('approvedTransfer', e.target.checked)}>{i18n.t('lbl.approvedTransfer')}</Checkbox>
                  </Form.Item>
                </Col>
              }
            </Row>
            <Row gutter={20}>
              <Col span={24} className="text-right splitting-buttons">
                <Button className="error mr-10 #cancel btn-collapse" icon="close" onClick={onClose}>{i18n.t('btn.cancel')}</Button>
                <Button htmlType={"button"} onClick={() => this.onParentSave(data)} className="success #save btn-collapse" icon="save" loading={transactions.single.saving}>
                  {i18n.t('btn.save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  tenantBanks: common.filter.tenantBanks,
  transactions: netekstre.transactions,
  voucherTypes: netekstre.filter.voucherTypes,
  accessRights: auth.data.accessRights
});
const mapDispatchToProps = (dispatch) => ({
  getTransaction: (id, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.bankAccountTransactions, id, key: 'transactions' }, callback)),
  getTenantBanks: (filter) => dispatch(netekstreActions.getFilter({ filter, url: endpoints.lookups.tenantBanks, key: 'tenantBanks' })),
  getVoucherTypes: (filter) => dispatch(netekstreActions.getFilter({ filter, url: endpoints.lookups.voucherTypeCodes, key: 'voucherTypes' })),
  saveTransaction: (data, endPoint, callback) => dispatch(netekstreActions.put({ url: endPoint, data, key: 'transactions' }, callback)),
  getPlanCodes: (data, callback) => dispatch(commonActions.getPlanCodes(data, callback)),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
});
const TransactionForm = Form.create()(Transaction);
export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);