import React, { useState } from 'react';
import i18n from 'plugins/i18n';
import { Typography, FormElements, FadeIn, Button } from 'components/UIComponents';
import { Form, Row, Col, Modal, Statistic, Tooltip } from 'antd';
import { connect } from 'react-redux'
import actions from 'store/actions/auth';
import { withRouter } from 'react-router-dom';
import { isTest } from 'helpers';
import { v4 as uuid } from 'uuid';
import { projectTypes } from 'lib/constants';
import config from 'config';
import moment from 'moment';

const SignIn = ({ form, isLoggedIn, signIn, getAccount, history, verifyTwoFactor, resendOtp, loading: authLoading }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ username: null, password: null });
  const [loading, setLoading] = useState(false);
  const [twoFactorLoginEnabled, setTwoFactorLoginEnabled] = useState(false);
  const [otpTimedOut, setOtpTimedOut] = useState(false);
  const [timeoutTime, setTimeoutTime] = useState(0);

  const onSubmit = e => {
    e.preventDefault();

    form.validateFields(error => {
      if (!error && isLoggedIn === null) {
        setLoading(true);
        signIn({ ...form.getFieldsValue(), clientName: isTest() ? uuid.v4() : "web" }, response => {
          if (response) {
            if (response.token) getUserAccountInfo();
            else if (response.resetPasswordGuid) passwordExpiredRedirection(response)
            else twoFactorLogin(response)
          }
          else
            setLoading(false);
        });
      }
    });
  }

  const passwordExpiredRedirection = ({ resetPasswordGuid }) =>
    Modal.error({
      title: i18n.t('msg.PasswordExpired'),
      content: i18n.t('msg.pleaseClickContinueButton'),
      okText: i18n.t('btn.continue'),
      cancelText: i18n.t('btn.cancel'),
      onOk: () => {
        history.push(`/auth/reset-password?guid=${resetPasswordGuid}`);
        Modal.destroyAll();
      }
    })

  const onSubmitSecurityCode = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      if (!error) {
        setLoading(true);
        verifyTwoFactor({ ...form.getFieldsValue(), memberId: data.memberId }, response => {
          if (response)
            if (response.redirect) {
              setTwoFactorLoginEnabled(false)
              setLoading(false);
            }
            else
              getUserAccountInfo();
          else
            setLoading(false);
        });
      }
    });
  }

  const getUserAccountInfo = () =>
    getAccount((response) => {
      setTimeout(() => {
        setLoading(false);
        response && window.location.replace((response.level < 2 || config.projectType === projectTypes.settings) ? '/admin' : '/home')
      }, 1000);
    })

  const twoFactorLogin = response => {
    setLoading(false);
    setTwoFactorLoginEnabled(true);
    setTimeoutTime(moment().add(120, "seconds"));
    updateData((data) => ({ ...data, memberId: response.memberId }))
  }

  const onResendOtp = () =>
    resendOtp(data.memberId || '', () => {
      setTimeoutTime(moment().add(120, "seconds"));
      setOtpTimedOut(false)
    })

  return (
    <FadeIn>
      <Typography.H className="mb-0" size={1}>{i18n.t('lbl.managementPanel')}</Typography.H>
      <Typography.P size="big">{i18n.t('lbl.welcomeMessage')}</Typography.P>
      {
        twoFactorLoginEnabled ?
          <Form className="mt-80 block" onSubmit={onSubmitSecurityCode}>
            <Typography.Label> {i18n.t('lbl.pleaseFillSecurityCode')} </Typography.Label>

            <Form.Item className="mt-20">
              {
                getFieldDecorator('securityCode', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.securityCode
                })(
                  <FormElements.Input
                    type="password"
                    label={i18n.t('lbl.smsVerify')}
                    prefix="key"
                    suffix={
                      otpTimedOut
                        ? <Tooltip placement="right" title={i18n.t('btn.resend')}>
                          <i className={`fas ${authLoading ? 'fa-spinner fa-spin' : 'fa-redo-alt link-button'}`} onClick={onResendOtp} />
                        </Tooltip>
                        : <Statistic.Countdown value={timeoutTime} format="mm:ss" onFinish={() => setOtpTimedOut(true)} />
                    }
                    onChange={(e) => updateData((data) => ({ ...data, securityCode: e }))}
                  />
                )}
            </Form.Item>

            <Button htmlType="submit" block className="auth-button mt-20" customIcon="right" customLoading={loading}>
              {i18n.t('btn.signIn')}
            </Button>
          </Form>
          :
          <Form className="mt-80" onSubmit={onSubmit}>
            <Form.Item>
              {
                getFieldDecorator('username', {
                  rules: [
                    { required: true, message: i18n.t('msg.required') },
                    { type: 'email', message: i18n.t('msg.emailInvalid') }
                  ],
                  initialValue: data.username
                })(
                  <FormElements.Input
                    label={i18n.t('lbl.username')}
                    prefix="user"
                    onChange={(e) => updateData((data) => ({ ...data, username: e }))}
                  />
                )}
            </Form.Item>
            <Form.Item>
              {
                getFieldDecorator('password', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.password
                })(
                  <FormElements.Input
                    type="password"
                    label={i18n.t('lbl.password')}
                    prefix="key"
                    onChange={(e) => updateData((data) => ({ ...data, password: e }))}
                  />
                )}
            </Form.Item>
            <Row>
              <Col xs={12}>
                <FormElements.CheckBox
                  id="remember-me"
                  label={i18n.t('lbl.rememberMe')}
                />
              </Col>
              <Col xs={12} className="text-right">
                <Typography.Label size="small" className="pointer" onClick={() => history.push('/auth/forgot-password')}> {i18n.t('lbl.forgotPassword')} </Typography.Label>
              </Col>
            </Row>
            <Button htmlType="submit" block className="auth-button mt-30" customIcon="right" customLoading={loading}>
              {i18n.t('btn.signIn')}
            </Button>
            {/* <Button className="mt-20" ghost block onClick={() => window.location.replace('/home')}>{i18n.t('btn.continueWithoutLogin')}</Button> */}
          </Form>
      }
    </FadeIn>
  )
}
const mapStateToProps = ({ auth }) => ({ ...auth });
const mapDispatchToProps = (dispatch) => ({
  signIn: (payload, callback) => dispatch(actions.signIn(payload, callback)),
  verifyTwoFactor: (payload, callback) => dispatch(actions.verifyTwoFactor(payload, callback)),
  getAccount: (callback) => dispatch(actions.getAccount(callback)),
  resendOtp: (payload, callback) => dispatch(actions.resendOtp(payload, callback)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(SignIn)));

