import React, { useState, useEffect } from 'react';
import { AgreementViewer, FadeIn } from 'components/UIComponents';
import { Row, Col, Button } from 'antd';
import i18n from 'plugins/i18n';
import endpoints from 'config/endpoints';
import { connect } from 'react-redux';
import actions from 'store/redux/netekstre';
import { httpMethods } from 'lib/constants';
import _ from 'lodash';
import { ibanNormalizer } from 'helpers'

const ContractInfo = ({ onChange, getAgreement, parameters, saveTenant, patchUser }) => {
  const [agreement, setAgreement] = useState({ longTitle: "", longText: "", id: "" });
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    getAgreement('OKR3', (agreement) => setAgreement(agreement));
  }, [getAgreement]);

  const onSubmit = (agreement) => {
    let data = _.cloneDeep(parameters);
    if (data.agreements) data.agreements.push(agreement);
    data.iban = ibanNormalizer(data.iban);
    data.bankAccounts.push({ iban: data.iban });
    delete data.iban;

    const headers = { Authorization: `Bearer ${localStorage.getItem("ott")}` }


    saveTenant(data, headers, (response) => {

      setSaving(true);
      const { tenantId, errors } = response;

      if (tenantId && !(errors && errors.length > 0)) {

        const patchData = [{ op: "replace", path: "/tenantId", value: tenantId }];
        const userId = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).userId : null;

        patchUser(userId, patchData, headers, resp => {
          if (!(resp.errors && resp.errors.length > 0)) {
            onChange('+')
            setSaving(false);
          }
        })
      }
    })
  }


  return (
    <FadeIn>
      <AgreementViewer.Full
        {...{
          id: agreement.id,
          title: agreement.longTitle,
          text: agreement.longText,
          acceptText: i18n.t("lbl.defaultAgreementAcceptance"),
          onSubmit
        }} />
      <Row className="pl-30 pr-30 mobile-text-center" gutter={44}>
        <Col xs={24} md={12} className="text-right">
          <Button ghost type="default" htmlType="button" size="large" onClick={() => onChange('-')} >
            {i18n.t('btn.cancel')}
          </Button>
        </Col>
        <Col xs={24} md={12}>
          <Button form='contract' type="primary" htmlType="submit" size="large" loading={saving}>
            {i18n.t('btn.continue')}
          </Button>
        </Col>
      </Row>
    </FadeIn>
  )
}

const mapStateToProps = ({ global }) => ({
  cities: global.filter.cities,
});
const mapDispatchToProps = (dispatch) => ({
  getAgreement: (id, callback) => dispatch(actions.globalRequest({ url: endpoints.info.agreement, id, notify: false }, httpMethods.get, callback)),
  saveTenant: (data, headers, callback) => dispatch(actions.globalRequest({ url: endpoints.user.tenant, data, headers, notify: false }, httpMethods.post, callback)),
  patchUser: (id, data, headers, callback) => dispatch(actions.globalRequest({ url: endpoints.user.user, id, data, headers, notify: false }, httpMethods.patch, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractInfo);