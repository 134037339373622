import React, { Component } from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import i18n from 'plugins/i18n';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import SearchIcon from "assets/img/icons/search.svg";

class NBSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
        ? (props.mode === ('tags' || 'multiple'))
          ? props.value.map(x => x.toString())
          : props.value.toString()
        : undefined,
      isFocused: false,
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value)
      return {
        value: (props.value && props.options?.length > 0)
          ? props.mode === 'tags' || props.mode === 'multiple'
            ? props.value.map(x => x.toString())
            : props.value.toString()
          : undefined,
      }
    else
      return null;
  };

  handleChange = (e) => {
    const difference = _.concat(_.difference(e, this.state.value), _.difference(this.state.value, e));
    const value = !e ? e : typeof e !== 'object' ? e.toString() : e;
    const { onChange, mode, options, optVal } = this.props;

    this.setState({ value }, () => {
      if ((mode === 'tags' || mode === 'multiple')) {
        if (typeof options[0][optVal] === 'number')
          onChange && onChange(e.map(x => parseInt(x)), difference);
        else
          onChange && onChange(e, difference);
      }
      else {
        if (options[0] && typeof options[0][optVal] === 'number')
          onChange && onChange(e ? parseInt(e) : e, difference);
        else
          onChange && onChange(e, difference);
      }
    })
  };


  render() {
    const { value, isFocused } = this.state;
    const { label, className, id, disabled, required, optName, allowClear, optVal, options, showSearch, onSearch, onFocus, mode, lang, loading, maxTagCount, currentModule, searchFields, suffix, nextGen, onBlur, placeholder, forceFocus, manuelSort =  false } = this.props;
    const isSearchBox = !!(showSearch || onSearch);
    const hasValue = !!(value && value.toString() !== '')

    const _options = options?.length > 0
      ? (typeof optName === 'string' && optName !== '') && manuelSort === false
        ? _.cloneDeep(options).sort((a, b) => a[optName].localeCompare(b[optName])) 
        : options
      : [];

    const NextGenSearchIcon = () => <><div className="search-icon"><ReactSVG className="icon" src={SearchIcon} /></div></>;
    const NextGenChevronDown = () => <i className="chevron-down fas fa-chevron-down" />;

    return (
      <div className={`nb nb-select ${mode === 'tags' || mode === 'multiple' ? 'nb-tag' : ''}${nextGen ? 'nextGen' : ''} ${isSearchBox ? 'search-box' : ''}`}>
        <Select
          loading={loading}
          id={id}
          maxTagCount={maxTagCount || 1}
          value={value}
          showSearch={showSearch}
          onSearch={onSearch}
          onChange={this.handleChange}
          onFocus={() => this.setState({ isFocused: true }, () => onFocus && onFocus(true || hasValue))}
          onBlur={() => this.setState({ isFocused: false }, () => onBlur && onBlur(false || hasValue))}
          mode={mode || 'default'}
          className={`${(value !== null && value !== undefined && value !== '' && value.length) && 'is-filled'} ${className || ''}`}
          disabled={!!disabled}
          allowClear={allowClear === false ? false : true}
          required={!!required}
          suffixIcon={!loading && (suffix || (nextGen ? isSearchBox ? <NextGenSearchIcon /> : <NextGenChevronDown /> : undefined))}
          dropdownClassName={`${currentModule} ${nextGen ? 'nextGen' : ''}`}
          showArrow
          placeholder={placeholder}
          filterOption={(input, option) => {
            return (
              (option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
              ||
              (typeof option.props.children === "string" && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
              ||
              (option.props['data-search-fields'] && option.props['data-search-fields']?.map(i =>
                typeof i === "string"
                  ? i.toLowerCase().indexOf(input.toLowerCase())
                  : i?.map(j => j.toLowerCase().indexOf(input.toLowerCase()))
              ))
            )
          }}
        >
          {
            !loading &&
            _options.map((item, i) => (
              <Select.Option
                disabled={item.disabled}
                data-search-fields={searchFields && searchFields.map(x => item[x])}
                className={`#select${i + 1}`}
                key={Math.random()}
                value={
                  optVal
                    ? typeof optVal === 'string'
                      ? (mode === 'tags' || mode === 'multiple')
                        ? (item[optVal] !== null && item[optVal] !== undefined)
                          ? item[optVal].toString()
                          : ''
                        : item[optVal] ? item[optVal].toString() : ''
                      : optVal(item)
                    : item
                }
              >
                {
                  typeof optName === 'string'
                    ? lang
                      ? i18n.t(`${lang}.${item[optName]}`)
                      : item[optName]
                    : optName === null
                      ? item
                      : optName(item)
                }
              </Select.Option>
            ))}
        </Select>
        <label className={forceFocus || (!forceFocus && (isFocused || hasValue)) ? 'focused' : ''}>{label || ''}</label>
      </div>
    );
  };
};

const mapStateToProps = ({ common }) => ({ ...common });
export default connect(mapStateToProps, null, null, { forwardRef: true })(NBSelect);