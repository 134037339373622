import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import { DataTable, ActionButtons, Description, Filter, ToggleData, DropdownList } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import { defaultFilters, regexTypes, status } from 'lib/constants';
import i18n from 'plugins/i18n';
import utils from 'lib';
import Rule from './Detail'
import { Icon, Popover, Button, Tooltip } from 'antd'

const PageAccesses = {
  AdminGeneralRules: "AdminGeneralRules",
  GeneralRules: "GeneralRules"
}

class GeneralRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.netekstre.GeneralRules(),
      currentBank: null,
      ruleToggleStarted: false,
      access: PageAccesses.GeneralRules,
      inlineToggleDataLoading: false
    };
    this.searchTimeout = null;
  };

  componentDidMount() {
    const { getDataTrigger } = this.props;
    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getRules();
      access = PageAccesses.GeneralRules;
    }
    else {
      access = PageAccesses.AdminGeneralRules;
    }

    utils.common.getTenantBanks();
    utils.common.checkAccessToUpdateOrDelete('Rules')
    utils.netekstre.getTransferStatus()
    utils.netekstre.getVoucherTypeCodes();
    utils.netekstre.getCategories('Incoming', 1)
    utils.netekstre.getCategories('Outgoing', 2)
    utils.netekstre.getTypeCodes(1);
    utils.netekstre.getTypeCodes(2);
    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access });
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getRules = () => {
    const filter = _.cloneDeep(this.state.filter);
    this.props.getRules(filter);
  };

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getRules);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getRules);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getRules);
  }

  getTypeCodeName = (RuleInitializerItems, i) => {
    const ruleItem = RuleInitializerItems.find(({ RegexType }) => RegexType === regexTypes[`TypeCode${i}`]);
    if (ruleItem && ruleItem.RegexPattern) return ruleItem.RegexPattern;
    return '';
  }

  onSelect = (value) => {
    this.setState({ currentBank: value });
  };

  onCallback = (e, type) => {
    if (!e) this.setState({ [type]: [] });
  };

  renderRuleToggle = () => {
    return [
      <div onClick={() => this.toggleData.startOrCancelProcess()}>
        <Icon type="swap" className="mr-5" />
        {i18n.t('btn.enabledOrDisabled')}
      </div>
    ]
  }

  getBankName = ({ GeneralRuleItems }) => {
    const ruleItem = GeneralRuleItems.find(({ RegexType }) => RegexType === regexTypes.Bank);
    return utils.common.getBankName({ data: ruleItem, listKeys: 'tenantBanks', dataField: 'RegexPattern', listField: 'bankEftCode' })
  };

  customButtons = ({ selectAll }) => (
    <ToggleData
      ref={el => this.toggleData = el}
      selectAll={selectAll}
      parent={this}
      url={endpoints.nte.adminGeneralRulesDisableOrEnable}
      modalOptions={{
        title: i18n.t('lbl.ruleEnabledOrDisabled'),
        content: i18n.t('msg.ruleEnabledOrDisabledDescription')
      }}
    />
  );

  inlineToggleData = (e, { Id }) => {
    e.stopPropagation();
    this.setState({ inlineToggleDataLoading: true })
    this.props.singleToggleData([Id], () => {
      this.setState({ inlineToggleDataLoading: false }, () => {
        this.getRules();
      })
    })
  }

  renderActionButtons = row => {
    const { access, inlineToggleDataLoading } = this.state;
    return (
      <>
        <ActionButtons url={access} hasDelete editUrl item={row} getDatas={this.getRules} openDialog={this.datatable && this.datatable.openDialog} />
        <Popover overlayClassName="ant-popover-action" placement="right" trigger="hover" content={
          <>
            {
              row.Enabled ?
                <>
                  <Tooltip placement="bottom" title={i18n.t('btn.deactivate')}>
                    <Button loading={inlineToggleDataLoading} size="default" className="no-animation" icon="close" style={{ color: '#D46464' }} onClick={e => this.inlineToggleData(e, row)} />
                  </Tooltip>
                  <Tooltip placement="bottom" title={i18n.t('btn.reproductionCustomRule')}>
                    <Button size="default" className="no-animation" icon="copy" style={{ color: '#40a9ff' }} onClick={(e) => {
                      e.stopPropagation();
                      this.datatable.openDialog(false, row.Id, { reproductionRule: true, getUrl: endpoints.nte.generalRuleForCustomRule, saveUrl: endpoints.nte.rules });
                    }} />
                  </Tooltip>
                </>
                :
                <Tooltip placement="bottom" title={i18n.t('btn.activate')}>
                  <Button loading={inlineToggleDataLoading} size="default" className="no-animation" icon="check" style={{ color: '#7ac32a' }} onClick={e => this.inlineToggleData(e, row)} />
                </Tooltip>
            }

          </>
        }>
          <Button onClick={e => e.stopPropagation()} size="small" className="table-button"><Icon type="double-right" /></Button>
        </Popover >
      </>
    )
  }

  render() {
    const { currentBank, ruleToggleStarted, access } = this.state;
    const { generalRules, incomingCategories, outgoingCategories, transferStatus, type1, type2, tenantBanks, voucherTypes } = this.props;
    let _type1 = type1 && type1.data ? (currentBank && currentBank.length) ? type1.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type1.data : [];
    let _type2 = type2 && type2.data ? (currentBank && currentBank.length) ? type2.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type2.data : [];
    _type1 = _.uniqBy(_type1, 'code');
    _type2 = _.uniqBy(_type2, 'code');

    const filters = [
      { label: i18n.t('lbl.bank'), options: tenantBanks.data, type: 'select', value: 'bankEftCode', name: (bankData) => utils.netekstre.getBankName({ bankData }), keys: ['GeneralRuleItems'], any: 'RegexPattern', showAll: true, callback: this.onSelect, clear: ['2', '3'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.exampleDescription'), type: 'input', keys: ['name'], contains: true, uniqueKey: '#exampleDescription' },
      { label: i18n.t('lbl.typeOne'), options: _type1, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['GeneralRuleItems'], any: 'regexPattern', any2: ['regexType', regexTypes.TypeCode1], uniqueKey: '#typeOne', multiple: true },
      { label: i18n.t('lbl.typeTwo'), options: _type2, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['GeneralRuleItems'], any: 'regexPattern', any2: ['regexType', regexTypes.TypeCode2], uniqueKey: '#typeTwo', multiple: true },
      { label: i18n.t('lbl.voucherType'), type: 'select', options: voucherTypes.data, keys: ['GeneralRuleVoucherTypes'], any: 'VoucherTypeId', value: 'id', name: 'name', uniqueKey: '#voucherType', multiple: true, guid: true },
      { label: i18n.t('lbl.incomingCategory'), type: 'select', options: incomingCategories.data, keys: ['IncomingCategoryId'], value: 'Id', name: 'Name', uniqueKey: '#incomingCategory', multiple: true, guid: true },
      { label: i18n.t('lbl.outgoingCategory'), type: 'select', options: outgoingCategories.data, keys: ['OutgoingCategoryId'], value: 'Id', name: 'Name', uniqueKey: '#outgoingCategory', multiple: true, guid: true },
      { label: i18n.t('lbl.transferStatus'), options: transferStatus.data, type: 'select', value: 'id', name: 'name', lang: 'status', keys: ['TransferStatus'], showAll: true, toggle: false, uniqueKey: '#transferStatus', multiple: true },
      { label: i18n.t('lbl.status'), type: 'select', options: status, lang: 'lbl', value: 'value', name: 'name', keys: ['Enabled'], showAll: true, uniqueKey: '#isActive', boolean: true, defaultValue: 'true' },
      { label: i18n.t('lbl.point'), type: 'number', operator: 'eq', keys: ['Priority'], uniqueKey: '#point' },
    ];
    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id', checkField: false, },
      { label: i18n.t('lbl.exampleDescription'), key: 'Name', render: ({ Name }) => <Description text={Name} /> },
      { label: i18n.t('lbl.active'), key: 'Enabled', render: ({ Enabled }) => <Icon style={{ color: Enabled ? '#7ac32a' : '#D46464', fontSize: 16 }} type={Enabled ? 'check' : 'close'} /> },
      { label: i18n.t('lbl.bank'), key: 'Bank', render: this.getBankName, checkField: false, sort: false },
      { label: i18n.t('lbl.typeOne'), key: 'idOne', checkField: false, render: ({ GeneralRuleItems }) => this.getTypeCodeName(GeneralRuleItems, 1), sort: false },
      { label: i18n.t('lbl.typeTwo'), key: 'idTwo', checkField: false, render: ({ GeneralRuleItems }) => this.getTypeCodeName(GeneralRuleItems, 2), sort: false },
      { label: i18n.t('lbl.incomingCategory'), key: 'IncomingCategory/Name', checkField: false },
      { label: i18n.t('lbl.outgoingCategory'), key: 'OutgoingCategory/Name', checkField: false },
      { label: i18n.t('lbl.point'), key: 'Priority' },
      { label: i18n.t('lbl.voucherType'), key: 'GeneralRuleVoucherTypes/voucherType', sort: false, checkField: false, render: ({ GeneralRuleVoucherTypes }) => <DropdownList data={GeneralRuleVoucherTypes} field="VoucherTypeName" /> },
      { label: i18n.t('lbl.erpType'), key: 'GeneralRuleVoucherTypes/erpType', sort: false, checkField: false, render: ({ GeneralRuleVoucherTypes }) => <DropdownList data={GeneralRuleVoucherTypes} field="ErpTypeName" /> },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          onPageChange={this.onPageChange}
          access="Rules"
          onSort={this.onSort}
          columns={columns}
          data={generalRules.list.data}
          loading={generalRules.list.loading}
          title={i18n.t('route.netekstre.generalRules')}
          deleteOptions={{ stateKey: "generalRules", url: access }}
          count={generalRules.list.count}
          getData={this.getRules}
          Component={Rule}
          dialogTitle={i18n.t('lbl.generalRules')}
          checkbox={ruleToggleStarted}
          checkAll
          CustomButton={this.customButtons}
          additionalButtons={this.renderRuleToggle()}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  accessRights: auth.data.accessRights,

  currentFilter: common.currentFilter,
  tenantBanks: common.filter.tenantBanks,

  generalRules: netekstre.generalRules,
  incomingCategories: netekstre.filter.incomingCategories,
  outgoingCategories: netekstre.filter.outgoingCategories,
  voucherTypes: netekstre.filter.voucherTypes,
  transferStatus: netekstre.filter.transferStatus,
  type1: netekstre.filter.typeCodes1,
  type2: netekstre.filter.typeCodes2,
});
const mapDispatchToProps = (dispatch) => ({
  getRules: (filter) => dispatch(actions.getAll({ url: endpoints.nte.generalRules, key: 'generalRules', filter, isNewODataStructure: true })),
  singleToggleData: (data, callback) => dispatch(actions.post({ data, url: endpoints.nte.generalRulesDisableOrEnable }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeneralRules);