import psr from './psr.svg';
import dbs from './dbs.svg';
import nte from './nte.svg';
import nth from './nth.svg';


export default {
  psr,
  nte,
  dbs,
  nth
}