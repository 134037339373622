
import React, { forwardRef, useState } from 'react';
import { Input as AntInput } from "antd";
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';

const Textarea = forwardRef((props, ref) => {
  const [focus, setFocus] = useState(false);
  const _prefix = typeof props.prefix === "string" ? <ReactSVG src={icons[props.prefix]} /> : props.prefix;
  return (
    <div className={`nb nb-input ${_prefix ? 'with-prefix' : ''} custom-textarea__wrapper`}>
      <AntInput.TextArea
        {...props}
        ref={ref}
        autoSize={{ minRows: 4, maxRows: 10 }}
        onChange={(e) => props.onChange(e.target.value)}
        className={`${props.className || ''} custom-input`}
        onFocus={() => setFocus(!focus)}
        onBlur={() => setFocus(false)}
      />
      <label className={`${focus || props.value ? 'is-filled' : ''}`} htmlFor={props.id}>
        {props.label}
      </label>
      {_prefix}
    </div>
  )
})

export default Textarea;
