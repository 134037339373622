import React, { useState } from 'react';
import i18n from 'plugins/i18n';
import ReactSVG from 'react-svg';
import listItemIcon from 'assets/img/icons/listItem.svg';
import { Icon, Modal, Row, Col, Table, Skeleton } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import actions from 'store/actions/netahsilat';
import endpoints from 'config/endpoints';
import { v4 } from 'uuid';
import { formatMoney, removeTilde } from 'helpers';
import { currencySymbols } from 'lib/constants';
import circleCloseIcon from 'assets/img/icons/circleCloseIcon.svg';


class InlineInstallmentModel {
  constructor(data = null) {
    this.id = v4();
    this.installment = data?.installment;
    this.isActive = data?.isActive;
    this.totalAmount = this.isActive && (data.amount + data.amount * ((data?.processCommRate || 0) / 100));
    this.installmentAmount = this.isActive && (this.totalAmount / this.installment);
    this.processCommRate = data?.processCommRate !== undefined && '% ' + parseFloat(data.processCommRate).toFixed(2);
  }
}

const InstallmentTable = ({ amount, currencyId }) => {

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const installmentTable = useSelector(({ netahsilat }) => netahsilat.installmentTable.full.list);
  const installments = installmentTable.data.length > 0 ? installmentTable.data : new Array(8).fill({ commRates: [] })
  const currencies = useSelector(({ netahsilat }) => netahsilat.filter.currencies?.data);
  const currencySymbol = currencySymbols[currencies.find(x => x.id === currencyId)?.name];

  const columns = [
    { title: i18n.t('lbl.installment'), dataIndex: 'installment' },
    { title: i18n.t('lbl.installmentAmount'), dataIndex: 'installmentAmount', render: cell => cell && `${formatMoney(cell)} ${currencySymbol}` },
    { title: i18n.t('lbl.totalAmount'), dataIndex: 'totalAmount', render: cell => cell && `${formatMoney(cell)} ${currencySymbol}` },
    { title: i18n.t('lbl.commission'), dataIndex: 'processCommRate' },
  ]

  const SeeAllButton = () => (
    <div className="button-from-div" onClick={getInstallments}>
      {installmentTable.loading ? <Icon type="loading" style={{ padding: '6px 0' }} /> : <ReactSVG src={listItemIcon} />}
      {i18n.t('btn.seeAllInstalmentOptions')}
    </div>
  )

  const getInstallments = () => {
    setVisible(true);
    dispatch(actions.getAll({ url: endpoints.netahsilat.installmentTable, key: 'installmentTable.full' }))
  }

  const data = pos => {
    let installments = [];
    for (let index = 2; index < 13; index++) {
      const currentPos = pos.commRates.find(x => x.installment === index);
      installments.push(new InlineInstallmentModel({
        amount,
        installment: currentPos?.installment || index,
        isActive: !!currentPos?.installment,
        processCommRate: currentPos?.processCommRate,
      }))
    }
    return installments;
  }

  return (
    <div>
      <SeeAllButton />
      <Modal
        {...{
          title: i18n.t('lbl.installmentTable'),
          visible,
          onCancel: () => setVisible(false),
          footer: null,
          width: "70%",
          className: "installment-box__modal",
          closeIcon: <ReactSVG src={circleCloseIcon} style={{ marginRight: 12, marginTop: 9 }} />
        }}
      >
        <Row gutter={[22, 22]}>
          {
            installments.map((pos, i) => {
              return (
                <Col xs={24} sm={6} key={i}>
                  <Skeleton paragraph={false} active loading={!!installmentTable.loading} className="installment-table__skeleton">
                    <Table {...{
                      className: 'nextGen installment-table',
                      bordered: true,
                      size: 'small',
                      columns,
                      rowKey: 'id',
                      dataSource: data(pos),
                      pagination: false,
                      rowClassName: row => row.isActive ? '' : 'disabled',
                      title: () => <div style={{ borderBottomColor: pos.logo?.installmentCellColor }}>{<img src={removeTilde(pos.logo?.logoSmall)} alt="bank-logo" />}</div>
                    }} />
                  </Skeleton>
                </Col>
              )
            })
          }
        </Row>
      </Modal>
    </div>
  )
}
export default InstallmentTable;