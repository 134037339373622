import React from 'react'
import { Layout, Button, Icon } from "antd";
import { withRouter } from "react-router-dom";
import ReactSVG from "react-svg";
import Logo from "assets/img/logo_white.svg";
import { modules } from "temp";
import { moduleColors } from "helpers";
import { Flip } from 'react-reveal';


const MobileHeader = ({ location, visible, setVisible }) => {
  const currentModule = location.pathname.substr(1).split('/')[0];
  const moduleName = modules.find(x => x.path === currentModule) ? modules.find(x => x.path === currentModule).name : '';

  return (
    <Layout.Header className="header-modules mobile-header mobile-only">
      <div>
        <Button onClick={() => setVisible(!visible)}>
          <Icon type="menu" />
        </Button>
        <div className="module-menu__header">
          <ReactSVG className="fadeInComponent" src={Logo} />
          <h2 className="flip-2-hor-bottom-2" style={{ color: moduleColors[currentModule] }}>
            <Flip left cascade>
              {moduleName}
            </Flip>
          </h2>
        </div>
      </div>
    </Layout.Header>
  )
}

export default withRouter(MobileHeader);