import React, { Component } from 'react';
import { connect } from 'react-redux';
import posraporActions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import defaultFilters from 'lib/constants/defaultFilters';
import { DataTable, Filter, FadeIn, CheckAccessRight } from "components/UIComponents";
import { formatDate, cardHarmony, posKinds, getInstallmentCount, transactionTypes, formatCurrency, businessCardTypes } from 'helpers';
import moment from "moment";
import i18n from 'plugins/i18n';
import utils from 'lib';
import _ from 'lodash';

class ReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.posrapor.ReportDetail(),
    };
  };

  componentDidMount() {
    this.getPosraporReport();
    utils.posrapor.getBanks();
    utils.posrapor.getCurrencies();
    utils.posrapor.getCreditCardTypes();
    utils.posrapor.getCreditCardPrograms();
    utils.posrapor.getCreditCardOrganizationTypes();
    utils.posrapor.getCreditCardBanks();
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getPosraporReport();
    });
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getPosraporReport);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getPosraporReport);
  }

  getPosraporReport = () => {
    let _filter = this.props.currentFilter['posrapor/reporting/report-detail'];
    let filter = _.cloneDeep(this.state.filter);

    if (!(_filter && _filter.fields && _filter.fields[0] && _filter.fields[0].length)) {
      const oneWeekBefore = moment().add(-14, 'day').startOf('day');
      this.filterRef.filterChange(oneWeekBefore.format('YYYY-MM-DDT00:00:00Z'), 0);
      filter.filter.and
        ? filter.filter.and.push({ TransactionDate: { ge: oneWeekBefore._d } })
        : filter.filter.and = [{ TransactionDate: { ge: oneWeekBefore._d } }]
    }

    this.props.getPosraporReport(filter);
  };

  getCreditCardTypeName = (row) => this.props.creditCardTypes.data.find(x => x.id === row.CreditCardTypeId)?.name || i18n.t('lbl.unknown');

  getCreditCardOrganizationTypesName = (row) => this.props.creditCardOrganizationTypes.data.find(x => x.id === row.CreditCardOrganizationTypeId)?.name || i18n.t('lbl.unknown');

  getCreditCardProgramsName = (row) => this.props.creditCardPrograms.data.find(x => x.id === row.CreditCardProgramId)?.name || i18n.t('lbl.unknown');

  getCreditCardBanksName = (row) => this.props.creditCardBanks.data.find(x => x.id === row.CreditCardBankId)?.name || i18n.t('lbl.unknown');

  getTransactionTypesName = (row) => i18n.t(`lbl.${transactionTypes.find(x => x.id === row.TransactionType)?.name || 'unknown'}`);

  getPosTypesName = (row) => i18n.t(`lbl.${posKinds.find(x => x.id === row.PosType)?.name || 'unknown'}`);

  getIsOnUsNotOnUs = (row) => i18n.t(`lbl.${cardHarmony.find(x => x.id === row.OnUs)?.name || 'unknown'}`);

  getBusinessCardTypesName = (row) => i18n.t(`lbl.${businessCardTypes.find(x => x.value === row.IsBusinessCardId)?.name || 'unknown'}`);

  render() {
    let { tenantBanks, reportDetail, creditCardOrganizationTypes, creditCardPrograms, creditCardBanks, creditCardTypes, currencies } = this.props;
    const { filter } = this.state;

    const filters = [
      { label: i18n.t('posrapor.transactionDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['TransactionDate'], uniqueKey: '#TransactionDateStart', allowClear: false },
      { label: i18n.t('posrapor.transactionDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['TransactionDate'], uniqueKey: '#TransactionDateEnd' },
      { label: i18n.t('posrapor.posBank'), type: 'select', options: tenantBanks.data, value: 'eftCode', name: (bankData) => utils.posrapor.getBankName({ bankData }), keys: ['Pos/PosRaporAccount/Bank/EftCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.cardBanks'), type: 'select', options: creditCardBanks.data, value: 'id', name: 'name', keys: ['CreditCardBankId'], showAll: true, multiple: true },
      { label: i18n.t('posrapor.isOnUs'), type: 'select', options: cardHarmony, value: 'id', lang: 'lbl', name: 'name', keys: ['onUs'], showAll: true, uniqueKey: '#cardHarmony', multiple: true },
      { label: i18n.t('posrapor.posKind'), type: 'select', options: posKinds, value: 'id', name: 'name', lang: 'posrapor', keys: ['Pos/PosType'], showAll: true, uniqueKey: '#posKind', multiple: true },
      { label: i18n.t('posrapor.paymentStatus'), type: 'select', options: transactionTypes, value: 'id', name: 'name', lang: 'posrapor', keys: ['TransactionType'], showAll: true, uniqueKey: '#posKind', multiple: true },
      { label: i18n.t('lbl.businessCard'), type: 'select', options: businessCardTypes, lang: 'lbl', value: 'value', name: 'name', keys: ['BankDailyReportLine/IsBusinessCardId'], showAll: true, uniqueKey: '#IsBusinessCardId', multiple: true },
      { label: i18n.t('lbl.tenantInstallmentCount'), type: 'select', options: getInstallmentCount(24), manuelSort: true, value: 'id', name: 'name', keys: ['TenantInstallmentCount'], showAll: true, uniqueKey: '#installmentCount', multiple: true },
      { label: i18n.t('posrapor.cardKind'), type: 'select', options: creditCardOrganizationTypes.data, value: 'id', name: 'name', keys: ['CreditCardOrganizationTypeId'], multiple: true },
      { label: i18n.t('posrapor.cardProgram'), type: 'select', options: creditCardPrograms.data, value: 'id', name: 'name', keys: ['CreditCardProgramId'], multiple: true },
      { label: i18n.t('posrapor.cardType'), type: 'select', options: creditCardTypes.data, value: 'id', name: 'name', keys: ['CreditCardTypeId'], multiple: true },
      { label: i18n.t('lbl.commitDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['CommitDate'], uniqueKey: '#CommitDate' },
      { label: i18n.t('lbl.commitDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['CommitDate'], uniqueKey: '#CommitDate' },
      { label: i18n.t('posrapor.netAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['NetAmount'], uniqueKey: '#NetAmountMin' },
      { label: i18n.t('posrapor.netAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['NetAmount'], uniqueKey: '#NetAmountMax' },
      { label: i18n.t('posrapor.PureAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['PureAmount'], uniqueKey: '#PureAmountMin' },
      { label: i18n.t('posrapor.PureAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['PureAmount'], uniqueKey: '#PureAmountMax' },
      { label: i18n.t('posrapor.commisionAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['CommisionAmount'], uniqueKey: '#CommissionAmountMin' },
      { label: i18n.t('posrapor.commisionAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['CommisionAmount'], uniqueKey: '#CommissionAmountMax' },
      { label: i18n.t('lbl.currencyCode'), keys: ['Pos/Currencies/Code'], type: 'select', options: currencies.data, name: "currencyName", value: "currencyCode", multiple: true },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Pos/MerchantId', 'Pos/TerminalId', 'TransactionId', 'ProvisionCode', 'Pos/CompanyName', 'TransactionId', 'ProvisionCode', 'Pos/TerminalId', 'Pos/MerchantId', 'AuthCode'], contains: true, uniqueKey: '#search' },
    ];
    let columns = [
      { label: i18n.t('posrapor.merchantName'), key: 'Pos/CompanyName', checkField: false },
      { label: i18n.t('posrapor.merchant'), key: 'Pos/MerchantId', checkField: false },
      { label: i18n.t('posrapor.terminalNo'), key: 'Pos/TerminalId', checkField: false },
      { label: i18n.t('posrapor.transactionNo'), key: 'TransactionId' },
      { label: i18n.t('posrapor.provisionCode'), key: 'ProvisionCode' },
      { label: i18n.t('lbl.authCode'), key: 'AuthCode' },
      { label: i18n.t('posrapor.posBank'), key: 'Pos/PosRaporAccount/Bank/Name', render: (data) => utils.posrapor.getBankName({ data, dataField: 'Pos.PosRaporAccount.Bank.EftCode' }), checkField: false },
      { label: i18n.t('posrapor.cardBank'), key: 'CreditCardBankId', render: this.getCreditCardBanksName },
      { label: i18n.t('posrapor.posKind'), key: 'Pos/PosType' },
      { label: i18n.t('posrapor.transactionDate'), key: 'TransactionDate', render: ({ TransactionDate }) => formatDate(TransactionDate, null, null, null, false), checkField: false },
      { label: i18n.t('posrapor.transactionTime'), key: 'TransactionTime', render: ({ TransactionDate }) => moment(TransactionDate).format('HH:mm'), checkField: false, sort: false },
      { label: i18n.t('lbl.commitDate'), key: 'CommitDate', render: ({ CommitDate }) => formatDate(CommitDate, null, null, null, false) },
      { label: i18n.t('posrapor.isOnUs'), key: 'OnUs', render: this.getIsOnUsNotOnUs },
      { label: i18n.t('posrapor.posKind'), key: 'Pos/PosType', render: this.getPosTypesName, checkField: false },
      { label: i18n.t('lbl.businessCard'), key: 'IsBusinessCardId', render: this.getBusinessCardTypesName },
      { label: i18n.t('posrapor.cardType'), key: 'CreditCardTypeId', render: this.getCreditCardTypeName },
      { label: i18n.t('posrapor.cardKind'), key: 'CreditCardOrganizationTypeId', render: this.getCreditCardOrganizationTypesName },
      { label: i18n.t('posrapor.cardProgram'), key: 'CreditCardProgramId', render: this.getCreditCardProgramsName },
      { label: i18n.t('posrapor.paymentStatus'), key: 'TransactionType', render: this.getTransactionTypesName },
      { label: i18n.t('posrapor.netAmount'), render: ({ NetAmount, Pos }) => formatCurrency({ data: NetAmount, currencyCode: Pos.Currencies.Code, withSign: false }), key: 'NetAmount' },
      { label: i18n.t('posrapor.PureAmount'), render: ({ PureAmount, Pos }) => formatCurrency({ data: PureAmount, currencyCode: Pos.Currencies.Code, withSign: false }), key: 'PureAmount' },
      { label: i18n.t('posrapor.commisionAmount'), render: ({ CommisionAmount, Pos }) => formatCurrency({ data: CommisionAmount, currencyCode: Pos.Currencies.Code, withSign: false }), key: 'CommisionAmount' },
      { label: i18n.t('lbl.tenantInstallmentCount'), key: 'TenantInstallmentCount' },
    ];
    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...reportDetail, single: reportDetail.list } }}>
            <Filter filters={filters} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              access="BankDailyReportLine"
              columns={columns}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              loading={reportDetail.list.loading}
              data={reportDetail.list.data}
              count={reportDetail.list.count}
              title={i18n.t('route.posrapor.reporting')}
              dialogTitle={i18n.t('lbl.reporting')}
              excel={{ url: "ExportBankDailyReportLineDetailReport", filter: { filter: filter.filter, expand: filter.expand, orderBy: filter.orderBy }, module: "posrapor" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor, common }) => ({
  reportDetail: posrapor.reportDetail,
  tenantBanks: posrapor.filter.banks,
  creditCardOrganizationTypes: posrapor.filter.creditCardOrganizationTypes,
  creditCardPrograms: posrapor.filter.creditCardPrograms,
  creditCardBanks: posrapor.filter.creditCardBanks,
  creditCardTypes: posrapor.filter.creditCardTypes,
  currencies: posrapor.filter.currencies,
  currentFilter: common.currentFilter
});
const mapDispatchToProps = (dispatch) => ({
  getPosraporReport: (filter, parameters) => dispatch(posraporActions.getAll({ filter, url: endpoints.posrapor.reportLine, key: 'reportDetail', parameters })),
  getBanks: (filter, parameters) => dispatch(posraporActions.getAll({ filter, url: endpoints.posrapor.banks, key: 'banks', parameters })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);