const stateKeys = [
  "poses",
  "commRates",
  "payments",
  "paymentsCalc",
  "paymentOperations",
  "netlinkTransactions",
  "netlinkTransactionsCalc",
  "paymentParameters",
  "pay",
  "defaultPosCommRates",
  { payment: ["get", "post", "prepare"] },
  { installmentTable: ["byFilter", "full"] },
]

const filterKeys = [
  "banks",
  "threeDSecureTypes",
  "nthBanks",
  "currencies",
  "poses",
  "posParameters",
  "posLogos",
  "transactionStatuses",
  "transactionTypes",
  "netlinkTransactionStatuses",
  "netlinkTypes",
  "languageTypes",
  "frequencyTypes",
  "paymentSets",
  "paymentTabs",
  "threeDStatuses"
]

//#region State Generating - SHOULD NOT BE CHANGED 
const getConstantStateData = () => ({
  list: { data: [], loading: null, count: null, error: null, statusCode: null },
  single: { data: null, saving: null, loading: null, error: null, statusCode: null }
})

const state = { filter: {} }
stateKeys.forEach(key => {
  if (typeof key === "string")
    state[key] = getConstantStateData();
  else {
    const [mainKey] = Object.keys(key);
    state[mainKey] = getConstantStateData();
    key[mainKey].forEach(innerKey => {
      state[mainKey] = {
        ...state[mainKey],
        [innerKey]: getConstantStateData()
      }
    })
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], enum: null, error: null, loading: null
  }
})
export default state;
//#endregion
