export class UserSignatoryModel {
  constructor(data = {}) {
    this.name = data.name || '';
    this.surname = data.surname || '';
    this.phone = data.phone || '';
    this.tckn = data.tckn || '';
    this.email = data.email || '';
    this.birthDate = data.birthDate || null;
    this.birthDate = data.birthDate || null;
    this.tenantId = data.tenantId || undefined;
    this.agreements = data.agreements || [];
  }
};

export class KrediTenantModel {
  constructor(data = {}) {
    this.name = data.name || '';
    this.taxNumber = data.taxNumber || '';
    this.taxOffice = data.taxOffice || '';
    this.cityId = data.cityId || '';
    this.districtId = data.districtId || '';
    this.neighborhoodId = data.neighborhoodId || '';
    this.address = data.address || '';
    this.bankAccounts = data.bankAccounts || [];
    this.agreements = data.agreements || [];
  }
};

export class SMSVerificationModel {
  constructor(data = {}) {
    this.otp = data.otp || '';
    this.memberId = data.memberId || '';
  }
};

export class SendUrlForTransactionComplete {
  constructor(data = {}) {
    this.pureAmount = data.pureAmount || null;
    this.memberId = data.memberId || '';
  }
};