import React, { useEffect } from 'react'
import { FadeIn, Typography } from 'components/UIComponents';
import ReactSVG from 'react-svg';
import { Row, Col } from 'antd';
import ResultSVG from 'assets/img/result.svg';
import i18n from 'plugins/i18n';
import { connect } from 'react-redux';
import actions from 'store/actions/auth';

const Result = ({ parameters, signIn, getAccount }) => {
  useEffect(() => {
    setTimeout(() => {
      const data = {
        username: localStorage.getItem("info") ? JSON.parse(localStorage.getItem("info")).username : null,
        password: parameters || null
      }
      signIn({ ...data, clientName: "web" }, (response) => {
        if (!response.errors) {
          getAccount((response) => {
            setTimeout(() => {
              localStorage.removeItem("user");
              localStorage.removeItem("info");
              // response && window.location.reload();
            }, 7000);
          })
        }
      });
    }, 2000);
  }, [signIn, getAccount, parameters])

  return (
    <FadeIn>
      <Row className="text-center p-30">
        <Col xs={24} md={24}>
          <ReactSVG src={ResultSVG} />
        </Col>

        <Col xs={24} md={24} className="mt-35 mb-5">
          <Typography.H type="green">
            {i18n.t('lbl.registrationSuccessfully')}
          </Typography.H>
        </Col>

        <Col xs={24} md={24}>
          <Typography.P>
            {i18n.t('lbl.mailSuccessfullySentEmail')}
          </Typography.P>
          <Typography.P className="mt-10">
            {i18n.t('lbl.youCanEnteredSystemYourInformation')}
          </Typography.P>
        </Col>
      </Row>
    </FadeIn>
  )
}
const mapDispatchToProps = (dispatch) => ({
  signIn: (payload, callback) => dispatch(actions.signIn(payload, callback)),
  getAccount: (callback) => dispatch(actions.getAccount(callback)),
});
export default connect(null, mapDispatchToProps)(Result);
