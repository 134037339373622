import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/common';
import reducers from 'store/reducers/common';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllCommon: ['payload', 'callback', 'isNewODataStructure'],
  getAllCommonSuccess: ['payload', 'isNewODataStructure'],
  getAllCommonFailure: ['payload'],
  getCommon: ['payload', 'callback'],
  getCommonSuccess: ['payload', 'isNewODataStructure'],
  getCommonFailure: ['payload'],
  postCommon: ['payload', 'callback'],
  postCommonSuccess: ['payload'],
  postCommonFailure: ['payload'],
  putCommon: ['payload', 'callback'],
  putCommonSuccess: ['payload', 'callback'],
  putCommonFailure: ['payload', 'callback'],
  deleteCommon: ['payload', 'callback'],
  deleteCommonSuccess: ['payload'],
  deleteCommonFailure: ['payload'],
  patchCommon: ['payload', 'callback'],
  patchCommonSuccess: ['payload'],
  patchCommonFailure: ['payload'],
  updateFilter: ['payload'],
  clearStateCommon: ['key'],
  setSelectedItems: ['payload', 'callback'],
  changeLanguage: ['payload', 'callback'],
  setModule: ['payload'],
  seenVersion: ['payload', 'callback'],
  changeTenant: ['payload', 'callback'],
  getAccountCodes: ['payload', 'callback'],
  getPlanCodes: ['payload', 'callback'],
  excelExport: ['payload'],
  getVersion: ['callback'],
  uploadImg: ['payload', 'callback'],
  changePassword: ['payload'],
  toggleData: ['payload'],
  getFilterCommon: ['payload', 'isNewODataStructure'],
  getFilterCommonSuccess: ['payload', 'isNewODataStructure'],
  getFilterCommonFailure: ['payload'],
  getWithPostCommon: ['payload'],
  getWithPostCommonSuccess: ['payload'],
  getWithPostCommonFailure: ['payload'],
  clearExcelFormat: [],
  setDatatableInlineValue: ['payload'],
  clearDatatableInlineValue: [],
  setDatatableBulkValue: ['payload', 'index'],
  clearDatatableBulkValues: [],
  listenThreeDProcess: ['payload', 'callback'],
  clearFilterCommon: ['key'],
});
export const CommonTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });

/* ------------- REDUCERS -------------  */
export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};



export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};


export const getWithPost = (state, action) => {
  const data = reducers.getWithPost(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};


export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}

export const clearExcelFormat = (state) => {
  const data = reducers.clearExcelFormat(state);
  return state.merge(data)
}

export const setSelectedItems = (state, action) => {
  const data = reducers.setSelectedItems(state, action);
  return state.merge(data)
}

export const updateFilter = (state, action) => {
  const data = reducers.updateFilter(state, action);
  return state.merge(data);
};

export const seenVersion = (state) => {
  const data = reducers.seenVersion(state);
  return state.merge(data);
};

export const setModule = (state, { callback, payload }) => {
  callback && callback()
  return state.merge({ currentModule: payload })
}

export const changeLanguage = (state, { callback, payload }) => {
  callback && callback()
  return state.merge({ i18nextLng: payload })
}


export const getFilter = (state, action) => {
  const data = reducers.getFilter(state, action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state, action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state, action)
  return state.merge(data);
};

export const toggleData = (state, { payload }) => {
  return state.merge({ [payload.key]: payload.data });
};

export const setDatatableInlineValue = (state, action) => {
  const data = reducers.setDatatableInlineValue(state, action);
  return state.merge(data);
}

export const clearDatatableInlineValue = (state) => {
  const data = reducers.clearDatatableInlineValue(state);
  return state.merge(data);
}

export const setDatatableBulkValue = (state, action) => {
  const data = reducers.setDatatableBulkValue(state, action);
  return state.merge(data);
}

export const clearDatatableBulkValues = (state) => {
  const data = reducers.clearDatatableBulkValues(state);
  return state.merge(data);
}

export const clearFilter = (state, action) => {
  const data = reducers.clearFilter(state, action)
  return state.merge(data)
}

/* ------------- Hookup Reducers To Types -------------  */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_COMMON]: getAll,
  [Types.GET_ALL_COMMON_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_COMMON_FAILURE]: getAllFailure,
  [Types.GET_COMMON]: get,
  [Types.GET_COMMON_SUCCESS]: getSuccess,
  [Types.GET_COMMON_FAILURE]: getFailure,
  [Types.POST_COMMON]: post,
  [Types.POST_COMMON_SUCCESS]: postSuccess,
  [Types.POST_COMMON_FAILURE]: postFailure,
  [Types.PUT_COMMON]: put,
  [Types.PUT_COMMON_SUCCESS]: putSuccess,
  [Types.PUT_COMMON_FAILURE]: putFailure,
  [Types.DELETE_COMMON]: _delete,
  [Types.DELETE_COMMON_SUCCESS]: deleteSuccess,
  [Types.DELETE_COMMON_FAILURE]: deleteFailure,
  [Types.PATCH_COMMON]: patch,
  [Types.PATCH_COMMON_SUCCESS]: patchSuccess,
  [Types.PATCH_COMMON_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_COMMON]: clearState,
  [Types.CLEAR_EXCEL_FORMAT]: clearExcelFormat,
  [Types.SET_SELECTED_ITEMS]: setSelectedItems,
  [Types.UPDATE_FILTER]: updateFilter,
  [Types.CHANGE_LANGUAGE]: changeLanguage,
  [Types.SET_MODULE]: setModule,
  [Types.SEEN_VERSION]: seenVersion,
  [Types.GET_ACCOUNT_CODES]: get,
  [Types.GET_PLAN_CODES]: get,
  [Types.TOGGLE_DATA]: toggleData,
  [Types.GET_FILTER_COMMON]: getFilter,
  [Types.GET_FILTER_COMMON_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_COMMON_FAILURE]: getFilterFailure,
  [Types.GET_WITH_POST_COMMON]: getWithPost,
  [Types.GET_WITH_POST_COMMON_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_COMMON_FAILURE]: getWithPostFailure,
  [Types.SET_DATATABLE_INLINE_VALUE]: setDatatableInlineValue,
  [Types.CLEAR_DATATABLE_INLINE_VALUE]: clearDatatableInlineValue,
  [Types.SET_DATATABLE_BULK_VALUE]: setDatatableBulkValue,
  [Types.CLEAR_DATATABLE_BULK_VALUES]: clearDatatableBulkValues,
  [Types.CLEAR_FILTER_COMMON]: clearFilter,
});