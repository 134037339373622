import React from 'react';
import { Button, Dropdown, Menu, Icon, Tag } from 'antd';
import { formatIBAN } from 'helpers';

const DropdownList = ({ data, field, tag, value }) => {
  return (
    <div>
      {data.length > 0 ?
        tag ?
          data.map((item, i) => <Tag className="table-tag" key={i} color="blue">{typeof field === 'string' ? field === 'iban' ? formatIBAN(item[field]) : item[field] : field(item)}</Tag>)
          :
          data.length > 1 ?
            <div>
              <Dropdown overlay={
                <Menu>
                  {data.map((x, i) => (
                    <Menu.Item key={i} disabled>{typeof field === 'string' ? field === 'iban' ? formatIBAN(x[field]) : x[field] : field(x)}</Menu.Item>
                  ))}
                </Menu>
              }>
                <Button size="small" className="full-width pl-10 pr-0" onClick={e => e.stopPropagation()}>
                  {typeof field === 'string' ? field === 'iban' ? formatIBAN(data[0][field]) : value ? value : data[0][field] : field(data[0])} <Icon type="down" />
                </Button>
              </Dropdown>
            </div>
            :
            typeof field === 'string' ? field === 'iban' ? formatIBAN(data[0][field]) : data[0][field] : field(data[0])
        :
        ''
      }
    </div>
  )
}

export default DropdownList;



