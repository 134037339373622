export default {
  path: '/tos',
  name: 'tos',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/payment/:id?',
      name: 'order',
      visible: false,
      component: require('components/Modules/TOS/PaymentLists/Detail').default,
    },
    {
      path: '/parameters',
      name: 'parameters',
      component: require('components/Modules/TOS/Parameters/List').default,
    },
    {
      path: '/recipient-accounts',
      name: 'recipientAccounts',
      component: require('components/Modules/TOS/RecipientAccounts/List').default,
    },
    {
      path: '/create-payments',
      name: 'createPayments',
      component: require('components/Modules/TOS/CreatePayments/List').default,
    },

    {
      path: '/payment-lists',
      name: 'paymentLists',
      component: require('components/Modules/TOS/PaymentLists/List').default,
    },
    {
      path: '/reporting',
      name: 'reporting',
      component: require('components/Modules/TOS/Reporting/List').default,
    },

  ]
};