import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import adminRoutes from 'plugins/routes/admin';

class Settings extends Component {
  render() {
    const settings = adminRoutes.routes.find(x => x.name === 'settings');
    let { accessRights } = this.props;
    return (
      <React.Fragment>
        <Switch>
          {settings.routes.map((route, i) => {
            if ((!route.access) ||
              (route.access && typeof route.access === 'string' && accessRights.length > 0 && accessRights.find(x => x.endPoint === route.access)) ||
              (route.access && Array.isArray(route.access) && accessRights.length > 0 && accessRights.some(x => route.access.includes(x.endPoint)))
            )
              return <Route path={`${adminRoutes.path}${settings.path}${route.path}`} component={route.component} key={`rd-${i}`} />;
            else
              return null
          })} 
          <Redirect from="/admin" to="/netekstre/dashboard" />
        </Switch>
      </React.Fragment>
    );
  };
};

export default Settings;