import React from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { useDispatch } from "react-redux";
import actions from "store/actions/common";

const ModuleItem = ({ location, path, code, hasModule }) => {
  const currentModule = location.pathname.substr(1).split('/')[0];
  const dispatch = useDispatch();

  const switchMode = (moduleName) => {
    dispatch(actions.setModule(moduleName));
  };

  return (
    <div className={`module-item ${path} ${currentModule === path ? 'is-active' : ''} ${hasModule ? '' : 'disabled'} `}>
      {
        <NavLink onClick={() => switchMode(path)} to={hasModule ? `/${path}` : '#'}>{code}</NavLink>
      }
    </div>
  )
}

export default withRouter(ModuleItem);