import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Tooltip, Modal } from 'antd';
import actions from 'store/actions/dbs';
import { formatCurrency, formatDate } from 'helpers';
import { CreateOrderModel, SendOrderModel } from 'models';
import { ActionButtons, DataTable, Instruction, CheckAccessRight, FadeIn } from 'components/UIComponents';
import { defaultFilters, baseUrls } from "lib/constants";
import endpoints from 'config/endpoints';
import _ from 'lodash';
import utils from 'lib'
import i18n from 'plugins/i18n';
import CreatingInstruction from './Detail';

const persistentFilter = { OperationStatusId: { eq: 1 } }

class CreatingInstructions extends Component {
  state = {
    filter: defaultFilters.dbs.CreatingInstructions(),
    totalAmount: null,
    totalCount: null,
    totalsLoading: false,
    orderSendingOrCreating: false
  };

  componentDidMount() {
    utils.dbs.getTenantCurrencies();
    utils.dbs.getTenantBanks('DbsAccounts');
    this.getCreatingInstructions();
    this.getInvoiceTotal();
    utils.common.checkAccessToUpdateOrDelete('DbsInvoice');
  };


  getCreatingInstructions = callback => {
    let { filter } = this.state;
    this.setState({ totalsLoading: true })
    this.props.getCreatingInstructions(filter, callback);
  };

  onBankChange = (bankId, key) => {
    let { filter } = this.state;
    this.onFilter(bankId ? { [key]: { eq: bankId } } : delete filter.filter[key]);
    this.getInvoiceTotalForAllData();
  }

  onCurrencyChange = (currencyCode, key) => {
    let { filter } = this.state;
    this.onFilter(currencyCode ? { [key]: { eq: currencyCode } } : delete filter.filter[key]);
    this.getInvoiceTotalForAllData();
  }

  getInvoiceTotal = response => {
    if (this.state.filter.filter && !_.isEqual(this.state.filter.filter, {})) {
      this.getInvoiceTotalForFilteredData(response);
    }
     else {
      this.getInvoiceTotalForAllData();
    } 
  }

  getInvoiceTotalForAllData = () => {
    let { filter } = this.state;
    let parameters = { OperationStatusId: 1 };

    if(filter.filter.BankCode) 
      parameters.bankCode = filter.filter.BankCode.eq;
    if(filter.filter.CurrencyCode) 
      parameters.currencyCode = filter.filter.CurrencyCode.eq;
    
    this.props.getInvoiceTotal(parameters, (response) => {
      if (response) {
        this.setState({
          totalAmount: response.TotalSum,
          totalCount: response.TotalCount,
          totalsLoading: false,
        }
        )
      }
    });
  }


  getInvoiceTotalForFilteredData = response => {
    let _amount = null, _count = null;
    if (response && response['@odata.count'] > 0) {
      _amount = 0;
      _count = response['@odata.count']
      response.value.forEach(x => {
        _amount += x.InvoiceAmount;
      });
    }
    this.setState({
      totalAmount: _amount,
      totalCount: _count,
      totalsLoading: false,
    })
  }

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getCreatingInstructions();
    });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({
      filter,

    }, () => {
      this.getCreatingInstructions(response => {
        this.getInvoiceTotal(response);
      });
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getCreatingInstructions());
  }

  additionalButtons = () => {
    let buttons = [
      <div onClick={() => window.open('/static/files/DBS_Sample_v1.xlsx')}>
        <Icon type="plus" className="mr-5" />
        {i18n.t('dbs.downloadExampleFile')}
      </div>
    ];

    return buttons;
  };

  orderOperation = (data, method) => {
    this.setState({ orderSendingOrCreating: true })
    let _data = method === "createOrder" ? new CreateOrderModel({ ...data, InvoiceIds: this.props.selectedItems }) : new SendOrderModel({ ...data, DbsOrderId: this.id, InvoiceIds: this.props.selectedItems });
    this.props[method](_data, (response) => {
      this.setState({ orderSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: response && response.Message,
          content: status === 'success' ? i18n.t(`dbs.${method}Response`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.getCreatingInstructions(response => {
              this.getInvoiceTotal(response);
            });
          }
        })
      }
    })
  }

  callbackDelete = () => {
    this.getCreatingInstructions(response => {
      this.getInvoiceTotal(response);
    });
  }

  getBankName = (row, field) => {
    let bank = this.props.tenantBanks.data && row ? this.props.tenantBanks.data.find(x => x.Code === row[field]) : null
    return bank ? <React.Fragment><img src={bank.LogoSmall} className="bank-icon" alt="" /> {bank.Name}</React.Fragment> : '';
  };


  getSelectedRowForInvoiceAmount = (checkAll = false) => {
    if(checkAll === true) {
      this.getInvoiceTotalForAllData();
    }
    else {
      const selectedItems = this.props.creatingInstructions.list.data.filter(x => this.props.selectedItems.includes(x.Id));
      const totalAmount = selectedItems.reduce((a,b) => a + b.InvoiceAmount, 0);

      this.setState({
        totalAmount, totalCount: selectedItems.length
      })
    }
   
  }

  render() {
    const { creatingInstructions, tenantBanks, tenantCurrencies } = this.props;
    const { totalAmount, totalCount, totalsLoading, orderSendingOrCreating, filter } = this.state;
    const instructions = [
      { label: i18n.t('dbs.bankName'), key: 'BankCode', type: 'select', onChange: this.onBankChange, options: tenantBanks.data, name: (row) => this.getBankName(row, 'Code'), value: "Code", required: true },
      { label: i18n.t('dbs.description'), key: 'Description', type: 'input' },
      { label: i18n.t('dbs.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('msg.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('dbs.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('msg.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode', type: 'select', onChange: this.onCurrencyChange, options: tenantCurrencies.data, name: "Name", value: "Code", loading: tenantCurrencies.loading, required: true },
      { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: (data) => this.orderOperation(data, 'createOrder'), disabled: (creatingInstructions.count === 0 || orderSendingOrCreating), access: 'DbsOrder.Create' },
      { label: i18n.t('btn.sendOrder'), type: 'button', className: 'success', onClick: (data) => this.orderOperation(data, 'sendOrder'), disabled: (creatingInstructions.count === 0 || orderSendingOrCreating), access: 'DbsOrder.Send' },
    ]
    const columns = [
      { render: (row) => <ActionButtons url="DbsInvoice" editUrl item={row} hasDelete getDatas={this.callbackDelete} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('dbs.bankName'), key: 'BankName', render: (row) => this.getBankName(row, 'BankCode'), checkField: false, },
      { label: i18n.t('dbs.currentAccountName'), key: 'CurrentAccount/Title', checkField: false, },
      { label: i18n.t('dbs.currency'), key: 'Currency/Code', checkField: false, },
      { label: i18n.t('dbs.invoiceDate'), key: 'InvoiceDate', render: (row) => formatDate(row.InvoiceDate, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.expiryDate'), key: 'DueDate', render: (row) => formatDate(row.DueDate, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.invoiceStatusSourceCode'), key: 'OperationStatus/Name', checkField: false },
      { label: i18n.t('dbs.invoiceNo'), key: 'InvoiceNumber' },
      { label: i18n.t('dbs.invoiceAmount'), key: 'InvoiceAmount', render: (row) => formatCurrency({ data: row.InvoiceAmount, currency: row.Currency ? row.Currency.Code : undefined, withSign: false }) },
      { label: i18n.t('dbs.createdDate'), key: 'InsertedTime', render: (row) => formatDate(row.InsertedTime, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.description'), key: 'Description' },
    ];

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...{ ...creatingInstructions, single: creatingInstructions.list } }}>
          <FadeIn>
            <Instruction {...{ instructions, loading: totalsLoading }} />
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              count={creatingInstructions.list.count}
              access="DbsInvoice"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={creatingInstructions.list.data}
              loading={creatingInstructions.list.loading}
              title={i18n.t('route.dbs.creatingInstructions')}
              newButton={{ endpoint: "openDialog", title: 'addInvoice' }}
              excel={{ module: "dbs", url: "DbsInvoice.Excel", filter: { filter: { ...filter.filter, ...persistentFilter }, expand: filter.expand }}}
              fromExcel={{ url: "DbsInvoice.ReadExcel", baseUrl: baseUrls.dbs }}
              additionalButtons={this.additionalButtons()}
              checkbox
              onCheck={this.getSelectedRowForInvoiceAmount}
              checkAll={creatingInstructions.list.data && creatingInstructions.list.data.length > 0}
              getData={() => this.getCreatingInstructions(this.getInvoiceTotal)}
              Component={CreatingInstruction}
              dialogTitle={i18n.t('lbl.creatingInstruction')}
              deleteOptions={{ stateKey: "creatingInstructions", url: "DbsInvoice" }}
            />
          </FadeIn>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs, common }) => ({
  creatingInstructions: dbs.creatingInstructions,
  tenantBanks: dbs.filter.tenantBanks,
  tenantCurrencies: dbs.filter.tenantCurrencies,
  selectedItems: common.dataTableSelectedItems
});
const mapDispatchToProps = (dispatch) => ({
  getCreatingInstructions: (filter, callback) => dispatch(actions.getAll({ filter: { ...filter, filter: { ...filter.filter, ...persistentFilter } }, url: endpoints.dbs.dbsInvoice, key: 'creatingInstructions' }, callback)),
  getInvoiceTotal: (parameters, callback) => dispatch(actions.getAll({ parameters, url: endpoints.dbs.dbsInvoiceAggregate }, callback)),
  createOrder: (data, callback) => dispatch(actions.post({ url: endpoints.dbs.createOrder, data, customNotification: true }, callback)),
  sendOrder: (data, callback) => dispatch(actions.post({ url: endpoints.dbs.sendOrder, data, customNotification: true }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreatingInstructions);