import React from 'react';
import InputMask from 'react-input-mask';

class InputPhone extends React.Component {
  state = { isFocused: '' };

  render() {
    const { mobile, label, value, onChange, className, disabled, nextGen } = this.props;
    return (
      <InputMask mask={mobile ? "+\\90 (599) 999 99 99" : "0(999) 999 99 99"} value={value} onChange={(e) => onChange(e.target.value)}>
        {(inputProps) => (
          <div className={`nb nb-input ${!!disabled ? 'disabled' : ''} ${nextGen ? 'nextGen' : ''}`}>
            <input
              disabled={disabled}
              className={`ant-input ${value !== null && value !== undefined && value.toString() !== '' && 'is-filled'} ${className || ''}`}
              {...inputProps}
            />
            <label className={this.state.isFocused ? 'focused' : ''}>{label || ''}</label>
          </div>
        )}
      </InputMask>
    )
  }
}

export default InputPhone;