import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/dbs';
import reducers from 'store/reducers/dbs';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllDbs: ['payload', 'callback'],
  getAllDbsSuccess: ['payload', 'isNewODataStructure'],
  getAllDbsFailure: ['payload'],
  getDbs: ['payload', 'callback'],
  getDbsSuccess: ['payload', 'isNewODataStructure'],
  getDbsFailure: ['payload'],
  postDbs: ['payload', 'callback'],
  postDbsSuccess: ['payload'],
  postDbsFailure: ['payload'],
  putDbs: ['payload', 'callback'],
  putDbsSuccess: ['payload'],
  putDbsFailure: ['payload'],
  deleteDbs: ['payload', 'callback'],
  deleteDbsSuccess: ['payload'],
  deleteDbsFailure: ['payload'],
  patchDbs: ['payload', 'callback'],
  patchDbsSuccess: ['payload'],
  patchDbsFailure: ['payload'],
  clearStateDbs: ['key'],
  clearFilterDbs: ['key'],
  getWithPostDbs: ['payload'],
  getWithPostDbsSuccess: ['payload'],
  getWithPostDbsFailure: ['payload'],
  getFilterDbs: ['payload', 'isNewODataStructure'],
  getFilterDbsSuccess: ['payload', 'isNewODataStructure'],
  getFilterDbsFailure: ['payload'],
});
export const DbsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}

export const clearFilter = (state, action) => {
  const data = reducers.clearFilter(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state, action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state, action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state, action)
  return state.merge(data);
};



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_DBS]: getAll,
  [Types.GET_ALL_DBS_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_DBS_FAILURE]: getAllFailure,
  [Types.GET_DBS]: get,
  [Types.GET_DBS_SUCCESS]: getSuccess,
  [Types.GET_DBS_FAILURE]: getFailure,
  [Types.POST_DBS]: post,
  [Types.POST_DBS_SUCCESS]: postSuccess,
  [Types.POST_DBS_FAILURE]: postFailure,
  [Types.PUT_DBS]: put,
  [Types.PUT_DBS_SUCCESS]: putSuccess,
  [Types.PUT_DBS_FAILURE]: putFailure,
  [Types.DELETE_DBS]: _delete,
  [Types.DELETE_DBS_SUCCESS]: deleteSuccess,
  [Types.DELETE_DBS_FAILURE]: deleteFailure,
  [Types.PATCH_DBS]: patch,
  [Types.PATCH_DBS_SUCCESS]: patchSuccess,
  [Types.PATCH_DBS_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_DBS]: clearState,
  [Types.CLEAR_FILTER_DBS]: clearFilter,
  [Types.GET_WITH_POST_DBS]: getWithPost,
  [Types.GET_WITH_POST_DBS_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_DBS_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_DBS]: getFilter,
  [Types.GET_FILTER_DBS_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_DBS_FAILURE]: getFilterFailure,
});