import React, { useEffect, useState, useCallback } from 'react';
import { Card, Spin, Icon, Empty } from "antd";
import commonActions from 'store/actions/common';
import { connect } from 'react-redux';
import i18n from 'plugins/i18n';
import endpoints from 'config/endpoints';
import { moduleTypes } from 'lib/constants';
import { Loading } from 'components/UIComponents';
import { Element, scroller, Events } from "react-scroll";
import { useLocation } from 'react-router-dom';
import moment from 'moment'


const Notifications = ({ getAlerts, alerts, setSeenStatus }) => {
  const [view, setView] = useState({})
  const [viewLoading, setViewLoading] = useState({});
  const urlData = useLocation().hash.slice(1);


  const onClickItem = useCallback((id, isSeen) => {
    viewLoading[id] = true
    setViewLoading({ ...viewLoading })

    const setStatus = (useTimeoutParam = false) => {
      const useTimeout = useTimeoutParam && !view[id];
      setTimeout(() => {
        view[id] = view[id] ? false : true;
        viewLoading[id] = false;
        setViewLoading({ ...viewLoading })
        setView({ ...view })
      }, useTimeout ? 500 : 0);
    }

    if (view[id] === undefined && !isSeen) {
      const data = { id, isSeen: true }
      setSeenStatus(data, (response) => {
        if (response) setStatus();
      })
    }
    else {
      setStatus(true);
    }
  }, [setSeenStatus, view, viewLoading])

  const goToNotification = useCallback((id) => {
    scroller.scrollTo(id, {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "antContainerScroll"
    });
  }, [])

  useEffect(() => {
    Events.scrollEvent.register('end', function (to, element) {
      var id = arguments[0];
      onClickItem(id, false)
    });
  }, [onClickItem, view])

  useEffect(() => {
    urlData && !alerts.list.loading && alerts.list.data.length > 0 && goToNotification(urlData)
  }, [alerts, urlData, goToNotification])


  useEffect(() => {
    const filter = {
      count: true,
      filter: { AlertType: { ne: 4 } }
    }
    getAlerts(filter)
  }, [getAlerts])

  return (
    <Card bordered={false} className="notifications-page" title={<div className="font-20">{i18n.t('lbl.notifications')}</div>}>
      <Loading loading={alerts.list.loading}>
        {
          alerts.list.data.length > 0 ?
            alerts.list.data.map(x => (
              <Element
                name={x.Id}
                className={`
                  notify-item 
                  ${urlData && urlData === x.Id ? 'current-item' : ''} 
                  ${x.IsSeen || view[x.Id] !== undefined ? '' : 'unread'}
                `}
                key={x.Id}
                onClick={() => onClickItem(x.Id, x.IsSeen)}
              >
                <div className="notify-item-icon">
                  {
                    !x.IsSeen && x.Count > 1 ?
                      <span>{x.Count}</span>
                      :
                      <i className={`${x.IsSeen || view[x.Id] !== undefined ? 'far' : 'fas'} fa-bell`}></i>
                  }
                </div>
                <div className={`notify-wrapper `}>
                  <span className={`notify-tag ${moduleTypes[x.ModuleType].toLowerCase()}`} />
                  <b>{x.Title}</b>
                  <div>
                    <div className={`description ${viewLoading[x.Id] || view[x.Id] ? 'show' : 'hide'} `}>
                      {
                        viewLoading[x.Id] ?
                          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                          :
                          x.Description
                      }
                    </div>
                  </div>

                  {
                    x.Count > 1 && !x.IsSeen &&
                    <p className="multiple-times">{i18n.t('lbl.countOfViews')}: {x.Count}</p>
                  }
                </div>

                <div className="time"><i className="far fa-clock"></i> {moment(x.LastAlertDate).format('DD MMMM YYYY HH:mm')}</div>

              </Element>
            ))
            :
            <Empty description={i18n.t("msg.thereAreNoNotifications")} />
        }
      </Loading>
    </Card>
  );
};

const mapStateToProps = ({ common }) => ({
  alerts: common.alertsPage
})
const mapDispatchToProps = (dispatch) => ({
  getAlerts: (filter, callback) => dispatch(commonActions.getAll({ filter, url: endpoints.tenant.alerts, key: "alertsPage", isNewODataStructure: true }, callback)),
  setSeenStatus: (data, callback) => dispatch(commonActions.post({ data, url: endpoints.tenant.alertsSeen }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)