import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Form, Row, Col, Button, Tooltip, Modal, notification, Checkbox, Icon } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { RuleModel, RuleItemModel } from 'models';
import { DataTable, Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import { httpMethods, regexTypes, categoryTypes as categoryTypesEnum, regexFilters } from 'lib/constants';
import i18n from 'plugins/i18n';

class Rule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      visible: false,
      data: new RuleModel(),
      InitialState: new RuleModel(),
      currentCodes: [],
      currentItem: {
        regexType: null,
        regexFilter: null,
        regexPattern: ''
      },
      index: null,
      planCodes: [],
      accountCodes: [],
    };
    this.id = props.id;
    this.searchTimeoutCurrentCode = null;
    this.searchTimeoutPlanCode = null;
  };

  componentDidMount() {
    this.props.getCategoryTypes({ filter: { or: [{ ParentId: { ne: null } }, { CategoryType: 0 }] } });
    this.props.getRegexFields();
    this.props.getBankAccounts();
    const { tenantId } = this.props
    const parameters = tenantId && { TenantId: tenantId };

    if (this.id) this.props.getRules(this.id, parameters, (response) => {
      if (response) {
        if (response.accountPlanCode && response.accountPlanCode !== '') {
          this.planCodeSearch(response.accountPlanCode, true);
        }
        if (response.currentAccountCode && response.currentAccountCode !== '') {
          this.accountCodeSearch(response.currentAccountCode, true);
        }
      }
    })

    else {
      this.props.getDynamicProperties((response) => {
        this.setState({ loading: false });
        if (response) this.update('rulePropertyConfigs', response);
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.rules.single.data && !state.data.id) {
      const data = new RuleModel({ ...props.rules.single.data });
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.rules.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  planCodeSearch = (e) => {
    this.props.getPlanCodes(e, (response) => {
      this.setState({ planCodes: response })
    });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  update = (key, value) => {
    if (!value && (key === 'currentAccountCode' || key === 'accountPlanCode')) value = '';
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
    if (key === 'currentAccountCode' && !value) this.accountCodeSearch('');
    if (key === 'accountPlanCode' && !value) this.planCodeSearch('');
  };

  updateItem = (key, value) => {
    const { currentItem } = this.state;
    currentItem[key] = value;
    if (key === 'regexType') {
      currentItem.regexFilter = null;
      currentItem.regexPattern = '';
    };
    this.setState({ currentItem });
  };

  save = (e) => {
    e.preventDefault();
    const { data, planCodes } = this.state;
    const { dialogCloseRequest, tenantId } = this.props;
    const _data = _.cloneDeep(data);
    const parameters = tenantId && { TenantId: tenantId };

    this.props.form.validateFields(error => {
      if (!error && (!_data.setManuelPriority || (!!_data.setManuelPriority && _data.priority))) {
        if (_data.ruleItems.length > 0) {
          this.setState({ saving: true });
          if (_data.accountPlanCode !== '' && !planCodes.find(x => x.Code === _data.accountPlanCode).id)
            _data.newAccountPlan = 1;

          const method = this.id ? httpMethods.put : httpMethods.post;
          _data.priority = _data.priority || null;
          _data.rulePropertyConfigs = _data.rulePropertyConfigs.filter(x => x.checked)

          this.props.saveData(_data, parameters, method, (response) => {
            if (response)
              this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
            else
              this.setState({ saving: false });
          });
        }
        else {
          notification.error({ message: i18n.t('msg.noRuleItems') });
        }
      }
    });
  };

  saveItem = () => {
    const { data, currentItem, index } = this.state;
    if (currentItem.regexType === null || currentItem.regexFilter === null || currentItem.regexPattern.toString().trim() === '') {
      notification.error({ message: i18n.t('msg.noRuleItem') });
    }
    else if ((currentItem.regexType === regexTypes.TypeCode1 || currentItem.regexType === regexTypes.TypeCode2) && !data.ruleItems.find(x => x.regexType === regexTypes.Bank)) {
      notification.error({ message: i18n.t('msg.noBankRule') });
    }
    else {
      index !== null && index !== undefined ? data.ruleItems[index] = currentItem : data.ruleItems.push(currentItem);
      this.setState({ visible: false, data });
    }
  }

  deleteRuleItem = (index) => {
    const { data } = this.state;
    const _item = data.ruleItems[index];
    if (_item.regexType === regexTypes.Bank) {
      const _bankAccount = data.ruleItems.find(x => x.regexType === regexTypes.BankaHesap);
      const _contactName = data.ruleItems.find(x => x.regexType === regexTypes.IlgiliKisi);
      const _typeCode1 = data.ruleItems.find(x => x.regexType === regexTypes.TypeCode1);
      const _typeCode2 = data.ruleItems.find(x => x.regexType === regexTypes.TypeCode2);

      if (_bankAccount)
        data.ruleItems.splice(data.ruleItems.indexOf(_bankAccount), 1);
      if (_contactName)
        data.ruleItems.splice(data.ruleItems.indexOf(_contactName), 1);
      if (_typeCode1)
        data.ruleItems.splice(data.ruleItems.indexOf(_typeCode1), 1);
      if (_typeCode2)
        data.ruleItems.splice(data.ruleItems.indexOf(_typeCode2), 1);

      notification.warning({ message: i18n.t('msg.bankDeleteRuleItemInfo'), duration: 20 });
    }
    data.ruleItems.splice(data.ruleItems.indexOf(_item), 1)
    this.setState({ data });
  };

  renderButtons = (row, i) => {
    return (
      <Button.Group className="action-buttons">
        <Tooltip placement="bottom" title={i18n.t('btn.delete')}>
          <Button className="#delete" icon="delete" size="small" onClick={() => this.deleteRuleItem(i)} />
        </Tooltip>
        <Tooltip placement="bottom" title={i18n.t('btn.edit')}>
          <Button className="#edit" icon="edit" size="small" onClick={() => this.setState({ currentItem: row, visible: true, index: i })} />
        </Tooltip>
      </Button.Group>
    );
  };

  getName = ({ regexType, regexPattern }) => {
    const { tenantBanks, bankAccounts } = this.props;

    let obj;

    if (regexType === regexTypes.Bank) obj = tenantBanks.data && tenantBanks.data.find(x => x.bankEftCode === regexPattern.toString());
    else if (regexType === regexTypes.BankaHesap) obj = bankAccounts.data && bankAccounts.data.find(x => x.id.toString() === regexPattern.toString());

    return obj ? regexType === regexTypes.Bank ? <React.Fragment><img src={obj.logoSmall} className="bank-icon" alt="" /> {obj.name}</React.Fragment> : obj.name : regexPattern;
  }

  propertyUpdate = (type, value, index) => {
    const { data } = this.state;
    data.rulePropertyConfigs[index][type] = value;
    this.setState({ data })
  }

  renderInput = (type) => {
    const { currentItem, data } = this.state;
    const { type1, type2, tenantBanks, bankAccounts } = this.props

    const bank = data.ruleItems.find(x => x.regexType === regexTypes.Bank);
    const input = <Input className={`#${currentItem.regexPattern}`} label={i18n.t('lbl.value')} value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />;

    const fields = {
      [regexTypes.TypeCode1]: <Select className="#typeCode1" label={i18n.t('lbl.value')} options={bank ? type1.data && type1.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal="code" optName="code" value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.TypeCode2]: <Select className="#typeCode2" label={i18n.t('lbl.value')} options={bank ? type2.data && type2.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal="code" optName="code" value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.Bank]: <Select className="#bank" label={i18n.t('lbl.value')} options={tenantBanks.data} optVal="bankEftCode" optName={(bank) => <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment>} value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.BankaHesap]: <Select className="#BankaHesap" label={i18n.t('lbl.value')} options={bank ? bankAccounts.data && bankAccounts.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal={(x) => x.id.toString()} optName="name" value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.Desc]: input,
      [regexTypes.Iban]: input,
      [regexTypes.Tckn]: input,
      [regexTypes.Vkn]: input,
      [regexTypes.Tutar]: input,
      [regexTypes.IlgiliKisi]: input
    };
    return (fields[type]);
  };

  generateRegexFields = (regexFields = []) => {
    const { data } = this.state;
    let _regexFields = []
    _regexFields = regexFields.map(x => {
      const ruleItem = data.ruleItems.find(y => y.regexType === x.regexType);
      if (ruleItem) {
        if (ruleItem.regexType === regexTypes.Tutar && data.ruleItems.filter(x => x.regexType === regexTypes.Tutar).length < 2)
          return x;
        else
          return { ...x, disabled: true }
      }
      else
        return x
    });

    if (!data.ruleItems.find(x => x.regexType === regexTypes.Bank)) {
      _regexFields = _regexFields.map(x => {
        const hasTypeCodeOrBankAccount = x.regexType !== regexTypes.TypeCode1 && x.regexType !== regexTypes.TypeCode2 && x.regexType !== regexTypes.BankaHesap && x.regexType !== regexTypes.IlgiliKisi;
        return hasTypeCodeOrBankAccount ? x : { ...x, disabled: true }
      });
    }
    return _regexFields;
  }

  generateRegexFilterOptions = (regexFieldsData = []) => {
    let regexFiltersOptions = [];
    const { currentItem } = this.state;

    const regexFiltersData = regexFieldsData.find(x => x.regexType === currentItem.regexType);
    const regexFilterItems = (regexFiltersData && regexFiltersData.regexFilters) ? regexFiltersData.regexFilters : []
    regexFiltersOptions = regexFilterItems.map(x => ({ value: x, name: i18n.t(`lbl.${regexFilters[x]}`) }));

    return regexFiltersOptions;
  }

  renderDialogButtons = checkAccessRights => {
    let { rules, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={rules.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={rules.single.loading} form="rule" htmlType="submit" className="success #save" icon="save" loading={rules.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      rules.single.saving,
      rules.single.loading
    )
  }

  render() {
    const { loading, visible, data, currentItem, planCodes, accountCodes } = this.state;
    const { transferStatus, voucherTypes, categoryTypes, regexFields, rules, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    const _regexFields = this.generateRegexFields(regexFields.data);
    const regexFiltersOptions = this.generateRegexFilterOptions(regexFields.data);

    let columns = [{ render: (row, i) => this.renderButtons(row, i), checkField: false, }];
    columns.push(
      { key: 'regexType', render: (row) => this.props.regexFields.data.length > 0 && this.props.regexFields.data.find(x => x.regexType === row.regexType).description },
      { key: 'regexFilter', render: (row) => i18n.t(`lbl.${regexFilters[row.regexFilter]}`) },
      { key: 'regexPattern', render: (row) => this.getName(row) },
    );

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...rules, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={!!(loading || planCodes.loading || accountCodes.loading)} className="loading-card">
            <Form onSubmit={this.save} id="rule">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.voucherType')}
                      value={data.voucherTypeId}
                      options={voucherTypes.data}
                      optVal="id"
                      optName="name"
                      onChange={(e) => this.update('voucherTypeId', (e))}
                      className="#voucherType"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.accountPlanCode')}
                      value={data.accountPlanCode}
                      options={planCodes}
                      optVal="Code"
                      optName={(e) => `${e ? e.Code + ' ' + e.Name || e.Title : ''}`}
                      showSearch
                      onSearch={this.planCodeSearch}
                      onChange={(e) => this.update('accountPlanCode', e)}
                      className="#accountPlanCode"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.bankAccountCode')} value={data.bankAccountCode} onChange={(e) => this.update('bankAccountCode', e)} className="#bankAccountCode" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.currentAccount')}
                      options={accountCodes}
                      optVal="Code"
                      optName={(e) => `${e ? e.Code + ' ' + e.Title || e.Name : ''}`}
                      value={data.currentAccountCode}
                      showSearch
                      onSearch={this.accountCodeSearch}
                      onChange={(e) => this.update('currentAccountCode', e)}
                      className="#currentAccountCode"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.incomingCategory')}
                      value={data.incomingCategoryId}
                      options={categoryTypes.data ? categoryTypes.data.filter(x => x.CategoryType === categoryTypesEnum.IncomingTransaction || x.CategoryType === 0) : []}
                      optVal="Id"
                      optName="Name"
                      onChange={(e) => this.update('incomingCategoryId', e)}
                      className="#incomingCategory"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.outgoingCategory')}
                      value={data.outgoingCategoryId}
                      options={categoryTypes.data ? categoryTypes.data.filter(x => x.CategoryType === categoryTypesEnum.OutgoingTransaction || x.CategoryType === 0) : []}
                      optVal="Id"
                      optName="Name"
                      onChange={(e) => this.update('outgoingCategoryId', e)}
                      className="#outgoingCategory"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.transferStatus')}
                      value={data.transferStatus}
                      options={transferStatus.data}
                      optVal="id"
                      optName={(x) => i18n.t(`status.${x.name}`)}
                      onChange={(e) => this.update('transferStatus', e)}
                      className="#transferStatus"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Row gutter={20}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        validateStatus={!!data.setManuelPriority && !data.priority ? 'error' : 'success'}
                        help={!!data.setManuelPriority && !data.priority && i18n.t('msg.required')}
                      >
                        <Input disabled={!data.setManuelPriority} type="number" label={i18n.t('lbl.point')} value={data.priority} onChange={(e) => this.update('priority', e)} className="#point" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <Checkbox className="#setManuelPriority" checked={!!data.setManuelPriority} onChange={(e) => this.update('setManuelPriority', e.target.checked)}>
                          {i18n.t('lbl.enterRulePoint')}
                          <Tooltip title={i18n.t('msg.whileIfUncheckedCalculatedRulePoint')}>
                            <Icon className="icon inner-question" type="question-circle" />
                          </Tooltip>
                        </Checkbox>

                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-20 mt-20">
                <DataTable
                  noHeader
                  data={data.ruleItems}
                  columns={columns}
                  pagination={false}
                  showNoData={false}
                  title={i18n.t('lbl.conditions')}
                  toggle={false}
                  className="full-width"
                  newButton={() => this.setState({ visible: true, currentItem: new RuleItemModel(), index: null })}
                />
              </Row>

              <Row className="mb-20 mt-40">
                <Card title={i18n.t('lbl.fieldDefinitions')}>
                  {
                    data.rulePropertyConfigs.map((fields, index) =>
                      <Row className="mt-5 inline-checkbox" key={index}>
                        <Col xs={24} sm={12} lg={12}>
                          <Checkbox className={`#${fields.name}`} checked={fields.checked} onChange={(e) => this.propertyUpdate('checked', e.target.checked, index)}>
                            {fields.name}
                          </Checkbox>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          {
                            fields.checked ?
                              <React.Fragment>
                                {
                                  fields.configOperationType === 2 &&
                                  <Select
                                    label={fields.name}
                                    value={fields.defaultValue}
                                    options={fields.items}
                                    optVal={null}
                                    optName={null}
                                    onChange={(e) => this.propertyUpdate('defaultValue', e, index)}
                                    className={`#${fields.name}Input`}
                                  />
                                }
                                {
                                  fields.configOperationType === 1 &&
                                  <Form.Item className="mb-0">
                                    {getFieldDecorator(fields.name, {
                                      rules: [
                                        { min: fields.length, message: i18n.t('msg.reqLength', { length: fields.length }), },
                                        { max: fields.length, message: i18n.t('msg.reqLength', { length: fields.length }), },
                                      ],
                                      initialValue: fields.defaultValue
                                    })(
                                      <Input maxLength={fields.length} label={fields.name} onChange={(e) => this.propertyUpdate('defaultValue', e, index)} className={`#${fields.name}Input`} />
                                    )}
                                  </Form.Item>
                                }
                              </React.Fragment>
                              :
                              null
                          }
                        </Col>
                      </Row>
                    )
                  }
                </Card>
              </Row>
            </Form>
          </Loading>
          <Modal
            title={i18n.t('lbl.condition')}
            visible={visible}
            onCancel={() => this.setState({ visible: false })}
            onOk={this.saveItem}
            okButtonProps={{ className: '#save', icon: 'save' }}
            cancelButtonProps={{ className: '#cancel', icon: 'close' }}
            okText={i18n.t('btn.save')}
            cancelText={i18n.t('btn.cancel')}
          >
            <Form>
              <Row gutter={8}>
                <Col span={8}>
                  <Select
                    className="#domain"
                    label={i18n.t('lbl.domain')}
                    value={currentItem.regexType}
                    options={_regexFields}
                    optVal="regexType"
                    optName="description"
                    onChange={(e) => this.updateItem('regexType', e)}
                  />
                </Col>
                <Col span={8}>
                  <Select
                    className="#operator"
                    value={currentItem.regexFilter}
                    label={i18n.t('lbl.operator')}
                    options={regexFiltersOptions}
                    optVal="value"
                    optName="name"
                    onChange={(e) => this.setState({ currentItem: { ...currentItem, regexFilter: e } })}
                  />
                </Col>
                <Col span={8}>
                  {this.renderInput(currentItem.regexType)}
                </Col>
              </Row>
            </Form>
          </Modal>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  bankAccounts: common.filter.bankAccounts,
  tenantBanks: common.filter.tenantBanks,

  rules: netekstre.rules,
  voucherTypes: netekstre.filter.voucherTypes,
  categoryTypes: netekstre.filter.categoryTypes,
  regexFields: netekstre.filter.regexFields,
  type1: netekstre.filter.typeCodes1,
  type2: netekstre.filter.typeCodes2,
  transferStatus: netekstre.filter.transferStatus,
});
const mapDispatchToProps = (dispatch, { endpoint }) => ({
  getPlanCodes: (data, callback) => dispatch(commonActions.getPlanCodes(data, callback)),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
  getBankAccounts: () => dispatch(commonActions.getFilter({ url: endpoints.tenant.bankAccountsGetBankAccountsByBankEftCode, key: 'bankAccounts' })),

  getRules: (id, parameters, callback) => dispatch(netekstreActions.get({ url: endpoint || endpoints.nte.rules, key: 'rules', id, parameters }, callback)),
  saveData: (data, parameters, method, callback) => dispatch(netekstreActions[method]({ parameters, url: endpoint || endpoints.nte.rules, key: 'rules', data }, callback)),
  getCategoryTypes: (filter) => dispatch(netekstreActions.getFilter({ url: endpoints.nte.tenantCategories, key: 'categoryTypes', filter, isNewODataStructure: true })),
  getRegexFields: () => dispatch(netekstreActions.getFilter({ url: endpoints.lookups.regexFields, key: 'regexFields' })),
  getDynamicProperties: (callback) => dispatch(netekstreActions.getAll({ url: endpoints.lookups.propertyConfigs }, callback)),
  clearState: () => dispatch(netekstreActions.clearState("rules"))

});
const RuleForm = Form.create()(Rule);
export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);

