import React from 'react'
import { Layout } from "antd";
import Logo from "assets/img/logo.svg";
import ReactSVG from "react-svg";

const Header = () => {
  return (
    <Layout.Header className="auth-header page-container">
      <ReactSVG src={Logo} />
    </Layout.Header>
  )
}

export default Header;
