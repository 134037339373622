import React, { Component } from 'react';
import Tenants from '../Tenants/List';
import NTECurrentAccounts from 'components/Modules/Common/Definitions/CurrentAccounts/List'
import i18n from 'plugins/i18n';
import { CheckAccessRight } from 'components/UIComponents';

class CurrentAccounts extends Component {
  state = {
    selectedTenant: null
  }

  setSelectedTenant = tenant => {
    this.setState({ selectedTenant: tenant })
  }

  render() {
    const { selectedTenant } = this.state;
    return (
      <CheckAccessRight {...{ stateKey: "admin.companies" }} withTag="div">
        <Tenants
          datatableTitle={selectedTenant ? selectedTenant.name : i18n.t('lbl.pleaseSelectTenant')}
          hasParent
          onTenantSelect={this.setSelectedTenant}
          datatableCollapsed={selectedTenant}
        />

        <NTECurrentAccounts
          showFilter={false}
          className="mt-60"
          datatableTitle={selectedTenant ? i18n.t('lbl.currentAccountsForTenant', { tenant: selectedTenant.name }) : null}
          getDataTrigger={selectedTenant}
          datatableCollapsed={!selectedTenant}
        />
      </CheckAccessRight>
    );
  };
};

export default CurrentAccounts;