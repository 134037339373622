import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import * as NetekstreTypes from 'store/actions/netekstre'

/* ------------- Types ------------- */
import { AuthTypes } from 'store/redux/auth';
import { AdminTypes } from 'store/redux/admin';
import { CommonTypes } from 'store/redux/common';
import { DbsTypes } from 'store/redux/dbs';
import { PosraporTypes } from 'store/redux/posrapor';
import { TosTypes } from 'store/redux/tos';
import { CekTypes } from 'store/redux/cek';
import { NetahsilatTypes } from 'store/redux/netahsilat';

/* ------------- Sagas ------------- */
import * as authSagas from './auth';
import * as netekstreSagas from './netekstre';
import * as dbsSagas from './dbs';
import * as tosSagas from './tos';
import * as cekSagas from './cek';
import * as posraporSagas from './posrapor';
import * as commonSagas from './common';
import * as adminSagas from './admin';
import * as netahsilatSagas from './netahsilat';

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([
    takeEvery(NetekstreTypes.GET_WITH_POST_NETEKSTRE, netekstreSagas.getWithPost),
    takeEvery(NetekstreTypes.GET_FILTER_NETEKSTRE, netekstreSagas.getFilter),
    takeEvery(NetekstreTypes.GET_ALL_NETEKSTRE, netekstreSagas.getAll),
    takeEvery(NetekstreTypes.GET_NETEKSTRE, netekstreSagas.get),
    takeEvery(NetekstreTypes.POST_NETEKSTRE, netekstreSagas.post),
    takeEvery(NetekstreTypes.PUT_NETEKSTRE, netekstreSagas.put),
    takeEvery(NetekstreTypes.DELETE_NETEKSTRE, netekstreSagas._delete),
    takeEvery(NetekstreTypes.PATCH_NETEKSTRE, netekstreSagas.patch),

    takeEvery(AdminTypes.GET_WITH_POST_ADMIN, adminSagas.getWithPost),
    takeEvery(AdminTypes.GET_FILTER_ADMIN, adminSagas.getFilter),
    takeEvery(AdminTypes.GET_ALL_ADMIN, adminSagas.getAll),
    takeEvery(AdminTypes.GET_ADMIN, adminSagas.get),
    takeEvery(AdminTypes.POST_ADMIN, adminSagas.post),
    takeEvery(AdminTypes.PUT_ADMIN, adminSagas.put),
    takeEvery(AdminTypes.DELETE_ADMIN, adminSagas._delete),
    takeEvery(AdminTypes.PATCH_ADMIN, adminSagas.patch),

    takeEvery(DbsTypes.GET_WITH_POST_DBS, dbsSagas.getWithPost),
    takeEvery(DbsTypes.GET_FILTER_DBS, dbsSagas.getFilter),
    takeEvery(DbsTypes.GET_ALL_DBS, dbsSagas.getAll),
    takeEvery(DbsTypes.GET_DBS, dbsSagas.get),
    takeEvery(DbsTypes.POST_DBS, dbsSagas.post),
    takeEvery(DbsTypes.PUT_DBS, dbsSagas.put),
    takeEvery(DbsTypes.DELETE_DBS, dbsSagas._delete),
    takeEvery(DbsTypes.PATCH_DBS, dbsSagas.patch),

    takeEvery(TosTypes.GET_WITH_POST_TOS, tosSagas.getWithPost),
    takeEvery(TosTypes.GET_FILTER_TOS, tosSagas.getFilter),
    takeEvery(TosTypes.GET_ALL_TOS, tosSagas.getAll),
    takeEvery(TosTypes.GET_TOS, tosSagas.get),
    takeEvery(TosTypes.POST_TOS, tosSagas.post),
    takeEvery(TosTypes.PUT_TOS, tosSagas.put),
    takeEvery(TosTypes.DELETE_TOS, tosSagas._delete),
    takeEvery(TosTypes.PATCH_TOS, tosSagas.patch),

    takeEvery(CekTypes.GET_WITH_POST_CEK, cekSagas.getWithPost),
    takeEvery(CekTypes.GET_FILTER_CEK, cekSagas.getFilter),
    takeEvery(CekTypes.GET_ALL_CEK, cekSagas.getAll),
    takeEvery(CekTypes.GET_CEK, cekSagas.get),
    takeEvery(CekTypes.POST_CEK, cekSagas.post),
    takeEvery(CekTypes.PUT_CEK, cekSagas.put),
    takeEvery(CekTypes.DELETE_CEK, cekSagas._delete),
    takeEvery(CekTypes.PATCH_CEK, cekSagas.patch),

    takeEvery(PosraporTypes.GET_WITH_POST_POSRAPOR, posraporSagas.getWithPost),
    takeEvery(PosraporTypes.GET_FILTER_POSRAPOR, posraporSagas.getFilter),
    takeEvery(PosraporTypes.GET_ALL_POSRAPOR, posraporSagas.getAll),
    takeEvery(PosraporTypes.GET_POSRAPOR, posraporSagas.get),
    takeEvery(PosraporTypes.POST_POSRAPOR, posraporSagas.post),
    takeEvery(PosraporTypes.PUT_POSRAPOR, posraporSagas.put),
    takeEvery(PosraporTypes.DELETE_POSRAPOR, posraporSagas._delete),
    takeEvery(PosraporTypes.PATCH_POSRAPOR, posraporSagas.patch),
    
    takeEvery(CommonTypes.GET_FILTER_COMMON, commonSagas.getFilter),
    takeEvery(CommonTypes.GET_ALL_COMMON, commonSagas.getAll),
    takeEvery(CommonTypes.GET_COMMON, commonSagas.get),
    takeEvery(CommonTypes.POST_COMMON, commonSagas.post),
    takeEvery(CommonTypes.PUT_COMMON, commonSagas.put),
    takeEvery(CommonTypes.DELETE_COMMON, commonSagas._delete),
    takeEvery(CommonTypes.PATCH_COMMON, commonSagas.patch),
    takeEvery(CommonTypes.SEEN_VERSION, commonSagas.seenVersion),
    takeLatest(CommonTypes.CHANGE_TENANT, commonSagas.changeTenant),
    takeLatest(CommonTypes.GET_ACCOUNT_CODES, commonSagas.getAccountCodes),
    takeLatest(CommonTypes.GET_PLAN_CODES, commonSagas.getPlanCodes),
    takeEvery(CommonTypes.EXCEL_EXPORT, commonSagas.excelExport),
    takeEvery(CommonTypes.GET_VERSION, commonSagas.getVersion),
    takeLatest(CommonTypes.UPLOAD_IMG, commonSagas.uploadImg),
    takeLatest(CommonTypes.CHANGE_PASSWORD, commonSagas.changePassword),
    takeLatest(CommonTypes.GET_WITH_POST_COMMON, commonSagas.getWithPost),
    takeEvery(CommonTypes.LISTEN_THREE_D_PROCESS, commonSagas.listen3DProcess),

    takeLatest(AuthTypes.SIGN_IN, authSagas.signIn),
    takeLatest(AuthTypes.VERIFY_TWO_FACTOR, authSagas.verifyTwoFactor),
    takeLatest(AuthTypes.GET_ACCOUNT, authSagas.getAccount),
    takeLatest(AuthTypes.LOGOUT, authSagas.logout),
    takeLatest(AuthTypes.FORGOT_PASSWORD, authSagas.forgotPassword),
    takeLatest(AuthTypes.RESET_PASSWORD, authSagas.resetPassword),
    takeLatest(AuthTypes.DO_BLOCKED_USER_TO_ACTIVE, authSagas.doBlockedUserToActive),
    takeLatest(AuthTypes.RESEND_OTP, authSagas.resendOtp),

    takeEvery(NetahsilatTypes.GET_WITH_POST_NETAHSILAT, netahsilatSagas.getWithPost),
    takeEvery(NetahsilatTypes.GET_FILTER_NETAHSILAT, netahsilatSagas.getFilter),
    takeEvery(NetahsilatTypes.GET_ALL_NETAHSILAT, netahsilatSagas.getAll),
    takeEvery(NetahsilatTypes.GET_NETAHSILAT, netahsilatSagas.get),
    takeEvery(NetahsilatTypes.POST_NETAHSILAT, netahsilatSagas.post),
    takeEvery(NetahsilatTypes.PUT_NETAHSILAT, netahsilatSagas.put),
    takeEvery(NetahsilatTypes.DELETE_NETAHSILAT, netahsilatSagas._delete),
    takeEvery(NetahsilatTypes.PATCH_NETAHSILAT, netahsilatSagas.patch),
  ]);
};