import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import { DataTable, ActionButtons } from 'components/UIComponents';
import DynamicField from './Detail';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { defaultFilters, dialogTypes, baseUrls } from 'lib/constants';
import utils from 'lib';

class DynamicFields extends Component {
  state = {
    filter: defaultFilters.netekstre.DynamicFields(),
  };

  componentDidMount() {
    this.getDynamicFields();
    utils.common.checkAccessToUpdateOrDelete('DynamicProperties');
  };

  getDynamicFields = () => {
    let { filter } = this.state;
    this.props.getDynamicFields(filter);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getDynamicFields();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getDynamicFields);
  }

  render() {
    let { dynamicFields, auth } = this.props;
    let access = auth.data.accessRights.find(x => x.endPoint === 'DynamicProperties' && x.method === 'POST');
    let columns = [
      { render: (row) => <ActionButtons url="DynamicProperties" state="dynamicFields" editUrl="openDialog" item={row} hasDelete getDatas={this.getDynamicFields} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.title'), key: 'Title' },
      { label: i18n.t('lbl.code'), key: 'Code' },
    ];

    return (
      <div className="page-content">
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="DynamicProperties"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={dynamicFields.list.data}
          loading={dynamicFields.list.loading}
          title={i18n.t('route.netekstre.dynamicFields')}
          newButton={access && "openDialog"}
          Component={DynamicField}
          dialogTitle={i18n.t('lbl.dynamicField')}
          dialogType={dialogTypes.Modal}
          getData={this.getDynamicFields}
          count={dynamicFields.list.count}
          deleteOptions={{ stateKey: "dynamicFields", url: "DynamicProperties", baseUrl: baseUrls.nte }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth }) => ({
  auth,
  dynamicFields: netekstre.dynamicFields,
});
const mapDispatchToProps = (dispatch) => ({
  getDynamicFields: (filter) => dispatch(actions.getAll({ filter, url: endpoints.nte.dynamicProperties, key: 'dynamicFields', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(DynamicFields);