import React from 'react';
import CoverImg from "assets/img/cover-img.svg";
// import ReactSVG from "react-svg"; FIXME:

const LayoutCover = () => {
  return (
    <img alt="netfinans" src={CoverImg} />
  )
}

export default LayoutCover;
