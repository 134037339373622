import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Modal, Tooltip } from 'antd';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import { formatChartData, formatCurrency, convertFieldsToCamelize } from 'helpers';
import { Loading } from 'components/UIComponents';
import Fade from 'react-reveal/Fade';
import i18n from 'plugins/i18n';
import { chartTypes } from 'models';
import { chartOptions } from 'lib/constants';
const actions = {};

class LineChart extends Component {
  state = {
    loading: false,
    visible: false,
    modalData: {
      color: null,
      data: [],
      title: ""
    },
    wait: this.props.getWithQueue,
  }

  componentDidMount = () => {
    if (!this.props.getWithQueue)
      this.getChartData();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.currencyCode !== prevProps.currencyCode ||
      this.props.multiTenant !== prevProps.multiTenant ||
      this.props.isCumulative !== prevProps.isCumulative ||
      this.props.includedInternalTransfer !== prevProps.includedInternalTransfer
    ) {
      this.getChartData();
    }
  };

  getChartData = async () => {
    const { multiTenant, currencyCode, isCumulative, includedInternalTransfer, currentModule } = this.props;
    const args = [currentModule, { currencyCode, getMultiTenant: multiTenant }, { isCumulative, includedInternalTransfer, currencyCode }]

    return new Promise(async (resolve) => {
      this.setState({ wait: false });
      if (!actions[currentModule]) {
        const _module = await import(`store/actions/${currentModule}`);
        actions[currentModule] = _module.default;
        this.props.getChartData(...args, resolve);
      }
      else {
        this.props.getChartData(...args, resolve);
      }
    })
  }

  onLineClick = (line) => {
    let { multiTenant } = this.props;
    let singleData = _.cloneDeep(this.props.lineChart.single.data);
    if (multiTenant) {
      line = line[0];
      if (line && singleData.bankDetails && singleData.bankDetails.length > 0) {
        let datasetIndex = line._datasetIndex;
        let dataIndex = line._index;
        let lineColor = line._chart.config.data.datasets[datasetIndex].borderColor;
        let details = singleData.bankDetails[datasetIndex].chartDetails[dataIndex].details;
        if (details && details.length > 0) {
          let modalData = {
            title: JSON.parse(singleData.xAxis)[dataIndex],
            color: lineColor,
            data: details.sort((a, b) => a.name.localeCompare(b.name)).map(x => { return { tenantName: x.name, data: x.total === null ? '--' : parseFloat(x.total), count: x.count } })
          }
          this.setState({ visible: true, modalData });
        }
      }
    }
  }

  render() {
    const { lineChart, currencyCode, multiTenant, auth, title, accessRight } = this.props;
    const { modalData, visible, wait } = this.state;
    let chartData = lineChart.single.data ? _.cloneDeep(formatChartData({ series: lineChart.single.data.series, labels: lineChart.single.data.xAxis, options: { positive: true }, type: chartTypes.lineChart })) : {};
    if (chartData && chartData.datasets && chartData.datasets.length > 0) {
      chartData.datasets.forEach(x => {
        // x.borderColor = '#7ebf42';
        // x.pointBorderColor = '#7ebf42';
        // x.pointBackgroundColor = '#7ebf42';
        x.borderWidth = 4;
        x.pointHoverBorderWidth = 8;
        x.label = i18n.t(`lbl.${x.label}`);

      })
    };
    return (
      <React.Fragment>
        <h1 className="title">{title}</h1>
        <div ref={el => this.test = el} >
          <Row gutter={20}>
            <Col xs={24}>
              <Card ref={el => this.test = el}>
                <Loading loading={lineChart.single.loading || wait}>
                  {
                    !auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method) ?
                      <div className="no-access-icon">
                        <div className="no-access-right">
                          <i className="fas fa-exclamation-triangle"></i>
                          {i18n.t('msg.noAccessRightChart')}
                        </div>
                      </div>
                      :
                      <div style={{ maxHeight: '300px' }}>
                        <Line getElementAtEvent={this.onLineClick} ref={el => this.lineChart = el} data={chartData} height={300} options={chartOptions.lineChart({ multiTenant })} />
                      </div>
                  }
                </Loading>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          centered
          title={<div className="text-center">{modalData.title}</div>}
          key={Math.random()}
        >
          <Fade bottom cascade>
            <div className="statement-container">
              {
                modalData.data.sort((a, b) => a.tenantName.localeCompare(b.tenantName)).map((x, i) =>
                  <div className="statement-row" key={i}>
                    <div>
                      <Tooltip placement="left" title={x.tenantName}>
                        {x.tenantName}
                      </Tooltip>
                    </div>
                    <div style={{ color: modalData.color }}>{x.data === '--' ? x.data : formatCurrency({ data: x.data, currencyCode, withColor: true })}</div>
                  </div>
                )
              }
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
    );
  };
};

const mapStateToProps = (state, { toCamel }) => ({
  auth: state.auth,
  lineChart: toCamel ? convertFieldsToCamelize(state[state.common.currentModule].lineChart) : state[state.common.currentModule].lineChart,
  currentModule: state.common.currentModule
});
const mapDispatchToProps = (dispatch, { endpoint, method }) => ({
  getChartData: (currentModule, data, parameters, callback) => dispatch(actions[currentModule][method]({ data, url: endpoint, key: 'lineChart', parameters }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LineChart);