import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Modal, Button } from 'antd';
import _ from 'lodash'
import i18n from 'plugins/i18n';
import { dialogTypes, drawerPositions } from 'lib/constants';
import { connect } from 'react-redux';
import config from 'config';
const actions = {};

const Dialog = ({ title, width, position, id, type, Component, onClose, isDeleteDialog, deleteRow, currentModule, getData, notEditable, deleteOptions, dialogOptions = {} }) => {
  const [visible, setVisible] = useState(true)
  const [showDialog, setShowDialog] = useState(true)
  const [Footer, setFooter] = useState(null);
  const [buttonState, setButtonState] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const dialogCloseRequest = ({ visible = false, runGetData = false }) => {
    setVisible(visible);
    runGetData && setTimeout(getData, 250);
  }

  const deleteItem = async () => {
    if (deleteOptions.deleteConfirmAction) await deleteOptions.deleteConfirmAction().then(deleteAction);
    else deleteAction();
  };

  const deleteAction = async () => {
    let payload = {
      id,
      url: deleteOptions.url,
      key: deleteOptions.stateKey,
      baseUrl: deleteOptions.baseUrl ? config[deleteOptions.baseUrl] : undefined,
      parameters: dialogOptions.tenantId ? { TenantId: dialogOptions.tenantId } : undefined
    };
    if (!actions[currentModule]) {
      const _module = await import(`store/actions/${currentModule}`);
      actions[currentModule] = _module.default;
      deleteRow(currentModule, payload, (response) => {
        if (response) {
          closeDialog();
          setTimeout(getData, 200);
        }
      });
    }
    else {
      deleteRow(currentModule, payload, (response) => {
        if (response) {
          closeDialog();
          setTimeout(getData, 200);
        }
      });
    }
  }

  const showConfirm = () => {
    Modal.confirm({
      title: i18n.t('msg.screenLeaveTitle'),
      content: i18n.t('msg.screenLeaveMessage'),
      okText: i18n.t('btn.yes'),
      cancelText: i18n.t('btn.no'),

      onOk() {
        setShowDialog(false);
        setTimeout(onClose, 250);
      },
      onCancel() {
        dialogCloseRequest({ visible: true });
      },
    });
  }

  const onDialogClose = (initialState, currentState) => {
    if (!isDeleteDialog && !_.isEqual(initialState, currentState)) {
      showConfirm();
      return;
    }
    closeDialog();
  }

  const closeDialog = () => {
    setShowDialog(false);
    setTimeout(onClose, 250)
  }

  const DeleteButton = (deleting) => (
    <div>
      <Button loading={deleting} className="error mr-10 #cancel" icon="delete" onClick={deleteItem}>{i18n.t('btn.delete')}</Button>
    </div>
  )

  const renderDialogButtons = (Buttons, saving, loading) => {
    if (!Footer || (saving !== buttonState) || (loading !== buttonLoading)) {
      setFooter(isDeleteDialog ? DeleteButton(saving) : Buttons)
      setButtonState(saving);
      setButtonLoading(loading);
    }
  };

  const Screen = <Component {...{ id, renderDialogButtons, onDialogClose, dialogClosing: !visible && showDialog, dialogCloseRequest, notEditable, ...dialogOptions }} />;
  switch (type) {
    case dialogTypes.Modal:
      return (
        <Modal
          title={title}
          visible={showDialog}
          onCancel={() => setVisible(!visible)}
          width={width}
          footer={Footer}
          className="dialog"
        >
          {Screen}
        </Modal>
      )
    case dialogTypes.Drawer:
      return (
        <Drawer
          title={title}
          placement={position}
          visible={showDialog}
          closable
          width={width}
          onClose={() => { setVisible(!visible) }}
          getContainer={false}
          style={{ left: 0 }}
          className="dialog"
        >
          {Screen}
          <div style={{ width }} className="dialog-footer">{Footer}</div>
        </Drawer>
      )
    default:
      return null;
  }
}

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.oneOf([drawerPositions.left, drawerPositions.right, drawerPositions.top, drawerPositions.bottom]).isRequired,
  isDeleteDialog: PropTypes.bool,
  type: PropTypes.oneOf([dialogTypes.Drawer, dialogTypes.Modal]).isRequired,
  Component: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogOptions: PropTypes.object
};

Dialog.defaultProps = {
  position: drawerPositions.right,
  isDeleteDialog: false,
  width: 550,
  type: dialogTypes.Drawer
}
const mapStateToProps = ({ common }) => ({ currentModule: common.currentModule });
const mapDispatchToProps = (dispatch) => ({
  deleteRow: (currentModule, payload, callback) => dispatch(actions[currentModule].delete(payload, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dialog);