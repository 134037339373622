// import TenantBank from "components/Modules/NetEkstre/TenantBanks/Detail"

const stateKeys = [
  "parameters",
  "reportings",
  "cheques",
  "payrolls"
]

const filterKeys = [
  "tenantBanks",
  "currencies",
  "currentAccounts",
  "operationStatuses"
]

//#region State Generating - SHOULD NOT NE CHANGED 
const state = { filter: {} }
stateKeys.forEach(key => {
  state[key] = {
    list: { data: [], loading: null, count: null, error: null, statusCode: null },
    single: { data: null, saving: null, loading: null, error: null, statusCode: null }
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], error: null, loading: null
  }
})
export default state;
//#endregion