import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Divider, Switch } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { RecipientAccountModel } from 'models';
import actions from 'store/actions/tos';
import { Loading, FadeIn, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import utils from 'lib';

class RecipientAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new RecipientAccountModel(),
      InitialState: null,
      linkToCurrentAccount: false,
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.recipientAccounts.single.data && props.recipientAccounts.single.saving === null && !state.data.id) {
      const data = new RecipientAccountModel(props.recipientAccounts.single.data);
      if (data.currentAccountId) props.getCurrentAccounts()
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false, linkToCurrentAccount: !!data.currentAccountId }
    }
    if (props.dialogClosing && !props.recipientAccounts.single.saving) {
      props.onDialogClose({ ...state.InitialState }, { ...state.data })
    }
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getRecipientAccount(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };


  onSwitchChange = (checked) => {
    const { currentAccounts, getCurrentAccounts } = this.props
    this.setState({ linkToCurrentAccount: checked, data: { ...this.state.data, currentAccountId: null } }, () => {
      if (checked && (!currentAccounts.data.length)) getCurrentAccounts()
    });
  }

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    const { data } = this.state
    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, (response) => {
          if (response) {
            this.setState({ InitialState: data }, () => { dialogCloseRequest({ runGetData: true }); })
          }
        });
      }
    });
  };

  onSearch = e => {
    const _this = this;
    if (e && e !== '' && e.length > 2) {
      const filter = { filter: { or: [{ name: { contains: e } }, { title: { contains: e } }, { code: { contains: e } }] } }
      _this.props.getCurrentAccounts(filter);
    }
  }

  renderDialogButtons = checkAccessRights => {
    let { recipientAccounts, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={recipientAccounts.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={recipientAccounts.single.loading} form="recipientAccounts" htmlType="submit" className="success #save" icon="save" loading={recipientAccounts.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      recipientAccounts.single.saving,
      recipientAccounts.single.loading
    )
  }

  render() {
    const { recipientAccounts, tenantBanks, dialogCloseRequest, currentAccounts } = this.props;
    const { loading, data, linkToCurrentAccount } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <FadeIn className="page-content">
        <CheckAccessRight {...{ ...recipientAccounts, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="recipientAccounts">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bank')}
                        options={tenantBanks.data}
                        optVal="Code"
                        optName={(bankData) => utils.common.getBankName({ bankData })}
                        onChange={(e) => this.update('bankEftCode', e)}
                        disabled={!!this.id}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#name" label={i18n.t('lbl.accountName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('accountNumber', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.accountNumber
                    })(
                      <Input className="#accountNumber" label={i18n.t('lbl.accountNumber')} onChange={(e) => this.update('accountNumber', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#branchNumber" label={i18n.t('lbl.branchNumber')} value={data.branchNumber} onChange={(e) => this.update('branchNumber', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#branchName" label={i18n.t('lbl.branchName')} value={data.branchName} onChange={(e) => this.update('branchName', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('iban', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.iban
                    })(
                      <Input className="#iban" label={i18n.t('lbl.iban')} type="iban" onChange={(e) => this.update('iban', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Divider>{i18n.t('lbl.currentAccount')}</Divider>
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <Form.Item className="mt-5">
                    <Switch checked={linkToCurrentAccount || !!data.currentAccountId} onChange={this.onSwitchChange} loading={currentAccounts.loading} /> {i18n.t('lbl.linkToCurrentAccount')}
                  </Form.Item>
                </Col>
                {
                  linkToCurrentAccount &&
                  <Col xs={24} md={12}>
                    <Form.Item>
                      {getFieldDecorator('currentAccountId', {
                        rules: [{ required: true, message: i18n.t('msg.required') }],
                        initialValue: data.currentAccountId
                      })(
                        <Select
                          loading={currentAccounts.loading}
                          showSearch
                          onSearch={this.onSearch}
                          className="#CurrentAccountId"
                          label={i18n.t('lbl.currentAccount')}
                          options={currentAccounts.data}
                          optVal="Id"
                          searchFields={["Name", "Code", "Title"]}
                          optName="Name"
                          onChange={(e) => this.update('currentAccountId', e)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ tos, common }) => ({
  recipientAccounts: tos.recipientAccounts,
  tenantBanks: tos.filter.tenantBanks,
  currentAccounts: tos.filter.currentAccounts,

});
const mapDispatchToProps = (dispatch) => ({
  getRecipientAccount: (id, callback) => dispatch(actions.get({ url: endpoints.tos.recipientAccounts, key: 'recipientAccounts', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tos.recipientAccounts, key: 'recipientAccounts', data }, callback)),
  getCurrentAccounts: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.lookups.currentAccounts, key: 'currentAccounts', isNewODataStructure: false })),
  clearState: () => dispatch(actions.clearState("recipientAccounts")),
});
const RecipientAccountForm = Form.create()(RecipientAccount);
export default connect(mapStateToProps, mapDispatchToProps)(RecipientAccountForm);