import React from 'react';
import { Card as AntCard } from "antd";
import ReactSVG from 'react-svg';
import Right from 'assets/img/right-icon.svg'
import { withRouter } from "react-router-dom";
import { HomeModal } from './';
import { Netekstre, Posrapor, Dbs, Tos, Cek, Netahsilat } from './Cards';
import moduleIcons from 'assets/img/moduleIcons';


function Card({ name, path, history, hasModule, isActive, url, code }) {

  const getComponent = moduleCode => {
    switch (moduleCode) {
      case "NTE": return <Netekstre />
      case "PSR": return <Posrapor />
      case "DBS": return <Dbs />
      case "TÖS": return <Tos />
      case "CEK": return <Cek />
      case "NTH": return <Netahsilat />
      default: return null;
    }
  }

  return (
    <AntCard
      title={moduleIcons[code.toLowerCase()] ? <ReactSVG src={moduleIcons[code.toLowerCase()]} className="module-logo" /> : name}
      className={`module-card ${path} ${hasModule && isActive ? '' : 'disabled'}`}
      onClick={() => hasModule && isActive ? history.push(`/${path}`) : null}
    >
      {
        isActive && hasModule && getComponent(code)
      }
      {
        isActive && !hasModule &&
        <div className="module-card__inner">
          <HomeModal module={{ name, path, url }} available />
        </div>
      }
      {
        !isActive &&
        <div className="module-card__inner">
          <HomeModal module={{ name, path, url }} />
        </div>
      }

      <ReactSVG className="right-icon" src={Right} />
    </AntCard>
  );
};

export default withRouter(Card);