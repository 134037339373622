import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Input } from "../NetbankaComponents";
import i18n from "plugins/i18n";
import { controlTypes } from "lib/constants";
import { connect } from "react-redux";
import actions from "store/actions/common";
import { Form, Icon, Tooltip, Checkbox } from "antd";


const Cell = ({ col, item, i, initEditable = false, setActive, onChange, editingRow, datatableInline, setDatatableInlineValue, setDatatableBulkValue, showErrors, defaultValues }) => {
  const [cellValue, setCellValue] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    return () => setCellValue(null)
  }, [])

  const getValueFromData = (data, key) => {
    let _data = _.cloneDeep(data);
    if (key.includes('/')) key = key.replace(/[/]/g, '.');
    const keys = key.split('.')
    keys.map(key => (_data = _data?.[key] || null));
    return _data;
  }

  const getValue = ({ item, i }) => (col.render && (!initEditable || !col.editable)) ? col.render(item, i, datatableInline.list.data?.find(x => x.index === i)) : getValueFromData(item, col.key);

  const onCheckboxChange = e => {
    onCellChange(e.target.checked);
    onBlur(e.target.checked);
  }

  const onClick = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    const id = item?.id || item?.Id;
    if (initEditable) {
      setDatatableBulkValue({ id, index: i }, i)
      setActive();
    }
    else if (!editingRow) {
      if (id) setDatatableInlineValue({ id, index: i })
      setActive();
    }
  };

  const onCellChange = (value, e) => {
    setCellValue(value);
    onChange && onChange(value, col);
  }

  const normalizeValue = (forceValue) => {
    const val = (forceValue !== null && forceValue !== undefined) ? forceValue : cellValue

    switch (col.editable?.type) {
      case controlTypes.TextBox:
      case controlTypes.CheckBox:
        return val;

      case controlTypes.Number:
        return isNaN(val) || val === '' || val === null ? null : parseFloat(val);

      default: return null;
    }
  }

  const onBlur = (forceValue) => {
    let localChange = false;
    if ((cellValue !== null && cellValue !== item[col.key]) || (forceValue !== null && forceValue !== undefined)) { localChange = true; setIsChanged(true) }

    if (isChanged || localChange || !!forceValue) {
      const normalizedValue = normalizeValue(forceValue);
      let fieldData = { [col.key]: normalizedValue };

      if (!datatableInline.list.data?.find(x => x.index === i)) fieldData = { ...defaultValues, ...fieldData }
      if (initEditable) {
        setDatatableBulkValue({ ...fieldData }, i)
      }
      else
        setDatatableInlineValue({ ...fieldData })
      setCellValue(normalizedValue);
    }
  }

  const getCellValue = () => {
    let value;
    if (cellValue !== null && !!editingRow && editingRow.index === i)
      value = cellValue
    else {
      if (initEditable)
        value = datatableInline.list.data?.find(x => x.index === i)?.[col.key] !== undefined ? datatableInline.list.data?.find(x => x.index === i)?.[col.key] : cell;
      else
        value = (datatableInline.single.data && datatableInline.single.data?.index === i && datatableInline.single.data?.[col.key]) || cell
    }
    return value;
  }

  const renderElement = () => {
    const value = getCellValue();
    const mustBeValue = col.editable?.required && (showErrors || isChanged) && (value === null || value === undefined || value === '');
    const hasChange = isChanged && item[col.key] !== cellValue && (item.id || item.Id);
    switch (col.editable?.type) {
      case controlTypes.TextBox:
        return (
          <Form.Item validateStatus={mustBeValue ? 'error' : 'success'} >
            <Input
              className={`editable-cell ${(initEditable || editingRow?.index === i) ? 'editing' : ''}`}
              onClick={onClick}
              onFocus={onClick}
              onChange={onCellChange}
              onBlur={() => onBlur()}
              value={value}
              suffix={
                hasChange || mustBeValue ?
                  <Tooltip title={<>
                    {hasChange && <span dangerouslySetInnerHTML={{ __html: i18n.t('lbl.prevValue', { value: item[col.key] || `(${i18n.t('lbl.emptyValue')})` }) }} />}
                    {mustBeValue && <div style={{ color: 'red' }}>{i18n.t('msg.required')}</div>}
                  </>}>
                    <Icon type="info-circle" />
                  </Tooltip>
                  :
                  <span />
              }
            />
          </Form.Item>
        )

      case controlTypes.Number:
        return (
          <Form.Item validateStatus={mustBeValue ? 'error' : 'success'} >
            <Input
              className={`editable-cell ${(initEditable || editingRow?.index === i) ? 'editing' : ''}`}
              onClick={onClick}
              type={col.editable?.inputType}
              onFocus={onClick}
              onChange={val => isNaN(val.replace(',', '.')) ? null : onCellChange(val)}
              onBlur={() => onBlur()}
              value={value}
              prefix={col.editable?.prefix}
              suffix={
                hasChange || mustBeValue ?
                  <Tooltip title={<>
                    {hasChange && <span dangerouslySetInnerHTML={{ __html: i18n.t('lbl.prevValue', { value: item[col.key] || `(${i18n.t('lbl.emptyValue')})` }) }} />}
                    {mustBeValue && <div style={{ color: 'red' }}>{i18n.t('msg.required')}</div>}
                  </>}>
                    <Icon type="info-circle" />
                  </Tooltip>
                  :
                  <span />
              }
            />
          </Form.Item>
        )

      case controlTypes.CheckBox:
        return (
          <Checkbox
            checked={value}
            onClick={onClick}
            onFocus={onClick}
            onChange={onCheckboxChange}
          />
        );


      default: return { error: 'dev.message.errorEditableTableColumnType' }
    }
  }

  let cell = getValue({ item, i });
  if (!!col.editable) {
    // if (col.render) throw new Error(i18n.t('dev.message.errorEditableTableColumn', { errorSource: col.label, errorKey: col.key }));
    cell = renderElement();
  }
  if (cell?.error) throw new Error(i18n.t(cell.error, { errorSource: col.label, errorKey: col.key, type: col.editable?.type }));

  return (cell)
}

const mapStateToProps = ({ common }) => ({
  datatableInline: common.datatableInline
});
const mapDispatchToProps = (dispatch) => ({
  setDatatableInlineValue: (data) => dispatch(actions.setDatatableInlineValue({ data })),
  setDatatableBulkValue: (data, index) => dispatch(actions.setDatatableBulkValue({ data }, index)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cell);