import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { Card, Form, Row, Col, Checkbox, Button, Icon, Popconfirm, Tabs } from 'antd';
import { Input, InputNumber, Select } from 'components/UIComponents/NetbankaComponents';
import { Loading } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { controlTypes } from 'lib/constants';
import ExchangeRates from 'components/UIComponents/GeneralParameters/ExChangeRates';

class Parameters extends Component {
  constructor(props) {
    super(props);
    this.hasAccessPUT = props.auth.data.accessRights.find(x => x.endPoint === 'GeneralParameters' && x.method === 'PUT') ? true : false
    this.state = {
      loading: false,
      saving: false,
      data: null,
      passwordsVisible: {},
      editingPassword: {}
    };
  };

  componentDidUpdate(prevProps) {
    if (this.props.generalParameters.list.data && prevProps.generalParameters.list.data !== this.props.generalParameters.list.data) {
      this.setState({ data: _.cloneDeep(this.props.generalParameters.list.data) });
    }
  }

  componentDidMount() {
    this.props.getGeneralParameters();
  };

  update = (key, value, definitionId) => {
    let { data, passwordsVisible, editingPassword } = this.state;
    let item = data.find(x => x.definitionId === definitionId);
    item[key] = value;
    if (item.controlType === controlTypes.Password && !passwordsVisible[item.definitionId]) { passwordsVisible[item.definitionId] = "password"; editingPassword[item.definitionId] = true }
    this.setState({ data, passwordsVisible, editingPassword });
  };

  save = async () => {
    const { data } = this.state;
    let tokenExpirationTime = data.find(x => x.name === "TokenExpirationTime").value;
    if (tokenExpirationTime) {
      var tokenExpirationTimeNumber = parseInt(tokenExpirationTime);
      let _passwords = data.filter(x => x.controlType === controlTypes.Password);
      _passwords.map(x => x.value = x.value === "********" ? null : x.value);
      localStorage.setItem("expirationTime", tokenExpirationTimeNumber)

      if (data.find(x => x.name === "SourceType").value === "2") //Manuel İşlem ise
        await this.exchangeRates ? this.exchangeRates.save(() => this.saveData(data)) : this.saveData(data);
      else {
        this.saveData(data);
      }
    }
  };

  saveData = (data) => {
    this.props.updateGeneralParameters(data, (res) => {
      res && window.location.replace('/home');
    });
  }

  getInput = (item) => {
    const { data, editingPassword, passwordsVisible } = this.state
    const _stateDataItem = data.find(x => x.definitionId === item.definitionId);
    switch (item.controlType) {
      case controlTypes.TextBox:
        return (<Input disabled={!this.hasAccessPUT} className={`#${item.description}`} label={item.description} info={item.info} onChange={(e) => this.update('value', e, item.definitionId)} />);
      case controlTypes.CheckBox:
        return (<Checkbox disabled={!this.hasAccessPUT} className={`#${item.description}`} checked={item.value === 'true'} onChange={(e) => this.update('value', e.target.checked.toString(), item.definitionId)}>{item.description}</Checkbox>);
      case controlTypes.Number:
        return (
          <Form.Item
            validateStatus={_stateDataItem && !_stateDataItem.value ? 'error' : null}
            help={_stateDataItem && !_stateDataItem.value ? i18n.t('msg.required') : ''}
          >
            <InputNumber
              value={parseInt(item.value || 0)}
              required={item.required}
              min={item.minValue}
              max={item.maxValue || 999999}
              disabled={!this.hasAccessPUT}
              className={`#${item.description}`}
              label={item.description}
              info={item.info}
              onChange={(e) => this.update('value', e, item.definitionId)}
              id={item.definitionId}
            />
          </Form.Item>
        );
      case controlTypes.Password:
        return (
          <Popconfirm
            visible={editingPassword[item.definitionId]}
            cancelText={i18n.t('btn.cancel')}
            okText={i18n.t('btn.continue')}
            onCancel={() => {
              editingPassword[item.definitionId] = false;
              this.setState({ editingPassword }, () => {
                editingPassword[item.definitionId] = undefined;
                this.setState({ editingPassword })
              })
            }}
            onConfirm={() => {
              if (_stateDataItem.value === null) this.update('value', '', item.definitionId)
              editingPassword[item.definitionId] = false;
              this.password[item.id].input.focus();
              this.setState({ editingPassword })
            }}
            title={i18n.t('msg.removingOldPassword')}
          >
            <Input
              ref={el => this.password = el}
              id={item.id}
              disabled={!this.hasAccessPUT}
              label={item.description}
              className={`#${item.description}`}
              value={_stateDataItem.value !== null ? _stateDataItem.value : item.value === null ? '********' : null}
              type={passwordsVisible[item.definitionId] || "password"}
              suffix={
                (passwordsVisible[item.definitionId] && _stateDataItem.value !== '') &&
                <Icon style={{ color: '#aaa' }} onClick={() => {
                  passwordsVisible[item.definitionId] = passwordsVisible[item.definitionId] === "password" ? "text" : "password"
                  this.setState({ passwordsVisible })
                }}
                  type="eye" />}
              info={!(passwordsVisible[item.definitionId] && _stateDataItem.value !== '') && i18n.t('msg.passwordTooltip')
              }
              onClick={() => { if (editingPassword[item.definitionId] === undefined) { editingPassword[item.definitionId] = true; this.setState({ editingPassword }) } }}
              onChange={(e) => editingPassword[item.definitionId] === false ? this.update('value', e, item.definitionId) : null}
            />
          </Popconfirm>
        );
      case controlTypes.Select:
        return (
          <Select
            className={`#${item.description}`}
            label={item.description}
            options={item.items}
            optVal="id"
            optName="name"
            onChange={(e) => this.update('value', e, item.definitionId)}
          />
        )
      default:
        return <></>;
    }
  };

  selectedTabPane = (item) => {
    switch (item.controlType) {
      case controlTypes.Select:
        return (
          <Select
            className={`#${item.description}`}
            label={item.description}
            options={item.items}
            optVal="id"
            optName="name"
            onChange={e => this.update('value', e, item.definitionId)}
          />
        )
      default: return null;
    }
  }

  render() {
    let { data } = this.state;
    let { generalParameters } = this.props;
    let { getFieldDecorator } = this.props.form;
    const selectedSourceType = data && data.find(x => x.name === "SourceType");

    return (
      <>
        <Tabs defaultActiveKey="main">
          <Tabs.TabPane tab="ERP/Muhasebe Programı Parametreleri" key="main">
            <div className="page-content">
              <Card title={i18n.t('lbl.parameters')}>
                <Loading loading={generalParameters.list && generalParameters.list.loading}>
                  <>
                    {
                      data && data.length > 0 &&
                      <Form>
                        <Row gutter={20}>
                          {data
                            .filter(x => x.name !== "SourceType")
                            .map((x, i) => (
                              <Col key={x.definitionId} xs={24} sm={12}>
                                {
                                  x.controlType === controlTypes.Number ?
                                    this.getInput(x)
                                    :
                                    <Form.Item>
                                      {
                                        getFieldDecorator(x.name, {
                                          rules: [{ required: x.required, message: i18n.t('msg.required') }],
                                          initialValue: x.controlType === controlTypes.CheckBox ? x.value === 'true' ? true : false : x.value
                                        })(
                                          this.getInput(x)
                                        )}
                                    </Form.Item>
                                }
                              </Col>
                            ))}
                        </Row>
                      </Form>
                    }
                  </>
                </Loading>
                <Row>
                  <Col span={24} className="text-right">
                    <Button disabled={!this.hasAccessPUT} icon="save" className="success #save btn-collapse" loading={generalParameters.single.saving} onClick={this.save}>
                      {i18n.t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Döviz Bilgileri" key="sourceType">
            <div className="page-content">
              <Card title={i18n.t('lbl.parameters')}>
                <Loading loading={generalParameters.list && generalParameters.list.loading}>
                  <>
                    {
                      data && data.length > 0 &&
                      <Form>
                        <Row gutter={20}>
                          {data.filter(x => x.name === "SourceType").map((x, i) => (
                            <Col key={x.definitionId} xs={24} sm={12}>
                              <Form.Item>
                                {
                                  getFieldDecorator(x.name, {
                                    rules: [{ required: x.required, message: i18n.t('msg.required') }],
                                    initialValue: x.value
                                  })(
                                    this.selectedTabPane(x, i)
                                  )}
                              </Form.Item>
                            </Col>
                          ))}
                        </Row>
                      </Form>
                    }
                    {
                      selectedSourceType &&
                      <ExchangeRates
                        sourceType={data.find(x => x.name === "SourceType")}
                        wrappedComponentRef={el => this.exchangeRates = el}
                      />
                    }
                  </>
                </Loading>
                <Row>
                  <Col span={24} className="text-right">
                    <Button disabled={!this.hasAccessPUT} icon="save" className="success #save btn-collapse" loading={generalParameters.single.saving} onClick={this.save}>
                      {i18n.t('btn.save')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  };
};

const mapStateToProps = ({ common, auth }) => ({
  auth,
  generalParameters: common.generalParameters,
  fixedExChangeTenantCurrencies: common.fixedExChangeTenantCurrencies,
  currencyRates: common.currencyRates,
  currencies: common.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getGeneralParameters: (filter) => dispatch(actions.getAll({ url: endpoints.tenant.generalParameters, key: 'generalParameters', filter })),
  getTenantCurrencies: (filter) => dispatch(actions.getAll({ url: endpoints.tenant.getTenantCurrencies, key: 'fixedExChangeTenantCurrencies', filter })),
  updateGeneralParameters: (data, callback) => dispatch(actions.put({ url: endpoints.tenant.generalParameters, key: 'generalParameters', data }, callback)),
  getCurrencyRates: (parameters) => dispatch(actions.get({ url: endpoints.rates.exchangeRates, parameters, key: 'currencyRates', })),
  getBankCurrencies: (data) => dispatch(actions.getWithPost({ url: endpoints.tenant.bankAccountsGetCurrencies, key: 'currencies', data })),
});
const ParametersForm = Form.create()(Parameters);
export default connect(mapStateToProps, mapDispatchToProps)(ParametersForm);