import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Button, Modal, Input } from 'antd';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import netekstreActions from 'store/actions/netekstre'
import authActions from 'store/actions/auth';

class ActionButtons extends Component {
  onClickButton = (e, isDeleteDialog, id, stateKey) => {
    e.stopPropagation();
    this.props.openDialog && this.props.openDialog(isDeleteDialog, id, stateKey)
  }

  onEdit = (e, row) => {
    e.stopPropagation();
    this.props.editUrl(row)
  }

  onEditClick = (e) => {
    const { item, mode, trigger, editUrl } = this.props;
    if (mode) {
      e.stopPropagation();
      trigger(item.Id)
    }
    else {
      if (typeof editUrl === 'function')
        this.onEdit(e, item)
      else
        return null
    }
  }

  resetPassword = (e) => {
    let _this = this;
    e.stopPropagation();
    Modal.confirm({
      title: i18n.t('msg.passwordResetConfirm'),
      onOk() {
        _this.props.resetPassword({ email: _this.props.item.Email });
      },
      okText: i18n.t('btn.yes'),
      cancelText: i18n.t('btn.no')
    });
  };

  getToken = (e) => {
    e.stopPropagation();
    let _this = this;
    this.props.getToken({ url: endpoints.tenant.tenantsAppToken(_this.props.item.id) }, (response) => {
      if (response)
        Modal.info({
          width: 500,
          title: response.token ? i18n.t('lbl.token') : <div className="text-center">{i18n.t('msg.tokenNotFound')}...</div>,
          content: (
            <div className="token-select">
              {response.token ?
                <Input.Group>
                  <Input
                    ref={el => _this.tokenInput = el}
                    value={response.token}
                    addonAfter={
                      <Button
                        type="primary"
                        onClick={() => { _this.tokenInput.select(); document.execCommand("copy"); }}
                      >
                        {i18n.t('btn.copy')}
                      </Button>
                    }
                  />
                </Input.Group>
                :
                null
              }
            </div>
          ),
        });
    });
  };

  render() {
    let { accessRights } = this.props.auth.data;
    let { url, editUrl, hasDelete, item, disabled, size, additionalButtons, stateKey } = this.props;
    return (
      <Button.Group className="action-buttons">
        {(hasDelete && accessRights.find(x => x.endPoint === url && x.method === 'DELETE') && !(url === 'TenantCategories' && (item.id === 1 || item.Id === 1))) &&
          <Tooltip placement="bottom" title={i18n.t('btn.delete')}>
            <Button className="#delete" icon="delete" size="small" onClick={(e) => this.onClickButton(e, true, item.id || item.Id, stateKey)} />
          </Tooltip>
        }
        {
          (editUrl && accessRights.find(x => x.endPoint === url && x.method === 'PUT') && (!(url === 'TenantCategories' && item.id === 1))) &&
          <Tooltip placement="bottom" title={i18n.t('btn.edit')}>
            <Button className="#edit" icon="edit" size={size ? size : 'small'} disabled={disabled} onClick={this.onEditClick} />
          </Tooltip>
        }
        {(url === 'Users' && accessRights.find(x => x.endPoint === 'Passwords' && x.method === 'GET')) &&
          <Tooltip placement="bottom" title={i18n.t('btn.resetPassword')}>
            <Button className="#resetPassword" size="small" onClick={this.resetPassword}>
              <i className="fas fa-key" />
            </Button>
          </Tooltip>
        }
        {
          url === 'Tenants' &&
          <Tooltip placement="bottom" title="Token" trigger={item.canUseErpService ? ['hover'] : ['click']}>
            <Button className="#getToken" size="small" onClick={this.getToken} disabled={item.isActive === false}>
              <i className="fab fa-gg-circle" />
            </Button>
          </Tooltip>
        }
        {
          additionalButtons &&
          <>{additionalButtons(item)}</>
        }
      </Button.Group>
    );
  };
};

const mapStateToProps = ({ auth, panel, common }) => ({ auth, panel, currentModule: common.currentModule });
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(authActions.forgotPassword({ data })),
  getToken: (payload, callback) => dispatch(netekstreActions.get(payload, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(ActionButtons);