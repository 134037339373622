import { removeSpaces } from "helpers";

export class CommRateModel {
  constructor(data = null) {
    this.id = data?.id;
    this.installment = data?.installment;
    this.commRate = data?.commRate || 0;
    this.serviceCommRate = data?.serviceCommRate || 0;
    this.maxInstallmentLimit = data?.maxInstallmentLimit || 0;
    this.minInstallmentLimit = data?.minInstallmentLimit || 0;
    this.plusInstallment = data?.plusInstallment || 0;
    this.plusInstallmentLowerLimit = data?.plusInstallmentLowerLimit || 0;
    this.plusInstallmentOverLimit = data?.plusInstallmentOverLimit || 0;
    this.paymentDeferral = data?.paymentDeferral || 0;
    this.paymentDeferralLowerLimit = data?.paymentDeferralLowerLimit || 0;
    this.paymentDeferralOverLimit = data?.paymentDeferralOverLimit || 0;
    this.isActive = data?.isActive || false;
  };
};

export class PosModel {
  constructor(data = null) {
    this.id = data?.id;
    this.posId = data?.posId;
    this.name = data?.name || '';
    this.description = data?.description || '';
    this.currencyDefinitionId = data?.currencyDefinitionId;
    this.logoId = data?.logoId;
    this.useCvv = (data?.useCvv !== null && data?.useCvv !== undefined) ? data.useCvv : true;
    this.is3DActive = (data?.is3DActive !== null && data?.is3DActive !== undefined) ? data.is3DActive : true;
    this.threeDSecureTypeId = data?.threeDSecureTypeId || 0;
    this.isActive = (data?.isActive !== null && data?.isActive !== undefined) ? data.isActive : true;
    this.posParameters = data?.posParameters || [];
    this.isDefaultPos = data?.isDefaultPos;
  };
};

export class OrderBaseModel {
  constructor(data = null) {
    this.amount = data?.amount || '';
    this.name = data?.name || '';
    this.description = data?.description || '';
    this.paymentSetId = data?.paymentSetId;
    this.languageId = data?.languageId;
    this.currencyId = data?.currencyId;
    this.paymentTab = data?.paymentTab;
    this.userId = data?.userId;
  };
};
export class EmailOrderModel extends OrderBaseModel {
  constructor(data = null) {
    super(data);
    this.expire = data?.expire;
    this.frequencyType = data?.frequencyType;
    this.isDisposable = data?.isDisposable || false;
    this.referenceNumber = data?.referenceNumber;
    this.phone = data?.phone;
    this.email = data?.email;
  };
};
export class SMSOrderModel extends OrderBaseModel {
  constructor(data = null) {
    super(data);
    this.expire = data?.expire;
    this.frequencyType = data?.frequencyType;
    this.isDisposable = data?.isDisposable || false;
    this.referenceNumber = data?.referenceNumber;
    this.phone = data?.phone;
    this.email = data?.email;
  };
};
export class QrOrderModel extends OrderBaseModel {
  constructor(data = null) {
    super(data);
  };
};

export class CreditCardOrderModel extends OrderBaseModel {
  constructor(data = null) {
    super(data);
    this.tenantPosId = data?.tenantPosId;
    this.phone = data?.phone;
    this.email = data?.email;
    this.tckn = data?.tckn;
    this.isAgreementConfirm = data?.isAgreementConfirm || true;
    this.impersonated = data?.impersonated || false;
    this.installment = data?.installment;
    this.paymentSetPosId = data?.paymentSetPosId;
    this.use3D = data?.use3D || false;
    this.posCurrencyDefinitionId = data?.posCurrencyDefinitionId;
    this.currencyDefinitionId = data?.currencyDefinitionId;
    this.cardNumber = data?.cardNumber || '';
    this.paymentSessionId = data?.paymentSessionId;
    this.impersonatedUserId = data?.impersonatedUserId;


    this.cardHolderFullName = data?.cardHolderFullName || '';
    this.cvv = data?.cvv || '';
    this.expiry = data?.expiry || '';
  };
};

export class DefaultPosModel {
  constructor(data = null) {
    this.tenantPosId = data?.tenantPosId;
    this.defaultPosServiceCommRate = data?.defaultPosServiceCommRate;
    this.defaultPosCommRate = data?.defaultPosCommRate;
    this.defaultMaxInstallmentLimit = data?.defaultMaxInstallmentLimit;
    this.defaultMinInstallmentLimit = data?.defaultMinInstallmentLimit;
  };
};

export class PaymentGetModel {
  constructor(data = null) {
    this.gatewayAuthToken = data?.gatewayAuthToken;
    this.gatewayHost = data?.gatewayHost;
    this.paymentParameters = new PaymentPageSettingModel(data?.paymentParameters);
    this.paymentSessionId = data?.paymentSessionId;
    this.userName = data?.userName;
  };
};

export class PaymentPostModel {
  constructor(data = null) {
    this.tenantPosId = data?.tenantPosId;
    this.name = data?.name;
    this.phone = data?.phone;
    this.email = data?.email;
    this.tckn = data?.tckn;
    this.description = data?.description;
    this.amount = data?.amount;
    this.isAgreementConfirm = data?.isAgreementConfirm || false;
    this.installment = data?.installment;
    this.paymentSetPosId = data?.paymentSetPosId;
    this.use3D = data?.use3D || false;
    this.posCurrencyDefinitionId = data?.posCurrencyDefinitionId;
    this.currencyDefinitionId = data?.currencyDefinitionId;
    this.cardNumber = data?.cardNumber;
    this.paymentSessionId = data?.paymentSessionId;
    this.userId = data?.userId;
    this.paymentTab = data?.paymentTab;
  };
};

export class PGWPrepareModel {
  constructor(data = null) {
    this.cardNumber = removeSpaces(data?.cardNumber);
    this.cvv = data?.cvv;
    this.expireMonth = data?.expiry?.slice(0, 2);
    this.expireYear = new Date().getFullYear().toString().substr(0,2) + data?.expiry?.slice(3, 5);
    this.cardHolderName = data?.cardHolderFullName;
    this.saveCreditCard = data?.saveCreditCard || false;
    this.cardPoint = data?.cardPoint || 0;
  };
};

export class PaymentPageSettingModel {
  constructor(data = null) {
    this.isShowTCKN = data?.isShowTCKN || false;
    this.isShowDescription = data?.isShowDescription || false;
    this.isShowPhone = data?.isShowPhone || false;
    this.isShowEmail = data?.isShowEmail || false;
    this.isShowVirtualKeyboard = data?.isShowVirtualKeyboard || false;
    this.isForcedTCKN = data?.isForcedTCKN || false;
    this.isForcedDescription = data?.isForcedDescription || false;
    this.isForcedPhone = data?.isForcedPhone || false;
    this.isForcedEmail = data?.isForcedEmail || false;
    this.isShowPaymentForm = data?.isShowPaymentForm || false;
    this.isShowAgreement = data?.isShowAgreement || false;
    this.isForcedAgreement = data?.isForcedAgreement || false;
    this.paymentFormTopDescription = data?.paymentFormTopDescription;
    this.agreementText = data?.agreementText;
  };
}
