import React, { useState } from 'react'
import { Steps } from "antd";
import i18n from 'plugins/i18n';
import { ContentViewer } from "/";
import { stepsStatuses } from 'lib/constants';
import { KrediContent } from 'components/UIComponents';

const Home = () => {
  const [current, setCurrent] = useState({ step: 0, parameters: {} });


  const stepChange = (direction, parameters) => {
    if (direction === "+" && current.step < 5) setCurrent((current) => ({ step: current.step + 1, parameters }));
    if (direction === "-" && current.step > 0) setCurrent((current) => ({ step: current.step - 1, parameters }));
  }

  const getStatus = index => {
    switch (index) {
      case current.step: return stepsStatuses.process;
      case current.step > index: return stepsStatuses.finish;
      case current.step < index: return stepsStatuses.wait;
      default: return null;
    }
  }

  return (
    <KrediContent>
      <Steps type="navigation" current={current.step} className={`steps-navigation ${current.step > 3 ? "none" : ""}`}>
        <Steps.Step {...{ status: getStatus(0), title: i18n.t('lbl.signatoryInfo') }} />
        <Steps.Step {...{ status: getStatus(1), title: i18n.t('lbl.tenantInfo') }} />
        <Steps.Step {...{ status: getStatus(2), title: i18n.t('lbl.contractInfo') }} />
        <Steps.Step {...{ status: getStatus(3), title: i18n.t('lbl.passwordSettings') }} />
      </Steps>
      <ContentViewer {...{ step: current.step, onChange: stepChange, parameters: current.parameters }} />
    </KrediContent>
  )
}

export default Home
