import endpoints from 'config/endpoints';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import actions from 'store/actions/netahsilat';
import { Button, Row, Col, Card, Divider, Tag } from 'antd';
import i18n from 'plugins/i18n';
import { formatDate, formatMoney } from 'helpers';
import ReactToPrint from 'react-to-print';
import { Loading } from 'components/UIComponents';

class Detail extends Component {
  state = {};

  static getDerivedStateFromProps(props) {
    if (props.dialogClosing) props.onDialogClose()
    return null;
  };

  componentDidMount() {
    this.props.getNetLinkTransaction(this.props.id);
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  getFormattedDate = date => formatDate(date, null, null, null, false);

  getCurrency = currency => this.props.currencies.data.find(x => x.id === currency)?.name || 'TRY';

  renderDialogButtons = () => {
    const { netlinkTransactions, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={netlinkTransactions.loading} className="mr-10 #cancel" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>,
      netlinkTransactions.saving,
      netlinkTransactions.loading
    )
  }

  getTag = status => {
    const colors = {
      1: 'green',
      2: 'red',
      4: 'orange',
      5: 'blue'
    }
    const getStatusName = () => this.props.netlinkTransactionStatuses.data.find(x => x.id === status)?.name || '';

    return <span className="card-title-tags pull-right"><Tag className={`${colors[status]} xs`}>{getStatusName()}</Tag></span>
  }

  render() {
    const { netlinkTransactions } = this.props;
    this.renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={netlinkTransactions.loading}>
          <Card size="small" title={<div>{i18n.t('lbl.operationStatus')} {this.getTag(netlinkTransactions.data?.status)}</div>} className="detail-cards has-child">
            <Row gutter={10}>
              <Col xs={12} className="text-semibold">  {i18n.t('lbl.operationType')} </Col>
              <Col xs={12}>{netlinkTransactions.data?.payLinkSendTypeName} </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
              <Col xs={12} className="text-semibold">  {i18n.t('lbl.requestBy')} </Col>
              <Col xs={12}>{netlinkTransactions.data?.insertedUserName} </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
              <Col xs={12} className="text-semibold">  {i18n.t('lbl.lastPaymentDate')} </Col>
              <Col xs={12}>{this.getFormattedDate(netlinkTransactions.data?.expireDate)} </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
              <Col xs={12} className="text-semibold">  {i18n.t('lbl.amount')} </Col>
              <Col xs={12}>{formatMoney(netlinkTransactions.data?.pureAmount)} {this.getCurrency(netlinkTransactions.data?.currencyId)}  </Col>
            </Row>
            <Divider />
            <Row gutter={10}>
              <Col xs={12} className="text-semibold">  {i18n.t('lbl.requestDate')} </Col>
              <Col xs={12}>{this.getFormattedDate(netlinkTransactions.data?.requestDate)} </Col>
            </Row>

            <Card size="small" className="detail-cards mt-20 mb-10">
              <Row gutter={10}>
                <Col xs={12} className="text-bold">  {i18n.t('lbl.consumerInfo')} </Col>
                <Divider />
              </Row>
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.nameSurname')} </Col>
                <Col xs={12}>{netlinkTransactions.data?.name} </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.email')} </Col>
                <Col xs={12}>{netlinkTransactions.data?.email} </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.phone')} </Col>
                <Col xs={12}>{netlinkTransactions.data?.phone} </Col>
              </Row>
            </Card>

            {
              netlinkTransactions.data?.payLinkSendType === 3 &&
              <Card size="small" className="detail-cards mt-20 mb-10" ref={el => this.printScope = el}>
                <Row gutter={10}>
                  <Col xs={24} className="text-bold">  {i18n.t('lbl.qrCode')} </Col>
                  <Divider />
                  <Col xs={24} className="text-center"><img id="qr-print" width={140} height={140} src={netlinkTransactions.data?.qrCodeContent || ''} alt="qr" /></Col>
                  <div id="hide-to-print">
                    <Divider />
                    <Col xs={24} className="text-center mt-5 mb-5">
                      <ReactToPrint
                        trigger={() => <Button disabled={netlinkTransactions.loading} className="#print blue" icon="printer">{i18n.t('btn.print')}</Button>}
                        content={() => this.printScope}
                        pageStyle={`@page { size: auto;  margin: 0mm; } @media print { body { padding: 40px; -webkit-print-color-adjust: exact; } #hide-to-print { display:none } #qr-print { width: 300px; height: 300px } }`}
                      />
                    </Col>
                  </div>
                </Row>
              </Card>
            }

          </Card>
        </Loading>
      </div>
    )
  }
}
const mapStateToProps = ({ netahsilat }) => ({
  netlinkTransactions: netahsilat.netlinkTransactions.single,
  netlinkTransactionStatuses: netahsilat.filter.netlinkTransactionStatuses,
  currencies: netahsilat.filter.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getNetLinkTransaction: (id, callback) => dispatch(actions.get({ url: endpoints.netahsilat.netlinkTransaction, key: 'netlinkTransactions', id }, callback)),
  clearState: () => dispatch(actions.clearState("netlinkTransactions")),
});
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
