import React from 'react';

export { default as chartOptions } from './chartOptions';
export { default as defaultFilters } from './defaultFilters';
export { default as textEditorOptions } from './textEditorOptions';

export * from './netahsilat';

export const httpMethods = {
  get: "get",
  getAll: "getAll",
  post: "post",
  put: "put",
  delete: "delete",
  patch: "patch",
  getWithPost: "getWithPost"
}

export const stepsStatuses = {
  finish: 'finish',
  process: 'process',
  wait: 'wait',
  error: 'error'
}

export const defaultValues = {
  input: '',
  select: undefined,
  multiSelect: undefined,
  date: null,
  search: undefined,
  number: null,
  slider: [0, 100]
};

export const ruleTypes = {
  system: 1,
  custom: 2,
}

export const levels = [
  { id: 1, name: 'partnerFirm' },
  { id: 2, name: 'groupFirm' },
  { id: 3, name: 'firm' },
];

export const typeCodeLevels = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
];

export const requestTypes = [
  { value: 'all', name: 'Hepsi' },
  { value: 'GET', name: 'Listeleme' },
  { value: 'POST', name: 'Ekleme' },
  { value: 'PUT', name: 'Güncelleme' },
  { value: 'DELETE', name: 'Silme' }
];

export const statusTypes = [
  { id: 1, name: 'active' },
  { id: 2, name: 'inactive' },
];

export const status = [
  { value: 'true', name: 'active' },
  { value: 'false', name: 'inactive' },
];

export const reportTypes = [
  { value: '0', name: 'daily', mode: 'date' },
  { value: '1', name: 'monthly', mode: 'month' },
  { value: '2', name: 'yearly', mode: 'year' },
];

export const reportTypesPosrapor = [
  { value: '1', name: 'daily', mode: 'date' },
  { value: '2', name: 'monthly', mode: 'month' },
  { value: '3', name: 'yearly', mode: 'year' },
];

export const reportDisplayTypes = [
  { value: '0', name: 'bankBased' },
  { value: '1', name: 'accountBased' },
];

export const reportDisplayTypesPosrapor = [
  { value: '1', name: 'bankBased' },
  { value: '2', name: 'posBased' },
];

export const durationTypes = [
  // { value: 1, name: i18n.t('lbl.daily'), mode: 'date', description: i18n.t('lbl.displaysLast30DaysData') },
  { value: 1, name: 'daily', mode: 'date', description: "displaysLast30DaysData" },
  { value: 2, name: 'monthly', mode: 'month', description: "displaysLast12MonthsData" },
  { value: 3, name: 'yearly', mode: 'year', description: "displaysLast5YearsData" },
];

export const categoryTypesLookup = [
  { id: 1, value: 'IncomingTransaction', name: 'incoming' },
  { id: 2, value: 'OutgoingTransaction', name: 'outgoing' },
];

export const workTypes = [
  { id: 1, name: 'saved' },
  { id: 2, name: 'unSaved' },
];

export const contractualTypes = [
  { id: 1, name: 'withInvoice' },
  { id: 2, name: 'withoutInvoice' },
];

export const transferStatusIcons = {
  Completed: <i className="icon-completed fas fa-check-circle" />,
  ToBeTransferred: <i className="icon-to-be-transferred fas fa-arrow-circle-up" />,
  Fail: <i className="icon-fail fas fa-times-circle" />,
  PriorityFail: <i className="icon-priority-fail fas fa-clone" />,
  NotMapping: <i className="icon-not-mapping fas fa-question-circle" />,
  WaitApproval: <i className="icon-wait-approval fas fa-info-circle" />,
  DontBeTransferred: <i className="icon-dont-be-transferred fas fa-exclamation-circle" />,
  Split: <i className="icon-split fas fa-code-branch" />,
  MultipleCurrentAccount: <i className="icon-multiple fas fa-layer-group"></i>
}

export const splitIcons = {
  Child: <i className="icon-not-mapping fas fa-link" />,
  ToBeSplit: <i className="fas fa-cut" />,
  Split: <i className="icon-completed fas fa-check-double" />,
};

export const currentAccountTypesLookup = [
  { name: 'person', value: 1 },
  { name: 'company', value: 2 },
];

export const dialogTypes = {
  Modal: "Modal",
  Drawer: "Drawer"
}

export const drawerPositions = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
};

export const eventEmitters = {
  openDialog: "openDialog",
  deleteItem: "deleteItem"
};

export const currentAccountTypes = {
  Person: 1,
  Company: 2
};

export const regexTypes = {
  TypeCode1: 1,
  TypeCode2: 2,
  Bank: 3,
  Desc: 4,
  Iban: 5,
  Tckn: 6,
  Vkn: 7,
  Tutar: 8,
  BankaHesap: 9,
  IlgiliKisi: 10
}

export const categoryTypes = {
  IncomingTransaction: 1,
  OutgoingTransaction: 2,
  Role: 3,
  Description: 4
}

export const transferStatuses = {
  DontBeTransferred: 0,
  Completed: 1,
  Fail: 2,
  PriorityFail: 3,
  NotMapping: 4,
  ToBeTransferred: 5,
  WaitApproval: 6,
  ToBeSplit: 7,
  Split: 8,
  MultipleCurrentAccount: 9
}

export const modelStates = {
  Added: 1,
  Modified: 2,
  Delete: 3,
}

export const regexFilters = {
  1: 'equal',
  2: 'contains',
  3: 'startsWith',
  4: 'endsWith',
  5: 'greater',
  6: 'less',
  7: 'custom',
  8: 'lessEqual',
  9: 'greaterEqual',
}

export const controlTypes = {
  TextBox: 1,
  CheckBox: 2,
  Select: 3,
  RadioButton: 4,
  MultiTextBox: 5,
  Password: 6,
  Number: 7
}
export const projectTypes = {
  netfinans: "netfinans",
  settings: "settings"
}

export const baseUrls = {
  nte: "nteUrl",
  tenant: "tenantUrl",
  dbs: "dbsUrl",
  posrapor: "posraporUrl",
  job: "jobUrl",
  netahsilat: 'nthUrl'

}

export const posTypes = [
  { id: 1, name: "physical" },
  { id: 2, name: "virtual" }
]

export const vendorTypes = [
  { id: 1, name: "vendor" },
  { id: 2, name: "subVendor" }
]

export const currencySymbols = {
  TRY: "₺",
  USD: "$",
  EUR: "€",
  EURO: "€",
  GBP: "£",
  CHF: "FR",
  CAD: "C$",
  SEK: "KR",
  JPY: "¥"
};

export const declaredBankEftCodes = {
  Anadolubank: "0135"
}

export const moduleTypes = {
  1: "Ortak",
  2: "NTE",
  3: "DBS",
  4: "TOS",
  5: "POS",
  6: "NTH",
  7: "ONK",
  8: "OKR"
}

export const moduleTypesDefinitionsEnum = {
  "common": "Ortak",
  "netekstre": "NTE",
  "posrapor": "POS",
  "netahsilat": "NTH",
  "dbs": "DBS",
  "tos": "TOS",
  "okr": "OKR",
  "cek": "CEK",
}

export const userRepresentativeTypeBoolean = {
  false: 1,
  true: 6,
}

export const userRepresentativeTypeInt = {
  1: false,
  6: true,
}

export const documentTypes = [
  { id: 1, name: "commercialRegistryNewspaper", attentionLength: 3 },
  { id: 2, name: "taxBoard", attentionLength: 2 },
  { id: 3, name: "signatureCirculars", attentionLength: 7 },
  { id: 4, name: "identityCard", attentionLength: 1 }
];

export const documentAttentions = [
  "companyDocuments",
  "documentsWillBeConfirmedECozum",
  "uploadableDocTypes",
  "docsAvailableAfterConfirmation"
];

export const documentApproveStatuses = {
  1: "documentNotUploaded",
  2: "waitingForApproval",
  3: "documentsIncorrectorMissing",
  4: "approved",
  5: "notApproved"
};

export const moduleTypesEnum = {
  "ORTAK": "Ortak",
  "NTE": "NTE",
  "DBS": "DBS",
  "TOS": "TOS",
  "PSR": "PSR",
  "NTH": "NTH",
  "OKR": "OKR",
  "CEK": "CEK",
}

export const tenantLevels = {
  siteOwner: 0,
  partner: 1,
  group: 2,
  tenant: 3
}

export const nthTransactionStatuses = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  unknown: 'unknown'
}

export const transactionStatusToStatusType = {
  1: nthTransactionStatuses.success,
  2: nthTransactionStatuses.error,
  3: nthTransactionStatuses.error,
  4: nthTransactionStatuses.warning,
  5: nthTransactionStatuses.unknown,
}