import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Modal, Tooltip, Radio } from 'antd';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { formatCurrency, formatChartData, convertFieldsToCamelize } from 'helpers';
import { Loading } from 'components/UIComponents';
import Fade from 'react-reveal/Fade';
import i18n from 'plugins/i18n';
import 'components/UIComponents/RoundedBars'
import { chartTypes } from 'models';
import { chartOptions } from 'lib/constants';
import utils from 'lib';
const actions = {};

class BarChart extends Component {
  state = {
    visible: false,
    dataIndex: undefined,
    modalData: { data: [], title: "" },
    wait: this.props.getWithQueue,
    Day: this.props.range?.defaultSelected
  }


  componentDidMount() {
    if (!this.props.getWithQueue)
      this.getChartData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.currencyCode !== prevProps.currencyCode || this.props.multiTenant !== prevProps.multiTenant || this.props.isCumulative !== prevProps.isCumulative) {
      this.getChartData();
    }
  };

  getCharts = (e) => {
    this.setState({ Day: parseInt(e.target.value) }, () => {
      this.getChartData();
    });
  }

  getChartData = async () => {
    const { currencyCode, multiTenant, isCumulative, endpoint, method, currentModule } = this.props;
    const queryPayload = { isCumulative, currencyCode };
    if(this.state.Day)
      queryPayload.Day = this.state.Day;
    const args = [currentModule, method, endpoint, { currencyCode, getMultiTenant: multiTenant }, queryPayload]

    return new Promise(async (resolve) => {
      this.setState({ wait: false });
      if (!actions[currentModule]) {
        const _module = await import(`store/actions/${currentModule}`);
        actions[currentModule] = _module.default;
        this.props.getChartData(...args, resolve);
      }
      else {
        this.props.getChartData(...args, resolve);
      }
    })
  }

  getBankName = (bankEftCode) => {
    const { multiTenant } = this.props;
    return utils.common.getBankName({ data: { code: bankEftCode }, dataField: 'code', listField: multiTenant ? 'eftCode' : 'bankEftCode', listKeys: 'tenantBanks', className: "medium" })
  };

  onLineClick = (line) => {
    const { multiTenant } = this.props;
    const _dataIndex = this.state.dataIndex;
    const data = _.cloneDeep(this.props.barChartData.single.data);

    if (multiTenant) {
      line = line[0];
      const hasData = data.bankDetails && data.bankDetails.length > 0;
      if (hasData) {
        const datasetIndex = line ? line._datasetIndex : 0;
        const dataIndex = line ? line._index : _dataIndex;

        if (data.bankDetails[dataIndex]) {
          let modalData = {
            title: this.getBankName(JSON.parse(data.series[datasetIndex].bankEftCodes)[dataIndex]),
            data: data.bankDetails[dataIndex].details.sort((a, b) => a.name.localeCompare(b.name)).map(x => { return { tenantName: x.name, data: x.data === null ? '--' : parseFloat(x.data) } })
          }
          this.setState({ visible: true, modalData });
        }
      }
    }
  }

  render() {
    const { data, loading } = this.props.barChartData.single;
    const { multiTenant, currencyCode, auth, title, accessRight, className, range } = this.props;
    const { modalData, dataIndex, wait, Day } = this.state;
    const chartData = data ? _.cloneDeep(formatChartData({ series: data.series, labels: data.xAxis, type: chartTypes.barChart })) : {};

    return (
      <React.Fragment>
        <Row gutter={20} className={`${className}`}>
          <Col span={24} sm={12}>
            <h1 className="title">{title}</h1>
          </Col>
          <Col xs={24} sm={12}>
            <Radio.Group className="pull-right" buttonStyle="solid" value={parseInt(Day)} onChange={this.getCharts}>
              {
                range?.ranges.map(x => (
                  <Radio.Button key={x.uniqueName} value={x.value} className={x.uniqueName}>{x.title}</Radio.Button>
                ))
              }
            </Radio.Group>
          </Col>
          <Col span={24} className="mb-50">
            <Card bodyStyle={{ height: '300px', paddingLeft: 0 }}>
              <Loading loading={loading || wait} className="loading-card">
                {
                  (!chartData || chartData === {} || (accessRight && !auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method))) ?
                    <div className="no-access-right">
                      <i className="fas fa-exclamation-triangle"></i>
                      {i18n.t('msg.noAccessRightChart')}
                    </div>
                    :
                    <React.Fragment>
                      <Bar ref="barChart" getElementAtEvent={this.onLineClick} data={chartData} options={chartOptions.barChart({ multiTenant, dataIndex, component: this })} />
                    </React.Fragment>
                }
              </Loading>
            </Card>
          </Col>
        </Row>
        <Modal
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          centered
          title={<div className="text-center">{modalData.title}</div>}
        >
          <Fade cascade bottom>
            <div className="statement-container">
              {
                modalData.data.sort((a, b) => a.tenantName.localeCompare(b.tenantName)).map((x, i) => (
                  <div className="statement-row" key={i}>
                    <div>
                      <Tooltip placement="top" title={x.tenantName}>
                        {x.tenantName}
                      </Tooltip>
                    </div>
                    <div>{x.data === '--' ? x.data : formatCurrency({ data: x.data, currencyCode, withColor: true })}</div>
                  </div>
                ))
              }
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
    );
  };
};

const mapStateToProps = (state, { toCamel, stateKey = "" }) => ({
  auth: state.auth,
  barChartData: toCamel ? convertFieldsToCamelize(state[state.common.currentModule][`barChartData${stateKey}`]) : state[state.common.currentModule][`barChartData${stateKey}`],
  tenantBanks: state.common.filter.tenantBanks,
  currentModule: state.common.currentModule,
});
const mapDispatchToProps = (dispatch, { stateKey = "" }) => ({
  getChartData: (currentModule, method, endpoint, data, parameters, callback) => dispatch(actions[currentModule][method]({ url: endpoint, key: `barChartData${stateKey}`, data, parameters }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BarChart);