import React from 'react';
import { SlidingCurrencyItem } from './';
import Ticker from 'react-ticker';
import i18n from 'plugins/i18n';
import { connect } from "react-redux";

const CurrencyHead = ({ data, isOpen, isEffectiveCurrencyCode}) => {
  return (
    <div className="marquee-head">{
      isOpen ?
        <span>{i18n.t('lbl.currency')} <span className="info">{i18n.t('lbl.tcmbDatas')}</span></span>
        :
        <Ticker>
          {() =>
            data.map(item =>
              <SlidingCurrencyItem {...{item, isEffectiveCurrencyCode}} key={item.from} />
            )
          }
        </Ticker>
    }
    </div>
  )
}

const mapStateToProps = ({ common }) => ({  ...common });
export default connect(mapStateToProps)(CurrencyHead);