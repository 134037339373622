import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/tos';
import { ActionButtons, DataTable, Filter } from 'components/UIComponents';
import { defaultFilters } from "lib/constants";
import endpoints from 'config/endpoints';
import { formatDate, formatCurrency } from "helpers";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Payment from './DeleteDetail';

class PaymentLists extends Component {
  state = {
    filter: defaultFilters.tos.PaymentLists()
  };

  componentDidMount() {
    this.getOrders();
    utils.tos.getTenantBanks('değiştir') //FIXME:
    this.props.getOrderStatus({ filter: { OperationStatusTypeId: { eq: 2 } } });
    utils.common.checkAccessToUpdateOrDelete('TosOrder')
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getOrders = () => {
    const { filter } = this.state;
    this.props.getOrders(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getOrders);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getOrders);
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1)
    this.setState({ filter }, this.getOrders);
  };


  render() {
    const { paymentList, tenantBanks, orderStatus } = this.props;
    const filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.tos.getBankName({ bankData }), keys: ['BankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.orderStatus'), type: 'select', search: true, options: orderStatus.data, value: 'Id', name: 'Name', keys: ['OperationStatusId'], uniqueKey: '#OperationStatus', multiple: true },
      { label: i18n.t('lbl.paymentDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['PaymentDate'], uniqueKey: '#PaymentDateStart', allowClear: true },
      { label: i18n.t('lbl.paymentDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['PaymentDate'], uniqueKey: '#createdDateEnd', allowClear: true },
      { label: i18n.t('lbl.amount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['TotalAmount'], uniqueKey: '#minAmount' },
      { label: i18n.t('lbl.amount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['TotalAmount'], uniqueKey: '#maxAmount' },
      { label: i18n.t('lbl.description'), type: 'input', keys: ['Description'], contains: true, uniqueKey: '#description' },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="TosOrder" editUrl item={row} hasDelete getDatas={this.getOrders} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'BankCode', render: (data) => utils.tos.getBankName({ data }) },
      { label: i18n.t('lbl.paymentDate'), key: 'PaymentDate', render: ({ PaymentDate }) => formatDate(PaymentDate, null, null, null, false) },
      { label: i18n.t('lbl.orderStatus'), key: 'OperationStatus/Name', checkField: false },
      { label: i18n.t('tos.piece'), key: 'TotalCount' },
      { label: i18n.t('lbl.amount'), key: 'TotalAmount', render: ({ TotalAmount, CurrencyCode }) => formatCurrency({ data: TotalAmount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode' },
      { label: i18n.t('lbl.description'), key: 'Description' },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          count={paymentList.list.count}
          wrappedComponentRef={el => this.datatable = el}
          access="TosOrder"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={paymentList.list.data}
          loading={paymentList.list.loading}
          title={i18n.t('route.tos.paymentList')}
          getData={this.getOrders}
          Component={Payment}
          dialogTitle={i18n.t('lbl.payment')}
          usePage="tos/payment"
          deleteOptions={{ stateKey: "paymentList", url: "TosOrder" }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ tos, common }) => ({
  paymentList: tos.paymentList,
  tenantBanks: tos.filter.tenantBanks,
  orderStatus: tos.filter.orderStatus,
});
const mapDispatchToProps = (dispatch) => ({
  getOrders: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tos.tosOrder, key: 'paymentList' })),
  getOrderStatus: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.tos.operationStatus, key: 'orderStatus' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentLists);