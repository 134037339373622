import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select, DatePicker } from 'components/UIComponents/NetbankaComponents';
import { InstructionListsModel } from 'models';
import actions from 'store/actions/dbs';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';

class DeleteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new InstructionListsModel(),
      InitialState: new InstructionListsModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.instructionLists.single.data && !state.data.Id) {
      const data = new InstructionListsModel({ ...props.instructionLists.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.instructionLists.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getInstructionLists(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = checkAccessRights => {
    let { instructionLists, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={instructionLists.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={instructionLists.single.loading} htmlType="submit" className="success #save" icon="save" loading={instructionLists.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      instructionLists.single.saving,
      instructionLists.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { tenantBanks, orderStatus, dialogCloseRequest, instructionLists } = this.props;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...instructionLists, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      disabled
                      className="#bank"
                      label={i18n.t('dbs.bankName')}
                      options={tenantBanks.data}
                      optVal="Code"
                      optName={(bankData) => utils.dbs.getBankName({ bankData })}
                      value={data.BankCode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <DatePicker disabled label={i18n.t('dbs.orderCreatedDate')} className="#InsertedTime" value={data.InsertedTime} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      disabled
                      className="#OperationStatus"
                      label={i18n.t('dbs.orderStatus')}
                      options={orderStatus.data}
                      optVal="Id"
                      optName="Name"
                      value={data.OperationStatusId}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input disabled className="#TotalCount" label={i18n.t('dbs.totalCount')} value={data.TotalCount} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input disabled className="#TotalAmount" label={i18n.t('dbs.totalAmount')} value={data.TotalAmount} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input disabled className="#Description" label={i18n.t('lbl.description')} value={data.Description} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  instructionLists: dbs.instructionLists,
  tenantBanks: dbs.filter.tenantBanks,
  orderStatus: dbs.filter.orderStatus,
});
const mapDispatchToProps = (dispatch) => ({
  getInstructionLists: (id, callback) => dispatch(actions.get({ url: endpoints.dbs.dbsOrder, key: 'instructionLists', id }, callback)),
  clearState: () => dispatch(actions.clearState("instructionLists"))
});
const DeleteDetailForm = Form.create()(DeleteDetail);
export default connect(mapStateToProps, mapDispatchToProps)(DeleteDetailForm);