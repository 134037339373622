import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/posrapor';
import { ActionButtons, DataTable, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from "lib";
import i18n from 'plugins/i18n';
import { defaultFilters, baseUrls } from 'lib/constants';
import Parameter from './Detail';

const PageAccesses = {
  PosRaporAccountParameters: "PosRaporAccountParameters",
  SubPosRaporAccountParameters: "SubPosRaporAccountParameters"
}

class Parameters extends Component {
  state = {
    filter: defaultFilters.posrapor.Parameters(),
    getDataTrigger: undefined,
    access: PageAccesses.PosRaporAccountParameters
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id };
      const filter = state.filter;
      props.getParameters(filter, parameters, endpoints.posrapor.subPosraporAccountParameters);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  componentDidMount() {
    const { getDataTrigger } = this.props;
    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getParameters();
      access = PageAccesses.PosRaporAccountParameters;
    }
    else {
      access = PageAccesses.SubPosRaporAccountParameters;
    }
    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })
    utils.posrapor.getBanks();
    utils.posrapor.getBankFileTypes();
  };

  getParameters = () => {

    const { getDataTrigger } = this.props;
    let { filter } = this.state;
    if (getDataTrigger)
      this.props.getParameters(filter, { TenantId: getDataTrigger.id }, endpoints.posrapor.subPosraporAccountParameters);
    else
      this.props.getParameters(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getParameters);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getParameters);
  }

  renderActionButtons = row => {
    const { access } = this.state
    return <ActionButtons url={access} editUrl item={row} hasDelete getDatas={this.getParameters} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { parameters, hasParent, className, datatableTitle, getDataTrigger, datatableCollapsed } = this.props;
    const { access } = this.state;

    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'PosRaporAccount/Bank/Name', render: (data) => utils.posrapor.getBankName({ data, dataField: 'PosRaporAccount.Bank.EftCode' }), checkField: false },
      { label: i18n.t('lbl.serviceType'), key: 'ProtocolType' },
      { label: i18n.t('lbl.userName'), key: 'FtpUser' },
    ];

    return (
      <div className={`page-content ${className || ''}`}>
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...parameters, single: parameters.list }, hasParent: getDataTrigger !== undefined }}>
            <DataTable
              count={parameters.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access={access}
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={parameters.list.data}
              loading={parameters.list.loading}
              title={datatableTitle || i18n.t('route.posrapor.parameters')}
              getData={this.getParameters}
              newButton="openDialog"
              Component={Parameter}
              dialogTitle={i18n.t('lbl.parameter')}
              deleteOptions={{ stateKey: "parameters", url: access, baseUrl: baseUrls.posrapor }}
              hasParent={hasParent}
              disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
              collapsed={datatableCollapsed}
              dialogOptions={{
                endpoint: getDataTrigger && endpoints.posrapor.subPosraporAccountParameters,
                tenantId: getDataTrigger && getDataTrigger.id
              }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor }) => ({
  parameters: posrapor.parameters,
  banks: posrapor.filter.banks,
  bankFileTypes: posrapor.filter.bankFileTypes
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (filter, parameters, endpoint) => dispatch(actions.getAll({ filter, parameters, url: endpoint || endpoints.posrapor.parameters, key: 'parameters' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Parameters);