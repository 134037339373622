import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox } from 'antd';
import { Input, Select, DatePicker, Tooltip } from 'components/UIComponents/NetbankaComponents';
import { TransactionModel } from 'models';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import _ from 'lodash';
import i18n from 'plugins/i18n';
import utils from 'lib';

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      data: new TransactionModel(),
      InitialState: new TransactionModel(),
      maxAmount: undefined,
      accountCodes: [],
      planCodes: []
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.dialogClosing && !props.transactions.single.saving) {
      var initialState = state.InitialState;
      var currentState = _.cloneDeep(state.data);

      props.onDialogClose({ ...initialState }, { ...currentState })
    }
    return null;
  };

  componentDidMount() {
    let { split, notSplit, maxAmount } = this.props;
    if (this.id) {
      // utils.netekstre.getTenantBanks();
      utils.netekstre.getVoucherTypeCodes();
      this.props.getTransaction(this.id, (response) => {
        if (response) {
          var model = new TransactionModel(response);
          this.setState({ loading: false, data: model, InitialState: model, maxAmount: response.amount }, () => {
            if (response.accountPlanCode && response.accountPlanCode !== '') {
              this.planCodeSearch(response.accountPlanCode);
            }
            if (response.currentAccountCode && response.currentAccountCode !== '') {
              this.accountCodeSearch(response.currentAccountCode);
            }
          })
        }
        else {
          this.props.history.push('/dashboard/transactions')
        }
      });
    }
    else if (split) {
      utils.netekstre.getVoucherTypeCodes();
      const _split = this.convertFieldsToCamelize(split);
      this.setState({ loading: false, data: new TransactionModel(_split), InitialState: _.cloneDeep(_split), maxAmount: notSplit ? (split.amount || split.Amount) : maxAmount }, () => {
        if (_split.accountPlanCode && _split.accountPlanCode !== '') {
          this.planCodeSearch(_split.accountPlanCode);
        }
        if (_split.currentAccountCode && _split.currentAccountCode !== '') {
          this.accountCodeSearch(_split.currentAccountCode);
        }
      })
    }
    else {
      this.props.history.push('/dashboard/transactions');
    }
  };

  planCodeSearch = (e) => {
    this.props.getPlanCodes(e, (response) => {
      this.setState({ planCodes: response })
    });
  };

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  update = (key, value) => {
    let { data } = this.state;
    // if (key === 'amount') {
    //   value = this.setLimits(key, value);
    //   // value = parseFloat(value)
    // }
    this.setState({ data: { ...data, [key]: value === undefined ? '' : value } });
  };

  // setLimits = (key, value) => {
  //   let { maxAmount } = this.state;
  //   let _value;
  //   if (Math.sign(maxAmount) !== -1) {
  //     _value = parseFloat(value) > parseFloat(maxAmount) ? maxAmount : parseFloat(value) < 0 ? 0 : value
  //   }
  //   else {
  //     _value = parseFloat(value) < parseFloat(maxAmount) ? maxAmount : parseFloat(value) > 0 ? 0 : value
  //   }
  //   return _value;
  // }

  convertFieldsToCamelize = data => {
    let camelizedData = {};
    Object.keys(data).map(key => camelizedData[String.prototype.camelize(key)] = data[key])
    return camelizedData;
  }

  save = (e) => {
    let { data, accountCodes } = this.state;
    e.preventDefault();
    const { form, saveTransaction, dialogCloseRequest } = this.props;

    const checkIsManuelAddedCurrentAccount = () => {
      const manuelAddedAccount = accountCodes.find(x => x.Id === data.currentAccountId && x.isManuelAdded === true)
      if (manuelAddedAccount && data.currentAccountId) {
        data.currentAccountCode = manuelAddedAccount?.Code;
        data.currentAccountId = undefined;
      }
      else if (!data.currentAccountId)
        data.currentAccountCode = undefined;
    }

    form.validateFields(error => {
      if (!error) {
        checkIsManuelAddedCurrentAccount();
        let endPoint = data.approvedTransfer ? endpoints.nte.bankAccountTransactionsApproveTransaction : endpoints.nte.bankAccountTransactions;
        saveTransaction(data, endPoint, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    })
  }

  validateIBAN = (rule, value, callback) => {
    if (value.length > 0 && (value.length < 26 || value.substr(0, 2) !== 'TR')) {
      callback(i18n.t('msg.invalidIBAN'));
    } else {
      callback();
    }
  };

  updateProp = (i, value) => {
    let data = _.cloneDeep(this.state.data);

    data.transactionDynamicProperties[i].Value = value ? value : '';
    data.transactionDynamicProperties[i].value = value ? value : '';

    this.setState({
      data
    });
  }

  onParentSave = (data) => {
    let { onSave } = this.props;
    data.amount = parseFloat(data.amount.toString().replace(',', '.'))
    this.props.form.validateFields(error => {
      if (!error) {
        onSave(data)
      }
    })
  }

  renderDialogButtons = checkAccessRights => {
    let { transactions, dialogCloseRequest, renderDialogButtons, split, onClose, mode } = this.props;
    const { data } = this.state
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={transactions.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => split ? onClose() : dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={transactions.single.loading} htmlType={split ? "button" : "submit"} onClick={() => split ? this.onParentSave(data) : null} className="success #save" icon="save" loading={transactions.single.saving || this.props.saving} form="transaction">
          {mode ? i18n.t(`btn.${mode}AndContinue`) : i18n.t('btn.save')}
        </Button>
      </div>,
      transactions.single.saving,
      transactions.single.loading
    )
  }

  render() {
    const { data, loading, maxAmount, planCodes, accountCodes } = this.state;
    const { tenantBanks, voucherTypes, accessRights, split, notSplit, categories, dialogCloseRequest, transactions } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    let _accountCodes = [...accountCodes, ...data.currentAccounts.map(x => ({ Title: x.name, Code: x.code, Name: x.name, Id: x.id }))]

    renderDialogButtons();

    let limits = { max: Math.sign(maxAmount) === -1 ? 0 : maxAmount, min: Math.sign(maxAmount) !== -1 ? 0 : maxAmount };
    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...transactions, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="transaction">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <DatePicker label={i18n.t('lbl.transactionDate')} value={data.transactionDate} disabled className="#transactionDate" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('amount', {
                      rules: [
                        { required: true, message: i18n.t('msg.required') },
                        { validator: (rule, value, callback) => value === "0" ? callback(true) : callback(), message: i18n.t('msg.noAcceptZero') },
                        { validator: (rule, value, callback) => value[value.length - 1] === (".") || value[value.length - 1] === (",") ? callback(true) : callback(), message: i18n.t('msg.notEndDotOrComma') },
                        { validator: (rule, value, callback) => value === "-" || value === "+" ? callback(true) : callback(), message: i18n.t('msg.signErrorNull') },
                        { validator: (rule, value, callback) => parseFloat(value.toString().replace(',', '.')) > parseFloat(limits.max) ? callback(true) : callback(), message: i18n.t('msg.geMax') },
                        { validator: (rule, value, callback) => parseFloat(value.toString().replace(',', '.')) < parseFloat(limits.min) ? callback(true) : callback(), message: i18n.t('msg.leMin') },
                      ],
                      initialValue: data.amount
                    })(
                      <Input type="amount" label={i18n.t('lbl.amount')} disabled={!split || (split && notSplit)} onChange={(e) => this.update('amount', e)} className="#amount" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bank')}
                        options={tenantBanks.data}
                        optVal="bankEftCode"
                        optName={(bankData) => utils.netekstre.getBankName({ bankData })}
                        disabled={!!this.id}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.typeOne')} value={data.typeCode1} disabled className="#typeOne" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.typeTwo')} value={data.typeCode2} disabled className="#typeTwo" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.accountName')} value={data.bankAccountName ? data.bankAccountName : data.bankAccount ? data.bankAccount.Name : ''} disabled className="#accountName" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.accountNumber')} value={data.bankAccountName ? data.bankAccountAccountNumber : data.bankAccount ? data.bankAccount.Number : ''} disabled className="#accountNumber" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.suffix')} value={data.suffix} disabled className="#suffix" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.category')}
                      options={categories.data}
                      value={data.categoryId}
                      optVal="Id"
                      optName="Name"
                      onChange={(e) => this.update('categoryId', e)}
                      className="#categoryName"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.voucherType')}
                      options={voucherTypes.data}
                      value={data.voucherTypeId}
                      optVal="id"
                      optName="name"
                      onChange={(e) => this.update('voucherTypeId', e)}
                      className="#voucherType"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('currentAccountId', {
                      rules: [{ required: data.transferStatusId === 9, message: i18n.t('msg.required') }],
                      initialValue: data.currentAccountId
                    })(
                      <Select
                        label={i18n.t('lbl.currentAccount')}
                        showSearch
                        onSearch={this.accountCodeSearch}
                        className="#currentAccountCode"
                        options={_accountCodes}
                        optVal="Id"
                        optName={(e) => `${e ? e.Code + ' ' + e.Title || e.Name : ''}`}
                        onChange={(e) => this.update('currentAccountId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.accountPlanCode')}
                      showSearch
                      onSearch={this.planCodeSearch}
                      className="#accountPlanCode"
                      options={planCodes}
                      value={data.accountPlanCode}
                      optVal="Code"
                      optName={(e) => `${e && e.Code ? e.Code : ''} ${e ? e.Title || e.Name : ''}`}
                      onChange={(e) => this.update('accountPlanCode', e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.tc')} type="stringNumber" value={data.tcknNumber} maxLength={11} onChange={(e) => this.update('tcknNumber', e)} className="#tcknNumber" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.taxNumber')} value={data.taxNumber} onChange={(e) => this.update('taxNumber', e)} className="#taxNumber" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('iban', {
                      rules: [{ validator: this.validateIBAN }],
                      initialValue: data.iban
                    })(
                      <Input label={i18n.t('lbl.iban')} type="iban" onChange={(e) => this.update('iban', e)} className="#iban" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.erpVoucherCode')} value={data.erpCode} onChange={(e) => this.update('erpCode', e)} className="#erpCode" />
                  </Form.Item>
                </Col>
                <Tooltip placement="top" maxLength={50} title={data.description1}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Input disabled={!split || (split && notSplit)} label={i18n.t('lbl.description')} value={data.description1} onChange={!(!split || (split && notSplit)) ? (e) => this.update('description1', e) : null} className="#description1" />
                    </Form.Item>
                  </Col>
                </Tooltip>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input label={i18n.t('lbl.bankAccountCode')} value={data.bankAccountCode} onChange={(e) => this.update('bankAccountCode', e)} className="#bankAccountCode" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input disabled label={i18n.t('lbl.documentNumber')} value={data.documentId} className="#documentId" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input disabled label={i18n.t('lbl.contactName')} value={data.contactName} className="#documentId" />
                  </Form.Item>
                </Col>
                {
                  data.transactionDynamicProperties.length > 0 &&
                  data.transactionDynamicProperties.map((prop, i) =>
                    <Col key={(prop.Name || prop.name) + (prop.id || prop.Id)} xs={24} sm={12}>
                      <Form.Item
                        className={`${((prop.required || prop.Required) && (!(prop.value || prop.Value) || (prop.value || prop.Value).trim() === '')) ? 'has-error' : ''}`}
                        help={(prop.required || prop.Required) && (!(prop.value || prop.Value) || (prop.value || prop.Value).trim() === '') ? i18n.t('msg.required') : ''}
                      >
                        {
                          (prop.operationType || prop.PropertyConfig.OperationType) === 2 ?
                            <Select
                              options={prop.items || prop.Items}
                              optVal={null}
                              optName={null}
                              label={(prop.name || prop.Name)}
                              value={(prop.value || prop.Value)}
                              onChange={(e) => this.updateProp(i, e)}
                              className={`#prop${i}`}
                            />
                            :
                            <Input
                              label={(prop.name || prop.Name)}
                              value={(prop.value || prop.Value)}
                              maxLength={(prop.maxLength || prop.MaxLength)}
                              onChange={(e) => this.updateProp(i, e)}
                              className={`#prop${i}`}
                            />
                        }
                      </Form.Item>
                    </Col>
                  )
                }
                {
                  accessRights.find(x => x.endPoint === 'ApproveTransaction') &&
                  data.transferStatus === 'WaitApproval' && !notSplit &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Checkbox checked={data.approvedTransfer} className="#approvedTransfer" onChange={(e) => this.update('approvedTransfer', e.target.checked)}>{i18n.t('lbl.approvedTransfer')}</Checkbox>
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  accessRights: auth.data.accessRights,

  tenantBanks: common.filter.tenantBanks,

  transactions: netekstre.transactions,
  voucherTypes: netekstre.filter.voucherTypes,
  categories: netekstre.filter.transactionCategories,
});
const mapDispatchToProps = (dispatch) => ({
  getTransaction: (id, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.bankAccountTransactions, id, key: 'transactions' }, callback)),
  saveTransaction: (data, endPoint, callback) => dispatch(netekstreActions.put({ url: endPoint, data, key: 'transactions', options: { errors: { showMessage: true } } }, callback)),
  getPlanCodes: (data, callback) => dispatch(commonActions.getPlanCodes(data, callback)),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
});
const TransactionForm = Form.create()(Transaction);
export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);