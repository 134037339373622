import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { Summary, LineChart, PieChartContainer, BarChart } from 'components/UIComponents/Dashboard';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { defaults } from "react-chartjs-2";
import { summaryCardTypes } from 'models';
import { httpMethods } from 'lib/constants';

defaults.global.defaultFontFamily = "Yellix";
defaults.global.defaultFontSize = 14;

class Dashboard extends Component {
  componentDidMount() {
    this.props.getTenantBanks(this.props.multiTenantDashboard);
  };

  render() {
    const { data } = this.props.auth;
    const hasAccessRights = data.accessRights.filter(x => ["Chart.BankBar", "Chart.CustomerLimitPie", "Chart.InvoiceLine"].includes(x.endPoint)).length !== 0;
    const currencyCode = "TRY";

    return (
      data.accessRights.length > 0 &&
      <div className={`page-content ${!hasAccessRights ? 'flex-center' : ''}`}>
        {
          hasAccessRights ?
            <>
              <Summary {...{
                multiTenant: false,
                isCumulative: false,
                currencyCode,
                endpoint: endpoints.dbs.totalBalances,
                typeSets: [summaryCardTypes.incomingPayment, summaryCardTypes.remainingPayment, summaryCardTypes.pendingPayment],
                method: httpMethods.get,
                toCamel: true,
                titles: [i18n.t('lbl.yesterdayIncomingPayments'), i18n.t('lbl.remainingPayments'), i18n.t('lbl.pendingPayments')]
              }} />
              <BarChart {...{
                multiTenant: false,
                isCumulative: false,
                currencyCode,
                endpoint: endpoints.dbs.chartBankBar,
                method: httpMethods.get,
                title: i18n.t('lbl.bankBasedTotalLimit'),
                accessRight: { endpoint: "Chart.BankBar", method: "GET" },
                toCamel: true,
              }} />
              <PieChartContainer
                {...{
                  currencyCode,
                  multiTenant: false,
                  isCumulative: false,
                  includedInternalTransfer: false,
                  title: i18n.t('lbl.vendorPaymentDistribution'),
                  range: {
                    ranges: [
                      { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                      { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                      { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                      { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                    ],
                    defaultSelected: 365
                  },
                  charts: [
                    {
                      chartName: "incoming",
                      currencyCode,
                      onLineClick: this.onLineClick,
                      title: i18n.t('lbl.top10DealersOnLimitBasis'),
                      accessRight: { endpoint: "Chart.CustomerLimitPie", method: "GET" },
                      type: "incomingTotals",
                      method: httpMethods.get,
                      endpoint: endpoints.dbs.chartLimitPie,
                      toCamel: true,
                    },
                    // {
                    //   chartName: "outgoing",
                    //   currencyCode,
                    //   onLineClick: this.onLineClick,
                    //   title: i18n.t('lbl.outgoing'),
                    //   accessRight: { endpoint: "Chart.CustomerInvoicePie", method: "GET" },
                    //   type: "outgoingTotals",
                    //   method: httpMethods.get,
                    //   endpoint: endpoints.dbs.chartCustomerPie,
                    //   toCamel: true,
                    // }
                  ]
                }}
              />
              <LineChart {...{
                currencyCode,
                multiTenant: false,
                isCumulative: false,
                includedInternalTransfer: false,
                endpoint: endpoints.dbs.chartInvoiceLine,
                method: httpMethods.get,
                title: i18n.t('lbl.totalOperationGiro'),
                accessRight: { endpoint: "Chart.InvoiceLine", method: "GET" },
                toCamel: true,
              }} />
            </>
            :
            <div className="no-access-right">
              <i className="fas fa-exclamation-triangle"></i>
              {i18n.t('msg.noAccessRightDashboard')}
            </div>
        }
      </div>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  filterCurrencies: common.filter.currencies,
  i18nextLng: common.i18nextLng
});
const mapDispatchToProps = (dispatch) => ({
  getCurrencies: (callback) => dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies' }, callback)),
  getTenantBanks: (multiTenant) => dispatch(actions.getFilter({ url: multiTenant ? endpoints.tenant.banks : endpoints.lookups.banks, key: 'tenantBanks' })),
  toggleData: (data) => dispatch(actions.toggleData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);