import React from 'react';
import { modules } from 'temp';
import { ModuleItem } from '.';
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';
import actions from 'store/actions/auth';
import { connect, useSelector } from "react-redux";
import routes from "plugins/routes";
import { Icon } from 'antd';

const ModuleSelector = ({ onCollapsed, collapsed, logout, onMobileClick }) => {

  const auth = useSelector(({ auth }) => ({ ...auth }));
  const accessRights = auth.data ? auth.data.accessRights : [];
  const userModuleIds = auth.data ? auth.data.modules.map(x => x.name) : [];

  const getDefaultRoute = (currentModule) => {
    const _routes = routes.find(x => x.name === currentModule);
    const _subRoutes = _routes.routes || [];
    let dr = "";
    if (accessRights.length) {
      dr = _subRoutes
        .filter(x => x.visible !== false)
        .find(x =>
          !x.access
          ||
          accessRights
            .find(y =>
              typeof x.access === "string" ?
                y.endPoint === x.access
                :
                x.access.includes(y.endPoint)
            )
        )
    }
    return dr;
  }


  return (
    <>
      <div className="module-selector" onClick={onMobileClick}>
        <ReactSVG className={`collapsed pointer ${collapsed ? 'on' : ''}`} src={icons.chevronLeft} onClick={onCollapsed} />
        <div>
          {
            modules.filter(x => userModuleIds.includes(x.beName) && !!getDefaultRoute(x.path) && x.isActive).map(mdl => {
              return <ModuleItem key={mdl.code} {... { ...mdl, hasModule: userModuleIds.includes(mdl.beName) && !!getDefaultRoute(mdl.path) }} />
            })
          }
          <ModuleItem {... { path: "common", code: <Icon type="user" />, isActive: true, hasModule: true }} />
        </div>
        <ReactSVG className="pointer" src={icons.logout} onClick={logout} />
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
});
export default connect(null, mapDispatchToProps)(ModuleSelector)