import React from 'react'
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/netahsilat';
import { get } from 'lodash';

export default class NetahsilatUtils {

  static getBankName({ data = null, dataField = 'bankEftCode', listKeys = 'tenantBanks', listField = 'eftCode', bankData = null }) {
    let list, bank;
    if (!bankData) {
      list = get(store.getState().admin.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</> : '';
  }

  static getTenantBanks() {
    dispatchers.getTenantBanks({ orderBy: ['name asc'] });
  }

  static getBanks() {
    dispatchers.getBanks({ orderBy: ['name asc'] });
  }

  static clearFilter(key) {
    dispatchers.clearFilter(key);
  }

  static clearState(key, options) {
    dispatchers.clearState(key, options);
  }

  static getNthBanks() {
    dispatchers.getNthBanks({ orderBy: ['name asc'] });
  }

  static getPoses() {
    dispatchers.getPoses({ orderBy: ['name asc'] });
  }

  static getCurrencies() {
    dispatchers.getCurrencies({ orderBy: ['name asc'] });
  }

  static get3DSecureTypes() {
    dispatchers.get3DSecureTypes();
  }

  static getTransactionTypes() {
    dispatchers.getTransactionTypes({ orderBy: ['name asc'] });
  }

  static getTransactionStatuses() {
    dispatchers.getTransactionStatuses({ orderBy: ['name asc'] });
  }

  static getNetLinkTransactionStatuses() {
    dispatchers.getNetLinkTransactionStatuses({ orderBy: ['name asc'] });
  }
  static getNetlinkTypes() {
    dispatchers.getNetlinkTypes({ orderBy: ['name asc'] });
  }

  static getLanguageTypes() {
    dispatchers.getLanguageTypes();
  }

  static getFrequencyTypes() {
    dispatchers.getFrequencyTypes();
  }

  static getPaymentSets() {
    dispatchers.getPaymentSets();
  }
  static getPaymentTabs() {
    dispatchers.getPaymentTabs();
  }
  static getThreeDStatuses({ convertEnum = false }) {
    dispatchers.getThreeDStatuses(convertEnum);
  }
}


const dispatchers = {
  clearFilter: (key) => store.dispatch(actions.clearFilter(key)),
  clearState: (key, options) => store.dispatch(actions.clearState(key, options)),
  getBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.banks, key: 'banks', filter })),
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.tenantBanks, key: 'tenantBanks', filter })),

  getPoses: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nthPoses, key: 'poses', filter })),
  getNthBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nthBanks, key: 'nthBanks', filter })),
  getCurrencies: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nthCurrencies, key: 'currencies', filter })),
  get3DSecureTypes: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nth3DSecureTypes, key: 'threeDSecureTypes', filter })),
  getTransactionTypes: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nthTransactionTypes, key: 'transactionTypes', filter })),
  getTransactionStatuses: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nthTransactionStatus, key: 'transactionStatuses', filter })),
  getNetLinkTransactionStatuses: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.nthNetlinkTransactionStatus, key: 'netlinkTransactionStatuses', filter })),
  getNetlinkTypes: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.netlinkTypes, key: 'netlinkTypes' })),
  getLanguageTypes: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.languageTypes, key: 'languageTypes' })),
  getFrequencyTypes: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.frequencyTypes, key: 'frequencyTypes' })),
  getPaymentSets: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.paymentSets, key: 'paymentSets' })),
  getPaymentTabs: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.paymentTabs, key: 'paymentTabs' })),
  getThreeDStatuses: (convertEnum) => store.dispatch(actions.getFilter({ url: endpoints.lookups.threeDStatuses, key: 'threeDStatuses', convertEnum })),
}