
import React, { forwardRef } from 'react';
import { Checkbox as AntCheckbox } from "antd";

const Checkbox = forwardRef((props, ref) => {
  const id = props.id || Math.random().toString();
  return (
    <div className="custom-checkbox__wrapper">
      <AntCheckbox
        {...{ ref, id }}
        {...props}
        className={`${props.className || ''} ${props.nextGen ? 'nextGen' : ''} custom-checkbox`}
      />
      <label className={`${props.nextGen ? 'nextGen' : ''}`} htmlFor={props.id || id}>
        {props.label}
      </label>
    </div>
  )
})

export default Checkbox;
