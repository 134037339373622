import React from 'react'
import ReactSVG from 'react-svg';
import Lock from 'assets/img/lock.svg'
import actions from 'store/actions/common';
import { useDispatch } from 'react-redux';

const addPageLockClassNames = () => {
  let blurOverlayElm = document.getElementById('blur-overlay');
  let blurElm = document.getElementById('blur');
  blurOverlayElm.classList.add('active');
  blurElm.classList.add('blur');
}

const PageLock = () => {
  const dispatch = useDispatch()
  return (
    <ReactSVG onClick={() => dispatch(actions.toggleData({ key: "blur", data: true }), addPageLockClassNames())} className="page-lock__icon" src={Lock} />
  )
}

export default PageLock