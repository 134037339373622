
import React, { useState } from 'react'
import { Form, Row, Col } from 'antd';
import i18n from 'plugins/i18n';
import { Typography, KrediContent, FormElements } from 'components/UIComponents';

const ApplicationContinuationStep2 = ({ form }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ name: '', surname: '' });
  return (
    <KrediContent>
      <Form>
        <Row gutter={44}>
          <Col xs={24} md={24}>
            <Typography.H size={3} color="#273c74">
              {i18n.t('lbl.creditApplication')}
            </Typography.H>
            <Typography.P className="mt-15" size="normal">
              {i18n.t('lbl.pleaseEnterTheInformationForCredit')}
            </Typography.P>
          </Col>
        </Row>

        <Row gutter={44} className="mt-30 mb-30">
          <Col xs={24} md={12}>
            <Form.Item>
              {
                getFieldDecorator('name', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.name,
                })(
                  <FormElements.Input
                    label={i18n.t('lbl.name')}
                    prefix="manager"
                    onChange={(e) => updateData((data) => ({ ...data, name: e }))}
                  />
                )}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item>
              {
                getFieldDecorator('surname', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: data.surname,
                })(
                  <FormElements.Input
                    label={i18n.t('lbl.surname')}
                    prefix="manager"
                    onChange={(e) => updateData((data) => ({ ...data, surname: e }))}
                  />
                )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </KrediContent>
  )
}

export default Form.create()(ApplicationContinuationStep2);
