import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Tooltip, Modal } from 'antd';
import actions from 'store/actions/tos';
import { formatCurrency, isEditableOrderTos } from 'helpers';
import { TosCreateOrSendOrderModel } from 'models';
import { ActionButtons, DataTable, Instruction, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from 'lib'
import { defaultFilters } from 'lib/constants';
import i18n from 'plugins/i18n';
import CreatePayment from '../CreatePayments/Detail';
import { isEditableInvoice } from 'helpers/tos';

class CreatePayments extends Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id
    this.state = {
      filter: defaultFilters.tos.CreatePayments(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      orderSendingOrCreating: false,
      isEditable: true
    };
  };

  componentDidMount() {
    if (!!this.id) {
      this.getOrderDetails();
      utils.tos.getTenantCurrencies();
      utils.tos.getTenantBanks('TosOrderDetail');
      this.getInvoiceTotal();
    }
    if (this.props.selectedItems.length > 0) {
      this.getInvoiceTotal();
    }
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.orderDetails.list.data && props.orderDetails.list.data.length) {
      const _order = props.orderDetails && props.orderDetails.list.data && props.orderDetails.list.data.length > 0 ? props.orderDetails.list.data[0] : null;
      return { isEditable: isEditableOrderTos(_order && _order.TosOrder ? _order.TosOrder.OperationStatusId : null) }
    }
    return null
  }

  getOrderDetails = () => {
    let { filter } = this.state;
    filter.filter = { TosOrderId: { type: 'guid', value: this.id }, }
    this.props.getOrderDetails(filter);
  };

  getInvoiceTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll || !!this.id) {
      let filter = {};
      filter.filter = {
        and: [{ TosOrderId: { type: 'guid', value: this.id }, }],
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }

      this.props.getInvoiceTotal(filter, response => {

        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(invoice =>
            _amount += invoice.Amount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getOrderDetails();
    });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getOrderDetails();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getOrderDetails);
  }

  additionalButtons = () => {
    let buttons = [
      <div>
        <Icon type="plus" />
        {i18n.t('tos.downloadExampleFile')}
      </div>,
      <div>
        <Icon type="plus" />
        {i18n.t('tos.filePayment')}
      </div>
    ];

    return buttons;
  };

  orderOperation = (data, method) => {
    let getTosOrderDetailData = this.props.orderDetails.list.data[0].TosOrder;
    this.setState({ orderSendingOrCreating: true })
    let _data = method === "createOrder" ? new TosCreateOrSendOrderModel({ ...data, OrderDetailIdList: this.props.selectedItems }) : new TosCreateOrSendOrderModel({ ...data, TosOrderId: this.id, OrderDetailIdList: this.props.selectedItems, TenantPaymentAccountId: getTosOrderDetailData.TenantPaymentAccountId });

    this.props[method](_data, (response) => {
      this.setState({ orderSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 || response.statusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: (response && response.Message) ? response.Message : status === 'success' ? i18n.t('msg.success') : i18n.t('msg.error'),
          content: status === 'success' ? i18n.t(`dbs.${method}Response`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.getOrderDetails();
            utils.common.clearSelectedItems();
          }
        })
      }
    })
  }

  callbackDelete = () => {
    this.getOrderDetails(response => {
      this.getInvoiceTotal(response);
    });
  }

  setOrderDetail = orderResponse => {
    const _order = orderResponse && orderResponse.list.data && orderResponse.list.data.length > 0 ? orderResponse.list.data[0] : null;
    return {
      id: this.id,
      description: _order && _order.TosOrder ? _order.TosOrder.Description : null,
      bankCode: _order && _order.TosOrder ? _order.TosOrder.BankCode : null,
      invoices: orderResponse.list.data,
      currencyCode: _order ? _order.CurrencyCode : null,
      count: orderResponse.list.count,
      loading: orderResponse.list.loading,
      statusId: _order && _order.TosOrder ? _order.TosOrder.OperationStatusId : null,
      paymentDate: _order && _order.TosOrder ? _order.TosOrder.PaymentDate : null,
    }
  }

  renderActionButtons = row => {
    return this.state.isEditable && <ActionButtons url="TosOrderDetail" editUrl item={row} hasDelete getDatas={this.callbackDelete} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { orderDetails, tenantBanks, selectedItems } = this.props;
    const { totalAmount, totalCount, totalsLoading, orderSendingOrCreating, filter, isEditable } = this.state;
    const _order = this.setOrderDetail(orderDetails);

    const instructions = [
      { label: i18n.t('dbs.bankName'), key: 'BankCode', type: 'select', options: tenantBanks.data, name: (bankData) => utils.tos.getBankName({ bankData }), value: "Code", defaultValue: _order.bankCode, disabled: true, required: true, prior: true },
      { label: i18n.t('lbl.paymentDate'), key: 'PaymentDate', type: 'date', required: true, defaultValue: _order.paymentDate, disabled: true, prior: true },
      { label: i18n.t('dbs.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: isEditable && true },
      { label: i18n.t('dbs.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: isEditable && true },
      { label: i18n.t('lbl.description'), key: 'Description', type: 'input', defaultValue: _order.description },
      isEditable && { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: (data) => this.orderOperation(data, 'createOrder'), disabled: (!!!this.id || _order.count === 0 || orderSendingOrCreating || !selectedItems.length > 0), access: 'TosOrder.Create' },
      { label: i18n.t(isEditable ? 'btn.sendOrder' : 'btn.sent'), type: 'button', className: 'success', onClick: (data) => this.orderOperation(data, 'sendOrder'), disabled: (!!!this.id || _order.count === 0 || orderSendingOrCreating || !isEditable), access: 'TosOrder.Send' },
    ]
    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.consumerAccountNumber'), key: 'AccountNumber' },
      { label: i18n.t('lbl.consumerTitle'), key: 'Title' },
      { label: i18n.t('lbl.bankName'), key: 'BankName', render: (data) => utils.tos.getBankName({ data }), checkField: false, },
      { label: i18n.t('lbl.iban'), key: 'IBAN' },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: ({ Amount, CurrencyCode }) => formatCurrency({ data: Amount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('lbl.description'), key: 'Description' },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...orderDetails }}>
            <Instruction {...{ instructions, loading: totalsLoading }} />
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              count={_order.count}
              access="TosOrderDetail"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={_order.invoices}
              loading={_order.loading}
              title={i18n.t('route.tos.createPayment')}
              newButton={isEditable && { endpoint: "openDialog", title: 'addManualPaymentDetail' }}
              excel={{ module: "tos", url: "TosOrderDetail.Excel", filter: { filter: filter.filter } }}
              additionalButtons={isEditable && this.additionalButtons()}
              getData={this.getOrderDetails}
              checkbox={{isActive: isEditable, disabled: item => !isEditableInvoice(item.OperationStatus.Id)}}
              onCheck={this.getInvoiceTotal}
              checkAll={{isActive: isEditable && _order.invoices && _order.invoices.length > 0, disabled: _order.invoices.some(x => !isEditableInvoice(x.OperationStatus.Id) )}}
              Component={isEditable && CreatePayment}
              dialogTitle={i18n.t('lbl.payment')}
              dialogNotEditable={!isEditable}
              deleteOptions={{ stateKey: "orderDetails", url: "TosOrderDetail" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };

};

const mapStateToProps = ({ tos, common }) => ({
  orderDetails: tos.orderDetails,
  tenantBanks: tos.filter.tenantBanks,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getOrderDetails: (filter, callback) => dispatch(actions.getAll({ filter, url: endpoints.tos.tosOrderDetail, key: 'orderDetails' }, callback)),
  getInvoiceTotal: (filter, callback) => dispatch(actions.getAll({ filter, url: endpoints.tos.tosOrderDetail }, callback)),
  createOrder: (data, callback) => dispatch(actions.post({ url: endpoints.tos.createOrder, data, customNotification: true }, callback)),
  sendOrder: (data, callback) => dispatch(actions.post({ url: endpoints.tos.sendOrder, data, customNotification: true }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreatePayments);