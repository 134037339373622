import React, { Component } from 'react';
import { connect } from 'react-redux';
import tosActions from 'store/actions/tos';
import { DataTable, Filter, Instruction } from 'components/UIComponents';
import { defaultFilters } from "lib/constants";
import endpoints from 'config/endpoints';
import { formatDate, formatCurrency, isEditableInvoice } from "helpers";
import { TosCreateOrSendOrderModel } from "models";
import { Tooltip, Icon, Modal } from "antd";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Reporting from './Detail';
import _ from 'lodash';

class Reportings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.tos.Reporting(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      orderSendingOrCreating: false,
      startCreateOrder: false,
      options: [],
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.options.length) return {
      options: _.cloneDeep(props.tenantBanks.data.map(x => {
        return { value: x.Code, label: x.Name, type: 'BankCode', isLeaf: false }
      }
      ))
    }
    return null;
  }

  componentDidMount() {
    utils.tos.getTenantBanks('tenantBanks');
    utils.tos.getTenantCurrencies();
    this.props.getInvoiceStatus({ filter: { OperationStatusTypeId: { eq: 1 } } });
    this.props.getOrderStatus({ filter: { OperationStatusTypeId: { eq: 2 } } });
    this.getReporting();
  }

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getReporting = () => {
    let filter = _.cloneDeep(this.state.filter);
    if (this.state.startCreateOrder) {
      const _editableIds = this.props.invoiceStatus.data.filter(x => isEditableInvoice(x.Id))
      filter.filter.and ?
        filter.filter.and.push({ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] })
        :
        filter.filter.and = [{ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] }];
    }

    this.props.getReporting(filter, () => {
      this.getOrderTotal()
    }
    );
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getReporting);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getReporting);
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(0);
    this.setState({ filter }, this.getReporting);
  };


  onBankChange = (bankId, key) => {
    const { filter } = this.state;
    this.onFilter(bankId ? { [key]: { eq: bankId } } : delete filter.filter[key]);
    if (!bankId) this.datatable.checkAll(false);
    this.setState({ bankId })
  }

  onCurrencyChange = (currencyCode, key) => {
    const { filter } = this.state;
    this.onFilter(currencyCode ? { [key]: { eq: currencyCode } } : delete filter.filter[key]);
    if (!currencyCode) this.datatable.checkAll(false);
    this.setState({ currencyCode })
  }

  onStartCreateOrder = () => {
    this.setState({ startCreateOrder: !this.state.startCreateOrder }, () =>
      this.getReporting()
    );
  }

  orderOperation = (data) => {
    const bankData = this.props.bankAccounts.data.find(x => x.AccountNumber === data.BankAccount[2])
    let _data = {
      Description: data.Description,
      PaymentDate: data.PaymentDate,
      TenantPaymentAccountId: bankData.Id,
      OrderDetailIdList: this.props.selectedItems
    }
    this.setState({ orderSendingOrCreating: true })
    _data = new TosCreateOrSendOrderModel(_data)

    this.props.createOrder(_data, (response) => {
      this.setState({ orderSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 || response.statusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: (response && response.Message) ? response.Message : status === 'success' ? i18n.t('msg.success') : i18n.t('msg.error'),
          content: status === 'success' ? i18n.t(`tos.createOrderResponse`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.setState({ totalAmount: null, totalCount: null }, () => {
              this.getReporting();
              utils.common.clearSelectedItems();
            })
          }
        })
      }
    })
  }

  getOrderTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll) {
      let filter = {};
      filter.filter = {
        ...this.state.filter.filter,
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }
      if (this.state.startCreateOrder) {
        const _editableIds = this.props.invoiceStatus.data.filter(x => isEditableInvoice(x.Id))
        filter.filter.and ?
          filter.filter.and.push({ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] })
          :
          filter.filter.and = [{ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] }];
      }

      this.props.getOrderTotal(filter, response => {
        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(invoice =>
            _amount += invoice.Amount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  loadData = selectedOptions => {
    let targetOption = {};
    targetOption = selectedOptions[selectedOptions.length - 1];

    if (targetOption.type === "BankCode") {
      targetOption.loading = true;
      const filter = { filter: { 'TenantBank/Bank/EftCode': targetOption.value, BranchNumber: { ne: null } }, }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.branchName'), type: 'BranchNumber', disabled: true },
          ...response.value.map(x => ({ label: `${x.BranchName} (${x.BranchNumber})`, value: x.BranchNumber, type: 'BranchNumber', isLeaf: false }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }

    if (targetOption.type === "BranchNumber") {
      targetOption.loading = true;
      const filter = {
        filter: {
          'TenantBank/Bank/EftCode': selectedOptions.find(x => x.type === "BankCode").value,
          BranchNumber: selectedOptions.find(x => x.type === "BranchNumber").value,
        },
      }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.accountNumber'), type: 'AccountNumber', disabled: true },
          ...response.value.map(x => ({ label: `${x.AccountNumber} (${x.CurrencyCode})`, value: x.AccountNumber, type: 'AccountNumber' }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }
  }

  render() {
    const { reportings, tenantBanks, orderStatus, invoiceStatus } = this.props;
    const { totalAmount, totalCount, totalsLoading, startCreateOrder, orderSendingOrCreating, options } = this.state;
    const _options = [{ value: Math.random(), label: i18n.t('lbl.bank'), type: 'BankCode', disabled: true }, ...options];
    const filters = [
      { label: i18n.t('tos.bankName'), type: 'select', keys: ['BankCode'], options: tenantBanks.data, value: 'Code', name: (bankData) => utils.tos.getBankName({ bankData }), showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('tos.cunsomerTitle'), type: 'select', keys: ['CustomertosName', 'CustomertosCode', 'CurrentAccount/Title', 'CurrentAccount/Code'], contains: true, uniqueKey: '#dealerCodeOrName' },
      { label: i18n.t('tos.orderNumber'), type: 'input', keys: ['InvoiceNumber'], uniqueKey: '#invoiceNumber' },
      { label: i18n.t('tos.orderStatus'), type: 'select', search: true, options: orderStatus.data, value: 'Id', name: 'Name', keys: ['tosOrder/OperationStatusId'], uniqueKey: '#fileStatus', multiple: true },
      { label: i18n.t('tos.invoiceStatus'), type: 'select', search: true, options: invoiceStatus.data, value: 'Id', name: 'Name', keys: ['tosOrder/OperationStatusId'], uniqueKey: '#invoiceStatus', multiple: true },
      { label: i18n.t('lbl.minAmount'), type: 'number', operator: 'ge', keys: ['InvoiceAmount'], uniqueKey: '#minAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.maxAmount'), type: 'number', operator: 'le', keys: ['InvoiceAmount'], uniqueKey: '#maxAmount', col: { lg: 3 } },
      { label: i18n.t('tos.createdDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['InsertedTime'], uniqueKey: '#createdDateStart', allowClear: true },
      { label: i18n.t('tos.createdDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['InsertedTime'], uniqueKey: '#createdDateEnd', allowClear: true },
      { label: i18n.t('tos.paymentDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['DueDate'], uniqueKey: '#paymentDateStart', allowClear: true },
      { label: i18n.t('tos.paymentDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['DueDate'], uniqueKey: '#paymentDateEnd', allowClear: true },
      { label: i18n.t('tos.sendingAccount'), type: 'input', keys: ['Description', 'tosOrder/Description'], contains: true, uniqueKey: '#description' },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Description', 'tosOrder/Description'], contains: true, uniqueKey: '#description' },
    ];
    const columns = [
      { label: i18n.t('tos.bankName'), key: 'BankCode', render: (data) => utils.tos.getBankName({ data }) },
      { label: i18n.t('lbl.branchNumber'), key: 'BankBranchCode' },
      { label: i18n.t('lbl.accountNumber'), key: 'AccountNumber' },
      { label: i18n.t('lbl.iban'), key: 'IBAN' },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: ({ Amount, CurrencyCode }) => formatCurrency({ data: Amount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('lbl.orderStatus'), key: 'TosOrder/OperationStatus/Name', checkField: false },
      { label: i18n.t('lbl.description'), key: 'Description' },
      { label: i18n.t('lbl.paymentDate'), key: 'TosOrder/PaymentDate', checkField: false, render: ({ TosOrder }) => formatDate(TosOrder && TosOrder.PaymentDate, null, null, null, false) },
    ];

    const instructions = [
      {
        label: i18n.t('lbl.bank/branch/account'),
        key: "BankAccount",
        type: "cascade",
        options: _options,
        required: true,
        loadData: this.loadData,
      },
      { label: i18n.t('lbl.paymentDate'), key: 'PaymentDate', type: 'date', required: true },
      { label: i18n.t('lbl.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('lbl.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('tos.description'), key: 'Description', type: 'input' },
      { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: this.orderOperation, disabled: (reportings.count === 0 || orderSendingOrCreating), access: 'TosOrder.Create' },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        {
          startCreateOrder &&
          <Instruction {...{ instructions, loading: totalsLoading }} />
        }
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          count={reportings.list.count}
          access="tosOrder"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={reportings.list.data}
          loading={reportings.list.loading}
          excel="ExporttosInvoice"
          title={i18n.t('route.tos.reportings')}
          getData={this.getReporting}
          checkbox={(startCreateOrder) && true}
          additionalButtons={[
            <div onClick={this.onStartCreateOrder}>
              <Icon type="interaction" className="mr-5" />
              {startCreateOrder ? i18n.t('btn.stopCreateOrder') : i18n.t('tos.startCreateOrder')}
            </div>
          ]}
          onCheck={this.getOrderTotal}
          checkAll={reportings.list.data && reportings.list.data.length > 0 && (startCreateOrder)}
          Component={Reporting}
          dialogTitle={i18n.t('lbl.reporting')}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ tos, common, netekstre }) => ({
  reportings: tos.reportings,
  tenantBanks: tos.filter.tenantBanks,
  currencies: tos.filter.currencies,
  orderStatus: tos.filter.orderStatus,
  invoiceStatus: tos.filter.invoiceStatus,
  bankAccounts: netekstre.filter.bankAccounts,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getReporting: (filter, callback) => dispatch(tosActions.getAll({ filter, url: endpoints.tos.reporting, key: 'reportings' }, callback)),
  getOrderTotal: (filter, callback) => dispatch(tosActions.getFilter({ filter, url: endpoints.tos.tosOrderDetail }, callback)),
  createOrder: (data, callback) => dispatch(tosActions.post({ url: endpoints.tos.createOrder, data, customNotification: true, options: { errors: { showNotification: false } } }, callback)),
  getOrderStatus: (filter) => dispatch(tosActions.getFilter({ filter, url: endpoints.tos.operationStatus, key: 'orderStatus' })),
  getInvoiceStatus: (filter) => dispatch(tosActions.getFilter({ filter, url: endpoints.tos.operationStatus, key: 'invoiceStatus' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reportings);