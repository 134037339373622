import React, { Component } from 'react';
import { Card, Timeline, Tag, Icon } from "antd";
import actions from 'store/actions/common';
import { connect } from 'react-redux';
import i18n from 'plugins/i18n';

class ChangeLog extends Component {
  state = { version: [] }

  componentDidMount() {
    this.props.getVersion((response) =>
      this.setState({ version: response })
    )
  }

  render() {
    let { version } = this.state
    return (
      <Card className="p-40">
        {
          version &&
          <Timeline>
            {
              version.map((v, i) =>
                <Timeline.Item
                  dot={i === 0 ? <Icon type="clock-circle-o" style={{ fontSize: '14px' }} /> : null}
                  key={v.version} color={i === 0 ? "green" : "blue"}
                >
                  <h3>
                    v{v.version} {i === 0 && ` - ${i18n.t('lbl.currentVersion')}`}
                  </h3>
                  <Tag className="change-log grey">{v.releaseDate}</Tag>
                  <ul>
                    {
                      v.changes &&
                      v.changes.length > 0 &&
                      v.changes.map((change, j) => {
                        let typeClass = '';
                        switch (change.type) {
                          case 'Düzeltme': typeClass = 'bug'; break;
                          case 'Geliştirme': typeClass = 'improving'; break;
                          case 'Özellik': typeClass = 'feature'; break;
                          default: break;
                        }
                        return <li key={j} className="change-log-item">
                          <Tag className={`change-log ${typeClass}`}> {i18n.t(`lbl.${typeClass}`)} </Tag><span>{change.description}</span>
                        </li>
                      })
                    }
                  </ul>
                </Timeline.Item>
              )
            }
            <Timeline.Item color="#fff">

            </Timeline.Item>
          </Timeline>
        }
      </Card>
    );
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVersion: (callback) => dispatch(actions.getVersion(callback))
})
export default connect(null, mapDispatchToProps)(ChangeLog);