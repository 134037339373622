import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { DataTable, Filter, ActionButtons, FadeIn } from 'components/UIComponents';
import { defaultFilters, categoryTypesLookup, baseUrls } from "lib/constants";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import Detail from './Detail';
import utils from 'lib';

const pageName = "GeneralCategories"


class GeneralCategories extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin[pageName]()
  };

  componentDidMount() {
    this.getGeneralCategories();
    this.props.getGeneralCategoriesFilter();
    utils.common.checkAccessToUpdateOrDelete(pageName);
  };

  componentWillUnmount() {
    utils.admin.clearFilter("movementCategories")
  }

  getGeneralCategories = () => {
    let { filter } = this.state;
    this.props.getGeneralCategories(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getGeneralCategories();
    });
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getGeneralCategories();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getGeneralCategories);
  }

  getCategoryType = ({ CategoryType }) => {
    switch (CategoryType) {
      case 1: return i18n.t('lbl.incoming');
      case 2: return i18n.t('lbl.outgoing');
      default: return '';
    }
  }

  render() {
    let { generalCategories, movementCategories } = this.props;
    let columns = [
      {
        render: (row) => <ActionButtons
          url={pageName}
          editUrl
          item={row}
          hasDelete
          getDatas={this.getGeneralCategories}
          openDialog={this.datatable && this.datatable.openDialog}

        />, toggle: false, sort: false, notIncluded: true, checkField: false
      },
      { label: i18n.t('lbl.code'), key: 'CategoryCode' },
      { label: i18n.t('lbl.categoryName'), key: 'Name' },
      { label: i18n.t('lbl.transactionCategoryType'), key: 'CategoryType', render: this.getCategoryType },
    ];
    let filters = [
      { label: i18n.t('lbl.categoryName'), type: 'select', options: movementCategories.data, value: 'Id', name: 'Name', keys: ['id'], uniqueKey: '#categoryId', multiple: true, guid: true },
      { label: i18n.t('lbl.transactionCategoryType'), type: 'select', options: categoryTypesLookup, lang: 'lbl', value: 'id', name: 'name', keys: ['categoryType'], uniqueKey: '#categoryType', multiple: true },
    ];
    return (
      <FadeIn className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access={pageName}
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={generalCategories.list.data}
          loading={generalCategories.list.loading}
          title={i18n.t('route.admin.generalCategories')}
          getData={this.getGeneralCategories}
          count={generalCategories.list.count}
          newButton="openDialog"
          Component={Detail}
          dialogTitle={i18n.t('lbl.generalCategory')}
          deleteOptions={{
            stateKey: pageName,
            url: pageName,
            baseUrl: baseUrls.nte
          }}
        />
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  generalCategories: admin.generalCategories,
  movementCategories: admin.filter.movementCategories,
});
const mapDispatchToProps = (dispatch) => ({
  getGeneralCategories: (filter) => dispatch(actions.getAll({ filter, url: endpoints.nte.generalCategories, key: 'generalCategories', isNewODataStructure: true })),
  getGeneralCategoriesFilter: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.nte.generalCategories, key: 'movementCategories', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeneralCategories);