import React, { Component } from 'react';
import { Card, Row, Col, Button, Spin, Icon, Form } from "antd";
import { Select, Input, DatePicker } from "components/UIComponents/NetbankaComponents";
import { Cascader } from "components/UIComponents";
import { connect } from 'react-redux';
import i18n from 'plugins/i18n';
import moment from 'moment'

class Instruction extends Component {
  state = { data: {}, loading: true, validate: false }

  componentDidMount() {
    this.generateState();
  }

  generateState = () => {
    let _state = this.handleStateData();
    this.setState({ ..._state })
  }

  handleStateData = () => {
    let { instructions } = this.props;
    let _state = { data: this.state.data }
    instructions.map(x =>
      _state.data[x.key] = (x.prior && x.defaultValue !== undefined) ? x.defaultValue : _state.data[x.key]
    )
    return _state
  }

  onSelectChange = (value, element) => {
    element.onChange && element.onChange(value, element.key);
    this.update(element.key, value)
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  requiredFieldsHasValue = data => {
    const { instructions } = this.props;
    const hasRequiredList = instructions.filter(x => x.required);
    return hasRequiredList.every(x => data[x.key])
  }

  isValidate = (requiredFieldsHasValue, { required, key, defaultValue }, data, type) => {
    let isValidate;
    isValidate = this.state.validate && !requiredFieldsHasValue;
    isValidate = isValidate && required && !(data[key] || defaultValue);
    if (isValidate) {
      return {
        help: i18n.t('msg.required'),
        validateStatus: "error"
      }
    }
    else {
      return {
        help: undefined,
        validateStatus: undefined
      }
    }
  }

  render() {
    const { instructions, loading, accessRights } = this.props;
    const data = this.handleStateData().data;
    const _loading = loading ? <Spin indicator={<Icon type="loading" spin />} /> : null;
    const requiredFieldsHasValue = this.requiredFieldsHasValue(data);

    return (
      <Card className="mb-20 instruction-card">
        <Row gutter={20}>
          <Col xs={24} md={17}>
            {
              instructions && instructions.length > 0 &&
              instructions
                .filter(x => x.type !== "button")
                .map((el, i) =>
                  <Col
                    xs={24}
                    md={Math.floor(24 / (instructions.filter(x => x.type !== "button").length))}
                    key={el.key || el.label || Math.random()}
                    style={{ width: `${100 / instructions.filter(x => x.type !== "button").length}%` }}
                  >
                    {
                      el.type === "cascade" &&
                      <Form.Item
                        help={this.isValidate(requiredFieldsHasValue, el, data).help}
                        validateStatus={this.isValidate(requiredFieldsHasValue, el, data).validateStatus}
                      >
                        <Cascader
                          options={el.options}
                          onChange={(e) => this.onSelectChange(e, el)}
                          label={el.label}
                          value={data[el.key] || el.defaultValue}
                          className={`#${el.key}`}
                          fieldNames={el.fieldNames}
                          loadData={el.loadData}
                        />
                      </Form.Item>
                    }
                    {
                      el.type === "select" &&
                      <Form.Item
                        help={this.isValidate(requiredFieldsHasValue, el, data).help}
                        validateStatus={this.isValidate(requiredFieldsHasValue, el, data).validateStatus}
                      >
                        <Select
                          loading={el.loading}
                          value={data[el.key] || el.defaultValue}
                          className={`#${el.key}`}
                          label={el.label}
                          options={el.options}
                          optVal={el.value}
                          optName={el.name}
                          disabled={el.disabled}
                          suffix={el.suffix}
                          onChange={(e) => this.onSelectChange(e, el)}
                        />
                      </Form.Item>
                    }
                    {
                      el.type === "input" &&
                      <Form.Item
                        help={this.isValidate(requiredFieldsHasValue, el, data).help}
                        validateStatus={this.isValidate(requiredFieldsHasValue, el, data).validateStatus}
                      >
                        <Input
                          value={
                            ((el.withLoading && !loading) || !el.withLoading) ?
                              (data[el.key] || el.defaultValue)
                              :
                              null
                          }
                          className={`#${el.key}`}
                          label={((el.withLoading && !loading) || !el.withLoading) ? el.label : null}
                          disabled={el.disabled}
                          suffix={el.suffix}
                          onChange={(e) => !el.unchangeable ? this.update(el.key, e) : null}
                          prefix={el.withLoading ? _loading : null}
                        />
                      </Form.Item>
                    }
                    {
                      el.type === "date" &&
                      <Form.Item
                        help={this.isValidate(requiredFieldsHasValue, el, data).help}
                        validateStatus={this.isValidate(requiredFieldsHasValue, el, data).validateStatus}
                      >
                        <DatePicker
                          value={data[el.key] || el.defaultValue}
                          label={((el.withLoading && !loading) || !el.withLoading) ? el.label : null}
                          disabled={el.disabled}
                          className={`#${el.key}`}
                          prefix={el.withLoading ? _loading : null}
                          suffix={el.suffix}
                          onChange={(e) => !el.unchangeable ? this.update(el.key, e) : null}
                          disabledDate={(e) => e < moment().add(-1, "day")}
                        />
                      </Form.Item>
                    }
                  </Col>
                )
            }
          </Col>
          <Col xs={24} md={7}>
            {
              instructions && instructions.length > 0 &&
              instructions
                .filter(x => x.type === "button"
                  && accessRights.find(y => y.endPoint === x.access)
                )
                .map((el, i) =>
                  <Col xs={24} md={Math.floor(24 / (instructions.filter(x => x.type === "button"
                    && accessRights.find(y => y.endPoint === x.access)
                  ).length))} key={el.key || el.label || Math.random()}>
                    <Button
                      block
                      className={`${el.className} #${el.key}`}
                      icon="save"
                      onClick={() => el.onClick ? el.onClick(data) : null}
                      disabled={el.disabled || loading || !requiredFieldsHasValue}
                      onMouseEnter={() => this.setState({ validate: true })}
                    >
                      {el.label}
                    </Button>
                  </Col>
                )}
          </Col>
        </Row>
      </Card>
    );
  };
};

const mapStateToProps = ({ auth }) => ({
  accessRights: auth.data.accessRights
});

export default connect(mapStateToProps)(Instruction);