import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Card, Divider } from 'antd';
import actions from 'store/actions/netahsilat';
import { Loading } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { formatDate, formatMoney } from 'helpers';
import ReactToPrint from 'react-to-print';

class TenantBank extends Component {
  state = {};

  static getDerivedStateFromProps(props) {
    if (props.dialogClosing) {
      props.onDialogClose();
      if (props.isPaymentDetail) props.netlinkPage.resetComponent()
    }
    return null;
  };

  componentDidMount() {
    this.props.getPayment(this.props.id);
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    const { payments, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={payments.loading} className="mr-10 #cancel" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
        <ReactToPrint
          trigger={() => <Button disabled={payments.loading} className="#print blue" icon="printer">{i18n.t('btn.print')}</Button>}
          content={() => this.printScope}
          pageStyle={`@page { size: auto;  margin: 0mm; } @media print { body { padding: 40px; -webkit-print-color-adjust: exact; } }`}
        />
      </div>,
      payments.saving,
      payments.loading
    )
  }

  getTransactionType = transactionType => this.props.transactionTypes.data.find(x => x.id === transactionType)?.name;

  getTransactionStatus = transactionStatus => this.props.transactionStatuses.data.find(x => x.id === transactionStatus)?.name;

  getCurrency = currency => this.props.currencies.data.find(x => x.id === currency)?.name || 'TRY';

  getFormattedDate = date => formatDate(date, null, null, null, false);

  render() {
    const { payments } = this.props;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content" ref={el => this.printScope = el}>
        <Form autoComplete="off">
          <Loading loading={payments.loading}>
            <Card size="small" title={i18n.t('lbl.paymentInfo')} className="detail-cards has-child">
              <Card size="small" className="detail-cards mb-10">
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.operationType')} </Col>
                  <Col xs={12}> {this.getTransactionType(payments.data?.transactionType)} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.operationStatus')} </Col>
                  <Col xs={12}> {this.getTransactionStatus(payments.data?.transactionStatus)} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.transactionDate')} </Col>
                  <Col xs={12}> {this.getFormattedDate(payments.data?.transactionDate)} </Col>
                </Row>
              </Card>
              <Card size="small" className="detail-cards mb-10">
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.paymentUserName')} </Col>
                  <Col xs={12}> {payments.data?.userName} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.performingBy')} </Col>
                  <Col xs={12}> {payments.data?.performingBy} </Col>
                </Row>
              </Card>

              <Card size="small" className="detail-cards mb-10">
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.amount')} </Col>
                  <Col xs={12}>  {formatMoney(payments.data?.amount)} {this.getCurrency(payments.data?.currencyId)} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.netAmount')} </Col>
                  <Col xs={12}>  {formatMoney(payments.data?.netAmount)} {this.getCurrency(payments.data?.currencyId)}  </Col>
                </Row>
              </Card>

              <Card size="small" className="detail-cards mb-10">
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.installment')} </Col>
                  <Col xs={12}>  {payments.data?.installment} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.bankName')} </Col>
                  <Col xs={12}>  {payments.data?.posName} </Col>
                </Row>
              </Card>

              <Card size="small" className="detail-cards mb-10">
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.authorizationNumber')} </Col>
                  <Col xs={12}>  {payments.data?.authenticationNumber} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.provisionNumber')} </Col>
                  <Col xs={12}>  {payments.data?.provisionNumber} </Col>
                </Row>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.referenceNumber')} </Col>
                  <Col xs={12}>  {payments.data?.referenceNumber} </Col>
                </Row>
              </Card>

              <Card size="small" className="detail-cards mb-10">
                <Row gutter={10}>
                  <Col xs={12} className="text-semibold">  {i18n.t('lbl.ipAddress')} </Col>
                  <Col xs={12}>  {payments.data?.ipAddress} </Col>
                </Row>
              </Card>
            </Card>


            <Card size="small" title={i18n.t('lbl.cardInfo')} className="detail-cards mt-20">
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.cardHolderName')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.userName} </Col>
              </Row>
              <Divider />
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.cardType')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.cardType} </Col>
              </Row>
              <Divider />
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.cardNumber')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.cardNumber} </Col>
              </Row>
              <Divider />
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.description')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.description} </Col>
              </Row>
            </Card>

            <Card size="small" title={i18n.t('lbl.contactInfo')} className="detail-cards mt-20">
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.email')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.email} </Col>
              </Row>
              <Divider />
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.phone')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.phone} </Col>
              </Row>
              <Divider />
              <Row gutter={10}>
                <Col xs={12} className="text-semibold">  {i18n.t('lbl.tckn')} </Col>
                <Col xs={12}>  {payments.data?.cardInfo?.tckn} </Col>
              </Row>
            </Card>

            {
              payments.data?.transaction.map((x, i) =>
                <Card key={i} size="small" title={this.getTransactionType(x.transactionType)} className="detail-cards mt-20">
                  <Row gutter={10}>
                    <Col xs={12} className="text-semibold">  {i18n.t('lbl.amount')} </Col>
                    <Col xs={12}>  {formatMoney(x.amount)} {this.getCurrency(payments.data?.currencyId)} </Col>
                  </Row>
                  <Divider />
                  <Row gutter={10}>
                    <Col xs={12} className="text-semibold">  {i18n.t('lbl.transactionDate')} </Col>
                    <Col xs={12}>  {this.getFormattedDate(x.transactionDate)} </Col>
                  </Row>
                  <Divider />
                  <Row gutter={10}>
                    <Col xs={12} className="text-semibold">  {i18n.t('lbl.description')} </Col>
                    <Col xs={12}>  {x.description} </Col>
                  </Row>
                  <Divider />
                  <Row gutter={10}>
                    <Col xs={12} className="text-semibold">  {i18n.t('lbl.operationStatus')} </Col>
                    <Col xs={12}>  {this.getTransactionStatus(x.transactionStatus)} </Col>
                  </Row>
                </Card>
              )
            }

          </Loading>
        </Form>
      </div >
    );
  };
};

const mapStateToProps = ({ netahsilat }) => ({
  payments: netahsilat.payments.single,
  transactionStatuses: netahsilat.filter.transactionStatuses,
  transactionTypes: netahsilat.filter.transactionTypes,
  currencies: netahsilat.filter.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getPayment: (id, callback) => dispatch(actions.get({ url: endpoints.netahsilat.payments, key: 'payments', id }, callback)),
  clearState: () => dispatch(actions.clearState("payments")),
});
const TenantBankForm = Form.create()(TenantBank);
export default connect(mapStateToProps, mapDispatchToProps)(TenantBankForm);