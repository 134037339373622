import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Select, Input, Textarea } from 'components/UIComponents/NetbankaComponents';
import { BankFileTypeModel } from 'models/posrapor';
import actions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class BankFileType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new BankFileTypeModel(),
      InitialState: new BankFileTypeModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.bankFileType.single.data && !state.data.id) {
      const data = new BankFileTypeModel({ ...props.bankFileType.single.data })
      data.isActive = props.bankFileType.single.data.isActive;
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.bankFileType.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getBankFileType(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };


  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData } = this.props;
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };


  renderDialogButtons = checkAccessRights => {
    let { bankFileType, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={bankFileType.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={bankFileType.single.loading} form="bankFileTypeForm" htmlType="submit" className="success #save" icon="save" loading={bankFileType.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      bankFileType.single.saving,
      bankFileType.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { banks, dialogCloseRequest, bankFileType } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...bankFileType, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="bankFileTypeForm">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankId
                    })(
                      <Select
                        className="#bankId"
                        label={i18n.t('posrapor.bankName')}
                        options={banks.data}
                        optVal="id"
                        optName={(bankData) => utils.common.getBankName({ bankData })}
                        onChange={(e) => this.update('bankId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('description', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.description
                    })(
                      <Input className="#description" label={i18n.t('posrapor.definition')} onChange={(e) => this.update('description', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                  <Form.Item>
                    {getFieldDecorator('headerColumns', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.headerColumns
                    })(
                      <Textarea rows={5} className="#headerColumns" maxLength={99999} label={i18n.t('posrapor.headerColumns')} onChange={(e) => this.update('headerColumns', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                  <Form.Item>
                    {getFieldDecorator('dynamicPropertyHeader', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.dynamicPropertyHeader
                    })(
                      <Textarea rows={12} className="#dynamicPropertyHeader" maxLength={99999} label={i18n.t('posrapor.dynamicPropertyHeader')} onChange={(e) => this.update('dynamicPropertyHeader', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor }) => ({
  bankFileType: posrapor.bankFileType,
  banks: posrapor.filter.banks,
});
const mapDispatchToProps = (dispatch) => ({
  getBankFileType: (id, callback) => dispatch(actions.get({ url: endpoints.posrapor.bankFileType, key: 'bankFileType', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.posrapor.bankFileType, key: 'bankFileType', data }, callback)),
  clearState: () => dispatch(actions.clearState("bankFileType"))
});
const BankFileTypeForm = Form.create()(BankFileType);
export default connect(mapStateToProps, mapDispatchToProps)(BankFileTypeForm);