import { call, put as sagaPut } from 'redux-saga/effects';
import { PanelProxy } from 'proxies';
import actions from 'store/actions/admin';
import config from 'config';


export function* getAll({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).getAll, payload);
    yield sagaPut(actions.getAllSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  catch (error) {
    yield sagaPut(actions.getAllFailure({ error: error.data, key: payload.key, statusCode: error.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  callback && callback(response.data);
}

export function* get({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).getAll, payload);
    yield sagaPut(actions.getSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
  }
  catch (error) {
    yield sagaPut(actions.getFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
  callback && callback(response.data);
}

export function* getFilter({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).getAll, payload);
    yield sagaPut(actions.getFilterSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode, isNewODataStructure: payload.isNewODataStructure, convertEnum: payload.convertEnum }));
  }
  catch (error) {
    yield sagaPut(actions.getFilterFailure({ error: error.data, key: payload.key, statusCode: error.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  callback && callback(response.data);
}

export function* post({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).post, payload);
    yield sagaPut(actions.postSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    response = yield sagaPut(actions.postFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
    payload.options && payload.options.showErrorResponse && callback && callback(response.error);
  }
}

export function* put({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).put, payload);
    yield sagaPut(actions.putSuccess({ data: null, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.putFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
}

export function* _delete({ payload, callback }) {
  let response;
  const _payload = {
    ...payload,
    url: `${payload.baseUrl || config.netekstreUrl}${payload.url}`
  }
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).delete, _payload);
    yield sagaPut(actions.deleteSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.deleteFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
}

export function* patch({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).patch, payload);
    yield sagaPut(actions.patchSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.patchFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
}


export function* getWithPost({ payload, callback }) {
  let response
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).post, payload);
    yield sagaPut(actions.getSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.getFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
};
