// import Detail from 'components/Modules/DBS/CreateOrder/Detail'

export default {
  path: '/dbs',
  name: 'dbs',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: require('components/Modules/DBS/Dashboard').default,
    },
    {
      path: '/customer-infos',
      name: 'customerInfos',
      access: 'CustomerDbsInfo',
      component: require('components/Modules/DBS/CustomerInfos/List').default,
    },
    {
      path: '/creating-instruction/:id?',
      name: 'creatingInstruction',
      visible: false,
      access: 'DbsInvoice',
      component: require('components/Modules/DBS/CreatingInstructions/Detail').default,
    },
    {
      path: '/creating-instructions',
      name: 'createOrder',
      access: 'DbsInvoice',
      component: require('components/Modules/DBS/CreatingInstructions/List').default,
    },
    {
      path: '/order/:id?',
      name: 'order',
      visible: false,
      access: 'DbsOrder',
      component: require('components/Modules/DBS/InstructionLists/Detail').default,
    },
    {
      path: '/instruction-lists',
      name: 'orderList',
      access: 'DbsOrder',
      component: require('components/Modules/DBS/InstructionLists/List').default,
    },
    {
      path: '/parameters',
      name: 'parameters',
      access: 'DbsAccount',
      component: require('components/Modules/DBS/Parameters/List').default,
    },
    {
      path: '/reporting',
      name: 'reporting',
      access: 'DbsInvoice',
      component: require('components/Modules/DBS/Reporting/List').default,
    },
  ]
};