import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { Divider, Radio } from 'antd';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { defaults } from "react-chartjs-2";
import { summaryCardTypes } from 'models';
import { httpMethods } from 'lib/constants';
import { CurrencyRates } from "components/UIComponents";
import PosraporCardTypes from "components/UIComponents/Dashboard/Posrapor.CardTypes";
import PosraporOnusNotOnus from "components/UIComponents/Dashboard/Posrapor.OnusNotOnus";
import { Summary, LineChart, PieChartContainer, BarChart } from 'components/UIComponents/Dashboard';


defaults.global.defaultFontFamily = "Yellix";
defaults.global.defaultFontSize = 14;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.chartQueue = {};
    this.state = {
      currencyCode: null,
    };
  };

  componentDidMount() {
    this.props.getBankCurrencies({ getMultiTenant: this.state.multiTenant });
    this.props.getCurrencies();
    this.props.getTenantBanks(this.props.multiTenantDashboard);
    this.getQueueData();
  };

  getQueueData = async () => {
    const charts = Object.keys(this.chartQueue);
    for (let i = 0; i < charts.length; i++) {
      await this.chartQueue[charts[i]]?.getChartData();
    }
  }

  changeCurrency = (e) => {
    this.setState({ currencyCode: e.target.value });
  };

  render() {
    let { filterCurrencies, currencies } = this.props;
    let { currencyCode } = this.state;
    const { data } = this.props.auth;
    let defaultCurrency = data ? (data.systemParameters ? JSON.parse(data.systemParameters).DefaultCurrency : null) : null;
    const hasAccessRights = data.accessRights.filter(x => ["InComingOutGoingTotals", "BankInComingTotals", "BankOutGoingTotals", "TotalBankBalances", "BankDailyReportLineCreditCardProgram", "BankDailyReportLineCreditCardType", "BankDailyReportLineOnUs"].includes(x.endPoint)).length !== 0;
    let _currencyCode = currencyCode || defaultCurrency;

    return (
      data.accessRights.length > 0 &&
      <div className={`page-content ${!hasAccessRights ? 'flex-center' : ''}`}>
        {
          hasAccessRights ?
            <>
              <Divider className="mb-50 dashboard-divider">
                <Radio.Group className="pull-right" buttonStyle="solid" value={currencyCode || defaultCurrency} onChange={this.changeCurrency}>
                  {currencies.single.data && currencies.single.data.filter(x => x).map(x => (
                    <Radio.Button className={`#${x}`} key={x} value={x}>
                      {x}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Divider>
              <CurrencyRates />


              <Summary {...{
                multiTenant: false,
                isCumulative: false,
                currencyCode: _currencyCode,
                currencies: filterCurrencies.data,
                endpoint: endpoints.posrapor.summary,
                options: [undefined, { isMoney: false }, undefined],
                typeSets: [summaryCardTypes.valorPureAmount, summaryCardTypes.transactionCount, summaryCardTypes.transactionPureAmount],
                method: httpMethods.get,
                titles: [i18n.t('lbl.valorPureAmount'), i18n.t('lbl.transactionPiece'), i18n.t('lbl.transactionPureAmount')],
                ref: el => this.chartQueue.Summary = el,
                getWithQueue: true
              }} />

              <BarChart {...{
                stateKey: "TotalBankBalances",
                multiTenant: false,
                isCumulative: false,
                currencyCode: _currencyCode,
                currencies: filterCurrencies.data,
                endpoint: endpoints.posrapor.totalBankBalances,
                method: httpMethods.get,
                title: i18n.t('lbl.bankBasedTransactionDistribution'),
                accessRight: { endpoint: "TotalBankBalances", method: "GET" },
                ref: el => this.chartQueue.TotalBankBalances = el,
                getWithQueue: true,
                range: {
                  ranges: [
                    { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                    { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                    { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                    { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                  ],
                  defaultSelected: 7
                },
              }} />

              <PieChartContainer
                {...{
                  currencyCode: _currencyCode,
                  multiTenant: false,
                  isCumulative: false,
                  includedInternalTransfer: false,
                  title: i18n.t('lbl.bankBasedPaymentDistribution'),
                  ref: el => this.chartQueue.BankBasedPaymentDistribution = el,
                  getWithQueue: true,
                  range: {
                    ranges: [
                      { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                      { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                      { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                      { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                    ],
                    defaultSelected: 7
                  },
                  charts: [
                    {
                      chartName: "incoming",
                      currencyCode,
                      onLineClick: this.onLineClick,
                      title: i18n.t('lbl.incoming'),
                      accessRight: { endpoint: "BankInComingTotals", method: "GET" },
                      type: "incomingTotals",
                      method: httpMethods.get,
                      endpoint: endpoints.posrapor.bankInComingTotals,
                    },
                    {
                      chartName: "outgoing",
                      currencyCode,
                      onLineClick: this.onLineClick,
                      title: i18n.t('lbl.outgoing'),
                      accessRight: { endpoint: "BankOutGoingTotals", method: "GET" },
                      type: "outgoingTotals",
                      method: httpMethods.get,
                      endpoint: endpoints.posrapor.bankOutGoingTotals,
                    }
                  ]
                }}
              />

              <LineChart {...{
                currencyCode: _currencyCode,
                multiTenant: false,
                isCumulative: false,
                includedInternalTransfer: false,
                endpoint: endpoints.posrapor.inComingOutGoingTotals,
                method: httpMethods.get,
                title: i18n.t('lbl.monthlyDistributionOfValueDatePayments'),
                accessRight: { endpoint: "InComingOutGoingTotals", method: "GET" },
                ref: el => this.chartQueue.InComingOutGoingTotals = el,
                getWithQueue: true
              }} />

              <BarChart {...{
                stateKey: "CreditCardProgram",
                multiTenant: false,
                isCumulative: false,
                currencyCode: _currencyCode,
                currencies: filterCurrencies.data,
                endpoint: endpoints.posrapor.bankDailyReportLineCreditCardProgram,
                method: httpMethods.get,
                title: i18n.t('posrapor.creditCardBasedTransactions'),
                accessRight: { endpoint: "BankDailyReportLineCreditCardProgram", method: "GET" },
                className: "mt-50",
                ref: el => this.chartQueue.BankDailyReportLineCreditCardProgram = el,
                getWithQueue: true,
                range: {
                  ranges: [
                    { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                    { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                    { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                    { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                  ],
                  defaultSelected: 7
                },
              }} />

              <PieChartContainer
                {...{
                  currencyCode: _currencyCode,
                  multiTenant: false,
                  isCumulative: false,
                  includedInternalTransfer: false,
                  title: i18n.t('posrapor.creditDebitCardUsageRate'),
                  ref: el => this.chartQueue.CreditDebitCardUsageRate = el,
                  getWithQueue: true,
                  range: {
                    ranges: [
                      { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                      { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                      { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                      { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                    ],
                    defaultSelected: 7
                  },
                  charts: [
                    {
                      chartName: "creditCardOrganizationType",
                      currencyCode,
                      onLineClick: this.onLineClick,
                      title: i18n.t('posrapor.creditDebitCardUsageRate'),
                      accessRight: { endpoint: "BankDailyReportLineCreditCardOrganizationType", method: "GET" },
                      type: "creditCardOrganizationType",
                      method: httpMethods.get,
                      endpoint: endpoints.posrapor.bankDailyReportLineCreditCardOrganizationType,
                    }
                  ]
                }}
              />
               <PosraporCardTypes
                {...{
                  ref: el => this.chartQueue.PosraporCardTypes = el,
                  currencyCode: _currencyCode,
                  currencies: filterCurrencies.data,
                  method: httpMethods.get,
                  endpoint: endpoints.posrapor.bankDailyReportLineCreditCardType,
                  accessRight: { endpoint: "BankDailyReportLineCreditCardType", method: "GET" },
                  title: i18n.t('lbl.bankBasedCreditCardTypeChart'),
                  getWithQueue: true,
                  range: {
                    ranges: [
                      { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                      { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                      { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                      { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                    ],
                    defaultSelected: 7
                  },
                }}
              /> 
              <PosraporOnusNotOnus
                {...{
                  ref: el => this.chartQueue.PosraporOnusNotOnus = el,
                  currencyCode: _currencyCode,
                  currencies: filterCurrencies.data,
                  method: httpMethods.get,
                  endpoint: endpoints.posrapor.bankDailyReportLineOnUs,
                  accessRight: { endpoint: "BankDailyReportLineOnUs", method: "GET" },
                  title: i18n.t('lbl.bankBasedOnusNotOnusChart'),
                  getWithQueue: true,
                  range: {
                    ranges: [
                      { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                      { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                      { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                      { value: 365, uniqueName: '#year', title: i18n.t('lbl.lastYear') },
                    ],
                    defaultSelected: 7
                  },
                }}
              />
            </>
            :
            <div className="no-access-right">
              <i className="fas fa-exclamation-triangle"></i>
              {i18n.t('msg.noAccessRightDashboard')}
            </div>
        }
      </div>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  currencies: common.currencies,
  filterCurrencies: common.filter.currencies,
  i18nextLng: common.i18nextLng
});
const mapDispatchToProps = (dispatch) => ({
  getBankCurrencies: (data) => dispatch(actions.getWithPost({ url: endpoints.tenant.bankAccountsGetCurrencies, key: 'currencies', data })),
  getCurrencies: (callback) => dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies' }, callback)),
  getTenantBanks: (multiTenant) => dispatch(actions.getFilter({ url: multiTenant ? endpoints.tenant.banks : endpoints.lookups.banks, key: 'tenantBanks' })),
  toggleData: (data) => dispatch(actions.toggleData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);