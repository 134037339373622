import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { DatePicker, Select, Input } from 'components/UIComponents/NetbankaComponents';
import { ChequeReportingModel } from 'models';
import actions from 'store/actions/cek';
import endpoints from 'config/endpoints';
import { Loading } from 'components/UIComponents';
import utils from 'lib';
import i18n from 'plugins/i18n';

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new ChequeReportingModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.reporting.data && !state.data.Id) {
      const data = new ChequeReportingModel({ ...props.reporting.data })
      return { data }
    }
    if (props.dialogClosing) props.onDialogClose(null, null)
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getReporting(this.id);
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    let { reporting, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={reporting.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>
    )
  }

  render() {
    const { data } = this.state;
    const { tenantBanks, reporting } = this.props;
    this.renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={reporting.loading}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    value={data.BankCode}
                    className="#bank"
                    options={tenantBanks.data}
                    optVal="Code"
                    optName={(bankData) => utils.cek.getBankName({ bankData })}
                    label={i18n.t('lbl.bankName')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.InsertedTime} label={i18n.t('lbl.insertedTime')} className="#InsertedTime" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={`${data.ChequeUserName} ${data.ChequeUserSurname}`} label={i18n.t('lbl.nameSurname')} className="#NameSurname" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.Title} label={i18n.t('lbl.titleU')} className="#Title" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.ChequeUserTckn} label={i18n.t('lbl.tc')} className="#ChequeUserTckn" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.ChequeUserVkn} label={i18n.t('lbl.taxNumber')} className="#ChequeUserVkn" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.ChequeCode} label={i18n.t('lbl.chequeNumber')} className="#ChequeCode" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.City} label={i18n.t('lbl.kesideCity')} className="#City" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.ChequeDate} label={i18n.t('lbl.kesideDate')} className="#ChequeDate" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={`${data.Amount} ${data.CurrencyCode}`} label={i18n.t('lbl.amount')} className="#Amount" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.Description} label={i18n.t('lbl.description')} className="#Description" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.PayRollId} label={i18n.t('lbl.payrollNumber')} className="#PayRollId" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input value={data.OperationStatusMessage} label={i18n.t('lbl.orderStatus')} className="#OperationStatusMessage" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ cek }) => ({
  reporting: cek.reportings.single,
  tenantBanks: cek.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getReporting: (id, callback) => dispatch(actions.get({ url: endpoints.cek.reporting, key: 'reportings', id }, callback)),
  clearState: () => dispatch(actions.clearState("reportings"))
});
export default connect(mapStateToProps, mapDispatchToProps)(Reporting);