export class ParameterModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.IsActive = data && data.IsActive ? data.IsActive : undefined;
    this.Description = data && data.Description ? data.Description : undefined;
    this.BankDbsCode1 = data && data.BankDbsCode1 ? data.BankDbsCode1 : undefined;
    this.BankDbsCode2 = data && data.BankDbsCode2 ? data.BankDbsCode2 : undefined;
    this.ServiceType = data && data.ServiceType ? data.ServiceType : undefined;
    this.ServiceTypeId = data && data.ServiceTypeId ? data.ServiceTypeId : undefined;
    this.Url = data && data.Url ? data.Url : undefined;
    this.Host = data && data.Host ? data.Host : undefined;
    this.Port = data && data.Port ? data.Port : undefined;
    this.Username = data && data.Username ? data.Username : undefined
    this.Password = data && data.Password ? data.Password : undefined
    this.UploadFolder = data && data.UploadFolder ? data.UploadFolder : undefined;
    this.DownloadFolder = data && data.DownloadFolder ? data.DownloadFolder : undefined;
    this.Deleted = data && data.Deleted ? data.Deleted : undefined;
    this.CurrencyCodes = data && data.CurrencyCodes ? data.CurrencyCodes : ['TRY']

  };
};
export class CustomerInfoModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.CurrentAccountId = data && data.CurrentAccountId ? data.CurrentAccountId : undefined;
    this.CustomerDbsCode = data && data.CustomerDbsCode ? data.CustomerDbsCode : undefined;
    this.CustomerDbsName = data && data.CustomerDbsName ? data.CustomerDbsName : undefined;
    this.Limit = data && data.Limit ? data.Limit : undefined;
    this.Risk = data && data.Risk ? data.Risk : undefined;
    this.InvoiceCount = data && data.InvoiceCount ? data.InvoiceCount : undefined;
    this.InvoiceSum = data && data.InvoiceSum ? data.InvoiceSum : undefined;
    this.Availability = data && data.Availability ? data.Availability : undefined;
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : undefined;
    this.Deleted = data && data.Deleted ? data.Deleted : undefined;
    this.Published = data && data.Published ? data.Published : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
  };
};

export class CreatingInstructionsModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.DbsOrderId= data && data.DbsOrderId ? data.DbsOrderId : undefined;
    this.CurrentAccountId = data && data.CurrentAccountId ? data.CurrentAccountId : undefined;
    this.InvoiceDate = data && data.InvoiceDate ? data.InvoiceDate : undefined;
    this.DueDate = data && data.DueDate ? data.DueDate : undefined;
    this.InvoiceNumber = data && data.InvoiceNumber ? data.InvoiceNumber : undefined
    this.InvoiceAmount = data && data.InvoiceAmount ? data.InvoiceAmount : undefined;
    this.CollectedAmount = data && data.CollectedAmount ? data.CollectedAmount : undefined;
    this.Status = data && data.Status ? data.Status : undefined;
    this.IsCollected = data && data.IsCollected ? data.IsCollected : undefined;
    this.IsUpdatable = data && data.IsUpdatable ? data.IsUpdatable : undefined;
    this.SendDirectly = data && data.SendDirectly ? data.SendDirectly : undefined;
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : undefined;
    this.Description = data && data.Description ? data.Description : undefined;
  };
};

export class InstructionListsModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.InsertedTime = data && data.InsertedTime ? data.InsertedTime : undefined;
    this.OperationStatusId = data && data.OperationStatusId ? data.OperationStatusId : undefined;
    this.TotalCount = data && data.TotalCount ? data.TotalCount : undefined;
    this.Description = data && data.Description ? data.Description : undefined;
    this.TotalAmount = data && data.TotalAmount ? data.TotalAmount : undefined;
  };
};

export class CreateOrderModel {
  constructor(data = null) {
    this.BankCode = data && data.BankCode ? data.BankCode : null;
    this.Description = data && data.Description ? data.Description : "";
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : null;
    this.InvoiceIds = data && data.InvoiceIds ? data.InvoiceIds : undefined;
  };
};

export class SendOrderModel {
  constructor(data = null) {
    this.BankCode = data && data.BankCode ? data.BankCode : null;
    this.Description = data && data.Description ? data.Description : "";
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : null;
    this.DbsOrderId = data && data.DbsOrderId ? data.DbsOrderId : undefined;
    this.InvoiceIds = data && data.InvoiceIds ? data.InvoiceIds : undefined;
  };
};

export class ReportingModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.CurrentAccountId = data && data.CurrentAccountId ? data.CurrentAccountId : undefined;
    this.DbsOrderId = data && data.DbsOrderId ? data.DbsOrderId : '';
    this.BankCode = data && data.BankCode ? data.BankCode : '';
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : null;
    this.BankStatusId = data && data.BankStatusId ? data.BankStatusId : null;
    this.ErpStatusId = data && data.ErpStatusId ? data.ErpStatusId : null;
    this.ErpCode = data && data.ErpCode ? data.ErpCode : null;
    this.ErpDescription = data && data.ErpDescription ? data.ErpDescription : null;
    this.CanBeManuelTransferred = data && data.CanBeManuelTransferred ? data.CanBeManuelTransferred : null;
    this.OperationStatusId = data && data.OperationStatusId ? data.OperationStatusId : null;
    this.OperationStatusMessage = data && data.OperationStatusMessage ? data.OperationStatusMessage : undefined;
    this.Description = data && data.Description ? data.Description : '';
    this.InvoiceDate = data && data.InvoiceDate ? data.InvoiceDate : '';
    this.DueDate = data && data.DueDate ? data.DueDate : null;
    this.InvoiceNumber = data && data.InvoiceNumber ? data.InvoiceNumber : null;
    this.InvoiceAmount = data && data.InvoiceAmount ? data.InvoiceAmount : null;
    this.CollectedAmount = data && data.CollectedAmount ? data.CollectedAmount : null;
    this.IsCollected = data && data.IsCollected ? data.IsCollected : null;
    this.IsUpdatable = data && data.IsUpdatable ? data.IsUpdatable : null;
    this.SendDirectly = data && data.SendDirectly ? data.SendDirectly : null;
    this.DeletedUserId = data && data.DeletedUserId ? data.DeletedUserId : null;
    this.DeletedTime = data && data.DeletedTime ? data.DeletedTime : null;
    this.UpdatedUserId = data && data.UpdatedUserId ? data.UpdatedUserId : null;
    this.UpdatedTime = data && data.UpdatedTime ? data.UpdatedTime : undefined;
    this.InsertedUserId = data && data.InsertedUserId ? data.InsertedUserId : '';
    this.InsertedTime = data && data.InsertedTime ? data.InsertedTime : '';
    this.Published = data && data.Published ? data.Published : null;
    this.Deleted = data && data.Deleted ? data.Deleted : null;
  };
};

