import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal } from 'antd';
import actions from 'store/actions/netekstre';
import { DataTable, ActionButtons, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import IncomingDetail from './IncomingDetail';
import OutgoingDetail from './OutgoingDetail';
import utils from 'lib';

const PageAccesses = {
  TenantCategories: "TenantCategories",
  SubTenantCategories: "SubTenantCategories"
}

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incomingLoading: false,
      outgoingLoading: false,
      incomingFilter: defaultFilters.netekstre.IncomingTotals({ TenantGuid: { eq: null } }),
      outgoingFilter: defaultFilters.netekstre.OutgoingTotals({ TenantGuid: { eq: null } }),
      index: null,
      getDataTrigger: undefined,
      access: PageAccesses.TenantCategories,
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id };
      props.getCategories(state.incomingFilter, 'incoming', parameters, endpoints.nte.subTenantCategories);
      props.getCategories(state.outgoingFilter, 'outgoing', parameters, endpoints.nte.subTenantCategories);
      return { getDataTrigger: props.getDataTrigger, incomingFilter: state.incomingFilter, outgoingFilter: state.outgoingFilter };
    }
    return null
  }

  componentDidMount() {
    const { getDataTrigger } = this.props;
    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getCategories('incoming');
      this.getCategories('outgoing');
      access = PageAccesses.TenantCategories;
    }
    else {
      access = PageAccesses.SubTenantCategories;
    }

    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })
  };

  getCategories = (type) => {
    const { getDataTrigger } = this.props;
    const filter = this.state[`${type}Filter`];

    if (getDataTrigger)
      this.props.getCategories(filter, type, { TenantId: getDataTrigger.id }, endpoints.nte.subTenantCategories);
    else
      this.props.getCategories(filter, type);

  };

  onSort = (category, key, type) => {
    let filter = this.state[`${category}Filter`];
    this.setState({ [`${category}Filter`]: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getCategories(category);
    });
  };

  onPageChange = (category, skip, top) => {
    let filter = this.state[`${category}Filter`];
    this.setState({
      [`${category}Filter`]: {
        ...filter,
        skip: skip,
        top: top,
      }
    }, () => this.getCategories(category))
  }

  onDeleteClick = () => {
    return new Promise((resolve) => {
      const modal = Modal.confirm();
      modal.update({
        title: i18n.t('msg.needToConfirmation'),
        content: <div dangerouslySetInnerHTML={{ __html: i18n.t('msg.deleteCategoryWarning') }} />,
        onOk: () => resolve(true),
        okText: i18n.t('btn.confirm'),
        cancelText: i18n.t('btn.cancel'),
      })
    })
  }

  render() {
    const { access } = this.state;
    const { incomingCategories, outgoingCategories, className, datatableTitle, getDataTrigger, datatableCollapsed, callback } = this.props;
    const incomingColumns = [
      {
        render: (row) =>
          <ActionButtons
            url={access}
            item={row}
            editUrl
            hasDelete
            getDatas={() => this.getCategories('incoming')}
            openDialog={this.datatable1 && this.datatable1.openDialog}
          />,
        sort: false, toggle: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.categoryName'), key: 'Name' },
      { label: i18n.t('lbl.code'), key: 'Code' },
    ];
    const outgoingColumns = [
      {
        render: (row) =>
          <ActionButtons
            url={access}
            item={row}
            editUrl
            hasDelete
            getDatas={() => this.getCategories('outgoing')}
            openDialog={this.datatable2 && this.datatable2.openDialog}
          />,
        sort: false, toggle: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.categoryName'), key: 'Name' },
      { label: i18n.t('lbl.code'), key: 'Code' },
    ];

    return (
      <FadeIn className={`page-content ${className || ''}`}>
        <Row gutter={20}>
          <CheckAccessRight {...{ ...incomingCategories, hasParent: getDataTrigger !== undefined }}>
            <Col md={24} lg={12} className={className ? '' : "mb-50"}>
              <DataTable
                wrappedComponentRef={el => this.datatable1 = el}
                access={access}
                order="1"
                onPageChange={(skip, top) => this.onPageChange('incoming', skip, top)}
                data={incomingCategories.list.data}
                onSort={(x, y) => this.onSort('incoming', x, y)}
                columns={incomingColumns}
                count={incomingCategories.list.count}
                loading={incomingCategories.list.loading}
                title={datatableTitle ? i18n.t('lbl.categoriesForTenant', { ...datatableTitle, type: i18n.t('lbl.incoming'), categoryType: i18n.t('lbl.general') }) : i18n.t('lbl.incomingCategories', { type: i18n.t('lbl.general') })}
                Component={IncomingDetail}
                collapsed={datatableCollapsed}
                dialogTitle={i18n.t('lbl.generalCategory')}
                getData={() => { this.getCategories('incoming'); callback && callback('incoming') }}
                disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
                deleteOptions={{
                  stateKey: "incomingCategoriesGeneral",
                  url: access,
                  baseUrl: baseUrls.nte,
                  deleteConfirmAction: this.onDeleteClick
                }}
                dialogOptions={{
                  endpoint: getDataTrigger && endpoints.nte.subTenantCategories,
                  tenantId: getDataTrigger && getDataTrigger.id
                }}
              />
            </Col>
          </CheckAccessRight>
          <CheckAccessRight {...{ ...outgoingCategories, hasParent: getDataTrigger !== undefined }}>
            <Col md={24} lg={12}>
              <DataTable
                wrappedComponentRef={el => this.datatable2 = el}
                onPageChange={(skip, top) => this.onPageChange('outgoing', skip, top)}
                access={access}
                order="2"
                data={outgoingCategories.list.data}
                onSort={(x, y) => this.onSort('outgoing', x, y)}
                columns={outgoingColumns}
                loading={outgoingCategories.list.loading}
                count={outgoingCategories.list.count}
                title={datatableTitle ? i18n.t('lbl.categoriesForTenant', { ...datatableTitle, type: i18n.t('lbl.outgoing'), categoryType: i18n.t('lbl.general') }) : i18n.t('lbl.outgoingCategories', { type: i18n.t('lbl.general') })}
                Component={OutgoingDetail}
                dialogTitle={i18n.t('lbl.generalCategory')}
                getData={() => { this.getCategories('outgoing'); callback && callback('outgoing') }}
                disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
                collapsed={datatableCollapsed}
                deleteOptions={{
                  stateKey: "outgoingCategoriesGeneral",
                  url: access,
                  baseUrl: baseUrls.nte,
                  deleteConfirmAction: this.onDeleteClick,
                }}
                dialogOptions={{
                  endpoint: getDataTrigger && endpoints.nte.subTenantCategories,
                  tenantId: getDataTrigger && getDataTrigger.id
                }}
              />
            </Col>
          </CheckAccessRight>
        </Row>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ netekstre, auth }) => ({
  auth,
  incomingCategories: netekstre.incomingCategoriesGeneral,
  outgoingCategories: netekstre.outgoingCategoriesGeneral,
});
const mapDispatchToProps = (dispatch) => ({
  getCategories: (filter, type, parameters, endpoint) => dispatch(actions.getAll({ filter, parameters, url: endpoint || endpoints.nte.tenantCategories, key: `${type}CategoriesGeneral`, isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Categories);