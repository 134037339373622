import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { defaultFilters, baseUrls } from 'lib/constants';
import { DataTable, Filter, ActionButtons, FadeIn, CheckAccessRight } from "components/UIComponents";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { formatDate } from 'helpers';
import Detail from './Detail';
import { Checkbox } from 'antd';
import utils from 'lib';

const PageAccesses = {
  TenantJobs: "TenantJob",
  SubTenantJobs: "SubTenantJob"
}

class Jobs extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin.TenantJob(),
    getDataTrigger: undefined,
    access: PageAccesses.TenantJobs
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id };
      const filter = state.filter;
      props.getTenantJobs(filter, parameters, endpoints.job.subTenantJob);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  componentDidMount() {
    const { getDataTrigger } = this.props;
    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getTenantJobs();
      access = PageAccesses.TenantJobs;
    }
    else {
      access = PageAccesses.SubTenantJobs;
    }
    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })
  };

  getTenantJobs = () => {
    const { getDataTrigger } = this.props;
    let { filter } = this.state;
    if (getDataTrigger)
      this.props.getTenantJobs(filter, { TenantId: getDataTrigger.id }, endpoints.job.subTenantJob);
    else
      this.props.getTenantJobs(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getTenantJobs);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: {
        ...filter, orderBy: [`${key} ${type}`]
      }
    }, this.getTenantJobs);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getTenantJobs);
  }

  renderActionButtons = row => {
    const { access } = this.state;
    return <ActionButtons url={access} editUrl item={row} getDatas={this.getTenantJobs} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { jobs, showFilter, className, datatableCollapsed, hasParent, datatableTitle, getDataTrigger } = this.props;
    const { access } = this.state
    let filters = [
      { label: i18n.t('lbl.lastRunDate', { add: `(${i18n.t('lbl.start')})` }), type: 'date', date: 'ge', keys: ['AfterRunningDate'], uniqueKey: '#afterRunningDateStart' },
      { label: i18n.t('lbl.lastRunDate', { add: `(${i18n.t('lbl.end')})` }), type: 'date', date: 'le', keys: ['AfterRunningDate'], uniqueKey: '#afterRunningDateEnd' },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Cron', 'JobName'], contains: true, uniqueKey: '#name' },
    ];
    let columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.jobName'), key: 'JobName', checkField: false },
      { label: i18n.t('lbl.cron'), key: 'Cron' },
      { label: i18n.t('lbl.lastRunDate'), key: 'AfterRunningDate', render: ({ AfterRunningDate }) => formatDate(AfterRunningDate, null, null, null, true) },
      { label: i18n.t('lbl.lastRun'), key: 'Status' },
      { label: i18n.t('lbl.jobStatus'), key: 'IsActive', render: (row) => <Checkbox checked={row.IsActive} /> },
    ];

    return (
      <div className={`page-content ${className || ''}`}>
        <FadeIn>
          <CheckAccessRight {...{ ...jobs, hasParent: getDataTrigger !== undefined }}>
            {
              showFilter !== false &&
              <Filter filters={filters} onFilter={this.onFilter} />
            }
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              access={access}
              history={this.props.history}
              newButton="openDialog"
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={jobs.list.data}
              loading={jobs.list.loading}
              title={datatableTitle || i18n.t('route.admin.jobs')}
              getData={this.getTenantJobs}
              count={jobs.list.count}
              Component={Detail}
              dialogTitle={i18n.t('lbl.job')}
              deleteOptions={{ stateKey: "jobs", url: access, baseUrl: baseUrls.job }}
              collapsed={datatableCollapsed}
              hasParent={hasParent}
              disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
              dialogOptions={{
                endpoint: getDataTrigger && endpoints.job.subTenantJob,
                tenantId: getDataTrigger && getDataTrigger.id
              }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  jobs: admin.jobs
});
const mapDispatchToProps = (dispatch) => ({
  getTenantJobs: (filter, parameters, endpoint) => dispatch(actions.getAll({ filter, parameters, url: endpoint || endpoints.job.tenantJob, key: 'jobs', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Jobs);