// export const modules = [
//   {
//     name: 'Netekstre',
//     path: 'netekstre',
//     code: 'NTE',
//     details: [
//       {
//         name: "assets",
//         value: "531.678.942",
//         unit: "₺",
//       }
//     ]
//   },
//   {
//     name: 'Dbs',
//     path: 'dbs',
//     code: 'DBS',
//     details: [
//       {
//         name: "pendingInvoices",
//         value: 2678,
//       },
//       {
//         name: "pendingOrders",
//         value: 4931
//       }
//     ]
//   },
//   {
//     name: 'Posrapor',
//     path: 'posrapor',
//     code: 'PSR',
//     details: [
//       {
//         name: "waitingTransferToERP",
//         value: "531",
//         unit: "Adet",
//       }
//     ]
//   },
//   {
//     name: 'Kredi',
//     path: 'kredi',
//     code: 'KRD',
//     details: [
//       {
//         name: "preConfirmCredit",
//         value: "25.000",
//         unit: "₺",
//       }
//     ]
//   },
//   {
//     name: 'Tös',
//     path: 'tos',
//     code: 'TÖS',
//     details: [
//       {
//         name: "totalUnpaidAmount",
//         value: "564.213.898",
//         unit: "₺",
//       }
//     ]
//   },
//   {
//     name: 'Çek',
//     path: 'cek',
//     code: 'ÇEK',
//     details: [
//       {
//         name: "totalCheckAmount",
//         value: "456.987.321",
//         unit: "₺",
//       }
//     ]
//   },
// ]

export const modules = [
  {
    id: 2,
    priority: 1,
    name: 'Netekstre',
    path: 'netekstre',
    code: 'NTE',
    beName: 'NTE',
    isActive: true,
    url: "https://www.netekstre.com/",
    // details: [
    //   {
    //     name: "assets",
    //     value: "531.678.942",
    //     unit: "₺",
    //   }
    // ],
    details: [
      {
        name: "pendingInvoices",
        value: 2678,
        unit: "₺",
      },
      {
        name: "pendingOrders",
        value: 4931,
        unit: "₺",
      },
      {
        name: "pendingOrders",
        value: 4931,
        unit: "₺",
      }
    ]
  },
  {
    id: 4,
    priority: 2,
    name: 'Posrapor',
    path: 'posrapor',
    code: 'PSR',
    beName: 'POS',
    isActive: true,
    url: "https://www.posrapor.com/",
    details: [
      {
        name: "waitingTransferToERP",
        value: "531",
        unit: "Adet",
      }
    ]
  },
  {
    id: 3,
    priority: 3,
    name: 'Dbs',
    path: 'dbs',
    code: 'DBS',
    beName: 'DBS',
    isActive: true,
    url: "https://www.netekstre.com/dbs/",
    details: [
      {
        name: "pendingInvoices",
        value: 2678,
      },
      {
        name: "pendingOrders",
        value: 4931
      }
    ]
  },
  // {
  //   id: null,
  //   priority: 4,
  //   name: 'Online Kredi',
  //   path: 'kredi',
  //   code: 'OKR',
  //   beName: 'OKR',
  //   isActive: false,
  //   url: null,
  //   details: [
  //     {
  //       name: "preConfirmCredit",
  //       value: "25.000",
  //       unit: "₺",
  //     }
  //   ]
  // },
  {
    id: 5,
    priority: 5,
    name: 'Tös',
    path: 'tos',
    code: 'TÖS',
    beName: 'TOS',
    isActive: true,
    url: null,
    details: [
      {
        name: "totalUnpaidAmount",
        value: "564.213.898",
        unit: "₺",
      }
    ]
  },
  {
    id: 6, //! be id iste
    priority: 6, //! necla önceliği öğren
    name: 'Çek/Senet',
    path: 'cek',
    code: 'ÇEK',
    beName: 'CEK',
    isActive: true,
    url: null, //! necla iste
    details: [
      {
        name: "totalCheckAmount",
        value: "456.987.321",
        unit: "₺",
      }
    ]
  },
  {
    id: null,
    priority: 4,
    name: 'Netahsilat',
    path: 'netahsilat',
    code: 'NTH',
    beName: 'NTH',
    isActive: true,
    url: null,
    details: [
      {
        name: "preConfirmCredit",
        value: "25.000",
        unit: "₺",
      }
    ]
  },
]