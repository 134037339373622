import React, { Component } from 'react';
import { Input, Tooltip, Icon } from 'antd';

class NBInput extends Component {
  state = { isFocused: '' };

  handleChange = (e) => {
    const { type, onChange, maxLength } = this.props

    switch (type) {
      case 'iban': if (e.length < 27)
        onChange && onChange(e);
        break;

      case 'phone': if (e.length < 12)
        onChange && onChange(e);
        break;

      case 'amount':
        // eslint-disable-next-line
        const isNumber = /^[-+]?\d+[\.\,]?\d?\d?$/;
        let change = false;

        if (e === "-" || e === "+")
          change = true;
        else if (e[0] === 0 && !e[1])
          change = false;
        else if ((isNumber.test(e) || e.length === 0))
          change = true;

        e = e.includes('.') ? e.replace('.', ',') : e;
        change && onChange && onChange(e);
        break;

      default: if (!maxLength || (maxLength && e.length < maxLength + 1))
        onChange && onChange(e);
        break;
    }
  };

  formatValue = (type, value) => {
    switch (type) {
      case 'iban':
        return value.replace(/(.{4})/g, '$1 ').trim();
      case 'stringNumber':
        return value.replace(/[^0-9]/, '');
      case 'phone':
        value = value.toString();
        return value.length < 2 ? '0' : (value.substr(0, 1) !== '0' ? '0' : '') + value.replace(/[^0-9]/, '');
      case 'amount':
        return value.toString().replace('.', ',');
      default:
        return value;
    }
  };

  render() {
    const { label, type, value, className, id, disabled, required, autoFocus, info, maxLength, minLength, step, max, min, suffix, rows, nextGen } = this.props;

    return (
      <div className={`nb nb-input ${!!disabled ? 'disabled' : ''}  ${nextGen ? 'nextGen' : ''}`}>
        <Input.TextArea
          rows={rows || 0}
          id={id}
          type={type}
          value={this.formatValue(type, value)}
          onChange={(e) => this.handleChange(type === 'iban' ? e.target.value.replace(/ /g, '').toUpperCase() : e.target.value)}
          required={!!required}
          disabled={!!disabled}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
          autoFocus={!!autoFocus}
          maxLength={maxLength || 512}
          minLength={minLength || null}
          className={`${value !== null && value !== undefined && value.toString() !== '' && 'is-filled'} ${className || ''}`}
          step={step}
          max={max}
          min={min}
          suffix={suffix}
        />
        {info &&
          <Tooltip title={info}>
            <Icon type="question-circle" className="form-tooltip" />
          </Tooltip>
        }
        <label className={(this.state.isFocused || (this.formatValue(type, value) && this.formatValue(type, value).toString() !== '')) ? 'focused' : ''}>{label || ''}</label>
      </div>
    );
  };
};

export default NBInput;