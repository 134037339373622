import React from 'react';
import CoverImg from "assets/img/register-cover.svg";

const LayoutCover = () => {
  return (
    <img alt="netfinans" src={CoverImg}/>
  )
}

export default LayoutCover;
