import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/posrapor';
import { ActionButtons, DataTable, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from "lib";
import { Icon } from 'antd';
import i18n from 'plugins/i18n';
import defaultFilters from 'lib/constants/defaultFilters';
import Account from './Detail';

class PosraporAccounts extends Component {
  state = {
    filter: defaultFilters.posrapor.Accounts(),
  };

  componentDidMount() {
    this.getPosraporAccounts();
    utils.posrapor.getBanks();
    utils.common.checkAccessToUpdateOrDelete('PosRaporAccount')
  };

  getPosraporAccounts = () => {
    const { filter } = this.state;
    this.props.getPosraporAccounts(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getPosraporAccounts);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getPosraporAccounts);
  }

  render() {
    const { accounts } = this.props;
    const columns = [
      { render: (row) => <ActionButtons url="PosRaporAccount" editUrl item={row} hasDelete getDatas={this.getPosraporAccounts} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'Bank/Name', render: (row) => utils.posrapor.getBankName({ data: row, dataField: 'Bank.EftCode' }), checkField: false },
      { label: i18n.t('posrapor.accountName'), key: 'Name' },
      { label: i18n.t('lbl.isActive'), key: 'IsActive', tdClass: 'text-center', render: ({ IsActive }) => <Icon style={{ color: IsActive ? '#7ac32a' : '#D46464', fontSize: 16 }} type={IsActive ? 'check' : 'close'} /> },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...accounts, single: accounts.list } }}>
            <DataTable
              count={accounts.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="PosRaporAccount"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={accounts.list.data}
              loading={accounts.list.loading}
              title={i18n.t('route.posrapor.accounts')}
              getData={this.getPosraporAccounts}
              newButton="openDialog"
              Component={Account}
              dialogTitle={i18n.t('lbl.account')}
              deleteOptions={{ stateKey: "accounts", url: "PosRaporAccount" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor }) => ({
  accounts: posrapor.accounts,
  banks: posrapor.filter.banks,
});
const mapDispatchToProps = (dispatch) => ({
  getPosraporAccounts: (filter) => dispatch(actions.getAll({ filter, url: endpoints.posrapor.posraporAccount, key: 'accounts' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PosraporAccounts);