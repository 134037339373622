const stateKeys = [
  "totalTransfer",
  "incomingTotals",
  "outgoingTotals",
  "summary",
  "currencies",
  "currencyRates",
  "tenantExchangeType",
  "barChartData",
  "avgReports",
  "balanceReports",
  "balances",
  "currencyBalances",
  "incomingCategoriesCustom",
  "outgoingCategoriesCustom",
  "incomingCategoriesGeneral",
  "outgoingCategoriesGeneral",
  "testData",
  "dynamicFields",
  "fieldDefinitions",
  "targetFields",
  "notifications",
  "roles",
  "users",
  "tenantBanks",
  "generalRules",
  "customRules",
  "rules",
  "tenantLimits",
  "transactions",
  "splitTransaction",
  "balancesDetail",
  "tenantBankParameters",
  "bankAccountsInfos",
  "matchingConditions",
  "lineChart"
]

const filterKeys = [
  "tenantBanks",
  "currencies",
  "transactionTransferTypes",
  "roles",
  "movementCategories",
  "transactionCategories",
  "targetFields",
  "sourceFields",
  "users",
  "templates",
  "accessRights",
  "mainCategories",
  "accessRightsLookup",
  "lookupTenantBanks",
  "incomingCategories",
  "outgoingCategories",
  "voucherTypes",
  "transferStatus",
  "typeCodes1",
  "typeCodes2",
  "regexFields",
  "categoryTypes",
  "banks",
  "currentAccounts",
  "dynamicProperties"
]

//#region State Generating - SHOULD NOT NE CHANGED 
const state = { filter: {} }
stateKeys.forEach(key => {
  state[key] = {
    list: { data: [], loading: null, count: null, error: null, statusCode: null },
    single: { data: null, saving: null, loading: null, error: null, statusCode: null }
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], error: null, loading: null
  }
})
export default state;
//#endregion