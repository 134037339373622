import React, { useState, useEffect } from 'react';
import { Card, Collapse, Tooltip } from 'antd';
import ReactSVG from 'react-svg';
import icons from 'assets/img/icons';
import { Typography, Loading } from 'components/UIComponents';
import i18n from 'plugins/i18n';
import { formatCurrency } from 'helpers';
import Fade from 'react-reveal/Fade';

const SummaryCard = ({ type, isCumulative, loading, data, multiTenant, title, options = { isMoney: true } }) => {
  const isMoney = options && options.isMoney;
  const [isClicked, setClicked] = useState(false);
  const suffix = isMoney && !loading && data && data[0] ? data[0].currencyCode : i18n.t('lbl.piece');
  const _data = data && data.length > 0 ? data.reduce((a, b) => a + b[type], 0) : null;
  const _dataText = !loading ? data && data.length ? formatCurrency({ data: _data, currencyCode: suffix, withFraction: isMoney, withSign: isMoney }) : null : '';

  useEffect(() => {
    !multiTenant && setClicked(false)
  }, [multiTenant])

  return (
    <Card className={`summary-card ${type} ${isClicked && multiTenant ? 'clicked' : ''}`} >
      <div className="summary-card__bg"></div>
      <Loading loading={loading}>
        {
          multiTenant ?
            <Collapse className="dashboard-collapse" expandIcon={() => <span></span>} accordion onChange={e => multiTenant && setClicked(!!e)}>
              <Collapse.Panel header={
                <>
                  <ReactSVG className="summary-icon" src={icons[type]} />
                  <Typography.H size={4} type="default" className="mt-15" >
                    {title || i18n.t(`lbl.${type}`, { add: isCumulative ? `(${i18n.t('lbl.cumulative')})` : '' })}
                  </Typography.H>
                  <Typography.H size={2} className="bold" >
                    {_dataText || i18n.t('msg.noAccessRightData')}
                  </Typography.H>
                </>
              } key="1">
                <Fade right cascade>
                  <div className="statement-container">
                    {
                      data &&
                      data.sort((a, b) => a.tenantName.localeCompare(b.tenantName)).map((x, i) =>
                        <div className="statement-row" key={i}>
                          <div>
                            <Tooltip placement="left" title={x.tenantName}>
                              <b> {x.tenantName}</b>
                            </Tooltip>
                          </div>
                          <div>
                            <span className="text-green"><b>{formatCurrency({ data: x[type], currencyCode: x.currencyCode })}</b> </span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </Fade>
              </Collapse.Panel>
            </Collapse>
            :
            <>
              <ReactSVG className="summary-icon" src={icons[type]} />
              <Typography.H size={4} type="default" className="mt-15" >
                {title || i18n.t(`lbl.${type}`, { add: isCumulative ? `(${i18n.t('lbl.cumulative')})` : '' })}
              </Typography.H>
              <Typography.H size={2} className="bold" >
                {_dataText || i18n.t('msg.noAccessRightData')}
              </Typography.H>
            </>
        }
      </Loading>
    </Card>
  )
}

export default SummaryCard;
