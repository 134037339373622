import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { currentAccountTypes, defaultFilters, reportDisplayTypes } from 'lib/constants';
import { DataTable, BalanceReportsButton, Filter, Parameter } from 'components/UIComponents';
import { formatCurrency, formatIBAN } from 'helpers';
import _ from 'lodash';
import moment from "moment";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n'
import { getKey } from 'lib/constants/defaultFilters';
import utils from 'lib';

class BalanceReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      key: getKey(),
      filter: defaultFilters.netekstre.BalanceReports(),
      currentBank: null,
      accountCodes: [],
      planCodes: [],
      parameters: {
        displayType: '0',
      },
    };
  };

  componentDidMount() {
    this.getBalanceReports();
    this.getFilterAccounts();
    utils.common.getTenantBanks();
    utils.common.getCurrencies();

    let key = getKey();
    let accountCodeFilter = this.props.currentFilter[key] && this.props.currentFilter[key].filter && this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.Code)) ? this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.Code)).or.find(x => x.Code).Code.contains : undefined;
    accountCodeFilter && this.accountCodeSearch(accountCodeFilter);

  };

  getBalanceReports = () => {
    let filter = _.cloneDeep(this.state.filter);
    const { parameters } = this.state;
    let filters = this.props.currentFilter['netekstre/reports/balances'];
    if (!(filters && filters.fields && filters.fields[0])) {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      filter.filter.and ? filter.filter.and.push({ recordDate: date }) : filter.filter.and = [{ recordDate: date }];
    }
    this.props.getBalanceReports(filter, parameters);
  };

  getFilterAccounts = () => {
    this.props.getFilterAccounts();
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getBalanceReports);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getBalanceReports);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getBalanceReports);
  };

  onSelect = (value) => {
    this.setState({ currentBank: value });
  };

  getCurrentName = (row) => {
    let item = currentAccountTypes.find(x => x.value === row.currentAccountType);
    return item ? item.name : '';
  };

  onCallback = (e, type) => {
    if (!e) this.setState({ [type]: [] });
  };

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  accountCodeClean = () => {
    this.props.getAccountCodes('');
  }

  onParameterChange = (key, value) => {
    let { parameters, filter } = this.state;
    parameters[key] = value;
    this.setState({ parameters }, () => this.getBalanceReports(filter))
  }

  getBankName = (row) => {
    let bank = this.props.tenantBanks.data && row ? this.props.tenantBanks.data.find(x => x.bankEftCode === row.BankEftCode) : null
    return bank ? <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment> : '';
  };

  getBalanceReportsButton = (row, selectedDate) => {
    return (
      <BalanceReportsButton transactionDate={selectedDate} bankAccountId={row.BankAccountId} title={this.getBankName(row)} currencyCode={row.CurrencyCode} />
    )
  }

  render() {
    let filter = _.cloneDeep(this.state.filter);
    let { currentBank, parameters } = this.state;
    let { balanceReports, tenantBanks, filterBanks, currencies } = this.props;
    let reportFilters = this.props.currentFilter['netekstre/reports/balances'];
    // let parameters = {};

    let selectedDate = new Date(), nextDate = new Date(), selectedString = null, nextString = null;
    selectedDate.setDate(selectedDate.getDate() - 1);
    if (reportFilters && reportFilters.fields && reportFilters.fields[0]) {
      selectedDate = new Date(reportFilters.fields[0]);
      nextDate = new Date(reportFilters.fields[0]);
      nextDate.setDate(nextDate.getDate());
    }
    let currentDate = new Date(selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    selectedString = currentDate.toISOString().split('T')[0].split('-').reverse().join('.');
    nextDate = moment(nextDate).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? moment(nextDate).add("day", -1)._d : moment(nextDate)._d;
    nextString = moment(nextDate).format('DD.MM.YYYY');

    let currencyData = _.groupBy(balanceReports.data, 'currencyCode');
    let sumData = {};
    let sumRows = [];
    Object.keys(currencyData).forEach((key, i) => {
      sumData[key] = {
        openingBalance: _.sumBy(currencyData[key], 'openingBalance'),
        incomingTotal: _.sumBy(currencyData[key], 'incomingTotal'),
        outgoingTotal: _.sumBy(currencyData[key], 'outgoingTotal'),
        closingBalance: _.sumBy(currencyData[key], 'closingBalance'),
        currencyCode: key,
        isSumRow: true,
        rowIndex: i
      };
      sumRows.push(sumData[key]);
    });

    if (!(reportFilters && reportFilters.fields && reportFilters.fields[0])) {
      filter.filter.and ? filter.filter.and.push({ recordDate: moment().startOf('day')._d }) : filter.filter.and = [{ recordDate: moment().add("day", -1).startOf('day')._d }];
    }

    let _accounts = filterBanks.data ? (currentBank && currentBank.length) ? filterBanks.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : filterBanks.data : [];

    let filters = [
      { label: i18n.t('lbl.date'), type: 'date', date: 'eq', keys: ['recordDate'], uniqueKey: '#recordDate', defaultValue: selectedDate.toISOString(), allowClear: false },
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'bankEftCode', name: (bank) => <React.Fragment><img src={bank && bank.logoSmall ? bank.logoSmall : ''} className="bank-icon" alt="" /> {bank && bank.name ? bank.name : ''}</React.Fragment>, keys: ['bankEftCode'], showAll: true, callback: this.onSelect, uniqueKey: '#bank', multiple: true, clear: ['2'] },
      { label: i18n.t('lbl.currencyCode'), options: currencies.data, type: 'select', showAll: true, value: 'currencyCode', name: 'currencyName', keys: ['currencyCode'], uniqueKey: '#currencyCode', multiple: true },
    ];

    let _parameters = [
      { type: 'radio', key: "displayType", uniqueKey: '#displayType', options: reportDisplayTypes, defaultValue: '0' },
    ]
    let columns = [
      { label: i18n.t('lbl.bank'), key: 'BankName', render: this.getBankName },
      { label: <>{i18n.t('lbl.openingBalance')}{selectedString ? ` (${selectedString})` : ''}</>, key: 'OpeningBalance', render: (row) => formatCurrency({ data: row.OpeningBalance, currencyCode: row.CurrencyCode, withColor: true }), tdClass: 'text-right pr-60', thClass: 'text-right' },
      { label: i18n.t('lbl.incomingTotal'), key: 'IncomingTotal', render: (row) => formatCurrency({ data: row.IncomingTotal, currencyCode: row.CurrencyCode, withSign: false }), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.outgoingTotal'), key: 'OutgoingTotal', render: (row) => formatCurrency({ data: row.OutgoingTotal, currencyCode: row.CurrencyCode, withSign: false }), tdClass: 'text-right', thClass: 'text-right' },
      { label: <>{i18n.t('lbl.closingBalance')}{nextString ? ` (${nextString})` : ''}</>, key: 'ClosingBalance', render: (row) => formatCurrency({ data: row.ClosingBalance, currencyCode: row.CurrencyCode, withColor: true }), tdClass: 'text-right pr-60', thClass: 'text-right' },
    ];

    if (parameters.displayType === '1') {
      columns.insert(0, { label: i18n.t('lbl.detail'), render: (row) => this.getBalanceReportsButton(row, nextDate), sort: false, notIncluded: true, key: 'BankEftCode' },)
      columns.insert(2, { label: i18n.t('lbl.accountName'), key: 'AccountName' })
      columns.insert(3, { label: i18n.t('lbl.bankAccountNumber'), key: 'AccountNumber', })
      columns.insert(4, { label: i18n.t('lbl.iban'), key: 'Iban', sort: false, render: (row) => formatIBAN(row.Iban) })
      filters.push({ label: i18n.t('lbl.accountName'), type: 'select', search: true, options: _accounts, value: 'id', name: 'name', keys: ['accountId'], uniqueKey: '#accountId', multiple: true, guid: true })
    }

    return (
      <div className="page-content">
        <Filter hasCurrent={selectedDate === new Date()} filters={filters} onFilter={this.onFilter} />
        <Parameter parameters={_parameters} onChange={this.onParameterChange} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          onPageChange={this.onPageChange}
          access="Transaction"
          history={this.props.history}
          onSort={this.onSort}
          columns={columns}
          count={balanceReports.list.count}
          data={balanceReports.list.data ? [...balanceReports.list.data, ...sumRows] : []}
          loading={balanceReports.list.loading}
          excel={{ url: "ExportBalanceReports", filter: { filter: filter.filter, expand: filter.expand }, parameters, module: "nte" }}
          title={i18n.t('route.netekstre.balancesReports')}
          getData={this.getBalanceReports}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ common, netekstre }) => ({
  filterBanks: common.filter.bankAccounts,
  tenantBanks: common.filter.tenantBanks,
  currencies: common.filter.currencies,
  currentFilter: common.currentFilter,

  balanceReports: netekstre.balanceReports,
});
const mapDispatchToProps = (dispatch) => ({
  getBalanceReports: (filter, parameters) => dispatch(netekstreActions.getAll({ filter, url: endpoints.nte.balanceReports, key: 'balanceReports', parameters, isNewODataStructure: true })),
  getFilterAccounts: (filter) => dispatch(commonActions.getFilter({ filter, url: endpoints.lookups.bankAccounts, key: 'bankAccounts' })),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BalanceReports);