export const GET_ALL_NETAHSILAT = "GET_ALL_NETAHSILAT";
export const GET_ALL_NETAHSILAT_SUCCESS = "GET_ALL_NETAHSILAT_SUCCESS";
export const GET_ALL_NETAHSILAT_FAILURE = "GET_ALL_NETAHSILAT_FAILURE";
export const GET_NETAHSILAT = "GET_NETAHSILAT";
export const GET_NETAHSILAT_SUCCESS = "GET_NETAHSILAT_SUCCESS";
export const GET_NETAHSILAT_FAILURE = "GET_NETAHSILAT_FAILURE";
export const POST_NETAHSILAT = "POST_NETAHSILAT";
export const POST_NETAHSILAT_SUCCESS = "POST_NETAHSILAT_SUCCESS";
export const POST_NETAHSILAT_FAILURE = "POST_NETAHSILAT_FAILURE";
export const PUT_NETAHSILAT = "PUT_NETAHSILAT";
export const PUT_NETAHSILAT_SUCCESS = "PUT_NETAHSILAT_SUCCESS";
export const PUT_NETAHSILAT_FAILURE = "PUT_NETAHSILAT_FAILURE";
export const DELETE_NETAHSILAT = "DELETE_NETAHSILAT";
export const DELETE_NETAHSILAT_SUCCESS = "DELETE_NETAHSILAT_SUCCESS";
export const DELETE_NETAHSILAT_FAILURE = "DELETE_NETAHSILAT_FAILURE";
export const PATCH_NETAHSILAT = "PATCH_NETAHSILAT";
export const PATCH_NETAHSILAT_SUCCESS = "PATCH_NETAHSILAT_SUCCESS";
export const PATCH_NETAHSILAT_FAILURE = "PATCH_NETAHSILAT_FAILURE";
export const GET_FILTER_NETAHSILAT = "GET_FILTER_NETAHSILAT";
export const GET_FILTER_NETAHSILAT_SUCCESS = "GET_FILTER_NETAHSILAT_SUCCESS";
export const GET_FILTER_NETAHSILAT_FAILURE = "GET_FILTER_NETAHSILAT_FAILURE";
export const CLEAR_STATE_NETAHSILAT = "CLEAR_STATE_NETAHSILAT";
export const CLEAR_FILTER_NETAHSILAT = "CLEAR_FILTER_NETAHSILAT";
export const GET_WITH_POST_NETAHSILAT = "GET_WITH_POST_NETAHSILAT";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_NETAHSILAT, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_NETAHSILAT_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_NETAHSILAT_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_NETAHSILAT, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_NETAHSILAT_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_NETAHSILAT_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_NETAHSILAT, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_NETAHSILAT_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_NETAHSILAT_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_NETAHSILAT, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_NETAHSILAT_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_NETAHSILAT_FAILURE, payload })
  },

  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_NETAHSILAT, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_NETAHSILAT, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_NETAHSILAT_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_NETAHSILAT_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_NETAHSILAT, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_NETAHSILAT_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_NETAHSILAT_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_NETAHSILAT, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_NETAHSILAT_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_NETAHSILAT_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key, options) => {
    return ({ type: CLEAR_STATE_NETAHSILAT, key, options })
  },
  clearFilter: (key) => {
    return ({ type: CLEAR_FILTER_NETAHSILAT, key })
  }
  //#endregion
}

export default actions;