export const pieChart = ({ multiTenant }) => {
  return {
    plugins: {
      labels: {
        render: 'percentage',
        fontColor: '#fff'
      }
    },
    hover: {
      onHover: function (e, v) { this.ctx.canvas.style.cursor = v[0] && multiTenant ? "pointer" : "default" },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'right'
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function (tooltipItem, data) {
          var indice = tooltipItem.index;
          let str = data.datasets[0].data[indice].toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          str += str.indexOf(',') !== -1 ? '' : ',00';
          let strs = str.split(',');
          if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
          return data.labels[indice] + ': ' + strs.join(',');
        }
      }
    },
  }
};