import React from 'react';
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/tos';
import _ from 'lodash';


export default class TosUtils {

  static getBankName({ data = null, dataField = 'BankCode', listKeys = 'tenantBanks', listField = 'Code', bankData = null, className = '' }) {
    let list, bank;
    if (!bankData) {
      list = _.get(store.getState().tos.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === _.get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall || bank.LogoSmall} className={`bank-icon ${className}`} alt="" /> {bank.name || bank.Name}</> : '';
  }

  static getTenantBanks(field = 'TenantId') {
    const filter = {
      orderBy: ['Name asc'],
      filter: { [field]: { any: { TenantId: { type: 'guid', value: store.getState().auth.data.tenantId } } } }
    }
    dispatchers.getTenantBanks(filter);
  }

  static getTenantCurrencies() {
    const filter = {
      filter: { AccountCurrencies: { any: { TenantId: { type: 'guid', value: store.getState().auth.data.tenantId } } } }
    }
    dispatchers.getTenantCurrencies(filter);
  }

}

const dispatchers = {
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.tos.tenantBanks, key: 'tenantBanks', isNewODataStructure: true })),
  getTenantCurrencies: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.tos.tenantCurrencies, key: 'currencies' })),
}
