import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Tabs, Checkbox, Tree } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import _ from 'lodash';
import { UserGroupModel } from 'models';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { compareFields } from 'helpers';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new UserGroupModel(),
      InitialState: new UserGroupModel(),
      search: '',
      bank: 'all',
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.roles.single.data && props.roles.single.saving === null && !state.data.id) {
      const data = new UserGroupModel({ ...props.roles.single.data, rights: props.roles.single.data.rights.map(right => right.toString()) });
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.roles.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };


  componentDidMount() {
    const { userHasNetekstreModule } = this.props
    this.props.getAccessRights();
    this.props.getAccessRightsLookup();
    if (userHasNetekstreModule) this.props.getTransactionCategories({ filter: { or: [{ ParentId: { ne: null } }, { CategoryType: 0 }] } });

    if (this.id) this.props.getRoles(this.id);
    else {
      this.props.getTenantBanks((response) => this.setCallbackData('tenantBankModels', 'tenantBankId', response));
      this.props.getBankAccounts((response) => this.setCallbackData('bankAccountModels', 'bankAccountId', response));
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  updateData = (field, e, index) => {
    let { data } = this.state;
    data[field][index].checked = e.target.checked;
    this.setState({ data });
  };

  updateSelectData = (field, prop, e) => {
    let { data } = this.state;
    let _item = data[field].find(x => x[prop].toString() === e[0].toString())
    _item.checked = !_item.checked;
    this.setState({ data });
  };

  setCallbackData = (field, prop, values) => {
    let { data } = this.state;
    data[field] = values.map(x => { x[prop] = x.Id || x.id; delete x.Id; delete x.id; return x; });
    this.setState({ data });
  }

  checkAll = (field, val) => {
    let { data } = this.state;
    data[field].map(x => x.checked = val);
    this.setState({ data });
  };

  updateCheckboxData = (field, id) => {
    let { data } = this.state;
    let item = data[field].find(x => x === id);
    item ? data[field].splice(data[field].indexOf(id), 1) : data[field].push(id);
    this.setState({ data });
  }

  updateCheckboxCheckAll = (field, val) => {
    let { data } = this.state;
    val ? this.props[field].data.map(x => data[field].push(x.Id)) : data[field] = []
    this.setState({ data });
  };

  update = (key, value) => {
    let data = _.cloneDeep(this.state.data);
    this.setState({ data: { ...data, [key]: value } });
  };

  updateRights = e => {
    const { data } = this.state;
    let _rights = e.filter(x => x.indexOf('x') === -1);
    data.rights = _rights;
    this.setState({ data })
  }

  save = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let { roles, dialogCloseRequest } = this.props;
    let _data = _.cloneDeep(data);
    this.props.form.validateFields(error => {
      if (!error) {

        this.setState({ saving: true });
        _data.roleCategoryModels = compareFields('roleCategoryModels', _data, this.id ? roles : null);
        _data.tenantBankModels = compareFields('tenantBankModels', _data, this.id ? roles : null);
        _data.bankAccountModels = compareFields('bankAccountModels', _data, this.id ? roles : null);
        // _data.rights = _data.rights.map(x => parseInt(x))

        let method = this.id ? httpMethods.put : httpMethods.post;
        this.props.saveData(_data, method, (response) => {
          if (response) {
            this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
          }
        })
      }
    });
  };

  renderTreeNodes = data => {
    return (
      data.map(item => {

        let _children = null;

        if (item.accessRightCategoriesRoles && item.accessRightCategoriesRoles.length) _children = item.accessRightCategoriesRoles;
        else if (item.roleTenantAccessRightModels && item.roleTenantAccessRightModels.length) _children = item.roleTenantAccessRightModels;

        if (_children) {
          return (
            <Tree.TreeNode title={item.name} key={`x-${item.name}-${item.id}`} dataRef={item}>
              {this.renderTreeNodes(_children)}
            </Tree.TreeNode>
          );
        }
        return <Tree.TreeNode checked={true} key={`${item.id}`} title={item.name} />;
      })
    )
  }

  renderDialogButtons = checkAccessRights => {
    let { roles, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={roles.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={roles.single.loading} form="roles" htmlType="submit" className="success #save" icon="save" loading={roles.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      roles.single.saving,
      roles.single.loading
    )
  }

  render() {
    const { tenantBanks, accessRights, dialogCloseRequest, roles, transactionCategoryModels, userHasNetekstreModule } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, data, bank } = this.state;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...roles, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="roles">
              <Row gutter={20}>
                <Col xs={24} sm={24}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input label={i18n.t('lbl.name')} onChange={(e) => this.update('name', e)} className="#name" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20} className="mb-20">
                <Col xs={24} md={24}>
                  <Tabs type="card">
                    <Tabs.TabPane tab={i18n.t('lbl.permissions')} key="1">
                      <React.Fragment>
                        <div className="card-list">

                          <Loading loading={accessRights.loading}>
                            {
                              accessRights.data && accessRights.data.length &&
                              <Tree
                                checkable
                                selectable={false}
                                onCheck={this.updateRights}
                                defaultCheckedKeys={data.rights}
                              >
                                {this.renderTreeNodes(accessRights.data)}
                              </Tree>
                            }
                          </Loading>
                        </div>
                      </React.Fragment>
                    </Tabs.TabPane>
                    {
                      userHasNetekstreModule &&
                      <Tabs.TabPane tab={i18n.t('lbl.movementCategories')} key="2">
                        <div className="card-list">
                          <Row>
                            <Checkbox className="#checkAllCategories" checked={transactionCategoryModels.data.map(x => x.Id).every(x => data.transactionCategoryModels.find(y => y === x))} onChange={(e) => this.updateCheckboxCheckAll('transactionCategoryModels', e.target.checked)}>{i18n.t('lbl.all')}</Checkbox>
                          </Row>
                          {
                            transactionCategoryModels.data &&
                            transactionCategoryModels.data.map((x, i) => (
                              <Row key={i}>
                                <Checkbox className={`#transactionCategoryModels${i}`} checked={!!data.transactionCategoryModels.find(t => t === x.Id)} onChange={(e) => this.updateCheckboxData("transactionCategoryModels", x.Id, e)}>
                                  {x.Name}
                                </Checkbox>
                              </Row>
                            ))
                          }
                        </div>
                      </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab={i18n.t('lbl.banks')} key="3">
                      <div className="card-list">
                        <Row><Checkbox className="#checkAllBanks" checked={data.tenantBankModels.find(x => !x.checked) ? false : true} onChange={(e) => this.checkAll('tenantBankModels', e.target.checked)}>{i18n.t('lbl.all')}</Checkbox></Row>
                        {data.tenantBankModels &&
                          data.tenantBankModels.map((x, i) => (
                            <Row key={i}>
                              <Checkbox className="#checkBank" checked={x.checked} onChange={(e) => this.updateData("tenantBankModels", e, i)}>
                                {x.name}
                              </Checkbox>
                            </Row>
                          ))}
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t('lbl.bankAccounts')} key="4">
                      <Row className="mb-20" gutter={20}>
                        <Col xs={12}>
                          <Select className="#bank" label={i18n.t('lbl.bank')} value={bank} onChange={(e) => this.setState({ bank: e })} options={[{ bankEftCode: 'all', name: i18n.t('lbl.all') }, ...tenantBanks.data || []]} optVal="bankEftCode" optName="name" />
                        </Col>
                      </Row>
                      <div className="card-list">
                        <Row><Checkbox className="#checkAllBankAccounts" checked={data.bankAccountModels.find(x => !x.checked) ? false : true} onChange={(e) => this.checkAll('bankAccountModels', e.target.checked)}>{i18n.t('lbl.all')}</Checkbox></Row>
                        {data.bankAccountModels &&
                          data.bankAccountModels.map((x, i) => (
                            (bank === x.bankEftCode || bank === "all") &&
                            <Row key={i}>
                              <Checkbox className="#checkBankAccount" checked={x.checked} onChange={(e) => this.updateData("bankAccountModels", e, i)}>
                                {x.name}
                              </Checkbox>
                            </Row>
                          ))
                        }
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common, auth }) => ({
  userHasNetekstreModule: auth.data.modules.find(x => x.name === "NTE"),
  roles: common.roles,
  tenantBanks: common.filter.tenantBanks,
  bankAccounts: common.filter.bankAccounts,
  accessRights: netekstre.filter.accessRights,
  accessRightsLookup: netekstre.filter.accessRightsLookup,
  transactionCategoryModels: netekstre.filter.transactionCategories,
});
const mapDispatchToProps = (dispatch) => ({
  getRoles: (id) => dispatch(commonActions.get({ url: endpoints.tenant.roles, key: 'roles', id })),
  saveData: (data, method, callback) => dispatch(commonActions[method]({ url: endpoints.tenant.roles, key: 'roles', data }, callback)),
  getTenantBanks: (callback) => dispatch(commonActions.getFilter({ url: endpoints.lookups.tenantBanks, key: 'tenantBanks' }, callback)),
  getBankAccounts: (callback) => dispatch(commonActions.getFilter({ url: endpoints.lookups.bankAccounts, key: 'bankAccounts' }, callback)),
  clearState: () => dispatch(commonActions.clearState("roles")),

  getTransactionCategories: (filter) => dispatch(netekstreActions.getFilter({ url: endpoints.nte.tenantCategories, key: 'transactionCategories', isNewODataStructure: true, filter })),
  getAccessRights: (callback) => dispatch(netekstreActions.getFilter({ url: endpoints.tenant.accessRights, key: 'accessRights' }, callback)),
  getAccessRightsLookup: () => dispatch(netekstreActions.getFilter({ url: endpoints.lookups.accessRights, key: 'accessRightsLookup' })),
});
const RoleForm = Form.create()(Role);
export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);