import React from 'react';
import { Tooltip, Progress as AntProgress } from "antd";

const Progress = ({ used, limit, contractualType, positive = false }) => {
  let _used, _limit, _percent, _status;
  _used = used ? used : 0;
  _limit = limit ? limit : 0;
  _percent = _limit ? Math.ceil((100 * _used / _limit)) : 0;
  _status = _percent > 70 ? `almost-full ${positive ? 'positive' : ''}` : _percent > 50 ? 'warning-text' : '';
  
  return (
    <Tooltip title={`${_used}${limit ? `/${_limit}` : ''}`}>
      <span className={`mr-10 progress-span ${_status} `}>
        {`${_used}`}
      </span>
      <AntProgress
        className={`company-progress ${_status}`}
        percent={_limit ? Math.ceil((100 * _used / _limit)) : 0}
        status="active"
        showInfo={!(limit === undefined || limit === null)}
      />
    </Tooltip>
  )
}

export default Progress;
