import config from './'

function getUrl(endpoint, api = config.netekstreUrl, isLookup = false, lookupTermPlural = true) {
  return `${api}${isLookup ? lookupTermPlural ? config.lookups : config.lookup : ''}${endpoint}`
}

export default {
  auth: {
    getTokenInfo: getUrl('Users/GetTokenInfo', config.tenantUrl),
    login: getUrl('account/login', config.authUrl),
    verifyTwoFactor: getUrl('account/verify-twofactor', config.authUrl),
    passwordsGetByEmail: getUrl('account/forgotpassword', config.authUrl),
    verifyOtp: getUrl('account/verifyotp', config.authUrl),
    passwords: getUrl('account/resetpassword', config.authUrl),
    setpwd: getUrl('member/setpwd', config.authUrl),
    resendotp: (id) => getUrl('account/resendotp/' + id, config.authUrl),
    changePassword: getUrl('account/changepassword/', config.authUrl),

    getMemberByGuid: (id) => getUrl('Members/GetMemberByGuid/' + id, config.authUrl),
    logout: getUrl('account/logout', config.authUrl),
    membersGetMemberByGuid: (guid) => getUrl('Members/GetMemberByGuid/ ' + guid, config.authUrl),
    doBlockedUserToActive: (id) => getUrl('account/DoBlockedUserToActive/' + id, config.authUrl),
    resendOtp: (id) => getUrl('account/resend-twofactor/' + id, config.authUrl),
  },
  dbs: {
    dbsAccount: getUrl('DbsAccount', config.dbsUrl),
    notification: getUrl('Notification', config.dbsUrl),
    customerDbsInfo: getUrl('CustomerDbsInfo', config.dbsUrl),
    dbs: getUrl('DBS', config.dbsUrl),
    reporting: getUrl('DbsInvoice', config.dbsUrl),
    dbsInvoice: getUrl('DbsInvoice', config.dbsUrl),
    dbsInvoiceAggregate: getUrl('DbsInvoice.Aggregate', config.dbsUrl),
    dbsOrder: getUrl('DbsOrder', config.dbsUrl),
    bank: getUrl('Bank', config.dbsUrl),
    bankAccount: getUrl('BankAccount', config.dbsUrl),
    currentAccount: getUrl('CurrentAccount', config.dbsUrl),
    tenant: getUrl('Tenant', config.dbsUrl),
    tenantBank: getUrl('TenantBank', config.dbsUrl),
    login: getUrl('Login', config.dbsUrl),
    signIn: getUrl('SignIn', config.dbsUrl),
    currencies: getUrl('DbsCurrency', config.dbsUrl),
    tenantCurrencies: getUrl('Currency', config.dbsUrl),
    createOrder: getUrl('DbsOrder.Create', config.dbsUrl),
    sendOrder: getUrl('DbsOrder.Send', config.dbsUrl),
    operationStatus: getUrl('OperationStatus', config.dbsUrl),
    accountParameter: getUrl('AccountParameter', config.dbsUrl),
    home: getUrl('DbsInvoice.PaymentDashboard', config.dbsUrl),
    totalBalances: getUrl('DbsInvoice.PaymentDashboard', config.dbsUrl),
    chartBankBar: getUrl('Chart.BankBar', config.dbsUrl),
    chartInvoiceLine: getUrl('Chart.InvoiceLine', config.dbsUrl),
    chartLimitPie: getUrl('Chart.CustomerLimitPie', config.dbsUrl),
    chartCustomerPie: getUrl('Chart.CustomerInvoicePie', config.dbsUrl),
    dbsBanks: getUrl('DbsBank', config.dbsUrl),
    customerDbsInfoLookup: getUrl('customerDbsInfo.Lookup', config.dbsUrl),
  },
  info: {
    agreement: getUrl('Agreement', config.infoUrl),
    cities: getUrl('City/GetCities', config.infoUrl),
    district: getUrl('District/GetDistricts', config.infoUrl),
    neighborhood: getUrl('Neighborhood/GetNeighborhoods', config.infoUrl),
  },
  user: {
    signatory: getUrl('user/signatory', config.userUrl),
    tenant: getUrl('tenant', config.userUrl),
    user: getUrl('user', config.userUrl),
  },
  kredi: {
    getByInitiatorUserId: getUrl('Transaction/GetTransactionByTenantId', config.krediUrl),
    getProducts: getUrl('Product/GetProducts', config.krediUrl),
    getBanks: getUrl('Bank/GetBanks', config.krediUrl),
    getBankPlan: getUrl('Bank/GetBankPlan', config.krediUrl),
    getFee: getUrl('Transaction/GetFee', config.krediUrl),
    sendUrlForTransactionComplete: getUrl('Transaction/SendUrlForTransactionComplete', config.krediUrl),
    getTransactionStatusByReferenceCode: getUrl('Transaction/GetTransactionStatusByReferenceCode', config.krediUrl),
    getActiveTransaction: getUrl('Transaction/GetActiveTransaction', config.krediUrl),
    transactionBankUpdate: getUrl('Transaction/TransactionBankUpdate', config.krediUrl)

  },
  lookups: {
    erpTypes: getUrl('ErpTypes', config.tenantUrl, true),
    roles: getUrl('Roles', config.tenantUrl, true),
    tenantBanks: getUrl('TenantBanks', config.tenantUrl, true),
    tenantBankModules: getUrl('TenantBankModules', config.tenantUrl, true),
    groupBankAccounts: getUrl('GroupBankAccounts', config.tenantUrl, true),
    users: getUrl('Users', config.tenantUrl, true),
    banks: getUrl('Banks', config.tenantUrl, true),
    voucherTypeCodes: getUrl('VoucherTypeCodes', config.tenantUrl, true),
    accessRightCategories: getUrl('AccessRightCategories', config.tenantUrl, true),
    accessRights: getUrl('AccessRights', config.tenantUrl, true),
    logTypes: getUrl('LogTypes', config.tenantUrl, true),
    tenantPackages: getUrl('TenantPackages', config.tenantUrl, true),
    canUseEnvironment: getUrl('CanUseEnvironment', config.tenantUrl, true),
    currentAccounts: getUrl('CurrentAccounts', config.tenantUrl, true),
    tenantExcelFormats: getUrl('TenantExcelFormats', config.tenantUrl, true),
    tenantExchangeType: getUrl('TenantExchangeType', config.tenantUrl, true),
    bankAccounts: getUrl('BankAccounts', config.tenantUrl, true),
    memberBankAccounts: getUrl('MemberBankAccounts', config.tenantUrl, true),

    bankFileTypes: getUrl('BankFileTypes', config.posraporUrl, true, false),
    creditCardOrganizationTypes: getUrl('CreditCardOrganizationTypes', config.posraporUrl, true, false),
    creditCardPrograms: getUrl('CreditCardPrograms', config.posraporUrl, true, false),
    creditCardTypes: getUrl('CreditCardTypes', config.posraporUrl, true, false),
    creditCardBanks: getUrl('CreditCardBanks', config.posraporUrl, true, false),

    transferStatus: getUrl('TransferStatus', config.nteUrl, true),
    typeCodes: getUrl('TypeCodes', config.nteUrl, true),
    regexFields: getUrl('RegexFields', config.nteUrl, true),
    regexFieldsOData: getUrl('RegexFieldsOData', config.nteUrl, true),

    propertyConfigs: getUrl('PropertyConfigs', config.nteUrl, true),
    dynamicProperties: getUrl('DynamicProperties', config.nteUrl, true),
    tenantDocumentApprovedStatuses: getUrl('TenantDocumentApprovedStatuses', config.tenantUrl, true),
    tenantStatuses: getUrl('TenantStatuses', config.tenantUrl, true),
    modules: getUrl('Modules', config.tenantUrl, true),
    tenantModules: getUrl('TenantModules', config.tenantUrl, true),
    parentTenantModules: getUrl('ParentTenantModules', config.tenantUrl, true),
    tenantLevels: getUrl('TenantLevels', config.tenantUrl, true),

    nthBanks: getUrl('GetBanks', config.nthUrl, true, false),
    nthPoses: getUrl('GetPoses', config.nthUrl, true, false),
    nthCurrencies: getUrl('GetCurrencies', config.nthUrl, true, false),
    nth3DSecureTypes: getUrl('GetThreeDSecureTypes', config.nthUrl, true, false),
    nthTransactionTypes: getUrl('GetTransactionTypes', config.nthUrl, true, false),
    nthTransactionStatus: getUrl('GetTransactionStatus', config.nthUrl, true, false),
    nthNetlinkTransactionStatus: getUrl('GetNetLinkTransactionStatus', config.nthUrl, true, false),
    netlinkTypes: getUrl('GetNetLinkTypes', config.nthUrl, true, false),
    languageTypes: getUrl('GetLanguageTypes', config.nthUrl, true, false),
    frequencyTypes: getUrl('GetNetLinkFrequencyTypes', config.nthUrl, true, false),
    paymentSets: getUrl('GetPaymentSets', config.nthUrl, true, false),
    paymentTabs: getUrl('GetPaymentTabs', config.nthUrl, true, false),
    threeDStatuses: getUrl('GetThreeDStatus', config.nthUrl, true, false),
  },
  posrapor: {
    banks: getUrl('Lookup/GetBanks', config.posraporUrl),
    companyNames: getUrl('Lookup/CompanyNames', config.posraporUrl),
    tenantBanks: getUrl('Lookup/GetTenantBanks', config.posraporUrl),
    parameter: getUrl('PosRaporAccountParameters', config.posraporUrl),
    parameters: getUrl('PosRaporAccountParameters', config.posraporUrl),
    reportLine: getUrl('BankDailyReportLine', config.posraporUrl),
    reportLineDetail: getUrl('BankDailyReportLineDetail', config.posraporUrl),
    posList: getUrl('BankDailyReportPos', config.posraporUrl),
    posListBulkEdit: getUrl('BankDailyReportPos/Update', config.posraporUrl),
    posraporAccount: getUrl('PosraporAccount', config.posraporUrl),
    subPosraporAccountParameters: getUrl('SubPosRaporAccountParameters', config.posraporUrl),
    report: getUrl('BankDailyReportLineReport', config.posraporUrl),
    tenantBankAccounts: getUrl('Lookup/TenantBankAccount', config.posraporUrl),
    home: getUrl('Home', config.posraporUrl),
    summary: getUrl('TotalBalances', config.posraporUrl),
    totalBankBalances: getUrl('TotalBankBalances', config.posraporUrl),
    inComingOutGoingTotals: getUrl('InComingOutGoingTotals', config.posraporUrl),
    bankInComingTotals: getUrl('BankInComingTotals', config.posraporUrl),
    bankOutGoingTotals: getUrl('BankOutGoingTotals', config.posraporUrl),
    bankDailyReportLineOnUs: getUrl('BankDailyReportLineOnUs', config.posraporUrl),
    bankDailyReportLineCreditCardProgram: getUrl('BankDailyReportLineCreditCardProgram', config.posraporUrl),
    bankDailyReportLineCreditCardType: getUrl('BankDailyReportLineCreditCardType', config.posraporUrl),
    bankDailyReportLineCreditCardOrganizationType: getUrl('BankDailyReportLineCreditCardOrganizationType', config.posraporUrl),
    bankFileType: getUrl('BankFileType', config.posraporUrl),
    bankFileTypeLookup: getUrl('Lookup/BankFileTypes', config.posraporUrl),
  },
  tos: {
    tosAccount: getUrl('TosAccount', config.tosUrl),
    tosOrderDetail: getUrl('TosOrderDetail', config.tosUrl),
    tosOrder: getUrl('TosOrder', config.tosUrl),
    tenantBanks: getUrl('Bank', config.tosUrl),
    tenantCurrencies: getUrl('Currency', config.tosUrl),
    operationStatus: getUrl('OperationStatus', config.tosUrl),
    tos: getUrl('TOS', config.tosUrl),
    createOrder: getUrl('TosOrder.Create', config.tosUrl),
    sendOrder: getUrl('TosOrder.Send', config.tosUrl),
    reporting: getUrl('TosOrderDetail', config.tosUrl),
    currencies: getUrl('Currency', config.tosUrl),
    recipientAccounts: getUrl('CustomerBankAccounts', config.tenantUrl),
    home: getUrl('TosOrderDetail.TosDashboard', config.tosUrl)
  },
  job: {
    tenantJob: getUrl('TenantJob', config.jobUrl),
    tenantLookup: getUrl('Lookup/Tenants', config.jobUrl),
    jobLookup: getUrl('Lookup/Jobs', config.jobUrl),
    subTenantJob: getUrl('SubTenantJob', config.jobUrl),
  },
  rates: {
    exchangeRates: getUrl('ExchangeRates', config.ratesUrl)
  },
  mock: {
    getProducts: getUrl('test', config.mockUrl),
    getBanks: getUrl('banks', config.mockUrl),
    getBankPlan: getUrl('GetBankPlan', config.mockUrl),
    test: getUrl('test', config.mockUrl),
    getActiveTransaction: getUrl('GetActiveTransaction', config.mockUrl)
  },
  nte: {
    generalCategories: getUrl('GeneralCategories', config.nteUrl),
    tenantCategories: getUrl('TenantCategories', config.nteUrl),
    adminGeneralRules: getUrl('AdminGeneralRules', config.nteUrl),
    exportBankAccountTransactionNotifications: getUrl('ExportBankAccountTransactionNotifications', config.nteUrl),
    bankAccountsBalances: getUrl('BankAccountsBalances', config.nteUrl),
    bankIncomingTotals: getUrl('BankIncomingTotals', config.nteUrl),
    bankOutGoingTotals: getUrl('BankOutGoingTotals', config.nteUrl),
    totalBalances: getUrl('TotalBalances', config.nteUrl),
    incomingOutgoingTotals: getUrl('IncomingOutgoingTotals', config.nteUrl),
    bankAccountTransactionCreators: getUrl('BankAccountTransactionCreators', config.nteUrl),
    accountPlanCodes: getUrl('AccountPlanCodes', config.nteUrl),
    balanceReports: getUrl('BalanceReports', config.nteUrl),
    balanceReportsGetDailyBalanceReport: getUrl('BalanceReports/GetDailyBalanceReport', config.nteUrl),
    bankAccountBalancesGetCumulative: getUrl('BankAccountBalances/GetCumulative', config.nteUrl),
    bankAccountBalances: getUrl('BankAccountBalances', config.nteUrl),
    bankAccountsGetTransactionTransferTypes: getUrl('BankAccounts/GetTransactionTransferTypes', config.nteUrl),
    bankAccountTransactions: getUrl('BankAccountTransactions', config.nteUrl),
    bankAccountTransactionsGetTransactions: getUrl('BankAccountTransactions/GetTransactions', config.nteUrl),
    bankAccountTransactionsApproveTransaction: getUrl('BankAccountTransactions/ApproveTransaction', config.nteUrl),
    bankAccountTransactionsApproveTransactionBulk: getUrl('BankAccountTransactions/ApproveTransactionBulk', config.nteUrl),
    bankAccountTransactionsSendManuelTransactions: getUrl('BankAccountTransactions/SendManuelTransactions', config.nteUrl),
    bankAccountTransactionsSplit: getUrl('BankAccountTransactions/Split', config.nteUrl),
    dynamicProperties: getUrl('DynamicProperties', config.nteUrl),
    propertyConfigs: getUrl('PropertyConfigs', config.nteUrl),
    propertyConfigsGetSourceFields: getUrl('PropertyConfigs/GetSourceFields', config.nteUrl),
    propertyConfigsGetTargetFields: (operationType) => getUrl('PropertyConfigs/GetTargetFields/' + operationType, config.nteUrl),
    rules: getUrl('Rules', config.nteUrl),
    syncRuleJobs: getUrl('SyncRuleJobs', config.nteUrl),
    syncGeneralRules: getUrl('SyncGeneralRules', config.nteUrl),
    transferStatusGetRuleTransferStatus: getUrl('TransferStatus/GetRuleTransferStatus', config.nteUrl),
    typeCodes: getUrl('TypeCodes', config.nteUrl),
    tenantBankParameterGetByEftCode: getUrl('TenantBankParameterGetByEftCode', config.nteUrl),
    tenantBankParameterBulk: getUrl('TenantBankParameterBulk', config.nteUrl),
    sendNotificationsSendNotificationAsPDFToMultiPerson: getUrl('Notifications/SendNotificationAsPDFToMultiPerson', config.nteUrl),
    sendNotificationsSendTransactionNotificationAsPDF: getUrl('Notifications/SendTransactionNotificationAsPDF', config.nteUrl),
    bankAccountInfosGetByBankAccountId: getUrl('BankAccountInfos/GetByBankAccountId', config.nteUrl),
    bankAccountInfos: getUrl('BankAccountInfos', config.nteUrl),
    anadolubankReturnUrl: (tenantBankId) => getUrl('TenantBankParameter/AnadolubankReturnUrl/' + tenantBankId, config.nteUrl),
    anadoluBankCallback: getUrl('TenantBankParameter/AnadoluBank', config.nteUrl),
    matchingConditions: getUrl('CurrentAccountDynamicProperties', config.nteUrl),
    generalRules: getUrl('GeneralRules', config.nteUrl),
    adminRules: getUrl('AdminRules', config.nteUrl),
    adminGeneralRulesDisableOrEnable: getUrl('AdminGeneralRulesDisableOrEnable', config.nteUrl),
    adminGeneralRuleForCustomRule: getUrl('AdminGeneralRuleForCustomRule', config.nteUrl),
    generalRuleForCustomRule: getUrl('GeneralRuleForCustomRule', config.nteUrl),
    subTenantCategories: getUrl('SubTenantCategories', config.nteUrl),
    generalRulesDisableOrEnable: getUrl('generalRulesDisableOrEnable', config.nteUrl),
    bankAccountTransactionsWithChildren: getUrl('BankAccountTransactions/GetWithChildren', config.nteUrl)
  },
  tenant: {
    erpTypes: getUrl('ErpTypes', config.tenantUrl),
    tenants: getUrl('Tenants', config.tenantUrl),
    tenantsChild: getUrl('Tenants/Child', config.tenantUrl),
    tenantsAppToken: (id) => getUrl('Tenants/AppToken/' + id, config.tenantUrl),
    getTenantLimit: getUrl('Tenants/GetTenantLimit', config.tenantUrl),
    notifications: getUrl('Notifications', config.tenantUrl),
    notificationsGetBankAccountTransactionTemplates: getUrl('Notifications/GetBankAccountTransactionTemplates', config.tenantUrl),
    licenseStatusReports: getUrl('LicenseStatusReports', config.tenantUrl),
    accessRights: getUrl('AccessRights', config.tenantUrl),
    bankAccounts: getUrl('BankAccounts', config.tenantUrl),
    bankAccountsGetBankAccountsByBankEftCode: getUrl('BankAccounts/GetBankAccountsByBankEftCode', config.tenantUrl),
    bankAccountsGetCurrencies: getUrl('BankAccounts/GetCurrencies', config.tenantUrl),
    banks: getUrl('Banks', config.tenantUrl),
    categories: getUrl('Categories', config.tenantUrl),
    currencies: getUrl('Currencies', config.tenantUrl),
    currentAccounts: getUrl('CurrentAccounts', config.tenantUrl),
    generalParameters: getUrl('GeneralParameters', config.tenantUrl),
    imageUpload: getUrl('Images/UploadLogo', config.tenantUrl),
    licencePackages: getUrl('LicencePackages', config.tenantUrl),
    getModulePackages: getUrl('LicencePackages/GetByModulIds', config.tenantUrl),
    roles: getUrl('Roles', config.tenantUrl),
    sendNotifications: getUrl('SendNotifications/NewTenant', config.tenantUrl),
    tenantBanks: getUrl('TenantBanks', config.tenantUrl),
    users: getUrl('Users', config.tenantUrl),
    seenVersion: getUrl('Users/SeenVersion', config.tenantUrl),
    voucherTypes: getUrl('VoucherTypes', config.tenantUrl),
    adminCurrentAccounts: getUrl("AdminCurrentAccounts", config.tenantUrl),
    adminTenantBanks: getUrl("AdminTenantBanks", config.tenantUrl),

    changeTenant: (id) => getUrl('Users/ChangeTenant/' + id, config.tenantUrl),
    gridSettings: getUrl('GridSettings', config.tenantUrl),
    tenantLicenseStatusReports: getUrl('TenantLicenceStatuses', config.tenantUrl),
    tenantCurrenciesGetTenantFixedExChangeRates: getUrl('Currencies/GetTenantFixedExChangeRates', config.tenantUrl),
    tenantCurrenciesSetTenantFixedExChangeRates: getUrl('Currencies/SetTenantFixedExChangeRates', config.tenantUrl),
    alerts: getUrl('Alerts', config.tenantUrl),
    alertsSeen: getUrl('Alerts/Seen', config.tenantUrl),
    currentAccountSelectedItems: getUrl('CurrentAccounts/SelectItems', config.tenantUrl),
    tenantUpdate: getUrl('Tenants/TenantUpdate', config.tenantUrl),
    getTenant: getUrl('Tenants/GetTenant', config.tenantUrl),
    documents: getUrl('TenantDocuments', config.tenantUrl),
    documentUpload: getUrl('TenantDocuments/TenantDocumentUpload', config.tenantUrl),

    adminDocuments: getUrl('AdminTenantDocuments', config.tenantUrl),
    adminDocumentProcess: getUrl('AdminTenantDocuments/AdminDocumentProcess', config.tenantUrl),
    adminDocumentUpload: getUrl('AdminTenantDocuments/AdminDocumentUpload', config.tenantUrl),
    tenantModuleExtraField: getUrl('TenantModuleExtraField', config.tenantUrl),
    accessRightCategories: getUrl('AccessRightCategories', config.tenantUrl),
    tenantUsers: getUrl('Tenants/TenantUsers', config.tenantUrl),
    tenantLicences: getUrl('TenantLicences', config.tenantUrl),
    getCustomersBySearchText: getUrl('Customers/GetBySearchText', config.tenantUrl),
    customers: getUrl('Customers', config.tenantUrl),
    adminCustomers: getUrl('AdminCustomers', config.tenantUrl),
  },
  cek: {
    tenantBanks: getUrl('Bank', config.cekUrl),
    reporting: getUrl('Cheque', config.cekUrl),
    cheques: getUrl('Cheque', config.cekUrl),
    chequeAccount: getUrl('ChequeAccount', config.cekUrl),
    currencies: getUrl('Currency', config.cekUrl),
    payrolls: getUrl('PayRoll', config.cekUrl),
    createPayroll: getUrl('Payroll.Create', config.cekUrl),
    sendPayroll: getUrl('Payroll.Send', config.cekUrl),
    operationStatus: getUrl('OperationStatus', config.cekUrl),
    tenantCurrencies: getUrl('Currency', config.cekUrl)
  },
  netahsilat: {
    tenantPos: getUrl('TenantPos', config.nthUrl),
    posCommRates: getUrl('PosCommRate', config.nthUrl),
    defaultPosCommRates: getUrl('PosCommRate/GetDefaultPosCommRate', config.nthUrl),
    updateDefaultPosCommRates: getUrl('PosCommRate/UpdateDefaultPosCommRate', config.nthUrl),
    posParameters: getUrl('PosParameter', config.nthUrl),
    posLogos: getUrl('PosLogo', config.nthUrl),
    payments: getUrl('Transaction', config.nthUrl),
    cancellation: getUrl('Cancel', config.nthUrl),
    refund: getUrl('Refund', config.nthUrl),
    netlinkTransaction: getUrl('NetLinkTransaction', config.nthUrl),
    netlinkTransactionCancel: getUrl('NetLinkTransaction/Cancel', config.nthUrl),
    webSiteParameter: getUrl('WebSiteParameter', config.nthUrl),
    webSiteParameterCreateOrUpdate: getUrl('WebSiteParameter/WebSiteParameterCreateOrUpdate', config.nthUrl),
    emailOrder: getUrl('EmailOrder', config.nthUrl),
    smsOrder: getUrl('SmsOrder', config.nthUrl),
    qrOrder: getUrl('QrOrder', config.nthUrl),
    payment: getUrl('Payment', config.nthUrl),
    installmentTable: getUrl('InstallmentTable', config.nthUrl),
    installmentTableByFilter: getUrl('InstallmentTable/GetByFilter', config.nthUrl),
  }
}
