import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import _ from 'lodash';
import SummaryCard from './SummaryCard';
import { convertFieldsToCamelize } from 'helpers';
const actions = {};

class Summary extends Component {
  state = { wait: this.props.getWithQueue }

  componentDidMount() {
    if (!this.props.getWithQueue)
      this.getChartData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.currencyCode !== prevProps.currencyCode || this.props.multiTenant !== prevProps.multiTenant || this.props.isCumulative !== prevProps.isCumulative) {
      this.getChartData();
    }
  };

  getChartData = async () => {
    let { currencyCode, multiTenant, isCumulative, endpoint, currentModule, method } = this.props;
    const args = [currentModule, method, endpoint, { currencyCode, getMultiTenant: multiTenant }, { isCumulative, currencyCode }];

    return new Promise(async (resolve) => {
      this.setState({ wait: false });
      if (!actions[currentModule]) {
        const _module = await import(`store/actions/${currentModule}`);
        actions[currentModule] = _module.default;
        this.props.getChartData(...args, resolve);
      }
      else {
        this.props.getChartData(...args, resolve);
      }
    })
  }


  render() {
    const { multiTenant, isCumulative, typeSets, titles, options } = this.props;
    const { wait } = this.state;
    let { loading, data } = this.props.summary.single;
    data = _.cloneDeep(data);
    return (
      <Row gutter={40}>
        {
          typeSets.map((type, i) => (
            <Col key={type} xs={24} md={8} className="mb-50">
              <SummaryCard {...{ loading: loading || wait, data, isCumulative, multiTenant, type, title: titles && titles[i], options: options && options[i] }} />
            </Col>
          ))
        }
      </Row>
    );
  };
};

const mapStateToProps = (state, { toCamel }) => ({
  currentModule: state.common.currentModule,
  summary: toCamel ? convertFieldsToCamelize(state[state.common.currentModule].summary) : state[state.common.currentModule].summary,
  i18nextLng: state.common.i18nextLng,
});
const mapDispatchToProps = (dispatch) => ({
  getChartData: (currentModule, method, endpoint, data, parameters, callback) => dispatch(actions[currentModule][method]({ url: endpoint, key: 'summary', data, parameters }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Summary);