import React, { Component } from 'react';
import Tenants from '../Tenants/List';
import NTEGeneralCategories from 'components/Modules/NetEkstre/Definitions/Categories/GeneralCategories/List'
import NTECustomCategories from 'components/Modules/NetEkstre/Definitions/Categories/CustomCategories/List'
import i18n from 'plugins/i18n';
import { CheckAccessRight } from 'components/UIComponents';

class GeneralCategories extends Component {
  state = {
    selectedTenant: null
  }

  setSelectedTenant = tenant => {
    this.setState({ selectedTenant: tenant })
  }

  render() {
    const { selectedTenant } = this.state;
    return (
      <CheckAccessRight {...{ stateKey: "admin.companies" }} withTag="div">
        <Tenants
          datatableTitle={selectedTenant ? selectedTenant.name : i18n.t('lbl.pleaseSelectTenant')}
          hasParent
          onTenantSelect={this.setSelectedTenant}
          datatableCollapsed={selectedTenant}
        />

        <NTEGeneralCategories
          className="mt-60"
          datatableTitle={selectedTenant ? { tenant: selectedTenant.name } : undefined}
          getDataTrigger={selectedTenant}
          datatableCollapsed={!selectedTenant}
          callback={this.customCategories && this.customCategories.getCategories}
        />

        <NTECustomCategories
          ref={el => this.customCategories = el}
          className="mt-60"
          datatableTitle={selectedTenant ? { tenant: selectedTenant.name } : undefined}
          getDataTrigger={selectedTenant}
          datatableCollapsed={!selectedTenant}
        />
      </CheckAccessRight>
    );
  };
};

export default GeneralCategories;