export default {
  path: '/posrapor',
  name: 'posrapor',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: require('components/Modules/Posrapor/Dashboard').default,
    },
    {
      path: '/accounts',
      name: 'accounts',
      access: 'PosRaporAccount',
      component: require('components/Modules/Posrapor/Account/List').default,
    },
    {
      path: '/parameters',
      name: 'parameters',
      access: 'PosRaporAccountParameters',
      component: require('components/Modules/Posrapor/Parameters/List').default,
    },
    {
      path: '/pos-lists',
      name: 'posLists',
      access: 'BankDailyReportPos',
      component: require('components/Modules/Posrapor/PosLists/List').default,
    },
    {
      path: '/report-lines',
      name: 'reportLines',
      access: 'BankDailyReportLine',
      component: require('components/Modules/Posrapor/ReportLines/List').default,
    },
    {
      path: '/report-line-details/:id?',
      name: 'reportLineDetails',
      visible: false,
      access: 'BankDailyReportLineDetail',
      component: require('components/Modules/Posrapor/ReportLineDetail/List').default,
    },
    {
      path: '/report-line-details',
      name: 'reportLineDetails',
      access: ['BankDailyReportLineDetail'],
      component: require('components/Modules/Posrapor/ReportLineDetail/List').default,
    },
    {
      path: '/reporting',
      name: 'reporting',
      access: ['BankDailyReportLineDetail'],
      component: require('components/Modules/Posrapor/Reporting').default,
      routes: [
        {
          path: '/summary-report',
          name: 'summaryReport',
          access: 'BankDailyReportLineDetail',
          component: require('components/Modules/Posrapor/Reporting/SummaryReport/List').default,
        },
        {
          path: '/report-detail',
          name: 'reportDetail',
          access: 'BankDailyReportLineDetail',
          component: require('components/Modules/Posrapor/Reporting/ReportDetail/List').default,
        },
      ]
    },
  ]
};