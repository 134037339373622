
import React, { forwardRef, useState } from 'react';
import { Input as AntInput } from "antd";

const Verification = forwardRef((props, ref) => {
  const [focus, setFocus] = useState(false);
  return (
    <div className="custom-verification__wrapper">
      <AntInput
        {...props}
        ref={ref}
        onChange={(e) => props.onChange(e.target.value)}
        className={`${props.className || ''} custom-verification`}
        onFocus={() => setFocus(!focus)}
        onBlur={() => setFocus(false)}
        maxLength={6}
      />
    </div>
  )
})

export default Verification;
