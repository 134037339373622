import { call, delay, put as sagaPut } from 'redux-saga/effects';
import { PanelProxy, AuthProxy } from 'proxies';
import actions from 'store/actions/common';
import { notification, message, Modal } from 'antd';
import i18n from 'plugins/i18n';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import endpoints from 'config/endpoints';
import config from 'config';
import { v4 } from 'uuid';
import { HTTP_STATUS_SUCCESS, getHttpStatusCategory } from 'http-response-status'
import { convertFieldsToCamelize } from 'helpers';

export function* getAll({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).getAll, payload);
    yield sagaPut(actions.getAllSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  catch (error) {
    yield sagaPut(actions.getAllFailure({ error: error.data, key: payload.key, statusCode: error.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  callback && callback(response.data);
}

export function* get({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).getAll, payload);
    yield sagaPut(actions.getSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
  }
  catch (error) {
    yield sagaPut(actions.getFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
  callback && callback(response.data);
}

export function* getFilter({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).getAll, payload);
    yield sagaPut(actions.getFilterSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  catch (error) {
    yield sagaPut(actions.getFilterFailure({ error: error.data, key: payload.key, statusCode: error.statusCode, isNewODataStructure: payload.isNewODataStructure }));
  }
  callback && callback(response.data);
}

export function* post({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).post, payload);
    yield sagaPut(actions.postSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
  }
  catch (error) {
    yield sagaPut(actions.postFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
  response && callback && callback(response.data);
}

export function* put({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).put, payload);
    yield sagaPut(actions.putSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.putFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
}

export function* _delete({ payload, callback }) {
  let response;
  const _payload = {
    ...payload,
    url: `${payload.baseUrl || config.nteUrl}${payload.url}`
  }
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).delete, _payload);
    yield sagaPut(actions.deleteSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.deleteFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
}

export function* patch({ payload, callback }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).patch, payload);
    yield sagaPut(actions.patchSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
    callback && callback(response.data);
  }
  catch (error) {
    yield sagaPut(actions.patchFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
}



export function seenVersion() {
};

export function* changeTenant(action) {
  let response;
  try {
    response = yield call(new AuthProxy().changeTenant, action.payload);
    localStorage.setItem('token', response.data.token);
    const info = { userId: response.data.userId, tenantId: response.data.tenantId };
    localStorage.setItem("userInfo", CryptoJS.AES.encrypt(JSON.stringify(info), "netfinans"));
    if (response.data.token) {
      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      axios.defaults.headers.userId = response.data.userId;
      axios.defaults.headers.tenantId = response.data.tenantId;
      action.callback && action.callback(response.data);
    }
  }
  catch (error) {
    notification.error({ message: error.data.message || i18n.t('msg.unknownError') });
  }
}

export function* getAccountCodes({ payload, callback }) {
  if (payload && payload !== '' && payload.length > 2) {
    let response, filter;
    try {
      yield (delay(1000));
      filter = {
        filter: {
          or: [
            { code: { contains: payload } },
            { title: { contains: payload } },
            { name: { contains: payload } },
          ]
        },
        top: 100
      };
      response = yield call(new PanelProxy(filter).getAll, { url: endpoints.tenant.currentAccounts });
      if (!response.data.value.find(x => x.code === payload)) {
        response.data.value.unshift({ Code: payload, Title: '', Id: v4(), isManuelAdded: true });
      }
      callback && callback(response.data.value);
    }
    catch {
      notification.error({ message: i18n.t('msg.unknownError') });
    }
  }
};

export function* getPlanCodes({ payload, callback }) {
  if (payload && payload !== '' && payload.length > 2) {
    let response, filter;
    try {
      yield (delay(1000));
      filter = { filter: { or: [{ code: { contains: payload } }, { name: { contains: payload } }] }, top: 100 };
      response = yield call(new PanelProxy(filter).getAll, { url: endpoints.nte.accountPlanCodes });
      if (!response.data.value.find(x => x.Code === payload)) {
        response.data.value.unshift({ Code: payload, Name: '' });
      }
      callback && callback(response.data.value);
    }
    catch {
      notification.error({ message: i18n.t('msg.unknownError') });
    }
  }
};

export function excelExport({ payload }) {
  const { filter, parameters, excel, module } = payload;
  message.loading(i18n.t('msg.preparingExcelFile'));

  let id = v4();
  let socket = new WebSocket(`${config[`socketUrl${module || 'nte'}`]}?UniqueId=${id}`);
  socket.onopen = () => {
    let response = new PanelProxy(filter, parameters).exportExcel({ url: config[`${module || 'nte'}Url`] + excel, id });
    if (!response || response.error) {
      notification.error({ message: response.error.message || i18n.t('msg.unknownError') });
    };
  };

  socket.onmessage = (e) => {
    message.destroy();
    const response = JSON.parse(e.data);
    const responseObj = {
      modalType: getHttpStatusCategory(response.Status) === HTTP_STATUS_SUCCESS ? 'confirm' : 'error',
      modalIcon: getHttpStatusCategory(response.Status) === HTTP_STATUS_SUCCESS ? 'check-circle' : 'close-circle',
      modalTitle: getHttpStatusCategory(response.Status) === HTTP_STATUS_SUCCESS ? i18n.t('msg.excelReady') : i18n.t('msg.excelEmpty'),
      modalOkText: getHttpStatusCategory(response.Status) === HTTP_STATUS_SUCCESS ? i18n.t('btn.download') : i18n.t('btn.close')
    }
    Modal[responseObj.modalType]({
      title: responseObj.modalTitle,
      icon: responseObj.modalIcon,
      okText: responseObj.modalOkText,
      cancelText: i18n.t('btn.cancel'),
      onOk() {
        if (getHttpStatusCategory(response.Status) === HTTP_STATUS_SUCCESS) {
          let info = localStorage.getItem('userInfo');
          info = info ? JSON.parse(CryptoJS.AES.decrypt(info, "netfinans").toString(CryptoJS.enc.Utf8)) : {};
          const token = localStorage.getItem('token');
          window.open(`${response.Message}&t=${token}&uid=${info.userId}&tid=${info.tenantId}`, '_blank');
        }
      }
    });
    socket.close();
  };
}

export function* getVersion({ callback }) {
  let response = yield call(new PanelProxy().getVersion);
  callback && callback(response.data);
}

export function uploadImg({ payload, callback }) {
  new PanelProxy().uploadImg(payload)
    .then(response => {
      callback && callback(response.data);
    }).catch(error => {
      notification.error({ message: error.message || i18n.t('msg.unknownError') });
    });
};

export function* changePassword({ payload }) {
  let response = yield call(new PanelProxy().getSingle, { url: endpoints.auth.passwords, id: payload.id });
  if (response && response.data) {
    notification.success({
      message: i18n.t('msg.passwordResetSuccess')
    });
  }
  else {
    notification.error({ message: response.error.message || i18n.t('msg.unknownError') });
  }
}

export function* getWithPost({ payload }) {
  let response;
  try {
    response = yield call(new PanelProxy(payload.filter, payload.parameters).post, payload);
    yield sagaPut(actions.getSuccess({ data: response.data, key: payload.key, statusCode: response.statusCode }));
  }
  catch (error) {
    yield sagaPut(actions.getFailure({ error: error.data, key: payload.key, statusCode: error.statusCode }));
  }
};

export function listen3DProcess({ payload, callback }) {
  const { referenceNumber, module = "nth" } = payload;
  const socket = new WebSocket(`${config[`socketUrl${module}`]}?UniqueId=${referenceNumber}`);

  socket.onmessage = e => {
    const response = JSON.parse(e.data);
    const isSuccess = getHttpStatusCategory(response.Status) === HTTP_STATUS_SUCCESS;
    if (!isSuccess)
      Modal.error({ title: i18n.t('msg.sorryAnErrorOccurred'), content: response?.Message || i18n.t('msg.anErrorOccurredOn3D') })
    else
      callback(response ? convertFieldsToCamelize(JSON.parse(response.Message)) : null);
    socket.close();
  };
}