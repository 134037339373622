import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import actions from 'store/actions/auth';
import { FadeIn, Loading, Typography } from 'components/UIComponents';
import i18n from 'plugins/i18n';
import { Button, Alert } from 'antd';

const UnblockForUser = ({ history, doBlockedUserToActive, auth }) => {
  const guid = queryString.parse(useLocation().search).guid;

  useEffect(() => {
    doBlockedUserToActive(guid)
  }, [doBlockedUserToActive, guid])

  return (
    <FadeIn>
      <Typography.H className="mb-10" size={1}>{i18n.t('lbl.pleaseWait')}</Typography.H>
      <Typography.P size="big">{i18n.t('lbl.doBlockedUserActiveWaitMessage')}</Typography.P>

      <Loading loading={auth.loading !== null ? auth.loading : true} style={{ color: '#7ac32a', fontSize: '58px' }}>
        {
          auth.data === 200 &&
          <Alert message={i18n.t('msg.removedUserBlockSuccessfully')} type="success" className="mt-30 text-500" />
        }
        <Button block type="primary" className="mt-20" onClick={() => history.push('/auth/sign-in')}>
          {i18n.t('btn.returnToSignIn')}
        </Button>
      </Loading>

    </FadeIn>
  )
}
const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = (dispatch) => ({
  doBlockedUserToActive: (id, callback) => dispatch(actions.doBlockedUserToActive(id, callback)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnblockForUser));