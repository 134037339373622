import Proxy from './Proxy';
import endpoints from 'config/endpoints';

export class AuthProxy extends Proxy {

  getAccount = () => {
    return this.submit('get', endpoints.auth.getTokenInfo, null, true);
  };

  signIn = (payload) => {
    return this.submit('post', endpoints.auth.login, payload, true, {});
  };

  verifyTwoFactor = (payload) => {
    return this.submit('post', endpoints.auth.verifyTwoFactor, payload, true);
  };

  getEmail = (payload) => {
    return this.submit('get', endpoints.auth.getMemberByGuid(payload), null, true);
  };

  resetPassword = (payload) => {
    return this.submit('post', endpoints.auth.passwords, payload, true);
  };

  changeTenant = (payload) => {
    return this.submit('get', endpoints.tenant.changeTenant(payload), null, true);
  };

  logout = (payload) => {
    return this.submit('post', endpoints.auth.logout, payload, true);
  };

  forgotPassword = ({ data, headers }) => {
    return this.submit('post', endpoints.auth.passwordsGetByEmail, data, null, headers)
  };

  doBlockedUserToActive = (payload) => {
    return this.submit('post', endpoints.auth.doBlockedUserToActive(payload), null, true);
  };

  resendOtp = (payload) => {
    return this.submit('post', endpoints.auth.resendOtp(payload), null, true);
  };
}
