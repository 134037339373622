import React, { Component } from 'react';
import { DatePicker, Icon } from 'antd';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import moment from 'moment';
import { connect } from 'react-redux';

class NBDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? moment(props.value) : null,
      visible: false,
      mode: props.mode || 'date'
    };
  };

  static getDerivedStateFromProps(props, state) {
    let _state = {}
    if (state.visible === false)
      _state.mode = props.mode;
    if (props.value && moment(props.value) !== state.value)
      _state.value = moment(props.value)
    return _state
  };

  handleChange = (e) => {
    const { startOf, endOf, onChange, time, date } = this.props;
    const { mode } = this.state;
    const _type = date ? (date === 'ge' ? 'startOf' : 'endOf') : null;

    if (startOf && e) e = e.startOf('day');
    if (e && (endOf || (_type === 'endOf' && mode !== 'time'))) e = e.endOf('day');
    if (time && mode !== 'time' && (e && ((e._i && moment(e._i).format('HH:mm') === '00:00') || !e._i))) this.setState({ mode: 'time' })

    this.setState({ value: e }, () => {
      onChange && onChange(e ? e.format() : null);
    });
  };

  onPanelChange = (value, mode) => {
    const { date } = this.props;
    const _type = date === 'ge' ? 'startOf' : 'endOf';
    const _value = value[_type](mode);

    if (mode)
      this.setState({ mode }, () => this.handleChange(_value))
    else
      this.handleChange(_value);
  }

  render() {
    const { value, visible, mode } = this.state;
    const { label, type, className, id, disabled, required, autoFocus, disabledDate, allowClear, hidePlaceholder, currentModule, time, format } = this.props;

    return (
      <div className="nb nb-date">
        <DatePicker
          showTime={!!time && (typeof time === 'object' ? { format: 'HH:mm', minuteStep: 5, ...time } : { format: 'HH:mm', minuteStep: 5 })}
          allowClear={allowClear === false ? false : true}
          id={id}
          type={type}
          value={value}
          locale={locale}
          onChange={(e) => this.handleChange(e)}
          required={!!required}
          disabled={!!disabled}
          disabledDate={disabledDate}
          autoFocus={!!autoFocus}
          placeholder=""
          format={format || `DD.MM.YYYY${time ? ' HH:mm' : ''}`}
          className={`${value && 'is-filled'} ${className || ''} ${label}`}
          mode={mode}
          onPanelChange={(this.onPanelChange)}
          onOpenChange={(e) => this.setState({ visible: e, })}
          open={visible}
          dropdownClassName={currentModule}
          suffixIcon={time && <div className="suffix-icons"><Icon type="clock-circle" /><Icon type="calendar" /></div>}
        />
        <label className={`${(value && value.toString() !== '') ? `${hidePlaceholder ? 'hide' : ''} focused` : ''}`}>{label || ''}</label>
      </div>
    );
  };
};
const mapStateToProps = ({ common }) => ({ ...common });
export default connect(mapStateToProps, null, null, { forwardRef: true })(NBDate);