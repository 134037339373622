import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Tooltip, Modal } from 'antd';
import dbsActions from 'store/actions/dbs';
import commonActions from 'store/actions/common';
import { formatCurrency, formatDate, isEditableInvoice, isEditableOrder } from 'helpers';
import { ActionButtons, DataTable, Instruction, FadeIn } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { CreateOrderModel, SendOrderModel } from 'models';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { defaultFilters } from 'lib/constants';
import Invoice from '../CreatingInstructions/Detail';

class instructionList extends Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id
    this.state = {
      filter: defaultFilters.dbs.OrderDetail(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      orderSendingOrCreating: false
    };
  };

  componentDidMount() {
    utils.common.checkAccessToUpdateOrDelete('DbsInvoice')
    if (!!this.id) {
      this.getOrderDetails();
      utils.dbs.getTenantCurrencies();
      utils.dbs.getTenantBanks('DbsAccounts');
      this.getInvoiceTotal();
    }
    if (this.props.selectedItems.length > 0) {
      this.getInvoiceTotal();
    }
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getOrderDetails = () => {
    let { filter } = this.state;
    filter.filter = { DbsOrderId: { type: 'guid', value: this.id }, }
    this.props.getOrderDetails(filter);
  };

  getDataCallback = () => {
    this.getOrderDetails();
    this.getInvoiceTotal();
  }

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getOrderDetails();
    });
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getOrderDetails);
  }

  additionalButtons = () => {
    const buttons = [
      <div onClick={() => window.open('/static/files/DBS_Sample_v1.xlsx')}>
        <Icon type="plus" className="mr-5" />
        {i18n.t('dbs.downloadExampleFile')}
      </div>
    ];

    return buttons;
  };

  getBankName = (row) => {
    const bank = this.props.tenantBanks.data ? this.props.tenantBanks.data.find(x => x.Code === row.Code) : null;
    return bank ? <React.Fragment><img src={bank.LogoSmall} className="bank-icon" alt="" /> {bank.Name}</React.Fragment> : '';
  };

  orderOperation = (data, method) => {
    this.setState({ orderSendingOrCreating: true })
    let _data = method === "createOrder" ? new CreateOrderModel({ ...data, InvoiceIds: this.props.selectedItems }) : new SendOrderModel({ ...data, DbsOrderId: this.id, InvoiceIds: this.props.selectedItems });
    if (method === "sendOrder" && _data.InvoiceIds.length > 0) {
      _data.DbsOrderId = null
    }
    this.props[method](_data, (response) => {
      this.setState({ orderSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: response && response.Message,
          content: status === 'success' ? i18n.t(`dbs.${method}Response`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.getOrderDetails();
          }
        })
      }
    })
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getOrderDetails();
    });
  };

  setOrderDetail = orderResponse => {
    const _order = orderResponse && orderResponse.list.data && orderResponse.list.data.length > 0 ? orderResponse.list.data[0] : null;
    return {
      id: this.id,
      description: _order ? _order.DbsOrder.Description : null,
      bankCode: _order ? _order.BankCode : null,
      invoices: orderResponse.list.data,
      currencyCode: _order ? _order.CurrencyCode : null,
      count: orderResponse.list.count,
      loading: orderResponse.list.loading,
      statusId: _order ? _order.DbsOrder.OperationStatus.Id : null,
    }
  }


  getInvoiceTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });


    if (selectedItems.length || checkAll || !!this.id) {
      let filter = {};
      filter.filter = {
        and: [{ DbsOrderId: { type: 'guid', value: this.id }, }],
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }

      this.props.getInvoiceTotal(filter, response => {

        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(invoice =>
            _amount += invoice.InvoiceAmount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  render() {
    const { orders, tenantBanks, tenantCurrencies, selectedItems } = this.props;
    const { totalAmount, totalCount, totalsLoading, orderSendingOrCreating, filter } = this.state;
    const _order = this.setOrderDetail(orders);
    const _isEditable = isEditableOrder(_order.statusId);
    const instructions = [
      { label: i18n.t('dbs.bankName'), key: 'BankCode', type: 'select', options: tenantBanks.data, name: (row) => this.getBankName(row), value: "Code", defaultValue: _order.bankCode, disabled: true, required: true, prior: true },
      { label: i18n.t('dbs.description'), key: 'Description', type: 'input', defaultValue: _order.description },
      { label: i18n.t('dbs.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: _isEditable && true },
      { label: i18n.t('dbs.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: _isEditable && true },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode', type: 'select', options: tenantCurrencies.data, name: "Name", value: "Code", loading: tenantCurrencies.loading, defaultValue: _order.currencyCode, disabled: true, required: true, prior: true },
      _isEditable && { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: (data) => this.orderOperation(data, 'createOrder'), disabled: (!!!this.id || _order.count === 0 || orderSendingOrCreating || !selectedItems.length > 0), access: 'DbsOrder.Create' },
      { label: i18n.t(_isEditable ? 'btn.sendOrder' : 'btn.sent'), type: 'button', className: 'success', onClick: (data) => this.orderOperation(data, 'sendOrder'), disabled: (!!!this.id || _order.count === 0 || orderSendingOrCreating || !_isEditable), access: 'DbsOrder.Send' },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="DbsInvoice" editUrl={row.IsUpdatable && "openDialog"} item={row} hasDelete getDatas={this.getDataCallback} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('dbs.dealerName'), key: 'CurrentAccount/Title', checkField: false, },
      { label: i18n.t('dbs.currency'), key: 'Currency/Code', checkField: false, },
      { label: i18n.t('dbs.invoiceDate'), key: 'InvoiceDate', render: (row) => formatDate(row.InvoiceDate, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.expiryDate'), key: 'DueDate', render: (row) => formatDate(row.DueDate, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.invoiceStatus'), key: 'OperationStatus/Name', checkField: false },
      { label: i18n.t('dbs.invoiceNo'), key: 'InvoiceNumber' },
      { label: i18n.t('dbs.invoiceAmount'), key: 'InvoiceAmount', render: ({ InvoiceAmount, Currency }) => formatCurrency({ data: InvoiceAmount, currencyCode: Currency ? Currency.Code : undefined, withSign: false }) },
      { label: i18n.t('dbs.createdDate'), key: 'InsertedTime', render: (row) => formatDate(row.InsertedTime, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.description'), key: 'Description' },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <Instruction {...{ instructions, loading: totalsLoading }} />
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            count={_order.count}
            access="DbsInvoice"
            history={this.props.history}
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={_order.invoices}
            loading={_order.loading}
            title={i18n.t('route.dbs.orderDetail')}
            newButton={_isEditable && "openDialog"}
            fromExcel={_isEditable && { url: "DbsInvoice" }}
            additionalButtons={this.additionalButtons()}
            getData={this.getDataCallback}
            checkbox={{isActive: _isEditable, disabled: item => !isEditableInvoice(item.OperationStatus.Id)}}
            onCheck={this.getInvoiceTotal}
            checkAll={{isActive: _isEditable && _order.invoices && _order.invoices.length > 0, disabled: _order.invoices.some(x => !isEditableInvoice(x.OperationStatus.Id) )}}
            Component={Invoice}
            dialogOptions={{ DbsOrderId: this.id }}
            dialogTitle={i18n.t('lbl.invoice')}
            dialogNotEditable={!_isEditable}
            deleteOptions={{ stateKey: "orders", url: "DbsInvoice" }}
            excel={{ module: "dbs", url: "DbsInvoice.Excel", filter: { filter: filter.filter, expand: filter.expand } }}
          />
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs, common }) => ({
  orders: dbs.orders,
  tenantBanks: dbs.filter.tenantBanks,
  tenantCurrencies: dbs.filter.tenantCurrencies,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getOrderDetails: (filter) => dispatch(dbsActions.getAll({ filter, url: endpoints.dbs.dbsInvoice, key: 'orders' })),
  getInvoiceTotal: (filter, callback) => dispatch(dbsActions.getAll({ filter, url: endpoints.dbs.dbsInvoice }, callback)),
  createOrder: (data, callback) => dispatch(dbsActions.post({ url: endpoints.dbs.createOrder, data, customNotification: true }, callback)),
  sendOrder: (data, callback) => dispatch(dbsActions.post({ url: endpoints.dbs.sendOrder, data, customNotification: true }, callback)),
  setSelectedItems: (id, callback) => dispatch(commonActions.setSelectedItems(id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(instructionList);