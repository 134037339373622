import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/posrapor';
import { ActionButtons, DataTable, Filter, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from "lib";
import { formatDate, formatCurrency, cardHarmony, businessCardTypes } from 'helpers';
import { Button, Tooltip, Icon } from 'antd';
import i18n from 'plugins/i18n';
import { defaultFilters } from 'lib/constants';
import ReportLine from './Detail';
import moment from 'moment';
import _ from 'lodash';

class ReportLines extends Component {
  state = {
    filter: defaultFilters.posrapor.ReportLines(),
  };

  componentDidMount() {
    this.getReportLines();
    utils.posrapor.getBanks();
    utils.posrapor.getCurrencies();
    utils.common.checkAccessToUpdateOrDelete('BankDailyReportLine');
    utils.posrapor.getCreditCardBanks();
    utils.posrapor.getCreditCardTypes();
    utils.posrapor.getCreditCardPrograms();
    utils.posrapor.getCreditCardOrganizationTypes();
    utils.posrapor.getCompanyNames();
  };

  getReportLines = () => {
    let _filter = this.props.currentFilter['posrapor/report-lines'];
    let filter = _.cloneDeep(this.state.filter);

    if (!(_filter && _filter.fields && _filter.fields[0] && _filter.fields[0].length)) {
      const oneWeekBefore = moment().add(-14, 'day').startOf('day');
      this.filterRef.filterChange(oneWeekBefore.format('YYYY-MM-DDT00:00:00Z'), 0);
      filter.filter.and
        ? filter.filter.and.push({ TransactionDate: { ge: oneWeekBefore._d } })
        : filter.filter.and = [{ TransactionDate: { ge: oneWeekBefore._d } }]
    }
    this.props.getReportLines(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getReportLines);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getReportLines);
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getReportLines);
  };

  renderAdditionalButtons = ({ Id }) => {
    let buttons = [];
    buttons.push(
      <Tooltip key={Math.random()} placement="bottom" title={i18n.t('lbl.installments')}>
        <Button className="#reportLineDetails" size="small" onClick={() => this.props.history.push(`/posrapor/report-line-details/${Id}`)}>
          <Icon type="ordered-list" />
        </Button>
      </Tooltip>
    )
    return buttons;
  }

  getCreditCardBanksName = (row) => this.props.creditCardBanks.data.find(x => x.id === row.CreditCardBankId)?.name || i18n.t('lbl.unknown');

  getCreditCardTypeName = (row) => this.props.creditCardTypes.data.find(x => x.id === row.CreditCardTypeId)?.name || i18n.t('lbl.unknown');

  getCreditCardProgramsName = (row) => this.props.creditCardPrograms.data.find(x => x.id === row.CreditCardProgramId)?.name || i18n.t('lbl.unknown');

  getCreditCardOrganizationTypesName = (row) => this.props.creditCardOrganizationTypes.data.find(x => x.id === row.CreditCardOrganizationTypeId)?.name || i18n.t('lbl.unknown');

  getBusinessCardTypesName = (row) => i18n.t(`lbl.${businessCardTypes.find(x => x.value === row.IsBusinessCardId)?.name || 'unknown'}`);

  getIsOnUsNotOnUs = (row) => i18n.t(`lbl.${cardHarmony.find(x => x.id === row.OnUs)?.name || 'unknown'}`);

  render() {
    const { reportLines, banks, currencies, creditCardBanks, creditCardTypes, creditCardPrograms, creditCardOrganizationTypes, companyNames } = this.props;
    const { filter } = this.state;
    const filters = [
      { label: i18n.t('posrapor.transactionDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['TransactionDate'], uniqueKey: '#transactionDate', allowClear: false },
      { label: i18n.t('posrapor.transactionDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['TransactionDate'], uniqueKey: '#transactionDate', allowClear: true },
      { label: i18n.t('posrapor.posBank'), type: 'select', options: banks.data, value: 'eftCode', name: (bankData) => utils.posrapor.getBankName({ bankData }), keys: ['Pos/PosRaporAccount/Bank/EftCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.cardBanks'), type: 'select', options: creditCardBanks.data, value: 'id', name: 'name', keys: ['CreditCardBankId'], showAll: true, multiple: true },
      { label: i18n.t('posrapor.cardType'), type: 'select', options: creditCardTypes.data, value: 'id', name: 'name', keys: ['CreditCardTypeId'], multiple: true },
      { label: i18n.t('posrapor.cardProgram'), type: 'select', options: creditCardPrograms.data, value: 'id', name: 'name', keys: ['CreditCardProgramId'], multiple: true },
      { label: i18n.t('posrapor.cardKind'), type: 'select', options: creditCardOrganizationTypes.data, value: 'id', name: 'name', keys: ['CreditCardOrganizationTypeId'], multiple: true },
      { label: i18n.t('posrapor.isOnUs'), type: 'select', options: cardHarmony, value: 'id', lang: 'lbl', name: 'name', keys: ['onUs'], showAll: true, uniqueKey: '#cardHarmony', multiple: true },
      { label: i18n.t('lbl.businessCard'), type: 'select', options: businessCardTypes, lang: 'lbl', value: 'value', name: 'name', keys: ['IsBusinessCardId'], showAll: true, uniqueKey: '#IsBusinessCardId', multiple: true },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Pos/MerchantId', 'Pos/TerminalId', 'TransactionId', 'ProvisionCode', 'Pos/CompanyName'], contains: true, uniqueKey: '#search' },
      { label: i18n.t('lbl.installmentCount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['InstallmentCount'], uniqueKey: '#InstallmentCountMin' },
      { label: i18n.t('lbl.installmentCount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['InstallmentCount'], uniqueKey: '#InstallmentCountMax' },
      { label: i18n.t('lbl.tenantInstallmentCount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['TenantInstallmentCount'], uniqueKey: '#TenantInstallmentCountMin' },
      { label: i18n.t('lbl.tenantInstallmentCount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['TenantInstallmentCount'], uniqueKey: '#TenantInstallmentCountMax' },
      { label: i18n.t('posrapor.netAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['NetAmount'], uniqueKey: '#NetAmountMin' },
      { label: i18n.t('lbl.commitDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['CommitDate'], uniqueKey: '#CommitDate', allowClear: true },
      { label: i18n.t('lbl.commitDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['CommitDate'], uniqueKey: '#CommitDate', allowClear: true },
      { label: i18n.t('posrapor.netAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['NetAmount'], uniqueKey: '#NetAmountMax' },
      { label: i18n.t('posrapor.pureAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['PureAmount'], uniqueKey: '#PureAmountMin' },
      { label: i18n.t('posrapor.pureAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['PureAmount'], uniqueKey: '#PureAmountMax' },
      { label: i18n.t('posrapor.commisionAmount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['CommisionAmount'], uniqueKey: '#CommissionAmountMin' },
      { label: i18n.t('posrapor.commisionAmount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['CommisionAmount'], uniqueKey: '#CommissionAmountMax' },
      { label: i18n.t('posrapor.serviceCommisionRate', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['ServiceCommisionRate'], uniqueKey: '#CommissionRateMin' },
      { label: i18n.t('posrapor.serviceCommisionRate', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['ServiceCommisionRate'], uniqueKey: '#CommissionRateMax' },
      { label: i18n.t('lbl.currencyCode'), keys: ['Pos/Currencies/Code'], type: 'select', options: currencies.data, name: "currencyName", value: "currencyCode", multiple: true },
      { label: i18n.t('posrapor.merchantName'), keys: ['Pos/CompanyName'], type: 'select', options: companyNames.data, name: "companyName", value: "companyName", search: true, multiple: true },
    ];
    const columns = [
      { render: (row) => <ActionButtons item={row} additionalButtons={() => this.renderAdditionalButtons(row)} url="BankDailyReportLine" editUrl openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('posrapor.posBank'), key: 'Pos/PosraporAccount/Bank/Name', render: (data) => utils.posrapor.getBankName({ data, dataField: 'Pos.PosRaporAccount.Bank.EftCode' }), checkField: false },
      { label: i18n.t('posrapor.cardBank'), key: 'CreditCardBankId', render: this.getCreditCardBanksName },
      { label: i18n.t('posrapor.cardType'), key: 'CreditCardTypeId', render: this.getCreditCardTypeName },
      { label: i18n.t('posrapor.cardProgram'), key: 'CreditCardProgramId', render: this.getCreditCardProgramsName },
      { label: i18n.t('posrapor.cardKind'), key: 'CreditCardOrganizationTypeId', render: this.getCreditCardOrganizationTypesName },
      { label: i18n.t('posrapor.isOnUs'), key: 'OnUs', render: this.getIsOnUsNotOnUs },
      { label: i18n.t('lbl.businessCard'), key: 'IsBusinessCardId', render: this.getBusinessCardTypesName },
      { label: i18n.t('posrapor.merchant'), key: 'Pos/MerchantId', checkField: false },
      { label: i18n.t('posrapor.merchantName'), key: 'Pos/CompanyName', checkField: false },
      { label: i18n.t('posrapor.terminalNo'), key: 'Pos/TerminalId', checkField: false },
      { label: i18n.t('posrapor.transactionNo'), key: 'TransactionId' },
      { label: i18n.t('posrapor.provisionCode'), key: 'ProvisionCode' },
      { label: i18n.t('posrapor.cardNumber'), key: 'CardNumber' },
      { label: i18n.t('posrapor.transactionDate'), key: 'TransactionDate', render: ({ TransactionDate }) => formatDate(TransactionDate, null, null, null, false) },
      { label: i18n.t('posrapor.transactionTime'), key: 'TransactionTime', render: ({ TransactionDate }) => moment(TransactionDate).format('HH:mm'), checkField: false, sort: false },
      { label: i18n.t('posrapor.pureAmount'), key: 'PureAmount', render: ({ PureAmount, Pos }) => formatCurrency({ data: PureAmount, currencyCode: Pos.Currencies.Code, withSign: false }) },
      { label: i18n.t('posrapor.netAmount'), key: 'NetAmount', render: ({ NetAmount, Pos }) => formatCurrency({ data: NetAmount, currencyCode: Pos.Currencies.Code, withSign: false }) },
      { label: i18n.t('lbl.installmentCount'), key: 'InstallmentCount' },
      { label: i18n.t('lbl.tenantInstallmentCount'), key: 'TenantInstallmentCount' },
      { label: i18n.t('lbl.commissionAmount'), key: 'CommisionAmount' },
      { label: i18n.t('lbl.commissionRate'), key: 'ServiceCommisionRate' },
      { label: i18n.t('lbl.commission'), key: 'Commision' },
      { label: i18n.t('lbl.chip'), key: 'Chip' },
      { label: i18n.t('lbl.point'), key: 'Point' },
      { label: i18n.t('lbl.award'), key: 'Award' },
      { label: i18n.t('lbl.authCode'), key: 'AuthCode' },
      { label: i18n.t('lbl.commitDate'), key: 'CommitDate', render: ({ CommitDate }) => formatDate(CommitDate, null, null, null, false) },
      { label: i18n.t('lbl.companyName'), key: 'CompanyName' }
    ];
    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...reportLines, single: reportLines.list } }}>
            <Filter filters={filters} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
            <DataTable
              count={reportLines.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="BankDailyReportLine"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={reportLines.list.data}
              loading={reportLines.list.loading}
              title={i18n.t('route.posrapor.reportLines')}
              getData={this.getReportLines}
              Component={ReportLine}
              dialogTitle={i18n.t('lbl.reportLine')}
              deleteOptions={{ stateKey: "reportLines", url: "BankDailyReportLine" }}
              excel={{ url: "ExportBankDailyReportLine", filter: { filter: filter.filter, expand: filter.expand, orderBy: filter.orderBy }, module: "posrapor" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor, common }) => ({
  reportLines: posrapor.reportLines,
  banks: posrapor.filter.banks,
  currencies: posrapor.filter.currencies,
  creditCardBanks: posrapor.filter.creditCardBanks,
  creditCardTypes: posrapor.filter.creditCardTypes,
  creditCardPrograms: posrapor.filter.creditCardPrograms,
  creditCardOrganizationTypes: posrapor.filter.creditCardOrganizationTypes,
  companyNames: posrapor.filter.companyNames,
  currentFilter: common.currentFilter

});
const mapDispatchToProps = (dispatch) => ({
  getReportLines: (filter) => dispatch(actions.getAll({ filter, url: endpoints.posrapor.reportLine, key: 'reportLines' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportLines);