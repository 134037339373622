import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/admin';
import { DataTable, Filter, Description, ActionButtons, RuleDescription, DropdownList, FadeIn, CheckAccessRight, ToggleData } from "components/UIComponents";
import _ from 'lodash';
import endpoints from 'config/endpoints';
import { baseUrls, defaultFilters, regexTypes, status } from 'lib/constants';
import utils from 'lib';
import i18n from 'plugins/i18n';
import Rule from './Detail'
import { Icon, Popover, Button, Tooltip } from 'antd';

const PageAccesses = {
  AdminGeneralRules: "AdminGeneralRules",
  GeneralRules: "GeneralRules"
}

class CustomRules extends Component {
  state = {
    loading: false,
    filter: defaultFilters.admin.GeneralRules(),
    getDataTrigger: undefined,
    currentBank: null,
    access: PageAccesses.GeneralRules,
    ruleToggleStarted: false,
    inlineToggleDataLoading: false,
    rulesSync: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id };
      const filter = state.filter;
      props.getRules(filter, parameters, endpoints.nte.adminGeneralRules);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  componentDidMount() {
    const { getDataTrigger } = this.props;
    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getRules();
      access = PageAccesses.AdminGeneralRules;
    }
    else {
      access = PageAccesses.AdminGeneralRules;
    }

    utils.admin.getGeneralCategories('Incoming');
    utils.admin.getGeneralCategories('Outgoing');
    utils.admin.getBanks();
    utils.admin.getVoucherTypeCodes();
    utils.admin.getTransferStatus();
    utils.admin.getErpTypes();
    utils.admin.getTypeCodes(1);
    utils.admin.getTypeCodes(2);

    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })
  };

  getRules = () => {
    let { filter } = this.state;
    const { getDataTrigger } = this.props;
    if (getDataTrigger)
      this.props.getRules(filter, { TenantId: getDataTrigger.id }, endpoints.nte.adminGeneralRules);
    else
      this.props.getRules(filter);
  };


  getBankName = ({ GeneralRuleItems }) => {
    let ruleItem = GeneralRuleItems.find(({ RegexType }) => RegexType === regexTypes.Bank);
    return utils.admin.getBankName({ data: ruleItem, dataField: 'RegexPattern', listKeys: 'banks', listField: 'bankEftCode' })
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getRules);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getRules);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getRules);
  }

  getTypeCodeName = (RuleInitializerItems, i) => {
    const ruleItem = RuleInitializerItems.find(({ RegexType }) => RegexType === regexTypes[`TypeCode${i}`]);
    if (ruleItem && ruleItem.RegexPattern) return ruleItem.RegexPattern;
    return '';
  }

  onSelect = (value) => {
    this.setState({ currentBank: value });
  };

  onCallback = (e, type) => {
    if (!e) this.setState({ [type]: [] });
  };

  customButtons = ({ selectAll }) => (
    <ToggleData
      ref={el => this.toggleData = el}
      selectAll={selectAll}
      parent={this}
      url={endpoints.nte.adminGeneralRulesDisableOrEnable}
      modalOptions={{
        title: i18n.t('lbl.ruleEnabledOrDisabled'),
        content: i18n.t('msg.ruleEnabledOrDisabledDescription')
      }}
    />
  );

  renderAdditionalButtons = () => {
    const { hasParent, getDataTrigger } = this.props
    return [
      getDataTrigger &&
      <div onClick={() => this.toggleData.startOrCancelProcess()}>
        <Icon type="swap" className="mr-5" />
        {i18n.t('btn.enabledOrDisabled')}
      </div>,
      !hasParent &&
      this.props.accessRights && this.props.accessRights.find(x => x.endPoint === 'SyncGeneralRules' && x.method === 'POST') &&
      <div
        onClick={() => {
          if (!this.state.rulesSync) {
            this.setState({ rulesSync: true });
            this.props.syncRules(() => this.setState({ rulesSync: false }))
          }
        }}
      >
        <Icon type="swap" className="mr-5" />
        {i18n.t('btn.startSyncRule')}
      </div>
    ]
  }

  inlineToggleData = (e, { Id }) => {
    e.stopPropagation();
    this.setState({ inlineToggleDataLoading: true })
    this.props.singleToggleData([Id], () => {
      this.setState({ inlineToggleDataLoading: false }, () => {
        this.getRules();
      })
    })
  }

  renderActionButtons = row => {
    const { access, inlineToggleDataLoading } = this.state;
    const { getDataTrigger } = this.props
    return (
      <>
        <ActionButtons url={access} hasDelete editUrl item={row} getDatas={this.getRules} openDialog={this.datatable && this.datatable.openDialog} />
        {
          getDataTrigger &&
          <Popover overlayClassName="ant-popover-action" placement="right" trigger="hover" content={
            <>
              {
                row.Enabled ?
                  <Tooltip placement="bottom" title={i18n.t('btn.deactivate')}>
                    <Button loading={inlineToggleDataLoading} size="default" className="no-animation" icon="close" style={{ color: '#D46464' }} onClick={e => this.inlineToggleData(e, row)} />
                  </Tooltip>
                  :
                  <Tooltip placement="bottom" title={i18n.t('btn.activate')}>
                    <Button loading={inlineToggleDataLoading} size="default" className="no-animation" icon="check" style={{ color: '#7ac32a' }} onClick={e => this.inlineToggleData(e, row)} />
                  </Tooltip>
              }
              <Tooltip placement="bottom" title={i18n.t('btn.reproductionCustomRule')}>
                <Button size="default" className="no-animation" icon="copy" style={{ color: '#40a9ff' }} onClick={(e) => {
                  e.stopPropagation();
                  this.datatable.openDialog(false, row.Id, { reproductionRule: true, getUrl: endpoints.nte.adminGeneralRuleForCustomRule, saveUrl: endpoints.nte.adminRules });
                }} />
              </Tooltip>
            </>
          }>
            <Button onClick={e => e.stopPropagation()} size="small" className="table-button"><Icon type="double-right" /></Button>
          </Popover>
        }
      </>
    )
  }

  render() {
    const { currentBank, access, ruleToggleStarted } = this.state;
    const { generalRules, voucherTypes, banks, incomingCategories, outgoingCategories, transferStatus, type1, type2, erpTypesFilter, showFilter, className, datatableTitle, getDataTrigger, callback } = this.props;
    let _type1 = type1 && type1.data ? (currentBank && currentBank.length) ? type1.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type1.data : [];
    let _type2 = type2 && type2.data ? (currentBank && currentBank.length) ? type2.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type2.data : [];
    _type1 = _.uniqBy(_type1, 'code');
    _type2 = _.uniqBy(_type2, 'code');

    const filters = [
      { label: i18n.t('lbl.bank'), options: banks.data, type: 'select', value: 'bankEftCode', name: (bank) => <React.Fragment><img src={bank ? bank.logoSmall : ''} className="bank-icon" alt="" /> {bank ? bank.name : ''}</React.Fragment>, keys: ['GeneralRuleItems'], any: 'regexPattern', showAll: true, callback: this.onSelect, clear: ['2', '3'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.exampleDescription'), type: 'input', keys: ['name'], contains: true, uniqueKey: '#exampleDescription' },
      { label: i18n.t('lbl.typeOne'), options: _type1, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['GeneralRuleItems'], any: 'RegexPattern', any2: ['RegexType', regexTypes.TypeCode1], uniqueKey: '#typeOne', multiple: true },
      { label: i18n.t('lbl.typeTwo'), options: _type2, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['GeneralRuleItems'], any: 'RegexPattern', any2: ['RegexType', regexTypes.TypeCode2], uniqueKey: '#typeTwo', multiple: true },
      { label: i18n.t('lbl.voucherType'), type: 'select', options: voucherTypes.data, keys: ['GeneralRuleVoucherTypes'], any: 'VoucherTypeId', value: 'Id', name: 'Name', uniqueKey: '#voucherType', multiple: true, guid: true },
      { label: i18n.t('lbl.incomingCategory'), type: 'select', options: incomingCategories.data, keys: ['IncomingCategoryId'], value: 'Id', name: 'Name', uniqueKey: '#incomingCategory', multiple: true, guid: true },
      { label: i18n.t('lbl.outgoingCategory'), type: 'select', options: outgoingCategories.data, keys: ['OutgoingCategoryId'], value: 'Id', name: 'Name', uniqueKey: '#outgoingCategory', multiple: true, guid: true },
      { label: i18n.t('lbl.transferStatus'), options: transferStatus.data, type: 'select', value: 'id', name: 'name', lang: 'status', keys: ['transferStatus'], showAll: true, toggle: false, uniqueKey: '#transferStatus', multiple: true },
      { label: i18n.t('lbl.erpType'), type: 'select', options: erpTypesFilter.data || [], value: 'id', name: 'name', keys: ['GeneralRuleVoucherTypes'], any: 'ErpTypeId', uniqueKey: '#code', multiple: true },
      { label: i18n.t('lbl.enabledOrDisabled'), type: 'select', options: status, value: 'value', name: 'name', keys: ['Enabled'], uniqueKey: '#enabled', lang: 'lbl', boolean: true },
      { label: i18n.t('lbl.point'), type: 'number', operator: 'eq',  keys: ['Priority'], uniqueKey: '#point' },
    ];
    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id', checkField: false, },
      { label: i18n.t('lbl.exampleDescription'), key: 'Name', render: ({ Name }) => <Description text={Name} /> },
      { render: (row) => <RuleDescription admin item={row} />, toggle: false, sort: false, notIncluded: true, checkField: false },
      { label: i18n.t('lbl.bank'), key: 'Bank', render: this.getBankName, checkField: false, sort: false },
      { label: i18n.t('lbl.enabledOrDisabled'), key: 'Enabled', tdClass: 'text-center', render: ({ Enabled }) => <Icon style={{ color: Enabled ? '#7ac32a' : '#D46464', fontSize: 16 }} type={Enabled ? 'check' : 'close'} /> },
      { label: i18n.t('lbl.typeOne'), key: 'idOne', checkField: false, render: ({ GeneralRuleItems }) => this.getTypeCodeName(GeneralRuleItems, 1), sort: false },
      { label: i18n.t('lbl.typeTwo'), key: 'idTwo', checkField: false, render: ({ GeneralRuleItems }) => this.getTypeCodeName(GeneralRuleItems, 2), sort: false },
      { label: i18n.t('lbl.incomingCategory'), key: 'IncomingCategory/Name', checkField: false }, //? control
      { label: i18n.t('lbl.outgoingCategory'), key: 'OutgoingCategory/Name', checkField: false }, //? control
      { label: i18n.t('lbl.point'), key: 'Priority' },
      { label: i18n.t('lbl.voucherType'), key: 'GeneralRuleVoucherTypes/voucherType', sort: false, checkField: false, render: ({ GeneralRuleVoucherTypes }) => <DropdownList data={GeneralRuleVoucherTypes} field="VoucherTypeName" /> },
      { label: i18n.t('lbl.erpType'), key: 'GeneralRuleVoucherTypes/erpType', sort: false, checkField: false, render: ({ GeneralRuleVoucherTypes }) => <DropdownList data={GeneralRuleVoucherTypes} field="ErpTypeName" /> },
    ];

    return (
      <FadeIn className={`page-content ${className || ''}`}>
        <CheckAccessRight {...{ ...generalRules, hasParent: getDataTrigger !== undefined }}>
          {
            showFilter !== false &&
            <Filter filters={filters} onFilter={this.onFilter} />
          }
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            history={this.props.history}
            onPageChange={this.onPageChange}
            access={access}
            onSort={this.onSort}
            columns={columns}
            data={generalRules.list.data}
            loading={generalRules.list.loading}
            title={datatableTitle || i18n.t('route.admin.rules')}
            newButton="openDialog"
            count={generalRules.list.count}
            Component={Rule}
            dialogTitle={i18n.t('lbl.rule')}
            checkbox={ruleToggleStarted}
            checkAll
            getData={() => { this.getRules(); callback && callback() }}
            deleteOptions={{ stateKey: "generalRules", url: access, baseUrl: baseUrls.nte }}
            CustomButton={this.customButtons}
            disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
            additionalButtons={this.renderAdditionalButtons()}
            dialogOptions={{
              endpoint: getDataTrigger && endpoints.nte.adminGeneralRules,
              tenantId: getDataTrigger && getDataTrigger.id,
            }}
          />
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ admin, auth }) => ({
  generalRules: admin.generalRules,
  banks: admin.filter.banks,
  incomingCategories: admin.filter.incomingCategories,
  outgoingCategories: admin.filter.outgoingCategories,
  voucherTypes: admin.filter.voucherTypes,
  transferStatus: admin.filter.transferStatus,
  accessRights: auth.data.accessRights,
  type1: admin.filter.typeCodes1,
  type2: admin.filter.typeCodes2,
  erpTypesFilter: admin.filter.erpTypes,
});
const mapDispatchToProps = (dispatch) => ({
  getRules: (filter, parameters, endpoint) => dispatch(actions.getAll({ filter, parameters, url: endpoint || endpoints.nte.adminGeneralRules, key: 'generalRules', isNewODataStructure: true })),
  singleToggleData: (data, callback) => dispatch(actions.post({ data, url: endpoints.nte.adminGeneralRulesDisableOrEnable }, callback)),
  syncRules: (callback) => dispatch(actions.post({ url: endpoints.nte.syncGeneralRules }, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomRules);
