import { store } from 'store/redux/createStore';
const defaultPageSize = 10;

export const getKey = () => {
  return `${window.location.pathname.slice(1)}`
}

const getValues = (controllerPath) => {
  const state = store.getState().common;
  return {
    key: getKey(),
    currentFilter: state.currentFilter,
    tableSettings: state.gridSettings.list.data ? state.gridSettings.list.data.find(x => x.controllerPath === controllerPath) : {}
  }
}

export default {
  netekstre: {
    Balances: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/balances");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['bankName asc']
      }
    },

    IncomingTotals: (filter) => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/categories1");
      return {
        filter: { categoryType: 1, parentId: { ne: null }, ...filter },
        skip: currentFilter && currentFilter[`${key}1`] && currentFilter[`${key}1`].skip ? currentFilter[`${key}1`].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },

    OutgoingTotals: (filter) => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/categories2");
      return {
        filter: { categoryType: 2, parentId: { ne: null }, ...filter },
        skip: currentFilter && currentFilter[`${key}2`] && currentFilter[`${key}2`].skip ? currentFilter[`${key}2`].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },

    DynamicFields: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/rules/dynamic-fields");
      return {
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },

    FieldDefinitions: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/rules/field-definitions");
      return {
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },

    Notifications: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/notifications");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: [
          { NotificationCategories: { expand: ['Notification', 'Category'] } },
          { NotificationRoles: { expand: ['Notification', 'Role'] } },
          { NotificationUsers: { expand: ['Notification', 'User'] } },
          'Template',
        ],
      }
    },

    GeneralRules: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/rules/general");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? { ...currentFilter[key].filter } : { and: { Enabled: true } },
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['IncomingCategory', 'OutgoingCategory', 'GeneralRuleVoucherTypes', 'GeneralRuleItems']
      }
    },

    CustomRules: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/rules/custom");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? { ...currentFilter[key].filter } : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['RuleItems', 'IncomingCategory', 'OutgoingCategory', 'VoucherType']
      }
    },

    Transactions: (locationFilter, getDynamicPropertiesOnGrid) => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/transactions");
      return {
        filter: locationFilter ? locationFilter : currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: locationFilter ? 0 : currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['transactionDate desc, Rank desc'],
        expand: [
          'Category',
          'CurrentAccount',
          {
            VoucherType: {
              select: ['Code', 'Name', 'Id']
            }
          },
          {
            BankAccount: {
              // select: ['Name', 'ErpCode', 'AccountNumber'],
              expand: {

                TenantBank: {
                  // select: ['Bank'],
                  expand: 'Bank'
                }
              }
            }
          },
          {
            CurrentAccounts: {
              select: "CurrentAccountId"
            }
          },
          getDynamicPropertiesOnGrid ? {
            TransactionDynamicProperties: {
              // select: ['Name', 'DynamicPropertyId', 'PropertyConfig', 'Value', 'Required'],
              expand: {
                PropertyConfig: {
                  // select: ['Name', 'Pattern', 'OperationType']
                },
              }
            }
          } : undefined,
        ],
        count: true,
      };
    },

    BalanceReports: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/reports/balances");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },

    AvgReports: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/reports/average");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['bankName asc'],
        count: true
      }
    },

    MatchingConditions: () => {
      let { key, currentFilter, tableSettings } = getValues("netekstre/definitions/rules/matching-conditions");
      return {
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },
  },

  admin: {
    GeneralCategories: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/definitions/categories");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },

    ErpInitializers: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/definitions/erptypes");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true
      };
    },

    TypeCodeInitializers: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/definitions/typecodes");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true
      };
    },

    VoucherTypeInitializers: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/definitions/vouchertypes");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true
      };
    },

    LicenceReportInitializers: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/reports/licence");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true
      };
    },

    Tenants: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/tenants");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['createdDate desc'],
        count: true
      };
    },

    PackagesInitializer: () => {
      let { key, currentFilter, tableSettings } = getValues("admin/packages");
      return {

        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true
      };
    },

    GeneralRules: () => {
      let { key, currentFilter, tableSettings } = getValues('admin/definitions/rules');
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['GeneralRuleItems', 'GeneralRuleVoucherTypes', 'OutgoingCategory', 'IncomingCategory']
      }
    },

    TenantJob: (type) => {
      let { key, currentFilter, tableSettings } = getValues('admin/jobs');
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? { ...currentFilter[key].filter, RuleType: type } : { RuleType: type },
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    }
  },

  tos: {
    CreatePayments: () => {
      let { key, currentFilter, tableSettings } = getValues("tos/create-payments");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['PaymentDate desc'],
        count: true,
        expand: 'TosOrder'
      }
    },

    Parameters: () => {
      let { key, currentFilter, tableSettings } = getValues("tos/parameters");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: 'ServiceType',
      }
    },

    PaymentList: () => {
      let { key, currentFilter, tableSettings } = getValues("tos/payment-list");
      return {
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : 10,
        expand: [{ tosOrder: { expand: 'OperationStatus' } }, 'CurrentAccount', 'OperationStatus', 'Currency'],
        count: true,
      }
    },

    PaymentLists: () => {
      let { key, currentFilter, tableSettings } = getValues("tos/payment-lists");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['InsertedTime desc'],
        expand: ['OperationStatus']
      }
    },

    Reporting: () => {
      let { key, currentFilter, tableSettings } = getValues("tos/reporting");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: [{ tosOrder: { expand: 'OperationStatus' } }]
      }
    },
    RecipientAccounts: () => {
      let { key, currentFilter, tableSettings } = getValues("tos/recipient-accounts");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: 'Bank',
        // orderBy: ['TenantBank/Bank/Name asc']
      }
    },
  },

  dbs: {
    CreatingInstructions: () => {
      let { key, currentFilter, tableSettings } = getValues("dbs/creating-instructions");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        expand: ['DbsOrder', 'CurrentAccount', 'OperationStatus', 'Currency', 'Bank'],
        count: true,
      }
    },

    OrderDetail: () => {
      let { key, currentFilter, tableSettings } = getValues("dbs/order/detail");
      return {
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        expand: [{ DbsOrder: { expand: 'OperationStatus' } }, 'CurrentAccount', 'OperationStatus', 'Currency', 'Bank'],
        count: true,
      }
    },

    InstructionLists: () => {
      let { key, currentFilter, tableSettings } = getValues("dbs/instruction-lists");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['InsertedTime desc'],
        expand: ['OperationStatus']
      }
    },

    CustomerInfos: () => {
      let { key, currentFilter, tableSettings } = getValues("dbs/customer-infos");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['CurrentAccount', 'Currency', 'Bank']
      }
    },

    Parameters: () => {
      let { key, currentFilter, tableSettings } = getValues("dbs/parameters");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: 'ServiceType',
      }
    },

    Reporting: () => {
      let { key, currentFilter, tableSettings } = getValues("dbs/reporting");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['CurrentAccount', 'OperationStatus', { DbsOrder: { expand: 'OperationStatus' } }]
      }
    }
  },

  posrapor: {
    PosLists: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/pos-lists");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['PosRaporAccount/Bank/Name asc', 'MerchantId asc'],
        expand: [{ PosRaporAccount: { expand: 'Bank' } }, 'Currencies']
      }
    },

    Accounts: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/accounts");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['Bank']
      }
    },

    Parameters: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/parameters");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: { PosRaporAccount: { expand: 'Bank' } }
      }
    },

    ReportLines: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/report-lines");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['TransactionDate desc'],
        count: true,
        expand: { Pos: [{ expand: { PosRaporAccount: { expand: 'Bank' } } }, 'Currencies'] },
      }
    },

    ReportLineDetails: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/report-line-details");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['BankDailyReportLine/TransactionDate desc, InstallmentNumber asc'],
        expand: {
          BankDailyReportLine: { expand: { Pos: [{ expand: { PosRaporAccount: { expand: 'Bank' } } }, 'Currencies'] } }
        }
      }
    },
    ReportDetail: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/reporting/report-detail");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['TransactionDate desc'],
        expand: [{ Pos: [{ expand: { PosRaporAccount: { expand: 'Bank' } } }, 'Currencies'] }]
      }
    },
    SummaryReport: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/reporting/summary-report");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        // orderBy: ['bankName asc'],
      }
    },
    BankFileType: () => {
      let { key, currentFilter, tableSettings } = getValues("posrapor/bank-file-types");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: 'Bank',
        orderBy: ['Bank/Name asc', 'Description asc'],
      }
    },
  },

  common: {
    BankAccounts: () => {
      let { key, currentFilter, tableSettings } = getValues("common/definitions/bank-accounts");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: { TenantBank: { expand: 'Bank' } },
        orderBy: ['TenantBank/Bank/Name asc']
      }
    },

    CurrentAccounts: () => {
      let { key, currentFilter, tableSettings } = getValues("common/definitions/current-accounts");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: [{ Ibans: { expand: 'CurrentAccount' } }, { BankAccountNumbers: { expand: 'CurrentAccount' } }]
      }
    },

    TenantBanks: () => {
      let { key, currentFilter, tableSettings } = getValues("common/definitions/banks");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: 'Bank',
        orderBy: ['Bank/Name asc'],
      }
    },

    Roles: () => {
      let { key, currentFilter, tableSettings } = getValues("common/definitions/member-management/roles");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: [{ RoleTenantBanks: { expand: 'TenantBank' } }, { RoleBankAccounts: { expand: 'BankAccount ' } }]
      }
    },

    Users: () => {
      let { key, currentFilter, tableSettings } = getValues("common/definitions/member-management/users");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: { UserRoles: { expand: 'Role' } }
      }
    },

    Customers: () => {
      let { key, currentFilter, tableSettings } = getValues("common/definitions/member-management/customers");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
      }
    },
  },

  cek: {
    Reporting: () => {
      let { key, currentFilter, tableSettings } = getValues("cek/reporting");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['OperationStatus', { Payroll: { expand: 'OperationStatus' } }]
      }
    },

    Cheques: () => {
      let { key, currentFilter, tableSettings } = getValues("cek/cheques");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['ChequeDate desc'],
        count: true,
        expand: 'PayRoll'
      }
    },

    Payrolls: () => {
      let { key, currentFilter, tableSettings } = getValues("cek/payrolls");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['InsertedTime desc'],
        expand: ['OperationStatus']
      }
    },



    Parameters: () => {
      let { key, currentFilter, tableSettings } = getValues("cek/parameters");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: ['ServiceType'],
      }
    },
  },

  netahsilat: {
    TenantPos: () => {
      let { key, currentFilter, tableSettings } = getValues("netahsilat/pos");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        orderBy: ['CreatedDate desc'],
        count: true,
      }
    },
    Payments: (locationFilter) => {
      let { key, currentFilter, tableSettings } = getValues("netahsilat/payment");
      return {
        filter: locationFilter ? locationFilter : currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: locationFilter ? 0 : currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        orderBy: ['InsertedDate desc'],
        // expand: 'User'
      }
    },
    NetLinkTransaction: () => {
      let { key, currentFilter, tableSettings } = getValues("netahsilat/netlink");
      return {
        filter: currentFilter[key] && currentFilter[key].filter ? currentFilter[key].filter : {},
        skip: currentFilter[key] && currentFilter[key].skip ? currentFilter[key].skip : 0,
        top: tableSettings ? tableSettings.pageSizes : defaultPageSize,
        count: true,
        expand: 'VPosTransactions'
      }
    }
  },
}
