import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import ActionDetail from "./ActionDetail";
import Loading from '../../../UIComponents/Loading';
import netekstreActions from "store/actions/netekstre";
import commonActions from "store/actions/common";
import endpoints from 'config/endpoints';

class ActionModal extends Component {
  state = {
    loading: false,
    saving: false,
    current: null,
    visible: false,
    itemList: [],
    filter: this.props.filter,
    hasChanged: false,
    items: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (!window._.isEqual(prevProps.filter, this.props.filter)) {
      this.setState({ filter: this.props.filter });
    }
  };

  open = (current, items) => {
    this.setState({ current, visible: true, itemList: items ? items : [] });
    if (!items || !items.length) this.getTransactions();
  };

  close = () => {
    this.setState({ current: 0, itemList: [], visible: false }, () => {
      this.props.getDatas();
    });
  };

  onSave = (item) => {
    if (this.props.mode === 'approve') {
      this.setState({ saving: true });
      this.props.approve(item, () => {
        this.setState({ saving: false, hasChanged: true });
        if (this.state.current < this.state.itemList.length - 1) this.updateIndex(this.state.current + 1);
        else this.close();
      });
    } else if (this.props.mode === 'transfer') {
      this.setState({ saving: true });
      this.props.transfer(item, () => {
        this.setState({ saving: false, hasChanged: true });
        if (this.state.current < this.state.itemList.length - 1) this.updateIndex(this.state.current + 1);
        else this.close();
      });
    }
    this.props.setSelectedItems(item.id)
  };

  getTransactions = () => {
    this.setState({ loading: true });
    let _filter = this.props.currentFilter['netekstre.transactions'];
    let filter = window._.cloneDeep(this.state.filter);
    if (this.props.mode === 'approve') {
      filter.filter.and ? filter.filter.and.push({ transferStatusId: 6 }) : filter.filter.and = [{ transferStatusId: 6 }];
    }
    if (!(_filter && _filter.fields && _filter.fields[2] && _filter.fields[2].length)) {
      filter.filter.and ? filter.filter.and.push({ transferStatusId: { ne: 8 } }) : filter.filter.and = ({ transferStatusId: { ne: 8 } })
    }
    this.props.getTransactions(filter, (response) => {
      this.setState({ itemList: response.value, loading: false });
    });
  };

  updateIndex = (current) => {
    this.setState({ loading: true }, () => {
      this.setState({ current }, () => {
        this.setState({ loading: false });
      });
    });
  };

  render() {
    const { itemList, current, visible, loading, saving } = this.state;
    const { mode } = this.props;
    return (
      <div>
        <Modal
          width={900}
          className="ant-modal-transaction"
          visible={visible}
          footer={null}
          destroyOnClose
          onCancel={this.close}
        >
          {current > 0 &&
            <Button icon="double-left" className="ant-btn-arrow prev"
              onClick={() => this.updateIndex(current - 1)} />
          }
          <Loading loading={loading}>
            <ActionDetail
              key="string"
              split={itemList[current]}
              notSplit
              onSave={this.onSave}
              onClose={this.close}
              saving={saving}
              mode={mode}
            />
          </Loading>
          {current < itemList.length - 1 &&
            <Button icon="double-right" className="ant-btn-arrow next"
              onClick={() => this.updateIndex(current + 1)} />
          }
        </Modal>
      </div>
    );
  };
};

const mapStateToProps = ({ common }) => ({ currentFilter: common.currentFilter });
const mapDispatchToProps = (dispatch) => ({
  getTransactions: (filter, callback) => dispatch(netekstreActions.getAll({ filter, url: endpoints.nte.bankAccountTransactions }, callback)),
  approve: (data, callback) => dispatch(netekstreActions.put({ url: endpoints.nte.bankAccountTransactionsApproveTransaction, data }, callback)),
  transfer: (data, callback) => dispatch(netekstreActions.put({ url: endpoints.nte.bankAccountTransactions, data: { ...data, canBeManuelTransferred: true }, }, callback)),
  setSelectedItems: (id, callback) => dispatch(commonActions.setSelectedItems(id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ActionModal);