import React,{ useEffect } from 'react'
import LayoutCover from "./LayoutCover";
import Header from "./Header";
import Content from "./Content";
import { Layout as AntLayout, Row, Col } from "antd";
import CoverBG from "assets/img/cover-bg.png";
import { useDispatch } from 'react-redux';
import actions from 'store/actions/auth';

const Layout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      dispatch(actions.getAccount((response) => {
        response && window.location.replace(response.level < 2 ? '/admin' : '/home')
      }))
    }
  }, [dispatch])

  return (
    <AntLayout className="auth-layout fadeInComponent" style={{ backgroundImage: `url(${CoverBG})` }}>
      <Header />
      <Row className="page-container mt--124" >
        <Col xs={24} md={10} className="full-height">
          <Content />
        </Col>
        <Col xs={0} md={14} className="layout-img">
          <LayoutCover />
        </Col>
      </Row>
    </AntLayout>
  )
}

export default Layout;
