import React, { useState, useEffect, useCallback } from 'react'
import ReactSVG from 'react-svg';
import Bell from 'assets/img/bell.svg'
import { Popover, Badge, Tooltip, Spin, Icon } from 'antd';
import { connect } from 'react-redux';
import commonActions from 'store/actions/common';
import endpoints from 'config/endpoints';
import { moduleTypes } from 'lib/constants';
import moment from 'moment'
import i18n from 'plugins/i18n';
import { Link } from 'react-router-dom';
import config from 'config';
import { useSelector } from 'react-redux';

const Notifications = ({ getAlerts, alerts }) => {
  const [bellClicked, setBellClicked] = useState(false);

  const auth = useSelector(({ auth }) => ({ ...auth }));
  const hasAccess = auth.data ? auth.data.accessRights.find(x => x.endPoint === "Alerts") : false;

  const getData = useCallback(() => {
    let nextUpdateDate = localStorage.getItem('notificationUpdateDate');

    if (!nextUpdateDate || moment(nextUpdateDate).isBefore(moment())) {
      const filter = { count: true, filter: { IsSeen: false, AlertType: { ne: 4 } } }
      nextUpdateDate = moment().add(config.notificationsUpdateInterval, 'milliseconds');
      localStorage.setItem('notificationUpdateDate', nextUpdateDate);
      getAlerts(filter)
    }
  }, [getAlerts])

  useEffect(() => { setInterval(getData, config.notificationsUpdateInterval) }, [getData])

  useEffect(() => { getData() }, [getData])

  useEffect(() => {
    if (bellClicked) setTimeout(() => setBellClicked(false), 1000);
  }, [bellClicked])

  useEffect(() => {
    window.onbeforeunload = function () {
      localStorage.removeItem('notificationUpdateDate');
    };
  }, [])


  return (
    <Popover
      placement="bottom"
      title={i18n.t('lbl.notifications')}
      content={
        <div className="notifications-popover__content">
          <div className="content">
            {
              hasAccess ?
                alerts.list.data.length > 0 ?
                  alerts.list.data.map(x => (
                    <Link to={`/common/notifications#${x.Id}`} key={x.Id}>
                      <div key={x.Id} className={`item ${x.IsSeen ? '' : 'unread'} ${moduleTypes[x.ModuleType].toLowerCase()}`}>
                        <span>{moment(x.LastAlertDate).format('DD MMMM YYYY')}
                          {
                            x.Count > 1 &&
                            <Tooltip title={i18n.t('msg.multipleTimesNotificationWarning', { count: x.Count })}>
                              <span className="count">{x.Count}</span>
                            </Tooltip>
                          }
                        </span>
                        <div>{x.Title}</div>
                      </div>
                    </Link>
                  ))
                  :
                  <p>{i18n.t('msg.noNotification')}</p>
                :
                <p className="text-center">{i18n.t('msg.noAccessRight')}</p>
            }
          </div>
          {
            hasAccess &&
            <footer>
              <Link to="/common/notifications">
                {i18n.t('btn.seeAll')} {alerts.list.data.length > 0 && `(${alerts.list.count})`}
              </Link>
            </footer>
          }
        </div>
      }
      trigger="click"
      overlayClassName="notifications-popover"
    >
      <div className="bell-container">
        {
          alerts.list.loading ?
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
            :
            <Badge count={alerts.list.data.filter(x => !x.IsSeen).length} dot>
              <ReactSVG onClick={() => setBellClicked(!bellClicked)} className={`bell__icon ${bellClicked ? 'clicked' : ''}`} src={Bell} />
            </Badge>
        }
      </div>
    </Popover>
  )
}


const mapStateToProps = ({ common }) => ({
  alerts: common.alerts
})
const mapDispatchToProps = (dispatch) => ({
  getAlerts: (filter, callback) => dispatch(commonActions.getAll({ filter, url: endpoints.tenant.alerts, key: "alerts", isNewODataStructure: true }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)