import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { DataTable, Filter, ActionButtons, DropdownList, Description } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { defaultFilters, baseUrls } from 'lib/constants';
import Notification from './Detail';
import utils from 'lib';

class Notifications extends Component {
  state = {
    filter: defaultFilters.netekstre.Notifications(),
  };

  componentDidMount() {
    this.getNotifications();
    this.props.getCategories();
    this.props.getRoles();
    this.props.getUsers({ orderBy: 'name asc' });
    this.props.getTemplates();
    utils.common.checkAccessToUpdateOrDelete("Notifications");
  };

  getNotifications = () => {
    let { filter } = this.state;
    this.props.getNotifications(filter);
  };


  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getNotifications();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getNotifications);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({
      filter
    }, this.getNotifications);
  };

  getTemplateName = (row) => {
    let template = this.props.templates.data ? this.props.templates.data.find(x => x.id === row.templateId) : null;
    return template ? template.subject : '';
  };

  render() {
    let { notifications, categories, roles, users } = this.props;
    let filters = [
      { label: i18n.t('lbl.movementCategories'), type: 'select', options: categories.data, value: 'Id', name: 'Name', keys: ['notificationTransactionCategories'], any: 'categoryId', uniqueKey: '#movementCategories', multiple: true, guid: true },
      { label: i18n.t('lbl.roles'), type: 'select', options: roles.data, value: 'id', name: 'name', keys: ['notificationRoles'], any: 'roleId', uniqueKey: '#roles', multiple: true },
      { label: i18n.t('lbl.users'), type: 'select', options: users.data, value: 'id', name: (row) => `${row.name} ${row.surname}`, keys: ['notificationUsers'], any: 'userId', uniqueKey: '#users', multiple: true, guid: true, },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['description'], contains: true, uniqueKey: '#search' },
    ];
    let columns = [
      { render: (row) => <ActionButtons url="Notifications" editUrl item={row} hasDelete getDatas={this.getNotifications} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { key: 'Description', label: i18n.t('lbl.description'), render: (row) => <Description text={row.Description} /> },
      { key: 'NotificationCategories', sort: false, label: i18n.t('lbl.movementCategories'), render: ({ NotificationCategories }) => <DropdownList data={NotificationCategories.map(x => x.Category)} field="Name" /> },
      { key: 'NotificationRoles', sort: false, label: i18n.t('lbl.roles'), render: ({ NotificationRoles }) => <DropdownList data={NotificationRoles.map(x => x.Role)} field="Name" /> },
      { key: 'NotificationUsers', sort: false, label: i18n.t('lbl.users'), render: ({ NotificationUsers }) => <DropdownList data={NotificationUsers.map(x => ({ ...x.User, Name: `${x.User.Name} ${x.User.Surname}` }))} field="Name" /> },
      { key: 'Template/Subject', sort: false, label: i18n.t('lbl.template'), checkField: false }
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          onPageChange={this.onPageChange}
          access="Notifications"
          newButton="openDialog"
          history={this.props.history}
          onSort={this.onSort}
          columns={columns}
          data={notifications.list.data}
          loading={notifications.list.loading}
          title={i18n.t('route.netekstre.notifications')}
          Component={Notification}
          dialogTitle={i18n.t('lbl.notification')}
          getData={this.getNotifications}
          count={notifications.list.count}
          deleteOptions={{ stateKey: "notifications", url: "Notifications", baseUrl: baseUrls.tenant }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  roles: common.filter.roles,
  users: common.filter.users,

  notifications: netekstre.notifications,
  categories: netekstre.filter.movementCategories,
  templates: netekstre.filter.templates,
});
const mapDispatchToProps = (dispatch) => ({
  getRoles: (filter) => dispatch(commonActions.getFilter({ filter, url: endpoints.lookups.roles, key: 'roles' })),
  getUsers: (filter) => dispatch(commonActions.getFilter({ filter, url: endpoints.lookups.users, key: 'users' })),

  getNotifications: (filter) => dispatch(netekstreActions.getAll({ filter, url: endpoints.tenant.notifications, key: 'notifications', isNewODataStructure: true })),
  getCategories: (filter) => dispatch(netekstreActions.getFilter({ filter, url: endpoints.nte.tenantCategories, key: 'movementCategories', isNewODataStructure: true })),
  getTemplates: () => dispatch(netekstreActions.getFilter({ url: endpoints.tenant.notificationsGetBankAccountTransactionTemplates, key: 'templates' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);