import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import ReactSVG from 'react-svg';
import icons from 'assets/img/icons';

const Phone = ({ mobile, label, value, onChange, className, disabled, prefix = null }, ref) => {

  const _prefix = typeof prefix === "string" ? <ReactSVG src={icons[prefix]} /> : prefix;
  return (
    <InputMask
      mask={mobile ? "+\\90 (599) 999 99 99" : "0(999) 999 99 99"}
      value={value}
      onChange={({ target }) => onChange(target.value)}
    >
      {(inputProps) => (
        <div className={`nb nb-input ${!!disabled ? 'disabled' : ''} ${_prefix ? 'with-prefix' : ''} custom-phone__wrapper`}>
          <input
            ref={ref}
            className={`ant-input ${value !== null && value !== undefined && value.toString() !== '' ? 'is-filled' : ''} ${className || ''}`}
            {...inputProps} />
          <label>{label || ''}</label>
          {_prefix}
        </div>
      )}
    </InputMask>
  )
}

export default forwardRef(Phone);