import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input } from 'components/UIComponents/NetbankaComponents';
import { ErpTypeModel } from 'models';
import { httpMethods } from 'lib/constants';
import actions from 'store/actions/admin';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class ErpType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      data: new ErpTypeModel(),
      InitialState: new ErpTypeModel(),
    };

    this.id = props.id;
  };

  componentDidMount() {
    if (this.id) {
      this.props.getErpType(this.id);
    }
    else {
      this.setState({ loading: false });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.erpTypes.single.data && !state.data.id) {
      const data = new ErpTypeModel(props.erpTypes.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.erpTypes.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
  }


  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };


  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, (response) => {
          if (response) {
            this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
          }
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    const { erpTypes, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={erpTypes.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={erpTypes.single.loading} form="erpType" htmlType="submit" className="success #save" icon="save" loading={erpTypes.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      erpTypes.single.saving,
      erpTypes.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { erpTypes, dialogCloseRequest } = this.props;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...erpTypes, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="erpType">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#name" label={i18n.t('lbl.erpName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('description', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.description
                    })(
                      <Input className="#description" label={i18n.t('lbl.description')} onChange={(e) => this.update('description', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ admin }) => ({ erpTypes: admin.erpTypes });
const mapDispatchToProps = (dispatch) => ({
  getErpType: (id, callback) => dispatch(actions.get({ url: endpoints.tenant.erpTypes, key: 'erpTypes', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tenant.erpTypes, key: 'erpTypes', data }, callback)),
  clearState: () => dispatch(actions.clearState("erpTypes")),
});
const ErpTypeForm = Form.create()(ErpType);
export default connect(mapStateToProps, mapDispatchToProps)(ErpTypeForm);