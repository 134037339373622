import React, { useEffect } from 'react';
import Content from './Content'
import Header from './Header'
import { Layout as AntLayout } from "antd";
import { connect } from "react-redux";
import authActions from 'store/actions/auth';
import commonActions from 'store/actions/common';
import { projectTypes } from 'lib/constants';
import config from 'config';


const Layout = ({ auth, getAccount, setModule }) => {
  useEffect(() => {
    setModule("home");
  }, [setModule])

  useEffect(() => {
    if(config.projectType === projectTypes.settings) window.location.replace('/admin')
  },[])

  useEffect(() => {
    !auth.data && getAccount((response) => {
      let expirationTime = response.systemParameters ? JSON.parse(response.systemParameters).TokenExpirationTime : undefined;
      localStorage.setItem("expirationTime", expirationTime);
    });
  }, [getAccount, auth])

  return (
    <AntLayout className="home-layout">
      <Header />
      <Content loading={auth.fetching} />
    </AntLayout>
  );
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  gridLoading: common.gridSettings.list.loading,
  initialized: common.gridSettings.list.initialized
});
const mapDispatchToProps = (dispatch) => ({
  setModule: (moduleName) => dispatch(commonActions.setModule(moduleName)),
  getAccount: (callback) => dispatch(authActions.getAccount(callback))
})
export default connect(mapStateToProps, mapDispatchToProps)(Layout);