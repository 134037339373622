export const GET_ALL_CEK = "GET_ALL_CEK";
export const GET_ALL_CEK_SUCCESS = "GET_ALL_CEK_SUCCESS";
export const GET_ALL_CEK_FAILURE = "GET_ALL_CEK_FAILURE";
export const GET_CEK = "GET_CEK";
export const GET_CEK_SUCCESS = "GET_CEK_SUCCESS";
export const GET_CEK_FAILURE = "GET_CEK_FAILURE";
export const POST_CEK = "POST_CEK";
export const POST_CEK_SUCCESS = "POST_CEK_SUCCESS";
export const POST_CEK_FAILURE = "POST_CEK_FAILURE";
export const PUT_CEK = "PUT_CEK";
export const PUT_CEK_SUCCESS = "PUT_CEK_SUCCESS";
export const PUT_CEK_FAILURE = "PUT_CEK_FAILURE";
export const DELETE_CEK = "DELETE_CEK";
export const DELETE_CEK_SUCCESS = "DELETE_CEK_SUCCESS";
export const DELETE_CEK_FAILURE = "DELETE_CEK_FAILURE";
export const PATCH_CEK = "PATCH_CEK";
export const PATCH_CEK_SUCCESS = "PATCH_CEK_SUCCESS";
export const PATCH_CEK_FAILURE = "PATCH_CEK_FAILURE";
export const GET_FILTER_CEK = "GET_FILTER_CEK";
export const GET_FILTER_CEK_SUCCESS = "GET_FILTER_CEK_SUCCESS";
export const GET_FILTER_CEK_FAILURE = "GET_FILTER_CEK_FAILURE";
export const CLEAR_STATE_CEK = "CLEAR_STATE_CEK";
export const GET_WITH_POST_CEK = "GET_WITH_POST_CEK";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_CEK, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_CEK_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_CEK_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_CEK, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_CEK_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_CEK_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_CEK, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_CEK_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_CEK_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_CEK, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_CEK_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_CEK_FAILURE, payload })
  },

  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_CEK, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_CEK, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_CEK_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_CEK_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_CEK, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_CEK_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_CEK_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_CEK, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_CEK_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_CEK_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key) => {
    return ({ type: CLEAR_STATE_CEK, key })
  }
  //#endregion
}

export default actions;