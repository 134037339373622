import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import adminRoutes from 'plugins/routes/admin';

class Definitions extends Component {
  render() {
    const definitions = adminRoutes.routes.find(x => x.name === 'definitions');
    let { accessRights } = this.props;
    return (
      <React.Fragment>
        <Switch>
          {definitions.routes.map((route, i) => {
            if ((!route.access) ||
              (route.access && typeof route.access === 'string' && accessRights.length > 0 && accessRights.find(x => x.endPoint === route.access && (x.method === 'GET'))) ||
              (route.access && Array.isArray(route.access) && accessRights.length > 0 && accessRights.some(x => route.access.includes(x.endPoint)))
            )
              return <Route path={`${adminRoutes.path}${definitions.path}${route.path}`} render={(props) => <route.component {...props} accessRights={accessRights} />} key={`rd-${i}`} />;
            else
              return null
          })}
          <Redirect from="/admin" to="/admin/packages" />
        </Switch>
      </React.Fragment>
    );
  };
};

export default Definitions;