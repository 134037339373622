import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Modal } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { TenantBankModel } from 'models';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods, declaredBankEftCodes } from 'lib/constants';

const tenantBankParameterGetByEftCodePersistentFilter = { filter: { visible: true } }

class TenantBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      isParametersLoaded: false,
      data: new TenantBankModel(),
      InitialState: new TenantBankModel(),
      tenantBankParameters: [],
      refreshTokenRequestLoading: false,
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.tenantBanks.single.data && props.tenantBanks.single.saving === null && !state.data.id) {
      let data = new TenantBankModel({ ...props.tenantBanks.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (!state.isParametersLoaded && !_.isEqual(Array.from(props.tenantBankParameters.list.data), state.tenantBankParameters)) {
      return { tenantBankParameters: _.cloneDeep(props.tenantBankParameters.list.data), isParametersLoaded: true }
    }
    if (props.dialogClosing && !props.tenantBanks.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    const { endpoint, getTenantBanks, getBanks, getTenantBankParameters, tenantId } = this.props;
    const parameters = tenantId && { TenantId: tenantId };
    this.getRoles();
    this.getTenantModules();

    getBanks({ orderBy: ['name asc'] });
    if (this.id) {
      getTenantBanks(this.id, endpoint, parameters, bankData =>
        //endpoint yoksa NTE içersinde bir işlemdir, nteapi isteği atılabilir.
        (!endpoint && bankData) && getTenantBankParameters(bankData.bankEftCode)
      );
    }
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearStateCommon("tenantBanks");
    this.props.clearStateNetekstre("tenantBankParameters");
  }

  getRoles = () => {
    let parameters;
    const { tenantId } = this.props
    if (tenantId) parameters = { TenantId: tenantId }
    this.props.getRoles(parameters);
  }

  getTenantModules = () => {
    let parameters;
    const { tenantId } = this.props
    if (tenantId) parameters = { TenantId: tenantId }
    this.props.getTenantModules(parameters);
  }

  getTenantBanks = () => {
    this.props.getTenantBanks({ orderBy: ['name asc'] });
  };

  update = (key, value) => {
    let { data } = this.state;
    const { endpoint } = this.props
    data[key] = value;
    if (key === 'bankEftCode' && !endpoint) {
      this.setState({ isParametersLoaded: false }, () => {
        if (value) {
          this.props.getTenantBankParameters(value)
        }
        else this.props.clearStateNetekstre("tenantBankParameters");
      })
    }
    this.setState({ data });
  };

  updateParameter = (key, value, index) => {
    let { tenantBankParameters } = this.state;
    if (tenantBankParameters.length) tenantBankParameters[index].value = value;
    this.setState({ tenantBankParameters });
  };

  save = (e) => {
    const { dialogCloseRequest, endpoint, saveData, form, saveTenantBankParameters, tenantId } = this.props;
    const { data, tenantBankParameters } = this.state
    e.preventDefault();
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        const parameters = tenantId && { TenantId: tenantId };
        if (endpoint) {
          //endpoint var ise dışardıdan bir istek demektir (Softtech), bunun için NTE'ye istekte bulunmuyoruz.
          saveData(data, method, endpoint, parameters, response => {
            const tenantBankId = response.data;
            this.setState({ InitialState: data }, () => {
              dialogCloseRequest({ runGetData: true });
              this.getRefreshToken(tenantBankId);
            })
          });
        }
        else {
          saveData(data, method, endpoint, parameters, response => {
            const tenantBankId = response.data
            const tenantBankParametersData = tenantBankParameters.map(x => ({ ...x, tenantBankId }));
            saveTenantBankParameters(tenantBankParametersData, method, () => {
              this.setState({ InitialState: data }, () => {
                dialogCloseRequest({ runGetData: true });
                this.getRefreshToken(tenantBankId);
              })
            });
          })
        }
      }
    });
  };

  getRefreshToken = (tenantBankId) => {
    const { bankEftCode } = this.state.data;

    if (bankEftCode === declaredBankEftCodes.Anadolubank) {
      this.setState({ refreshTokenRequestLoading: true });
      this.props.getRefreshToken(tenantBankId || this.id, (response) => {
        this.setState({ refreshTokenRequestLoading: false });
        if (response) {
          const confirmModal = Modal.confirm();
          confirmModal.update({
            title: i18n.t('msg.redirectWarning'),
            content: i18n.t('msg.redirectWarningMessage'),
            okText: i18n.t('btn.continue'),
            cancelText: i18n.t('btn.cancel'),
            onOk: () => {
              confirmModal.update({ okButtonProps: { loading: true } })
              window.location.replace(response.data);
            }
          })
        }
      })
    }
  }

  renderDialogButtons = checkAccessRights => {
    let { tenantBanks, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={tenantBanks.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={tenantBanks.single.loading} form="tenantBank" htmlType="submit" className="success #save" icon="save" loading={tenantBanks.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      tenantBanks.single.saving,
      tenantBanks.single.loading
    )
  }

  render() {
    const { loading, data, refreshTokenRequestLoading } = this.state;
    const { tenantBanks, banks, roles, dialogCloseRequest, tenantModules } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...tenantBanks, renderDialogButtons, dialogCloseRequest }}>
          <Form onSubmit={this.save} id="tenantBank" autoComplete="off">
            <Loading loading={loading}>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bank')}
                        options={banks.data}
                        optVal="eftCode"
                        optName={(bank) => <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment>}
                        onChange={(e) => this.update('bankEftCode', e)}
                        disabled={!!this.id}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#merchantCode" label={i18n.t('lbl.merchantCode')} value={data.merchantCode} onChange={(e) => this.update('merchantCode', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('modules', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.modules
                    })(
                      <Select
                        mode="multiple"
                        className="#modules"
                        label={i18n.t('lbl.modules')}
                        options={tenantModules.data.filter(x => x.name !== 'Ortak')}
                        optName="name"
                        optVal="name"
                        onChange={(e) => this.update('modules', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('roleIds', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.roleIds
                    })(
                      <Select mode="multiple" className="#roleIds" label={i18n.t('lbl.roles')} options={roles.data || []} optName="name" optVal="id" onChange={(e) => this.update('roleIds', e)} />
                    )}
                  </Form.Item>
                </Col>
                {
                  this.id &&
                  data.bankEftCode === declaredBankEftCodes.Anadolubank &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button loading={refreshTokenRequestLoading} onClick={() => this.getRefreshToken()} htmlType="button" block className="blue #refresh" icon="api">{i18n.t('btn.getRefreshToken')}</Button>
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Loading>
          </Form>
        </CheckAccessRight>
      </div >
    );
  };
};

const mapStateToProps = ({ auth, netekstre, common }) => ({
  modules: auth.data.modules,

  tenantModules: common.filter.tenantModules,
  tenantBanks: common.tenantBanks,
  tenantBankParameters: netekstre.tenantBankParameters,
  banks: netekstre.filter.banks,
  roles: netekstre.filter.roles,
});
const mapDispatchToProps = (dispatch) => ({
  getTenantBanks: (id, endpoint, parameters, callback) => dispatch(commonActions.get({ url: endpoint || endpoints.tenant.tenantBanks, parameters, key: 'tenantBanks', id }, callback)),
  saveData: (data, method, endpoint, parameters, callback) => dispatch(commonActions[method]({ url: endpoint || endpoints.tenant.tenantBanks, parameters, key: 'tenantBanks', data }, callback)),
  getTenantBankParameters: (id) => dispatch(netekstreActions.getAll({ url: endpoints.nte.tenantBankParameterGetByEftCode, key: "tenantBankParameters", id, filter: tenantBankParameterGetByEftCodePersistentFilter })),
  saveTenantBankParameters: (data, method, callback) => dispatch(netekstreActions[method]({ data, url: endpoints.nte.tenantBankParameterBulk, key: "tenantBankParameters" }, callback)),
  getBanks: (filter) => dispatch(netekstreActions.getFilter({ url: endpoints.tenant.banks, key: 'banks', filter })),
  getRoles: (parameters) => dispatch(netekstreActions.getFilter({ url: endpoints.lookups.roles, key: 'roles', parameters })),
  getRefreshToken: (tenantBankId, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.anadolubankReturnUrl(tenantBankId), options: { errors: { showMessage: true } } }, callback)),
  clearStateCommon: (key) => dispatch(commonActions.clearState(key)),
  clearStateNetekstre: (key) => dispatch(netekstreActions.clearState(key, true)),
  getTenantModules: (parameters) => dispatch(commonActions.getFilter({ parameters, url: endpoints.lookups.tenantModules, key: 'tenantModules' })),
});
const TenantBankForm = Form.create()(TenantBank);
export default connect(mapStateToProps, mapDispatchToProps)(TenantBankForm);