import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { DataTable, Filter, DropdownList, ActionButtons, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, currentAccountTypesLookup, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import CurrentAccount from './Detail';
import utils from 'lib';

const PageAccesses = {
  CurrentAccounts: "CurrentAccounts",
  AdminCurrentAccounts: "AdminCurrentAccounts"
}

class CurrentAccounts extends Component {
  state = {
    loading: false,
    filter: defaultFilters.common.CurrentAccounts(),
    accountCodes: [],
    getDataTrigger: undefined,
    access: PageAccesses.CurrentAccounts
  };

  componentDidMount() {
    const { getDataTrigger } = this.props;

    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getCurrentAccounts();
      access = PageAccesses.CurrentAccounts;
    }
    else {
      access = PageAccesses.AdminCurrentAccounts;
    }
    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })


    let key = 'common/definitions/current-accounts';
    let accountCodeFilter = this.props.currentFilter[key] && this.props.currentFilter[key].filter && this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.Code)) ? this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.Code)).or.find(x => x.Code).Code.contains : undefined;
    accountCodeFilter && this.accountCodeSearch(accountCodeFilter);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id }
      const filter = state.filter
      props.getCurrentAccounts(filter, parameters, endpoints.tenant.adminCurrentAccounts);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  getCurrentAccounts = () => {
    const { getDataTrigger } = this.props;
    let { filter } = this.state;
    if (getDataTrigger)
      this.props.getCurrentAccounts(filter, { TenantId: getDataTrigger.id }, endpoints.tenant.adminCurrentAccounts);
    else
      this.props.getCurrentAccounts(filter);

  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({
      filter
    }, this.getCurrentAccounts);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getCurrentAccounts);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getCurrentAccounts);
  };

  getCurrentName = ({ CurrentAccountType }) => {
    let item = currentAccountTypesLookup.find(({ value }) => value === CurrentAccountType);
    return item ? i18n.t(`lbl.${item.name}`) : '';
  };

  onCallback = (e, type) => {
    if (!e) this.setState({ [type]: [] });
  };

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  accountCodeClean = () => {
    this.props.getAccountCodes('');
  }

  renderActionButtons = row => {
    const { access } = this.state;
    return <ActionButtons url={access} editUrl item={row} hasDelete getDatas={this.getCurrentAccounts} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { currentAccounts, showFilter, hasParent, className, datatableTitle, getDataTrigger, datatableCollapsed } = this.props;
    const { accountCodes, access, filter } = this.state;
    const filters = [
      { label: i18n.t('lbl.currentAccountCode'), options: accountCodes, type: 'select', contains: true, search: true, onSearch: this.accountCodeSearch, onFocus: this.accountCodeClean, value: 'Code', name: (e) => `${e && e.Code ? e.Code : ''} ${e && e.Title ? e.Title : ''}`, keys: ['Code'], callback: (e) => this.onCallback(e, 'accountCodes'), uniqueKey: '#currentAccountCode' },
      { label: i18n.t('lbl.currentAccountName'), type: 'input', keys: ['Title'], contains: true, uniqueKey: '#currentAccountName' },
      { label: i18n.t('lbl.currentAccountType'), type: 'select', options: currentAccountTypesLookup, value: 'value', name: row => row && i18n.t(`lbl.${row.name}`), keys: ['CurrentAccountType'], showAll: true, uniqueKey: '#currentAccountType' },
      { label: i18n.t('lbl.tc'), type: 'input', keys: ['TcknNumber'], contains: true, uniqueKey: '#tc' },
      { label: i18n.t('lbl.taxNumber'), type: 'input', keys: ['TaxNumber'], contains: true, uniqueKey: '#taxNumber' },
      { label: i18n.t('lbl.iban'), type: 'input', keys: ['Ibans'], any: 'Iban', uniqueKey: '#iban' },
      { label: i18n.t('lbl.bankAccountNumber'), type: 'input', keys: ['BankAccountNumbers'], any: 'BankAccountNumber', uniqueKey: '#bankAccountNumber' }
    ];
    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.currentAccountCode'), key: 'Code' },
      { label: i18n.t('lbl.currentAccountName'), key: 'Title' },
      { label: i18n.t('lbl.currentAccountType'), key: 'CurrentAccountType', render: this.getCurrentName },
      { label: i18n.t('lbl.nameSurname'), key: 'Name' },
      { label: i18n.t('lbl.email'), key: 'Email' },
      { label: i18n.t('lbl.phone'), key: 'Phone' },
      { label: i18n.t('lbl.mobilePhone'), key: 'Gsm' },
      { label: i18n.t('lbl.tc'), key: 'TcknNumber' },
      { label: i18n.t('lbl.taxNumber'), key: 'TaxNumber' },
      { label: i18n.t('lbl.onlineJobNumber'), key: 'OnlineJobNumber' },
      { label: i18n.t('lbl.iban'), key: 'Ibans', sort: false, render: ({ Ibans }) => <DropdownList data={Ibans} field="Iban" /> },
      { label: i18n.t('lbl.bankAccountNumbers'), key: 'BankAccountNumbers', sort: false, render: ({ BankAccountNumbers }) => <DropdownList data={BankAccountNumbers} field="BankAccountNumber" /> },
    ];

    return (
      <FadeIn className={`page-content ${className || ''}`}>
        <CheckAccessRight {...{ ...currentAccounts, hasParent: getDataTrigger !== undefined }}>

          {
            showFilter !== false &&
            <Filter filters={filters} onFilter={this.onFilter} />
          }
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            onPageChange={this.onPageChange}
            access={access}
            history={this.props.history}
            newButton="openDialog"
            onSort={this.onSort}
            columns={columns}
            data={currentAccounts.list.data}
            loading={currentAccounts.list.loading}
            excel={{ url: getDataTrigger ? "AdminExportCurrentAccounts" : 'ExportCurrentAccounts', module: "tenant", parameters: getDataTrigger && { TenantId: getDataTrigger.id }, filter: { filter: filter.filter } }}
            title={datatableTitle || i18n.t('route.common.currentAccounts')}
            fromExcel={{ url: "ImportsCurrentAccounts", baseUrl: baseUrls.tenant }}
            getData={this.getCurrentAccounts}
            count={currentAccounts.list.count}
            Component={CurrentAccount}
            dialogTitle={i18n.t('lbl.currentAccount')}
            deleteOptions={{ stateKey: "currentAccounts", url: access, baseUrl: baseUrls.tenant }}
            hasParent={hasParent}
            disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
            collapsed={datatableCollapsed}
            dialogOptions={{
              endpoint: getDataTrigger && endpoints.tenant.adminCurrentAccounts,
              tenantId: getDataTrigger && getDataTrigger.id
            }}
          />
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  currentAccounts: common.currentAccounts,
  currentFilter: common.currentFilter
});
const mapDispatchToProps = (dispatch) => ({
  getCurrentAccounts: (filter, parameters, endpoint) => dispatch(actions.getAll({ filter, parameters, url: endpoint || endpoints.tenant.currentAccounts, key: 'currentAccounts', isNewODataStructure: true })),
  getAccountCodes: (data, callback) => dispatch(actions.getAccountCodes(data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(CurrentAccounts);