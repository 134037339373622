
import P from "./P";
import H from "./H";
import Label from "./Label";


export default class Typography {
  static P = P;
  static H = H;
  static Label = Label;
}