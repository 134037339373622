import React from 'react';

let eventList = {};

React.on = (event, callback) => {
    eventList[event] ? eventList[event].push(callback) : eventList[event] = [callback];
};

React.emit = (event, data) => {
    eventList[event] && eventList[event].map(x => x(data));
};