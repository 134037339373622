import home from './home';
import netekstre from './modules/netekstre';
import posrapor from './modules/posrapor';
import dbs from './modules/dbs';
import kredi from './modules/kredi';
import tos from './modules/tos';
import cek from './modules/cek';
import common from './modules/common';
import netahsilat from './modules/netahsilat';
import auth from './auth';
import register from './register';
import admin from './admin';
import config from 'config';
import { projectTypes } from 'lib/constants';

const routes = [
  admin,
  auth,
  register,
  home,
]

if (config.projectType === projectTypes.netfinans) {
  routes.push(
    netekstre,
    posrapor,
    dbs,
    kredi,
    tos,
    cek,
    common,
    netahsilat
  )
}

export default routes;