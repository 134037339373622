import React, { useState, useEffect, useRef } from 'react'
import { FormElements, Installments, Typography, BorderedContent, KrediContent } from 'components/UIComponents'
import i18n from 'plugins/i18n';
import { Form, Row, Col, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import actions from 'store/redux/netekstre';
import { httpMethods } from 'lib/constants';

const Application = ({ form, getProducts, getBanks, products, banks, getFee, auth, setPage }) => {
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ amount: null, banks: [], products: [] })
  const [fee, setFee] = useState(null);
  const getFeeTimeOut = useRef(null);



  useEffect(() => {
    getProducts();
    getBanks();
  }, [getProducts, getBanks])

  useEffect(() => {
    clearTimeout(getFeeTimeOut.current);
    getFeeTimeOut.current = setTimeout(() => {
      data.amount && getFee({ pureAmount: parseFloat(data.amount) }, response => {
        setFee(response)
      });
    }, 1000)
  }, [data.amount, getFee, auth.tenantId]);


  return (
    <KrediContent>
      <Form>
        <Row gutter={44}>
          <Col xs={24} md={6}>
            <Form.Item>
              {
                getFieldDecorator('amount', {
                  rules: [
                    { required: true, message: i18n.t('msg.required') }
                  ],
                  initialValue: data.amount
                })(
                  <FormElements.Amount
                    label={i18n.t("lbl.amount")}
                    prefix="tl"
                    onChange={e => updateData(data => ({ ...data, amount: e }))}
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={44} className="mt-20">
          <Col xs={24} md={6}>
            <Form.Item>
              <FormElements.SelectableList
                title={i18n.t('lbl.productType')}
                text={i18n.t('lbl.pleaseProductType')}
                onChange={e => updateData(data => ({ ...data, products: e }))}
                options={products.data}
                loading={products.loading}
                disabled={data.amount < 1000}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item>
              <FormElements.SelectableList
                title={i18n.t('lbl.bankSelect')}
                text={i18n.t('lbl.pleaseSelectYourBankAccount')}
                onChange={e => updateData(data => ({ ...data, banks: e }))}
                options={banks.data}
                loading={banks.loading}
                disabled={data.amount < 1000}
              />
            </Form.Item>
          </Col>
        </Row>
        {
          data.amount &&
          data.products.length > 0 &&
          data.banks.length > 0 &&
          <Row gutter={44} className="mt-20">
            <Col xs={24} md={24} className="mobile-text-center">
              <Typography.H size={2} className="pl-10"> {i18n.t("lbl.installmentOptions")} </Typography.H>
            </Col>
            {
              data.banks.map(item =>
                <Col xs={24} md={7} key={item}>
                  <Installments.InstallmentPlan {...{ products: data.products, amount: data.amount, bank: item }} />
                </Col>
              )
            }
          </Row>
        }
        {
          data.amount &&
          data.products.length > 0 &&
          data.banks.length > 0 &&
          fee &&
          <Row gutter={44}>
            <Col xs={24} md={16}>
              <BorderedContent>
                <Typography.P size="semi-bold mb-10" color="#2381d3">
                  <Icon type="exclamation-circle" className="pr-5" /> {i18n.t("lbl.feeAmount", { amount: fee.feeAmount.toFixed(2) })}
                </Typography.P>
                <Typography.P size="semi-bold pl-20 pb-5" color="#474747">
                  {i18n.t('lbl.creditAmount')} : {Number.prototype.formatThousand(data.amount)} ₺
              </Typography.P>
                <Typography.P size="semi-bold pl-20" color="#474747">
                  {i18n.t('lbl.transferredAmount')} : {Number.prototype.formatThousand(fee.netAmount)} ₺
            </Typography.P>
              </BorderedContent>
            </Col>
          </Row>
        }

        <Row gutter={44} className="mt-20 mobile-text-center">
          <Col xs={24} md={6}>
            <Button ghost type="default" htmlType="button" size="large" >
              {i18n.t('btn.cancel')}
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Button
              {...{
                type: "primary",
                htmlType: "submit",
                size: "large",
                loading: false,
                disabled: !fee,
                onClick: () => setPage({
                  current: 'SendingUrl',
                  parameters: {
                    pureAmount: fee.pureAmount,
                    netAmount: fee.netAmount,
                    feeAmount: fee.feeAmount,
                    products: data.products,
                  }
                })
              }}
            >
              {i18n.t('btn.createTransactionUrl')}
            </Button>
          </Col>
        </Row>
      </Form>
    </KrediContent>
  )
}

const mapStateToProps = ({ global, auth }) => ({
  products: global.filter.products,
  banks: global.filter.krediBanks,
  auth: auth.data
});
const mapDispatchToProps = (dispatch) => ({
  getProducts: () => dispatch(actions.globalRequest({ url: endpoints.kredi.getProducts, key: 'filter.products' })),
  getBanks: () => dispatch(actions.globalRequest({ url: endpoints.kredi.getBanks, key: 'filter.krediBanks' })),
  getFee: (data, callback) => dispatch(actions.globalRequest({ url: endpoints.kredi.getFee, data }, httpMethods.post, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Application));