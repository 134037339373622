export const GET_ALL_COMMON = "GET_ALL_COMMON";
export const GET_ALL_COMMON_SUCCESS = "GET_ALL_COMMON_SUCCESS";
export const GET_ALL_COMMON_FAILURE = "GET_ALL_COMMON_FAILURE";
export const GET_COMMON = "GET_COMMON";
export const GET_COMMON_SUCCESS = "GET_COMMON_SUCCESS";
export const GET_COMMON_FAILURE = "GET_COMMON_FAILURE";
export const POST_COMMON = "POST_COMMON";
export const POST_COMMON_SUCCESS = "POST_COMMON_SUCCESS";
export const POST_COMMON_FAILURE = "POST_COMMON_FAILURE";
export const PUT_COMMON = "PUT_COMMON";
export const PUT_COMMON_SUCCESS = "PUT_COMMON_SUCCESS";
export const PUT_COMMON_FAILURE = "PUT_COMMON_FAILURE";
export const DELETE_COMMON = "DELETE_COMMON";
export const DELETE_COMMON_SUCCESS = "DELETE_COMMON_SUCCESS";
export const DELETE_COMMON_FAILURE = "DELETE_COMMON_FAILURE";
export const PATCH_COMMON = "PATCH_COMMON";
export const PATCH_COMMON_SUCCESS = "PATCH_COMMON_SUCCESS";
export const PATCH_COMMON_FAILURE = "PATCH_COMMON_FAILURE";
export const GET_FILTER_COMMON = "GET_FILTER_COMMON";
export const GET_FILTER_COMMON_SUCCESS = "GET_FILTER_COMMON_SUCCESS";
export const GET_FILTER_COMMON_FAILURE = "GET_FILTER_COMMON_FAILURE";
export const CLEAR_STATE_COMMON = "CLEAR_STATE_COMMON";
export const CLEAR_FILTER_COMMON = "CLEAR_FILTER_COMMON";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const UPDATE_FILTER = "UPDATE_FILTER";
export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_MODULE = "SET_MODULE";
export const SEEN_VERSION = "SEEN_VERSION";
export const GET_VERSION = "GET_VERSION";
export const CHANGE_TENANT = "CHANGE_TENANT";
export const GET_ACCOUNT_CODES = "GET_ACCOUNT_CODES";
export const GET_PLAN_CODES = "GET_PLAN_CODES";
export const TOGGLE_DATA = "TOGGLE_DATA";
export const EXCEL_EXPORT = "EXCEL_EXPORT"
export const CLEAR_EXCEL_FORMAT = "CLEAR_EXCEL_FORMAT";
export const GET_WITH_POST_COMMON = "GET_WITH_POST_COMMON";
export const LISTEN_THREE_D_PROCESS = "LISTEN_THREE_D_PROCESS";

export const SET_DATATABLE_INLINE_VALUE = "SET_DATATABLE_INLINE_VALUE";
export const CLEAR_DATATABLE_INLINE_VALUE = "CLEAR_DATATABLE_INLINE_VALUE";
export const SET_DATATABLE_BULK_VALUE = "SET_DATATABLE_BULK_VALUE";
export const CLEAR_DATATABLE_BULK_VALUES = "CLEAR_DATATABLE_BULK_VALUES";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_COMMON, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_COMMON_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_COMMON_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_COMMON, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_COMMON_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_COMMON_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_COMMON, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_COMMON_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_COMMON_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST] 
  post: (payload, callback) => {
    return ({ type: POST_COMMON, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_COMMON_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_COMMON_FAILURE, payload })
  },
  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_COMMON, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_COMMON, payload, callback })
  },
  putSuccess: (payload, callback) => {
    return ({ type: PUT_COMMON_SUCCESS, payload, callback })
  },
  putFailure: (payload, callback) => {
    return ({ type: PUT_COMMON_FAILURE, payload, callback })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_COMMON, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_COMMON_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_COMMON_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_COMMON, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_COMMON_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_COMMON_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key) => {
    return ({ type: CLEAR_STATE_COMMON, key })
  },
  setSelectedItems: (payload, callback) => {
    return ({ type: SET_SELECTED_ITEMS, payload, callback })
  },
  updateFilter: (payload) => {
    return ({ type: UPDATE_FILTER, payload })
  },
  changeLanguage: (payload) => {
    return ({ type: CHANGE_LANGUAGE, payload })
  },
  setModule: (payload) => {
    return ({ type: SET_MODULE, payload })
  },
  seenVersion: (payload, callback) => {
    return ({ type: SEEN_VERSION, payload, callback })
  },
  getVersion: (callback) => {
    return ({ type: GET_VERSION, callback })
  },
  changeTenant: (payload, callback) => {
    return ({ type: CHANGE_TENANT, payload, callback })
  },
  getAccountCodes: (payload, callback) => {
    return ({ type: GET_ACCOUNT_CODES, payload, callback })
  },
  getPlanCodes: (payload, callback) => {
    return ({ type: GET_PLAN_CODES, payload, callback })
  },
  toggleData: (payload) => {
    return ({ type: TOGGLE_DATA, payload })
  },
  changePassword: (payload) => {
    return ({ type: CHANGE_PASSWORD, payload })
  },
  excelExport: (payload) => {
    return ({ type: EXCEL_EXPORT, payload })
  },
  clearExcelFormat: (key) => {
    return ({ type: CLEAR_EXCEL_FORMAT, key })
  },
  listen3DProcess: (payload, callback) => {
    return ({ type: LISTEN_THREE_D_PROCESS, payload, callback })
  },
  setDatatableInlineValue: (payload) => {
    return ({ type: SET_DATATABLE_INLINE_VALUE, payload })
  },
  clearDatatableInlineValue: () => {
    return ({ type: CLEAR_DATATABLE_INLINE_VALUE })
  },
  setDatatableBulkValue: (payload, index) => {
    return ({ type: SET_DATATABLE_BULK_VALUE, payload, index })
  },
  clearDatatableBulkValues: () => {
    return ({ type: CLEAR_DATATABLE_BULK_VALUES })
  },
  clearFilter: (key) => {
    return ({ type: CLEAR_FILTER_COMMON, key })
  }
  //#endregion
}

export default actions;