import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Tooltip, Modal } from 'antd';
import actions from 'store/actions/tos';
import { formatCurrency } from 'helpers';
import { TosCreateOrSendOrderModel } from 'models';
import { ActionButtons, DataTable, Instruction, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import _ from 'lodash';
import utils from 'lib'
import { defaultFilters } from 'lib/constants';
import i18n from 'plugins/i18n';
import CreatePayment from './Detail';

const persistentFilter = { OperationStatusId: { eq: 1 } }

class CreatePayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.tos.CreatePayments(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      orderSendingOrCreating: false,
      options: [],
    };
  };

  componentDidMount() {
    this.getOrderDetails();
    utils.tos.getTenantBanks('TosOrderDetail');

    this.getInvoiceTotal();
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.options.length) return {
      options: _.cloneDeep(props.tenantBanks.data.map(x => {
        // const label = utils.tos.getBankName({ bankData: x }); //TODO:
        return { value: x.Code, label: x.Name, type: 'BankCode', isLeaf: false }
      }
      ))
    }
    return null;
  }

  getOrderDetails = callback => {
    let { filter } = this.state;
    this.setState({ totalsLoading: true })
    this.props.getOrderDetails(filter, callback);
  };

  getInvoiceTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll || !!this.id) {
      let filter = {};
      filter.filter = {
        // and: [{ TosOrderId: { type: 'guid', value: this.id }, }],
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }

      this.props.getInvoiceTotal(filter, response => {

        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(invoice =>
            _amount += invoice.Amount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
        else {
          this.setState({ totalsLoading: false })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getOrderDetails();
    });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getOrderDetails(response => {
        this.getInvoiceTotal(response);
      });
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getOrderDetails());
  }

  additionalButtons = () => {
    let buttons = [
      <div>
        <Icon type="plus" className="mr-5" />
        {i18n.t('tos.downloadExampleFile')}
      </div>,
      <div>
        <Icon type="plus" className="mr-5" />
        {i18n.t('tos.filePayment')}
      </div>
    ];

    return buttons;
  };

  orderOperation = (data, method) => {
    const bankData = this.props.bankAccounts.data.find(x => x.AccountNumber === data.BankAccount[2] && x.Suffix === data.BankAccount[3]) // [0,1,2,3] => EFT CODE, Branchnumber, AccountNumber, Suffix
    let _data = {
      Description: data.Description,
      PaymentDate: data.PaymentDate,
      TenantPaymentAccountId: bankData.Id,
      OrderDetailIdList: this.props.selectedItems
    }
    this.setState({ orderSendingOrCreating: true })
    _data = new TosCreateOrSendOrderModel(_data)

    this.props[method](_data, (response) => {
      this.setState({ orderSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 || response.statusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: (response && response.Message) ? response.Message : status === 'success' ? i18n.t('msg.success') : i18n.t('msg.error'),
          content: status === 'success' ? i18n.t(`dbs.${method}Response`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.getOrderDetails(response => {
              this.getInvoiceTotal(response);
              utils.common.clearSelectedItems();
            });
          }
        })
      }
    })
  }

  callbackDelete = () => {
    this.getOrderDetails(response => {
      this.getInvoiceTotal(response);
    });
  }

  loadData = selectedOptions => {
    let targetOption = {};
    targetOption = selectedOptions[selectedOptions.length - 1];

    if (targetOption.type === "BankCode") {
      targetOption.loading = true;
      const filter = { filter: { 'TenantBank/Bank/EftCode': targetOption.value, BranchNumber: { ne: null } }, }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.branchName'), type: 'BranchNumber', disabled: true },
          ...response.value.filter((k, i, j) => j.findIndex(t => (t.id === k.id)) === i).map(x => ({ label: `${x.BranchName} (${x.BranchNumber})`, value: x.BranchNumber, type: 'BranchNumber', isLeaf: false }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }

    if (targetOption.type === "BranchNumber") {
      targetOption.loading = true;
      const filter = {
        filter: {
          'TenantBank/Bank/EftCode': selectedOptions.find(x => x.type === "BankCode").value,
          BranchNumber: selectedOptions.find(x => x.type === "BranchNumber").value,
        },
      }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.accountNumber'), type: 'AccountNumber', disabled: true },
          ...response.value.map(x => ({ label: `${x.AccountNumber} (${x.CurrencyCode})`, value: x.AccountNumber, type: 'AccountNumber', isLeaf: false }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }
    if (targetOption.type === "AccountNumber") {
      targetOption.loading = true;
      const filter = {
        filter: {
          'TenantBank/Bank/EftCode': selectedOptions.find(x => x.type === "BankCode").value,
          BranchNumber: selectedOptions.find(x => x.type === "BranchNumber").value,
          AccountNumber: selectedOptions.find(x => x.type === "AccountNumber").value
        },
      }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.suffix'), type: 'Suffix', disabled: true },
          ...response.value.map(x => ({ label: `${x.Suffix}`, value: x.Suffix, type: 'Suffix' }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }
  }

  render() {
    const { orderDetails } = this.props;
    const { totalAmount, totalCount, totalsLoading, orderSendingOrCreating, filter, options } = this.state;
    const _options = [{ value: Math.random(), label: i18n.t('lbl.bank'), type: 'BankCode', disabled: true }, ...options];
    const instructions = [
      {
        label: i18n.t('lbl.bank/branch/account'),
        key: "BankAccount",
        type: "cascade",
        options: _options,
        required: true,
        loadData: this.loadData,
      },
      { label: i18n.t('lbl.paymentDate'), key: 'PaymentDate', type: 'date', required: true },
      { label: i18n.t('lbl.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('lbl.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('lbl.description'), key: 'Description', type: 'input' },
      { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: (data) => this.orderOperation(data, 'createOrder'), disabled: (orderDetails.count === 0 || orderSendingOrCreating), access: 'TosOrder.Create' },
      { label: i18n.t('btn.sendOrder'), type: 'button', className: 'success', onClick: (data) => this.orderOperation(data, 'sendOrder'), disabled: (orderDetails.count === 0 || orderSendingOrCreating), access: 'TosOrder.Send' },
    ]
    const columns = [
      { render: (row) => <ActionButtons url="TosOrderDetail" editUrl item={row} hasDelete getDatas={this.callbackDelete} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.consumerAccountNumber'), key: 'AccountNumber' },
      { label: i18n.t('lbl.suffix'), key: 'Suffix' },
      { label: i18n.t('lbl.consumerTitle'), key: 'Title' },
      { label: i18n.t('lbl.bankName'), key: 'BankName', render: (data) => utils.tos.getBankName({ data }), checkField: false, },
      { label: i18n.t('lbl.iban'), key: 'IBAN' },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: ({ Amount, CurrencyCode }) => formatCurrency({ data: Amount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('lbl.description'), key: 'Description' },
    ];

    return (
      <div className="page-content" >
        <FadeIn>
          <CheckAccessRight {...{ ...orderDetails }}>
            <Instruction {...{ instructions, loading: totalsLoading || orderSendingOrCreating }} />
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              count={orderDetails.list.count}
              access="TosOrderDetail"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={orderDetails.list.data}
              loading={orderDetails.list.loading}
              title={i18n.t('route.tos.createPayment')}
              newButton={{ endpoint: "openDialog", title: 'addManualPaymentDetail' }}
              excel={{ module: "tos", url: "TosOrderDetail.Excel", filter: { filter: filter.filter } }}
              additionalButtons={this.additionalButtons()}
              getData={() => this.getOrderDetails(this.getInvoiceTotal)}
              Component={CreatePayment}
              dialogTitle={i18n.t('lbl.payment')}
              checkbox
              onCheck={this.getInvoiceTotal}
              checkAll={orderDetails.list.data && orderDetails.list.data.length > 0}
              deleteOptions={{ stateKey: "orderDetails", url: "TosOrderDetail" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };

};

const mapStateToProps = ({ tos, netekstre, common }) => ({
  orderDetails: tos.orderDetails,
  tenantBanks: tos.filter.tenantBanks,
  bankAccounts: netekstre.filter.bankAccounts,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getOrderDetails: (filter, callback) => dispatch(actions.getAll({ filter: { ...filter, filter: { ...filter.filter, ...persistentFilter } }, url: endpoints.tos.tosOrderDetail, key: 'orderDetails' }, callback)),
  getInvoiceTotal: (filter, callback) => dispatch(actions.getAll({ filter: { ...filter, filter: { ...filter.filter, ...persistentFilter } }, url: endpoints.tos.tosOrderDetail }, callback)),
  createOrder: (data, callback) => dispatch(actions.post({ url: endpoints.tos.createOrder, data, customNotification: true, options: { errors: { showNotification: false } } }, callback)),
  sendOrder: (data, callback) => dispatch(actions.post({ url: endpoints.tos.sendOrder, data, customNotification: true, options: { errors: { showNotification: false } } }, callback)),
  clearState: () => dispatch(actions.clearState("orderDetails"))
});
export default connect(mapStateToProps, mapDispatchToProps)(CreatePayments);