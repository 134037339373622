import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Form, Row, Col, Button } from 'antd';
import _ from 'lodash';
import { Input, Select, InputPhone } from 'components/UIComponents/NetbankaComponents';
import { CurrentAccountModel } from 'models';
import { isTcknValidator, isVknValidator } from 'helpers';
import actions from 'store/actions/common';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { currentAccountTypesLookup, currentAccountTypes, httpMethods } from 'lib/constants';
import i18n from 'plugins/i18n';
import utils from 'lib';

class CurrentAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new CurrentAccountModel(),
      ibans: [],
      iban: '',
      bankAccountNumber: '',
      showError: false,
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.currentAccounts.single.data && props.currentAccounts.single.saving === null && !state.data.id) {
      const data = new CurrentAccountModel({ ...props.currentAccounts.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.currentAccounts.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    const { endpoint, getCurrentAccount, tenantId } = this.props;
    const parameters = tenantId && { TenantId: tenantId };
    utils.common.getUsers({ filter: { isRepresentative: true } });
    if (this.id) getCurrentAccount(this.id, parameters, endpoint);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let _data = _.cloneDeep(data);
    this.setState({ showError: true }, () => {
      const { dialogCloseRequest, form, saveData, endpoint, tenantId } = this.props;
      form.validateFields(error => {
        if (!error && !this.customValidator() && ((data.currentAccountType === currentAccountTypes.Person && !this.tcknValidator()) || (data.currentAccountType === currentAccountTypes.Company))) {
          this.setState({ saving: true });

          const parameters = tenantId && { TenantId: tenantId }
          const method = this.id ? httpMethods.put : httpMethods.post;

          saveData(_data, method, endpoint, parameters, (response) => {
            this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
          });
        }
      });
    });
  };

  deleteIban = (index) => {
    let { data } = this.state;
    let _item = data.ibans[index];
    data.ibans.splice(data.ibans.indexOf(_item), 1)
    if (!this.state.showError) this.setState({ showError: true });
    this.setState({ data });
  };

  addIban = () => {
    let { data } = this.state;
    data.ibans.push({ iban: this.state.iban, currentAccountId: this.id || undefined });
    this.setState({ data, iban: '' });
  };

  deleteBankAccount = (index) => {
    let { data } = this.state;
    let _item = data.bankAccountNumbers[index];
    data.bankAccountNumbers.splice(data.bankAccountNumbers.indexOf(_item), 1)
    this.setState({ data });
  };

  addBankAccount = () => {
    let { data } = this.state;
    data.bankAccountNumbers.push({ bankAccountNumber: this.state.bankAccountNumber, currentAccountId: this.id || undefined });
    this.setState({ data, bankAccountNumber: '' });
  };

  customValidator = (rule = null, value = null, callback = null) => {
    let { taxNumber, tcknNumber, currentAccountType } = this.props.form.getFieldsValue();
    let isValid;
    isValid = (
      (this.state.data.ibans.length === 0) &&
      (this.state.data.bankAccountNumbers.length === 0) &&
      (currentAccountType === currentAccountTypes.Company ? !taxNumber : !tcknNumber)
    )
    callback && callback(this.state.showError && isValid);
    return this.state.showError && isValid
  }

  tcknValidator = (rule = null, value = null, callback) => {
    let { tcknNumber } = this.state.data;
    let isValid = tcknNumber.length === 11 && isTcknValidator(tcknNumber);
    callback && callback(!isValid);
    return !isValid;
  }

  vknValidator = (rule = null, value = null, callback) => {

    let { taxNumber } = this.state.data;
    let isValid = taxNumber.length === 10 && isVknValidator(taxNumber);
    callback && callback(!isValid);
    return !isValid;
  }

  renderDialogButtons = checkAccessRights => {
    let { currentAccounts, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={currentAccounts.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={currentAccounts.single.loading} form="currentAccount" htmlType="submit" className="success #save" icon="save" loading={currentAccounts.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      currentAccounts.single.saving,
      currentAccounts.single.loading
    )
  }

  render() {
    const { loading, data, bankAccountNumber, iban } = this.state;
    const { currentAccounts, dialogCloseRequest, users } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...currentAccounts, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="currentAccount">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('currentAccountType', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.currentAccountType
                    })(
                      <Select
                        className="#currentAccountType"
                        label={i18n.t('lbl.currentAccountType')}
                        options={currentAccountTypesLookup}
                        optVal="value"
                        optName={(row) => i18n.t(`lbl.${row.name}`)}
                        onChange={(e) => this.update('currentAccountType', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('code', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.code
                    })(
                      <Input className="#currentAccountCode" label={i18n.t('lbl.currentAccountCode')} onChange={(e) => this.update('code', e)} disabled={!!data.id} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('currentAccountName', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.title
                    })(
                      <Input className="#currentAccountName" label={i18n.t('lbl.currentAccountName')} onChange={(e) => this.update('title', e)} />
                    )}
                  </Form.Item>
                </Col>
                {data.currentAccountType === currentAccountTypes.Person &&
                  <Col xs={24} sm={12}>
                    <Form.Item
                      help={this.customValidator() ? i18n.t('msg.requiredfield') : this.tcknValidator() ? '* Geçersiz Format' : ''}
                      validateStatus={this.customValidator() || this.tcknValidator() ? 'error' : null}
                    >
                      {getFieldDecorator('tcknNumber', {
                        rules: [{ required: false, message: i18n.t('msg.required') },],
                        initialValue: data.tcknNumber
                      })(
                        <Input className="#tcknNumber" label={`* ${i18n.t('lbl.tc')}`} type="stringNumber" maxLength={11} onChange={(e) => this.update('tcknNumber', e)} />
                      )}
                    </Form.Item>
                  </Col>
                }
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#email" label={i18n.t('lbl.email')} value={data.email} onChange={(e) => this.update('email', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('companyTitle', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#companyTitle" label={i18n.t(`lbl.${data.currentAccountType === currentAccountTypes.Person ? 'nameSurname' : 'companyTitle'}`)} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <InputPhone className="#phone" label={i18n.t('lbl.phone')} value={data.phone} onChange={(e) => this.update('phone', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <InputPhone mobile className="#gsm" label={i18n.t('lbl.mobilePhone')} value={data.gsm} onChange={(e) => this.update('gsm', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#taxOffice" label={i18n.t('lbl.taxOffice')} value={data.taxOffice} onChange={(e) => this.update('taxOffice', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  {
                    data.currentAccountType === currentAccountTypes.Company ?
                      <Form.Item
                        help={this.customValidator() ? i18n.t('msg.requiredfield') : this.vknValidator() ? '* Geçersiz Format' : ''}
                        validateStatus={this.customValidator() || this.vknValidator() ? 'error' : null}
                      >
                        {getFieldDecorator('taxNumber', {
                          rules: [{ required: false, message: i18n.t('msg.required') }],
                          initialValue: data.taxNumber
                        })(
                          <Input className="#taxNumber" maxLength={10} label={`* ${i18n.t('lbl.taxNumber')}`} onChange={(e) => this.update('taxNumber', e)} />
                        )}
                      </Form.Item>
                      :
                      <Form.Item>
                        <Input className="#taxNumber" label={`${i18n.t('lbl.taxNumber')}`} value={data.taxNumber} onChange={(e) => this.update('taxNumber', e)} />
                      </Form.Item>
                  }
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#onlineJobNumber" label={i18n.t('lbl.onlineJobNumber')} value={data.onlineJobNumber} onChange={(e) => this.update('onlineJobNumber', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                  <Form.Item>
                    {getFieldDecorator('customerRepresentatives', {
                      initialValue: data.customerRepresentatives
                    })(
                      <Select
                        className="#customerRepresentatives"
                        label={i18n.t('lbl.customerRepresentatives')}
                        options={users.data}
                        optVal="id"
                        optName={(data) => `${data.name} ${data.surname}`}
                        onChange={(e) => this.update('customerRepresentatives', e)}
                        mode="multiple"
                        maxTagCount={3}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20} className="mb-20">
                <Col xs={24} sm={12}>
                  <Card>
                    <table className="full-width">
                      <tbody>
                        <tr>
                          <td>
                            <Form.Item help={this.customValidator() ? i18n.t('msg.requiredfield') : ''} validateStatus={this.customValidator() ? 'error' : null}>
                              <Input className="#iban" label={`* ${i18n.t('lbl.iban')}`} value={iban} type="iban" onChange={(e) => this.setState({ iban: e })} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item>
                              <Button className={`${iban === "" ? 'disabled' : ''} #addIban`} icon="plus" disabled={iban === ""} onClick={this.addIban} />
                            </Form.Item>
                          </td>
                        </tr>
                        {data.ibans.map((x, i) => (
                          <tr key={i}>
                            <td>{x.iban}</td>
                            <td><Button className="#deleteIban" size="small" icon="delete" onClick={() => this.deleteIban(i)} /></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card>
                </Col>
                <Col xs={24} sm={12}>
                  <Card>
                    <table className="full-width">
                      <tbody>
                        <tr>
                          <td>
                            <Form.Item help={this.customValidator() ? i18n.t('msg.requiredfield') : ''} validateStatus={this.customValidator() ? 'error' : null}>
                              <Input className="#bankAccount" label={`* ${i18n.t('lbl.bankAccountNumber')}`} value={bankAccountNumber} onChange={(e) => this.setState({ bankAccountNumber: e })} />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item>
                              <Button className={`${bankAccountNumber === "" ? 'disabled' : ''} #addBankAccount`} icon="plus" disabled={bankAccountNumber === ""} onClick={this.addBankAccount} />
                            </Form.Item>
                          </td>
                        </tr>
                        {
                          data.bankAccountNumbers.map((x, i) => (
                            <tr key={i}>
                              <td>{x.bankAccountNumber}</td>
                              <td><Button className="#deleteBankAccount" size="small" icon="delete" onClick={() => this.deleteBankAccount(i)} /></td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  currentAccounts: common.currentAccounts,
  users: common.filter.users,
});
const mapDispatchToProps = (dispatch) => ({
  getCurrentAccount: (id, parameters, endpoint) => dispatch(actions.get({ url: endpoint || endpoints.tenant.currentAccounts, parameters, key: 'currentAccounts', id })),
  saveData: (data, method, endpoint, parameters, callback) => dispatch(actions[method]({ url: endpoint || endpoints.tenant.currentAccounts, parameters, key: 'currentAccounts', data }, callback)),
  clearState: () => dispatch(actions.clearState("currentAccounts"))
});
const CurrentAccountForm = Form.create()(CurrentAccount);
export default connect(mapStateToProps, mapDispatchToProps)(CurrentAccountForm);