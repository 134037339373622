import React, { useState, useEffect } from 'react'
import { KrediContent, FormElements, Installments } from 'components/UIComponents';
import { Form, Row, Col, Button } from 'antd';
import i18n from 'plugins/i18n';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import actions from 'store/redux/netekstre';
import { useParams, withRouter } from 'react-router-dom';
import { httpMethods } from 'lib/constants';

const ApplicationContinuation = ({ form, banks, getBanks, getActiveTransaction, transactionBankUpdate, history }) => {
  const { id } = useParams();
  const { getFieldDecorator } = form;
  const [saving, setSaving] = useState(false)
  const [data, updateData] = useState({ amount: null, saltTransactionId: id, bankId: null })

  useEffect(() => {
    getActiveTransaction(id, (response) => updateData(response));
    getBanks(id);
  }, [getActiveTransaction, id, getBanks])

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      setSaving(true);
      if (!error) {
        const _data = { ...data, saltTransactionId: id}
        transactionBankUpdate(_data, ({ errors }) => {
          if (!(errors && errors.length > 0)) {
            history.push(`/kredi/application-continuation-2/${id}`)
          }
          setSaving(false)
        })
      }
    })
  }



  return (
    <KrediContent>
      <Form onSubmit={onSubmit}>
        <Row gutter={44}>
          <Col xs={24} md={12}>
            <Form.Item>
              <FormElements.Input
                label={i18n.t("lbl.amount")}
                prefix="tl"
                value={data.amount}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {
                getFieldDecorator('cityId', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: null,
                })(
                  <FormElements.Select
                    label={i18n.t('lbl.bankSelect')}
                    options={banks.data}
                    optName="name"
                    optVal="id"
                    prefix="banks"
                    loading={false}
                    onChange={(bankId) => updateData((data) => ({ ...data, bankId }))}
                  />
                )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={44} className="mt-30">
          <Col xs={24} md={9}>
            <Installments.InstallmentPlan {...{ products: data.products, amount: data.amount, bank: data.bankId }} />
          </Col>
        </Row>

        <Row gutter={44} className="mt-30 mobile-text-center">
          <Col xs={24} md={9}>
            <Button type="primary" htmlType="submit" size="large" loading={saving}>
              {i18n.t('btn.continue')}
            </Button>
          </Col>
        </Row>
      </Form>
    </KrediContent>
  )
}
const mapStateToProps = ({ global }) => ({
  banks: global.filter.krediBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getActiveTransaction: (id, callback) => dispatch(actions.globalRequest({ url: endpoints.kredi.getActiveTransaction, id }, httpMethods.get, callback)),
  getBanks: (id) => dispatch(actions.globalRequest({ url: endpoints.kredi.getBanks, key: 'filter.krediBanks', id })),
  transactionBankUpdate: (data, callback) => dispatch(actions.globalRequest({ url: endpoints.kredi.transactionBankUpdate, data }, httpMethods.post, callback))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ApplicationContinuation)));