import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { TosParameterModel } from 'models';
import { serviceTypes } from 'helpers/tos';
import actions from 'store/actions/tos';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new TosParameterModel(),
      InitialState: new TosParameterModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.parameters.single.data && !state.data.Id) {
      const data = new TosParameterModel({ ...props.parameters.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.parameters.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getParameters(this.id);
    else this.setState({ loading: false });
    utils.common.getTenantBanks();
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };


  // save = (e) => {
  //   e.preventDefault();
  //   this.props.form.validateFields(error => {
  //     if (!error) {
  //       const method = this.Id ? httpMethods.put : httpMethods.post;
  //       this.props.saveData(this.state.data, method, (response) => {
  //         response ? this.props.history.push('/dashboard/tos/parameters') : this.setState({ saving: false });
  //       });
  //     }
  //   });
  // };

  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData } = this.props;
    const data = _.cloneDeep(this.state.data);
    data.Port = parseInt(data.Port);
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { parameters, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={parameters.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={parameters.single.loading} form="parameter" htmlType="submit" className="success #save" icon="save" loading={parameters.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      parameters.single.saving,
      parameters.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { tenantBanksTos, tenantBanksCommon, parameters, dialogCloseRequest, id } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    const tenantBanks = _.intersectionWith(tenantBanksTos.data, tenantBanksCommon.data, ({ Code }, { bankEftCode }) => bankEftCode === Code);

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...parameters, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="parameter">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bankName')}
                        options={tenantBanks}
                        optVal="Code"
                        disabled={id}
                        optName={(bankData) => utils.tos.getBankName({ bankData })}
                        onChange={(e) => this.update('BankCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ServiceTypeId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ServiceTypeId
                    })(
                      <Select
                        className="#type"
                        label={i18n.t('tos.serviceTypes')}
                        options={serviceTypes}
                        optVal="id"
                        optName="name"
                        allowClear={false}
                        onChange={(e) => this.update('ServiceTypeId', e)}
                        lang="tos"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('TOSCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.TOSCode
                    })(
                      <Input className="#TOSCode" label={i18n.t('tos.companyCode')} onChange={(e) => this.update('TOSCode', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('TOSCode2', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.TOSCode2
                    })(
                      <Input className="#TOSCode2" label={i18n.t('tos.companyTitle')} onChange={(e) => this.update('TOSCode2', e)} />
                    )}
                  </Form.Item>
                </Col>
                {
                  data.ServiceTypeId &&
                  <>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('UserName', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.UserName
                        })(
                          <Input className="#UserName" label={i18n.t('tos.userName')} onChange={(e) => this.update('UserName', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('Password', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Password
                        })(
                          <Input type="password" className="#Password" label={i18n.t('tos.password')} onChange={(e) => this.update('Password', e)} />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                }
              </Row>
              <Row gutter={20}>
                {
                  data.ServiceTypeId && serviceTypes.find(x => x.id === data.ServiceTypeId).name.includes('ftp') &&
                  <>
                    <Col xs={18} sm={9}>
                      <Form.Item>
                        {getFieldDecorator('Host', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Host
                        })(
                          <Input className="#Host" label={i18n.t('tos.hostName')} onChange={(e) => this.update('Host', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={6} sm={3}>
                      <Form.Item>
                        {getFieldDecorator('Port', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Port
                        })(
                          <Input className="#Port" maxLength={5} label={i18n.t('tos.portName')} onChange={(e) => this.update('Port', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('UploadFolder', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.UploadFolder
                        })(
                          <Input className="#UploadFolder" label={i18n.t('tos.upFolder')} onChange={(e) => this.update('UploadFolder', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('DownloadFolder', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.DownloadFolder
                        })(
                          <Input className="#DownloadFolder" label={i18n.t('tos.downFolder')} onChange={(e) => this.update('DownloadFolder', e)} />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                }
                {
                  data.ServiceTypeId && serviceTypes.find(x => x.id === data.ServiceTypeId).name === "ws" &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      {getFieldDecorator('Url', {
                        rules: [{ required: true, message: i18n.t('msg.required') }],
                        initialValue: data.Url
                      })(
                        <Input className="#Url" label={i18n.t('tos.url')} onChange={(e) => this.update('Url', e)} />
                      )}
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ tos, common }) => ({
  parameters: tos.parameters,
  tenantBanksTos: tos.filter.tenantBanks,
  tenantBanksCommon: common.filter.tenantBanks,
  currencies: tos.filter.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (id, callback) => dispatch(actions.get({ url: endpoints.tos.tosAccount, key: 'parameters', id }, callback)),
  getCurrencies: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.tos.currencies, key: 'currencies' })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tos.tosAccount, key: 'parameters', data }, callback)),
  clearState: () => dispatch(actions.clearState("parameters"))
});
const ParameterForm = Form.create()(Parameter);
export default connect(mapStateToProps, mapDispatchToProps)(ParameterForm);