import devMessages from "./devMessages";

export default {
  translation: {
    msg: {
      sendEmailLimitError: "Mail gönderilemiyor",
      sendEmailLimitErrorContent: "Mail gönderilebilecek veri sayısı {{config}}. Maksimum limiti geçtiğiniz için mail gönderilemiyor. Uygun sayıda veri seçerek yeniden deneyeniz.",
      downloadLimitError: "İndirilemiyor",
      downloadLimitErrorContent: "İndirilebilecek veri sayısı {{config}}. Maksimum limiti geçtiğiniz için indirme gerçekleştirilemiyor. Uygun sayıda veri seçerek yeniden deneyeniz.",
      filesSent: 'Dosya{{pluralSuffix}} gönderildi',
      filesDownloaded: 'Dosya{{pluralSuffix}} indirildi',
      bankNotSharingThisData: 'Banka bu bilgiyi paylaşmamaktadır',
      requiredCategory: 'Lütfen en az bir hareket kategorisi seçiniz',
      requiredRoleOrUserOrNotify: "Kullanıcı, Rol ya da 'Cari hesabı bilgilendir' seçimi zorunludur",
      timeoutSmsCodeWarning: 'SMS doğrulama kodunuzun süresi dolmuştur, oturum açma ekranına yönlendirileceksiniz.',
      multiPersonEmailLimitContent: "En fazla {{config}} farklı kişiye mail gönderebilirsiniz.",
      cancelOrRefundSuccess: '{{type}} işlemi başarıyla gerçekleşmiştir',
      cancelOrRefundError: '{{type}} işlemi sırasında hata oluştu',
      cancelOrRefundWarning: '{{type}} işlemi beklemeye alınmıştır',
      anErrorOccurredOn3D: '3D ödeme esnasında bekleyenmeyen bir hata oluştu',
      transactionSuccessfullyCompleted: 'İşleminiz başarıyla gerçekleşi',
      transactionOnHold: 'İşleminiz beklemeye alınmıştır',
      anErrorOccurredInTransaction: 'İşleminizde beklenmeyen bir hata meydana geldi',
      transactionStatusUnknown: 'İşleminiz beklenmeyen şekilde sonuçlandı',
      requiredInstallmentSelection: 'Bir taksit seçimi yapmalısınız',
      cvcHelpMessage: 'Lütfen kartınızın arka yüzünde bulunan 3 haneli kodu giriniz',
      fillCardAndAmountFields: 'Tutar ve kredi kartı bilgilerinizi doldurunuz',
      defaultPosDataSuccessfullyUpdated: 'Varsayılan Pos bilgileri başarıyla kaydedildi',
      paymentLinkSuccessfullySent: 'Ödeme bağlantısı başarı ile gönderilmiştir',
      successful: 'Başarılı',
      successfullySavedPaymentParameters: 'Ödeme Parametreleri başarı ile kaydedildi',
      paymentCancelWarning: 'Aşağıdaki bilgileri ait ödeme linki iptal edilecektir. Onaylıyor musunuz?',
      onlyRequestSentUsable: "Yalnızca 'İstek Gönderildi' durumundaki ödemelerde kullanılabilir",
      noLicencePackageSelect: 'Paket seçimi yapılmadı',
      notFoundModuleLicencePackages: 'Pakete ait modül bilgileri bulunamadı',
      removedModuleName: '<b>{{removedModuleNames}}</b> modülüne ait tanım bilgileri kaldırıldı.',
      removedModuleNames: '<b>{{removedModuleNames}}</b> modüllerine ait tanım bilgileri kaldırıldı.',
      mustBeCheckUsingMonthlyOrYearly: "Fiyatlandırma yapabilmek için aylık ya da yıllık kullanılabilir özelliklerinden en az bir tanesini işaretlemelisiniz",
      noModuleSelection: 'Henüz herhangi bir modül seçimi yapılmadı',
      noPackageDefinitionForModule: 'Bu modül için paket tanım detayı bulunmuyor.',
      unusableCuzCanceled: 'İptal edilen ödemelerde işlem yapılamaz',
      onlySuccessfulTransactionUsable: 'Yalnızca başarılı ödemelerde işlem yapılabilir',
      notFoundPosLogos: 'Posa ait logolar bulunamadı.',
      selectPosFirst: 'Logoları görüntüleyebilmek için banka seçimi yapmalısınız',
      noPosParameters: 'Bu posa ait parametre bulunamadı',
      thereIsNoChange: 'Hiçbir değişiklik yapmadınız',
      pleaseFillRequiredFields: 'Zorunlu alanları doldurunuz',
      noUsersYet: 'Henüz hiçbir kullanıcı eklenmemiş.',
      accessRightsNotFound: 'Yetki bilgileri bulunamadı, bağlı olduğunuz firma için yetkiler tanımlanmamış olabilir.',
      moduleInfoNotFound: 'Ürün bilgisi bulunamadı, firma bilgilerinde hiçbir uygulama seçilmemiş olabilir.',
      activationMailSent: 'Kurulum maili gönderildi',
      resendActivationMail: 'Kurulum mailini tekrar gönder',
      saveSuccess: 'Başarıyla kaydedildi',
      areYouSureToDelete: 'Silmek istediğinize emin misiniz?',
      documentUploadError: 'Evrak yüklenirken bir hata oluştu',
      deleteConfirm: 'Kalıcı olarak silinecektir, onaylıyor musunuz?',
      whileIfUncheckedCalculatedRulePoint: 'İşaretlenmezse kural puanı otomatik olarak hesaplanacaktır.',
      noAccessRightChart: 'Grafiği Görüntüleme Yetkiniz Yoktur',
      pleaseClickContinueButton: 'Lütfen devam et butonuna tıklayarak yeni şifrenizi oluşturunuz',
      addBankWithTypeCode1: 'Tip Kodu 1 koşulu ile birlikte Banka koşulu da eklenmesi gerekmektedir.',
      addTypeCode1WithBank: 'Banka koşulu ile birlikte Tip Kodu 1 koşulu da eklenmesi gerekmektedir.',
      tenantCategoryUpdateWarning: 'Yapılan değişiklikten dolayı, kullanılan ortak kategori ve ortak kurallar kullanım dışı kalacaktır ve yerine özel kategori açılacaktır. Devam etmek istiyor musunuz?',
      needToConfirmation: 'Onaylamanız Gerekiyor!',
      deleteCategoryWarning: 'Silmek istediğiniz kategorinin yerine <b>Diğer</b> kategorisi atanacaktır, onaylıyor musunuz ?',
      customRuleIsEqualToGeneralRule: 'Ortak kuraldan türetilmek istenen özel kural bilgileri orijinali ile birebir aynı olamaz.',
      ruleEnabledOrDisabledDescription: 'Seçilen kuralların aktif/pasiflik durumları var olanın tersi olacak şekilde güncellenecektir, onaylıyor musunuz?',
      ruleIsUpdated: 'Kural Değiştirildi!',
      updateWarning: 'Kuralda değişiklikler yaptınız. Lütfen mevcut kuralı güncellemek ya da yaptığınız değişikliklerle yeni bir kural oluşturulması arasında bir seçim yapınız.',
      noAccessRightData: 'Yetkiniz Yoktur',
      thereAreNoNotifications: "Görüntülenecek bildiriminiz bulunmamaktadır.",
      noNotification: "Görüntülenecek yeni bildirimiz bulunmamaktadır.",
      multipleTimesNotificationWarning: "Bu bildirimi {{count}}. kez görüyorsunuz",
      dataFetchError: 'Bilgilere erişilemedi, daha sonra tekrar deneyebilirsiniz.',
      maxRule: 'En Fazla 5 Kural Seçebilirsiniz',
      dataCreatedSuccessful: 'data başarıyla oluşturuldu.',
      bankDeleteRuleItemInfo: 'Banka koşulunu kaldırdığınız için bankaya bağlı tip kodu 1, tip kodu 2, banka hesabı ve ilgili kişi koşulları da kaldırıldı.',
      confirmUpdateThisField: '{{field}} alanını güncellemek için onaylamanız gerekiyor.',
      isExistSuccessfullySent: 'İşlem başarılı, girdiğiniz mail adresi sistemde kayıtlı ise şifre yenileme maili gönderilecektir.',
      screenSaverMode: 'Ekran Koruma Modu',
      displaysLast30DaysData: "Başlangıç Tarihi'nden itibaren geriye dönük son 30 günün verilerini raporlar.",
      displaysLast12MonthsData: "Başlangıç Tarihi'nden itibaren geriye dönük son 12 ayın verilerini raporlar.",
      displaysLast5YearsData: "Başlangıç Tarihi'nden itibaren geriye dönük son 5 yılın verilerini raporlar.",
      passwordResetConfirm: "Hesap şifresi sıfırlanacaktır. Emin misiniz?",
      passwordResetSuccess: "Hesap şifresi sıfırlandı.",
      passwordUpdated: "Şifre başarıyla değiştirildi.",
      noAccessRightDashboard: "Dashboard'u görüntüleme yetkiniz yoktur.",
      excelReady: 'Excel dosyanız hazırlanmıştır. İndirmek için tıklayınız.',
      excelEmpty: 'Veri olmadığı için excel dosyanız üretilememiştir.',
      preparingExcelFile: 'Excel dosyası hazırlanıyor...',
      unchangeable: 'Değiştirilemez',
      noData: 'Veri Yok',
      noTransactionDetails: 'Listelenecek hareket detayı bulunamadı.',
      amountEqualTotal: 'Toplam tutar asıl hareketin tutarına eşit olmalıdır.',
      isSingleAndEqualTotal: 'Asıl hareketin tutarına eşit tek bir alt hareket ile bölüştürme yapılamaz.',
      isTransferred: 'Bu hareketlerden en az biri aktarıldığı için bölme işlemleri gerçekleştirilemiyor.',
      leMin: 'En düşük tutarın altında değer girilmemelidir',
      geMax: 'En yüksek tutarın üstünde değer girilmemelidir',
      signErrorNull: 'Değer yalnızca -/+ olmamalıdır. ',
      signError: '-/+ kullanılmamalıdır.',
      notEndDotOrComma: 'Değer nokta ya da virgül ile bitmemelidir',
      noAcceptZero: 'Değer sıfır olmamalıdır',
      invalidIBAN: 'Lütfen geçerli bir IBAN giriniz',
      missing: 'Eksik',
      much: 'Fazla',
      noBankRule: "Lütfen önce banka koşulu giriniz.",
      noBank: "Lütfen banka koşulu ekleyiniz.",
      noTypeCode1: "Lütfen tip kodu 1 koşulu ekleyiniz.",
      noRuleItem: "Lütfen koşul için gerekli bütün alanları doldurunuz!",
      noRuleItems: "Lütfen kural tanımlamak için en az bir koşul giriniz!",
      reqLength: 'Bu alan {{type}} {{length}} karakter olmalıdır.',
      tokenNotFound: 'Token Bulunamadı',
      noAccessRightManagementPanel: 'Yönetim paneline erişim yetkiniz yoktur.',
      noAccessRight: 'Görüntüleme yetkiniz yoktur.',
      requiredfield: "Yıldızlı alanlardan en az biri zorunludur.",
      cannotBeLessThan0: 'Değer sıfırdan küçük olamaz',
      removingOldPassword: 'Devam ederseniz önceki kullandığınız şifre kaybedilecektir.',
      passwordTooltip: 'Bu alanda güncelleme yapılmaz ise var olan değer kullanılacaktır.',
      addAtLeastOne: 'Lütfen en az bir kayıt ekleyin',
      cannotDeleteLastOne: 'En az bir kayıt kalmak zorunda olduğundan bu kayıt silinemez.',
      pleaseFillOther: 'Lütfen önce diğer kayıtları doldurunuz',
      screenLeaveTitle: "Ekrandan ayrılmak istiyor musunuz?",
      screenLeaveMessage: "Ekrandan ayrılırsanız yaptığınız değişiklikler iptal edilecektir. Onaylıyor musunuz?",
      putSuccess: 'Başarıyla güncellendi',
      updateSuccess: 'Başarıyla güncellendi',
      addSuccess: "Başarılıyla kaydedildi",
      patchSuccess: "Başarıyla güncellendi",
      resentCodeSuccessfully: 'Kodunuz başarı ile gönderilmiştir.',
      resent: 'Kod tekrar gönderildi',
      timedOut: 'Süre doldu',
      resentCode: 'Doğrulama kodunun yeniden gönderilmesini ister misiniz?',
      invalidFormat: 'Geçersiz Format',
      successfullySent: 'Başarıyla gönderildi',
      success: 'Başarılı',
      anErrorOccurred: 'Bir hata oluştu',
      sorryAnErrorOccurred: 'Üzgünüz, bir hata oluştu',
      setPasswordFailed: 'Şifre Belirlenemedi',
      passwordRequired: 'Şifre gerekiyor',
      passwordMatchError: 'Şifreler eşleşmiyor',
      invalidEmail: 'Lütfen geçerli bir mail adresi giriniz',
      validationRequired: 'Doğrulama Gerekiyor',
      unknownError: 'Bilinmeyen hata',
      wrongNameOrPassword: 'Kullanıcı adı ya da şifre hatalı',
      loginFailed: 'Oturum Açılamadı',
      required: 'Bu alan zorunludur',
      emailInvalid: 'Geçersiz email',
      excelResponseStatus: "Excel'den aktarma durumu",
      errorRefreshTokenForAnadolubank: 'Token yenilenirken bir hata oluştu.',
      bankReturnedCode: 'Anadolubank tarafında gerçekleştirmek istediğiniz işlem sonuçlandıralamadı. Banka şu hata kodunu gönderdi: ',
      refreshTokenSuccess: 'Token yenileme işlemi başarılı',
      refreshTokenSuccessDesc: 'Anadolubank parametrelerinde token başarıyla yenilenmiştir.',
      redirectWarning: 'Başka bir siteye yönleniyorsunuz...',
      redirectWarningMessage: 'Bu işleme devam edebilmek için bankanın kendi servisine yönlendirileceksiniz, devam etmek istiyor musunuz?',
      error: 'Hata',
      removedUserBlockSuccessfully: 'Bloke kaldırıldı, mevcut şifreniz ile giriş yapabilirsiniz.',
      passwordRight: 'Kalan şifre deneme hakkınız: ',
      UserBlocked: 'Kullanıcı engellendi',
      transactionTransferConfirmMessage: "Aktarma işlemi esnasında filtrede değişiklik olduğu için <b>'Tümünü Seç'</b> seçiminiz kaldırılacaktır, onaylıyor musunuz?",
      createPayrollResponse: 'Belirlediğiniz şartlara uygun olan çekleriniz için talimat hazırlanmıştır.',
      excelExportLimitError: "Excel'e aktarılamıyor",
      excelExportLimitErrorContent: "Excel'e aktarılabilecek veri sayısı {{config}}. Maksimum limiti geçtiğiniz için aktarım yapılamıyor. Filtreleri arttırıp veri sayısını düşürerek yeniden deneyeniz.",
      sorrySomeErrorsOccurred: 'Üzgünüz, bazı hatalar oluştu',

      //BE Messages
      Found: 'Silinmek istenen bankaya ait banka hesapları mevcut',
      MaximumOtpTryOverflow: 'Çok fazla hatalı güvenlik kodu girdiniz. Lütfen daha sonra tekrar deneyiniz.',
      BadRequest: 'Hatalı istek',
      TooManyRequest: 'İstek Limiti Aşıldı',
      NotFound: 'Kayıt bulunamadı',
      SecurityCodeNotFound: "Hatalı güvenlik kodu. Lütfen size gönderilen güvenlik kodunu giriniz.",
      TwoFactorSendSecurityCode: "Güvenlik kodu gönderilirken hata oluştu.",
      InvalidResetPasswordCode: 'Hatalı şifre sıfırlama kodu',
      SendResetPasswordCodeError: "Şifre sıfırlama gönderilirken hata oluştu",
      InvalidOtp: 'Şifre hatalı. Lütfen size gönderilen şifreyi giriniz. Kalan deneme hakkınız: {{data}}',
      InvalidTckn: 'Kimlik bilgileriniz doğrulanamadı',
      AlreadyHasOtp: "Zaten bir OTP'niz var. Daha sonra tekrar deneyiniz.",
      InternalServerError: "Bilinmeyen bir sunucu hatası oluştu.",
      InvalidEmail: "Geçersiz bir email adresi girdiniz",
      MemberAlreadyExist: "Bu email adresine kayıtlı başka bir üye var",
      InvalidPhone: "Geçersiz bir telefon numarası girdiniz",
      RePasswordIsNotEqualToPassword: "Girdiğiniz şifreler uyuşmuyor",
      MemberNotFound: "Üye bulunamadı",
      UserNotFound: "Kullanıcı bulunamadı",
      TenantNotFound: "Firma Bulunamadı",
      InvalidSessionTime: "Oturum süresi doldu. Bu süre 10 ile 180 dakika arasında olmalıdır",
      WrongUsernameOrPassword: "Kullanıcı adı veya şifreyi yanlış girdiniz",
      BankAccountNotFound: "Girdiğiniz bilgilere ait banka hesabı bulunamadı",
      ApproveRequiredAgreements: "Lütfen zorunlu olan sözleşmeleri onaylayınız",
      AgreementApproveError: "Sözleşmeler onaylanırken bir hata oluştu",
      LoginError: "Kullanıcı adı veya şifre hatalı",
      InvalidCaptcha: "Geçersiz doğrulama kodu",
      NotAcceptable: "Model geçersiz",
      WrongPasswordGuid: "Şifreniz zaten güncellenmiş olabilir. Şifrenizi yine de güncellemek isterseniz lüften sistem yöneticiniz ile iletişime geçiniz.",
      OldPasswordUsed: 'Daha önce kullandığınız son 3 şifre dışında yeni bir şifre ile tekrar deneyiniz.',
      MustToBeInPassword: 'Şifre gereksinimleri karşılanmadı.',
      AlreadyUseIdentity: 'Zaten bu kimlik bilgisine sahipsiniz.',
      IdentityUseByAnotherMember: 'Bu kimlik başka bir üye tarafından kullanılıyor.',
      TwoFactorPhoneNumberNotFound: 'Güvenlik kodu gönderilecek telefon numarası bulunamadı.',
      TwoFactorVerificationError: 'Hatalı işlem. Lütfen daha sonra tekrar deneyiniz.',
      TenantApiServiceError: 'Firma parametreleri okunurken hata oluştu.',
      ErrorSendingOtp: 'OTP gönderilirken hata oluştu.',
      ActivationCodeNotFound: 'Aktivasyon kodu bulunamadı.',
      TokenNotFound: 'Token bulunamadı.',
      ActivationCodeSendingError: 'Aktivasyon kodu gönderilirken hata oluştu.',
      OtpNotFound: 'OTP bulunamadı.',
      MissingAuthorizationHeader: 'Lütfen kimlik doğrulama için, isteğin başlığından (Authorization) token bilginizi gönderin.',
      BankNotFound: 'Banka bulunamadı.',
      BlockedUser: '{{trialCount}} kez hatalı kullanıcı adı/şifre ile oturum açma talebinde bulundunuz. {{minute}} dakika sonra yeniden deneyiniz.',
      IncorrectCountOfLogins: 'Hatalı kullanıcı bilgileri ile oturum açma isteği. Kalan deneme hakkınız: {{data}}',
      SendInformBlockedUser: 'Kullanıcıya hesabının donduruldu bilgisi iletildi',
      PasswordExpired: 'Şifrenizin kullanım süresi dolmuştur.',
      InvalidInputFormat: 'Hesap aktif etme bağlantısı geçersiz ya da kullanıma açık değildir.',
      NotFoundActivePos: 'Aktif posunuz bulunmadığı için işlem gerçekleştirilemeyecektir.'
    },
    btn: {
      sendPaymentLink: 'Ödeme Linki Gönder',
      impersonatedPayment: 'Yerine Ödeme',
      goToPayPage: 'Ödeme Sayfasına Git',
      pay: 'Ödeme Yap',
      seeAllInstalmentOptions: 'Tüm taksit seçeneklerini gör',
      updateQrCode: 'Kare Kodu Güncelle',
      createQrCode: 'Kare Kod Oluştur',
      sendToAnotherUser: 'Başka Kullanıcıya Gönder',
      downloadPDFFiles: 'PDF Dosyalarını İndir',
      paymentDetail: 'Ödeme Detayı',
      transactionDetail: 'İşlem Detay',
      print: 'Yazdır',
      cancellation: 'İptal',
      refund: 'İade',
      paymentInfo: 'Ödeme Bilgileri',
      commissionRates: 'Komisyon oranları',
      saveData: 'Verileri kaydet',
      saveNext: "Kaydet ve İlerle",
      uploadDocument: "Evrak yükle",
      downloadSampleDocument: 'Örnek evrak indir',
      addManualPaymentDetail: 'Manuel Ödeme Kaydı Ekle',
      reproductionCustomRule: 'Özel Kural Türet',
      deactivate: 'Pasifleştir',
      activate: 'Aktifleştir',
      enabledOrDisabled: 'Aktif/Pasif',
      update: 'Güncelle',
      newRule: 'Yeni Kural',
      seeAll: "Tümünü Gör",
      clickToBuy: "Satın almak için tıklayınız",
      sent: "Gönderildi",
      bulkEdit: "Toplu Düzenle",
      includedInternalTransfer: 'Virman İşlemleri',
      addUser: 'Yeni Kullanıcı Ekle',
      resetPassword: 'Şifre Değiştir',
      all: 'Tümünü Seç',
      thisPage: 'Bu Sayfayı Seç',
      download: 'İndir',
      transferAndContinue: 'Aktar ve Devam Et',
      approveAndContinue: 'Onayla ve Devam Et',
      startCreateOrder: 'Talimat Hazırla',
      stopCreateOrder: 'Talimat Hazırlamaktan Vazgeç',
      addInvoice: 'Fatura Ekle',
      sendOrder: 'Gönder',
      createOrder: 'Oluştur',
      admin: 'Yönetim Paneli',
      addSubTransaction: 'Alt Kayıt Ekle',
      startTransfer: 'Aktarımı Başlat',
      cancelTransfer: 'Aktarımdan Vazgeç',
      sync: 'Aktar',
      startApprove: 'Onaylamayı Başlat',
      cancelApprove: 'Onaylamayı Bitir',
      confirmation: 'Onay',
      confirm: 'Onayla',
      refresh: 'Yenile',
      copy: 'Kopyala',
      synchronize: 'Eşitle',
      cancelSync: 'Eşitlemekten Vazgeç',
      cancelSyncRule: 'Kural Eşitlemekten Vazgeç',
      syncRule: 'Kural Eşitle',
      startSyncRule: 'Kural Eşitlemeyi Başlat',
      profile: 'Profil',
      changePassword: 'Şifremi Değiştir',
      more: 'Daha Fazla',
      goBack: 'Geri Dön',
      close: 'Kapat',
      next: "İleri",
      back: "Geri",
      save: 'Kaydet',
      accountTransactions: 'Hesaba ait hareketleri görüntüle',
      allAssets: 'Tüm Varlıklarım',
      yes: "Evet",
      no: "Hayır",
      createTransactionUrl: 'Başvuru linki oluştur',
      detail: 'Detay',
      cancel: 'Vazgeç',
      continue: 'Devam Et',
      continueWithoutLogin: 'Üyeliksiz devam et',
      goToSignIn: 'Giriş Sayfasına Dön',
      setPasswordFailed: 'Şifre Belirlenemedi',
      setPasswordSuccess: 'Şifre Belirlendi',
      settingPassword: 'Şifre Belirleniyor',
      setPassword: 'Şifre Belirle',
      send: 'Gönder',
      ok: 'Tamam',
      delete: 'Sil',
      edit: 'Güncelle',
      newRecord: 'Yeni Kayıt Ekle',
      excelExport: "Excel'e Aktar",
      excelImport: "Excel'den Aktar",
      list: 'Listele',
      actions: 'İşlemler',
      clear: 'Temizle',
      en: 'English',
      tr: 'Türkçe',
      returnHome: 'Ana Ekrana Dön',
      signIn: 'Giriş Yap',
      allFirm: 'Tüm Firmalar',
      cumulative: 'Kümülatif',
      logout: 'Çıkış Yap',
      downloadErrorsExcel: "Hata Excelini İndir",
      getRefreshToken: "Token'ı yenile",
      youAreRedirecting: "Yönlendiriliyorsunuz...",
      returnToSignIn: 'Giriş sayfasına dön',
      resend: 'Tekrar gönder',
      startCreatePayroll: 'Talimat Hazırla',
      stopCreatePayroll: 'Talimat Hazırlamaktan Vazgeç',
    },
    lbl: {
      refund: 'İade',
      clickTheThreeDots: 'Detay için üç nokta (...) üzerine tıklayınız.',
      onUs: 'OnUs',
      notOnUs: 'NotOnUs',
      format: 'Format',
      yes: "Evet",
      no: "Hayır",
      blockedDays: 'Bloke Gün Sayısı {{add}}',
      commitDate: 'Gün Sonu Tarihi {{add}}',
      authCode: 'Slip No',
      businessCard: 'Is Business',
      addCheque: 'Çek Ekle',
      startCreatePayroll: 'Talimat Hazırla',
      stopCreatePayroll: 'Talimat Hazırlamaktan Vazgeç',
      customersFor: '{{tenant}} firmasının Müşterileri',
      countryCode: 'Ülke Kodu',
      passportNo: 'Pasaport Numarası',
      userCode: 'Üye Kodu',
      foreignNational: 'Yabancı Uyruk',
      customer: 'Müşteri',
      member: 'Üye',
      membershipDate: 'Üyelik Tarihi {{add}}',
      installmentTable: 'Taksit Tablosu',
      iWannaPayWith3DSecure: '3D Secure ile ödeme yapmak istiyorum',
      singlePayment: 'Tek Çekim',
      agreementAndKVKKText: 'Sözleşme ve KVKK Metni',
      agreementAndKVKKCheckboxLabel: 'Sözleşme ve KVKK Metni’ni okudum, onaylıyorum.',
      contactInfo: 'İletişim Bilgileri',
      selectYourInstalmentOptions: 'Size uygun taksit seçeneğini seçiniz',
      cvc: 'CVC',
      expiryShort: 'AA/YY',
      cardHolderFullName: 'Kart Sahibi Adı Soyadı',
      paymentType: 'Ödeme Tipi',
      tenantCode: 'Firma Kodu',
      relatedTenantCode: 'Bağlı Firma Kodu',
      defaultPos: 'Varsayılan Pos',
      useDefaultPos: 'Varsayılan Pos olarak kullanılsın',
      infoForQrOrder: 'QR Order Ödeme Bilgileri',
      youCanPayWithQr: 'Girdiğiniz bilgiler ile karekod ödemesi yapabilirsiniz',
      paymentSet: 'Ödeme Seti',
      emailAddress: 'E-Mail Adresi',
      isDisposablePayLink: 'Tek Kullanımlık Ödeme Linki',
      period: 'Periyot',
      linkTimeout: 'Link Zaman Aşımı',
      selectLanguage: 'Dil Seçiniz',
      transactionNumber: 'İşlem Numarası',
      enterAmount: 'Tutarı Giriniz',
      qrOrder: 'QR Order',
      smsOrder: 'SMS Order',
      emailOrder: 'E-Mail Order',
      creditCard: 'Kredi Kartı',
      payWith: '{{type}} ile Öde',
      payToInsteadOfPerson: 'Yerine Ödeme Yapılan Kişi',
      searchFirm: 'Firma ara',
      bankBasedOnusNotOnusChart: 'Banka Bazlı OnUs/NotOnus Dağılım Grafiği',
      bankBasedCreditCardTypeChart: 'Banka Bazlı Kart Tipi Dağılım Grafiği',
      unknown: 'Belirsiz',
      cardBanks: 'Kart Bankaları',
      cancelSend: 'Göndermekten vazgeç',
      confirmSelectedRecords: 'Seçili kayıtları indirmek ister misin ?',
      multiplePdfDownload: 'Çoklu PDF İndir',
      multiplePdfMailSend: 'Çoklu PDF Mail Gönder',
      cancelDownload: 'İndirmekten vazgeç',
      isDisplayAgreement: 'Sözleşme gösterilsin',
      paymentPageAgreement: 'Ödeme Sayfası Sözleşme',
      isDisplayPaymentForm: 'Ödeme formunda gösterilsin',
      paymentPageDescription: 'Ödeme Sayfası Açıklama',
      virtualKeyboard: 'Sanal Klavye',
      isRequired: 'Zorunlu',
      isDisplay: 'Görünsün',
      fieldName: 'Alan Adı',
      paymentSettings: 'Ödeme Parametreleri',
      criticalBalanceLimit: 'Kritik Bakiye Limiti',
      resendPaymentInfo: 'Yeniden Gönderilecek Bilgiler',
      requestDate: 'İstek Tarihi',
      consumerInfo: 'Alıcı Bilgileri',
      requestBy: 'İstek Yapan',
      netlinkTransaction: 'NetLink Ödeme',
      sendType: 'Gönderim Tipi',
      sentDate: 'Gönderim Tarihi {{add}}',
      requestSent: 'İstek Gönderildi',
      pending: 'Bekleyen',
      unsuccessful: 'Başarısız',
      successful: 'Başarılı',
      lastPaymentDate: 'Son Ödeme Tarihi',
      isUserTypeAdmin: 'Yetkili kullanıcı mı?',
      unused: 'Kullanılmıyor',
      monthlyTotalAmount: 'Aylık Toplam Fiyat',
      yearlyTotalAmount: 'Yıllık Toplam Fiyat',
      yearlyTransactionLimit: 'Yıllık Hareket Sayısı',
      monthlyTransactionLimit: 'Aylık Hareket Sayısı',
      invoiceType: 'Fatura Tipi',
      useMonthly: 'Aylık Kullanılabilir',
      useYearly: 'Yıllık Kullanılabilir',
      monthlyTotalPackageAmount: 'Aylık Toplam Paket Tutarı',
      yearlyTotalPackageAmount: 'Yıllık Toplam Paket Tutarı',
      monthlyMaintenanceAmount: 'Aylık Bakım/Destek',
      yearlyMaintenanceAmount: 'Yıllık Bakım/Destek',
      yearlyAmount: 'Yıllık Tutar',
      monthlyAmount: 'Aylık Tutar',
      vatRate: 'KDV',
      prices: 'Fiyatlar',
      packageDefinition: 'Paket Tanımları',
      own: 'Kendisi',
      refundAmount: 'İade Edilecek Tutar',
      performingBy: 'İşlemi Gerçekleştiren Kişi',
      cardType: 'Kart Tipi',
      cardHolderName: 'Kart Üzerindeki İsim',
      cardInfo: 'Kart Bilgileri',
      ipAddress: 'IP Adresi',
      provisionNumber: 'Provizyon Numarası',
      authorizationNumber: 'Otorizasyon Numarası',
      paymentUserName: 'Ödeyen Kullanıcı',
      paymentInfo: 'Ödeme Bilgileri',
      pageTotal: 'Sayfa Toplamı',
      sales: 'Satış',
      cancelOrRefund: 'İptal/İade',
      generalTotals: 'Genel Toplamlar',
      referenceNumber: 'Referans Numarası',
      netAmount: 'Net Tutar',
      operationStatus: 'İşlem Durumu',
      operationType: 'İşlem Tipi',
      posParameters: 'Pos Parametreleri',
      posInfo: 'Pos Bilgisi',
      pos: 'Pos',
      posActive: 'Pos Aktif',
      useCvv: 'CVV Kullanılsın',
      threeDSecureType: '3D Güvenlik Seviyesi',
      selectPaymentLogo: 'Ödeme ekranında görülecek logoyu seçiniz',
      plusInstallment: '+Taksit',
      plusInstallmentLowerLimit: '+Taksit Alt Limit',
      plusInstallmentOverLimit: '+Taksit Üst Limit',
      deferral: 'Erteleme',
      deferralLowerLimit: 'Erteleme Alt Limit',
      deferralOverLimit: 'Erteleme Üst Limit',
      instalmentDeferralAndPlusInstallment: 'Taksit Erteleme ve +Taksit',
      maxLimit: 'Üst Limit',
      minLimit: 'Alt Limit',
      bankCommission: 'Banka Komisyonu',
      customerCommission: 'Müşteri Komisyonu',
      installment: '{{installment}} Taksit',
      commRates: 'Komisyon Oranları',
      posCommission: 'Pos Komisyon',
      logo: 'Logo',
      is3DActive: '3D aktif mi?',
      currencyType: 'Döviz Tipi',
      prevValue: 'Önceki Değer: {{value}}',
      emptyValue: 'Yok',
      newTenant: 'Yeni {{tenantType}} Ekle',
      editTenant: '{{tenantType}} Düzenle',
      moduleInfo: 'Ürün Bilgileri',
      usableModules: 'Kullanılan Uygulamalar',
      partnerTenants: 'Partner Şirketler',
      groupTenants: 'Grup Şirketler',
      tenantStatus: 'Firma Durumu',
      processStatus: 'İşlem Durumu',
      membershipOperations: 'Üyelik İşlemleri',
      documentNotUploaded: "Evrak yüklenmedi",
      waitingForApproval: "Onay bekliyor",
      documentsIncorrectorMissing: "Evrak hatalı/eksik",
      approved: "Onaylandı",
      notApproved: "Onaylanmadı",
      commercialRegistryNewspaper: 'Ticari Sicil Gazetesi',
      taxBoard: 'Vergi Levhası',
      signatureCirculars: 'İmza Sirküleri',
      identityCard: 'Kimlik Kartı',
      fullAddress: 'Açık Adres',
      documents: 'Belgeler',
      insertedTime: 'Oluşturma Tarihi',
      MICR: 'MICR',
      Cheque: 'Çek',
      institutionCode: 'Kurum Kodu',
      sftp: 'SFTP',
      hostName: 'Host',
      portName: 'Port',
      upFolder: 'Üst Klasör',
      downFolder: 'Alt Klasör',
      downloadExampleFile: 'Örnek Dosya İndir',
      uploadChequeWithFile: 'Dosya ile Çek Yükle',
      createCheque: 'Çek Hazırla',
      returnToSignIn: 'Giriş sayfasına dön',
      getRefreshToken: "Token'ı yenile",
      youAreRedirecting: "Yönlendiriliyorsunuz...",
      blockageAmount: 'Blokeli Tutar',
      currentBalance: 'Kullanılabilir Bakiye',
      creditLimit: 'Kredi Limiti',
      availableBalanceWithCredit: 'Kredili Kullanılabilir Bakiye',
      order: 'Talimat',
      suffix: 'Ek No',
      enterRulePoint: 'Kural puanı gir',
      piece: 'Adet',
      monthlyDistributionOfValueDatePayments: 'Valörlü Ödemelerin Tutar Bazında Aylık Dağılımı',
      transactionPiece: 'İşlem Adedi',
      top10DealersOnLimitBasis: 'Limit bazında ilk 10 bayi',
      yesterdayIncomingPayments: 'Dün Gelen Tahsilat',
      cardNumber: 'Kart Numarası',
      isRepresentative: 'Müşteri temsilcisi mi?',
      doBlockedUserActiveWaitMessage: 'Hesabınızın blokesi kaldırılıyorken lütfen bekleyin, bu işlemin ardından giriş yapabileceksiniz.',
      pleaseWait: 'Lütfen Bekleyin...',
      general: 'Ortak',
      categoriesForTenant: '{{tenant}} firmasının {{categoryType}} {{type}} Kategorileri',
      customCategories: 'Özel Kategoriler',
      generalCategory: 'Genel Kategori',
      enabledOrDisabled: 'Aktif/Pasif',
      ruleEnabledOrDisabled: 'Kural Aktif/Pasif Güncellemesi',
      generalRulesForTenant: '{{tenant}} firmasının Ortak Kuralları',
      customRulesForTenant: '{{tenant}} firmasının Özel Kuralları',
      rule: 'Kural',
      futurePayment: 'Gelecek Ödeme',
      completedPayment: 'Tamamlanan Ödeme',
      modules: 'Modüller',
      orderStatus: 'Talimat Durumu',
      unchangeable: 'Değiştirilemez',
      paymentDetails: 'Ödeme Bilgileri',
      customerBankAccountDetails: 'Alıcı Hesap Bilgileri',
      customerBankAccount: 'Alıcı Banka Hesabı',
      linkToCurrentAccount: 'Cari hesaba bağla',
      payment: 'Ödeme',
      incomingPayments: 'Gelen Tahsilat',
      remainingPayments: 'Bugün Gelecek Tahsilat',
      pendingPayments: 'Bekleyen Tahsilat',
      totalOperationGiro: 'Toplam İşlem Ciro Raporu',
      vendorPaymentDistribution: 'Dönemsel Bayi Bazlı Tahsilatların Dağılımı',
      bankBasedTotalLimit: 'Banka Bazlı Toplam Limitler',
      bankBasedPaymentDistribution: 'Dönemsel Gelen Ödeme Banka Bazlı Dağılım',
      monthlyTotalPayment: 'Aylık Toplam Gelen Ödeme',
      lastYear: 'Geçen Yıl',
      bankBasedTransactionDistribution: 'Banka Bazlı Yapılan İşlemlerin Dağılımı',
      valorPureAmount: 'Gelen Ödeme',
      transactionPureAmount: 'İşlem Tutarı',
      notifyToCustomerRepresentative: "Müşteri temsilcisini bilgilendir",
      customerRepresentatives: "Müşteri Temsilcisi",
      currentAccountSelectedItemText: "Cari Eşleşme Alan Adı",
      dynamicFieldName: "Dinamik Alan Adı",
      matchingCondition: "Eşleşme Koşulu",
      countOfViews: "Görüntülenme sayısı",
      unread: "Okunmamış",
      notifications: "Bildirimler",
      bankAddedSuccessful: 'Banka Başarıyla Eklendi',
      noCheckCurrentAccount: "Cari Hesabı Kontrol Etme",
      currentAccountSingleMatch: "Cari Hesap Tekil Eşleşmiş",
      currentAccountNotMatch: "Cari Hesap Eşleşmemiş",
      currentAccountMultiMatch: "Cari Hesap Çoklu Eşleşmiş",
      remainingPayment: "Kalan Ödeme",
      commission: "Komisyon",
      chip: "Chip",
      point: "Puan",
      award: "Ödül",
      pleaseFillSecurityCode: "Lütfen telefonunuza gönderilen SMS şifresini giriniz",
      smsVerify: "SMS Doğrulama Kodu",
      pendingPayment: "Bekleyen Ödeme",
      incomingPayment: "Gelen Ödeme",
      dailyIncoming: "Günlük Gelen",
      dailyOutgoing: "Günlük Giden",
      moduleInactive: '{{module}} modulü aktif değildir.',
      clickForProductInformation: 'Ürün hakkında detaylı bilgi ve satın almak için tıklayınız.',
      willBeAvailableSoonHeader: '{{module}} Nedir?',
      willBeAvailableSoon: 'Çok yakında hizmetinize sunulacaktır, ürün hakkında detaylı bilgi için tıklayınız.',
      bankCount: 'Banka Sayısı {{add}}',
      bankLimitCount: "Banka Sayısı (En Az)",
      bankLimit: "Banka Sayısı",
      currentAccountAddedSuccessful: "Cari hesap başarı ile eklenmiştir.",
      posBased: "Poslara Göre",
      paymentAmount: "İşlem Tutarı",
      paymentCount: "İşlem Adedi",
      vendor: "Bayi",
      subVendor: "Alt Bayi",
      physical: "Fiziksel",
      virtual: "Sanal",
      key: 'Anahtar',
      job: 'Job',
      jobName: 'Job Adı',
      cron: 'Cron',
      lastRun: 'Son Çalışma',
      jobStatus: 'Job Durumu',
      lastRunDate: 'Son Çalışma Tarihi {{add}}',
      testDataStatus: 'Test Datası Yaratma Durumu',
      packages: 'Paketler',
      partnertenant: 'Partner Müşteri',
      true: 'Var',
      false: 'Yok',
      firm: 'Firma',
      saved: 'Saklamalı',
      unSaved: 'Saklamasız',
      withInvoice: 'Faturalı',
      withoutInvoice: 'Faturasız',
      jobsForTenant: "{{tenant}} firmasının Job'ları",
      psrParametersForTenant: '{{tenant}} firmasının Posrapor Parametreleri',
      banksForTenant: '{{tenant}} firmasının Bankaları',
      currentAccountsForTenant: '{{tenant}} firmasının Cari Hesapları',
      dbsParametersForTenant: '{{tenant}} firmasının DBS Parametreleri',
      pleaseSelectTenant: 'Lütfen Firma Seçiniz',
      dynamicField: 'Dinamik Alan',
      'bank/branch/account': 'Banka/Şube/Hesap',
      erpName: 'Erp Adı',
      bankBased: 'Bankalara Göre',
      accountBased: 'Hesaplara Göre',
      paymentDate: 'Ödeme Tarihi {{add}}',
      totalAmount: 'Toplam Tutar',
      totalCount: 'Toplam Adet',
      consumerAccountNumber: 'Alıcı Hesap No',
      consumerTitle: 'Alıcı Ünvan',
      excelFormat: 'Excel Formatı',
      createPayment: 'Ödeme Hazırla',
      invoice: 'Fatura',
      reporting: 'Raporlama',
      creatingInstruction: 'Talimat',
      reportLine: 'Kart Hareketleri',
      tenantInstallmentCount: 'Şirket Taksit Sayısı {{add}}',
      installmentNumber: 'Taksit Sırası {{add}}',
      commissionAmount: '∑ Komisyon Tutarı',
      commissionRate: '∑ Komisyon Oranı',
      installmentAmount: 'Taksit Tutarı',
      installmentNetAmount: 'Taksit Net Tutarı',
      valorDate: 'Valör Tarihi {{add}}',
      installmentCount: 'Taksit Sayısı {{add}}',
      installments: 'Taksitler',
      userName: 'Kullanıcı Adı',
      serviceType: 'Servis Tipi',
      account: 'Hesap',
      parameter: 'Parametre',
      customerInfo: 'Bayi Bilgisi',
      unmatched: 'Eşleştirilmemiş',
      matched: 'Eşleştirilmiş',
      value: 'Değer',
      generalRule: 'Ortak Kural',
      generalRules: 'Ortak Kurallar',
      delete: "Sil",
      newRecord: "Yeni Kayıt Ekle",
      date: 'Tarih',
      incomingTotal: 'Giriş Toplamı',
      outgoingTotal: 'Çıkış Toplamı',
      openingBalance: 'Önceki Gün Devri',
      closingBalance: 'Sonraki Güne Devir',
      detail: 'Detay',
      savedCount: 'Saklamalı',
      unsavedCount: 'Saklamasız',
      transactionCount: "Hareket Sayısı",
      licenceType: "Lisans Tipi",
      accessRights: 'Yetkiler',
      requiredAccessRight: "En az 1 yetki seçmeniz gerekmektedir.",
      hidingDay: 'Saklamalı / Saklamasız Gün Sayısı',
      vknTckn: 'VKN / TCKN',
      relatedTenant: 'Bağlı Firma',
      maxTenantCount: 'Maksimum İşyeri Sayısı',
      tenantLogo: 'Firma Logosu',
      usingErpType: 'Kullandığı Erp Tipi',
      distributableErpType: 'Dağıtabileceği Erp Tipleri',
      logType: 'Log Tipi',
      tenantPackages: 'Firmaya Tanımlı Paketler',
      isActive: "Aktif",
      canUsePortal: 'Portal Kullanabilir',
      canUseMobile: 'Mobil Kullanabilir',
      canUseErp: 'ERP Kullanabilir',
      canUseErpService: 'ERP Kullanabilir',
      addMinOneUser: "En az 1 kullanıcı girmeniz gerekmektedir.",
      unitPrice: "Birim Fiyat",
      totalPrice: "Toplam Fiyat",
      newLicence: "Yeni Lisans Ekle",
      updateLicence: "Lisans Düzenle",
      firmInformation: "Firma Bilgileri",
      licenceInformation: "Lisans Bilgileri",
      firmUsers: "Firma Kullanıcıları",
      editCompany: "Firma Düzenle",
      bankTransactionSplit: "Banka Hareket Bölüştürme",
      approvedTransfer: 'Hareketin aktarılmasını onaylıyorum.',
      transaction: 'Hesap Hareketi',
      downloadAsPDF: 'PDF Olarak İndir',
      sendMail: 'Mail Gönder',
      sendAsMail: 'Mail Olarak Gönder',
      syncSuccessfulAgain: 'Başarılı olarak aktarılanları tekrar aktarmak ister misiniz?',
      synchronize: 'Aktar',
      confirmation: 'Onay',
      doYouConfirm: 'Onaylamak ister misiniz?',
      doYouConfirmSync: 'Başarılı olarak aktarılanları tekrar aktarmak ister misiniz?',
      integrationStatus: 'Ent Durumu',
      bankAccountBalance: 'Bakiye',
      contactName: 'İlgili Kişi',
      documentNumber: 'Dekont Numarası',
      category: 'Kategori',
      operator: "Operatör",
      domain: "Alan Adı",
      equal: 'Eşit',
      contains: 'İçeren',
      startsWith: 'Başlayan',
      endsWith: 'Biten',
      greater: 'Büyüktür',
      less: 'Küçüktür',
      custom: 'Özel',
      lessEqual: 'Küçük Eşittir',
      greaterEqual: 'Büyük Eşittir',
      condition: "Koşul",
      conditions: "Koşullar",
      fieldDefinitions: 'Alan Tanımları',
      priority: 'Öncelik',
      notifyToCurrentAccount: 'Cari hesabı bilgilendir.',
      notification: 'Bildirim',
      template: 'Şablon',
      passwordResetConfirm: "Hesap şifresi sıfırlanacaktır. Emin misiniz?",
      passwordResetSuccess: "Hesap şifresi sıfırlandı.",
      passwordUpdated: "Şifre başarıyla değiştirildi.",
      token: 'Token',
      userCount: 'Kullanıcı Sayısı',
      accountCount: 'Hesap Sayısı',
      groupTenantUserCount: 'Grup Firma Kullanıcı Sayısı',
      groupTenantAccountCount: 'Grup Firma Hesap Sayısı',
      groupTenantTransactionCount: 'Grup Firma Hareket Sayısı',
      usedInfo: 'Kullanım Durumu',
      newCompany: "Yeni Firma Ekle",
      newGroupTenant: 'Yeni Grup Firma Ekle',
      newPartnerTenant: 'New Partner Tenant',
      editPartner: "Partner Firma Düzenle",
      newPartner: "Yeni Partner Firma Ekle",
      editGroupCompany: "Grup Firma Düzenle",
      newGroupCompany: "Yeni Grup Firma Ekle",
      companyNameCode: "Firma Adı / Kodu",
      partnerFirm: "Partner Firma",
      groupFirm: "Grup Firma",
      users: "Kullanıcılar",
      active: "Aktif",
      companyName: 'Firma Adı',
      companyCode: 'Firma Kodu',
      packageName: "Paket Adı",
      packageStatus: "Paket Durumu",
      accountLimit: "Hesap Sayısı",
      transactionLimit: "Hareket Sayısı",
      userLimit: "Kullanıcı Sayısı",
      accountLimitCount: "Hesap Sayısı (En Az)",
      transactionLimitCount: "Hareket Sayısı (En Az)",
      userLimitCount: "Kullanıcı Sayısı (En Az)",
      unlimited: 'Sınırsız',
      groupTenant: "Grup Firma",
      partnerTenant: "Partner Firma",
      tenant: 'Müşteri',
      grouptenant: "Grup Firma",
      licenceEndDate: 'Lisans Bitiş Tarihi',
      licenceEndDateStart: 'Lisans Bitiş Tarihi (Başlangıç)',
      licenceEndDateEnd: 'Lisans Bitiş Tarihi (Bitiş)',
      licence: 'Lisans',
      erp: "ERP",
      mobile: "Mobil",
      portal: "Portal",
      firmType: "Firma Tipi",
      contractualType: 'Fatura Tipi',
      workType: 'Çalışma Tipi',
      status: "Durum",
      inactive: "Pasif",
      minUsedTransactionCount: 'Hareketler (En Az)',
      maxUsedTransactionCount: 'Hareketler (En Fazla)',
      minUsedUserCount: 'Kullanıcılar (En Az)',
      maxUsedUserCount: 'Kullanıcılar (En Fazla)',
      minUsedAccountCount: 'Hesaplar (En Az)',
      maxUsedAccountCount: 'Hesaplar (En Fazla)',
      accounts: 'Hesaplar',
      transactions: "Hareketler",
      daily: "Günlük",
      monthly: "Aylık",
      yearly: "Yıllık",
      minSavedCount: 'Saklamalı (En Az)',
      maxSavedCount: 'Saklamalı (En Fazla)',
      minUnsavedCount: 'Saklamasız (En Az)',
      maxUnsavedCount: 'Saklamasız (En Fazla)',
      voucherTypes: 'Fiş Tipi',
      erpVoucherCode: 'Erp Fiş Kodu',
      erpVoucherName: 'Erp Fiş Adı',
      typeCode: "Tip Kodu",
      typeCodeType: "Tip Kodu Türü",
      description: 'Açıklama',
      erpType: "ERP Tipi",
      customRule: 'Özel Kural',
      syncRule: 'Kural Eşitle',
      includeCompleted: 'Tamamlananları dahil et',
      transferStatus: 'Transfer Durumu',
      incomingCategory: "Gelen Kategori",
      outgoingCategory: "Giden Kategori",
      bankAccountCode: "Banka Hesap Kodu",
      voucherType: 'Fiş Tipi',
      typeOne: "Tip Kodu 1",
      typeTwo: "Tip Kodu 2",
      accountPlanCode: "Hesap Plan Kodu",
      exampleDescription: "Örnek Açıklama",
      oldPassword: "Eski Şifrenizi Giriniz",
      userInfo: 'Kullanıcı Bilgileri',
      currentVersion: 'Güncel Versiyon',
      feature: 'Özellik',
      improving: 'Geliştirme',
      bug: 'Düzeltme',
      versioning: 'Versiyon {{version}} Yenilikleri',
      currentAccount: 'Cari Hesap',
      companyTitle: 'Firma Ünvanı',
      company: 'Kurumsal',
      person: 'Bireysel',
      bankAccountNumbers: 'Banka Hesap Numaraları',
      bankParameters: 'Banka Parametreleri',
      tenantBank: 'Banka',
      merchantCode: 'Merchant Kod',
      parameters: 'Parametreler',
      user: 'Kullanıcı',
      banks: 'Bankalar',
      permissions: 'Yetkiler',
      role: 'Rol',
      mainUserGroup: 'Ana Kullanıcı Rolü',
      fieldDefinition: 'Alan Tanımı',
      required: 'Zorunlu',
      min: 'En Az',
      max: 'En Fazla',
      minAmount: 'En Az Tutar',
      maxAmount: 'En Fazla Tutar',
      categoryInitializer: 'Hareket Kategorisi',
      transactionCategoryType: 'Kategori Tipi',
      id: 'Id',
      list: 'Liste',
      automaticRule: 'Otomatik Kural',
      textInput: 'Metin Girişi',
      formulaPattern: 'Formül/Desen',
      process: 'İşlem',
      target: 'Hedef',
      source: 'Kaynak',
      title: 'Başlık',
      code: 'Kod',
      thisIsATestData: 'Bu bir test kaydıdır.',
      movementCategories: 'Hareket Kategorileri',
      bankAccounts: 'Banka Hesapları',
      add: 'Ekle',
      edit: 'Düzenle',
      transactionCategory: 'Hareket Kategorisi',
      categoryName: 'Kategori Adı',
      outgoingCategories: '{{type}} Giden Kategoriler',
      incomingCategories: '{{type}} Gelen Kategoriler',
      roles: 'Kullanıcı Rolleri',
      transactionTransferType: 'Hareket T. Tipi',
      erpCode: 'Erp Kodu',
      card: 'Kart',
      table: 'Tablo',
      cumulativeBalance: 'Kümülatif {{currency}}',
      branchNumber: 'Şube Kodu',
      branchName: 'Şube Adı',
      accountNumber: 'Hesap Numarası',
      balance: 'Bakiye',
      lastTransactionDate: 'Son İşlem Tarihi',
      lastRequestDate: 'Son Başarılı Sorgulama',
      search: 'Arama',
      currencyBalances: "Kümülatif Bakiye",
      currencyCode: 'Para Birimi',
      accountName: 'Hesap Adı',
      endDate: 'Bitiş Tarihi',
      startDate: 'Başlangıç Tarihi',
      tenantName: 'Firma Adı',
      administrator: 'Yönetim',
      noRecord: "Kayıt Bulunamadı",
      statusDate: "Durum Tarihi {{add}}",
      bank: "Banka",
      start: "Başlangıç",
      end: "Bitiş",
      creditLinkSent: 'Kredi Başvuru Linki Gönderildi',
      creditWaitingApproval: 'Başvuru Süreci Devam Ediyor',
      applicationCompleted: 'Başvuru Tamamlandı',
      applicationDate: 'Başvuru Tarihi {{add}}',
      bankName: 'Banka Adı',
      referenceCode: "Referans Kodu: {{code}}",
      sendingUrlUserInfo: 'Başvuru linkini göndermek istediğiniz kişinin bilgisini giriniz',
      qrDescription: "Başvuruya devam etmek için telefonunuzdan ekrandaki QR kodunu okutunuz. QR kodu ile devam edebilmenizi çin telefonunuzda QR kod okuyucusunun bulunması gerekmektedir. Yüklü değil ise AppStore veya PlayStore’ dan indirebilirsiniz.",
      qrCode: 'QR Kod',
      feeAmount: 'Bu başvurunun {{amount}}₺ tutarı hizmet bedeli olarak alınacaktır',
      creditAmount: 'Kredi başvuru tutarı',
      transferredAmount: 'Aktarılacak tutar',
      amount: 'Tutar',
      maturityRate: 'Vade Oranı',
      month: 'Ay',
      maturity: 'Vade',
      totalInstallmentAmount: 'Toplam Tutar',
      bankRate: 'Vade Oranı',
      installmentOptions: 'Taksit Seçenekleri',
      productType: 'Ürün Tipi',
      pleaseProductType: 'Lütfen ödeme yapılacak ürün tipini seçiniz',
      bankSelect: 'Banka Seçimi',
      pleaseSelectYourBankAccount: 'Lütfen hesabınızın bulunduğu bankayı seçiniz',
      applyCredit: 'Krediye Başvur',
      toView: 'Görüntülemek için',
      pleaseSignIn: "Oturum açın",
      mailSuccessfullySentEmail: 'E-Posta adresinize aktivasyon linki gönderilmiştir. Lütfen aktivasyon işlemlerini tamamlayınız',
      youCanEnteredSystemYourInformation: 'Vermiş olduğunuz bilgiler ile sisteme giriş yapabilirsiniz.',
      registrationSuccessfully: 'Üyelik işlemleriniz başarıyla tamamlanmıştır',
      defaultAgreementAcceptance: 'Sözleşme bilgilerini okudum, onaylıyorum.',
      neighborhood: 'Semt / Mahalle',
      district: 'İlçe',
      city: 'Şehir',
      address: 'Adres',
      tenantContactInfo: 'Firma İletişim Bilgileri',
      pleaseEnterTenantContactInformation: 'Lütfen firma iletişim bilgilerinizi giriniz',
      taxOffice: 'Vergi Dairesi',
      taxNo: 'Vergi Numarası',
      memberTenantName: 'Üye İş Yeri Adı',
      ibanNo: 'IBAN NO',
      pleaseEnterTenantInformation: "Lütfen firma bilgilerinizi giriniz",
      resend: 'Tekrar Gönder',
      remainingTime: 'Kalan Süre',
      pleaseEnterVerificationCode: 'Lütfen girmiş olduğunuz telefon numarasına gönderilen doğrulama kodunu giriniz',
      name: 'Ad',
      surname: 'Soyad',
      birthDate: 'Doğum Tarihi',
      tckn: 'TCKN',
      gsm: 'GSM No',
      pleaseEnterSignatoryInformation: 'Lütfen şirket imza yetkilisinin bilgilerini giriniz',
      passwordSettings: 'Şifre İşlemleri',
      contractInfo: 'Sözleşme Bilgileri',
      signatoryInfo: 'İmza Yetkilisi Bilgileri',
      tenantInfo: 'Firma Bilgileri',
      comingSoon: 'Yakında',
      newPassword: 'Şifrenizi giriniz',
      confirmPassword: 'Şifrenizi doğrulayınız',
      setPassword: 'Şifre Belirle',
      youCanEnterANewPassword: 'Yeni şifrenizi yazabilirsiniz',
      forgotPasswordMessage: 'Yeni bir şifre belirlemek için kayıtlı e-posta adresinizi yazınız. Şifre değiştirme linkini e-posta adresinize göndereceğiz.',
      all: 'Hepsi',
      total: 'Toplam',
      record: 'Kayıt',
      bankAccountNumber: 'Banka Hesap Numarası',
      iban: 'IBAN',
      currentAccountCode: 'Cari Hesap Kodu',
      currentAccountName: 'Cari Hesap Adı',
      currentAccountType: 'Cari Hesap Tipi',
      nameSurname: 'Adı Soyadı',
      email: 'Eposta',
      phone: 'Telefon',
      mobilePhone: 'Mobil Telefon',
      tc: 'TCKN',
      taxNumber: 'VKN',
      onlineJobNumber: 'Online İş Numarası',
      filter: 'Filtre',
      currency: 'Döviz Kurları',
      tcmbDatas: 'Merkez Bankası bilgileridir.',
      buying: 'Alış',
      selling: 'Satış',
      cashAsset: "Nakit Varlıklar {{add}}",
      overdraftAccountBalance: "Ek Hesap Bakiyesi {{add}}",
      netAsset: "Net Varlıklar {{add}}",
      accountBalance: "Banka Bazlı {{isCumulative}} Hesap Bakiyesi Dağılımı",
      bankBasedDistribution: "Dönemsel Gelen/Giden Banka Bazlı {{isCumulative}} Dağılım",
      countProcess: "Adet İşlem",
      totalProcess: "Toplam İşlem",
      transactionAmount: "İşlem Tutarı",
      transactionStatus: "İşlem Durumu",
      transactionDate: "İşlem Tarihi {{add}}",
      moneyTransferDate: "Para Aktarım Tarihi",
      totalTransferInOut: "Aylık Gelen/Giden {{isCumulative}} Hareketler Toplamı",
      incoming: 'Gelen',
      outgoing: 'Giden',
      Giden: 'Giden',
      Gelen: 'Gelen',
      ocak: 'Ocak',
      şubat: 'Şubat',
      mart: 'Mart',
      nisan: 'Nisan',
      mayıs: 'Mayıs',
      haziran: 'Haziran',
      temmuz: 'Temmuz',
      ağustos: 'Ağustos',
      eylül: 'Eylül',
      ekim: 'Ekim',
      kasım: 'Kasım',
      aralık: 'Aralık',
      yesterday: 'Dün',
      today: 'Bugün',
      last7day: 'Son 7 Gün',
      last30day: 'Son 30 Gün',
      cumulative: 'Kümülatif',
      welcomeMessage: 'Hoşgeldiniz. Lütfen giriş yapınız',
      username: 'Kullanıcı Adı',
      password: 'Şifre',
      rememberMe: 'Beni Hatırla',
      forgotPassword: 'Şifremi Unuttum',
      managementPanel: 'Yönetim Paneli',
      assets: 'Varlıklarım',
      pendingInvoices: 'Bekleyen Faturalar',
      pendingOrders: 'Bekleyen Talimatlar',
      waitingTransferToERP: "ERP'ye Aktarılması Beklenen",
      preConfirmCredit: 'Ön Onaylı Hazır Kredi',
      totalUnpaidAmount: 'Toplam Ödenecek Tutar',
      totalCheckAmount: 'Toplam Çek Tutarı',
      dealerName: "Bayi Adı",
      bankCode: 'Banka',
      chequeUserNameSurname: 'Ad Soyad / Unvan',
      chequeNumber: 'Çek No',
      kesideCity: 'Keşide Yeri',
      kesideDate: 'Keşide Tarihi {{add}}',
      chequeStatus: 'Çek Durumu',
      orderDescription: 'Talimat Açıklaması',
      createdDate: 'Oluşturma Tarihi {{add}}',
      payrollNumber: 'Bordro No',
      titleU: 'Ünvan',

      //BE
      PayInstead: "Yerine Ödeme",
      PayByCard: "Kendi Kartım ile Ödeme",
      PayByCustomerCard: "Müşteri Kartı ile Ödeme"

    },
    status: {
      ToBeTransferred: "Yeni / Aktarılacak",
      Completed: "Başarılı",
      Fail: "Başarısız",
      PriorityFail: "Mükerrer Banka Hareketi",
      NotMapping: "Eşleştirme Yapılmamış",
      DontBeTransferred: "Aktarılmayacak",
      WaitApproval: "Onay Bekleyen",
      synchronize: 'Eşitle',
      syncStatus: 'Aktarım Durumu',
      includeCompleted: 'Tamamlananları dahil et',
      Split: 'Bölünmüş Kayıt',
      ToBeSplit: 'Bölünecek',
      None: 'Hiçbiri',
      Child: 'Bölünmüş Alt Kayıt',
      MultipleCurrentAccount: 'Çoklu Cari Eşleşmesi',
      tenant: {
        Active: 'Aktif',
        Passive: 'Pasif',
        Blocked: 'Bloklu',
        NotApproved: 'Onaylanmadı',
        WaitingForApproval: 'Onay bekliyor',
        InformationMissingIncorrect: 'Bilgi eksik/hatalı',
        DocumentMissingIncorrect: 'Evrak eksik/hatalı'
      }
    },
    routes: {
      bankFileTypes: 'Posrapor Dosya Formatları',
      summaryReport: 'Özet Rapor',
      reportDetail: 'Detay Rapor',
      bankParameters: 'Banka Parametreleri',
      customers: 'Müşteriler',
      memberManagement: 'Üye Yönetimi',
      doPay: 'Ödeme Yap',
      paymentParameters: 'Ödeme Parametreleri',
      netlinkTransaction: 'NetLink Ödemeler',
      payment: 'Ödemeler',
      posList: 'Pos Listeleme',
      tenantInfo: 'Firma Bilgileri',
      allCategories: 'Kategoriler',
      customCategories: "Özel Kategoriler",
      generalCategories: "Ortak Kategoriler",
      recipientAccounts: "Alıcı Hesaplar",
      matchingConditions: "Eşleşme Koşulları",
      tenantsLicence: "Hesap İstatistik Raporu",
      accounts: 'Hesaplar',
      newCompany: "Yeni Şirket Ekle",
      newGroupTenant: 'Yeni Grup Şirketi Ekle',
      account: 'Hesaplar',
      newPartnerTenant: 'Yeni Partner Şirketi Ekle',
      packages: 'Paketler',
      tenants: "Müşteriler",
      companies: "Firmalar",
      licence: "İstatistik Raporu",
      voucherTypes: 'Fiş Tipleri',
      typeCodes: "Tip Kodları",
      erpTypes: "ERP Tipleri",
      paymentList: 'Ödeme Listeleme',
      paymentLists: 'Ödeme Listeleme',
      createPayment: 'Ödeme Hazırla',
      createPayments: 'Ödeme Hazırla',
      announcements: 'Duyurular',
      createCheque: 'Çek Hazırla',
      chequeList: 'Çek Listesi',
      tos: 'Toplu Ödeme Sistemi',
      cek: 'Çek',
      posrapor: 'Posrapor',
      netfinans: 'Netfinans',
      tenantBank: 'Bankalar',
      tenantBanks: 'Bankalar',
      parameter: 'Parametreler',
      parameters: 'Parametreler',
      customerInfo: 'Bayi Bilgileri',
      customerInfos: 'Bayi Bilgileri',
      reporting: 'Raporlama',
      reportings: 'Raporlama',
      creatingInstruction: 'Talimat Hazırla',
      creatingInstructions: 'Talimat Hazırla',
      instructionList: 'Talimat Düzenleme',
      instructionLists: 'Talimat Listeleme',
      dbs: 'Doğrudan Borçlandırma Sistemi',
      usedInfo: 'Kullanım Durumu',
      dashboard: "Anasayfa",
      home: "Anasayfa",
      transactions: "Hesap Hareketleri",
      balances: "Hesap Bakiyeleri",
      currentAccount: "Yeni Cari Hesap",
      currentAccounts: "Cari Hesap Listesi",
      bankAccount: "Banka Hesabı",
      bankAccounts: "Banka Hesapları",
      definitions: "Tanımlar",
      settings: "Ayarlar",
      categories: "Hareket Kategorileri",
      user: "Yeni Kullanıcı",
      users: "Kullanıcılar",
      rule: "Yeni Kural",
      rules: "Kurallar",
      ruleDenifinitions: "Kural Tanımları",
      generalRules: "Ortak Kurallar",
      customRules: "Özel Kurallar",
      role: "Yeni Kullanıcı Rolü",
      roles: "Roller",
      notification: "Yeni Bildirim",
      notifications: "Bildirimler",
      profile: "Kullanıcı Bilgileri",
      userSettings: "Kullanıcı Ayarları",
      dynamicFields: "Dinamik Alanlar",
      fieldDefinitions: "Alan Tanımları",
      createData: 'Test Datası Yarat',
      reports: 'Raporlar',
      balance: 'Banka Devir Raporu',
      average: 'Ortalama Banka Raporu',
      changelog: 'Değişiklik Geçmişi',
      posLists: 'Pos Listesi',
      reportLines: 'Kart Hareketleri',
      reportLineDetails: 'Kart Hareketleri Detay',
      applyCredit: 'Kredi Kullandır',
      applications: 'Başvurularım',
      banks: "Bankalar",
      createTestData: "Test Datası Yarat",
      transactionCategories: "Hareket Kategorileri",
      reportAverage: "Ortalama Banka Raporu",
      reportBalances: "Banka Devir Raporu",
      createOrder: "Talimat Hazırla",
      orderList: "Talimat Listesi",
      dbsParameters: "DBS Parametreleri",
      psrParameters: "Posrapor Parametreleri",
      jobManagement: "Job Yönetimi"
    },
    dbs: {
      invoice: 'Talimat',
      InvoiceNumber: 'Fatura No',
      Description: 'Açıklama',
      OperationStatusId: 'Operasyon Durum Id',
      CurrentAccountId: 'Cari Hesap Id',
      CurrencyCode: 'Para Birimi',
      OrderId: 'Talimat Id',
      UpdatedTime: 'Değiştirilme Tarihi',
      editInvoice: 'Fatura Düzenle',
      newInvoice: 'Yeni Fatura Ekle',
      currentAccountName: 'Cari Hesap Adı',
      createdDate: 'Oluşturma Tarihi {{add}}',
      createOrderResponse: 'Belirlediğiniz şartlara uygun olan faturalarınız için talimat hazırlanmıştır.',
      sendOrderResponse: 'Belirlediğiniz şartlara uygun olan faturalarınız için talimat bankaya gönderilmiştir.',
      currentAccount: 'Cari Hesap',
      match: 'Eşleştirilme',
      matchStatus: 'Eşleştirilme Durumu',
      BankDbsCode1: 'Firma Kodu 1',
      BankDbsCode2: 'Firma Kodu 2',
      success: 'Başarılı',
      orderStatusCode: 'Talimat Durum Kodu: {{code}}',
      totalCount: 'Toplam Adet',
      totalAmount: 'Toplam Tutar',
      createOrder: 'Talimat Hazırla',
      sendOrder: 'Talimat Gönder',
      newCustomerInfo: 'Yeni Bayi Ekle',
      editCustomerInfo: 'Bayi Düzenle',
      risk: 'Risk {{add}}',
      forwardDatedInvoiceTotal: 'İleri Tarihli Fatura Toplamı {{add}}',
      forwardDatedInvoiceCount: 'İleri Tarihli Fatura Adedi {{add}}',
      usableLimit: 'Kullanılabilir Limit {{add}}',
      declaredLimit: 'Tanımlı limit {{add}}',
      url: 'Url',
      hostName: 'Host',
      portName: 'Port',
      upFolder: 'Up Folder',
      downFolder: 'Down Folder',
      userName: 'Kulanıcı Adı',
      password: 'Şifre',
      companyTitle: 'Firma Ünvanı',
      companyCode: 'Firma Kodu',
      newParameters: 'Yeni Parametre Ekle',
      editParameters: 'Parametre Düzenle',
      serviceTypes: 'Servis Tipleri',
      sftp: 'SFTP',
      ftp: 'FTP',
      ws: 'WS',
      serviceType: 'Servis Tipi',
      dealerCode: 'Bayi Kodu',
      orderDescription: 'Talimat Açıklaması',
      orderNumber: 'Talimat Numarası',
      orderStatusSourceCode: 'Talimat Durum Kodu',
      invoiceStatus: 'Fatura Durumu',
      dealerCodeOrName: 'Bayi Kodu/Adı',
      orderDetail: 'Talimat Detay',
      amount: 'Tutar {{add}}',
      piece: 'Adet',
      orderStatus: 'Talimat Durumu',
      orderCreatedDate: 'Talimat Oluşturma Tarihi {{add}}',
      invoiceNumber: 'Fatura Numarası',
      editCreatingOrders: 'Talimat Düzenle',
      newCreatingOrders: 'Yeni Talimat Hazırla',
      downloadExampleFile: 'Örnek Dosya İndir',
      bankName: 'Banka Adı',
      dealerName: 'Bayi Adı',
      currency: 'Para Birimi',
      invoiceDate: 'Fatura Tarihi {{add}}',
      expiryDate: 'Vade Tarihi {{add}}',
      invoiceStatusSourceCode: 'Fatura Durumu',
      invoiceNo: 'Fatura Numarası',
      invoiceAmount: 'Fatura Tutarı',
      description: 'Açıklama',
      operationStatuses: {
        initial: 'Başlatıldı',
        waitingProcess: 'İlerleme Bekleniyor'
      },
      orderStatuses: {
        new: 'Yeni',
        canceled: 'İptal',
        failed: 'Hatalı',
        erpNew: 'ERP - Yeni',
        waitApproval: 'Onayda Bekliyor',
        approved: 'Onaylandı',
        waitConfirmation: 'Teyit Bekliyor',
        willBePay: 'Ödenecek',
        unpaid: 'Ödenmedi',
        partiallyPaid: 'Kısmi Ödendi',
        paid: 'Ödendi',
      }
    },
    tos: {
      insertedTime: 'Oluşturma Tarihi',
      accountNumber: 'Hesap Numarası',
      operationStatusId: 'Operasyon Durum Id',
      currentAccountId: 'Cari Hesap Id',
      currencyCode: 'Para Birimi',
      recipientAccounts: 'Alıcı Hesaplar',
      min: '(En Az)',
      max: '(En Fazla)',
      startCreateOrder: 'Ödeme Hazırla',
      createNewPayment: 'Yeni Ödeme Oluştur',
      cunsomerIban: 'Alıcı IBAN',
      cunsomerBranchCode: "Alıcı Şube Kodu",
      cunsomerAccountNo: 'Alıcı Hesap No',
      cunsomerTitle: 'Alıcı Ünvan',
      virtualPosId: 'Pos No',
      merchantId: 'İşyeri No (Merchant Id)',
      terminalId: 'Terminal No (Terminal Id)',
      branch: 'Şube Adı (Branch)',
      editPosList: 'Pos Düzenle',
      posNo: 'Pos Numarası',
      editInvoice: 'Fatura Düzenle',
      newInvoice: 'Yeni Fatura Ekle',
      currentAccountName: 'Cari Hesap Adı',
      createdDate: 'Oluşturma Tarihi {{add}}',
      createOrderResponse: 'Belirlediğiniz şartlara uygun olan faturalarınız için talimat hazırlanmıştır.',
      sendOrderResponse: 'Belirlediğiniz şartlara uygun olan faturalarınız için talimat bankaya gönderilmiştir.',
      currentAccount: 'Cari Hesap',
      match: 'Eşleştirilme',
      matchStatus: 'Eşleştirilme Durumu',
      BankDbsCode1: 'Firma Kodu 1',
      BankDbsCode2: 'Firma Kodu 2',
      success: 'Başarılı',
      orderStatusCode: 'Talimat Durum Kodu: {{code}}',
      totalCount: 'Toplam Adet',
      totalAmount: 'Toplam Tutar',
      createOrder: 'Talimat Hazırla',
      sendOrder: 'Talimat Gönder',
      newCustomerInfo: 'Yeni Bayi Ekle',
      editCustomerInfo: 'Bayi Düzenle',
      risk: 'Risk {{add}}',
      forwardDatedInvoiceTotal: 'İleri Tarihli Fatura Toplamı {{add}}',
      forwardDatedInvoiceCount: 'İleri Tarihli Fatura Adedi {{add}}',
      usableLimit: 'Kullanılabilir Limit {{add}}',
      declaredLimit: 'Tanımlı limit {{add}}',
      url: 'Url',
      hostName: 'Host',
      portName: 'Port',
      upFolder: 'Up Folder',
      downFolder: 'Down Folder',
      userName: 'Kulanıcı Adı',
      password: 'Şifre',
      companyTitle: 'Firma Ünvanı',
      companyCode: 'Firma Kodu',
      newParameters: 'Yeni Parametre Ekle',
      editParameters: 'Parametre Düzenle',
      serviceTypes: 'Servis Tipleri',
      sftp: 'SFTP',
      ftp: 'FTP',
      ws: 'WS',
      serviceType: 'Servis Tipi',
      dealerCode: 'Bayi Kodu',
      orderDescription: 'Talimat Açıklaması',
      orderNumber: 'Talimat Numarası',
      orderStatusSourceCode: 'Talimat Durum Kodu',
      invoiceStatus: 'Fatura Durumu',
      dealerCodeOrName: 'Bayi Kodu/Adı',
      orderDetail: 'Talimat Detay',
      amount: 'Tutar {{add}}',
      piece: 'Adet',
      orderStatus: 'Talimat Durumu',
      orderCreatedDate: 'Talimat Oluşturma Tarihi {{add}}',
      invoiceNumber: 'Fatura Numarası',
      editCreatingOrders: 'Talimat Düzenle',
      newCreatingOrders: 'Yeni Talimat Hazırla',
      downloadExampleFile: 'Örnek Dosya İndir',
      bankName: 'Banka Adı',
      dealerName: 'Bayi Adı',
      currency: 'Para Birimi',
      invoiceDate: 'Fatura Tarihi {{add}}',
      expiryDate: 'Vade Tarihi {{add}}',
      paymentDate: 'Ödeme Tarihi {{add}}',
      invoiceStatusSourceCode: 'Fatura Durumu',
      invoiceNo: 'Fatura Numarası',
      invoiceAmount: 'Fatura Tutarı',
      description: 'Açıklama',
      sendingAccount: 'Gönderen Hesap',
      monthlyPayments: 'Aylık Ödemeler',
      manuelPayment: 'Manuel Ödeme Kaydı',
      filePayment: 'Dosya İle Ödeme Kaydı',
    },
    posrapor: {
      sales: 'Satış',
      dynamicPropertyHeader: 'Dinamik Başlıklar',
      headerColumns: 'Sabit Başlıklar',
      definition: 'Tanım',
      merchantName: 'Üye İşyeri Adı',
      fileFormat: 'Dosya Formatı',
      refund: 'İade',
      creditDebitCardUsageRate: 'Kredi/Debit Kart Kullanım Oranı',
      creditCardBasedTransactions: 'Kart Programı Bazlı Yapılan İşlemler',
      installment: 'Taksit',
      inAdvance: 'Peşin',
      posKind: 'Pos Türü',
      cardHarmony: 'Kart Uyumu',
      physical: 'Fiziki',
      virtual: 'Sanal',
      posBank: 'Pos Bank',
      cardBank: 'Kart Bankası',
      isOnUs: 'Bank Onus',
      cardType: 'Kart Tipi',
      cardKind: 'Kart Türü',
      cardProgram: 'Kart Programı',
      paymentStatus: 'Ödeme Durumu',
      PureAmount: 'Brüt Tutar {{add}}',
      transactionTime: 'İşlem Saati',
      posRaporAccountIsActive: "Pos hesabı aktif mi?",
      accountName: "Hesap Adı",
      vendorType: 'Bayi Tipi / Türü',
      currentAccountCode: 'Cari Hesap Adı / Kodu',
      posType: 'Pos Tipi / Türü',
      referenceNo: 'Referans Numarası',
      virtualPosId: 'Pos No',
      merchantId: 'İşyeri No (Merchant Id)',
      terminalId: 'Terminal No (Terminal Id)',
      branch: 'Şube Adı (Branch)',
      editPosList: 'Pos Düzenle',
      posNo: 'Pos Numarası',
      transactionDate: "İşlem Tarihi {{add}}",
      searchByNumbers: 'Numarayla Ara',
      accountType: 'Hesap Tipi',
      accountNumber: 'Hesap Numarası',
      currencyType: 'Para Birimi',
      iban: 'IBAN',
      erpPaymentSetCode: 'ÖS',
      erpPosCode: 'Pos Kodu',
      erpTenantCode: 'Bayi Kodu ',
      id: 'Id',
      newParameters: 'Yeni Parametre Ekle',
      editParameters: 'Parametre Düzenle',
      bankName: 'Banka Adı',
      serviceTypes: 'Servis Tipi',
      ftp: 'FTP',
      sftp: 'SFTP',
      ws: 'WS',
      path: 'Path',
      downloadPath: 'Download Path',
      filePattern: 'File Pattern',
      downloadFilePattern: 'Download File Pattern',
      host: 'Host',
      port: 'Port',
      url: 'Url',
      ftpUser: 'Kullanıcı Adı',
      password: 'Şifre',
      spaceMapping: 'Space Mapping',
      userName: 'Kullanıcı Adı',
      merchant: 'Üye İşyeri No',
      terminalNo: 'Terminal Numarası',
      provisionCode: 'Provizyon Numarası',
      netAmount: 'Net Tutar {{add}}',
      pureAmount: 'Çekilen Tutar  {{add}}',
      installmentCount: 'Taksit Sayısı',
      transactionNo: 'İşlem Numarası',
      merchantOrTerminal: 'Üye İşyeri / Terminal No',
      detailReportLine: 'Kart Hareketi',
      serviceCommisionRate: 'Komisyon Oranı {{add}}',
      commisionAmount: 'Komisyon Tutarı {{add}}',
      cardNumber: 'Kart Numarası',
      pos: 'Pos'
    },
    route: {
      netekstre: {
        matchingConditions: "Eşleşme Koşulları",
        balances: 'Bakiyeler',
        dynamicFields: 'Dinamik Alanlar',
        fieldDefinitions: 'Alan Tanımları',
        generalRules: 'Ortak Kurallar',
        customRules: 'Özel Kurallar',
        notifications: 'Bildirimler',
        transactions: 'Hesap Hareketleri',
        balancesReports: 'Banka Devir Raporu',
        averageReports: 'Ortalama Banka Raporu'
      },
      kredi: {
        applications: "Başvurularım"
      },
      tos: {
        paymentList: 'Ödeme Listeleme',
        recipientAccounts: 'Alıcı Hesaplar',
        instructionList: 'Ödeme Listeleme',
        instructionLists: "Ödeme Listesi",
        parameters: "Parametreler",
        customerInfos: 'Bayi Bilgileri',
        createPayment: 'Ödeme Hazırla',
        reportings: 'Raporlama',
      },
      admin: {
        packages: 'Paketler',
        tenants: "Firmalar",
        licenseStatus: "İstatistik Raporu",
        voucherTypes: 'Fiş Tipleri',
        erpTypes: "ERP Tipleri",
        generalCategories: 'Ortak Kategoriler',
        typeCodes: "Tip Kodları",
        rules: "Kurallar",
        tenantLicenseStatus: "Hesap İstatistik Raporu",
        jobs: "Job Yönetimi"
      },
      dbs: {
        orderDetail: 'Fatura Detayı',
        customerInfos: 'Bayi Bilgileri',
        creatingInstructions: 'Talimat Hazırla',
        instructionLists: 'Talimat Listeleme',
        instructionList: 'Talimat Listeleme',
        parameters: 'Parametreler',
        reportings: 'Raporlama',
      },
      posrapor: {
        parameters: 'Parametreler',
        posLists: 'Pos Listesi',
        accounts: 'Hesaplar',
        reportLines: 'Kart Hareketleri',
        reportLineDetails: 'Kart Hareketleri Detay',
        reporting: 'Raporlama',
        reportDetail: 'Detay Rapor',
        bankFileType: 'Posrapor Dosya Formatları'
      },
      common: {
        bankAccounts: 'Banka Hesapları',
        currentAccounts: 'Cari Hesaplar',
        tenantBanks: 'Bankalar',
        roles: 'Roller',
        users: 'Kullanıcılar',
        customers: 'Müşteriler'
      },
      netahsilat: {
        netlinkTransactions: 'NetLink Ödemeler',
        poses: 'Pos Listeleme',
        payments: 'Ödemeler'
      }
    },
    descriptions: {
      willBeAvailableSoonModal: {
        dbs: `
          <p>Doğrudan Borçlandırma Sistemi ile geniş bayi/müşteri ağı bulunan firmaların, bayi ya da müşterilerine yapacakları mal ve hizmet satışlarına yönelik fatura tahsilatlarının otomatik olarak  gerçekleştirilmesini sağlanarak nakit yönetim hizmeti oluşturulmaktır. </p>
          <p>DBS sistemi ile firmanın bayi yada müşterilerine yaptığı satışlardan doğan alacakların, hızlı ve garantili tahsilatı sağlanmış olacaktır. Doğrudan Borçlandırma Sistemi’nde ana firmanın bankaya ilettiği tutarlar, belirtilen vade sonunda bayi/müşteri hesabındaki bakiyeden, bakiye müsait değilse tesis edilmiş kredi limitinden otomatik olarak çekilerek ana firma hesaplarına aktarılır.</p>
          <p>Havale/Çek/Senet/Teminat Mektubu takibinin yarattığı maliyet ve risk azalır. Kredili DBS’de Banka tarafından bayilere sağlanan kredi imkânı ile firmanın alacak tahsilat riski sıfırlanırken, bayi de kısa vadeli finansman ihtiyacını karşılar. Türk Lirası ve/veya tüm çevrilebilir döviz cinslerindeki faturaların tahsilatı sağlanır. Bayi teminat takibi ve maliyeti azalır, tahsilat raporları ile bayi risk takibi kolaylaşır. Otomasyon sayesinde operasyonel maliyetler düşer, operasyonel iş yükü azalır. </p>
        `,
        posrapor: `
          <p>Posrapor işletme ve kurumların pos makineleri veya sanal poslarıyla yaptıkları tahsilat işlemlerinin banka hareketlerine dair detaylı bilgi verir.</p>
          <p>Posrapor direkt olarak banka hesabınızla entegre çalışmakta olup İşlem tutarı, komisyon tutarı, komisyon oranı, taksit sayısı ve tutarları, valör tarihi, tutarları ve diğer verileri banka veri tabanından çekerek size tek platform üzerinden görüntüleme/raporlama imkanı sunar.</p>
          <p>Kredi kartı işlemlerinin takibi, raporlaması, ERP/muhasebe sistemlerine kaydı ve denetimi çeşitli zorluklar içermektedir. Özellikle de birden fazla banka ve aynı anda pos makinesi ve sanal pos kullanan işletmeler için bu zorluklar daha fazladır. Posrapor bu zorlukların tamamını ortadan kaldıran, kredi kartı tahsilatlarının tutar, taksit sayısı, komisyon oranı, net tutar, komisyon tutarı, valörü (hesaba geçiş tarihi) ve diğer bilgileriyle birlikte muhasebe sistemine otomatik olarak aktarılmasını sağlayan bir çözümdür.</p>
          <p>Tüm pos ve sanal pos işlemlerinin tek ekrandan takip edilebildiği ve otomatik olarak ERP/muhasebe sistemine aktarıldığı Posrapor ile yönetim ve denetim kolaylığının yanı sıra hata olasılığı da ortadan kalkmaktadır.</p>
        `,
        netekstre: `
          <p>Netekstre, tüm hesap hareketlerinizi bankalardan online olarak alır ve tek panel üzerinde tüm hareketleri görüntülemenizi sağlar.  Toplanan tüm hareketler ortak bir veri yapısına çevrilir ve web servis üzerinden ERP sistemlerine (SAP, Oracle, Microsoft Dynamics, Logo, Netsis, Mikro, Eta vb.) aktarılır.</p>
          <p>Netekstre ile tüm banka hareketleri tek panel üzerinde izlenebilir, panel üzerinden özel raporlar oluşturulup export edilebilir, anlık ve periyodik olarak sms ve email ile raporlar alınabilir.</p>
          <p>Tüm işlemlerin ortak bir veri yapısına dönüştürülüp hızlıca ERP/muhasebe sistemlerine aktarılması, firmalara zaman ve iş gücü bakımından büyük kazanç sağlamaktadır.</p>
        `,
        kredi: `
          <p><b>Kredi</b> modülü için tanıtım lansmanı henüz yapılmamıştır. Çok yakında hizmetinize sunulmak üzere burada yerini alacaktır.</p>
          <p>Detaylı bilgi kısa süre içerisinde erişilebilir olacaktır.</p>
          <p><b>İlginiz ve sabrınız için teşekkürler.</b></p>
        `,
        tos: `
          <p><b>Tös</b> modülü için tanıtım lansmanı henüz yapılmamıştır. Çok yakında hizmetinize sunulmak üzere burada yerini alacaktır.</p>
          <p>Detaylı bilgi kısa süre içerisinde erişilebilir olacaktır.</p>
          <p><b>İlginiz ve sabrınız için teşekkürler.</b></p>
        `,
        cek: `
          <p><b>Çek/senet</b> modülü için tanıtım lansmanı henüz yapılmamıştır. Çok yakında hizmetinize sunulmak üzere burada yerini alacaktır.</p>
          <p>Detaylı bilgi kısa süre içerisinde erişilebilir olacaktır.</p>
          <p><b>İlginiz ve sabrınız için teşekkürler.</b></p>
        `
      },
      installmentInfo: {
        plusInstallment: 'Bankalar ile yapılan anlaşmalardan gelen artı taksidi ifade eder.',
        paymentDeferral: 'Bankalar ile yapılan anlaşmalardan gelen taksit ertelemeyi ifade eder.'
      },
      customerDetail: 'Lütfen müşterilerinizin tüm bilgilerini giriniz. Ekleme işlemi sonrası kullanıcıya şifre oluşturma linki gönderilecektir.'
    },
    attentions: {
      companyDocuments: "Şirketinizin ticari faaliyetine ilişkin evraklarınızı görüntüleyebilir ya da düzenleyebilirsiniz.",
      documentsWillBeConfirmedECozum: "Sisteme yüklediğiniz tüm evraklar Eçözüm tarafından onaylandıktan sonra uygulama kullanımınız aktifleşir.",
      uploadableDocTypes: "Sisteme sadece PDF, DOC, DOCX, JPG, JPEG veya PNG formatında evrak yükleyebilirsiniz.",
      docsAvailableAfterConfirmation: "Tüm evraklarınız sistemde onaylandıktan sonra firmanız aktifleştirilecektir.",

      commercialRegistryNewspaper: {
        attention1: 'Mevcut Ticari Sicil Gazetenizi, şirketinize ait sayfanın/sayfaların tamamı gözükecek şekilde taratarak ya da fotoğrafını çekerek bu alana yükleyiniz.',
        attention2: 'Ticari Sicil Gazetenize, e-devlet üzerinden "Türkiye Ticaret Sicili Gazetesi" alanındaki ilgili linke tıklayarak ulaşabilirsiniz.',
        attention3: 'Ticari sicil gazetesi ile vergi levhasındaki adres ve ünvanlar aynı olmalıdır.',
      },

      taxBoard: {
        attention1: 'Güncel son hali yüklenmelidir.',
        attention2: 'Vergi levhanıza, e-devlet üzerindeki "Vergi Levhası Sorgulama" alanından ulaşabilirsiniz.'
      },

      signatureCirculars: {
        attention1: "Geçerlilik süresi (varsa) dolmamış olmalıdır.",
        attention2: "Sözleşmelerde kullandığınız tüm imzalar imza sirkülerinizdeki imzalarla uyuşmalıdır.",
        attention3: "Firmanız için vekil tayin ettiğiniz kişilerin imzası kullanılacaksa ayrıca bu kişiler için de imza sirküleri ve vekaletnamesi birlikte yüklenmelidir.",
        attention4: "Kvkk kapsamında Kimlik belgenizi imza sirkülerinizle birlikte yüklememenizi rica ederiz.Yüklemeniz durumunda reddedilecektir.",
        attention5: "İmza sirküleriniz/beyannameniz yoksa en yakın notere giderek temin edebilirsiniz.",
        attention6: "Şahıs şirketiyseniz adınıza ait imza beyannamesi yükleyebilirsiniz.",
        attention7: "Herhangi bir kişiye vekalet vermeniz durumunda; imza yetkilisine ait imza sirküleri/beyannamesi, vekaletname ve vekil kişiye ait imza sirkülerinin/beyannamesinin bu alana yüklenmesi gerekmektedir.",
      },

      identityCard: {
        attention1: "Kimliğinizin ön ve arka yüzü yüklenmelidir."
      }
    },
    const: {
      pluralSuffix: 'lar',
    },
    ...devMessages.tr
  }
};
