import React, { useState } from 'react'
import i18n from 'plugins/i18n';
import { Typography, FormElements, FadeIn } from 'components/UIComponents';
import { Row, Col, Form, Statistic, Modal, Button } from 'antd';
import RemainingIcon from 'assets/img/remaining-time.svg';
import ReactSVG from 'react-svg';
import moment from 'moment';
import { SMSVerificationModel } from 'models';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import { httpMethods } from 'lib/constants';
import actions from 'store/redux/netekstre';

const SmsVerification = ({ form, setPage, onChange, verifyOtp, resendOtp }) => {
  const { getFieldDecorator } = form;
  const [otp, setOtp] = useState(null);
  const [countdown, setCountdown] = useState(120);
  const [saving, setSaving] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      if (!error) {
        setSaving(true);
        const data = new SMSVerificationModel({ otp, memberId: JSON.parse(localStorage.getItem("user")).memberId });
        verifyOtp(data, ({ errors, ott }) => {
          if (!(errors && errors.length > 0)) {
            localStorage.setItem("ott", ott);
            onChange('+')
          }
          setSaving(false);
        })
      }
    })
  }

  const onFinish = () => {
    setShowResend(true);
    setCountdown(0);
  }

  const onResend = () => {
    const memberId = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).memberId : null;
    resendOtp(memberId, ({ errors }) => {
      if (!(errors && errors.length > 0)) {
        Modal.info({
          title: i18n.t('msg.resent'),
          content: i18n.t('msg.resentCodeSuccessfully'),
          onOk: () => { setCountdown(120); setShowResend(false) },
          okText: i18n.t('btn.ok'),
          centered: true
        })
      }
    })
  }

  return (
    <FadeIn>
      <Row className="text-center mt-80">
        <Form onSubmit={onSubmit}>
          <Col xs={24} md={24}>
            <Typography.P size="normal" color="#474747">
              {i18n.t('lbl.pleaseEnterVerificationCode')}
            </Typography.P>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 9 }} className="mb-35 mt-35">
            <Form.Item>
              {
                getFieldDecorator('otp', {
                  rules: [{ required: true, message: i18n.t('msg.required') }],
                  initialValue: otp
                })(
                  <FormElements.Verification
                    {...{
                      onChange: (value) => setOtp(value),
                    }}
                  />
                )}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 9 }}>
            <Row >
              <Col xs={24} md={16} className="text-left">
                <div className="remaining-time">
                  <Typography.Label size="normal" color="#474747">
                    {i18n.t('lbl.remainingTime')}
                  </Typography.Label>
                  <ReactSVG src={RemainingIcon} className="remaining-time__icon" />
                  <Statistic.Countdown value={moment().add("seconds", countdown)} format="mm:ss" onFinish={onFinish} />
                </div>
              </Col>
              <Col xs={24} md={8} className="text-right">
                {
                  showResend &&
                  <Typography.Label size="normal" color="#474747" className="pointer" onClick={onResend}>
                    {i18n.t('lbl.resend')}
                  </Typography.Label>
                }
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={24} className="mt-40">
            <Row gutter={44}>
              <Col xs={24} md={12} className="text-right">
                <Button ghost type="default" htmlType="button" size="large" onClick={() => setPage('GetInfo')}>
                  {i18n.t('btn.cancel')}
                </Button>
              </Col>
              <Col xs={24} md={12} className="text-left">
                <Button type="primary" htmlType="submit" size="large" loading={saving}>
                  {i18n.t('btn.continue')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Row>
    </FadeIn>
  )
}

const mapDispatchToProps = (dispatch) => ({
  verifyOtp: (data, callback) => dispatch(actions.globalRequest({ data, url: endpoints.auth.verifyOtp, notify: false }, httpMethods.post, callback)),
  resendOtp: (id, callback) => dispatch(actions.globalRequest({ url: endpoints.auth.resendotp(id),  notify: false }, httpMethods.post, callback))
});
export default connect(null, mapDispatchToProps)(Form.create()(SmsVerification));
