import React, { Component } from 'react';
import { Input, Tooltip, Icon } from 'antd';
import { formatExpirationDate, formatCVC, formatCreditCardNumber } from 'helpers';

class NBInput extends Component {
  state = { isFocused: '' }

  handleChange = (e, event) => {
    const { type, onChange, maxLength } = this.props;

    switch (type) {
      case 'iban':
        if (e.length < 27) onChange && onChange(e, event);
        break;

      case 'phone':
        if (e.length < 12) onChange && onChange(e, event);
        break;

      case 'amount':
        let change = false;
        e = e.includes('.') ? e.split('.').join('') : e;
        //eslint-disable-next-line
        var isNumber = /^[-+]?\d+[\.\,]?\d?\d?$/;
        if (e === "-" || e === "+")
          change = true;
        else if (e[0] === 0 && !e[1])
          change = false;
        else if ((isNumber.test(e) || e.length === 0))
          change = true;
        change && onChange && onChange(e, event);

        break;

      default:
        if (!maxLength || (maxLength && e.length < maxLength + 1))
          onChange && onChange(e, event);

    }
  };

  formatValue = (type, value) => {
    switch (type) {
      case 'iban':
        return value.replace(/(.{4})/g, '$1 ').trim();
      case 'stringNumber':
        return value.replace(/[^0-9]/, '');
      case 'phone':
        value = value ? value.toString() : '';
        return value.length < 2 ? '0' : (value.substr(0, 1) !== '0' ? '0' : '') + value.replace(/[^0-9]/, '');
      case 'amount':
        let _v;
        let _sign = "";
        if(value.toString()[0] === '-' || value.toString()[0] === '+') {
          _sign = value.toString().substr(0,1);
          value = value.toString().slice(1);
        } 
        _v = value ? parseFloat(value?.toString()?.replace(',', '.'))?.toString()?.replace('.', ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")?.split(',') : value;
        _v = (_v || _sign) && `${_sign}${_v?.[0] || ''}${value?.toString()?.includes(',') ? ',' : ''}${(_v[1] && _v[1] !== '00') ? _v[1] : ''}`
        return _v;
      case 'cc-expiry':
        return formatExpirationDate(value);
      case 'cc-number':
        return formatCreditCardNumber(value);
      case 'cc-cvc':
        return formatCVC(value);
      default:
        return value;
    }
  };

  setMaxLength = () => {
    const { type, maxLength } = this.props
    switch (type) {
      case 'cc-number': return 19;
      case 'cc-expiry': return 5;
      case 'cc-cvc': return 3;
      default: return maxLength || 512;
    }
  }

  setMinLength = () => {
    const { type, minLength } = this.props
    switch (type) {
      case 'cc-number': return 19;
      case 'cc-expiry': return 5;
      case 'cc-cvc': return 3;
      default: return minLength || null;
    }
  }

  render() {
    const { label, type, value, className, id, disabled, required, autoFocus, info, step, max, min, suffix, prefix, onClick, onBlur, onFocus, placeholder, nextGen, forceFocus, pattern, mask } = this.props;
    const { isFocused } = this.state;
    const hasValue = !!(this.formatValue(type, value) && this.formatValue(type, value).toString() !== '');

    return (
      <div className={`nb nb-input ${!!disabled ? 'disabled' : ''} ${nextGen ? 'nextGen' : ''}`}>
        <Input
          ref={el => this[id] = el}
          id={id ? id.toString() : undefined}
          type={type}
          value={this.formatValue(type, value)}
          onChange={(e) => this.handleChange(type === 'iban' ? e.target.value.replace(/ /g, '').toUpperCase() : e.target.value, e)}
          required={!!required}
          disabled={!!disabled}
          onFocus={() => this.setState({ isFocused: true }, () => onFocus && onFocus(true || hasValue))}
          onBlur={() => this.setState({ isFocused: false }, () => onBlur && onBlur(false || hasValue))}
          autoFocus={!!autoFocus}
          maxLength={this.setMaxLength()}
          minLength={this.setMinLength()}
          className={`${value !== null && value !== undefined && value.toString() !== '' && 'is-filled'} ${className || ''} ${isFocused ? 'focused-input' : ''} ${mask ? 'masked' : ''}`}
          step={step}
          max={max}
          min={min}
          suffix={suffix}
          prefix={prefix}
          onClick={onClick}
          placeholder={placeholder}
          pattern={pattern}
        />
        {info &&
          <Tooltip title={info}>
            <Icon type="question-circle" className="form-tooltip" />
          </Tooltip>
        }
        <label className={forceFocus || (!forceFocus && (isFocused || hasValue)) ? 'focused' : ''}>{label || ''}</label>
      </div>
    );
  };
};

export default NBInput;