import React, { Component } from 'react';
import Tenants from '../Tenants/List';
import NTEGeneralRules from 'components/Admin/Views/Definitions/GeneralRules/List'
import NTECustomRules from 'components/Modules/NetEkstre/Definitions/RuleDefinitions/Rules/CustomRules/List'
import i18n from 'plugins/i18n';
import { CheckAccessRight } from 'components/UIComponents';

class GeneralRules extends Component {
  state = {
    selectedTenant: null
  }

  setSelectedTenant = tenant => {
    this.setState({ selectedTenant: tenant })
  }

  render() {
    const { selectedTenant } = this.state;
    return (
      <CheckAccessRight {...{ stateKey: "admin.companies" }} withTag="div">
        <Tenants
          datatableTitle={selectedTenant ? selectedTenant.name : i18n.t('lbl.pleaseSelectTenant')}
          hasParent
          onTenantSelect={this.setSelectedTenant}
          datatableCollapsed={selectedTenant}
        />

        <NTEGeneralRules
          showFilter={false}
          className="mt-60"
          datatableTitle={selectedTenant ? i18n.t('lbl.generalRulesForTenant', { tenant: selectedTenant.name }) : i18n.t('lbl.generalRules')}
          getDataTrigger={selectedTenant}
          datatableCollapsed={!selectedTenant}
          callback={this.customRules && this.customRules.getRules}
          hasParent
        />

        <NTECustomRules
          ref={el => this.customRules = el}
          showFilter={false}
          className="mt-60"
          datatableTitle={selectedTenant ? i18n.t('lbl.customRulesForTenant', { tenant: selectedTenant.name }) : null}
          getDataTrigger={selectedTenant}
          datatableCollapsed={!selectedTenant}
        />
      </CheckAccessRight>
    );
  };
};

export default GeneralRules;