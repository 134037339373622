import React, { useState } from 'react'
import { SmsVerification, GetInfo } from "./SingatoryInfo/";

const SignatoryInfo = ({ onChange }) => {
  const [page, setPage] = useState("GetInfo");
  const pages = {
    GetInfo: GetInfo,
    SmsVerification: SmsVerification
  }
  const Component = pages[page];
  
  return (
    <Component {...{ setPage, onChange }} />
  )
}

export default SignatoryInfo;
