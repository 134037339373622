import React from 'react';
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/posrapor';
import { get } from 'lodash'


export default class PosraporUtils {

  static getBankName({ data = null, dataField = 'bankEftCode', listKeys = 'banks', listField = 'eftCode', bankData = null, extra = null }) {
    let list, bank;
    if (!bankData) {
      list = get(store.getState().posrapor.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name} {extra}</> : '';
  }

  static getTenantBanks() {
    dispatchers.getTenantBanks({ orderBy: ['name asc'] });
  }

  static getBanks(parameters) {
    dispatchers.getBanks({ orderBy: ['name asc'] }, parameters);
  }

  static getCurrencies() {
    dispatchers.getCurrencies({ orderBy: ['name asc'] });
  }

  static getCompanyNames() {
    dispatchers.getCompanyNames({ orderBy: ['name asc'] });
  }

  static getTenantBankAccounts() {
    dispatchers.getTenantBankAccounts({ orderBy: ['name asc'] });
  }

  static getCreditCardTypes() {
    dispatchers.getCreditCardTypes({ orderBy: ['name asc'] });
  }

  static getCreditCardPrograms() {
    dispatchers.getCreditCardPrograms({ orderBy: ['name asc'] });
  }

  static getCreditCardOrganizationTypes() {
    dispatchers.getCreditCardOrganizationTypes({ orderBy: ['name asc'] });
  }

  static getCreditCardBanks() {
    dispatchers.getCreditCardBanks({ orderBy: ['name asc'] });
  }

  static getBankFileTypes() {
    dispatchers.getBankFileTypes({ orderBy: ['description asc'] });
  }

  static getBankFileTypeLookup() {
    dispatchers.getBankFileTypeLookup();
  }

  static getBankFileTypeName({ data = null, dataField = 'bankFileTypeId', listKeys = 'bankFileTypes', listField = 'id', bankData = null, extra = null }) {
    let list, bank;
    if (!bankData) {
      list = get(store.getState().posrapor.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <>{bank.description} {extra}</> : '';
  }
}


const dispatchers = {
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.posrapor.tenantBanks, key: 'tenantBanks' })),
  getBanks: (filter, parameters) => store.dispatch(actions.getFilter({ filter, url: endpoints.posrapor.banks, key: 'banks', parameters })),
  getCurrencies: () => store.dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies' })),
  getCompanyNames: () => store.dispatch(actions.getFilter({ url: endpoints.posrapor.companyNames, key: 'companyNames' })),
  getTenantBankAccounts: () => store.dispatch(actions.getFilter({ url: endpoints.posrapor.tenantBankAccounts, key: 'tenantBankAccounts' })),
  getCreditCardTypes: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.creditCardTypes, key: 'creditCardTypes' })),
  getCreditCardPrograms: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.creditCardPrograms, key: 'creditCardPrograms' })),
  getCreditCardOrganizationTypes: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.creditCardOrganizationTypes, key: 'creditCardOrganizationTypes' })),
  getCreditCardBanks: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.creditCardBanks, key: 'creditCardBanks' })),
  getBankFileTypes: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.bankFileTypes, key: 'bankFileTypes' })),
  getBankFileTypeLookup: (filter, parameters) => store.dispatch(actions.getFilter({ filter, url: endpoints.posrapor.bankFileTypeLookup, key: 'bankFileTypes', parameters })),
}
