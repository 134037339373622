import common from './common';
import dbs from './dbs';
import posrapor from './posrapor';
import netekstre from './netekstre';
import tos from './tos';
import cek from './cek';
import admin from './admin';
import netahsilat from './netahsilat';

export default class utils {
  static posrapor = posrapor;
  static dbs = dbs;
  static common = common;
  static netekstre = netekstre;
  static tos = tos;
  static cek = cek;
  static admin = admin;
  static netahsilat = netahsilat;
}