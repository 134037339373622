import i18n from 'plugins/i18n';

const stateKeys = [
  "datatableInline",

  "gridSettings",
  "currentFilter",
  "orderStatus",
  "selectedItems",
  "customers",
  "tenantExcelFormats",
  "homeDetailsNetekstre",
  "homeDetailsPosrapor",
  "homeDetailsDbs",
  "homeDetailsTos",
  "generalParameters",
  "bankAccounts",
  "currentAccounts",
  "tenantBanks",
  "roles",
  "users",
  "currencies",
  "fixedExChangeTenantCurrencies",
  "currencyRates",
  "alerts",
  "alertsPage",
  "tenants",
  "documents",
  "customers"
]

const filterKeys = [
  "orderOperation",
  "currentFilter",
  "orderStatus",
  "invoiceStatus",
  "currencies",
  "bankAccounts",
  "tenantBanks",
  "lookupTenantBanks",
  "roles",
  "users",
  "tenantExchangeType",
  "currentAccountSelectedItems",
  "currentAccounts",
  "cities",
  "districts",
  "tenantModules",
  "modules",
  "parentTenantModules",
  "customers",
  "tenantBankModules"
]


const state = {
  currentFilter: {},
  currentModule: null,
  i18nextLng: i18n.language,
  dataTableSelectedItems: [],
  multiTenantBalances: false,
  multiTenantDashboard: false,
  cumulativeBalances: false,
  cumulativeDashboard: false,
  includedInternalTransfer: false,
  hasAccessRightToUpdateOrDeleteCurrentPage: false,
  filter: {},
  blur: false
}

stateKeys.forEach(key => {
  state[key] = {
    list: { data: [], loading: null, count: null, error: null, statusCode: null },
    single: { data: null, saving: null, loading: null, error: null, statusCode: null }
  }
})

filterKeys.forEach(key => {
  state.filter[key] = {
    data: [], error: null, loading: null
  }
})

export default state;