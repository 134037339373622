import React, { useEffect, useState } from 'react';
import Content from './Content';
import Header from './Header';
import Sider from './Sider';
import MobileHeader from './MobileHeader';
import { Layout as AntLayout } from "antd";
import { connect } from "react-redux";
import authActions from 'store/actions/auth';
import commonActions from 'store/actions/common';
import endpoints from 'config/endpoints';
import { useLocation } from 'react-router-dom';
import { moduleTypesDefinitionsEnum } from 'lib/constants';

const Layout = ({ getAccount, getGridSettings, gridLoading, initialized, auth, setModule }) => {
  const [visible, setVisible] = useState(false);
  const currentModule = useLocation().pathname.substr(1).split('/')[0];
  const hasModule = auth.data ? !!auth.data.modules.find(x => x.name === moduleTypesDefinitionsEnum[currentModule]) : true

  useEffect(() => { if (!hasModule) window.location.replace('/home') }, [hasModule])

  useEffect(() => {
    getAccount((response) => {
      let expirationTime = response.systemParameters ? JSON.parse(response.systemParameters).TokenExpirationTime : null;
      localStorage.setItem("expirationTime", expirationTime);
      getGridSettings({ filter: { userId: { type: 'guid', value: response.userId } } });
    });
  }, [getAccount, getGridSettings])

  useEffect(() => {
    setModule(currentModule);
  }, [setModule, currentModule])

  return (
    <AntLayout>
      <Sider {...{ visible, setVisible }} />
      <AntLayout>
        <Header loading={auth.fetching || !initialized} />
        <MobileHeader {...{ visible, setVisible }} />
        <Content loading={auth.fetching || !initialized} />
      </AntLayout>
    </AntLayout>
  );
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  gridLoading: common.gridSettings.list.loading,
  initialized: common.gridSettings.list.initialized
});
const mapDispatchToProps = (dispatch) => ({
  getAccount: (callback) => dispatch(authActions.getAccount(callback)),
  getGridSettings: (filter) => dispatch(commonActions.getAll({ url: endpoints.tenant.gridSettings, key: 'gridSettings', filter })),
  setModule: (moduleName) => dispatch(commonActions.setModule(moduleName))
})
export default connect(mapStateToProps, mapDispatchToProps)(Layout);