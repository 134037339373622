import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, notification, Row, Col, Button, Tooltip } from 'antd';
import { Input } from 'components/UIComponents/NetbankaComponents';
import { DataTable } from 'components/UIComponents';
import actions from 'store/actions/netekstre';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import config from "config";

class MailModal extends Component {
  state = { visible: false, loading: false, entityIds: [], contacts: [] };

  open = entityId => {
    const { entityIds } = this.state;
    entityIds.push(entityId);
    this.setState({ visible: true, entityIds });
  }

  close = () => {
    this.props.form.resetFields();
    this.setState({ visible: false, entityIds: [], contacts: [] });
  };

  submit = () => {
    const { contacts, entityIds } = this.state;
    if (contacts.length > 0) {
      this.setState({ loading: true, });
      const data = { entityIds, contacts }
      this.props.sendEmail(data, (response) => {
        if (response) {
          this.setState({ loading: false }, () => {
            notification.success({ message: i18n.t('msg.successfullySent') });
            this.close();
          });
        }
      });
    }
    else 
      notification.error({ message: i18n.t('msg.addAtLeastOne') })
  }

  deleteMail = index => {
    const { contacts } = this.state;
    if (contacts.length > 0) {
      contacts.splice(index, 1)
      this.setState({ contacts })
    }
  }

  addMail = () => {
    const { contacts } = this.state;
    this.props.form.validateFields(error => {
      if (!error) {
        const contact = this.props.form.getFieldsValue();
        contacts.push(contact);
        this.setState({ contacts }, () => {
          this.props.form.resetFields()
        });
      };
    });
  }

  render() {
    const { visible, loading, contacts } = this.state;
    const { getFieldDecorator } = this.props.form;
    const contactsMailDisabled = contacts.length >= config.multiPersonEmailLimit;

    const columns = [
      { label: i18n.t('lbl.nameSurname'), key: 'fullName', sort: false },
      { label: i18n.t('lbl.email'), key: 'email', sort: false, },
      { checkField: false, render: (i) => <Button icon="delete" onClick={() => this.deleteMail(i)} /> },
    ];

    return (
      <Modal
        okButtonProps={{ className: '#save', icon: 'save', loading }}
        cancelButtonProps={{ className: '#cancel', icon: 'close', loading }}
        okText={i18n.t('btn.send')}
        title={i18n.t('lbl.sendAsMail')}
        visible={visible}
        centered
        onOk={this.submit}
        onCancel={this.close}
        confirmLoading={loading}
        destroyOnClose
        width={700}
      >
        <Form>
          <Row>
            <DataTable
              noHeader
              data={contacts}
              columns={columns}
              showNoData={true}
              toggle={false}
              loading={loading}
              className="full-width"
            />
          </Row>
          <Row gutter={10}>
            <Col xs={24} sm={10}>
              <Form.Item>
                {getFieldDecorator('fullName', {
                  rules: [{ required: true, message: i18n.t('msg.required') }]
                })(
                  <Input label={i18n.t('lbl.nameSurname')} disabled={contactsMailDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={10}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    { required: true, message: i18n.t('msg.required') },
                    { type: 'email', message: i18n.t('msg.emailInvalid') }
                  ]
                })(
                  <Input label={i18n.t('lbl.email')} disabled={contactsMailDisabled} />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              {
                contactsMailDisabled
                  ?
                  <Tooltip title={i18n.t("msg.multiPersonEmailLimitContent", { config: config.multiPersonEmailLimit })}>
                    <Button style={{ height: 38 }} icon="plus" disabled />
                  </Tooltip>
                  :
                  <Button style={{ height: 38 }} icon="plus" onClick={this.addMail} />
              }
            </Col>
          </Row>
        </Form>
      </Modal >
    );
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (data, callback) => dispatch(actions.post({ url: endpoints.nte.sendNotificationsSendNotificationAsPDFToMultiPerson, data }, callback))
});
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Form.create()(MailModal));
