import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { DataTable, Filter, ActionButtons, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import utils from 'lib';
import TenantBank from './Detail';
import queryString from 'query-string';
import { Modal } from 'antd';

const PageAccesses = {
  TenantBanks: "TenantBanks",
  AdminTenantBanks: "AdminTenantBanks"
}

class TenantBanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.common.TenantBanks(),
      getDataTrigger: undefined,
      access: PageAccesses.TenantBanks,
      queryStringData: (props.location && props.location.search) ? queryString.parse(props.location.search.substring(1).split('?').join('&')) : undefined
    };
  }

  componentDidMount() {
    const { queryStringData } = this.state;
    const { getDataTrigger, getLookupTenantBanks } = this.props;
    let access;

    if (queryStringData) this.showReturnMessageFromExternalLink(queryStringData)

    if (getDataTrigger === undefined || (getDataTrigger !== null)) {
      this.getTenantBanks();
      access = PageAccesses.TenantBanks;
      getLookupTenantBanks({ orderBy: ['name asc'] });
    }
    else {
      access = PageAccesses.AdminTenantBanks;
    }

    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id }
      const filter = state.filter
      props.getTenantBanks(filter, null, parameters, endpoints.tenant.adminTenantBanks);
      props.getLookupTenantBanks({ orderBy: ['name asc'] }, { TenantId: props.getDataTrigger.id });
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  };

  //For AnadolubankRedirect
  showReturnMessageFromExternalLink = queryStringData => {
    const { data, code, error } = queryStringData;
    const { callbackAnadoluBank } = this.props
    if (!error) {
      callbackAnadoluBank({ data, code }, () => {
        Modal.success({
          title: i18n.t('msg.refreshTokenSuccess'),
          content: i18n.t('msg.refreshTokenSuccessDesc'),
          okText: i18n.t('btn.ok')
        })
      })
    }
    else {
      Modal.error({
        title: i18n.t('msg.errorRefreshTokenForAnadolubank'),
        content: i18n.t('msg.bankReturnedCode') + error,
        okText: i18n.t('btn.ok')
      })
    }
  }

  getTenantBanks = () => {
    const { getDataTrigger } = this.props;
    const { filter } = this.state;
    if (getDataTrigger)
      this.props.getTenantBanks(filter, () => {
        this.props.getLookupTenantBanks({ orderBy: ['name asc'] }, { TenantId: getDataTrigger.id });
      }, { TenantId: getDataTrigger.id }, endpoints.tenant.adminTenantBanks);
    else
      this.props.getTenantBanks(filter, () => {
        this.props.getLookupTenantBanks({ orderBy: ['name asc'] });
      });
  };


  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getTenantBanks);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getTenantBanks);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getTenantBanks);
  }

  renderActionButtons = row => {
    const { access } = this.state;
    return <ActionButtons url={access} state="tenantBanks" editUrl item={row} hasDelete getDatas={this.getTenantBanks} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { tenantBanks, lookupTenantBanks, showFilter, hasParent, className, datatableTitle, getDataTrigger, datatableCollapsed } = this.props;
    const { access } = this.state
    let filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: lookupTenantBanks.data, value: 'id', name: (bankData) => utils.common.getBankName({ bankData }), keys: ['Id'], showAll: true, uniqueKey: '#bank', multiple: true, guid: true },
      { label: i18n.t('lbl.merchantCode'), type: 'input', keys: ['MerchantCode'], contains: true, uniqueKey: '#merchantCode' }
    ];
    let columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bank'), key: 'Bank/Name', render: ({ Bank }) => utils.common.getBankName({ data: Bank, listKeys: 'lookupTenantBanks', dataField: 'EftCode', listField: 'bankEftCode' }), checkField: false },
      { label: i18n.t('lbl.merchantCode'), key: 'MerchantCode' },
    ];

    return (
      <FadeIn className={`page-content ${className || ''}`}>
        <CheckAccessRight {...{ ...{ ...tenantBanks, single: tenantBanks.list }, hasParent: getDataTrigger !== undefined }} >
          {
            showFilter !== false &&
            <Filter filters={filters} onFilter={this.onFilter} />
          }
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            access={access}
            history={this.props.history}
            newButton="openDialog"
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={tenantBanks.list.data}
            loading={tenantBanks.list.loading}
            title={datatableTitle || i18n.t('route.common.tenantBanks')}
            getData={this.getTenantBanks}
            count={tenantBanks.list.count}
            Component={TenantBank}
            dialogTitle={i18n.t('lbl.tenantBank')}
            deleteOptions={{ stateKey: "tenantBanks", url: access, baseUrl: baseUrls.tenant }}
            hasParent={hasParent}
            disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
            collapsed={datatableCollapsed}
            dialogOptions={{
              endpoint: getDataTrigger && endpoints.tenant.adminTenantBanks,
              tenantId: getDataTrigger && getDataTrigger.id
            }}
          />
        </CheckAccessRight>
      </FadeIn >
    );
  };
};

const mapStateToProps = ({ common }) => ({
  lookupTenantBanks: common.filter.lookupTenantBanks,
  tenantBanks: common.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getTenantBanks: (filter, callback, parameters, endpoint) => dispatch(actions.getAll({ filter, parameters, url: endpoint || endpoints.tenant.tenantBanks, key: 'tenantBanks', isNewODataStructure: true }, callback)),
  getLookupTenantBanks: (filter, parameters) => dispatch(actions.getFilter({ filter, parameters, url: endpoints.lookups.tenantBanks, key: 'lookupTenantBanks' })),
  callbackAnadoluBank: (parameters, callback) => dispatch(actions.get({ parameters, url: endpoints.nte.anadoluBankCallback }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TenantBanks);