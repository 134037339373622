import React, { Component } from 'react';
import { ModuleCards } from "components/Home/Views/components";

class Home extends Component {
  render() {
    return (
      <div className="module-card__container">
        <ModuleCards />
      </div>
    );
  };
};

export default Home;