import React, { useState } from 'react'
import { Typography, FormElements } from '..'
import { List, Icon } from 'antd'

const SelectableList = ({ title = "", text = "", options = [], onChange, loading, disabled }) => {
  const [data, updateData] = useState([]);

  const onChecked = (id, checked) => {
    if (checked) data.push(id)
    else data.splice(data.indexOf(id), 1);
    updateData(data);
    onChange && onChange(data);
  }

  return (
    <div className="custom-selectable-list__wrapper">
      <Typography.H size={3}>
        {title}
      </Typography.H>
      <Typography.P>
        {text}
      </Typography.P>
      <List
        size="small"
        bordered
        loading={{ indicator: <Icon type="loading" />, spinning: loading  }}
        dataSource={options || []}
        renderItem={item =>
          <List.Item>
            <FormElements.CheckBox
              id={item.id}
              label={item.name}
              onChange={({ target }) => onChecked(item.id, target.checked)}
              disabled={disabled}
            />
          </List.Item>
        }
      />
    </div>
  )
}

export default SelectableList;
