import React, { Component } from 'react';
import { connect } from 'react-redux';
import cekActions from 'store/actions/cek';
import { DataTable, Filter, Instruction } from 'components/UIComponents';
import { defaultFilters } from "lib/constants";
import endpoints from 'config/endpoints';
import { formatDate, formatCurrency, isEditableOrderCek, isEditableCheques } from "helpers";
import { ChequeCreateOrSendOrderModel } from "models";
import { Tooltip, Icon, Modal } from "antd";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Reporting from './Detail';
import _ from 'lodash';

class Reportings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.cek.Reporting(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      chequeSendingOrCreating: false,
      startCreatePayroll: false,
      options: [],
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.options.length) return {
      options: _.cloneDeep(props.tenantBanks.data.map(x => {
        return { value: x.Code, label: x.Name, type: 'BankCode', isLeaf: false }
      }))
    }
    return null;
  }

  componentDidMount() {
    utils.cek.getTenantBanks('tenantBanks');
    utils.cek.getOperationStatus();
    this.getReporting();
  }

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getReporting = () => {
    let filter = _.cloneDeep(this.state.filter);
    if (this.state.startCreatePayroll) {
      const chequeStatus = this.props.operationStatuses.data.filter(({ OperationStatusTypeId }) => OperationStatusTypeId === 1);
      const _editableIds = chequeStatus.filter(x => isEditableCheques(x.Id))
      filter.filter.and ?
        filter.filter.and.push({ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] })
        :
        filter.filter.and = [{ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] }];
    }

    this.props.getReporting(filter, () => {
      this.getCheckListTotalAmount()
    }
    );
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getReporting);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getReporting);
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(0);
    this.setState({ filter }, this.getReporting);
  };

  onBankChange = (bankId, key) => {
    const { filter } = this.state;
    this.onFilter(bankId ? { [key]: { eq: bankId } } : delete filter.filter[key]);
    if (!bankId) this.datatable.checkAll(false);
    this.setState({ bankId })
  }

  onStartCreateOrder = () => {
    this.setState({ startCreatePayroll: !this.state.startCreatePayroll }, () =>
      this.getReporting()
    );
  }

  orderOperation = (data) => {
    const bankData = this.props.bankAccounts.data.find(x => x.AccountNumber === data.BankAccount[2])
    let _data = {
      Description: data.Description,
      SendDate: data.SendDate,
      Amount: data.Amount,
      TenantPaymentAccountId: bankData.Id,
      ChequesIdList: this.props.selectedItems
    }
    this.setState({ chequeSendingOrCreating: true })
    _data = new ChequeCreateOrSendOrderModel(_data)

    this.props.createPayroll(_data, (response) => {
      this.setState({ chequeSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 || response.statusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: (response && response.Message) ? response.Message : status === 'success' ? i18n.t('msg.success') : i18n.t('msg.error'),
          content: status === 'success' ? i18n.t(`msg.createPayrollResponse`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.setState({ totalAmount: null, totalCount: null }, () => {
              this.getReporting();
              utils.common.clearSelectedItems();
            })
          }
        })
      }
    })
  }

  getCheckListTotalAmount = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems, operationStatuses } = this.props;
    const chequeStatus = operationStatuses.data.filter(({ OperationStatusTypeId }) => OperationStatusTypeId === 1);
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll) {
      let filter = {};
      filter.filter = {
        ...this.state.filter.filter,
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }
      if (this.state.startCreatePayroll) {
        const _editableIds = chequeStatus.filter(x => isEditableOrderCek(x.Id))
        filter.filter.and ?
          filter.filter.and.push({ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] })
          :
          filter.filter.and = [{ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] }];
      }

      this.props.getCheckListTotalAmount(filter, response => {
        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(cheque =>
            _amount += cheque.Amount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  loadData = selectedOptions => {
    let targetOption = {};
    targetOption = selectedOptions[selectedOptions.length - 1];

    if (targetOption.type === "BankCode") {
      targetOption.loading = true;
      const filter = { filter: { 'TenantBank/Bank/EftCode': targetOption.value, BranchNumber: { ne: null } }, }
      utils.netekstre.getBankAccountsOData(filter, response => {
        if (response) {
          targetOption.children = [
            { value: Math.random(), label: i18n.t('lbl.branchName'), type: 'BranchNumber', disabled: true },
            ...response.value.map(x => ({ label: `${x.BranchName} (${x.BranchNumber})`, value: x.BranchNumber, type: 'BranchNumber', isLeaf: false }))
          ]
          targetOption.loading = false;
          this.setState({ options: [...this.state.options] });
        }
      })
    }

    if (targetOption.type === "BranchNumber") {
      targetOption.loading = true;
      const filter = {
        filter: {
          'TenantBank/Bank/EftCode': selectedOptions.find(x => x.type === "BankCode").value,
          BranchNumber: selectedOptions.find(x => x.type === "BranchNumber").value,
        },
      }
      utils.netekstre.getBankAccountsOData(filter, response => {
        targetOption.children = [
          { value: Math.random(), label: i18n.t('lbl.accountNumber'), type: 'AccountNumber', disabled: true },
          ...response.value.map(x => ({ label: `${x.AccountNumber} (${x.CurrencyCode})`, value: x.AccountNumber, type: 'AccountNumber' }))
        ]
        targetOption.loading = false;
        this.setState({ options: [...this.state.options] });
      })
    }
  }

  render() {
    const { reportings, tenantBanks, operationStatuses } = this.props;
    const { totalAmount, totalCount, totalsLoading, startCreatePayroll, chequeSendingOrCreating, options } = this.state;
    const _options = [{ value: Math.random(), label: i18n.t('lbl.bank'), type: 'BankCode', disabled: true }, ...options];
    const filters = [
      { label: i18n.t('lbl.bankName'), keys: ['BankCode'], multiple: true, showAll: true, type: 'select', options: tenantBanks.data, name: (bankData) => utils.cek.getBankName({ bankData }), value: "Code" },
      { label: i18n.t('lbl.payrollNumber'), type: 'input', keys: ['PayRollId'], uniqueKey: '#payrollNumber', contains: true },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Description', 'Payroll/Description', 'ChequeUserName', 'ChequeUserSurname', 'City', 'ChequeCode',], contains: true, uniqueKey: '#description' },
      { label: i18n.t('lbl.kesideDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['ChequeDate'], uniqueKey: '#chequeDateStart', allowClear: true },
      { label: i18n.t('lbl.kesideDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['ChequeDate'], uniqueKey: '#chequeDateEnd', allowClear: true },
      { label: i18n.t('lbl.createdDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['InsertedTime'], uniqueKey: '#insertedTimeStart', allowClear: true },
      { label: i18n.t('lbl.createdDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['InsertedTime'], uniqueKey: '#insertedTimeEnd', allowClear: true },
      { label: i18n.t('lbl.minAmount'), type: 'number', operator: 'ge', keys: ['Amount'], uniqueKey: '#minAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.maxAmount'), type: 'number', operator: 'le', keys: ['Amount'], uniqueKey: '#maxAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.chequeStatus'), type: 'select', search: true, options: operationStatuses.data.filter(({ OperationStatusTypeId }) => OperationStatusTypeId === 1), value: 'Id', name: 'Name', keys: ['OperationStatusId'], uniqueKey: '#chequeStatus', multiple: true },
      { label: i18n.t('lbl.orderStatus'), type: 'select', search: true, options: operationStatuses.data.filter(({ OperationStatusTypeId }) => OperationStatusTypeId === 2), value: 'Id', name: 'Name', keys: ['OperationStatusId'], uniqueKey: '#orderStatus', multiple: true },
    ];
    const columns = [
      { label: i18n.t('tos.bankName'), key: 'BankCode', render: (data) => utils.cek.getBankName({ data }) },
      { label: i18n.t('lbl.nameSurname'), key: 'ChequeUserName', render: ({ ChequeUserName, ChequeUserSurname }) => `${ChequeUserName} / ${ChequeUserSurname}` },
      { label: i18n.t('lbl.vknTckn'), key: 'ChequeUserTckn', render: ({ ChequeUserVkn, ChequeUserTckn }) => `${ChequeUserVkn || ''} ${ChequeUserTckn || ''}` },
      { label: i18n.t('lbl.titleU'), key: 'Title' },
      { label: i18n.t('lbl.chequeNumber'), key: 'ChequeCode' },
      { label: i18n.t('lbl.kesideCity'), key: 'City' },
      { label: i18n.t('lbl.kesideDate'), key: 'ChequeDate', render: ({ ChequeDate }) => formatDate(ChequeDate, null, null, null, false) },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: ({ Amount, CurrencyCode }) => formatCurrency({ data: Amount, withSign: false, currencyCode: CurrencyCode }) },
      { label: i18n.t('lbl.description'), key: 'Description' },
      { label: i18n.t('lbl.payrollNumber'), key: 'PayRollId' },
      { label: i18n.t('lbl.createdDate'), key: 'InsertedTime', render: ({ InsertedTime }) => formatDate(InsertedTime, null, null, null, false) },
      { label: i18n.t('lbl.orderStatus'), key: 'PayRoll/OperationStatus/Name', checkField: false },
      { label: i18n.t('lbl.orderDescription'), key: 'PayRoll/Description', checkField: false }
    ];

    const instructions = [
      {
        label: i18n.t('lbl.bank/branch/account'),
        key: "BankAccount",
        type: "cascade",
        options: _options,
        required: true,
        loadData: this.loadData,
      },
      { label: i18n.t('lbl.paymentDate'), key: 'SendDate', type: 'date', required: true },
      { label: i18n.t('lbl.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('lbl.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true },
      { label: i18n.t('tos.description'), key: 'Description', type: 'input' },
      { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: this.orderOperation, disabled: (reportings.count === 0 || chequeSendingOrCreating), access: 'TosOrder.Create' },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        {
          startCreatePayroll &&
          <Instruction {...{ instructions, loading: totalsLoading }} />
        }
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          count={reportings.list.count}
          access="tosOrder"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={reportings.list.data}
          loading={reportings.list.loading}
          excel="ExporttosInvoice"
          title={i18n.t('route.tos.reportings')}
          getData={this.getReporting}
          checkbox={(startCreatePayroll) && true}
          additionalButtons={[
            <div onClick={this.onStartCreateOrder}>
              <Icon type="interaction" className="mr-5" />
              {startCreatePayroll ? i18n.t('btn.stopCreatePayroll') : i18n.t('btn.startCreatePayroll')}
            </div>
          ]}
          onCheck={this.getCheckListTotalAmount}
          checkAll={reportings.list.data && reportings.list.data.length > 0 && (startCreatePayroll)}
          Component={Reporting}
          dialogTitle={i18n.t('lbl.reporting')}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ cek, common, netekstre }) => ({
  reportings: cek.reportings,
  tenantBanks: cek.filter.tenantBanks,
  operationStatuses: cek.filter.operationStatuses,

  selectedItems: common.dataTableSelectedItems,

  bankAccounts: netekstre.filter.bankAccounts,
});
const mapDispatchToProps = (dispatch) => ({
  getReporting: (filter, callback) => dispatch(cekActions.getAll({ filter, url: endpoints.cek.reporting, key: 'reportings' }, callback)),
  getCheckListTotalAmount: (filter, callback) => dispatch(cekActions.getFilter({ filter, url: endpoints.cek.cheques }, callback)),
  createPayroll: (data, callback) => dispatch(cekActions.post({ url: endpoints.cek.createPayroll, data, customNotification: true, options: { errors: { showNotification: false } } }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reportings);