import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import moment from "moment"
import { Button, Tooltip, Modal } from 'antd';
import endpoints from 'config/endpoints';
import Fade from 'react-reveal';
import { formatCurrency } from 'helpers';
import i18n from 'plugins/i18n';
import { getKey } from 'lib/constants/defaultFilters';

class BalanceReportsButton extends Component {
  constructor(props) {
    super(props);
    this.key = getKey();
    this.state = {
      loading: false,
      filter: {
        filter: {},
        orderBy: ['Amount desc']
      },
      visible: false,
      data: []
    };
  };

  onClick = () => {
    let { filter } = this.state;
    this.setState({ loading: true });
    const { bankAccountId, transactionDate } = this.props;
    const _transactionDate = this.props.currentFilter[this.key] && this.props.currentFilter[this.key].fields && this.props.currentFilter[this.key].fields[0] ? new Date(this.props.currentFilter[this.key].fields[0]) : transactionDate;
    filter.filter = { bankAccountId: { type: 'guid', value: bankAccountId }, transactionDate: { ge: moment(_transactionDate).startOf("day")._d, le: moment(_transactionDate).endOf("day")._d } }
    this.props.getTransaction(filter, (response) => {
      this.setState({ data: response.value, visible: true, loading: false })
    });
  }

  render() {
    let { visible, data, loading } = this.state;
    let { title, currencyCode } = this.props;

    return (
      <>
        <div>

          <Button onClick={this.onClick} className="#info info">
            <i className={loading ? "fas fa-circle-notch fa-spin" : "fas fa-ellipsis-h"}></i>
          </Button>
        </div>
        {
          data &&
          <Modal
            visible={visible}
            onCancel={() => this.setState({ visible: false })}
            footer={null}
            centered
            title={<div className="text-center">{title}</div>}
            className="statement-modal"
            width={600}
          >
            <Fade cascade bottom>
              <div className="statement-container">
                {
                  data.length > 0 ?
                    data.map((t, i) => (
                      <div key={i} className="statement-row">
                        <div>
                          <Tooltip placement="top" title={t.Description1}>
                            <span className="description">{t.Description1}</span>
                          </Tooltip>
                        </div>
                        <div>
                          {formatCurrency({ data: t.Amount, currencyCode, withColor: true})}
                        </div>
                      </div>
                    ))
                    :
                    <div className="text-center">
                      {i18n.t('msg.noTransactionDetails')}
                    </div>
                }
              </div>
            </Fade>
          </Modal>
        }

      </>
    );
  };
};

const mapStateToProps = ({ common }) => ({ currentFilter: common.currentFilter });
const mapDispatchToProps = (dispatch) => ({
  getTransaction: (filter, callback) => dispatch(actions.getAll({ url: endpoints.nte.bankAccountTransactions, filter }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BalanceReportsButton);