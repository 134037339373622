import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/cek';
import { ActionButtons, DataTable, CheckAccessRight, FadeIn } from 'components/UIComponents';
import { defaultFilters } from "lib/constants";
import endpoints from 'config/endpoints';
// import { serviceTypes } from "helpers/cek";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Parameter from './Detail';

class Parameters extends Component {
  state = {
    filter: defaultFilters.cek.Parameters()
  };

  componentDidMount() {
    this.getParameters();
    utils.cek.getTenantBanks('tenantBanks');
    utils.common.checkAccessToUpdateOrDelete('BankAccounts')
  };

  getParameters = () => {
    const { filter } = this.state;
    this.props.getParameters(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getParameters);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getParameters);
  }


  render() {
    const { parameters } = this.props;
    const columns = [
      { render: (row) => <ActionButtons url="ChequeAccount" editUrl item={row} hasDelete getDatas={this.getParameters} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'BankCode', render: (data) => utils.cek.getBankName({ data }) },
      { label: i18n.t('lbl.serviceType'), key: 'ServiceType/Name', checkField: false }
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...parameters }}>
            <DataTable
              count={parameters.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="ChequeAccount"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={parameters.list.data}
              loading={parameters.list.loading}
              title={i18n.t('lbl.parameters')}
              getData={this.getParameters}
              newButton="openDialog"
              Component={Parameter}
              dialogTitle={i18n.t('lbl.parameter')}
              deleteOptions={{ stateKey: "parameters", url: "ChequeAccount" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ cek }) => ({
  parameters: cek.parameters,
  tenantBanks: cek.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (filter) => dispatch(actions.getAll({ filter, url: endpoints.cek.chequeAccount, key: 'parameters' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Parameters);