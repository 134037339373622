import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { DataTable, Filter, ActionButtons, CheckAccessRight, FadeIn } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls, status, moduleTypesEnum } from 'lib/constants';
import i18n from 'plugins/i18n';
import Customer from './Detail'
import utils from 'lib';
import { Checkbox, Tooltip, Button } from 'antd';
import { formatDate } from 'helpers';

const PageAccesses = {
  Customers: "Customers",
  AdminCustomers: "AdminCustomers"
}

class Customers extends Component {
  state = {
    filter: defaultFilters.common.Customers(),
    access: PageAccesses.Customers,
    getDataTrigger: undefined,
  };

  componentDidMount() {
    const { getDataTrigger } = this.props;

    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getCustomers();
      access = PageAccesses.Customers;
    }
    else {
      access = PageAccesses.AdminCustomers;
    }
    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id }
      const filter = state.filter
      props.getCustomers(filter, parameters, endpoints.tenant.adminCustomers);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  getCustomers = () => {
    const { getDataTrigger } = this.props;
    const { filter } = this.state;
    if (getDataTrigger)
      this.props.getCustomers(filter, { TenantId: getDataTrigger.id }, endpoints.tenant.adminCustomers);
    else
      this.props.getCustomers(filter);
  };

  onFilter = newFilter => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getCustomers);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, this.getCustomers);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getCustomers);
  };

  additionalButtons = item => {
    const { history, modules } = this.props;
    return (
      !!modules.find(x => x.name === moduleTypesEnum.NTH) &&
      <>
        <Tooltip placement="bottom" title={i18n.t('btn.impersonatedPayment')}>
          <Button className="#edit" icon="credit-card" size='small' onClick={() => history.push({ pathname: '/netahsilat/pay', state: { userId: item.Id } })} />
        </Tooltip>
        <Tooltip placement="bottom" title={i18n.t('btn.sendPaymentLink')}>
          <Button className="#edit" icon="shake" size='small' onClick={() => history.push({ pathname: '/netahsilat/pay', state: { userId: item.Id, tab: 'email' } })} />
        </Tooltip>
      </>
    )
  }

  renderActionButtons = row => {
    const { access } = this.state;
    const { getDataTrigger } = this.props
    const useActions = !!(getDataTrigger === undefined || (getDataTrigger !== null))
    return <ActionButtons url={access} editUrl={useActions} item={row} hasDelete={useActions} getDatas={this.getCustomers} openDialog={this.datatable?.openDialog} additionalButtons={this.additionalButtons} />
  }

  render() {
    const { customers, showFilter, hasParent, className, datatableTitle, getDataTrigger, datatableCollapsed } = this.props;
    const { access } = this.state;
    const filters = [
      { label: i18n.t('lbl.membershipDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['CreatedDate'], uniqueKey: '#createdDate', allowClear: true },
      { label: i18n.t('lbl.membershipDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['CreatedDate'], uniqueKey: '#createdDate', allowClear: true },
      { label: i18n.t('lbl.isActive'), type: 'select', options: status, value: 'value', name: 'name', keys: ['IsActive'], uniqueKey: '#isActive', lang: 'lbl', showAll: true, boolean: true },
      { label: i18n.t('lbl.member'), type: 'input', keys: ['Name', 'UserCode', 'Email', 'Phone'], contains: true, uniqueKey: '#search' }
    ];
    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.userName'), key: 'Name', render: row => `${row.Name} ${row.Surname}` },
      { label: i18n.t('lbl.code'), key: 'UserCode' },
      { label: i18n.t('lbl.membershipDate'), key: 'CreatedDate', render: ({ CreatedDate }) => formatDate(CreatedDate, null, null, null, false) },
      { label: i18n.t('lbl.email'), key: 'Email' },
      { label: i18n.t('lbl.phone'), key: 'Phone' },
      { label: i18n.t('lbl.isActive'), key: 'IsActive', render: (row) => <Checkbox checked={row.IsActive} />, thClass: 'text-center', tdClass: 'text-center' },
    ];

    return (
      <FadeIn className={`page-content ${className || ''}`}>
        <CheckAccessRight {...{ ...customers, hasParent: getDataTrigger !== undefined }}>

          {
            showFilter !== false &&
            <Filter filters={filters} onFilter={this.onFilter} />
          }

          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            onPageChange={this.onPageChange}
            access="Users"
            newButton="openDialog"
            history={this.props.history}
            onSort={this.onSort}
            columns={columns}
            data={customers.data}
            loading={customers.loading}
            title={datatableTitle || i18n.t('route.common.customers')}
            count={customers.count}
            Component={Customer}
            dialogTitle={i18n.t('lbl.customer')}
            getData={this.getCustomers}
            deleteOptions={{ stateKey: "customers", url: access, baseUrl: baseUrls.tenant }}
            hasParent={hasParent}
            disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
            collapsed={datatableCollapsed}
            dialogOptions={{
              endpoint: getDataTrigger && endpoints.tenant.adminCustomers,
              tenantId: getDataTrigger && getDataTrigger.id
            }}
          />
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ common, auth }) => ({
  customers: common.customers.list,
  modules: auth.data?.modules
});
const mapDispatchToProps = (dispatch) => ({
  getCustomers: (filter, parameters, endpoint) => dispatch(actions.getAll({ url: endpoint || endpoints.tenant.customers, key: 'customers', filter, parameters, isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Customers);