import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/tos';
import { ActionButtons, DataTable, Filter, CheckAccessRight, FadeIn } from 'components/UIComponents';
import { defaultFilters } from "lib/constants";
import endpoints from 'config/endpoints';
import { serviceTypes } from "helpers/tos";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Parameter from './Detail';

class Parameters extends Component {
  state = {
    filter: defaultFilters.tos.Parameters()
  };

  componentDidMount() {
    this.getParameters();
    utils.tos.getTenantBanks('tenantBanks');
    utils.common.checkAccessToUpdateOrDelete('TosAccount')
  };

  getParameters = () => {
    const { filter } = this.state;
    this.props.getParameters(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getParameters);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getParameters);
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getParameters);
  };

  render() {
    const { parameters, tenantBanks } = this.props;
    const filters = [
      { label: i18n.t('lbl.bankName'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.tos.getBankName({ bankData }), keys: ['bankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.serviceType'), type: 'select', search: true, options: serviceTypes, value: 'id', name: 'name', keys: ['ServiceType/Id'], uniqueKey: '#serviceType', multiple: true, lang: 'tos' },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="TosAccount" editUrl item={row} hasDelete getDatas={this.getParameters} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'BankCode', render: (data) => utils.tos.getBankName({ data }) },
      { label: i18n.t('lbl.serviceType'), key: 'ServiceType/Name', checkField: false }
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...parameters }}>
            <Filter filters={filters} onFilter={this.onFilter} />
            <DataTable
              count={parameters.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="TosAccount"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={parameters.list.data}
              loading={parameters.list.loading}
              title={i18n.t('route.tos.parameters')}
              getData={this.getParameters}
              newButton="openDialog"
              Component={Parameter}
              dialogTitle={i18n.t('lbl.parameter')}
              deleteOptions={{ stateKey: "parameters", url: "TosAccount" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ tos }) => ({
  parameters: tos.parameters,
  tenantBanks: tos.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tos.tosAccount, key: 'parameters' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Parameters);