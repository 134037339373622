import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/posrapor';
import reducers from 'store/reducers/posrapor';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllPosrapor: ['payload', 'callback'],
  getAllPosraporSuccess: ['payload'],
  getAllPosraporFailure: ['payload'],
  getPosrapor: ['payload', 'callback'],
  getPosraporSuccess: ['payload'],
  getPosraporFailure: ['payload'],
  postPosrapor: ['payload', 'callback'],
  postPosraporSuccess: ['payload'],
  postPosraporFailure: ['payload'],
  putPosrapor: ['payload', 'callback'],
  putPosraporSuccess: ['payload'],
  putPosraporFailure: ['payload'],
  deletePosrapor: ['payload', 'callback'],
  deletePosraporSuccess: ['payload'],
  deletePosraporFailure: ['payload'],
  patchPosrapor: ['payload', 'callback'],
  patchPosraporSuccess: ['payload'],
  patchPosraporFailure: ['payload'],
  clearStatePosrapor: ['key'],
  getWithPostPosrapor: ['payload'],
  getWithPostPosraporSuccess: ['payload'],
  getWithPostPosraporFailure: ['payload'],
  getFilterPosrapor: ['payload'],
  getFilterPosraporSuccess: ['payload'],
  getFilterPosraporFailure: ['payload'],
});
export const PosraporTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state, action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state, action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state, action)
  return state.merge(data);
};



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_POSRAPOR]: getAll,
  [Types.GET_ALL_POSRAPOR_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_POSRAPOR_FAILURE]: getAllFailure,
  [Types.GET_POSRAPOR]: get,
  [Types.GET_POSRAPOR_SUCCESS]: getSuccess,
  [Types.GET_POSRAPOR_FAILURE]: getFailure,
  [Types.POST_POSRAPOR]: post,
  [Types.POST_POSRAPOR_SUCCESS]: postSuccess,
  [Types.POST_POSRAPOR_FAILURE]: postFailure,
  [Types.PUT_POSRAPOR]: put,
  [Types.PUT_POSRAPOR_SUCCESS]: putSuccess,
  [Types.PUT_POSRAPOR_FAILURE]: putFailure,
  [Types.DELETE_POSRAPOR]: _delete,
  [Types.DELETE_POSRAPOR_SUCCESS]: deleteSuccess,
  [Types.DELETE_POSRAPOR_FAILURE]: deleteFailure,
  [Types.PATCH_POSRAPOR]: patch,
  [Types.PATCH_POSRAPOR_SUCCESS]: patchSuccess,
  [Types.PATCH_POSRAPOR_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_POSRAPOR]: clearState,
  [Types.GET_WITH_POST_POSRAPOR]: getWithPost,
  [Types.GET_WITH_POST_POSRAPOR_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_POSRAPOR_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_POSRAPOR]: getFilter,
  [Types.GET_FILTER_POSRAPOR_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_POSRAPOR_FAILURE]: getFilterFailure,
});