import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select, DatePicker } from 'components/UIComponents/NetbankaComponents';
import { CreatingInstructionsModel } from 'models';
import actions from 'store/actions/dbs';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib'
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class CreatingInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new CreatingInstructionsModel(),
      InitialState: new CreatingInstructionsModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.creatingInstructions.single.data && !state.data.Id) {
      const data = new CreatingInstructionsModel({ ...props.creatingInstructions.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.creatingInstructions.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) {
      this.props.getCreatingInstructions(this.id, response => {
        this.props.getCustomers({ filter: { CurrentAccountId: { type: 'guid', value: response.CurrentAccountId } } });
      });
    }
    else {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    if (key === "BankCode" && !this.props.customers.data.find(x => x.BankCode === value)) {
      this.props.form.setFieldsValue({ CurrentAccountId: null });
    }
    this.setState({ data });
  };


  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData, DbsOrderId } = this.props;
    form.validateFields(error => {
      if (!error) {
        
        const data = _.cloneDeep(this.state.data);
        if(DbsOrderId) data.DbsOrderId = DbsOrderId;
        data.InvoiceAmount = parseFloat(data.InvoiceAmount.replace(',', '.'))

        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  onSearch = e => {
    if (e && e !== '' && e.length > 2) {
      const filter = {
        filter: {
          or: { CustomerDbsName: { contains: e } },
          and: { CurrentAccountId: { ne: null } }
        }
      }
      this.props.getCustomers(filter);
    }
  }

  renderDialogButtons = checkAccessRights => {
    let { creatingInstructions, dialogCloseRequest, renderDialogButtons, notEditable } = this.props;

    renderDialogButtons(
      checkAccessRights
      ||
      (
        notEditable ?
          <div>
            <Button disabled={creatingInstructions.single.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
          </div>
          :
          <div>
            <Button disabled={creatingInstructions.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
            <Button disabled={creatingInstructions.single.loading} form="creatingInstruction" htmlType="submit" className="success #save" icon="save" loading={creatingInstructions.single.saving}>{i18n.t('btn.save')}</Button>
          </div>
      ),
      creatingInstructions.single.saving,
      creatingInstructions.single.loading
    )
  }

  render() {
    const { tenantCurrencies, tenantBanks, customers, dialogCloseRequest, creatingInstructions } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, data } = this.state;
    const { renderDialogButtons } = this;


    const _customers = data.BankCode ? customers.data ? customers.data.filter(x => x.BankCode === data.BankCode) : [] : customers.data;
    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...creatingInstructions, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="creatingInstruction">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankCode
                    })(
                      <Select
                        loading={tenantBanks.loading}
                        className="#bank"
                        label={i18n.t('dbs.bankName')}
                        options={tenantBanks.data}
                        optVal="Code"
                        optName={(bankData) => utils.dbs.getBankName({ bankData })}
                        onChange={(e) => this.update('BankCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('CurrentAccountId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CurrentAccountId
                    })(
                      <Select
                        loading={customers.loading}
                        showSearch
                        onSearch={this.onSearch}
                        className="#bank"
                        label={i18n.t('dbs.dealerName')}
                        options={_customers}
                        optVal="CurrentAccountId"
                        optName="CustomerDbsName"
                        onChange={(e) => this.update('CurrentAccountId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('CurrencyCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CurrencyCode
                    })(
                      <Select
                        loading={tenantCurrencies.loading}
                        className="#CurrencyCode"
                        label={i18n.t('lbl.currencyCode')}
                        options={tenantCurrencies.data}
                        optVal="Code"
                        optName="Name"
                        allowClear={false}
                        onChange={(e) => this.update('CurrencyCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('InvoiceDate', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.InvoiceDate
                    })(
                      <DatePicker label={i18n.t('dbs.invoiceDate')} className="#invoiceDate" onChange={e => this.update('InvoiceDate', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('DueDate', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.DueDate
                    })(
                      <DatePicker label={i18n.t('dbs.expiryDate')} className="#DueDate" onChange={e => this.update('DueDate', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('InvoiceNumber', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.InvoiceNumber
                    })(
                      <Input className="#InvoiceNumber" label={i18n.t('dbs.invoiceNumber')} onChange={(e) => this.update('InvoiceNumber', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('InvoiceAmount', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.InvoiceAmount || ''
                    })(
                      <Input type="amount" className="#InvoiceAmount" label={i18n.t('dbs.invoiceAmount')} onChange={(e) => this.update('InvoiceAmount', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('Description', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.Description || ''
                    })(
                      <Input className="#Description" label={i18n.t('dbs.Description')} onChange={(e) => this.update('Description', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  creatingInstructions: dbs.creatingInstructions,
  tenantCurrencies: dbs.filter.tenantCurrencies,
  tenantBanks: dbs.filter.tenantBanks,
  customers: dbs.filter.customers,
});
const mapDispatchToProps = (dispatch) => ({
  getCreatingInstructions: (id, callback) => dispatch(actions.get({ url: endpoints.dbs.dbsInvoice, key: 'creatingInstructions', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.dbs.dbsInvoice, key: 'creatingInstructions', data }, callback)),
  getCustomers: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.dbs.customerDbsInfo, key: 'customers', isNewODataStructure: true })),
  clearState: () => dispatch(actions.clearState('creatingInstructions')),
});
const CreatingInstructionsForm = Form.create()(CreatingInstructions);
export default connect(mapStateToProps, mapDispatchToProps)(CreatingInstructionsForm);
