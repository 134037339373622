import React, { useState } from 'react'
import { Layout, Drawer } from 'antd';
import { ModuleSelector, ModuleMenu } from './components';


const Sider = ({ visible, setVisible }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [subVisible, setSubVisible] = useState(false)

  return (
    <>
      <Layout.Sider collapsed={collapsed} className={`dashboard ${collapsed ? 'collapsed' : ''} mobile-hide`}>
        <ModuleSelector onCollapsed={() => setCollapsed(!collapsed)} collapsed={collapsed} />
        <ModuleMenu collapsed={collapsed} />
      </Layout.Sider>
      <Drawer
        {...{
          visible,
          placement: "left",
          closable: false,
          onClose: () => setVisible(false),
          className: 'mobile-menu',
        }}
      >
        <ModuleSelector onCollapsed={() => setCollapsed(!collapsed)} collapsed={collapsed} onMobileClick={() => setSubVisible(!subVisible)} />
        <Drawer
          {...{
            visible: subVisible,
            placement: "left",
            closable: false,
            onClose: () => setSubVisible(false),
            className: 'sub-mobile-menu',
          }}
        >
          <ModuleMenu collapsed={collapsed} />
        </Drawer>
      </Drawer>
    </>
  )
}

export default Sider;