import React, { Component } from 'react';
import actions from "store/actions/cek";
import endpoints from "config/endpoints";
import { Button, Col, Form, Row } from "antd";
import { connect } from "react-redux";
import i18n from "plugins/i18n";
import { Loading } from "components/UIComponents";
import { Input, Select } from "components/UIComponents/NetbankaComponents";
import utils from "lib";
import { ChequeModel } from "models";

class DeleteCheque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new ChequeModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.cheques.single.data && !state.data.Id) {
      return { data: new ChequeModel({ ...props.cheques.single.data }), loading: false }
    }
    if (props.dialogClosing && !props.cheques.single.saving) props.onDialogClose(null, null)
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getCheque(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    let { cheques, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={cheques.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>
    )
  }


  render() {
    const { loading, data } = this.state;
    const { tenantBanks } = this.props;
    this.renderDialogButtons();
    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    value={data.BankCode}
                    className="#bank"
                    label={i18n.t('lbl.bankName')}
                    options={tenantBanks.data}
                    optVal="Code"
                    optName={(bankData) => utils.cek.getBankName({ bankData })}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#OperationStatus" label={i18n.t('lbl.status')} value={data.OperationStatusMessage} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#TotalAmount" label={i18n.t('lbl.totalAmount')} value={data.Amount} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#CurrencyCode" label={i18n.t('lbl.currencyCode')} value={data.CurrencyCode} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled className="#Description" label={i18n.t('lbl.description')} value={data.Description} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>

    );
  }
}

const mapStateToProps = ({ cek }) => ({
  cheques: cek.cheques,
  tenantBanks: cek.filter.tenantBanks
});
const mapDispatchToProps = (dispatch) => ({
  getCheque: (id, callback) => dispatch(actions.get({ url: endpoints.cek.cheques, key: 'cheques', id }, callback)),
  clearState: () => dispatch(actions.clearState("cheques"))
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteCheque);