import React, { Component } from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import moment from 'moment';
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';


class NBDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? moment(props.value) : null,
      visible: false,
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value && moment(props.value) !== state.value) {
      return {
        value: moment(props.value)
      }
    }
    else {
      return {
        value: null
      }
    }
  };

  handleChange = (e) => {
    if (this.props.startOf) e = e.startOf('day');
    if (this.props.endOf) e = e.endOf('day');
    this.setState({ value: e, visible: false }, () => {
      this.props.onChange && this.props.onChange(e ? e.format() : null);
    });
  };

  onPanelChange = (value) => {
    let { mode, date } = this.props;
    let _type = date === 'ge' ? 'startOf' : 'endOf';
    let _value = value[_type](mode);
    this.handleChange(_value);
  }

  render() {
    let { value, visible } = this.state;
    let { label, type, className, id, disabled, required, autoFocus, disabledDate, allowClear, mode, hidePlaceholder, prefix } = this.props;
    const _prefix = typeof prefix === "string" ? <ReactSVG src={icons[prefix]} /> : prefix;
    return (
      <div className="custom-datetime__wrapper">
        <DatePicker
          dropdownClassName="custom-form-calendar-picker"
          allowClear={allowClear === false ? false : true}
          suffixIcon={_prefix}
          id={id}
          type={type}
          value={value}
          locale={locale}
          onChange={(e) => this.handleChange(e)}
          required={!!required}
          disabled={!!disabled}
          disabledDate={disabledDate}
          autoFocus={!!autoFocus}
          placeholder=""
          format="DD.MM.YYYY"
          className={`${value && 'is-filled'} ${className || ''} ${label}`}
          mode={mode || 'date'}
          onPanelChange={(this.onPanelChange)}
          onOpenChange={(e) => this.setState({ visible: e })}
          open={visible}
          
        />
        <label className={`${(value && value.toString() !== '') ? `${hidePlaceholder ? 'hide' : ''} is-focus` : ''}`}>{label || ''}</label>
      </div>
    );
  };
};

export default NBDate;