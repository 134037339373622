import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Pagination, ConfigProvider, Dropdown, Button, Checkbox, Menu, Icon, Popover, Spin, Form, Modal } from 'antd';
import tr_TR from 'antd/es/locale/tr_TR';
import en_US from 'antd/es/locale/en_US';
import commonActions from 'store/actions/common';
import Loading from '../Loading';
import { TableSettingModel } from 'models';
import { isStartsGuid, isEditableInvoice, preparePatchData } from 'helpers';
import { Upload } from 'components/UIComponents/NetbankaComponents';
import Dialog from 'components/UIComponents/Dialog';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { baseUrls, httpMethods } from 'lib/constants';
import { getKey as defaultFiltersGetKey } from 'lib/constants/defaultFilters';
import utils from 'lib';
import { Cell } from './exports';
import config from 'config';

const lang = { tr_TR, en_US }

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.key = props.reduxFilterKey || this.getKey();
    let settings = props.gridSettings && props.gridSettings.list.data ? props.gridSettings.list.data : [];
    let _settings = settings.find(x => x.controllerPath === this.key);
    this.state = {
      page: props.currentFilter[this.key] && props.currentFilter[this.key].page ? window._.cloneDeep(props.currentFilter[this.key].page) : 1,
      visible: false,
      columns: props.columns.map(x => { return { ...x, visible: true } }),
      all: true,
      sort: props.defaultSort || null,
      desc: true,
      key: this.key,
      selectAll: false,
      selectPage: false,
      tableSettings: new TableSettingModel(_settings || { userId: props.userId, controllerPath: this.key }),
      processing: false,
      highlight: undefined,
      isDeleteDialog: false,
      showDialog: false,
      id: "",
      collapsed: false,
      externalDialogOptions: null,
      flagger: false,
      editingRow: null,
      showErrors: false,
      bulkDataError: null,
    };
  };

  getKey = () => {
    let key = `${defaultFiltersGetKey()}${this.props.order ? this.props.order : ''}`;
    const keyHasGuid = isStartsGuid(key.split('/')[key.split('/').length - 1])
    if (keyHasGuid) {
      let splittedKey = key.split('/');
      key = splittedKey.filter((x, i) => i !== splittedKey.length - 1).join('/')
    }
    return key;
  }

  componentDidMount() {
    let cols = this.state.tableSettings.fields;
    if (cols) {
      let { columns } = this.state;
      cols = JSON.parse(cols);
      columns.map((x, i) => columns[i].visible = (cols[i] !== undefined && cols[i] !== null) ? cols[i] : true);
      this.setState({ columns });
    };

    if (this.getKey() === "netekstre/transactions") {
      this.props.getTenantExcelFormats();
    }
    document.getElementsByClassName('ant-layout-content')[0].addEventListener('scroll', this.onScroll);
  };

  componentWillUnmount() {
    if (this.key) {
      utils.common.clearExcelFormat()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.columns.length !== prevProps.columns.length) {
      let cols = this.state.tableSettings.fields;
      let columns = this.props.columns.map(x => { return { ...x, visible: true } });
      if (cols) {
        cols = JSON.parse(cols);
        columns.map((x, i) => columns[i].visible = (cols[i] !== undefined && cols[i] !== null) ? cols[i] : true);
      }
      this.setState({ columns });
    }
    if (prevState.collapsed !== this.props.collapsed) {
      this.setState({ collapsed: this.props.collapsed })
    }
    if (document.getElementsByClassName("datatable").length > 0 && this.state.flagger === false) {
      this.setState({
        flagger: !this.state.flagger
      })
      this.insertCell();
    }
  }

  onScroll = (e) => {
    let top;
    if (this.datatable && this.datatable.getElementsByTagName('thead')[0]) {
      if (this.datatable) top = this.datatable.getElementsByTagName('thead')[0].getBoundingClientRect().top;
      if (top < 64) {
        if (this.headerTable && this.headerTable.style.display !== 'block') {
          this.headerTable.style.display = 'block';
          this.headerTable.scrollLeft = this.datatable.scrollLeft;
        }
      }
      else {
        if (this.headerTable && this.headerTable.style.display !== 'none') {
          this.headerTable.style.display = 'none';
        }
      }
    }
  };


  insertCell = () => {
    const { data } = this.props;
    if (data.length && !(data.length === 1 && data[data.length - 1]?.isTotalRow)) {
      const dataTablePanelWidth = document.getElementById('dpanel-width')?.clientWidth;
      const tableWidth = document.querySelector('table')?.clientWidth;
      const newWidth = dataTablePanelWidth - tableWidth;

      if (newWidth >= 0) {
        Array.from(document.querySelectorAll("thead tr")).forEach((tr) => {
          if (tr.querySelector('th') && !tr.querySelector('#created-td')) {
            var isThElement = `<th style="width: ${newWidth}px" id='created-td'></th>`;
            tr.insertAdjacentHTML('beforeend', isThElement)
          }
        });
        Array.from(document.querySelectorAll("tbody tr")).forEach((tr) => {
          if (tr.querySelector('td') && !tr.querySelector('#added-newtd')) {
            var isTdElement = `<td style="width: ${newWidth}px" id='added-newtd'></td>`;
            tr.insertAdjacentHTML('beforeend', isTdElement)
          }
        });
      }
    }
  }

  removeAllChildNodes() {
    var checkNewCell = document.getElementById('created-td') ? true : false;
    if (checkNewCell) {
      Array.from(document.querySelectorAll("thead tr")).forEach((tr) => {
        tr.removeChild(tr.lastElementChild);
      });
      Array.from(document.querySelectorAll("tbody tr")).forEach((tr) => {
        tr.removeChild(tr.lastElementChild);
      });
    }
  }

  showTotal = (total, range) => {
    return `${range[0]} - ${range[1]} (${i18n.t('lbl.total')} ${total} ${i18n.t('lbl.record')})`;
  };

  toggleColumn = (e, index) => {
    let { columns, tableSettings } = this.state;
    columns.filter(x => x.toggle !== false)[index].visible = e.target.checked;
    this.setState({ columns });
    tableSettings.fields = JSON.stringify(columns.map(x => { return x.visible }));
    this.removeAllChildNodes();
    this.props.saveSettings(tableSettings, tableSettings.id ? httpMethods.put : httpMethods.post, (response) => {
      if (response) {
        this.setState({
          tableSettings: { ...tableSettings, id: response.data }
        });
        this.props.getSettings();
      }
      this.insertCell();
    });
  };

  toggleAll = (e) => {
    let { columns, tableSettings } = this.state;
    columns = columns.map(x => { return { ...x, visible: e.target.checked } });
    this.setState({ columns });
    tableSettings.fields = JSON.stringify(columns.map(x => { return x.visible }));
    this.props.saveSettings(tableSettings, tableSettings.id ? httpMethods.put : httpMethods.post, (response) => {
      if (response) {
        this.setState({ tableSettings: { ...tableSettings, id: response.data } });
        this.props.getSettings();
      }
    });
  }

  sort = (key) => {
    const { initEditable } = this.props;
    const { sort, desc, editingRow } = this.state;
    if (!initEditable && !editingRow)
      this.setState({ sort: key, desc: sort === key ? !desc : true }, () => {
        this.props.onSort(this.state.sort, this.state.desc ? 'desc' : 'asc');
      });
  };

  pageUpdate = (page) => {
    this.setState({ page });
  };

  pageChange = () => {
    let { page, tableSettings } = this.state;
    this.props.onPageChange((page - 1) * tableSettings.pageSizes, tableSettings.pageSizes);
    //this.props.updateFilter((page - 1) * tableSettings.pageSizes, page, this.state.key);
  }

  onPageSizeChange = (size) => {
    let { tableSettings, key } = this.state;
    let { saveSettings, updateFilter, getSettings } = this.props;
    tableSettings.pageSizes = size;
    saveSettings(tableSettings, tableSettings.id ? httpMethods.put : httpMethods.post, (response) => {
      if (response) {
        let { page, tableSettings } = this.state;
        page = 1;
        this.setState({ page, tableSettings: { ...tableSettings, id: response.data } }, () => {
          updateFilter((page - 1) * tableSettings.pageSizes, page, key);
          getSettings();
        });
      }
    });
    this.setState({ page: 1 }, () => {
      this.pageChange();
    });
  }

  checkPage = e => {
    let { data, selectedItems, onCheck } = this.props;
    let _selectedItems = [];

    if (e) {
      this.setState({ selectAll: false })
      data.forEach(item => {
        let id = item.id || item.Id
        if (selectedItems.indexOf(id) === -1) _selectedItems.push(id)
      })
    }
    else {
      data.forEach(item => {
        let id = item.id || item.Id
        if (selectedItems.indexOf(id) !== -1) _selectedItems.push(id)
      })
    }

    return new Promise((resolve) => {
      this.props.setSelectedItems(_selectedItems);
      resolve()
    }).then(() => onCheck && onCheck())

  }

  checkAll = (e) => {

    let { onCheck } = this.props;
    this.setState({ selectAll: e }, () =>
      new Promise((resolve) => {
        this.props.setSelectedItems(e);
        resolve()
      }).then(() => onCheck && onCheck(e))
    );
  };

  renderSort = (col) => {
    return (
      col.sort !== false && this.state.sort === col.key ?
        <div className="sort">
          {(this.state.sort !== col.key || (this.state.sort === col.key && !this.state.desc)) &&
            <Icon type="caret-up" />
          }
          {(this.state.sort !== col.key || (this.state.sort === col.key && this.state.desc)) &&
            <Icon type="caret-down" />
          }
        </div>
        :
        ''
    );
  };

  excelExport = (formatId) => {
    const { excel, currentFilter, data, count } = this.props;
    if (data && data.length && count > config.excelExportLimit) {
      Modal.warn({
        title: i18n.t('msg.excelExportLimitError'),
        content: i18n.t("msg.excelExportLimitErrorContent", { config: config.excelExportLimit }),
        okText: i18n.t('btn.ok')
      })
    }
    else {
      const key = defaultFiltersGetKey();
      const filter = currentFilter[key] ? _.cloneDeep(currentFilter[key].filter) : {};
      const isString = typeof excel === "string";
      const _excel = isString ? excel : excel.url;
      const _module = (!isString && excel.module) ? excel.module : '';
      let _parameters = excel && !_.isEqual(excel.parameters, {}) ? excel.parameters : {};
      let _filter = {
        ...excel.filter,
        orderBy: excel.filter ? excel.filter.orderBy : this.state.sort ? [`${this.state.sort} ${this.state.desc ? 'desc' : 'asc'}`] : undefined
      };


      if (key === 'netekstre/transactions') {
        if (!(currentFilter[key] && currentFilter[key].fields && currentFilter[key].fields[2] && currentFilter[key].fields[2].length)) {
          filter.and ? filter.and.push({ transferStatusId: { ne: 8 } }) : filter.and = ({ transferStatusId: { ne: 8 } })
        }
      }
      if (key === 'netekstre/reports/balances') {
        if (!(currentFilter[key] && currentFilter[key].fields && currentFilter[key].fields[0] && currentFilter[key].fields[0].length && currentFilter[key].fields[3] && currentFilter[key].fields[3].length)) {
          let date = new Date();
          date.setDate(date.getDate() - 1);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          filter && (filter.and ? filter.and.push({ reportDate: date }, { currencyCode: 'TRY' }) : filter.and = [{ reportDate: date }, { currencyCode: 'TRY' }])
        }
      }

      if (isString || !excel.filter) _filter.filter = filter;
      else _filter = { ..._filter, ...filter };

      if (formatId) {
        if (!_parameters) _parameters = {};
        _parameters.tenantExcelFormatId = formatId
      }
      this.props.excelExport(_excel, _filter, _parameters, _module);
    }
  };

  onMenuClick = ({ key }, formatId) => {
    switch (key) {
      case 'excel':
        this.excelExport(formatId);
        break;
      case 'new':
        typeof this.props.newButton === 'string' ?
          (this.props.newButton === "openDialog" ?
            this.openDialog(false, undefined)
            :
            this.props.history.push(`/${this.props.newButton}`))
          :
          typeof this.props.newButton === "object" ?
            (this.props.newButton.endpoint === "openDialog" ?
              this.openDialog(false, undefined)
              :
              this.props.history.push(`/${this.props.newButton.endpoint}`))
            :
            this.props.newButton();
        break;
      case 'startApprove':
        if (this.props.startApprove) this.props.setSelectedItems(false);
        this.props.startApprove(!this.props.approveStarted);
        break;
      case 'startSync':
        if (this.props.syncStarted) this.props.setSelectedItems(false);
        this.props.startSync(!this.props.syncStarted);
        break;
      case 'startTransfer':
        if (this.props.transferStarted) this.props.setSelectedItems(false);
        this.props.startTransfer(!this.props.transferStarted);
        break;
      default:
        break;
    }
  };

  onSelect = (id) => {
    let { selectedItems, onCheck } = this.props;
    let { selectAll } = this.state;
    if (selectedItems.length === 0 && selectAll) {
      this.setState({ selectAll: false })
      return new Promise((resolve) => {
        this.props.setSelectedItems(id);
        resolve()
      }).then(() => onCheck && onCheck(id))
    }
    else {
      return new Promise((resolve) => {
        this.props.setSelectedItems(id);
        resolve()
      }).then(() => onCheck && onCheck(id))
    }
  };

  onTableScroll = (e) => {
    e.persist();
    this.headerTable.scrollLeft = e.target.scrollLeft;
  };

  highlight = (row) => {
    this.setState({
      highlight: row.parentTransactionId || row.id
    })
  }

  onCancelInlineEdit = e => {
    e.stopPropagation();
    this.setState({ editingRow: null });
    this.props.clearDatatableInlineValue()
  }

  onSaveInlineData = e => {
    e.stopPropagation();
    const { saveInlineData, deleteOptions, datatableInline, clearDatatableInlineValue, defaultFields = [] } = this.props;
    const url = `${config[deleteOptions.baseUrl || baseUrls.nte]}${deleteOptions.url}`;
    const data = preparePatchData(datatableInline.single.data, defaultFields);

    const callback = () => {
      this.setState({ editingRow: null });
      clearDatatableInlineValue();
    }

    if (data) saveInlineData(data.id, data.patchData, url, callback);
    else callback();
  }

  onSaveBulkData = ({ beforeSubmit, callback, method = httpMethods.post }) => {
    this.setState({ showErrors: true, bulkDataError: null });
    const { saveBulkData, deleteOptions, clearDatatableBulkValues, columns, data: tableData, defaultFields = [] } = this.props;
    const requiredKeys = columns.filter(x => x.editable?.required).map(x => x.key);
    const url = `${config[deleteOptions.baseUrl || baseUrls.nte]}${deleteOptions.url}`;
    utils.common.prepareBulkData(requiredKeys, tableData, defaultFields)
      .then(data => {
        if (beforeSubmit) data = beforeSubmit(data);
        saveBulkData(data, url, method, response => {
          this.setState({ editingRow: null, showErrors: false, bulkDataError: null });
          callback && callback({ response })
          clearDatatableBulkValues();
        });
      })
      .catch(({ errorMessage }) =>
        this.setState({ bulkDataError: errorMessage })
      )
  }

  getDefaultValues = item => {
    const { columns } = this.state;
    const { defaultValueFields } = this.props
    let defaultValues = {};
    columns.filter(x => defaultValueFields.includes(x.key)).map(y => defaultValues[y.key] = item[y.key])
    return defaultValues;
  }

  renderTable = (isHeader) => {
    const { data, showNoData, checkbox, checkAll, toggle, withTotal, checkFields, noHeader, selectedItems, className, usePage, additionalRows, tableStyle,
      onRowClick, currentModule, doubleTHead, Component, disabled, i18nextLng, clickable, initEditable, datatableInline, defaultValueFields, hideIndex } = this.props;
    const { columns, selectAll, highlight, editingRow, showErrors } = this.state;
    const key = defaultFiltersGetKey();

    return (
      <div
        lang={i18nextLng.substring(0, 2)}
        ref={el => this[isHeader ? 'headerTable' : 'datatable'] = el}
        className={`datatable-panel ${noHeader ? 'no-header' : ''} ${isHeader ? 'fixed-header' : ''} ${doubleTHead ? 'double-thead' : ''}`}
        onScroll={!isHeader ? this.onTableScroll : null}
        id='dpanel-width'
      >
        <Form>
          <table
            style={tableStyle}
            className={
              `datatable 
              ${(!data || data.length < 1 || (data.length === 1 && data[data.length - 1]?.isTotalRow)) ? 'datatable-empty' : ''} 
              ${className || ''} 
              ${!!editingRow ? 'editing-table' : ''}
              ${initEditable ? 'bulk-edit' : ''}
            `}
            id='d-width'
          >
            {columns.filter(x => !x.label).length !== columns.length &&
              <thead>
                <tr>
                  {
                    checkbox &&
                    <th>
                      {
                        checkAll &&
                        <Popover overlayClassName={currentModule} placement="right" trigger={['click']} content={
                          <React.Fragment>
                            <Checkbox className="#checkAll" checked={selectAll} onChange={(e) => this.checkAll(e.target.checked)}>
                              {i18n.t('btn.all')}
                            </Checkbox>
                            <Checkbox className="#checkPage" checked={selectedItems.length && data && data.every(x => selectedItems.find(y => x.Id === y || x.id === y))} onChange={(e) => this.checkPage(e.target.checked)}>
                              {i18n.t('btn.thisPage')}
                            </Checkbox>
                          </React.Fragment>
                        }>
                          <Checkbox className="#checkDropdown" indeterminate={selectedItems.length > 0} checked={selectAll} />
                        </Popover>
                      }
                    </th>
                  }
                  {
                    //Add blank th if actionButtons col is not exist
                    !!data && !!data.length &&
                    !columns.find(x => x.key === "Id" && !!x.render) &&
                    (!initEditable && editingRow) &&
                    <th />
                  }
                  {
                    !!data && !!data.length && !(withTotal && data.length - 1 === 0) &&
                    columns.map((col, i) => (
                      (toggle === false || (col.toggle === false || col.visible)) && (checkFields === false || col.checkField === false || (checkFields !== false && data && data[0] && Object.keys(data[0]).includes(col.key))) &&
                      <th onClick={() => col.sort !== false && this.sort(col.key)} className={col.thClass ? col.thClass : ''} key={col.key || i}>
                        <span className="th-content">
                          {this.props.columns[i] && this.props.columns[i].label ? this.props.columns[i].label : ''}
                          {this.renderSort(col)}
                        </span>
                        {
                          col.children && col.children.length &&
                          <div className="children-tr">
                            {
                              col.children.map((child, i) =>
                                <span key={`${child}${i}`}>
                                  <span>
                                    {i18n.t(`lbl.${child.label}`)}
                                  </span>
                                </span>
                              )
                            }
                          </div>
                        }
                      </th>
                    ))}
                </tr>
              </thead>
            }
            <tbody>
              {(!data || data.length < 1 || (withTotal && data.length - 1 === 0)) ?
                showNoData !== false &&
                <tr className="no-hover mt-10" >
                  <td colSpan={columns.length + 1} className="text-center">
                    {i18n.t('lbl.noRecord')}
                  </td>
                </tr>
                :
                data.map((item, i) => (
                  <tr
                    style={hideIndex?.includes(i) ? { display: 'none' } : {}}
                    key={i}
                    onMouseLeave={() => key === "netekstre/transactions" && highlight && this.setState({ highlight: undefined })}
                    onMouseEnter={() => key === "netekstre/transactions" && (item.parentTransactionId || item.splitStatus === "Split") && this.highlight(item)}
                    className={`
                    ${item.isSumRow ? item.rowIndex === 0 ? 'sum-row sum-row-first' : 'sum-row' : ''} 
                    ${highlight && (item.parentTransactionId === highlight || item.id === highlight) ? 'highlight' : ''}
                    ${withTotal && data.length - 1 === i ? ' total-tr' : ''}
                    ${(!Component || disabled || item.disableOnRowClick) && !clickable ? 'no-hover' : ''}
                    ${editingRow?.index === i ? 'row-editing' : ''}
                  `}
                    onClick={() => {
                      if (!disabled && !item.disableOnRowClick) {
                        if (usePage) {
                          switch (typeof usePage) {
                            case 'string':
                              return this.props.history.push(`/${usePage}/${item.id || item.Id}`);
                            case 'function':
                              return this.props.history.push(`/${usePage(item)}/${item.id || item.Id}`);
                            case 'object':
                              return this.props.history.push(usePage.execute(item));
                            default: break;
                          }
                        }
                        else if (!editingRow) {
                          (onRowClick) ?
                            onRowClick(item)
                            :
                            this.setState({ isDeleteDialog: false, id: item.id || item.Id, showDialog: true })
                        }
                      }

                    }}
                  >
                    {
                      checkbox &&
                      <td>
                        <Checkbox
                          disabled={item.OperationStatus ? !isEditableInvoice(item.OperationStatus.Id) : false}
                          className={`#check${i}`}
                          onChange={(e) => {
                            this.setState({ showDialog: false })
                            this.onSelect(item.id || item.Id)
                          }}
                          checked={(selectedItems.find(x => x === item.id || x === item.Id) || selectAll) ? true : false}
                        />
                      </td>
                    }
                    {
                      //Adding inline action buttons
                      (!initEditable && editingRow?.index === i) &&
                      <td className="editable-table-actions">
                        <div>
                          <Button icon="check" type="primary" onClick={this.onSaveInlineData} loading={datatableInline.single.saving}></Button>
                          <Button icon="close" type="danger" onClick={this.onCancelInlineEdit} ></Button>
                        </div>
                      </td>
                    }
                    {
                      //Add blank td if actionButtons col is not exist
                      !columns.find(x => x.key === "Id" && !!x.render) &&
                      (!initEditable && editingRow && editingRow?.index !== i) &&
                      <td />
                    }
                    {
                      columns.map((col, j) => (
                        (toggle === false || (col.toggle === false || col.visible)) && (checkFields === false || col.checkField === false || (checkFields !== false && data && data[0] && Object.keys(data[0]).includes(col.key))) &&
                        <td key={col.key || j} className={`${col.tdClass ? col.tdClass : ''} ${(col.key === "Id" && !!col.render) ? 'action-buttons' : ''} ${!!col.editable ? 'editable-td' : ''}`}>

                          {
                            <Cell {...{
                              defaultValues: defaultValueFields && this.getDefaultValues(item),
                              showErrors,
                              col,
                              item,
                              i,
                              initEditable,
                              editingRow,
                              setActive: () => this.setState({ editingRow: { index: i, id: item.id || item.Id } }),
                              setLeave: () => this.setState({ editingRow: null })
                            }} />}
                        </td>
                      ))
                    }
                  </tr>
                ))}
              {
                additionalRows?.map(x =>
                  <tr key={Math.random()} className={`no-hover ${x.className || ''}`}>
                    {
                      x.columns.map(y =>
                        <td colSpan={x.columns.length === 1 && columns.length + 1} key={Math.random()} className={y.className || ''}>
                          {y.render?.() || y.value}
                        </td>
                      )
                    }
                  </tr>
                )
              }
            </tbody>
          </table>
        </Form>
      </div >
    );
  };

  openDialog = (isDeleteDialog, id, options) => {
    this.setState({ isDeleteDialog, id, showDialog: true, externalDialogOptions: options })
  }

  collapseTable = (collapsed) => {
    const { onHeaderClick } = this.props
    onHeaderClick && onHeaderClick(null);
    this.setState({ collapsed });

  }

  renderCustomBulkButton = (parentProps) => {
    const { loading, datatableInline } = this.props;
    const { bulkDataError } = this.state
    return (
      <div className="editable-table-save-btn-wrapper">
        <Form.Item validateStatus={!!bulkDataError ? "error" : "success"} help={bulkDataError ? i18n.t(`msg.${bulkDataError}`) : null}>
          <Button
            disabled={loading}
            loading={datatableInline.single.saving}
            className="editable-table-save-btn"
            size="small"
            icon="save"
            type="primary"
            onClick={this.onSaveBulkData}
            children={i18n.t('btn.saveData')}
            {...{ ...parentProps }}
          >
          </Button>
        </Form.Item>
      </div>
    )
  }

  render() {
    const { accessRights } = this.props.auth.data;
    const {
      data, loading = false, pagination, selectedItems, footer, access, title, excel, fromExcel, newButton, toggle, startApprove, startSync, startTransfer, CustomButton, syncStarted,
      approveStarted, disabled, transferStarted, getData, refresh, className, additionalButtons, count, tenantExcelFormats, Component, dialogTitle, dialogNotEditable, dialogType,
      url, currentModule, i18nextLng, hasParent, dialogOptions, deleteOptions, userId, tenantId, initEditable, datatableInline
    } = this.props;
    const { page, visible, columns, selectAll, tableSettings, processing, showDialog, isDeleteDialog, id, collapsed, externalDialogOptions, editingRow, bulkDataError } = this.state;

    const _dialogTitle = dialogTitle + " | " + (isDeleteDialog ? i18n.t('lbl.delete') : (id ? i18n.t('lbl.detail') : i18n.t('lbl.newRecord')));

    return (
      <Card
        className={`table-card ${className ? className : ''} ${(disabled || collapsed) ? 'collapsed-card' : ''} ${title ? '' : 'no-card-title'}`}
        ref={el => this.card = el}
        onClick={() => (collapsed && !disabled) ? this.collapseTable(false) : null}
        title={
          <React.Fragment>
            {
              toggle !== false && !hasParent ?
                <Dropdown overlayClassName={currentModule} overlay={
                  <Menu>
                    <Menu.Item key="all">
                      <Checkbox className="#checkAllMenu" checked={columns.filter(x => x.toggle !== false && !x.visible).length === 0} onChange={(e) => this.toggleAll(e)}>{i18n.t('lbl.all')}</Checkbox>
                    </Menu.Item>
                    {columns.filter(x => x.toggle !== false).map((x, i) => (
                      <Menu.Item key={i}>
                        <Checkbox className={`checkMenu${i}`} checked={x.visible} onChange={(e) => this.toggleColumn(e, i)}>
                          {x && x.label && x.key && this.props.columns.find(y => y && y.key === x.key) && this.props.columns.find(y => y && y.key === x.key).label}
                        </Checkbox>
                      </Menu.Item>
                    ))}
                  </Menu>
                } trigger={['click']} onVisibleChange={(e) => this.setState({ visible: e })} visible={visible}>
                  <span className="pointer">
                    {title || ''}
                    <Icon type="caret-down" className="ml-5" /></span>

                  {/* <Button className="#caretDown" size="small" ic  on="caret-down" /> */}
                </Dropdown>
                :
                title ?
                  <span className="mr-10">{title || ''}</span>
                  :
                  null
            }
            {
              (!hasParent) &&
              !loading && (
                ((!access && newButton) || (access && newButton && accessRights.find(x => x.endPoint === access && x.method === 'POST'))) ||
                additionalButtons ||
                (excel && accessRights.find(x => x.endPoint === (typeof excel === "string" ? excel : excel.url) && x.method === 'GET')) ||
                // (fromExcel && accessRights.find(x => x.endPoint === fromExcel && x.method === 'GET')) ||
                (startApprove && accessRights && accessRights.find(x => x.endPoint === 'ApproveTransactionBulk' && x.method === 'PUT')) ||
                (startSync && accessRights && accessRights.find(x => x.endPoint === 'SyncRuleJobs' && x.method === 'POST')) ||
                (startTransfer && accessRights && accessRights.find(x => x.endPoint === 'SendManuelTransactions' && x.method === 'PUT'))
              ) &&
              <Dropdown disabled={(disabled || collapsed)} overlayClassName={currentModule} className="pull-right" overlay={
                <Menu onClick={this.onMenuClick}>
                  {((!access && newButton) || (access && newButton && accessRights.find(x => x.endPoint === access && x.method === 'POST'))) &&
                    <Menu.Item key="new">
                      <Icon type="plus" />
                      {i18n.t(`btn.${typeof newButton === "string" || typeof newButton === "function" ? "newRecord" : newButton.title}`)}
                    </Menu.Item>
                  }
                  {
                    additionalButtons?.filter(x => x).map((x, i) => {
                      if (x.render)
                        return (<Menu.Item disabled={x.disabled} key={`menuitem-${i}`}>{x.render()} </Menu.Item>)
                      else
                        return (<Menu.Item key={`menuitem-${i}`}>{x} </Menu.Item>)
                    })
                  }
                  {
                    (excel && accessRights.find(x => x.endPoint === (typeof excel === "string" ? excel : excel.url) && x.method === 'GET')) &&
                    tenantExcelFormats.list.data &&
                    tenantExcelFormats.list.data.length > 0 &&
                    <Menu.SubMenu title={<><Icon type="file-excel" /> {i18n.t('btn.excelExport')}</>} disabled={!(data && data.length && !(data.length === 1 && data[data.length - 1]?.isTotalRow))}>
                      <Menu.ItemGroup title={i18n.t('lbl.excelFormat')}>

                        {
                          tenantExcelFormats.list.data.map(format =>
                            <Menu.Item onClick={() => this.onMenuClick({ key: "excel" }, format.id)} key={format.id}>
                              <Icon type="file-excel" />
                              {format.name}
                            </Menu.Item>
                          )
                        }
                      </Menu.ItemGroup>
                    </Menu.SubMenu>
                  }{
                    (excel && accessRights.find(x => x.endPoint === (typeof excel === "string" ? excel : excel.url) && x.method === 'GET')) &&
                    tenantExcelFormats.list.data &&
                    !tenantExcelFormats.list.data.length &&
                    <Menu.Item key="excel" disabled={!(data && data.length && !(data.length === 1 && data[data.length - 1]?.isTotalRow))}>
                      <Icon type="file-excel" />
                      {i18n.t('btn.excelExport')}
                    </Menu.Item>
                  }
                  {
                    fromExcel &&
                    <Menu.Item key="fromExcel">
                      <Upload
                        content={
                          <React.Fragment>
                            <Icon type="file-excel" />
                            {i18n.t('btn.excelImport')}
                          </React.Fragment>
                        }
                        processing={(processing) => this.setState({ processing })}
                        name="File"
                        action={fromExcel.url}
                        baseUrl={fromExcel.baseUrl}
                        getData={getData || null}
                        headers={{ userId, tenantId }}
                      />
                    </Menu.Item>
                  }
                  {(startApprove && accessRights && accessRights.find(x => x.endPoint === 'ApproveTransactionBulk' && x.method === 'PUT')) &&
                    <Menu.Item key="startApprove" disabled={syncStarted || transferStarted}>
                      <Icon type={approveStarted ? 'close' : 'check'} />
                      {i18n.t(`btn.${approveStarted ? 'cancelApprove' : 'startApprove'}`)}
                    </Menu.Item>
                  }
                  {(startSync && accessRights && accessRights.find(x => x.endPoint === 'SyncRuleJobs' && x.method === 'POST')) &&
                    <Menu.Item key="startSync" disabled={approveStarted || transferStarted}>
                      <Icon type={syncStarted ? 'close' : 'swap'} />
                      {i18n.t(`btn.${syncStarted ? 'cancelSyncRule' : 'startSyncRule'}`)}
                    </Menu.Item>
                  }
                  {(startTransfer && accessRights && accessRights.find(x => x.endPoint === 'SendManuelTransactions' && x.method === 'PUT')) &&
                    <Menu.Item key="startTransfer" disabled={approveStarted || syncStarted}>
                      <Icon type={transferStarted ? 'close' : 'swap'} />
                      {i18n.t(`btn.${transferStarted ? 'cancelTransfer' : 'startTransfer'}`)}
                    </Menu.Item>
                  }
                </Menu>
              }>
                <Button loading={processing} disabled={!processing} className="#actions ant-btn-dropdown-type btn-collapse" type="default">{i18n.t('btn.actions')} {!processing && <Icon type="caret-down" />} </Button>
              </Dropdown>
            }
            {
              CustomButton &&
              <React.Fragment>
                <CustomButton
                  selectedItems={[...selectedItems]}
                  selectAll={selectAll}
                  disabled={!data?.length > 0}
                  dataCount={(count) || (data?.[0]?.totalRowCount) || 0}
                />
              </React.Fragment>
            }
            {
              initEditable &&
              <div className="editable-table-save-btn-wrapper">
                <Form.Item validateStatus={!!bulkDataError ? "error" : "success"} help={bulkDataError ? i18n.t(`msg.${bulkDataError}`) : null}>
                  <Button
                    disabled={loading}
                    loading={datatableInline.single.saving}
                    className="editable-table-save-btn"
                    size="small"
                    icon="save"
                    type="primary"
                    onClick={this.onSaveBulkData}
                  >
                    {i18n.t('btn.saveData')}
                  </Button>
                </Form.Item>
              </div>
            }
            {refresh &&
              <Button className="pull-right mr-10" size="small" icon="sync" onClick={() => this.setState({ page: 1 }, () => this.pageChange())}>{i18n.t('btn.refresh')}</Button>
            }
          </React.Fragment>
        }>
        {showDialog && Component &&
          <Dialog
            {...{
              onClose: () => { this.setState({ showDialog: false, id: "", externalDialogOptions: null }) },
              title: _dialogTitle,
              isDeleteDialog,
              Component,
              id,
              url,
              getData,
              type: dialogType,
              notEditable: dialogNotEditable,
              deleteOptions: { ...deleteOptions, stateKey: deleteOptions ? String.prototype.camelize(deleteOptions.stateKey) : undefined },
              dialogOptions: { ...dialogOptions, ...externalDialogOptions }
            }}
          />
        }
        <Loading loading={loading} className="loading-table">
          <Spin className="loading-spin" spinning={datatableInline.single.saving} indicator={<Icon type="loading" />}>
            <React.Fragment>
              {this.renderTable()}
              {this.renderTable(true)}
              {footer && footer}
              {!loading && pagination !== false && this.props.onPageChange &&
                <div className={`pagination ${currentModule}`}>
                  <ConfigProvider locale={lang[i18nextLng]}>
                    <Pagination
                      disabled={!!editingRow || initEditable}
                      className="text-right"
                      size="small"
                      current={page}
                      total={data && ((count) || (data.length ? data[0].totalRowCount : 0))}
                      pageSize={tableSettings.pageSizes}
                      pageSizeOptions={['10', '25', '50', '100', '500', '1000']}
                      showSizeChanger
                      showTotal={this.showTotal}
                      onChange={(page) => this.setState({ page }, () => this.pageChange())}
                      onShowSizeChange={(current, size) => this.onPageSizeChange(size)} />
                  </ConfigProvider>
                </div>
              }
            </React.Fragment>
          </Spin>
        </Loading>
      </Card>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  userId: auth.data.userId,
  tenantId: auth.data.tenantId,
  selectedItems: common.dataTableSelectedItems,
  gridSettings: common.gridSettings,
  tenantExcelFormats: common.tenantExcelFormats,
  currentFilter: common.currentFilter,
  currentModule: common.currentModule,
  i18nextLng: common.i18nextLng,
  datatableInline: common.datatableInline
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedItems: (id, callback) => dispatch(commonActions.setSelectedItems(id, callback)),
  getTenantExcelFormats: () => dispatch(commonActions.getAll({ url: endpoints.lookups.tenantExcelFormats, key: 'tenantExcelFormats' })),
  getSettings: () => dispatch(commonActions.getAll({ url: endpoints.tenant.gridSettings, key: 'gridSettings' })),
  saveSettings: (data, method, callback) => dispatch(commonActions[method]({ url: endpoints.tenant.gridSettings, data }, callback)),
  updateFilter: (skip, page, key) => dispatch(commonActions.updateFilter({ skip, page, key })),

  clearDatatableInlineValue: () => dispatch(commonActions.clearDatatableInlineValue()),
  clearDatatableBulkValues: () => dispatch(commonActions.clearDatatableBulkValues()),
  saveInlineData: (id, data, url, callback) => dispatch(commonActions.patch({ id, url, key: 'datatableInline', data }, callback)),
  saveBulkData: (data, url, method, callback) => dispatch(commonActions[method]({ url, key: 'datatableInline', data }, callback)),

  excelExport: (excel, filter, parameters, module) => dispatch(commonActions.excelExport({ excel, filter, parameters, module })),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Form.create()(DataTable));