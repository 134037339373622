
import Input from "./Input";
import CheckBox from "./CheckBox";
import DatePicker from './DatePicker';
import Phone from './Phone';
import Verification from './Verification';
import Iban from './Iban';
import Textarea from './Textarea';
import SelectableList from './SelectableList';
import Select from './Select';
import Amount from './Amount';
import BigAmount from './BigAmount';
import InputGroup from './InputGroup';



export default class FormElements {
  static Input = Input;
  static CheckBox = CheckBox;
  static DatePicker = DatePicker;
  static Phone = Phone;
  static Verification = Verification;
  static Iban = Iban;
  static Textarea = Textarea;
  static Select = Select;
  static SelectableList = SelectableList;
  static Amount = Amount;
  static BigAmount = BigAmount;
  static InputGroup = InputGroup;
}