import Proxy from './Proxy';
import axios from 'axios';
import { dataUriToBlob } from 'helpers';
import endpoints from 'config/endpoints';

export class PanelProxy extends Proxy {
  exportExcel = (payload) => {
    return this.submit('get', `${payload.url}`, { headers: { UniqueId: payload.id } });
  };

  uploadImg = (image) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      let im = dataUriToBlob(image);
      im.fileName = "asd.png";
      im.name = "asd.png";
      form.append('fileName', im);
      axios.post(endpoints.tenant.imageUpload, form, { headers: { 'Content-Type': false } }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  };

  getVersion = () => {
    return new Promise((resolve) => {
      axios.get('/version.json')
        .then(response => {
          resolve(response)
        })
    })
  }
};