import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, List, Button, Select as AntSelect, notification, Input as AntInput, InputNumber, Checkbox } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { FieldDefinitionModel } from 'models';
import { compareFields } from 'helpers';
import actions from 'store/actions/netekstre';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
const operationType = 3;

class AutomaticRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new FieldDefinitionModel(_.cloneDeep(props.fieldDefinitions.single.data))
    };
  };

  componentDidMount() {
    this.props.getSourceFields();
    this.props.getTargetFields(operationType);
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  targetAdd = () => {
    let { data } = this.state;
    if (data.targets.find(x => x.name === '')) {
      notification.error({ message: i18n.t('msg.pleaseFillOther') })
    }
    else {
      data.targets.push({ dynamicPropertyId: null, index: 0, name: '', type: 3, required: false });
      this.setState({ data })
    }
  };

  targetUpdate = (index, key, value) => {
    let { data } = this.state;
    let { targetFields } = this.props;
    data.targets[index][key] = value;
    if (key === "name") {
      data.targets[index].dynamicPropertyId = value ? targetFields.data.find(x => x.name === value).dynamicPropertyId : null;
    }
    this.setState({ data });
  };

  targetDelete = (index) => {
    let { data } = this.state;
    if (data.targets.filter(x => x.modelState !== 3).length !== 1) {
      let _item = data.targets.filter(x => x.modelState ? x.modelState !== 3 : x)[index];
      _item.modelState === undefined ? data.targets.splice(data.targets.indexOf(_item), 1) : _item.modelState = 3;
      this.setState({ data });
    }
    else {
      notification.error({ message: i18n.t('msg.cannotDeleteLastOne') })
    }
  }

  save = () => {
    return new Promise((resolve) => {
      let { data } = this.state;
      let _data = _.cloneDeep(data);
      _data.operationType = operationType;
      this.props.form.validateFields(error => {
        if (!error) {
          if (_data.targets.length === 0) {
            notification.error({ message: i18n.t('msg.addAtLeastOne') })
          }

          else {
            let originData = { single: { data: { ...this.props.fieldDefinitions.single.data } } }
            _data.targets = compareFields('targets', _data, data.id ? originData : null);

            let method = _data.id ? httpMethods.put : httpMethods.post;
            this.props.saveData(_data, method, (response) => {
              response && resolve();
            });
          }
        }
      });
    })

  };

  render() {
    let { data } = this.state;
    let { getFieldDecorator } = this.props.form;
    let { sourceFields, targetFields, currentModule } = this.props;
    return (
      <div className={currentModule}>
        <Form>
          <Form.Item>
            {
              getFieldDecorator('name', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.name
              })(
                <Input className="#autoRuleName" onChange={(e) => this.update('name', e)} label={i18n.t("lbl.title")} />
              )
            }
          </Form.Item>
          <Form.Item>
            {
              getFieldDecorator('source', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.sourceField
              })(
                <Select className="#autoRuleSourceField" options={sourceFields.data} label={i18n.t("lbl.source")} optName="description" optVal="name" onChange={(e) => this.update('sourceField', e)} />
              )
            }
          </Form.Item>
          <Form.Item>
            {
              getFieldDecorator('pattern', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.pattern
              })(
                <Input className="#autoRulePattern" label={i18n.t("lbl.formulaPattern")} onChange={(e) => this.update('pattern', e)} />
              )
            }
          </Form.Item>
          <Form.Item>
            <h3 className="ml-10">{i18n.t('lbl.target')}</h3>
            <List bordered size="small">
              {
                data.targets.map((x, i) =>
                  x.modelState !== 3 &&
                  <List.Item className="rules-list" actions={[<Button shape="circle" icon="delete" size="small" onClick={() => this.targetDelete(i)} />]} key={i}>
                    <AntInput.Group compact>
                      <AntSelect className={`#autoRuleTargets${i}`} style={{ width: '85%' }} onChange={(e) => this.targetUpdate(i, 'name', e)} value={x.name} size="small">
                        {
                          targetFields.data &&
                          targetFields.data.map((x, i) =>
                            <AntSelect.Option key={(i + 1 * 111) + i} value={x.name}>{x.description}</AntSelect.Option>
                          )
                        }
                      </AntSelect>
                      <InputNumber className="order-item" min={0} style={{ width: '15%' }} size="small" value={x.index} onChange={(e) => this.targetUpdate(i, 'index', e)} />
                    </AntInput.Group>
                    <Checkbox checked={x.required} onChange={(e) => this.targetUpdate(i, 'required', e.target.checked)}>{i18n.t('lbl.required')}</Checkbox>
                  </List.Item>
                )}
              <List.Item>
                <div className="full-width text-center">
                  <Button className="#autoRuleTargetAdd" shape="circle" icon="plus" size="small" onClick={this.targetAdd} />
                </div>
              </List.Item>
            </List>
          </Form.Item>
        </Form>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  sourceFields: netekstre.filter.sourceFields,
  targetFields: netekstre.filter.targetFields,
  fieldDefinitions: netekstre.fieldDefinitions,
  currentModule: common.currentModule
});
const mapDispatchToProps = (dispatch) => ({
  getSourceFields: () => dispatch(actions.getFilter({ url: endpoints.nte.propertyConfigsGetSourceFields, key: 'sourceFields' })),
  getTargetFields: (operationType) => dispatch(actions.getFilter({ url: endpoints.nte.propertyConfigsGetTargetFields(operationType), key: 'targetFields' })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.propertyConfigs, data }, callback)),
  clearState: () => dispatch(actions.clearState("fieldDefinitions")),
});
const AutomaticRuleForm = Form.create()(AutomaticRule);
export default connect(mapStateToProps, mapDispatchToProps)(AutomaticRuleForm);