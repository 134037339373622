import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { VoucherTypeModel } from 'models';
import actions from 'store/actions/admin';
import Loading from 'components/UIComponents/Loading';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import { CheckAccessRight } from 'components/UIComponents';

class VoucherType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new VoucherTypeModel(),
      InitialState: new VoucherTypeModel()
    };
    this.id = props.id;
  };

  componentDidMount() {
    this.props.getErpTypes();
    if (this.id) this.props.getVoucherType(this.id);
    else this.setState({ loading: false });
  };


  static getDerivedStateFromProps(props, state) {
    if (props.voucherTypes.single.data && !state.data.id) {
      const data = new VoucherTypeModel(props.voucherTypes.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.voucherTypes.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;

    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, (response) => {
          if (response) {
            this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
          }
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { voucherTypes, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={voucherTypes.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={voucherTypes.single.loading} form="voucherType" htmlType="submit" className="success #save" icon="save" loading={voucherTypes.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      voucherTypes.single.saving,
      voucherTypes.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { erpTypes, voucherTypes, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...voucherTypes, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="voucherType">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('code', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.code
                    })(
                      <Input className="#code" label={i18n.t('lbl.erpVoucherCode')} onChange={(e) => this.update('code', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#name" label={i18n.t('lbl.erpVoucherName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('erpTypeKey', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.erpTypeKey
                    })(
                      <Select
                        label={i18n.t('lbl.erpType')}
                        options={erpTypes.list && erpTypes.list.data ? erpTypes.list.data : []}
                        optName="name"
                        optVal="id"
                        onChange={(e) => this.update('erpTypeKey', e)}
                      />
                    )}
                  </Form.Item>
                </Col>

              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ admin }) => ({ voucherTypes: admin.voucherTypes, erpTypes: admin.filter.erpTypes, });
const mapDispatchToProps = (dispatch) => ({
  getVoucherType: (id, callback) => dispatch(actions.get({ url: endpoints.tenant.voucherTypes, key: 'voucherTypes', id }, callback)),
  getErpTypes: () => dispatch(actions.getAll({ url: endpoints.lookups.erpTypes, key: 'filter.erpTypes' })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tenant.voucherTypes, key: 'voucherTypes', data }, callback)),
  clearState: () => dispatch(actions.clearState("voucherTypes")),
});
const VoucherTypeForm = Form.create()(VoucherType);
export default connect(mapStateToProps, mapDispatchToProps)(VoucherTypeForm);