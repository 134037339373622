import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/tos';
import { formatIBAN } from 'helpers';
import { DataTable, Filter, ActionButtons, FadeIn } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import BankAccount from './Detail'
import utils from 'lib';

class BankAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.tos.RecipientAccounts(),
    };
  };

  componentDidMount() {
    this.getRecipientAccounts();
    utils.tos.getTenantBanks();
    utils.common.getCurrencies();
    utils.common.checkAccessToUpdateOrDelete('BankAccounts'); //!CustomerBankAccounts
  };

  getRecipientAccounts = () => {
    let { filter } = this.state;
    this.props.getRecipientAccounts(filter);
  };


  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getRecipientAccounts);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getRecipientAccounts);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter },
      this.getRecipientAccounts
    );
  }

  getCurrencyCode = ({ CurrencyCode }) => {
    const { currencies } = this.props;
    let currency = currencies.data ? currencies.data.find(({ currencyCode }) => currencyCode === CurrencyCode) : null;
    return currency ? currency.currencySymbol : '';
  };

  render() {
    let { recipientAccounts, tenantBanks } = this.props;
    let filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.tos.getBankName({ bankData }), keys: ['Bank/EftCode'], showAll: true, callback: this.onSelect, clear: ['1'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Name', 'BranchNumber', 'AccountNumber', 'Iban'], contains: true, uniqueKey: '#search' }
    ];
    let columns = [
      {
        render: (row) =>
          <ActionButtons
            url="CustomerBankAccounts"
            editUrl
            item={row}
            hasDelete
            getDatas={this.getRecipientAccounts}
            openDialog={this.datatable && this.datatable.openDialog}
          />,
        toggle: false, sort: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.bank'), key: 'Bank/Name', render: ({ Bank }) => utils.tos.getBankName({ data: Bank, dataField: 'EftCode', listField: 'Code' }), checkField: false },
      { label: i18n.t('lbl.branchName'), key: 'BranchName' },
      { label: i18n.t('lbl.accountName'), key: 'Name' },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode', render: this.getCurrencyCode, tdClass: 'text-center' },
      { label: i18n.t('lbl.branchNumber'), key: 'BranchNumber' },
      { label: i18n.t('lbl.accountNumber'), key: 'AccountNumber' },
      { label: i18n.t('lbl.iban'), key: 'Iban', render: (row) => formatIBAN(row.Iban) },
    ];


    return (
      <FadeIn className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="CustomerBankAccounts"
          history={this.props.history}
          newButton="openDialog"
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={recipientAccounts.list.data}
          loading={recipientAccounts.list.loading}
          title={i18n.t('route.tos.recipientAccounts')}
          getData={this.getRecipientAccounts}
          Component={BankAccount}
          dialogTitle={i18n.t('routes.recipientAccounts')}
          count={recipientAccounts.list.count}
          deleteOptions={{ stateKey: "recipientAccounts", url: "CustomerBankAccounts", baseUrl: baseUrls.tenant }}
        />
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ tos, common }) => ({
  recipientAccounts: tos.recipientAccounts,
  tenantBanks: tos.filter.tenantBanks,
  currencies: common.filter.currencies,
  // filterBanks: tos.filter.recipientAccounts,
});
const mapDispatchToProps = (dispatch) => ({
  getRecipientAccounts: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tos.recipientAccounts, key: 'recipientAccounts', isNewODataStructure: true })),
  // getFilterAccounts: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.lookups.bankAccounts, key: 'recipientAccounts' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);