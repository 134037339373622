export default {
  path: '/auth',
  name: 'auth',
  component: require('components/Auth/Layout').default,
  routes: [
    {
      path: '/sign-in',
      name: 'signIn',
      component: require('components/Auth/Views/SignIn').default,
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: require('components/Auth/Views/ForgotPassword').default,
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: require('components/Auth/Views/ResetPassword').default,
    },
    {
      path: '/unblockforuser',
      name: 'unblockForUser',
      component: require('components/Auth/Views/UnblockForUser').default,
    }
  ]
};