export const GET_ALL_DBS = "GET_ALL_DBS";
export const GET_ALL_DBS_SUCCESS = "GET_ALL_DBS_SUCCESS";
export const GET_ALL_DBS_FAILURE = "GET_ALL_DBS_FAILURE";
export const GET_DBS = "GET_DBS";
export const GET_DBS_SUCCESS = "GET_DBS_SUCCESS";
export const GET_DBS_FAILURE = "GET_DBS_FAILURE";
export const POST_DBS = "POST_DBS";
export const POST_DBS_SUCCESS = "POST_DBS_SUCCESS";
export const POST_DBS_FAILURE = "POST_DBS_FAILURE";
export const PUT_DBS = "PUT_DBS";
export const PUT_DBS_SUCCESS = "PUT_DBS_SUCCESS";
export const PUT_DBS_FAILURE = "PUT_DBS_FAILURE";
export const DELETE_DBS = "DELETE_DBS";
export const DELETE_DBS_SUCCESS = "DELETE_DBS_SUCCESS";
export const DELETE_DBS_FAILURE = "DELETE_DBS_FAILURE";
export const PATCH_DBS = "PATCH_DBS";
export const PATCH_DBS_SUCCESS = "PATCH_DBS_SUCCESS";
export const PATCH_DBS_FAILURE = "PATCH_DBS_FAILURE";
export const GET_FILTER_DBS = "GET_FILTER_DBS";
export const GET_FILTER_DBS_SUCCESS = "GET_FILTER_DBS_SUCCESS";
export const GET_FILTER_DBS_FAILURE = "GET_FILTER_DBS_FAILURE";
export const CLEAR_STATE_DBS = "CLEAR_STATE_DBS";
export const CLEAR_FILTER_DBS = "CLEAR_FILTER_DBS";
export const GET_WITH_POST_DBS = "GET_WITH_POST_DBS";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_DBS, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_DBS_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_DBS_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_DBS, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_DBS_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_DBS_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_DBS, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_DBS_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_DBS_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_DBS, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_DBS_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_DBS_FAILURE, payload })
  },

  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_DBS, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_DBS, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_DBS_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_DBS_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_DBS, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_DBS_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_DBS_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_DBS, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_DBS_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_DBS_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key) => {
    return ({ type: CLEAR_STATE_DBS, key })
  },

  clearFilter: (key) => {
    return ({ type: CLEAR_FILTER_DBS, key })
  }
  //#endregion
}

export default actions;