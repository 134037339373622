import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Empty, Icon, Collapse } from 'antd';
import { Pie } from 'react-chartjs-2';
import _ from 'lodash';
import 'chartjs-plugin-labels';
import { Loading, Description } from 'components/UIComponents';
import { formatCurrency, formatChartData, convertFieldsToCamelize } from 'helpers';
import Fade from 'react-reveal/Fade';
import i18n from 'plugins/i18n';
import { chartTypes } from 'models';
import { chartOptions } from 'lib/constants';

const PieChart = ({ data, loading, auth, multiTenant, currencyCode, onLineClick, title, accessRight, type, currentModule }) => {
  const pieChartData = data ? _.cloneDeep(formatChartData({ series: data.series, labels: data.xAxis, type: chartTypes.pieChart })) : {};
  const tenants = _.cloneDeep(auth.data.tenants);
  return (
    <Card>
      <Card.Grid className="full-width">
        {
          auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method) &&
          <h4 className="text-center">{title}</h4>
        }
        <Loading loading={loading} className="loading-card">
          {
            !auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method) ?
              <div className="no-access-icon">
                <div className="no-access-right">
                  <i className="fas fa-exclamation-triangle"></i>
                  {i18n.t('msg.noAccessRightChart')}
                </div>
              </div>
              :
              <div style={{ minHeight: 400 }} className="centered">
                {
                  pieChartData.labels && pieChartData.labels.length ?
                    <Pie getElementAtEvent={line => onLineClick(line, type)} data={pieChartData} options={chartOptions.pieChart({ multiTenant })} />
                    :
                    <Empty description={i18n.t('msg.noData')} />
                }
              </div>
          }
        </Loading>
      </Card.Grid>

      <Card.Grid className={`full-width ${multiTenant ? "no-padding" : ""}`}>
        {
          multiTenant && currentModule === 'netekstre' ?
            <Collapse className="dashboard-collapse" expandIcon={() => <span></span>} accordion>
              <Collapse.Panel header={
                <Row>
                  <Col span={12} className="text-center pointer">
                    <Loading loading={loading}>
                      <React.Fragment>
                        <h1 className={type === "incomingTotals" ? "text-green" : "text-red"}>{data && data.count}</h1>
                        <h4><Icon className="clickable-dashboard bank-based" type="right-circle" />{i18n.t('lbl.countProcess')}</h4>
                      </React.Fragment>
                    </Loading>
                  </Col>
                  <Col span={12} className="text-center pointer">
                    <Loading loading={loading}>
                      <React.Fragment>
                        <h1 className={type === "incomingTotals" ? "text-green" : "text-red"}>{data && data.total !== null ? formatCurrency({ data: data.total, currencyCode }) : ''}</h1>
                        <h4><Icon className="clickable-dashboard bank-based" type="right-circle" />{i18n.t('lbl.totalProcess')}</h4>
                      </React.Fragment>
                    </Loading>
                  </Col>
                </Row>
              } key="1">
                <div className="statement-container green">
                  {
                    data && tenants.sort((a, b) => a.name.localeCompare(b.name)).map((tenant, i) => { return { tenantName: tenant.name, data: data.bankDetails[0].chartDetails.map(bank => bank.details.find(item => item.name === tenant.name)) } }).map((x, i) =>
                      <Row key={i} gutter={20} className="statement-row">
                        <Col span={12}>
                          <Fade cascade top>
                            <div className="flex">
                              <b>
                                <Description text={x.tenantName} limit={22} />
                              </b>
                              <div><b>{x.data.reduce((a, b) => a + b.count, 0) === 0 ? '--' : <span className="text-green">{x.data.reduce((a, b) => a + b.count, 0)} Adet İşlem</span>}</b></div>
                            </div>
                          </Fade>
                        </Col>

                        <Col span={12}>
                          <Fade cascade top>
                            <div className="flex">
                              <b>
                                <Description text={x.tenantName} limit={22} />
                              </b>
                              <div><b>{x.data.reduce((a, b) => a + b.total, 0) === 0 ? '--' : formatCurrency({ data: x.data.reduce((a, b) => a + b.total, 0), currencyCode, withColor: true })}</b></div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                    )
                  }
                </div>
              </Collapse.Panel>
            </Collapse>
            :
            auth.data.accessRights.find(x => x.endPoint === accessRight.endpoint && x.method === accessRight.method) ?
              <Row>
                <Col span={12} className="text-center">
                  <Loading loading={loading}>
                    <React.Fragment>
                      <h1 className={type === "incomingTotals" ? "text-green" : "text-red"}>{data && data.count}</h1>
                      <h4>{i18n.t('lbl.countProcess')}</h4>
                    </React.Fragment>
                  </Loading>
                </Col>
                <Col span={12} className="text-center">
                  <Loading loading={loading}>
                    <React.Fragment>
                      <h1 className={type === "incomingTotals" ? "text-green" : "text-red"}>{data && data.total !== null ? formatCurrency({ data: parseInt(data.total), currencyCode }) : ''}</h1>
                      <h4>{i18n.t('lbl.totalProcess')}</h4>
                    </React.Fragment>
                  </Loading>
                </Col>
              </Row>
              :
              null
        }
      </Card.Grid>
    </Card >
  )
}

const mapStateToProps = (state, { type, toCamel }) => ({
  auth: state.auth,
  data: toCamel ? convertFieldsToCamelize(state[state.common.currentModule][type].single.data) : state[state.common.currentModule][type].single.data,
  loading: state[state.common.currentModule][type].single.loading,
  multiTenant: state.common.multiTenantDashboard,
  common: state.common,
  currentModule: state.common.currentModule
});
export default connect(mapStateToProps)(PieChart);
