export default {
  agreements: [

  ],
  applications: {
    data: [],
    singleData: null,
    error: null,
    loading: false,
    saving: false,
  },
  activeTransaction: {
    data: [],
    singleData: null,
    error: null,
    loading: false,
    saving: false,
  },
}