import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { Checkbox } from 'antd';
import { formatIBAN } from 'helpers';
import { DataTable, Filter, ActionButtons, FadeIn } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import BankAccount from './Detail'
import utils from 'lib';

class BankAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.common.BankAccounts(props),
      currentBank: null
    };
  };

  componentDidMount() {
    this.getBankAccounts();
    this.props.getFilterAccounts();
    utils.common.getTenantBanks();
    utils.common.getCurrencies();
    utils.common.checkAccessToUpdateOrDelete('BankAccounts');

    utils.netekstre.getTransferTypes();
  };

  getBankAccounts = () => {
    let { filter } = this.state;
    this.props.getBankAccounts(filter);
  };


  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getBankAccounts);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getBankAccounts);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter },
      this.getBankAccounts
    );
  }

  onSelect = (value) => {
    let { filter } = this.state;
    filter.filter = !value ? {} : filter.filter;
    this.setState({ currentBank: value, filter });
  };

  getCurrencyCode = ({ CurrencyCode }) => {
    const { currencies } = this.props;
    let currency = currencies.data ? currencies.data.find(({ currencyCode }) => currencyCode === CurrencyCode) : null;
    return currency ? currency.currencySymbol : '';
  };

  getTransferName = ({ TransactionTransferType }) => {
    const { transferTypes } = this.props;
    let type = transferTypes.data ? transferTypes.data.find(({ name }) => name === TransactionTransferType) : null;
    return type ? type.description : '';
  };

  render() {
    let { bankAccounts, tenantBanks, filterBanks } = this.props;
    let { currentBank } = this.state;
    let _accounts = filterBanks.data ? (currentBank && currentBank.length) ? filterBanks.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : filterBanks.data : [];

    let filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'bankEftCode', name: (bankData) => utils.common.getBankName({ bankData }), keys: ['TenantBank/Bank/EftCode'], showAll: true, callback: this.onSelect, clear: ['1'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.accountName'), type: 'select', search: true, options: _accounts, value: 'id', name: 'name', keys: ['Id'], uniqueKey: '#accountName', multiple: true, guid: true },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['Name', 'ErpCode', 'BranchNumber', 'AccountNumber', 'Iban'], contains: true, uniqueKey: '#search' }
    ];
    let columns = [
      {
        render: (row) =>
          <ActionButtons
            url="BankAccounts"
            editUrl
            item={row}
            hasDelete
            getDatas={this.getBankAccounts}
            openDialog={this.datatable && this.datatable.openDialog}
          />,
        toggle: false, sort: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.bank'), key: 'TenantBank/Bank/Name', render: ({ TenantBank }) => utils.common.getBankName({ data: TenantBank.Bank, dataField: 'EftCode', listKeys: 'tenantBanks', listField: 'bankEftCode' }), checkField: false },
      { label: i18n.t('lbl.branchName'), key: 'BranchName' },
      { label: i18n.t('lbl.accountName'), key: 'Name' },
      { label: i18n.t('lbl.erpCode'), key: 'ErpCode' },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode', render: this.getCurrencyCode, tdClass: 'text-center' },
      { label: i18n.t('lbl.branchNumber'), key: 'BranchNumber' },
      { label: i18n.t('lbl.accountNumber'), key: 'AccountNumber' },
      { label: i18n.t('lbl.criticalBalanceLimit'), key: 'criticalBalanceLimit' }, // BE bu fieldı eklediğinde list kısmına dahil edecektir.
      { label: i18n.t('lbl.suffix'), key: 'Suffix' },
      { label: i18n.t('lbl.iban'), key: 'Iban', render: (row) => formatIBAN(row.Iban) },
      { label: i18n.t('lbl.transactionTransferType'), key: 'TransactionTransferType', render: this.getTransferName },
      { label: i18n.t('lbl.active'), key: 'IsActive', sort: false, render: (row) => <Checkbox checked={row.IsActive} /> }
    ];


    return (
      <FadeIn className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="BankAccounts"
          history={this.props.history}
          newButton="openDialog"
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={bankAccounts.list.data}
          loading={bankAccounts.list.loading}
          excel={{ url: "ExportBankAccounts", module: "tenant" }}
          fromExcel={{ url: "ImportsBankAccounts", baseUrl: baseUrls.tenant }}
          title={i18n.t('route.common.bankAccounts')}
          getData={this.getBankAccounts}
          Component={BankAccount}
          dialogTitle={i18n.t('routes.bankAccount')}
          count={bankAccounts.list.count}
          deleteOptions={{ stateKey: "bankAccounts", url: "BankAccounts", baseUrl: baseUrls.tenant }}
        />
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  bankAccounts: common.bankAccounts,
  filterBanks: common.filter.bankAccounts,
  tenantBanks: common.filter.tenantBanks,
  transferTypes: netekstre.filter.transactionTransferTypes,
  currencies: common.filter.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getBankAccounts: (filter) => dispatch(actions.getAll({ filter, url: endpoints.tenant.bankAccounts, key: 'bankAccounts', isNewODataStructure: true })),
  getFilterAccounts: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.lookups.bankAccounts, key: 'bankAccounts' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);