import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'plugins'
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import 'assets/scss/netekstre_old/main.css'
import 'assets/less/main.css';
import { Provider } from 'react-redux';
import createStore from 'store/redux';
import 'currency-flags/dist/currency-flags.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-credit-cards/es/styles-compiled.css'
import "module";


ReactDOM.render(
  <Provider store={createStore()}>
    <App />
  </Provider>, document.getElementById('root'));

serviceWorker.unregister();
