export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const VERIFY_TWO_FACTOR = "VERIFY_TWO_FACTOR";
export const VERIFY_TWO_FACTOR_SUCCESS = "VERIFY_TWO_FACTOR_SUCCESS";
export const VERIFY_TWO_FACTOR_FAILURE = "VERIFY_TWO_FACTOR_FAILURE";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAILURE = "GET_ACCOUNT_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const DO_BLOCKED_USER_TO_ACTIVE = "DO_BLOCKED_USER_TO_ACTIVE";
export const DO_BLOCKED_USER_TO_ACTIVE_SUCCESS = "DO_BLOCKED_USER_TO_ACTIVE_SUCCESS";
export const DO_BLOCKED_USER_TO_ACTIVE_FAILURE = "DO_BLOCKED_USER_TO_ACTIVE_FAILURE";
export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";



const actions = {
  //#region [SIGN IN] 
  signIn: (payload, callback) => {
    return ({ type: SIGN_IN, payload, callback })
  },
  signInSuccess: (payload) => {
    return ({ type: SIGN_IN_SUCCESS, payload })
  },
  signInFailure: (payload) => {
    return ({ type: SIGN_IN_FAILURE, payload })
  },
  //#endregion

  //#region [VERIFY TWO FACTOR] 
  verifyTwoFactor: (payload, callback) => {
    return ({ type: VERIFY_TWO_FACTOR, payload, callback })
  },
  verifyTwoFactorSuccess: (payload) => {
    return ({ type: VERIFY_TWO_FACTOR_SUCCESS, payload })
  },
  verifyTwoFactorFailure: (payload) => {
    return ({ type: VERIFY_TWO_FACTOR_FAILURE, payload })
  },
  //#endregion

  //#region [GET ACCOUNT]
  getAccount: (callback) => {
    return ({ type: GET_ACCOUNT, callback })
  },
  getAccountSuccess: (payload) => {
    return ({ type: GET_ACCOUNT_SUCCESS, payload })
  },
  getAccountFailure: (payload) => {
    return ({ type: GET_ACCOUNT_FAILURE, payload })
  },
  //#endregion

  //#region [LOGOUT]
  logout: (callback) => {
    return ({ type: LOGOUT, callback })
  },
  logoutSuccess: (payload) => {
    return ({ type: LOGOUT_SUCCESS, payload })
  },
  logoutFailure: (payload) => {
    return ({ type: LOGOUT_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  forgotPassword: (payload, callback) => {
    return ({ type: FORGOT_PASSWORD, payload, callback })
  },
  resetPassword: (payload, callback) => {
    return ({ type: RESET_PASSWORD, payload, callback })
  },
  resetPasswordSuccess: (payload, callback) => {
    return ({ type: RESET_PASSWORD_SUCCESS, payload, callback })
  },
  resetPasswordFailure: (payload, callback) => {
    return ({ type: RESET_PASSWORD_FAILURE, payload, callback })
  },

  doBlockedUserToActive: (payload, callback) => {
    return ({ type: DO_BLOCKED_USER_TO_ACTIVE, payload, callback })
  },
  doBlockedUserToActiveSuccess: (payload, callback) => {
    return ({ type: DO_BLOCKED_USER_TO_ACTIVE_SUCCESS, payload, callback })
  },
  doBlockedUserToActiveFailure: (payload) => {
    return ({ type: DO_BLOCKED_USER_TO_ACTIVE_FAILURE, payload })
  },

  resendOtp: (payload, callback) => {
    return ({ type: RESEND_OTP, payload, callback })
  },
  resendOtpSuccess: (payload, callback) => {
    return ({ type: RESEND_OTP_SUCCESS, payload, callback })
  },
  resendOtpFailure: (payload, callback) => {
    return ({ type: RESEND_OTP_FAILURE, payload, callback })
  },
  //#endregion
}

export default actions;