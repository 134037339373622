import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import endpoints from 'config/endpoints';
import { Card, Row, Col, Collapse } from 'antd';
import { CurrencyCard, CurrencyHead } from './CurrencyRate'
import { Loading } from "components/UIComponents";

class CurrencyRates extends Component {
  state = { activeKey: '-1' }

  componentDidMount() {
    this.props.getCurrencyRates({ Type: 1 }) //Merkez Bankası
    this.props.getTenantExchangeType();
  }

  isUseEffective = ({ single }) => {
    if (single.data) {
      switch (single.data.item) {
        case "Effective": return true;
        case "Normal": return false;
        default: return null;
      }
    }
    return null;
  }

  render() {
    const { activeKey } = this.state;
    const { currencyRates, tenantExchangeType } = this.props;
    const isEffectiveCurrencyCode = this.isUseEffective(tenantExchangeType);
    return (
      <Card className="mb-50 filter-card table-card currency-rates">
        <Loading loading={currencyRates.single.loading}>
          <>
            {
              currencyRates.single.data && currencyRates.single.data.exchangeRates && currencyRates.single.data.exchangeRates.length > 0 &&
              <Collapse activeKey={activeKey} onChange={(e) => { this.setState({ activeKey: activeKey === '1' ? '-1' : '1' }) }}>
                <Collapse.Panel key="1" header={<CurrencyHead isEffectiveCurrencyCode={isEffectiveCurrencyCode} data={currencyRates.single.data.exchangeRates} isOpen={activeKey === '1'} />}>
                  <Row gutter={20}>
                    {
                      currencyRates.single.data.exchangeRates.map(item =>
                        <Col xs={12} sm={6} md={6} lg={4} key={item.from} >
                          <CurrencyCard {...{ item, isEffectiveCurrencyCode }} />
                        </Col>
                      )
                    }
                    <Col>

                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            }
          </>
        </Loading>
      </Card>
    );
  };
};
const mapStateToProps = ({ netekstre, custom }) => ({ currencyRates: netekstre.currencyRates, tenantExchangeType: netekstre.tenantExchangeType, ...custom });
const mapDispatchToProps = (dispatch) => ({
  getCurrencyRates: (parameters) => dispatch(actions.get({ url: endpoints.rates.exchangeRates, parameters, key: 'currencyRates', })),
  getTenantExchangeType: (parameters) => dispatch(actions.get({ url: endpoints.lookups.tenantExchangeType, parameters, key: 'tenantExchangeType', })),
});
export default connect(mapStateToProps, mapDispatchToProps)(CurrencyRates);