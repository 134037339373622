export class CekParameterModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.ServiceTypeId = data && data.ServiceTypeId ? data.ServiceTypeId : 2;
    this.ChequeCode = data && data.ChequeCode ? data.ChequeCode : undefined;
    this.Title = data && data.Title ? data.Title : undefined;
    this.Host = data && data.Host ? data.Host : undefined;
    this.Port = data && data.Port ? data.Port : undefined;
    this.UploadFolder = data && data.UploadFolder ? data.UploadFolder : undefined;
    this.DownloadFolder = data && data.DownloadFolder ? data.DownloadFolder : undefined;
    this.UserName = data && data.UserName ? data.UserName : undefined;
    this.Password = data && data.Password ? data.Password : undefined;
    this.BankTenantCode = data && data.BankTenantCode ? data.BankTenantCode : undefined;
  };
}

export class ChequeModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.BankCode = data && data.BankCode ? data.BankCode : undefined;
    this.ChequeUserName = data && data.ChequeUserName ? data.ChequeUserName : '';
    this.ChequeUserSurname = data && data.ChequeUserSurname ? data.ChequeUserSurname : '';
    this.ChequeUserVkn = data && data.ChequeUserVkn ? data.ChequeUserVkn : undefined;
    this.ChequeUserTckn = data && data.ChequeUserTckn ? data.ChequeUserTckn : undefined;
    this.MICR = data && data.MICR ? data.MICR : undefined;
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : undefined;
    this.ChequeCode = data && data.ChequeCode ? data.ChequeCode : undefined;
    this.ChequeDate = data && data.ChequeDate ? data.ChequeDate : undefined;
    this.City = data && data.City ? data.City : undefined;
    this.CollectDate = data && data.CollectDate ? data.CollectDate : undefined;
    this.Amount = data && data.Amount ? data.Amount : '';
    this.Description = data && data.Description ? data.Description : '';
    this.OperationStatusMessage = data && data.OperationStatusMessage ? data.OperationStatusMessage : '';
  };
}

export class ChequeCreateOrSendOrderModel {
  constructor(data = null) {
    this.Description = data && data.Description ? data.Description : '';
    this.SendDate = data && data.SendDate ? data.SendDate : undefined;
    this.TenantPaymentAccountId = data && data.TenantPaymentAccountId ? data.TenantPaymentAccountId : undefined;
    this.PayRollId = data && data.PayRollId ? data.PayRollId : undefined;
    this.ChequesIdList = data && data.ChequesIdList ? data.ChequesIdList : undefined;
  };
}

export class ChequeReportingModel {
  constructor(data = null) {
    this.Id = data && data.Id ? data.Id : undefined;
    this.InsertedTime = data && data.InsertedTime ? data.InsertedTime : undefined;
    this.InsertedUserId = data && data.InsertedUserId ? data.InsertedUserId : undefined;
    this.UpdatedUserId = data && data.UpdatedUserId ? data.UpdatedUserId : null;
    this.UpdatedTime = data && data.UpdatedTime ? data.UpdatedTime : undefined;
    this.TenantId = data && data.TenantId ? data.TenantId : undefined;
    this.PayRollId = data && data.PayRollId ? data.PayRollId : undefined;
    this.Amount = data && data.Amount ? data.Amount : '';
    this.BankCode = data && data.BankCode ? data.BankCode : '';
    this.CurrencyCode = data && data.CurrencyCode ? data.CurrencyCode : null;
    this.BankStatusId = data && data.BankStatusId ? data.BankStatusId : null;
    this.ErpStatusId = data && data.ErpStatusId ? data.ErpStatusId : null;
    this.OperationStatusId = data && data.OperationStatusId ? data.OperationStatusId : null;
    this.OperationStatusMessage = data && data.OperationStatusMessage ? data.OperationStatusMessage : undefined;
    this.CanBeManuelTransferred = data && data.CanBeManuelTransferred ? data.CanBeManuelTransferred : null;
    this.ErpCode = data && data.ErpCode ? data.ErpCode : null;
    this.ErpDescription = data && data.ErpDescription ? data.ErpDescription : null;
    this.Description = data && data.Description ? data.Description : '';
    this.Title = data && data.Title ? data.Title : '';
    this.ChequeCode = data && data.ChequeCode ? data.ChequeCode : '';
    this.City = data && data.City ? data.City : '';
    this.MICR = data && data.MICR ? data.MICR : '';
    this.ChequeUserName = data && data.ChequeUserName ? data.ChequeUserName : '';
    this.ChequeUserSurname = data && data.ChequeUserSurname ? data.ChequeUserSurname : '';
    this.ChequeUserVkn = data && data.ChequeUserVkn ? data.ChequeUserVkn : '';
    this.ChequeUserTckn = data && data.ChequeUserTckn ? data.ChequeUserTckn : '';
    this.ChequeDate = data && data.ChequeDate ? data.ChequeDate : null;
  };
};