import React, { Component } from 'react';
import { Col, Row, Input as AntInput } from 'antd';


class InputGroup extends Component {
  state = { isFocusGroupElement: new Array(this.props.children.length).fill(false) };

  focusOnChange = (index, isFocused) => {
    const { isFocusGroupElement } = this.state;
    isFocusGroupElement[index] = isFocused;
    this.setState({ isFocusGroupElement })
  }

  render() {
    const { children, label } = this.props;
    const { isFocusGroupElement } = this.state
    const anyFocusedElement = isFocusGroupElement.some(x => !!x)

    return (
      <AntInput.Group className={anyFocusedElement ? "is-focused-group" : ''}>
        <Row>
          {
            children.map((component, index) => {
              return (
                <Col key={index + component.props.className} span={component.props.span || (24 / children.length)}>
                  {
                    {
                      ...component,
                      props: {
                        ...component.props,
                        onFocus: isFocused => this.focusOnChange(index, isFocused),
                        onBlur: isFocused => this.focusOnChange(index, isFocused),
                        forceFocus: index === 0 && anyFocusedElement,
                        label: index === 0
                          ? label
                          : anyFocusedElement && component.props.label,
                        placeholder: (index !== 0 && anyFocusedElement) || isFocusGroupElement[index]
                          ? component.props.placeholder
                          : undefined
                      }
                    }
                  }
                </Col>
              )
            })
          }
        </Row>
      </AntInput.Group>
    )
  }
}
export default InputGroup
