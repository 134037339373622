import React, { Component } from 'react';
import { connect } from "react-redux";
import { ActionButtons, DataTable, Filter } from "components/UIComponents";
import i18n from "plugins/i18n";
import actions from "store/actions/cek";
import endpoints from "config/endpoints";
import utils from "lib";
import { formatCurrency, formatDate } from "helpers";
import { defaultFilters } from "lib/constants";
import Cheque from './DeleteDetail';

class Payrolls extends Component {
  state = {
    filter: defaultFilters.cek.Payrolls()
  };

  componentDidMount() {
    this.getPayrolls();
    utils.cek.getTenantBanks();
    utils.cek.getOperationStatus({ filter: { OperationStatusTypeId: { eq: 2 } } })
  }

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getPayrolls = () => {
    const { filter } = this.state;
    this.props.getPayrolls(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getPayrolls);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getPayrolls);
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1)
    this.setState({ filter }, this.getPayrolls);
  };

  render() {
    const { payrolls, tenantBanks, operationStatuses } = this.props;

    const filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.cek.getBankName({ bankData }), keys: ['BankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.orderStatus'), type: 'select', search: true, options: operationStatuses.data, value: 'Id', name: 'Name', keys: ['OperationStatusId'], uniqueKey: '#OperationStatus', multiple: true },
      { label: i18n.t('lbl.amount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['TotalAmount'], uniqueKey: '#minAmount' },
      { label: i18n.t('lbl.amount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['TotalAmount'], uniqueKey: '#maxAmount' },
      { label: i18n.t('lbl.description'), type: 'input', keys: ['Description'], contains: true, uniqueKey: '#description' },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="Payroll" editUrl item={row} hasDelete getDatas={this.getPayrolls} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'BankCode', render: (data) => utils.tos.getBankName({ data }) },
      { label: i18n.t('lbl.kesideDate'), key: 'ChequeDate', render: ({ ChequeDate }) => formatDate(ChequeDate, null, null, null, false) },
      { label: i18n.t('lbl.orderStatus'), key: 'OperationStatus/Name', checkField: false },
      { label: i18n.t('tos.piece'), key: 'TotalCount' },
      { label: i18n.t('lbl.amount'), key: 'TotalAmount', render: ({ TotalAmount, CurrencyCode }) => formatCurrency({ data: TotalAmount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('lbl.description'), key: 'Description' },
    ]
    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          count={payrolls.list.count}
          wrappedComponentRef={el => this.datatable = el}
          access="Payroll"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={payrolls.list.data}
          loading={payrolls.list.loading}
          title={i18n.t('routes.chequeList')}
          getData={this.getPayrolls}
          Component={Cheque}
          dialogTitle={i18n.t('lbl.Cheque')}
          usePage="cek/cheque"
          deleteOptions={{ stateKey: "payrolls", url: "Payroll" }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ cek }) => ({
  payrolls: cek.payrolls,
  tenantBanks: cek.filter.tenantBanks,
  operationStatuses: cek.filter.operationStatuses
});
const mapDispatchToProps = (dispatch) => ({
  getPayrolls: (filter) => dispatch(actions.getAll({ filter, url: endpoints.cek.payrolls, key: 'payrolls' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Payrolls);