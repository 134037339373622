import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/dbs';
import { ActionButtons, DataTable, Filter, FadeIn, CheckAccessRight } from 'components/UIComponents';
import { defaultFilters, baseUrls } from "lib/constants";
import endpoints from 'config/endpoints';
import { serviceTypes } from "helpers";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Parameter from './Detail';

const PageAccesses = {
  DbsAccount: "DbsAccount",
  AccountParameter: "AccountParameter"
}

class Parameters extends Component {
  state = {
    filter: defaultFilters.dbs.Parameters(),
    getDataTrigger: undefined,
    access: PageAccesses.DbsAccount
  }

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id }
      const filter = state.filter
      props.getParameters(filter, parameters, endpoints.dbs.accountParameter);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  componentDidMount() {
    const { getDataTrigger } = this.props;

    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getParameters();
      access = PageAccesses.DbsAccount;
    }
    else {
      access = PageAccesses.AccountParameter;
    }
    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })

    utils.dbs.getTenantBanks('DbsAccount');
  };


  getParameters = () => {
    const { getDataTrigger } = this.props;
    let { filter } = this.state;
    if (getDataTrigger)
      this.props.getParameters(filter, { TenantId: getDataTrigger.id }, endpoints.dbs.accountParameter);
    else
      this.props.getParameters(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getParameters);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getParameters);
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getParameters);
  }

  renderActionButtons = row => {
    const { access } = this.state;
    return <ActionButtons url={access} editUrl item={row} hasDelete getDatas={this.getParameters} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { parameters, tenantBanks, showFilter, className, datatableCollapsed, hasParent, datatableTitle, getDataTrigger } = this.props;
    const { access } = this.state
    const filters = [
      { label: i18n.t('dbs.bankName'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.dbs.getBankName({ bankData }), keys: ['bankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('dbs.serviceType'), type: 'select', search: true, options: serviceTypes, value: 'id', name: 'name', keys: ['ServiceType/Id'], uniqueKey: '#serviceType', multiple: true, lang: 'dbs' },
    ];
    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('dbs.bankName'), key: 'BankCode', render: (data) => utils.dbs.getBankName({ data }) },
      { label: i18n.t('dbs.serviceType'), key: 'ServiceType/Name', checkField: false }
    ];

    return (
      <div className={`page-content ${className || ''}`}>
        <FadeIn>
          <CheckAccessRight {...{ ...parameters, hasParent: getDataTrigger !== undefined }}>
            {
              showFilter !== false &&
              <Filter filters={filters} onFilter={this.onFilter} />
            }
            <DataTable
              count={parameters.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access={access}
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={parameters.list.data}
              loading={parameters.list.loading}
              title={datatableTitle || i18n.t('route.dbs.parameters')}
              getData={this.getParameters}
              newButton="openDialog"
              Component={Parameter}
              dialogTitle={i18n.t('lbl.parameter')}
              deleteOptions={{ stateKey: "parameters", url: access, baseUrl: baseUrls.dbs }}
              collapsed={datatableCollapsed}
              hasParent={hasParent}
              disabled={!(getDataTrigger === undefined || (getDataTrigger !== null))}
              dialogOptions={{
                endpoint: getDataTrigger && endpoints.dbs.accountParameter,
                tenantId: getDataTrigger && getDataTrigger.id
              }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  parameters: dbs.parameters,
  tenantBanks: dbs.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (filter, parameters, endpoint) => dispatch(actions.getAll({ filter, url: endpoint || endpoints.dbs.dbsAccount, key: 'parameters', parameters })),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Parameters);
