import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import netekstreActions from 'store/actions/netekstre';
import { Select } from 'components/UIComponents/NetbankaComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { MatchingConditionModel } from 'models';
import _ from 'lodash';
import { httpMethods } from 'lib/constants';
import { CheckAccessRight } from 'components/UIComponents';

class MatchingCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new MatchingConditionModel(),
      InitialState: new MatchingConditionModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.matchingConditions.single.data && !state.data.id) {
      const data = new MatchingConditionModel({ ...props.matchingConditions.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.matchingConditions.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getMatchingCondition(this.id);

    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, (response) => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { matchingConditions, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={matchingConditions.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={matchingConditions.single.loading} form="matchingConditions" htmlType="submit" className="success #save" icon="save" loading={matchingConditions.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      matchingConditions.single.saving,
      matchingConditions.single.loading
    )
  }

  render() {
    const { data } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { matchingConditions, dynamicProperties, currentAccountSelectedItems, dialogCloseRequest } = this.props;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <CheckAccessRight {...{ ...matchingConditions, renderDialogButtons, dialogCloseRequest }}>
        <Form onSubmit={this.save} id="matchingConditions">
          <Form.Item>
            {getFieldDecorator('dynamicPropertyId',
              {
                rules: [{ required: true, message: i18n.t('msg.required') }],
                initialValue: data.dynamicPropertyId
              }
            )(
              <Select
                className="#dynamicPropertyId"
                options={dynamicProperties.data}
                optVal="id"
                optName="name"
                label={i18n.t('lbl.dynamicFieldName')}
                onChange={(e) => this.update('dynamicPropertyId', e)}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('currentAccountSelectedItem',
              {
                rules: [{ required: true, message: i18n.t('msg.required') }],
                initialValue: data.currentAccountSelectedItem
              }
            )(
              <Select
                className="#currentAccountSelectedItem"
                options={currentAccountSelectedItems.data}
                optVal="name"
                optName="description"
                label={i18n.t('lbl.currentAccountSelectedItemText')}
                onChange={(e) => this.update('currentAccountSelectedItem', e)}
              />
            )}
          </Form.Item>
        </Form>
      </CheckAccessRight>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  matchingConditions: netekstre.matchingConditions,
  dynamicProperties: netekstre.filter.dynamicProperties,
  currentAccountSelectedItems: common.filter.currentAccountSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getMatchingCondition: (id, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.matchingConditions, key: 'matchingConditions', id }, callback)),
  saveData: (data, method, callback) => dispatch(netekstreActions[method]({ url: endpoints.nte.matchingConditions, key: 'matchingConditions', data }, callback)),
  clearState: () => dispatch(netekstreActions.clearState("matchingConditions")),
});
const MatchingConditionForm = Form.create()(MatchingCondition);
export default connect(mapStateToProps, mapDispatchToProps)(MatchingConditionForm);