import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import { ConfirmPopup, SyncPopup, Loading } from 'components/UIComponents';
import { Row, Col, Button, Card, Tooltip } from "antd";
import { compareFields } from 'helpers'
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import SplitTable from './SplitTable';
import utils from 'lib';

class TransactionsSplit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEqual: false,
      isZero: false,
      warningVisible: false,
    };
    this.id = props.match.params.id;
    this.searchTimeout = null;
  };

  componentDidMount() {
    this.getSplitTransaction();
    utils.common.getTenantBanks({ orderBy: ['name asc'] });
  };

  getSplitTransaction = () => {
    this.props.getSplitTransaction(this.id, () => {

      this.setState({
        loading: false
      })
    });
  };

  getTenantBanks = () => {
    this.props.getTenantBanks();
  }

  onChange = (eq, zero, data) => {
    this.setState({ isEqual: eq, isZero: zero, data })
  }

  save = (e) => {
    e.preventDefault();
    let _data = this.splitTable.state.data;
    let { splitTransaction } = this.props;
    _data.children = _data.children.filter(x => isNaN(x.id));
    _data.children = compareFields('children', _data, splitTransaction);
    if (_data.children.length === 0) {
      this.props.history.push('/netekstre/transactions');
    }
    else {
      this.props.saveTransaction(_data, (response) => {
        response && this.props.history.push('/netekstre/transactions');
      });
    }
  }

  customButtons = ({ selectedItems, selectAll, disabled }) => (
    <React.Fragment>
      {(this.state.startApprove && this.props.accessRights && this.props.accessRights.find(x => x.endPoint === 'ApproveTransactionBulk' && x.method === 'PUT')) &&
        <ConfirmPopup disabled={disabled} selectedItems={[...selectedItems]} selectAll={selectAll} filter={this.state.filter.filter} onStartApprove={this.onStartApprove} />
      }
      {(this.state.startTransfer && this.props.accessRights && this.props.accessRights.find(x => x.endPoint === 'SendManuelTransactions' && x.method === 'PUT')) &&
        <SyncPopup disabled={disabled} selectedItems={[...selectedItems]} selectAll={selectAll} filter={this.state.filter.filter} onStartSync={this.onStartSync} />
      }
    </React.Fragment>
  );

  render() {
    let { splitTransaction, tenantBanks, transactions } = this.props;
    let { loading, isEqual, isZero, data } = this.state;
    let isTransferCompleted = splitTransaction.single.data !== null && splitTransaction.single.data.children && splitTransaction.single.data.children.some(x => x.transferStatus === "Completed") ? true : false
    let isSingleAndEqualTotal = data && data.children && data.children.filter(x => x.id > -1 && x.modelState !== 3).length === 1 && data.children.filter(x => x.id > -1 && x.modelState !== 3)[0].amount === data.amount;
    return (
      <div className="page-content">
        <Card className="table-card" ref={el => this.card = el} title={<span className="mr-10">{i18n.t("lbl.bankTransactionSplit")}</span>}>
          <Loading loading={loading || splitTransaction.single.data === null || tenantBanks.data === null || !tenantBanks.data.length > 0}>
            <React.Fragment>
              <Row gutter={20}>
                <Col span={24}>
                  <SplitTable
                    data={splitTransaction.single.data}
                    helpersData={tenantBanks.data}
                    onChange={this.onChange}
                    ref={el => this.splitTable = el}
                  />
                </Col>
              </Row>
              <Row gutter={20} className="mt-10">
                <Col span={24} className="text-right pr-30 pb-20 pt-30">
                  <Button className="error mr-10 #cancel" icon="close" onClick={() => this.props.history.push(`/netekstre/transactions`)}>{i18n.t('btn.cancel')}</Button>
                  <span
                    onMouseEnter={() => this.setState({ warningVisible: ((!isEqual || isZero) || isTransferCompleted) && true })}
                    onMouseLeave={() => this.setState({ warningVisible: false })}
                  >
                    <Tooltip
                      visible={this.state.warningVisible}
                      title={isTransferCompleted ? i18n.t('msg.isTransferred') : isSingleAndEqualTotal ? i18n.t('msg.isSingleAndEqualTotal') : i18n.t('msg.amountEqualTotal')}
                      placement="topLeft"
                    >
                      <Button disabled={isTransferCompleted || (!isEqual || isZero)} htmlType="submit" onClick={this.save} className="success #save" icon="save" loading={transactions.saving}>{i18n.t('btn.save')}</Button>
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </React.Fragment>
          </Loading>
        </Card>

      </div >
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  accessRights: auth.data.accessRights,
  tenantBanks: common.filter.tenantBanks,
  splitTransaction: netekstre.splitTransaction,
  transactions: netekstre.transactions.single
});
const mapDispatchToProps = (dispatch) => ({
  getSplitTransaction: (id, callback) => dispatch(actions.get({ url: endpoints.nte.bankAccountTransactionsWithChildren, id, key: 'splitTransaction' }, callback)),
  saveTransaction: (data, callback) => dispatch(actions.put({ url: endpoints.nte.bankAccountTransactionsSplit, data, key: 'transactions' }, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsSplit);