// export { default as accountBalances} from './accountBalances'

import { barChart } from "./barChart";
import { pieChart } from "./pieChart";
import { lineChart } from "./lineChart";
import { stackedBarChart } from "./stackedBarChart";
import './common';

export default {
  barChart,
  pieChart,
  lineChart,
  stackedBarChart
}