import { combineReducers } from 'redux';
import configureStore from './createStore';
import rootSaga from '../sagas';

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = combineReducers({
    auth: require('./auth').reducer,
    netekstre: require('./netekstre').reducer,
    tos: require('./tos').reducer,
    dbs: require('./dbs').reducer,
    posrapor: require('./posrapor').reducer,
    admin: require('./admin').reducer,
    common: require('./common').reducer,
    cek: require('./cek').reducer,
    netahsilat: require('./netahsilat').reducer,
  });

  return configureStore(rootReducer, rootSaga);
};