import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Radio, Card } from 'antd';
import _ from 'lodash';
import 'chartjs-plugin-labels';
import { PieChartModal } from './PieChartContainer/';
import { PieChart } from '.'
import utils from 'lib';
import { convertFieldsToCamelize } from 'helpers';
import { httpMethods } from 'lib/constants';
import Loading from '../Loading';
const actions = {};

class PieChartContainer extends Component {
  state = {
    incomingLoading: false,
    outgoingLoading: false,
    Day: this.props.range.defaultSelected,
    visible: false,
    wait: !!this.props.getWithQueue,
    modalData: {
      data: [],
      title: "",
      type: null,
    },
  };

  componentDidMount() {
    if (!this.props.getWithQueue)
      this.getChartData();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.currencyCode !== prevProps.currencyCode ||
      this.props.multiTenant !== prevProps.multiTenant ||
      this.props.isCumulative !== prevProps.isCumulative ||
      this.props.includedInternalTransfer !== prevProps.includedInternalTransfer
    ) {
      this.getChartData();
    }
  };

  getCharts = (e) => {
    this.setState({ Day: parseInt(e.target.value) }, () => {
      this.getChartData();
    });
  }

  getChartData = async () => {
    const { charts, getWithQueue } = this.props;
    if (getWithQueue)
      return new Promise(async resolve => {
        for (let i = 0; i < charts.length; i++) {
          await this.getAChartData({ chart: charts[i].chartName, method: charts[i].method, endpoint: charts[i].endpoint, key: charts[i].type });
        }
        this.setState({ wait: false });
        resolve();
      })
    else
      charts.forEach(x => {
        this.getAChartData({ chart: x.chartName, method: x.method, endpoint: x.endpoint, key: x.type });
      })
  }

  getAChartData = async ({ chart, method, endpoint, key }) => {
    return new Promise(async resolve => {
      this.setState({ [`${chart}Loading`]: true });

      const { multiTenant, currencyCode, isCumulative, includedInternalTransfer, currentModule } = this.props;
      const data = { currencyCode, getMultiTenant: multiTenant, Day: parseInt(this.state.Day) };
      const parameters = method === httpMethods.get ? { isCumulative, includedInternalTransfer, ...data } : { isCumulative, includedInternalTransfer }
      const args = [currentModule, endpoint, method, key, data, parameters, currencyCode]

      if (!actions[currentModule]) {
        const _module = await import(`store/actions/${currentModule}`);
        actions[currentModule] = _module.default;
        this.props.getChartData(...args, resolve);
      }
      else {
        this.props.getChartData(...args, resolve);
      }
    })
  }

  getBankName = (bankEftCode) => {
    let { multiTenant } = this.props;
    return utils.common.getBankName({ data: { code: bankEftCode }, dataField: 'code', listField: multiTenant ? 'eftCode' : 'bankEftCode', listKeys: 'tenantBanks', className: "medium" })
  };

  onLineClick = (line, chart) => {
    let { multiTenant } = this.props;
    let singleData = _.cloneDeep(this.props[chart].single.data);
    if (multiTenant) {
      line = line[0];
      if (line && singleData.bankDetails && singleData.bankDetails.length > 0) {
        let datasetIndex = line._datasetIndex;
        let dataIndex = line._index;

        if (singleData.bankDetails[datasetIndex].chartDetails[dataIndex].details) {
          let modalData = {
            title: this.getBankName(JSON.parse(singleData.series[datasetIndex].bankEftCodes)[dataIndex]),
            type: 'amount',
            data: singleData.bankDetails[datasetIndex].chartDetails[dataIndex].details.sort((a, b) => a.name.localeCompare(b.name)).map(x => { return { tenantName: x.name, data: x.total === null ? '--' : parseFloat(x.total), count: x.count } })
          }
          this.setState({ visible: true, modalData });
        }
      }
    }
  }

  render() {
    let { Day, modalData, visible, wait } = this.state;
    let { currencyCode, range, charts, title } = this.props;

    return (
      <React.Fragment>

        <Row>
          <Col xs={24} sm={12}>
            <h1 className="title">{title}</h1>
          </Col>
          <Col xs={24} sm={12}>
            <Radio.Group className="pull-right" buttonStyle="solid" value={parseInt(Day)} onChange={this.getCharts}>
              {
                range.ranges.map(x => (
                  <Radio.Button key={x.uniqueName} value={x.value} className={x.uniqueName}>{x.title}</Radio.Button>
                ))
              }
            </Radio.Group>
          </Col>
        </Row>
        <Loading loading={wait} Wrapper={Card} wrapperClassName="mb-40">
          <Row gutter={20} className={wait ? 'mb-50' : ''}>
            {
              charts.map(x =>
                <Col key={x.type} xs={24} md={24 / charts.length} className="mb-50">
                  <PieChart {...{ ...x, onLineClick: this.onLineClick }} />
                </Col>
              )
            }
          </Row>
        </Loading>
        <PieChartModal {...{ visible, modalData, currencyCode, setVisible: (e) => this.setState({ visible: e }) }} />

      </React.Fragment >
    );
  };
};

const mapStateToProps = (state, { charts, toCamel }) => {
  const _state = {
    auth: state.auth,
    tenantBanks: state.common.filter.tenantBanks,
    currentModule: state.common.currentModule,
  }
  charts.forEach(x => { _state[x.type] = toCamel ? convertFieldsToCamelize(state[state.common.currentModule][x.type]) : state[state.common.currentModule][x.type] })
  return _state
}
const mapDispatchToProps = (dispatch) => ({
  getChartData: (currentModule, endpoint, method, key, data, parameters, currencyCode, callback) => dispatch(actions[currentModule][method]({ data, url: endpoint, key, parameters, currencyCode }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PieChartContainer);