import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { DataTable, Filter, Parameter } from 'components/UIComponents';
import { reportTypes, reportDisplayTypes } from 'lib/constants';
import { formatCurrency } from 'helpers';
import moment from 'moment';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import defaultFilters from 'lib/constants/defaultFilters';
import utils from 'lib';


class AvgReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.netekstre.AvgReports(),
      memberBankAccountFilter: {},
      parameters: {
        reportType: '0',
        displayType: '0',
        startDate: moment().startOf('date').add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]'),
        endDate: moment().endOf('date').add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.999[Z]'),
      },
      dateMode: 'date',
      currentBank: null,
      currentTenant: null,
    };
  };

  componentDidMount() {
    this.getAvgReports();
    this.props.getMemberBankAccount();
    utils.common.getTenantBanks();
    utils.common.getCurrencies();
  };

  getAvgReports = (e) => {
    let { parameters, dateMode } = this.state;
    let filter = _.cloneDeep(this.state.filter);
    let filters = this.props.currentFilter['netekstre/reports/average'];
    if (!(filters && filters.fields && filters.fields[1])) {
      filter.filter.and ? filter.filter.and.push({ reportDate: { ge: moment().startOf(dateMode)._d } }) : filter.filter.and = [{ reportDate: { ge: moment().startOf(dateMode)._d } }];
    }
    if (!(filters && filters.fields && filters.fields[2])) {
      filter.filter.and ? filter.filter.and.push({ reportDate: { le: moment().endOf(dateMode)._d } }) : filter.filter.and = [{ reportDate: { le: moment().endOf(dateMode)._d } }];
    }

    parameters.startDate = moment(filters && filters.fields && filters.fields[1]).startOf(dateMode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]');
    parameters.endDate = moment(filters && filters.fields && filters.fields[2]).endOf(dateMode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.999[Z]');
    this.props.getAvgReports(filter, parameters);
  };

  // getMemberBankAccount = (key, value, isGuid = false) => {
  //   const { memberBankAccountFilter } = this.state;
  //   let newFilter = value ? {
  //     or: value.map(x => ({ [key]: isGuid ? { type: 'guid', value: x } : x }))
  //   } : null;
  //   let filter = { filter: { ...memberBankAccountFilter, ...newFilter } };
  //   this.props.getMemberBankAccount(filter);
  // }
  //? performans gerekçesi ile UI tarafı zorlanırsa BE taraflı filtreleme yukarıdaki kod ile yapılabilir

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getAvgReports();
    });
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getAvgReports();
    });
  };

  onSelect = (value) => {
    let { filter } = this.state;
    filter.filter = !value ? {} : filter.filter;
    this.setState({ currentBank: value, filter });
  };

  onTenantSelect = (value) => {
    let { filter } = this.state;
    filter.filter = !value ? {} : filter.filter;
    //? bkz: line 66 - this.setState({ currentTenant: value, filter }, () => this.getMemberBankAccount('TenantId', value, true));
    
    this.setState({ currentTenant: value, filter });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getAvgReports());
  }

  onParameterChange = (key, value) => {
    let { parameters, filter } = this.state;
    parameters[key] = value;
    this.setState({ parameters }, () => this.getAvgReports(filter))
  }

  onDateModeChange = (item) => {
    let filter = _.cloneDeep(this.state.filter);
    let parameters = _.cloneDeep(this.state.parameters)
    let filters = this.props.currentFilter['netekstre/reports/average'];
    if (filter.filter.and) {
      filter.filter.and.find(x => x.or && x.or.length > 0 && x.or.find(y => y.reportDate && y.reportDate.ge ? y.reportDate.ge = moment(filters.fields[1]).startOf(item.mode)._d : null));
      filter.filter.and.find(x => x.or && x.or.length > 0 && x.or.find(y => y.reportDate && y.reportDate.le ? y.reportDate.le = moment(filters.fields[2]).endOf(item.mode)._d : null));
      parameters.startDate = moment(filters.fields[2]).startOf(item.mode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]');
      parameters.endDate = moment(filters.fields[2]).endOf(item.mode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.999[Z]');
    }
    let startDate = moment(filters ? filters.fields[1] : undefined).startOf(item.mode);
    let endDate = moment(filters ? filters.fields[2] : undefined).endOf(item.mode);
    this.filterRef.filterChange(startDate.format('YYYY-MM-DDT00:00:00Z'), 1);
    this.filterRef.filterChange(endDate.format('YYYY-MM-DDT00:00:00Z'), 2);
    parameters.reportType = item.value;
    this.setState({ dateMode: item && item.mode, filter, parameters, startDate: startDate._d, endDate: endDate._d }, () => this.getAvgReports(filter))

  }

  render() {
    let { avgReports, bankAccounts, tenantBanks, currencies, auth, currentFilter } = this.props;
    let { dateMode, currentBank, locationState, startDate, endDate, parameters, currentTenant } = this.state;
    let _accounts = bankAccounts.data ? (currentBank && currentBank.length) ? bankAccounts.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : bankAccounts.data : [];
    _accounts = (currentTenant && currentTenant.length) ? _accounts.filter(x => currentTenant.find(y => y === x.tenantId)) : _accounts;
    let _filter = currentFilter['netekstre/reports/average'];
    let _tenants = auth.data.tenants;
    let defaultStartDate = (_filter && _filter.fields && _filter.fields[1]) || new Date().toISOString();
    let defaultEndDate = (_filter && _filter.fields && _filter.fields[2]) || new Date().toISOString();

    let filter = _.cloneDeep(this.state.filter);
    let _filters = currentFilter['netekstre/reports/average'];
    if (!(_filters && _filters.fields && _filters.fields[1])) {
      filter.filter.and ? filter.filter.and.push({ reportDate: { ge: moment().startOf(dateMode)._d } }) : filter.filter.and = [{ reportDate: { ge: moment().startOf(dateMode)._d } }];
    }
    if (!(_filters && _filters.fields && _filters.fields[2])) {
      filter.filter.and ? filter.filter.and.push({ reportDate: { le: moment().endOf(dateMode)._d } }) : filter.filter.and = [{ reportDate: { le: moment().endOf(dateMode)._d } }];
    }


    let filters = [
      { label: i18n.t('lbl.tenantName'), type: 'select', options: _tenants, value: 'id', name: 'name', keys: ['tenantId'], uniqueKey: '#accountName', multiple: true, callback: this.onTenantSelect, guid: true },
      { label: i18n.t('lbl.startDate'), type: 'date', mode: dateMode, date: 'ge', keys: ['reportDate'], uniqueKey: '#startDate', disabled: '2', defaultValue: defaultStartDate, allowClear: false, value: startDate, callback: () => this.setState({ startDate: undefined }), callbackTimeout: 1, },
      { label: i18n.t('lbl.endDate'), type: 'date', mode: dateMode, date: 'le', keys: ['reportDate'], uniqueKey: '#endDate', disabled: '1', defaultValue: defaultEndDate, allowClear: false, value: endDate, callback: () => this.setState({ endDate: undefined }), callbackTimeout: 1, },
      { label: i18n.t('lbl.bank'), options: tenantBanks.data, type: 'select', value: 'bankEftCode', defaultValue: locationState ? [locationState.bankEftCode] : null, name: (bankData) => utils.netekstre.getBankName({ bankData }), keys: ['bankEftCode'], showAll: true, callback: this.onSelect, clear: ['4', '5', '6'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.currencyCode'), type: 'select', options: currencies.data, value: 'currencyCode', name: 'currencyName', keys: ['currencycode'], uniqueKey: '#currencyCode', multiple: true },

    ];
    let _parameters = [
      { type: 'radio', key: "reportType", uniqueKey: '#reportType', options: reportTypes, defaultValue: '0', callback: (value, item) => this.onDateModeChange(item), callbackTimeout: 10, },
      { type: 'radio', key: "displayType", uniqueKey: '#displayType', options: reportDisplayTypes, defaultValue: '0' },
    ]
    let columns = [
      { label: i18n.t('lbl.tenantName'), key: 'TenantName' },
      { label: i18n.t('lbl.bank'), key: 'BankName', render: (data) => utils.common.getBankName({ data, listKeys: 'tenantBanks', dataField: "BankEftCode", listField: "bankEftCode" }) },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode' },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: (row) => formatCurrency({ data: row.Amount, currencyCode: row.CurrencyCode, withColor: true }) },
      { label: i18n.t('lbl.date'), key: 'DisplayDate' },
    ];

    if (parameters.displayType === '1') {
      columns.insert(3, { label: i18n.t('lbl.accountName'), key: 'AccountName' })
      filters.push({ label: i18n.t('lbl.accountName'), search: true, type: 'select', options: _accounts, showSearch: true, guid: true, value: 'id', defaultValue: locationState ? [locationState.bankAccountId] : null, name: 'name', keys: ['accountId'], uniqueKey: '#accountName', multiple: true })
    }

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
        <Parameter parameters={_parameters} onChange={this.onParameterChange} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          className="mb-20"
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          count={avgReports.list.count}
          data={avgReports.list.data}
          loading={avgReports.list.loading}
          excel={{ url: "ExportDailyBalanceReports", filter, parameters, module: "nte" }}
          title={i18n.t('route.netekstre.averageReports')}
          history={this.props.history}
          access="Transaction"
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  auth,
  avgReports: netekstre.avgReports,
  tenantBanks: common.filter.tenantBanks,
  bankAccounts: common.filter.bankAccounts,
  currencies: common.filter.currencies,
  currentFilter: common.currentFilter
});
const mapDispatchToProps = (dispatch) => ({
  getAvgReports: (filter, parameters) => dispatch(netekstreActions.getAll({ filter, url: endpoints.nte.balanceReportsGetDailyBalanceReport, key: 'avgReports', parameters, isNewODataStructure: true })),
  updateFilter: (fields, filter, key) => dispatch(commonActions.updateFilter({ fields, filter, key })),
  getMemberBankAccount: (filter) => dispatch(commonActions.getFilter({ filter, url: endpoints.lookups.memberBankAccounts, key: 'bankAccounts' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(AvgReports);