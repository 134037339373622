import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { DataTable, DropdownList, Filter, ActionButtons } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import User from './Detail'
import utils from 'lib';
import { Checkbox } from 'antd';

class Users extends Component { 
  state = {
    loading: false,
    filter: defaultFilters.common.Users(),
  };

  componentDidMount() {
    this.getUsers();
    this.getRoles();
    utils.common.checkAccessToUpdateOrDelete('Users')
  };

  getUsers = () => {
    let { filter } = this.state;
    this.props.getUsers(filter);
  };

  getRoles = () => {
    let { filter } = { filter: { deleted: false } }
    this.props.getRoles(filter);
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getUsers();
    });
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getUsers();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getUsers());
  };

  render() {
    let { users, roles } = this.props;
    let filters = [
      { label: i18n.t('lbl.roles'), type: 'select', options: roles.data, value: 'id', name: 'name', keys: ['UserRoles'], any: 'RoleId', uniqueKey: '#userGroups', multiple: true, guid: true },
      { label: i18n.t('lbl.search'), placeholder: i18n.t('lbl.search'), type: 'input', keys: ['Name', 'Surname', 'Email', 'Phone', 'Description', 'Gsm'], contains: true, uniqueKey: '#search' }
    ];
    let columns = [
      { render: (row) => <ActionButtons url="Users" editUrl item={row} hasDelete getDatas={this.getUsers} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.name'), key: 'Name' },
      { label: i18n.t('lbl.surname'), key: 'Surname' },
      { label: i18n.t('lbl.email'), key: 'Email' },
      { label: i18n.t('lbl.phone'), key: 'Phone' },
      { label: i18n.t('lbl.mobilePhone'), key: 'Gsm' },
      { label: i18n.t('lbl.description'), key: 'Description' },
      { label: i18n.t('lbl.isActive'), key: 'IsActive', render: (row) => <Checkbox checked={row.IsActive} />, thClass: 'text-center', tdClass: 'text-center' },
      { label: i18n.t('lbl.roles'), sort: false, key: 'UserRoles', render: (row) => <DropdownList tag data={row.UserRoles.map(x => x.Role)} field="Name" /> },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          onPageChange={this.onPageChange}
          access="Users"
          newButton="openDialog"
          history={this.props.history}
          onSort={this.onSort}
          columns={columns}
          data={users.list.data}
          loading={users.list.loading}
          title={i18n.t('route.common.users')}
          count={users.list.count}
          Component={User}
          dialogTitle={i18n.t('lbl.user')}
          getData={this.getUsers}
          deleteOptions={{ stateKey: "users", url: "Users", baseUrl: baseUrls.tenant }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  users: common.users,
  roles: common.filter.roles,
});
const mapDispatchToProps = (dispatch) => ({
  getUsers: (filter) => dispatch(actions.getAll({ url: endpoints.tenant.users, key: 'users', filter, isNewODataStructure: true })),
  getRoles: (filter) => dispatch(actions.getFilter({ url: endpoints.lookups.roles, key: 'roles', filter })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);