import React, { Component } from 'react';
import { connect } from 'react-redux';
import dbsActions from 'store/actions/dbs';
import commonActions from 'store/actions/common';
import { DataTable, Filter, Instruction, CheckAccessRight, FadeIn } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import { formatDate, formatCurrency, isEditableInvoice } from "helpers";
import { CreateOrderModel } from "models";
import { Tooltip, Icon, Modal } from "antd";
import utils from 'lib';
import i18n from 'plugins/i18n';
import { defaultFilters } from 'lib/constants';
import _ from 'lodash';
import Reporting from './Detail'

class Reportings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.dbs.Reporting(),
      totalAmount: null,
      totalCount: null,
      bankId: undefined,
      currencyCode: undefined,
      totalsLoading: false,
      orderSendingOrCreating: false,
      startCreateOrder: false,
    };
    this.id = props.id;
  };


  componentDidMount() {
    utils.dbs.getTenantCurrencies();
    utils.dbs.getTenantBanks('DbsAccount');

    this.getReporting();
    this.props.getOrderStatus({ filter: { OperationStatusTypeId: { eq: 2 } } });
    this.props.getInvoiceStatus({ filter: { OperationStatusTypeId: { eq: 1 } } });
    utils.common.checkAccessToUpdateOrDelete('DbsInvoice')
    if (this.props.selectedItems.length > 0) {
      this.getInvoiceTotal();
    }
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getReporting = () => {
    let filter = _.cloneDeep(this.state.filter);
    if (this.state.startCreateOrder) {
      const _editableIds = this.props.invoiceStatus.data.filter(x => isEditableInvoice(x.Id))
      filter.filter.and ?
        filter.filter.and.push({ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] })
        :
        filter.filter.and = [{ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] }];
    }

    this.props.getReporting(filter, () => this.getInvoiceTotal());
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getReporting);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getReporting());
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getReporting);
  };

  onBankChange = (bankId, key) => {
    const { filter } = this.state;
    this.onFilter(bankId ? { [key]: { eq: bankId } } : delete filter.filter[key]);
    if (!bankId) this.datatable.checkAll(false);
    this.setState({ bankId })
  }

  onCurrencyChange = (currencyCode, key) => {
    const { filter } = this.state;
    this.onFilter(currencyCode ? { [key]: { eq: currencyCode } } : delete filter.filter[key]);
    if (!currencyCode) this.datatable.checkAll(false);
    this.setState({ currencyCode })
  }

  onStartCreateOrder = () => {
    this.setState({ startCreateOrder: !this.state.startCreateOrder }, this.getReporting);
  }

  orderOperation = (data) => {
    this.setState({ orderSendingOrCreating: true })
    const _data = new CreateOrderModel({ ...data, InvoiceIds: this.props.selectedItems })
    this.props.createOrder(_data, (response) => {
      this.setState({ orderSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: response && response.Message,
          content: status === 'success' ? i18n.t(`dbs.createOrderResponse`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.setState({ totalAmount: null, totalCount: null }, () => {
              this.getReporting();
              utils.common.clearSelectedItems();
            })
          }
        })
      }
    })
  }

  getInvoiceTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll) {
      let filter = {};
      filter.filter = {
        ...this.state.filter.filter,
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }
      if (this.state.startCreateOrder) {
        const _editableIds = this.props.invoiceStatus.data.filter(x => isEditableInvoice(x.Id))
        filter.filter.and ?
          filter.filter.and.push({ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] })
          :
          filter.filter.and = [{ or: [..._editableIds.map(x => { return { OperationStatusId: x.Id } })] }];
      }

      this.props.getInvoiceTotal(filter, response => {
        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(invoice =>
            _amount += invoice.InvoiceAmount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  render() {
    const { reportings, tenantBanks, tenantCurrencies, orderStatus, invoiceStatus } = this.props;
    const { totalAmount, totalCount, totalsLoading, orderSendingOrCreating, startCreateOrder, bankId, currencyCode } = this.state;
    const filters = [
      { label: i18n.t('dbs.dealerCodeOrName'), type: 'input', keys: ['CurrentAccount/Title', 'CurrentAccount/Code'], contains: true, uniqueKey: '#dealerCodeOrName' },
      { label: i18n.t('dbs.invoiceNumber'), type: 'input', keys: ['InvoiceNumber'], uniqueKey: '#invoiceNumber' },
      { label: i18n.t('dbs.orderStatus'), type: 'select', search: true, options: orderStatus.data, value: 'Id', name: 'Name', keys: ['DbsOrder/OperationStatusId'], uniqueKey: '#fileStatus', multiple: true },
      { label: i18n.t('dbs.invoiceStatus'), type: 'select', search: true, options: invoiceStatus.data, value: 'Id', name: 'Name', keys: ['OperationStatusId'], uniqueKey: '#invoiceStatus', multiple: true },
      { label: i18n.t('dbs.createdDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['InsertedTime'], uniqueKey: '#createdDateStart', allowClear: true },
      { label: i18n.t('dbs.createdDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['InsertedTime'], uniqueKey: '#createdDateEnd', allowClear: true },
      { label: i18n.t('dbs.invoiceDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['InvoiceDate'], uniqueKey: '#invoiceDateStart', allowClear: true },
      { label: i18n.t('dbs.invoiceDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['InvoiceDate'], uniqueKey: '#invoiceDateEnd', allowClear: true },
      { label: i18n.t('dbs.expiryDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['DueDate'], uniqueKey: '#expiryDateStart', allowClear: true },
      { label: i18n.t('dbs.expiryDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['DueDate'], uniqueKey: '#expiryDateEnd', allowClear: true },
      { label: i18n.t('lbl.minAmount'), type: 'number', operator: 'ge', keys: ['InvoiceAmount'], uniqueKey: '#minAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.maxAmount'), type: 'number', operator: 'le', keys: ['InvoiceAmount'], uniqueKey: '#maxAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.description'), type: 'input', keys: ['Description', 'DbsOrder/Description'], contains: true, uniqueKey: '#description' },
    ];
    const columns = [
      // { render: (row) => <ActionButtons url="DbsInvoice" editUrl item={row} hasDelete getDatas={this.getCreatingInstructions}  openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id', checkField: false, },
      { label: i18n.t('dbs.bankName'), key: 'BankCode', render: (data) => utils.dbs.getBankName({ data, dataField: "BankCode" }) },
      { label: i18n.t('dbs.dealerName'), key: 'CurrentAccount/Title', checkField: false },
      { label: i18n.t('dbs.dealerCode'), key: 'CurrentAccount/Code', checkField: false },
      { label: i18n.t('dbs.invoiceDate'), key: 'InvoiceDate', render: (row) => formatDate(row.InvoiceDate) },
      { label: i18n.t('dbs.expiryDate'), key: 'DueDate', render: (row) => formatDate(row.DueDate) },
      { label: i18n.t('dbs.orderCreatedDate'), key: 'InsertedTime', render: (row) => formatDate(row.InsertedTime) },
      { label: i18n.t('dbs.invoiceStatus'), key: 'OperationStatus/Name', checkField: false },
      { label: i18n.t('dbs.orderStatus'), key: 'DbsOrder/OperationStatus/Name', checkField: false },
      { label: i18n.t('dbs.invoiceNumber'), key: 'InvoiceNumber' },
      { label: i18n.t('dbs.amount'), key: 'InvoiceAmount', render: row => formatCurrency({ data: row.InvoiceAmount, currencyCode: row.CurrencyCode, withSign: false }) },
      { label: i18n.t('dbs.currency'), key: 'CurrencyCode' },
      { label: i18n.t('dbs.description'), key: 'Description' },
      { label: i18n.t('dbs.orderDescription'), key: 'DbsOrder.Description', checkField: false },
    ];

    const instructions = [
      { label: i18n.t('dbs.bankName'), key: 'BankCode', type: 'select', onChange: this.onBankChange, options: tenantBanks.data, name: (bankData) => utils.dbs.getBankName({ bankData }), value: "Code", required: true },
      { label: i18n.t('dbs.description'), key: 'Description', type: 'input' },
      { label: i18n.t('dbs.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, required: true, prior: true },
      { label: i18n.t('dbs.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, required: true, prior: true },
      { label: i18n.t('lbl.currencyCode'), key: 'CurrencyCode', type: 'select', onChange: this.onCurrencyChange, options: tenantCurrencies.data, name: "Name", value: "Code", loading: tenantCurrencies.loading, required: true },
      { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: this.orderOperation, disabled: (reportings.list.count === 0 || orderSendingOrCreating), access: 'DbsOrder.Create' },
    ]

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...reportings }}>
            <Filter filters={filters} onFilter={this.onFilter} />
            {
              startCreateOrder &&
              <Instruction {...{ instructions, loading: totalsLoading }} />
            }
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              count={reportings.list.count}
              access="DbsInvoice"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={reportings.list.data}
              loading={reportings.list.loading}
              excel="ExportDbsInvoice"
              title={i18n.t('route.dbs.reportings')}
              getData={this.getReporting}
              checkbox={(startCreateOrder && bankId && currencyCode) && true}
              additionalButtons={[
                <div onClick={this.onStartCreateOrder}>
                  <Icon type="interaction" className="mr-5" />
                  {startCreateOrder ? i18n.t('btn.stopCreateOrder') : i18n.t('btn.startCreateOrder')}
                </div>
              ]}
              onCheck={this.getInvoiceTotal}
              checkAll={reportings.list.data && reportings.list.data.length > 0 && (startCreateOrder && bankId && currencyCode)}
              Component={Reporting}
              dialogTitle={i18n.t('lbl.reporting')}
              deleteOptions={{ stateKey: "reportings", url: "DbsInvoice" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs, common }) => ({
  reportings: dbs.reportings,
  tenantBanks: dbs.filter.tenantBanks,
  tenantCurrencies: dbs.filter.tenantCurrencies,
  orderStatus: dbs.filter.orderStatus,
  invoiceStatus: dbs.filter.invoiceStatus,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getReporting: (filter, callback) => dispatch(dbsActions.getAll({ filter, url: endpoints.dbs.reporting, key: 'reportings' }, callback)),
  getInvoiceTotal: (filter, callback) => dispatch(dbsActions.getAll({ filter, url: endpoints.dbs.dbsInvoice }, callback)),
  createOrder: (data, callback) => dispatch(dbsActions.post({ url: endpoints.dbs.createOrder, data, customNotification: true }, callback)),
  getOrderStatus: (filter) => dispatch(dbsActions.getFilter({ filter, url: endpoints.dbs.operationStatus, key: 'orderStatus', isNewODataStructure: true })),
  getInvoiceStatus: (filter) => dispatch(dbsActions.getFilter({ filter, url: endpoints.dbs.operationStatus, key: 'invoiceStatus', isNewODataStructure: true })),

  setSelectedItems: (id, callback) => dispatch(commonActions.setSelectedItems(id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reportings);