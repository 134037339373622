import React from 'react';
import { Button } from "antd";
import i18n from 'plugins/i18n';
import { connect } from 'react-redux';

const getAccessRightsFromStateKey = async (stateKey) => {
  const _ = await import(`lodash`);
  const { store } = await import(`store/redux/createStore`);

  return { ..._.get(store.getState(), stateKey.split('.')) }
}

const CheckAccessRight = ({ single = { statusCode: null }, list = { statusCode: null }, children, renderDialogButtons, dialogCloseRequest, hasParent, common, stateKey, withTag }) => {
  const hasAccess = common.hasAccessRightToUpdateOrDeleteCurrentPage;
  const Tag = withTag || React.Fragment;
  let _single = single, _list = list;

  if (stateKey) {
    getAccessRightsFromStateKey(stateKey).then(stateData => {
      _single = stateData.single;
      _list = stateData.list;
    });
  }

  renderDialogButtons && renderDialogButtons(
    (_single.statusCode === 403 || !hasAccess) &&
    <div>
      <Button disabled={_single.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
    </div>
  )
  return (
    _single.statusCode === 403 || _list.statusCode === 403 ?
      <div className={`no-access-right check-access-rights ${hasParent ? 'has-parent' : ''}`}>
        <i className="fas fa-exclamation-triangle"></i>
        {i18n.t('msg.noAccessRight')}
      </div>
      :
      <Tag>
        {children}
      </Tag>
  );
};

export default connect((common) => common)(CheckAccessRight);