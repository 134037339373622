import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/netahsilat';
import reducers from 'store/reducers/netahsilat';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllNetahsilat: ['payload', 'callback', 'isNewODataStructure'],
  getAllNetahsilatSuccess: ['payload', 'isNewODataStructure'],
  getAllNetahsilatFailure: ['payload'],
  getNetahsilat: ['payload', 'callback'],
  getNetahsilatSuccess: ['payload', 'isNewODataStructure'],
  getNetahsilatFailure: ['payload'],
  postNetahsilat: ['payload', 'callback'],
  postNetahsilatSuccess: ['payload'],
  postNetahsilatFailure: ['payload'],
  putNetahsilat: ['payload', 'callback'],
  putNetahsilatSuccess: ['payload'],
  putNetahsilatFailure: ['payload'],
  deleteNetahsilat: ['payload', 'callback'],
  deleteNetahsilatSuccess: ['payload'],
  deleteNetahsilatFailure: ['payload'],
  patchNetahsilat: ['payload', 'callback'],
  patchNetahsilatSuccess: ['payload'],
  patchNetahsilatFailure: ['payload'],
  clearStateNetahsilat: ['key', 'options'],
  clearFilterNetahsilat: ['key'],
  getWithPostNetahsilat: ['payload'],
  getWithPostNetahsilatSuccess: ['payload'],
  getWithPostNetahsilatFailure: ['payload'],
  getFilterNetahsilat: ['payload', 'isNewODataStructure'],
  getFilterNetahsilatSuccess: ['payload', 'isNewODataStructure'],
  getFilterNetahsilatFailure: ['payload'],
});
export const NetahsilatTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}

export const clearFilter = (state, action) => {
  const data = reducers.clearFilter(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.getWithPost(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state, action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state, action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state, action)
  return state.merge(data);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_NETAHSILAT]: getAll,
  [Types.GET_ALL_NETAHSILAT_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_NETAHSILAT_FAILURE]: getAllFailure,
  [Types.GET_NETAHSILAT]: get,
  [Types.GET_NETAHSILAT_SUCCESS]: getSuccess,
  [Types.GET_NETAHSILAT_FAILURE]: getFailure,
  [Types.POST_NETAHSILAT]: post,
  [Types.POST_NETAHSILAT_SUCCESS]: postSuccess,
  [Types.POST_NETAHSILAT_FAILURE]: postFailure,
  [Types.PUT_NETAHSILAT]: put,
  [Types.PUT_NETAHSILAT_SUCCESS]: putSuccess,
  [Types.PUT_NETAHSILAT_FAILURE]: putFailure,
  [Types.DELETE_NETAHSILAT]: _delete,
  [Types.DELETE_NETAHSILAT_SUCCESS]: deleteSuccess,
  [Types.DELETE_NETAHSILAT_FAILURE]: deleteFailure,
  [Types.PATCH_NETAHSILAT]: patch,
  [Types.PATCH_NETAHSILAT_SUCCESS]: patchSuccess,
  [Types.PATCH_NETAHSILAT_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_NETAHSILAT]: clearState,
  [Types.CLEAR_FILTER_NETAHSILAT]: clearFilter,
  [Types.GET_WITH_POST_NETAHSILAT]: getWithPost,
  [Types.GET_WITH_POST_NETAHSILAT_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_NETAHSILAT_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_NETAHSILAT]: getFilter,
  [Types.GET_FILTER_NETAHSILAT_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_NETAHSILAT_FAILURE]: getFilterFailure,
});