/*eslint no-extend-native: ["error", { "exceptions": ["Number", "String", "Array"] }]*/
Number.prototype.formatCurrency = function (x) {
  return (x && x > 0) ? this.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Number.prototype.formatThousand = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

String.prototype.capitalize = function (key) {
  return key.charAt(0).toUpperCase() + key.slice(1);
}

Array.prototype.insert = function ( index, item ) {
  this.splice( index, 0, item );
};

String.prototype.camelize = function(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};