import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Tabs, notification } from 'antd';
import { PackageModel } from 'models';
import { httpMethods } from 'lib/constants';
import actions from 'store/actions/admin';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { CheckAccessRight, Loading } from 'components/UIComponents';
import utils from 'lib';
import { bulkParseFloat } from 'helpers';

const Definitions = lazy(() => import('./components/Definitions'));
const Prices = lazy(() => import('./components/Prices'));

class Package extends Component {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.getModuleTimeOut = null
    this.state = {
      data: new PackageModel(),
      InitialState: new PackageModel(),
    };
  };

  componentDidMount() {
    if (this.id) this.props.getPackage(this.id);
    utils.common.getModules({ isActive: true });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.packages.data && !state.data.id) {
      const data = new PackageModel(props.packages.data);
      props.getModulePackages({ moduleIds: data.moduleIds, id: data.id });
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.packages.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    utils.admin.clearState('packages', { deep: true });
  }

  update = async (key, value) => {
    const { data } = this.state;
    if (key === 'moduleIds' && value)
      data[key] = await this.onChangeModuleIds(value);
    else
      data[key] = value;

    this.setState({ data })
  }

  onChangeModuleIds = (value) => {
    const _this = this;

    return new Promise(resolve => {
      const { data } = this.state;
      const { modules } = this.props;

      clearTimeout(_this.getModuleTimeOut);
      _this.getModuleTimeOut = setTimeout(() => {
        const removedModuleIds = _.difference(data.moduleIds, value)
        const removedModuleNames = removedModuleIds.reduce((a, b, i) => a + `${i === 0 ? '' : ', '}${modules.data.find(x => x.id === b)?.definition}`, '')
        if (removedModuleIds.length) notification.info({ message: <span dangerouslySetInnerHTML={{ __html: i18n.t(`msg.removedModuleName${removedModuleIds.length > 1 ? 's' : ''}`, { removedModuleNames }) }} /> })
        _this.props.getModulePackages({ moduleIds: value, id: data.id })
        resolve(value)
      }, 750);
    })
  }


  save = async (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    const data = await this.prepareSaveData();

    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, (response) => {
          if (response)
            this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
        });
      }
    });
  };

  prepareSaveData = async () => {
    let data = _.cloneDeep(this.state.data);
    const { modulePackages } = this.props
    const moduleLicencePackages = [];
    const licencePackageDefinitions = this.definitions.getData();
    const licencePackageAmounts = this.prices?.getData?.();
    const totals = this.prices?.getTotals?.() || {};

    licencePackageDefinitions.forEach(item => {
      let modulePackage = modulePackages.data.find(x => x.moduleId === item.moduleId);
      modulePackage = {
        ...modulePackage,
        licencePackageDefinitions: licencePackageDefinitions.find(x => x.moduleId === item.moduleId).licencePackageDefinitions,
        licencePackageAmounts: bulkParseFloat(licencePackageAmounts
          ? licencePackageAmounts.find(x => x.moduleId === item.moduleId).licencePackageAmounts
          : data.moduleLicencePackages.find(x => x.moduleId === item.moduleId)?.licencePackageAmounts || modulePackage.licencePackageAmounts
        )
      }
      moduleLicencePackages.push(modulePackage);
    })
    data.moduleLicencePackages = moduleLicencePackages;

    data = { ...bulkParseFloat(data), ...totals }
    return data;
  }

  renderDialogButtons = checkAccessRights => {
    const { packages, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={packages.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={packages.loading} form="package" htmlType="submit" className="success #save" icon="save" loading={packages.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      packages.saving,
      packages.loading
    )
  }

  render() {
    const data = Object.assign({}, this.state.data);
    const { dialogCloseRequest, packages, form } = this.props;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...packages, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={packages.loading}>
            <Form onSubmit={this.save} id="package">

              <Tabs defaultActiveKey="definition">

                <Tabs.TabPane key="definition" tab={i18n.t(`lbl.packageDefinition`)}>
                  <Suspense fallback={<Loading />}>
                    <Definitions {...{ update: this.update, data, getFieldDecorator: form.getFieldDecorator, ref: el => this.definitions = el }} />
                  </Suspense>
                </Tabs.TabPane>

                <Tabs.TabPane key="prices" tab={i18n.t(`lbl.prices`)}>
                  <Suspense fallback={<Loading />}>
                    <Prices {...{ update: this.update, data, getFieldDecorator: form.getFieldDecorator, ref: el => this.prices = el }} />
                  </Suspense>
                </Tabs.TabPane>

              </Tabs>

            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};


const mapStateToProps = ({ admin, common }) => ({
  modules: common.filter.modules,
  packages: admin.packages.definitions.single,
  modulePackages: admin.packages.modulePackages.single
});
const mapDispatchToProps = (dispatch) => ({
  getPackage: (id, callback) => dispatch(actions.get({ url: endpoints.tenant.licencePackages, key: 'packages.definitions', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tenant.licencePackages, key: 'packages', data }, callback)),
  getModulePackages: (data, callback) => dispatch(actions.getWithPost({ url: endpoints.tenant.getModulePackages, key: 'packages.modulePackages', data }, callback)),
});
const PackageForm = Form.create()(Package);
export default connect(mapStateToProps, mapDispatchToProps)(PackageForm);
