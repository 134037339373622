import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from "store/actions/cek";
import endpoints from "config/endpoints";
import utils from "lib";
import { defaultFilters } from "lib/constants";
import i18n from "plugins/i18n";
import { Icon, Modal, Tooltip } from "antd";
import { ActionButtons, CheckAccessRight, DataTable, FadeIn, Instruction } from "components/UIComponents";
import { ChequeCreateOrSendOrderModel } from "models";
import { formatCurrency, isEditableOrderTos } from "helpers";
import DeleteDetail from './DeleteDetail';

class Payroll extends Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id
    this.state = {
      filter: defaultFilters.cek.Cheques(),
      totalAmount: null,
      totalCount: null,
      totalsLoading: false,
      chequeSendingOrCreating: false,
      isEditable: true
    };
  };

  componentDidMount() {
    if (!!this.id) {
      this.getCheques();
      utils.cek.getTenantBanks('PayRoll');
      this.getChequeTotal();
    }
    if (this.props.selectedItems.length > 0) {
      this.getChequeTotal();
    }
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.cheques.list.data && props.cheques.list.data.length) {
      const _cheque = props.cheques && props.cheques.list.data && props.cheques.list.data.length > 0 ? props.cheques.list.data[0] : null;
      return { isEditable: isEditableOrderTos(_cheque && _cheque.PayRoll ? _cheque.PayRoll.OperationStatusId : null) }
    }
    return null
  }

  getCheques = () => {
    let { filter } = this.state;
    filter.filter = { PayRollId: { type: 'guid', value: this.id }, }
    this.props.getCheques(filter);
  };

  getChequeTotal = (checkAll = false) => {
    let _amount = null, _count = null;
    let { selectedItems } = this.props;
    this.setState({ totalsLoading: true });

    if (selectedItems.length || checkAll || !!this.id) {
      let filter = {};
      filter.filter = {
        and: [{ PayRollId: { type: 'guid', value: this.id }, }],
        or: checkAll ? [] : selectedItems.map(id => { return { Id: { type: 'guid', value: id } } })
      }

      this.props.getChequeTotal(filter, response => {

        if (response && response.value && response.value.length > 0) {
          _count = response.value.length;
          _amount = 0;
          response.value.map(cheque =>
            _amount += cheque.Amount
          )
          this.setState({
            totalAmount: _amount,
            totalCount: _count,
            totalsLoading: false
          })
        }
      });
    }
    else {
      this.setState({
        totalAmount: _amount,
        totalCount: _count,
        totalsLoading: false
      })
    }
  }

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getCheques();
    });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, () => {
      this.getCheques();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getCheques);
  }

  additionalButtons = () => {
    let buttons = [
      <div>
        <Icon type="plus" />
        {i18n.t('lbl.downloadExampleFile')}
      </div>,
      <div>
        <Icon type="plus" />
        {i18n.t('lbl.uploadChequeWithFile')}
      </div>
    ];

    return buttons;
  };

  payrollOperation = (data, method) => {
    let getPayRollDetailData = this.props.cheques.list.data[0].Cheque;
    this.setState({ orderSendingOrCreating: true })
    let _data = method === "createPayRoll" ? new ChequeCreateOrSendOrderModel({ ...data, ChequesIdList: this.props.selectedItems }) : new ChequeCreateOrSendOrderModel({ ...data, PayRollId: this.id, ChequesIdList: this.props.selectedItems, TenantPaymentAccountId: getPayRollDetailData.TenantPaymentAccountId });

    this.props[method](_data, (response) => {
      this.setState({ chequeSendingOrCreating: false })
      if (response) {
        let status = response.StatusCode === 200 || response.statusCode === 200 ? 'success' : 'error';
        Modal[status]({
          title: (response && response.Message) ? response.Message : status === 'success' ? i18n.t('msg.success') : i18n.t('msg.error'),
          content: status === 'success' ? i18n.t(`dbs.${method}Response`) : i18n.t('msg.unknownError'),
          onOk: () => {
            this.getCheques();
            utils.common.clearSelectedItems();
          }
        })
      }
    })
  }

  callbackDelete = () => {
    this.getCheques(response => {
      this.getChequeTotal(response);
    });
  }

  setPayrollDetail = payrollResponse => {
    const _cheque = payrollResponse && payrollResponse.list.data && payrollResponse.list.data.length > 0 ? payrollResponse.list.data[0] : null;
    return {
      id: this.id,
      description: _cheque && _cheque.PayRoll ? _cheque.PayRoll.Description : null,
      bankCode: _cheque && _cheque.PayRoll ? _cheque.PayRoll.BankCode : null,
      cheques: payrollResponse.list.data,
      currencyCode: _cheque ? _cheque.CurrencyCode : null,
      count: payrollResponse.list.count,
      loading: payrollResponse.list.loading,
      statusId: _cheque && _cheque.PayRoll ? _cheque.PayRoll.OperationStatusId : null,
      sendDate: _cheque && _cheque.PayRoll ? _cheque.PayRoll.SendDate : null
    }
  }

  renderActionButtons = row => {
    return !this.state.isEditable && <ActionButtons url="Cheque" editUrl item={row} hasDelete getDatas={this.callbackDelete} openDialog={this.datatable && this.datatable.openDialog} />
  }


  render() {
    let { totalAmount, totalCount, totalsLoading, chequeSendingOrCreating, isEditable } = this.state;
    const { cheques, tenantBanks, selectedItems } = this.props;
    const _payroll = this.setPayrollDetail(cheques);
    isEditable = !isEditable;

    const instructions = [
      { label: i18n.t('dbs.bankName'), key: 'BankCode', type: 'select', options: tenantBanks.data, name: (bankData) => utils.cek.getBankName({ bankData }), value: "Code", defaultValue: _payroll.bankCode, disabled: true, required: true, prior: true },
      { label: i18n.t('dbs.totalAmount'), key: 'InvoiceAmount', type: 'input', defaultValue: totalAmount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: isEditable && true },
      { label: i18n.t('dbs.totalCount'), key: 'TotalCount', type: 'input', defaultValue: totalCount, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: isEditable && true },
      { label: i18n.t('lbl.description'), key: 'Description', type: 'input', defaultValue: _payroll.description, unchangeable: true, suffix: <Tooltip title={i18n.t('lbl.unchangeable')}><Icon type="info-circle" /></Tooltip>, withLoading: true, prior: true, required: isEditable && true },
      isEditable && { label: i18n.t('btn.createOrder'), type: 'button', className: 'primary', onClick: (data) => this.payrollOperation(data, 'createPayroll'), disabled: (!!!this.id || _payroll.count === 0 || chequeSendingOrCreating || !selectedItems.length > 0), access: 'PayRoll.Create' },
      { label: i18n.t(isEditable ? 'btn.sendOrder' : 'btn.sent'), type: 'button', className: 'success', onClick: (data) => this.payrollOperation(data, 'sendPayroll'), disabled: (!!!this.id || _payroll.count === 0 || chequeSendingOrCreating || !isEditable), access: 'PayRoll.Send' },
    ]

    const columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.consumerAccountNumber'), key: 'AccountNumber' },
      { label: i18n.t('lbl.consumerTitle'), key: 'Title' },
      { label: i18n.t('lbl.bankName'), key: 'BankName', render: (data) => utils.cek.getBankName({ data }), checkField: false, },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: ({ Amount, CurrencyCode }) => formatCurrency({ data: Amount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('lbl.description'), key: 'Description' },
    ];
    return (
      <FadeIn>
        <CheckAccessRight {...{ ...cheques }}>
          <Instruction {...{ instructions, loading: totalsLoading }} />
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            count={_payroll.count}
            access="Cheque"
            history={this.props.history}
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={_payroll.cheques}
            loading={_payroll.loading}
            title={i18n.t('lbl.createCheque')}
            newButton={isEditable && { endpoint: "openDialog", title: 'addCheque' }}
            additionalButtons={isEditable && this.additionalButtons()}
            getData={this.getCheques}
            checkbox={isEditable}
            onCheck={this.getChequeTotal}
            checkAll={isEditable && _payroll.cheques && _payroll.cheques.length > 0}
            Component={isEditable && DeleteDetail}
            dialogTitle={i18n.t('lbl.Cheque')}
            dialogNotEditable={!isEditable}
            deleteOptions={{ stateKey: "cheques", url: "Cheque" }}
          />
        </CheckAccessRight>
      </FadeIn>
    );
  }
}

const mapStateToProps = ({ cek, common }) => ({
  cheques: cek.cheques,
  tenantBanks: cek.filter.tenantBanks,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getCheques: (filter, callback) => dispatch(actions.getAll({ filter, url: endpoints.cek.cheques, key: 'cheques' }, callback)),
  getChequeTotal: (filter, callback) => dispatch(actions.getAll({ filter, url: endpoints.cek.cheques }, callback)),
  createPayroll: (data, callback) => dispatch(actions.post({ url: endpoints.cek.createPayroll, data, customNotification: true }, callback)),
  sendPayroll: (data, callback) => dispatch(actions.post({ url: endpoints.cek.sendPayroll, data, customNotification: true }, callback)),
  clearState: () => dispatch(actions.clearState("cheques"))
});
export default connect(mapStateToProps, mapDispatchToProps)(Payroll);