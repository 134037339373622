import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { DataTable, ActionButtons, Filter } from 'components/UIComponents';
import MatchingCondition from './Detail';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { defaultFilters, dialogTypes, baseUrls } from 'lib/constants';
import utils from 'lib';

class MatchingConditions extends Component {
  state = {
    filter: defaultFilters.netekstre.MatchingConditions(),
  };

  componentDidMount() {
    this.getMatchingConditions();
    this.props.getCurrentAccountSelectedItems();
    this.props.getDynamicProperties();
    utils.common.checkAccessToUpdateOrDelete('CurrentAccountDynamicProperties');
  };

  getMatchingConditions = () => {
    let { filter } = this.state;
    this.props.getMatchingConditions(filter);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getMatchingConditions();
    });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({
      filter
    }, this.getMatchingConditions);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getMatchingConditions);
  }

  render() {
    let { matchingConditions, currentAccountSelectedItems, dynamicProperties, auth } = this.props;
    let access = auth.data.accessRights.find(x => x.endPoint === 'CurrentAccountDynamicProperties' && x.method === 'POST');
    let filters = [
      { label: i18n.t('lbl.dynamicFieldName'), type: 'select', options: dynamicProperties.data, value: 'id', name: 'name', keys: ['DynamicPropertyId'], showAll: true, uniqueKey: '#dynamicPropertyId', multiple: true, guid: true },
      { label: i18n.t('lbl.currentAccountSelectedItemText'), type: 'select', options: currentAccountSelectedItems.data, value: 'name', name: 'description', keys: ['currentAccountSelectedItemText', 'currentAccountSelectedItem'], showAll: true, uniqueKey: '#currentAccountSelectedItem', multiple: true },
    ];
    let columns = [
      { render: (row) => <ActionButtons url="CurrentAccountDynamicProperties" state="matchingConditions" editUrl="openDialog" item={row} hasDelete getDatas={this.getMatchingConditions} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.dynamicFieldName'), key: 'Title' },
      { label: i18n.t('lbl.currentAccountSelectedItemText'), key: 'CurrentAccountSelectedItemText' },
    ];

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="CurrentAccountDynamicProperties"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={matchingConditions.list.data}
          loading={matchingConditions.list.loading}
          title={i18n.t('route.netekstre.matchingConditions')}
          newButton={access && "openDialog"}
          Component={MatchingCondition}
          dialogTitle={i18n.t('lbl.matchingCondition')}
          dialogType={dialogTypes.Modal}
          getData={this.getMatchingConditions}
          count={matchingConditions.list.count}
          deleteOptions={{ stateKey: "matchingConditions", url: "CurrentAccountDynamicProperties", baseUrl: baseUrls.nte }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common, auth }) => ({
  auth,
  matchingConditions: netekstre.matchingConditions,
  dynamicProperties: netekstre.filter.dynamicProperties,
  currentAccountSelectedItems: common.filter.currentAccountSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  getMatchingConditions: (filter) => dispatch(netekstreActions.getAll({ filter, url: endpoints.nte.matchingConditions, key: 'matchingConditions', isNewODataStructure: true })),
  getCurrentAccountSelectedItems: (filter) => dispatch(commonActions.getFilter({ filter, url: endpoints.tenant.currentAccountSelectedItems, key: 'currentAccountSelectedItems' })),
  getDynamicProperties: (filter) => dispatch(netekstreActions.getFilter({ filter, url: endpoints.lookups.dynamicProperties, key: 'dynamicProperties' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(MatchingConditions);