import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { ParameterModel } from 'models';
import { serviceTypes } from 'helpers';
import actions from 'store/actions/dbs';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new ParameterModel(),
      InitialState: new ParameterModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.parameters.single.data && !state.data.Id) {
      const data = new ParameterModel({ ...props.parameters.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.parameters.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    const { endpoint, getParameters, tenantId } = this.props;
    const parameters = tenantId && { TenantId: tenantId };
    if (this.id) getParameters(this.id, parameters, endpoint);
    else this.setState({ loading: false });

    utils.dbs.getDbsBanks(null, tenantId && { TenantId: tenantId })

    this.props.getCurrencies({ orderBy: ['Id asc'] });

  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    const { data } = this.state;
    if (key === "BankCode") {
      this.props.getCurrencies({ filter: { BankCode: value }, orderBy: ['Id asc'] });
    }
    this.setState({ data: { ...data, [key]: value } });
  };


  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData, endpoint, tenantId } = this.props;
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        const parameters = tenantId && { TenantId: tenantId }
        saveData(this.state.data, method, endpoint, parameters, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };


  renderDialogButtons = checkAccessRights => {
    let { parameters, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={parameters.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={parameters.single.loading} form="parameter" htmlType="submit" className="success #save" icon="save" loading={parameters.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      parameters.single.saving,
      parameters.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { dbsBanks, currencies, parameters, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;
    const _currencies = currencies.data ? currencies.data.map(x => x.Code === 'TRY' ? { ...x, disabled: true } : x) : [];

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...parameters, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="parameter">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankCode
                    })(
                      <Select
                        loading={dbsBanks.loading}
                        className="#bank"
                        label={i18n.t('dbs.bankName')}
                        options={dbsBanks.data}
                        optVal="Code"
                        optName={(bankData) => utils.dbs.getBankName({ bankData })}
                        onChange={(e) => this.update('BankCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ServiceTypeId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ServiceTypeId
                    })(
                      <Select
                        className="#type"
                        label={i18n.t('dbs.serviceTypes')}
                        options={serviceTypes}
                        optVal="id"
                        optName="name"
                        allowClear={false}
                        onChange={(e) => this.update('ServiceTypeId', e)}
                        lang="dbs"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankDbsCode1', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankDbsCode1
                    })(
                      <Input className="#BankDbsCode1" label={i18n.t('dbs.BankDbsCode1')} onChange={(e) => this.update('BankDbsCode1', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankDbsCode2', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.BankDbsCode2
                    })(
                      <Input className="#BankDbsCode2" label={i18n.t('dbs.BankDbsCode2')} onChange={(e) => this.update('BankDbsCode2', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('CurrencyCodes', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CurrencyCodes
                    })(
                      <Select
                        mode="multiple"
                        maxTagCount={2}
                        options={_currencies}
                        optVal="Code"
                        optName="Name"
                        allowClear={false}
                        className="#CurrencyCodes"
                        label={i18n.t('lbl.currencyCode')}
                        onChange={(e) => this.update('CurrencyCodes', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                {
                  data.ServiceTypeId && serviceTypes.find(x => x.id === data.ServiceTypeId).name.includes('ftp') &&
                  <>
                    <Col xs={18} sm={9}>
                      <Form.Item>
                        {getFieldDecorator('Host', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Host
                        })(
                          <Input className="#host" label={i18n.t('dbs.hostName')} onChange={(e) => this.update('Host', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={6} sm={3}>
                      <Form.Item>
                        {getFieldDecorator('Port', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Port
                        })(
                          <Input className="#port" label={i18n.t('dbs.portName')} onChange={(e) => this.update('Port', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('UploadFolder', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.UploadFolder
                        })(
                          <Input className="#uploadFolder" label={i18n.t('dbs.upFolder')} onChange={(e) => this.update('UploadFolder', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('DownloadFolder', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.DownloadFolder
                        })(
                          <Input className="#downloadFolder" label={i18n.t('dbs.downFolder')} onChange={(e) => this.update('DownloadFolder', e)} />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                }
                {
                  data.ServiceTypeId && serviceTypes.find(x => x.id === data.ServiceTypeId).name === "ws" &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      {getFieldDecorator('Url', {
                        rules: [{ required: true, message: i18n.t('msg.required') }],
                        initialValue: data.Url
                      })(
                        <Input className="#url" label={i18n.t('dbs.url')} onChange={(e) => this.update('Url', e)} />
                      )}
                    </Form.Item>
                  </Col>
                }
                {
                  data.ServiceTypeId &&
                  <>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('Username', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Username
                        })(
                          <Input className="#username" label={i18n.t('dbs.userName')} onChange={(e) => this.update('Username', e)} />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        {getFieldDecorator('Password', {
                          rules: [{ required: true, message: i18n.t('msg.required') }],
                          initialValue: data.Password
                        })(
                          <Input type="password" className="#password" label={i18n.t('dbs.password')} onChange={(e) => this.update('Password', e)} />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                }
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  parameters: dbs.parameters,
  dbsBanks: dbs.filter.dbsBanks,
  currencies: dbs.filter.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getParameters: (id, parameters, endpoint) => dispatch(actions.get({ url: endpoint || endpoints.dbs.dbsAccount, parameters, key: 'parameters', id })),
  saveData: (data, method, endpoint, parameters, callback) => dispatch(actions[method]({ url: endpoint || endpoints.dbs.dbsAccount, parameters, key: 'parameters', data }, callback)),
  getCurrencies: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.dbs.currencies, key: 'currencies', isNewODataStructure: true })),
  clearState: () => dispatch(actions.clearState("parameters"))
});
const ParameterForm = Form.create()(Parameter);
export default connect(mapStateToProps, mapDispatchToProps)(ParameterForm);