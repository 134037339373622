import React, { useState, createRef } from 'react';
import i18n from 'plugins/i18n';
import { Typography, FormElements, Button, FadeIn } from 'components/UIComponents';
import { Form } from 'antd';
import { connect } from 'react-redux'
import actions from 'store/actions/auth';
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from 'react-router-dom';


const ForgotPassword = ({ form, forgotPassword, history }) => {
  const recaptchaRef = createRef();
  const { getFieldDecorator } = form;
  const [data, updateData] = useState({ email: null });
  const [encodedResponse, setEncodedResponse] = useState(null);
  const [sending, setSending] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(error => {
      if (!error && encodedResponse) {
        setSending(true);
        forgotPassword(data, { encodedResponse }, () => {
          setTimeout(() => {
            history.push('/auth/sign-in');
          }, 1000);
        });
      }
    });
  }

  return (
    <FadeIn>
      <Typography.H className="mb-0" size={1}>{i18n.t('lbl.forgotPassword')}</Typography.H>
      <Typography.P size="big">{i18n.t('lbl.forgotPasswordMessage')}</Typography.P>
      <Form className="mt-50" onSubmit={onSubmit}>
        <Form.Item>
          {
            getFieldDecorator('email', {
              rules: [
                { required: true, message: i18n.t('msg.required') },
                { type: 'email', message: i18n.t('msg.emailInvalid') }
              ],
              initialValue: data.username
            })(
              <FormElements.Input
                label={i18n.t('lbl.email')}
                prefix="user"
                onChange={(e) => updateData((data) => ({ ...data, email: e }))}
              />
            )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('encodedResponse', {
            rules: [
              { required: true, message: i18n.t('msg.validationRequired') },
            ]
          })(
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LeOWJwUAAAAAAhFmUsMAVzxGtIA5ylJp-vjz5NH"
              onChange={(value) => { value && setEncodedResponse(value) }}
              className="recaptcha-item"
            />
          )}
        </Form.Item>

        <Button htmlType="submit" block className="auth-button mt-30" customIcon="right" customLoading={sending}>
          {i18n.t('btn.send')}
        </Button>
      </Form>
    </FadeIn>
  )
}
const mapStateToProps = ({ auth }) => ({ ...auth });
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data, headers, callback) => dispatch(actions.forgotPassword({ data, headers }, callback)),
});
const ForgotPasswordForm = withRouter(Form.create()(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)));
export default ForgotPasswordForm;

