import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netahsilat';
import { defaultFilters } from 'lib/constants';
import { DataTable, ActionButtons, FadeIn, Filter, Loading } from "components/UIComponents";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { Tooltip, Icon, Button, Tag } from 'antd';
import utils from 'lib';
import { formatDate, formatMoney } from 'helpers';
import Payment from './Detail';
import moment from 'moment';

const CancelOrRefund = lazy(() => import('./components/CancelOrRefund'));

class Payments extends Component {
  constructor(props) {
    super(props);
    const locationState = props.location.state;
    this.locationFilter = locationState ? { and: [{ NetLinkTransactionId: { type: 'guid', value: locationState.NetLinkTransactionId } }] } : null;
    props.history.replace('/netahsilat/payment', null);
    this.state = {
      filter: defaultFilters.netahsilat.Payments(this.locationFilter),
    }
  }

  componentDidMount() {
    this.getPageData();
    utils.netahsilat.getTransactionStatuses();
    utils.netahsilat.getTransactionTypes();
    utils.netahsilat.getCurrencies();
  };

  getPageData = () => {
    this.getPayments();
    this.getPaymentsCalc();
  }

  getPayments = () => {
    const { filter } = this.state;
    this.props.getPayments(filter);
  };

  getPaymentsCalc = () => {
    const transform = [{
      filter: { ...this.state.filter.filter }
    }, {
      groupBy: {
        properties: ['VPosTransactionType'],
        transform: [{
          aggregate: {
            ProcessAmount: { with: 'sum', as: 'ProcessAmountTotal' },
            ProcessNetAmount: { with: 'sum', as: 'ProcessNetAmountTotal' },
            LastAmount: { with: 'sum', as: 'LastAmountTotal' },
          }
        }]
      }
    }]
    this.props.getPaymentsCalc({ transform });
  };


  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({
      filter
    }, this.getPageData);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter, orderBy: [`${key} ${type}`]
      }
    }, this.getPageData);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getPageData);
  }

  additionalButtons = ({ Id, InsertedDate, TransactionStatusId, VPosTransactionType, LastAmount }) => {
    const { accessRights } = this.props;
    const type = moment(InsertedDate).diff(moment(), 'days') === 0 ? 'cancellation' : 'refund';

    const onClick = e => {
      e.stopPropagation();
      this.cancelOrRefund.openModal(type, Id)
    }

    const getTitle = () => {
      if (TransactionStatusId !== 1) return i18n.t('msg.onlySuccessfulTransactionUsable')  // 1: Başarılı
      else if (LastAmount === 0) return i18n.t('msg.unusableCuzCanceled')  // LastAmount = 0 : İptal
      else return i18n.t(`btn.${type}`)
    }

    return (
      Id ?
        <>
          {
            accessRights.find(x => x.endPoint === 'Transaction' && x.method === 'GET') &&
            <Tooltip placement="bottom" title={i18n.t('btn.paymentInfo')}>
              <Button className="#paymentInfo" size="small">
                <Icon type="file-search" />
              </Button>
            </Tooltip>
          }
          {
            accessRights.find(x => x.endPoint === (type === 'refund' ? 'Refund' : 'Cancel') && x.method === 'POST') &&
            <Tooltip placement="bottom" title={getTitle()} overlayClassName="text-center">
              <Button disabled={TransactionStatusId !== 1 || LastAmount === 0} className={`#${type}`} size="small" onClick={onClick}>
                <Icon type={type === 'refund' ? "retweet" : "close-circle"} />
              </Button>
            </Tooltip>
          }
        </>
        :
        <Tooltip placement="bottom" title={i18n.t('lbl.pageTotal')}>
          <Button className="#pageTotal" size="small" onClick={e => e.stopPropagation()}>
            <Icon type="info-circle" />
          </Button>
        </Tooltip>
    )
  }

  getTransactionTypeName = (row) => {
    const transactionTypes = [...this.props.transactionTypes.data, { id: "total", name: i18n.t('lbl.total') }];
    return transactionTypes.find(x => x.id === row.VPosTransactionType)?.name || '';
  }

  getTransactionStatusName = (row) => this.props.transactionStatuses.data.find(x => x.id === row.TransactionStatusId)?.name || '';

  getCurrencyName = (row) => this.props.currencies.data.find(x => x.id === row.CurrencyId)?.name || '';

  calcTotal = data => ({
    VPosTransactionType: "total",
    ProcessAmount: data.reduce((a, b) => a + b.ProcessAmount, 0),
    ProcessNetAmount: data.reduce((a, b) => a + b.ProcessNetAmount, 0),
    LastAmount: data.reduce((a, b) => a + b.LastAmount, 0),
    CurrencyId: data[0]?.CurrencyId,
    disableOnRowClick: true,
    isTotalRow: true
  })

  getCalculatedData = () => {
    const { paymentsCalc, history } = this.props;
    const types = [{ type: 'sales' }, { type: 'cancel', label: 'cancelOrRefund' }, { type: 'balance' }];
    const data = {
      sales: paymentsCalc.data.filter(x => x.VPosTransactionType === 3 || x.VPosTransactionType === 4).reduce((a, b) => a + b.ProcessAmountTotal, 0),
      cancel: paymentsCalc.data.reduce((a, b) => a + b.ProcessAmountTotal, 0) - paymentsCalc.data.reduce((a, b) => a + b.LastAmountTotal, 0),
      get balance() { return this.sales - this.cancel }
    };

    const Loading = ({ children }) => paymentsCalc.loading ? <Icon type="loading" /> : children;

    const getTag = ({ type, label, tagData, currency = 'TRY' }) => (
      <Tag key={type} className={type}>{i18n.t(`lbl.${label || type}`)} : <Loading>{formatMoney(tagData || data[type])} {currency}</Loading></Tag>
    );

    return (
      <>
        <div className="card-title-tags">
          {types.map(getTag)}
          <Tooltip placement="right" title={i18n.t('lbl.generalTotals')} >
            <Icon type="info-circle" style={{ color: 'rgba(0,0,0,0.25)' }} />
          </Tooltip >
        </div>
        {
          this.locationFilter &&
          <Button className="blue pull-right" onClick={() => history.goBack()}>{i18n.t('btn.goBack')}</Button>
        }
      </>
    );
  }

  render() {
    const { payments, transactionStatuses, transactionTypes } = this.props;
    const filters = [
      { label: i18n.t('lbl.transactionDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['InsertedDate'], uniqueKey: '#insertedDateStart', disabled: '1' },
      { label: i18n.t('lbl.transactionDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['InsertedDate'], uniqueKey: '#insertedDateEnd', disabled: '0' },
      { label: i18n.t('lbl.operationStatus'), type: 'select', options: transactionStatuses.data, value: 'id', name: 'name', keys: ['TransactionStatusId'], showAll: true, uniqueKey: '#transactionStatus', multiple: true, loading: transactionStatuses.loading },
      { label: i18n.t('lbl.operationType'), type: 'select', options: transactionTypes.data, value: 'id', name: 'name', keys: ['VPosTransactionType'], showAll: true, uniqueKey: '#transactionType', multiple: true, loading: transactionTypes.loading },
      { label: i18n.t('lbl.userName'), type: 'input', keys: ['User/Member/Name', 'User/Member/Surname'], contains: true, uniqueKey: '#username' },
      { label: i18n.t('lbl.referenceNumber'), type: 'input', keys: ['OrderReference'], contains: true, uniqueKey: '#referenceNumber' }
    ];
    const columns = [
      {
        render: (row, i) =>
          <ActionButtons
            url="Transaction"
            item={row}
            getDatas={this.getPageData}
            openDialog={this.datatable?.openDialog}
            additionalButtons={() => this.additionalButtons(row, i)}
          />,
        toggle: false, sort: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.operationType'), checkField: false, key: 'VPosTransactionType', render: this.getTransactionTypeName },
      { label: i18n.t('lbl.operationStatus'), checkField: false, key: 'TransactionStatusId', render: this.getTransactionStatusName },
      { label: i18n.t('lbl.transactionDate'), checkField: false, key: 'InsertedDate', render: row => formatDate(row.InsertedDate, null, null, null, false) },
      { label: i18n.t('lbl.amount'), checkField: false, key: 'ProcessAmount', render: row => <>{formatMoney(row.ProcessAmount)} {this.getCurrencyName(row)}</> },
      { label: i18n.t('lbl.balance'), checkField: false, key: 'LastAmount', render: row => <>{formatMoney(row.LastAmount)} {this.getCurrencyName(row)}</> },
      { label: i18n.t('lbl.netAmount'), checkField: false, key: 'ProcessNetAmount', render: row => <>{formatMoney(row.ProcessNetAmount)} {this.getCurrencyName(row)}</> },
      { label: i18n.t('lbl.user'), key: 'User/Name', checkField: false, render: (row) => `${row.User?.Member?.Name || ''} ${row.User?.Member?.Surname || ''}` },
      { label: i18n.t('lbl.referenceNumber'), checkField: false, key: 'OrderReference', },
    ];

    const data = [...payments.data, this.calcTotal(payments.data)];

    return (
      <div className="page-content">
        <FadeIn>
          {
            !this.locationFilter &&
            <Filter filters={filters} onFilter={this.onFilter} />
          }
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            access="Transaction"
            history={this.props.history}
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={data}
            loading={payments.loading}
            title={i18n.t('route.netahsilat.payments')}
            getData={this.getPageData}
            count={payments.count}
            excel={!this.locationFilter && { url: "ExportTransaction", module: "nth" }}
            withTotal
            CustomButton={this.getCalculatedData}
            Component={Payment}
            dialogTitle={i18n.t('lbl.paymentInfo')}
          />
          <Suspense fallback={<Loading />}>
            <CancelOrRefund wrappedComponentRef={el => this.cancelOrRefund = el} getData={this.getPageData} />
          </Suspense>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ netahsilat }) => ({
  payments: netahsilat.payments.list,
  paymentsCalc: netahsilat.paymentsCalc.list,
  transactionStatuses: netahsilat.filter.transactionStatuses,
  transactionTypes: netahsilat.filter.transactionTypes,
  currencies: netahsilat.filter.currencies,
});
const mapDispatchToProps = (dispatch) => ({
  getPayments: (filter) => dispatch(actions.getAll({ filter, url: endpoints.netahsilat.payments, key: 'payments', isNewODataStructure: true })),
  getPaymentsCalc: (filter) => dispatch(actions.getAll({ filter, url: endpoints.netahsilat.payments, key: 'paymentsCalc' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Payments);