import React from 'react'
import { Layout } from 'antd'
import { Switch, Route, withRouter, Redirect, } from "react-router-dom";
import routes from "plugins/routes";
import { Loading } from 'components/UIComponents';
import { useSelector } from 'react-redux';
import { NoAccessRight } from 'components/UIComponents';

const Content = ({ location, loading }) => {
  const currentModule = location.pathname.substr(1).split('/')[0];
  const _routes = routes.find(x => x.name === currentModule);
  const _subRoutes = _routes.routes || [];
  const auth = useSelector(({ auth }) => ({ ...auth }));
  const accessRights = auth.data ? auth.data.accessRights : [];
  const hasAccessRight = accessRights.find(x => x.endPoint === "ManagementPanel" && x.method === "GET");

  const getDefaultRoute = () => {
    let dr = "";
    if (accessRights.length) {
      dr = _subRoutes
        .filter(x => x.visible !== false)
        .find(x =>
          !x.access
          ||
          accessRights
            .find(y =>
              typeof x.access === "string" ?
                y.endPoint === x.access
                :
                x.access.includes(y.endPoint)
            )
        ).path
    }
    return dr;
  }

  return (
    <Loading loading={loading}>
      <Layout.Content className={`${currentModule} module-content`}>
        {
          hasAccessRight ?
            <Switch>
              {/* _subRoutes.filter(x => (token && x.tokenCheck === true) || (!token && !x.tokenCheck)).map(route => ( */}

              {_subRoutes.map((route, i) => {
                if ((!route.access) ||
                  (route.access && typeof route.access === 'string' && accessRights.length > 0 && accessRights.find(x => x.endPoint === route.access && (x.method === 'GET'))) ||
                  (route.access && Array.isArray(route.access) && accessRights.length > 0 && accessRights.some(x => route.access.includes(x.endPoint)))
                )
                  return <Route path={`${_routes.path}${route.path}`} render={(props) => <route.component {...props} accessRights={accessRights} />} key={route.name} />
                else
                  return null
              })}
              <Redirect from="/admin" to={`/admin${getDefaultRoute()}`} />
            </Switch>
            :
            <NoAccessRight />
        }
      </Layout.Content>
    </Loading>
  )
}

export default withRouter(Content);