import React, { Component } from 'react';
import { Layout } from "antd";
import Home from '../Views/Home'
import { Loading } from 'components/UIComponents';

class Content extends Component {
  render() {
    return (
      <Layout.Content className="content-home">
        <Loading loading={this.props.loading}>
          <Home />
        </Loading>
      </Layout.Content>
    );
  };
};

export default Content;