import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import netekstreRoutes from 'plugins/routes/modules/netekstre';

class Reports extends Component {
  render() {
    const reports = netekstreRoutes.routes.find(x => x.name === 'reports');
    let { accessRights } = this.props;
    return (
      <React.Fragment>
        <Switch>
          {reports.routes.map((route, i) => {
            if ((!route.access) ||
              (route.access && typeof route.access === 'string' && accessRights.length > 0 && accessRights.find(x => x.endPoint === route.access)) ||
              (route.access && Array.isArray(route.access) && accessRights.length > 0 && accessRights.some(x => route.access.includes(x.endPoint)))
            )
              return <Route path={`${netekstreRoutes.path}${reports.path}${route.path}`} component={route.component} key={`rd-${i}`} />;
            else
              return null
          })}
          <Redirect from="/dashboard" to="/dashboard/home" />
        </Switch>
      </React.Fragment>
    );
  };
};

export default Reports;