export class ErrorModel {
  constructor(nativeError = null, throwError = null) {
    this.statusCode = throwError ? throwError.status : nativeError ? nativeError.statusCode : 500;
    this.messages = getMessage(nativeError, throwError);
    this.data = throwError || nativeError
  };
};

const getMessage = (nativeError, throwError) => {
  let message = [];
  if (throwError && throwError.data && throwError.data.errors) throwError.data.errors.map(x => message.push({ code: x.code, message: x.message, data: x.data }));
  else if (nativeError.message) message.push({ code: nativeError.message, message: null })
  else message.push({ code: 'unknownError', message: null });
  return message;
}

export class TenantInfoModel {
  constructor(data = null) {
    this.name = data && data.name ? data.name : "";
    this.taxNumber = data && data.taxNumber ? data.taxNumber : "";
    this.address = data && data.address ? data.address : "";
    this.cityId = data && data.cityId ? data.cityId : undefined;
    this.districtId = data && data.districtId ? data.districtId : undefined;
    this.countryId = data && data.countryId ? data.countryId : undefined;
  };
};

export class DocumentModel {
  constructor(data = null) {
    this.tenantDocumentType = data && data.tenantDocumentType ? data.tenantDocumentType : undefined;
    this.approveStatus = data && data.approveStatus ? data.approveStatus : undefined;
    this.approveStatusName = data && data.approveStatusName ? data.approveStatusName : "";
    this.documentName = data && data.documentName ? data.documentName : "";
    this.downloadUrl = data && data.downloadUrl ? data.downloadUrl : "";
    this.isFileUploadability = data && data.isFileUploadability ? data.isFileUploadability : false;
    this.isDeletable = data && data.isDeletable ? data.isDeletable : false;
    this.linkName = data && data.linkName ? data.linkName : "";
    this.sampleFileDownloadUrl = data && data.sampleFileDownloadUrl ? data.sampleFileDownloadUrl : "";
    this.tenantId = data && data.tenantId ? data.tenantId : "";
    this.name = data && data.name ? data.name : "";
  };
};