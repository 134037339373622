import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Collapse, Row, Col, Button, Icon, Form, Tag, Slider, Modal } from 'antd';
import { Input, Select, DatePicker } from './NetbankaComponents';
import { formatDate } from 'helpers';
import { defaultValues } from 'lib/constants';
import actions from 'store/actions/common';
import moment from 'moment';
import i18n from 'plugins/i18n';
import FadeIn from './FadeIn';
import { getKey } from 'lib/constants/defaultFilters';

class Filter extends Component {
  constructor(props) {
    super(props);
    let key = getKey();
    let fields = {};
    (props.currentFilter[key] && props.currentFilter[key].fields && !props.hasCurrent) ?
      fields = window._.cloneDeep(props.currentFilter[key].fields)
      :
      props.filters.map((x, i) =>
        x ?
          fields[i] =
          x.defaultValue ?
            x.defaultValue
            :
            x.multiple ?
              defaultValues.multiSelect
              :
              defaultValues[x.type]
          :
          null
      );
    this.state = {
      fields,
      key,
      activeKey: props.currentFilter[key] && props.currentFilter[key].fields && Object.keys(props.currentFilter[key].fields).find(x => props.currentFilter[key].fields[x] !== null && props.currentFilter[key].fields[x] !== undefined && props.currentFilter[key].fields[x] !== '' && props.currentFilter[key].fields[x] !== null) ? [] : ['1'],
      disabled: false,
    };
    this.callbackTimeout = null;
  };

  componentDidMount() {
    if (this.props.hasCurrent && !this.props.currentFilter[this.state.key]) {
      this.props.updateFilter(this.state.fields, this.createFilter(), this.state.key);
    }
  }

  applyFilter = async (...args) => {
    if(args[1]) args[1].stopPropagation();
    const _args = args.splice(1);
    const { needToConfirm, datatable } = this.props;

    if (needToConfirm && datatable.state.selectAll)
      this.confirmFilterChanges()
        .then(confirmation => confirmation ? args[0](..._args) : this.forceUpdate());
    else
      args[0](..._args)
  }

  confirmFilterChanges = async () => {
    const { datatable } = this.props;
    return new Promise((resolve) => Modal.confirm({
      title: i18n.t('msg.needToConfirmation'),
      content: <div dangerouslySetInnerHTML={{ __html: i18n.t('msg.transactionTransferConfirmMessage') }} />,
      okText: i18n.t('btn.confirm'),
      cancelText: i18n.t('btn.cancel'),
      onOk: async () => {
        datatable.checkAll(false);
        resolve(true)
      },
      onCancel: async () => {
        resolve(false);
      }
    }))
  }

  deleteFields = (indexList) => {
    let { fields } = this.state;
    Object.keys(fields).forEach(x => {
      if (indexList.indexOf(parseInt(x)) > -1) {
        delete fields[x];
      }
    });
    this.setState({ fields });
    let filter = this.createFilter();
    this.props.onFilter(filter);
    this.props.updateFilter(fields, filter, this.state.key);
  };

  createFilter = () => {
    let filter = {
      and: []
    };
    this.props.filters.forEach((x, i) => {
      let or = [];
      if (this.state.fields[i] !== defaultValues[x.type]) {
        x.keys.forEach(y => {
          if (x.indexof) {
            or.push({ [`indexof(${y}, '${this.state.fields[i]}')`]: 0 });
          }
          if (x.contains) {
            or.push({ [y]: { contains: y === 'iban' ? this.state.fields[i].replace(/ /g, '') : this.state.fields[i] } });
          }
          else if (x.type === 'date') {
            let date = new Date(formatDate(this.state.fields[i], true, null, x.date === 'le', true, !!x.time));
            date.setHours(date.getHours() - 3);
            date = x.endOf ? moment(date).endOf('day').subtract(999, 'milliseconds')._d : date;
            or.push({ [y]: { [x.date]: date } });
          }
          else if (x.type === 'select' && x.nullCheck) {
            or.push({ [y]: { [this.state.fields[i]]: null } })
          }
          else if (x.type === 'select' && x.multiple && this.state.fields[i] && x.any && x.guid) {
            this.state.fields[i].map(f =>
              or.push({ [y]: { any: { [x.any]: { eq: { type: 'guid', value: f } } } } })
            )
          }
          else if (x.type === 'select' && x.multiple && this.state.fields[i] && !x.any && x.guid) {
            this.state.fields[i].map(f =>
              or.push({ [y]: { eq: { type: 'guid', value: f } } })
            )
          }
          else if (x.type === "input" && x.guid) {
            or.push({ [y]: { eq: { type: 'guid', value: this.state.fields[i] } } })
          }
          else if (x.type === 'select' && x.multiple && this.state.fields[i] && !x.any) {
            this.state.fields[i].map(f =>
              or.push({ [y]: f })
            )
          }
          else if (x.type === "number" && x.any) {
            if (this.state.fields[i] !== "") {
              or.push({ [y]: { any: { [x.any]: { [x.operator]: parseInt(this.state.fields[i]) } } } });
            }
          }
          else if (x.type === "number") {
            if (this.state.fields[i] !== "") {
              or.push({ [y]: { [x.operator]: parseInt(this.state.fields[i]) } });
            }
          }
          else if (x.any && !x.multiple) {
            or.push({ [y]: { any: { [x.any]: x.any === 'iban' ? this.state.fields[i].replace(/ /g, '') : this.state.fields[i] } } });
          }
          else if (x.any && x.multiple) {
            // eslint-disable-next-line
            this.state.fields[i].map(f => {
              or.push({ [y]: { any: { [x.any]: x.any === 'iban' ? f.replace(/ /g, '') : f, [x.any2 && x.any2[0]]: x.any2 && x.any2[1] ? x.any2 && x.any2[1] : undefined } } });
            });
          }
          else if (x.type === "select" && x.boolean) {
            or.push({ [y]: Boolean(this.state.fields[i].toString() === "true") })
          }
          else if (x.type === 'slider') {
            or.push({ [y]: { ge: this.state.fields[i][0], le: this.state.fields[i][1] } });
          }
          else if (x.type === 'select' && !x.multiple && x.guid) {
            or.push({ [y]: { type: 'guid', value: this.state.fields[i] } })
          }
          else {
            or.push({ [y]: this.state.fields[i] })
          }
        });
        filter.and.push({ or });
      }
    });
    return filter;
  };

  search = (e) => {
    if (e) e.stopPropagation();
    let _this = this;
    this.setState({ activeKey: [] });
    let filter = this.createFilter();
    this.props.updateFilter(this.state.fields, filter, this.state.key);
    setTimeout(() => _this.props.onFilter(filter), 200);
  };

  exportExcel = (e) => {
    e.stopPropagation();
  };

  filterChange = (value, key, search, item) => {
    let _this = this;
    let { fields } = this.state;
    fields[key] = value;
    this.setState({ fields }, () => {
      clearTimeout(this.callbackTimeout);
      if (item && item.callback) this.callbackTimeout = setTimeout(() => { item.callback(value, item.options && item.options.find(x => x.value === value)) }, (item && item.callbackTimeout) || 1000);
      setTimeout(() => {
        if (item && item.clear && item.clear.length) {
          item.clear.forEach(x => {
            fields[x] = undefined;
          });
          _this.setState({ fields }, () => {
            if (search) this.applyFilter(this.search);
          });
        }
        else {
          if (search) this.applyFilter(this.search);
        }
      }, (item && item.callbackTimeout) || 300);
    });
  };

  removeFilter = (e, tag) => {
    e.stopPropagation();
    let val = defaultValues[tag.filter.type];
    let key;
    if (tag.key.indexOf(':') !== -1 && tag.filter.multiple) {
      let keys = tag.key.split(':');
      let fields = window._.cloneDeep(this.state.fields);
      let type = typeof fields[keys[0]][0];
      fields[keys[0]].splice(fields[keys[0]].indexOf(type === 'number' ? parseInt(keys[1]) : keys[1]), 1);
      val = fields[keys[0]];
      key = keys[0];
    }
    this.filterChange(val, key ? key : tag.key, true, tag.filter);
  };

  clearFilter = async () => {
    let { fields } = this.state;
    this.props.filters.map((x, i) => fields[i] = x.allowClear === false ? fields[i] : x.multiple ? defaultValues.multiSelect : defaultValues[x.type]);
    this.props.filters.map((x, i) => x.type === 'number' && this.props.form.setFieldsValue({ [x.label]: defaultValues[x.type] ? defaultValues[x.type].toString() : '' }))
    this.setState({ fields }, () => this.search());
  };

  amountValidation = (value, callback, field) => {
    let message = "";
    let error = false;

    if (value[value.length - 1] === (".") || value[value.length - 1] === (",")) {
      error = true;
      message = i18n.t('msg.notEndDotOrComma');
    }
    else if ((value[0] === "-" || value[0] === "+") && value.length < 2) {
      error = true;
      message = i18n.t('msg.signErrorNull');
    }

    if (error) {
      callback(true)
      this.props.form.setFields({
        [field]: {
          errors: [new Error(message)],
        }
      })
    }
    else {
      callback();
    }
    this.setState({
      disabled: error,
    })
  }

  render() {
    let { filters, excelExport, needToConfirm } = this.props;
    let { activeKey, fields, disabled } = this.state;
    let tags = [];
    let { getFieldDecorator } = this.props.form;

    Object.keys(fields).filter(x => fields[x] !== null && fields[x] !== undefined && fields[x] !== '' && filters[x]).forEach(x => {
      let _filter = filters[x];
      if (_filter && _filter.multiple) {
        fields[x].forEach(y => {
          tags.push({
            key: x + ':' + y,
            clear: filters[x].allowClear,
            filter: _filter,
            value: _filter.type === 'date' ?
              formatDate(y, null, true, null, null, !!_filter.time) :
              _filter.type === 'select' ?
                _filter.lang ?
                  _filter.options && _filter.options.length > 0 ?
                    i18n.t(`${_filter.lang}.${_filter.options.find(z => z[_filter.value] === y)[_filter.name]}`)
                    : ''
                  :
                  typeof _filter.name === 'string' ?
                    _filter.options && _filter.options.length > 0 ?
                      _filter.options.find(z => z[_filter.value] === y)[_filter.name]
                      : ''
                    :
                    _filter.name(_filter.options && _filter.options.length > 0 ?
                      _filter.options.find(z => z[_filter.value] === y) : null)
                :
                _filter.lang ?
                  i18n.t(`${_filter.lang}.${y}`)
                  : y,
          });
        });
      }
      else {
        if (_filter.type !== 'slider' || (_filter.type === 'slider' && !(fields[x][0] === 0 && fields[x][1] === 100)))
          tags.push({
            key: x,
            filter: _filter,
            value: _filter.type === 'date' ?
              formatDate(fields[x], null, true, null, null, !!_filter.time) :
              _filter.type === 'slider' ?
                `${fields[x][0]} - ${fields[x][1]}`
                :
                _filter.type === 'select' ?
                  _filter.lang ?
                    _filter.options ?
                      i18n.t(`${_filter.lang}.${_filter.options.find(y => y[_filter.value] === fields[x])[_filter.name]}`)
                      :
                      ''
                    :
                    typeof _filter.name === 'string' ?
                      _filter.options && _filter.options.find(y => y[_filter.value] === fields[x]) ?
                        _filter.options.find(y => y[_filter.value] === fields[x])[_filter.name]
                        :
                        ''
                      :
                      _filter.name(_filter.options.find(y => y[_filter.value] === fields[x]))
                  :
                  _filter.lang ?
                    i18n.t(`${_filter.lang}.${fields[x]}`)
                    :
                    fields[x],
          });
      }
    });

    return (
      <FadeIn>
        <Card className="mb-50 filter-card table-card">
          <Collapse activeKey={activeKey} onChange={(e) => { this.setState({ activeKey: e }) }}>
            <Collapse.Panel key="1" header={
              <React.Fragment>
                <Row>
                  <Col xs={24} lg={13} className="pl-25">
                    {(tags.length === 0 || activeKey.length > 0) &&
                      <span className="filter">{i18n.t('lbl.filter')}</span>
                    }
                    {tags.length > 0 &&
                      <div className="filter-tags">
                        {activeKey.length === 0 &&
                          tags.map(tag => (
                            <Tag
                              onClick={(e) => e.stopPropagation()}
                              key={tag.key}
                              closable={tag.filter.allowClear !== false && !needToConfirm}
                              onClose={(e) => { tag.filter.allowClear !== false && this.applyFilter(this.removeFilter, e, tag) }}
                            >
                              {tag.filter.label} : {tag.value}
                            </Tag>
                          ))
                        }
                      </div>
                    }
                  </Col>
                  <Col xs={0} md={0} lg={11} className="text-right">
                    {Object.keys(fields).some(x => fields[x] !== null && fields[x] !== undefined && fields[x] !== '' && fields[x].length !== 0) &&
                      <Button className="mr-10 #clear btn-collapse" size="small" type="danger" onClick={(e) => this.applyFilter(this.clearFilter, e)}>
                        <i className="fas fa-backspace mr-10" /> {i18n.t('btn.clear')}
                      </Button>
                    }
                    <Button disabled={disabled} className="#list btn-success btn-collapse" size="small" type="default" onClick={(e) => this.applyFilter(this.search, e)}>
                      <i className="fas fa-search mr-10" /> {i18n.t('btn.list')}
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            }>
              <Form layout="vertical">
                <Row gutter={20}>
                  <Col xs={24} lg={0} className="text-center mb-15">
                    <Button className="#list mr-15 btn-collapse" type="primary" onClick={(e) => this.applyFilter(this.search, e)}>
                      <i className="fas fa-search mr-10" /> {i18n.t('btn.list')}
                    </Button>
                    {excelExport &&
                      <Button type="primary" className="btn-success #excelExport" onClick={(e) => { this.exportExcel(e) }}>
                        <Icon type="file-excel" />{i18n.t('btn.downloadExcel')}
                      </Button>
                    }
                  </Col>
                  {filters.map((item, i) => (
                    item &&
                    <Col key={i} xs={24} md={8} lg={item.col ? item.col.lg : 6}>
                      <Form.Item>
                        {item.type === 'select' &&
                          <Select
                            manuelSort={item.manuelSort}
                            mode={item.multiple ? "multiple" : ""}
                            className={item.uniqueKey}
                            value={fields[i]}
                            label={item.label}
                            showSearch={item.search}
                            allowClear={item.allowClear !== false}
                            onSearch={item.onSearch}
                            onFocus={item.onFocus || null}
                            options={item.options}
                            optVal={item.value}
                            searchFields={item.searchFields}
                            optName={item.lang ? (e) => i18n.t(`${item.lang}.${e[item.name]}`) : item.name}
                            onChange={(e) => this.filterChange(e, i, undefined, item)} />
                        }
                        {item.type === 'date' &&
                          <DatePicker
                            allowClear={item.allowClear !== false}
                            label={item.label}
                            value={item.value || fields[i]}
                            disabledDate={item.disabled && fields[item.disabled] ? (e) => e && ((item.date === 'ge' && e > moment(fields[item.disabled])) || (item.date === 'le' && e < moment(fields[item.disabled]))) : null}
                            onChange={(e) => this.filterChange(e, i, null, item)}
                            format={`DD.MM.YYYY${item.time ? ' HH:mm' : ''}`}
                            time={item.time}
                            className={`full-width ${item.uniqueKey}`}
                            mode={item.mode}
                            date={item.date}
                          />
                        }
                        {item.type === 'input' &&
                          <Input disabled={item.disabled} className={item.uniqueKey} label={item.label} value={fields[i]} onChange={(e) => item.changeable !== false && this.filterChange(e, i, null, item)} />
                        }
                        {item.type === 'number' &&
                          getFieldDecorator(item.label, {
                            rules: [
                              { validator: (rule, value, callback) => this.amountValidation(value, callback, item.label), message: "" },
                            ],
                            initialValue: fields[i] ? fields[i].toString() : ''
                          })(
                            <Input type="amount" className={item.uniqueKey} label={item.label} onChange={(e) => this.filterChange(e, i, null, item)} />
                          )}
                        {item.type === 'slider' &&
                          <>
                            <p className="mb-0">{item.label}</p>
                            <Slider value={fields[i]} onChange={(e) => this.filterChange(e, i, undefined, item)} range marks={{ 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }} />
                          </>
                        }
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Card>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ common }) => ({
  currentFilter: common.currentFilter,
  selectedItems: common.dataTableSelectedItems,
});
const mapDispatchToProps = (dispatch) => ({
  updateFilter: (fields, filter, key) => dispatch(actions.updateFilter({ fields, filter, key }))
});
const FilterForm = Form.create()(Filter);
export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);