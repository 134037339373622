import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Button, Popover, Icon } from 'antd';
import { formatCurrency, formatDate, formatIBAN } from 'helpers';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import _ from 'lodash';
import { DataTable, Filter, Description, ActionButtons, DropdownList, ConfirmPopup, SyncPopup, ActionModal, MailModal, Loading } from "components/UIComponents"
import endpoints from 'config/endpoints';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import i18n from 'plugins/i18n';
import { splitIcons, transferStatusIcons, defaultFilters, baseUrls } from 'lib/constants';
import utils from 'lib';
import Transaction from './Detail';

const DownloadMultiplePdf = lazy(() => import('./components/DownloadMultiplePdf'));
const SendMultiplePdfMail = lazy(() => import('./components/SendMultiplePdfMail'));


class Transactions extends Component {
  constructor(props) {
    super(props);
    const locationState = this.props.location.state;
    const getDynamicPropertiesOnGrid = JSON.parse(JSON.parse(props.systemParameters).GetDynamicPropertiesOnGrid);

    this.locationFilter = locationState
      ? { and: [{ bankEftCode: locationState.bankEftCode }, { BankAccountId: { type: 'guid', value: locationState.bankAccountId } }] }
      : null;
    props.history.replace('/netekstre/transactions', null);

    this.state = {
      value: 1,
      startApprove: false,
      startTransfer: false,
      multiplePdfDownload: false,
      sendMultiplePdfMail: false,
      sendMultipleEmail: false,
      modalVisible: false,
      currentItem: null,
      locationState,
      filter: defaultFilters.netekstre.Transactions(this.locationFilter, getDynamicPropertiesOnGrid),
      parameters: {},
      planCodes: [],
      accountCodes: [],
      currentBank: null
    };
    this.searchTimeout = null;
  };

  componentDidMount() {
    this.getTransactions();
    utils.common.getTenantBanks();
    utils.common.getBankAccounts();
    utils.common.getCurrencies();
    utils.netekstre.getLookupsTransferStatus();
    utils.netekstre.getVoucherTypeCodes();
    utils.netekstre.getTransactionCategories({ filter: { or: [{ ParentId: { ne: null } }, { CategoryType: 0 }] } });
    utils.netekstre.getTypeCodes(1);
    utils.netekstre.getTypeCodes(2);
    utils.common.checkAccessToUpdateOrDelete('BankAccountTransactions')

    const key = `netekstre/transactions`;

    const accountCodeFilter = this.props.currentFilter[key] && this.props.currentFilter[key].filter && this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.currentAccountCode)) ? this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.currentAccountCode)).or.find(x => x.currentAccountCode).currentAccountCode.contains : undefined;
    accountCodeFilter && this.accountCodeSearch(accountCodeFilter);

    const planCodeFilter = this.props.currentFilter[key] && this.props.currentFilter[key].filter && this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.accountPlanCode)) ? this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.accountPlanCode)).or.find(x => x.accountPlanCode).accountPlanCode.contains : undefined;
    planCodeFilter && this.planCodeSearch(planCodeFilter);
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  onStartApprove = (val) => {
    const filter = this.getCloneFilter();
    this.setState({ startApprove: val, filter }, this.getTransactions);
  };


  onStartSync = (val) => {
    const filter = this.getCloneFilter();
    this.setState({ startTransfer: val, filter }, this.getTransactions);
  };

  multiplePdfActionsCallback = (key, val) => {
    this.setState({ [key]: val });
  }

  getCloneFilter = () => {
    const { filter } = this.state;
    const clone = filter;

    if (clone && clone.filter && clone.filter.and && clone.filter.and.length > 0) {
      let filterArr = clone.filter.and;
      const newArr = [];
      for (let index = 0; index < filterArr.length; index++) {
        const element = filterArr[index];
        const json = JSON.stringify(element);
        if (json !== '{"transferStatusId":{"ne":8}}' && json !== '{"transferStatusId":{"ne":6}}' && json !== '{"transferStatusId":6}')
          newArr.push(element);
      }

      // filterArr = filterArr.filter(s => s !== { transferStatusId: 6 } && s !== { transferStatusId: { ne: 6 } });
      clone.filter.and = newArr.length > 0 ? newArr : null;
    }
    return clone;
  }

  getTransactions = () => {
    let _filter = this.props.currentFilter['netekstre/transactions'];

    let filter = _.cloneDeep(this.state.filter);
    if (this.state.startApprove) {
      filter.filter.and
        ? filter.filter.and.push({ transferStatusId: 6 })
        : filter.filter.and = [{ transferStatusId: 6 }];
    }
    if (this.state.startTransfer) {
      filter.filter.and
        ? filter.filter.and.push({ transferStatusId: { ne: 6 } })
        : filter.filter.and = [{ transferStatusId: { ne: 6 } }];
    }
    if (!(_filter && _filter.fields && _filter.fields[2] && _filter.fields[2].length)) {
      filter.filter.and
        ? filter.filter.and.push({ transferStatusId: { ne: 8 } })
        : filter.filter.and = [{ transferStatusId: { ne: 8 } }]
    }
    if (!(_filter && _filter.fields && _filter.fields[0] && _filter.fields[0].length)) {
      const oneWeekBefore = moment().add(-7, "day").startOf("day");
      this.filterRef.filterChange(oneWeekBefore.format('YYYY-MM-DDT00:00:00Z'), 0);
      filter.filter.and
        ? filter.filter.and.push({ transactionDate: { ge: oneWeekBefore._d } })
        : filter.filter.and = [{ transactionDate: { ge: oneWeekBefore._d } }]
    } //TODO: default filter

    const clearFilter = this.changeFilterToUnique(filter);
    this.setState({ filter: clearFilter }, () => {
      this.props.getTransactions(clearFilter);
    })

  };

  changeFilterToUnique = (filter) => {
    const cloneFilter = filter;

    if (cloneFilter && cloneFilter.filter && cloneFilter.filter.and && cloneFilter.filter.and.length > 0) {
      const andArr = [];
      cloneFilter.filter.and.forEach((item) => {
        if (!_.some(andArr, item)) andArr.push(item)
      })
      cloneFilter.filter.and = andArr;
    }
    return cloneFilter;
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getTransactions());
  };

  onSort = (key, type) => {
    this.setState({
      filter: { ...this.state.filter, orderBy: [`${key} ${type}`] }
    }, this.getTransactions);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getTransactions);
  }

  planCodeSearch = (e) => {
    this.props.getPlanCodes(e, (response) => {
      this.setState({ planCodes: response })
    });
  };

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  planCodeClean = () => {
    this.props.getPlanCodes('');
  };

  accountCodeClean = () => {
    this.props.getAccountCodes('');
  };

  onSelect = (value) => {
    this.setState({ currentBank: value });
  };

  onCallback = (e, type) => {
    if (!e) this.setState({ [type]: [] });
  };

  trigger = (currentItem) => {
    const { selectedItems } = this.props;
    const { data } = this.props.transactions.list;
    this.modal.open(
      selectedItems && selectedItems.length
        ? selectedItems.indexOf(currentItem)
        : data.indexOf(data.find(x => x.Id === currentItem)),
      selectedItems && selectedItems.length
        ? data.filter(x => selectedItems.indexOf(x.Id) > -1)
        : null);
  };

  getSplits = (row) => {
    return (
      <Tooltip placement="bottom" title={i18n.t(`status.${row.SplitStatus}`)}>
        <span
          onClick={
            (e) => {
              e.stopPropagation();
              row.SplitStatus === "Child"
                ? this.props.history.push(`/netekstre/transaction/split/${row.ParentTransactionId}`)
                : this.props.history.push(`/netekstre/transaction/split/${row.Id}`)
            }
          }
        >
          {splitIcons[row.SplitStatus]}
        </span>
      </Tooltip>
    )
  }

  customButtons = ({ selectedItems, selectAll, disabled, dataCount }) => {
    const { startApprove, startTransfer, multiplePdfDownload, sendMultiplePdfMail, filter } = this.state;
    const { accessRights } = this.props
    return (
      <React.Fragment>
        {(startApprove && accessRights && accessRights.find(x => x.endPoint === 'ApproveTransactionBulk' && x.method === 'PUT')) &&
          <ConfirmPopup disabled={disabled} selectedItems={[...selectedItems]} selectAll={selectAll} filter={filter.filter} onStartApprove={this.onStartApprove} />
        }
        {(startTransfer && accessRights && accessRights.find(x => x.endPoint === 'SendManuelTransactions' && x.method === 'PUT')) &&
          <SyncPopup disabled={disabled} selectedItems={[...selectedItems]} selectAll={selectAll} filter={filter.filter} onStartSync={this.onStartSync} />
        }
        {(multiplePdfDownload) &&
          <Suspense fallback={<Loading size="sm" />}>
            <DownloadMultiplePdf {...{ disabled, selectAll, selectedItems, dataCount, filter: filter.filter, callback: this.multiplePdfActionsCallback }} />
          </Suspense>
        }
        {(sendMultiplePdfMail) &&
          <Suspense fallback={<Loading size="sm" />}>
            <SendMultiplePdfMail {...{ disabled, selectAll, selectedItems, dataCount, filter: filter.filter, callback: this.multiplePdfActionsCallback }} />
          </Suspense>
        }
      </React.Fragment>
    )
  }

  renderAdditionalButtons = () => {
    const { multiplePdfDownload, sendMultiplePdfMail } = this.state;

    const toggleAction = (key) => {
      this.setState({ [key]: !this.state[key] },() => {
        if (this.state[key] === false) utils.common.clearSelectedItems();
      })
    }

    return [
      {
        disabled: sendMultiplePdfMail,
        render: () =>
          <div onClick={() => !sendMultiplePdfMail && toggleAction('multiplePdfDownload')}>
            <Icon type={multiplePdfDownload ? 'close' : 'download'} className="mr-5" />
            {i18n.t(`lbl.${multiplePdfDownload ? 'cancelDownload' : 'multiplePdfDownload'}`)}
          </div>,
      },
      {
        disabled: multiplePdfDownload,
        render: () =>
          <div onClick={() => !multiplePdfDownload && toggleAction('sendMultiplePdfMail')}>
            <Icon type={sendMultiplePdfMail ? 'close' : 'mail'} className="mr-5" />
            {i18n.t(`lbl.${sendMultiplePdfMail ? 'cancelSend' : 'multiplePdfMailSend'}`)}
          </div>
      }
    ];
  }


  downloadPDF = (row) => {
    this.props.downloadPDF(row.Id, (response) => {
      const token = localStorage.getItem('token');
      let info = localStorage.getItem('userInfo');
      info = info ? JSON.parse(CryptoJS.AES.decrypt(info, "netfinans").toString(CryptoJS.enc.Utf8)) : {};
      if (response) window.open(`${response.value}&t=${token}&uid=${info.userId}&tid=${info.tenantId}`, '_blank');
    });
  };

  renderActionButtons = (row, index) => { // Burası datatable içerisindeki pdf mail ve güncelle butonlarını oluşturuyor.
    const { selectedItems } = this.props
    return (
      <Popover overlayClassName="ant-popover-action" placement="right" trigger="hover" content={
        <>
          <ActionButtons
            size="default"
            url="BankAccountTransactions"
            mode={this.state.startApprove ? 'approve' : this.state.startTransfer ? 'transfer' : null}
            trigger={this.trigger}
            editUrl
            newODataStructure
            item={row}
            index={index}
            disabled={((this.state.startApprove || this.state.startTransfer) && selectedItems.length && selectedItems.indexOf(row.Id) < 0) ? true : false}
          />
          <Tooltip placement="bottom" title={i18n.t('lbl.sendMail')}>
            <Button size="default" icon="mail" onClick={(e) => {
              e.stopPropagation();
              this.mailModal.open(row.Id)
            }} />
          </Tooltip>
          <Tooltip placement="bottom" title={i18n.t('lbl.downloadAsPDF')}>
            <Button size="default" icon="file-pdf" onClick={(e) => {
              e.stopPropagation();
              this.downloadPDF(row)
            }} />
          </Tooltip>
        </>
      }>
        <Button onClick={e => e.stopPropagation()} size="small" className="table-button"><i style={{ fontSize: 16 }} className="fas fa-angle-double-right"></i></Button>
      </Popover>
    );
  };

  render() {
    const { currentBank, startApprove, startTransfer, locationState, planCodes, filter, parameters, accountCodes, multiplePdfDownload, sendMultiplePdfMail } = this.state;
    const { transactions, tenantBanks, bankAccounts, voucherTypes, transferStatus, categories, accessRights, type1, type2, currencies, systemParameters } = this.props;
    const _accounts = bankAccounts.data ? (currentBank && currentBank.length) ? bankAccounts.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : bankAccounts.data : [];
    let _type1 = type1 && type1.data ? (currentBank && currentBank.length) ? type1.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type1.data : [];
    let _type2 = type2 && type2.data ? (currentBank && currentBank.length) ? type2.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type2.data : [];

    _type1 = _.uniqBy(_type1, 'code');
    _type2 = _.uniqBy(_type2, 'code');

    const filters = [
      { label: i18n.t('lbl.startDate'), type: 'date', date: 'ge', keys: ['transactionDate'], uniqueKey: '#startDate', disabled: '1', time: true, allowClear: false },
      { label: i18n.t('lbl.endDate'), type: 'date', date: 'le', keys: ['transactionDate'], uniqueKey: '#endDate', disabled: '0', time: true, },
      { label: i18n.t('lbl.integrationStatus'), options: transferStatus.data, type: 'select', value: 'id', name: 'name', lang: 'status', keys: ['transferStatusId'], showAll: true, toggle: false, uniqueKey: '#transferStatus', multiple: true },
      { label: i18n.t('lbl.voucherType'), options: voucherTypes.data, type: 'select', value: 'id', name: 'name', keys: ['voucherTypeId'], showAll: true, uniqueKey: '#voucherType', multiple: true, guid: true },
      { label: i18n.t('lbl.bank'), options: tenantBanks.data, type: 'select', value: 'bankEftCode', defaultValue: locationState ? [locationState.bankEftCode] : null, name: (bank) => bank ? <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment> : '', keys: ['bankEftCode'], showAll: true, callback: this.onSelect, clear: ['5', '6', '7'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.accountName'), search: true, type: 'select', options: _accounts, showSearch: true, value: 'id', defaultValue: locationState ? [locationState.bankAccountId] : null, name: 'name', keys: ['bankAccountId'], uniqueKey: '#accountName', multiple: true, guid: true },
      { label: i18n.t('lbl.accountPlanCode'), options: planCodes, type: 'select', contains: true, search: true, onSearch: this.planCodeSearch, onFocus: this.planCodeClean, value: 'Code', name: (e) => `${e ? e.Code + ' ' + e.Name || e.Title : ''}`, keys: ['MuhPlanCode'], callback: (e) => this.onCallback(e, 'planCodes'), uniqueKey: '#accountPlanCode' },
      { label: i18n.t('lbl.typeOne'), options: _type1, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['typeCode1'], uniqueKey: '#typeOne', multiple: true },
      { label: i18n.t('lbl.typeTwo'), options: _type2, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['typeCode2'], uniqueKey: '#typeTwo', multiple: true },
      { label: i18n.t('lbl.currencyCode'), options: currencies.data, type: 'select', showAll: true, value: 'currencyCode', name: 'currencyName', keys: ['currencyCode'], uniqueKey: '#currencyCode', multiple: true },
      { label: i18n.t('lbl.currentAccount'), options: accountCodes, type: 'select', contains: true, search: true, onSearch: this.accountCodeSearch, onFocus: this.accountCodeClean, value: 'Code', name: (e) => `${e ? e.Code + ' ' + e.Title || e.Name : ''}`, keys: ['CurrentAccount/Code'], callback: (e) => this.onCallback(e, 'accountCodes'), uniqueKey: '#currentAccountCode' },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['description1', 'taxNumber', 'tcknNumber', 'iban', 'documentId', 'contactName', 'BankAccount/AccountNumber'], contains: true, uniqueKey: '#searchInput' },
      { label: i18n.t('lbl.category'), options: categories.data, type: 'select', showAll: true, value: 'Id', name: (e) => `${e ? e.Name : ''}`, keys: ['CategoryId'], uniqueKey: '#category', multiple: true, guid: true },
      { label: i18n.t('lbl.minAmount'), type: 'number', operator: 'ge', keys: ['amount'], uniqueKey: '#minAmount', col: { lg: 3 } },
      { label: i18n.t('lbl.maxAmount'), type: 'number', operator: 'le', keys: ['amount'], uniqueKey: '#maxAmount', col: { lg: 3 } },
    ];
    const columns = [
      { render: this.renderActionButtons, tdClass: "pr-5", toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: '', key: 'SplitStatus', render: this.getSplits, tdClass: 'text-center pl-0 pr-0', toggle: false, notIncluded: true },
      { label: '', key: 'TransferStatusName', render: (row) => <Tooltip placement="bottom" title={i18n.t(`status.${row.TransferStatusName}`)}>{transferStatusIcons[row.TransferStatusName]}</Tooltip>, tdClass: 'text-center pl-0 pr-5', toggle: false, notIncluded: true },
      { label: i18n.t('lbl.bank'), key: 'BankAccount/TenantBank/Bank/Name', checkField: false, render: (data) => utils.common.getBankName({ data, dataField: 'BankEftCode', listKeys: 'tenantBanks', listField: 'bankEftCode' }) },
      { label: i18n.t('lbl.transactionDate'), key: 'TransactionDate', render: ({ TransactionDate }) => formatDate(TransactionDate) },
      { label: i18n.t('lbl.description'), key: 'Description1', render: ({ Description1 }) => <Description text={Description1} /> },
      { label: i18n.t('lbl.amount'), key: 'Amount', render: (row) => formatCurrency({ data: row.Amount, currencyCode: row.CurrencyCode, withColor: true }), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.bankAccountBalance'), key: 'BankAccountBalance', render: (row) => formatCurrency({ data: row.BankAccountBalance, currencyCode: row.CurrencyCode }), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.accountName'), key: 'BankAccount/Name', checkField: false },
      { label: i18n.t('lbl.accountNumber'), key: 'BankAccount/AccountNumber', checkField: false },
      { label: i18n.t('lbl.suffix'), key: 'BankAccount/Suffix', checkField: false },
      { label: i18n.t('lbl.contactName'), key: 'ContactName' },
      { label: i18n.t('lbl.typeOne'), key: 'TypeCode1' },
      { label: i18n.t('lbl.typeTwo'), key: 'TypeCode2' },
      { label: i18n.t('lbl.documentNumber'), key: 'DocumentId' },
      { label: i18n.t('lbl.tc'), key: 'TcknNumber' },
      { label: i18n.t('lbl.taxNumber'), key: 'TaxNumber' },
      { label: i18n.t('lbl.iban'), key: 'Iban', render: (row) => formatIBAN(row.Iban) },
      { label: i18n.t('lbl.currentAccountCode'), key: 'CurrentAccount/Code', checkField: false },
      { label: i18n.t('lbl.currentAccountName'), key: 'CurrentAccount/Name', checkField: false },
      { label: i18n.t('lbl.bankAccountCode'), key: 'BankAccountCode' },
      { label: i18n.t('lbl.accountPlanCode'), key: 'MuhPlanCode' },
      { label: i18n.t('lbl.voucherType'), key: 'VoucherType/Name', checkField: false },
      { label: i18n.t('lbl.erpVoucherCode'), key: 'ErpCode' },
      { label: i18n.t('lbl.category'), key: 'Category/Name', checkField: false },
    ];
    let arr = [];
    if (transactions.list.data && transactions.list.data.length) transactions.list.data.map(x => x.TransactionDynamicProperties && arr.push(...x.TransactionDynamicProperties));

    arr = window._.sortBy(window._.uniqBy(arr, 'DynamicPropertyId'), 'Name');
    arr.forEach(x => {
      columns.push({
        checkField: false,
        label: x.Name, key: x.Name, render: (row) => {
          const dynamicProperty = row.TransactionDynamicProperties.find(y => y.DynamicPropertyId === x.DynamicPropertyId)
          return (
            x.PropertyConfig.OperationType === 2 ?
              <DropdownList
                data={dynamicProperty
                  ? dynamicProperty.PropertyConfig.Pattern.split(';').map(y => { return { Name: y } })
                  : []}
                field="Name"
                value={dynamicProperty && dynamicProperty.Value}
              />
              :
              dynamicProperty ? dynamicProperty.Value : '-'

          )
        }
      })
    });

    return (
      <div className="page-content">
        <Filter filters={filters} onFilter={this.onFilter} hasCurrent={this.locationFilter} wrappedComponentRef={el => this.filterRef = el} needToConfirm={startTransfer} datatable={this.datatable} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          count={transactions.list.count}
          defaultSort="transactionDate"
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={transactions.list.data}
          loading={transactions.list.loading}
          excel={{ url: "ExportBankAccountTransactions", filter: { filter: filter.filter, expand: filter.expand, orderBy: filter.orderBy }, parameters }}
          refresh
          title={i18n.t('route.netekstre.transactions')}
          fromExcel={JSON.parse(JSON.parse(systemParameters).UpdateTransferStatusAsManuel) && { url: "ImportsBankAccountTransactions", baseUrl: baseUrls.nte }}
          approveStarted={startApprove}
          transferStarted={startTransfer}
          startApprove={this.onStartApprove}
          startTransfer={this.onStartSync}
          CustomButton={this.customButtons}
          additionalButtons={this.renderAdditionalButtons()}
          checkbox={((startApprove || startTransfer) && accessRights && accessRights.find(x => (x.endPoint === 'ApproveTransactionBulk' || x.endPoint === 'SendManuelTransactions') && x.method === 'PUT')) || (multiplePdfDownload || sendMultiplePdfMail)}
          checkAll
          getData={this.getTransactions}
          Component={Transaction}
          dialogTitle={i18n.t('lbl.transaction')}
          deleteOptions={{ url: "Transactions", stateKey: "transactions" }}
        />
        <ActionModal
          datatable={this.datatable}
          getDatas={this.getTransactions}
          ref={el => this.modal = el}
          filter={this.state.filter}
          mode={startApprove ? 'approve' : startTransfer ? 'transfer' : null}
        />
        <MailModal wrappedComponentRef={el => this.mailModal = el} />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  accessRights: auth.data.accessRights,
  systemParameters: auth.data.systemParameters,

  selectedItems: common.dataTableSelectedItems,
  currentFilter: common.currentFilter,
  currencies: common.filter.currencies,
  tenantBanks: common.filter.tenantBanks,
  bankAccounts: common.filter.bankAccounts,

  transactions: netekstre.transactions,
  voucherTypes: netekstre.filter.voucherTypes,
  transferStatus: netekstre.filter.transferStatus,
  categories: netekstre.filter.transactionCategories,
  type1: netekstre.filter.typeCodes1,
  type2: netekstre.filter.typeCodes2,
});
const mapDispatchToProps = (dispatch) => ({
  getTransactions: (filter) => dispatch(netekstreActions.getAll({ filter, url: endpoints.nte.bankAccountTransactions, key: 'transactions', isNewODataStructure: true })),
  downloadPDF: (id, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.exportBankAccountTransactionNotifications, id }, callback)),
  getPlanCodes: (data, callback) => dispatch(commonActions.getPlanCodes(data, callback)),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
