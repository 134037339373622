import React, { Component } from 'react';
import { connect } from 'react-redux';
import posraporActions from 'store/actions/posrapor';
import commonActions from 'store/actions/common';
import { DataTable, Filter, Parameter } from 'components/UIComponents';
import { reportTypesPosrapor, reportDisplayTypesPosrapor } from 'lib/constants';
import { formatCurrency } from 'helpers';
import moment from 'moment';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import defaultFilters from 'lib/constants/defaultFilters';
import utils from 'lib';


class SummaryReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: defaultFilters.posrapor.SummaryReport(),
            parameters: {
                reportType: '1',
                displayType: '1',
                startDate: moment().startOf('date').add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]'),
                endDate: moment().endOf('date').add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.999[Z]'),
            },
            dateMode: 'date',
            currentBank: null,
            currentTenant: null,
        };
    };

    componentDidMount() {
        this.getPosraporReport();
        utils.posrapor.getBanks();
        utils.posrapor.getCurrencies();
    };

    getPosraporReport = (e) => {
        let { parameters, dateMode } = this.state;
        let filter = _.cloneDeep(this.state.filter);
        let filters = this.props.currentFilter['posrapor/reporting/summary-report'];
        if (!(filters && filters.fields && filters.fields[1])) {
            filter.filter.and ? filter.filter.and.push({ ValorDate: { ge: moment().startOf(dateMode)._d } }) : filter.filter.and = [{ ValorDate: { ge: moment().startOf(dateMode)._d } }];
        }
        if (!(filters && filters.fields && filters.fields[2])) {
            filter.filter.and ? filter.filter.and.push({ ValorDate: { le: moment().endOf(dateMode)._d } }) : filter.filter.and = [{ ValorDate: { le: moment().endOf(dateMode)._d } }];
        }

        parameters.startDate = moment(filters && filters.fields && filters.fields[1]).startOf(dateMode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]');
        parameters.endDate = moment(filters && filters.fields && filters.fields[2]).endOf(dateMode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.999[Z]');
        this.props.getPosraporReport(filter, parameters);
    };

    onFilter = (newFilter) => {
        let { filter } = this.state;
        filter.filter = { ...filter.filter, ...newFilter };
        filter.skip = 0;
        this.datatable.pageUpdate(1);
        this.setState({ filter }, () => {
            this.getPosraporReport();
        });
    };

    onSort = (key, type) => {
        let { filter } = this.state;
        this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
            this.getPosraporReport();
        });
    };

    onSelect = (value) => {
        let { filter } = this.state;
        filter.filter = !value ? {} : filter.filter;
        this.setState({ currentBank: value, filter });
    };

    onTenantSelect = (value) => {
        let { filter } = this.state;
        filter.filter = !value ? {} : filter.filter;
        this.setState({ currentTenant: value, filter });
    };

    onPageChange = (skip, top) => {
        let { filter } = this.state;
        filter.skip = skip;
        filter.top = top;
        this.setState({
            filter
        }, () => this.getPosraporReport());
    }

    onParameterChange = (key, value) => {
        let { parameters, filter } = this.state;
        parameters[key] = value;
        this.setState({ parameters }, () => this.getPosraporReport(filter))
    }

    onDateModeChange = (item) => {
        let filter = _.cloneDeep(this.state.filter);
        let parameters = _.cloneDeep(this.state.parameters)
        let filters = this.props.currentFilter['posrapor/reporting/summary-report'];
        if (filter.filter.and) {
            filter.filter.and.find(x => x.or && x.or.length > 0 && x.or.find(y => y.ValorDate && y.ValorDate.ge ? y.ValorDate.ge = moment(filters.fields[1]).startOf(item.mode)._d : null));
            filter.filter.and.find(x => x.or && x.or.length > 0 && x.or.find(y => y.ValorDate && y.ValorDate.le ? y.ValorDate.le = moment(filters.fields[2]).endOf(item.mode)._d : null));
            parameters.startDate = moment(filters.fields[2]).startOf(item.mode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]');
            parameters.endDate = moment(filters.fields[2]).endOf(item.mode).add('hour', -3).format('YYYY-MM-DD[T]HH:mm:ss.999[Z]');
        }
        let startDate = moment(filters ? filters.fields[1] : undefined).startOf(item.mode);
        let endDate = moment(filters ? filters.fields[2] : undefined).endOf(item.mode);
        this.filterRef.filterChange(startDate.format('YYYY-MM-DDT00:00:00Z'), 1);
        this.filterRef.filterChange(endDate.format('YYYY-MM-DDT00:00:00Z'), 2);
        parameters.reportType = item.value;
        this.setState({ dateMode: item && item.mode, filter, parameters, startDate: startDate._d, endDate: endDate._d }, () => this.getPosraporReport(filter))
    }

    render() {
        let { report, tenantBanks, currentFilter } = this.props;
        let { dateMode, startDate, endDate, parameters } = this.state;
        let _filter = currentFilter['posrapor/reporting/summary-report'];
        let defaultStartDate = (_filter && _filter.fields && _filter.fields[1]) || new Date().toISOString();
        let defaultEndDate = (_filter && _filter.fields && _filter.fields[2]) || new Date().toISOString();

        let filter = _.cloneDeep(this.state.filter);
        let _filters = currentFilter['posrapor/reporting/summary-report'];
        if (!(_filters && _filters.fields && _filters.fields[1])) {
            filter.filter.and ? filter.filter.and.push({ ValorDate: { ge: moment().startOf(dateMode)._d } }) : filter.filter.and = [{ ValorDate: { ge: moment().startOf(dateMode)._d } }];
        }
        if (!(_filters && _filters.fields && _filters.fields[2])) {
            filter.filter.and ? filter.filter.and.push({ ValorDate: { le: moment().endOf(dateMode)._d } }) : filter.filter.and = [{ ValorDate: { le: moment().endOf(dateMode)._d } }];
        }

        let filters = [
            { label: i18n.t('lbl.bankName'), type: 'select', options: tenantBanks.data, value: 'eftCode', name: (bankData) => utils.posrapor.getBankName({ bankData }), keys: ['BankDailyReportLine/Pos/PosRaporAccount/Bank/EftCode'], showAll: true, uniqueKey: '#bank', multiple: true },
            { label: i18n.t('lbl.startDate'), type: 'date', mode: dateMode, date: 'ge', keys: ['ValorDate'], uniqueKey: '#startDate', disabled: '2', defaultValue: defaultStartDate, allowClear: false, value: startDate, callback: () => this.setState({ startDate: undefined }), callbackTimeout: 1, },
            { label: i18n.t('lbl.endDate'), type: 'date', mode: dateMode, date: 'le', keys: ['ValorDate'], uniqueKey: '#endDate', disabled: '1', defaultValue: defaultEndDate, allowClear: false, value: endDate, callback: () => this.setState({ endDate: undefined }), callbackTimeout: 1, },

        ];
        let _parameters = [
            { type: 'radio', key: "reportType", uniqueKey: '#reportType', options: reportTypesPosrapor, defaultValue: '1', callback: (value, item) => this.onDateModeChange(item), callbackTimeout: 10, },
            { type: 'radio', key: "displayType", uniqueKey: '#displayType', options: reportDisplayTypesPosrapor, defaultValue: '1' },
        ]

        let columns = [
            { label: i18n.t('lbl.name'), key: 'EftCode', render: data => utils.posrapor.getBankName({ data, dataField: "EftCode", listField: "eftCode" }) },
            { label: i18n.t('lbl.name'), key: 'Pos' },
            { label: i18n.t('lbl.paymentCount'), key: 'PaymentCount' },
            { label: i18n.t('lbl.paymentAmount'), key: 'PaymentAmount', render: ({ PaymentAmount }) => formatCurrency({ data: PaymentAmount, currencyCode: ' ', withSign: false }) },
            { label: i18n.t('lbl.currencyCode'), key: 'CurrencyType' },
            { label: i18n.t('lbl.date'), key: 'DisplayDate' },
        ];

        return (
            <div className="page-content">
                <Filter filters={filters} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
                <Parameter parameters={_parameters} onChange={this.onParameterChange} />
                <DataTable
                    wrappedComponentRef={el => this.datatable = el}
                    onPageChange={this.onPageChange}
                    onSort={this.onSort}
                    columns={columns}
                    data={report.list.data}
                    loading={report.list.loading}
                    excel={{ url: "ExportBankDailyReportLineReport", filter, parameters, module: "posrapor" }}
                    title={i18n.t('route.posrapor.reporting')}
                    history={this.props.history}
                    access="BankDailyReportPos"
                    count={report.list.count}
                />
            </div>
        );
    };
};

const mapStateToProps = ({ posrapor, auth, common }) => ({
    report: posrapor.report,
    tenantBanks: posrapor.filter.banks,
    currentFilter: common.currentFilter
});
const mapDispatchToProps = (dispatch) => ({
    getPosraporReport: (filter, parameters) => dispatch(posraporActions.getAll({ filter, url: endpoints.posrapor.report, key: 'report', parameters })),
    updateFilter: (fields, filter, key) => dispatch(commonActions.updateFilter({ fields, filter, key })),
});
export default connect(mapStateToProps, mapDispatchToProps)(SummaryReport);