import { call, put } from 'redux-saga/effects';
import { AuthProxy } from 'proxies';
import actions from 'store/actions/auth';
import { notification, Modal } from 'antd';
import axios from 'axios';
import i18n from 'plugins/i18n';
import CryptoJS from 'crypto-js';

function* sleep(time) {
  yield new Promise(resolve => setTimeout(resolve, time));
}

export function* signIn({ payload, callback }) {
  let response;
  try {
    response = yield call(new AuthProxy().signIn, payload);
    if (response.statusCode === 200 && response.data.token) {
      localStorage.setItem('token', response.data.token);
      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    }
    yield put(actions.signInSuccess(response));
    yield call(sleep, 1000);
  }
  catch (error) {
    yield put(actions.signInFailure({ error, isLoggedIn: false }));
    yield call(sleep, 2000);
    yield put(actions.signInFailure({ error, isLoggedIn: null }));
    if (error.statusCode === 403 && error.messages.length && error.messages[0].code === "PasswordExpired")
      response = { data: { resetPasswordGuid: error.messages[0].data } }
  }
  callback && callback(response && response.data);
};

export function* verifyTwoFactor({ payload, callback }) {
  let response;
  try {
    response = yield call(new AuthProxy().verifyTwoFactor, payload);
    if (response.statusCode === 200 && response.data.token) {
      localStorage.setItem('token', response.data.token);
      axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    }
    yield put(actions.verifyTwoFactorSuccess(response));
    yield call(sleep, 1000);
  }
  catch (error) {
    yield put(actions.verifyTwoFactorFailure({ error, isLoggedIn: false }));
    yield call(sleep, 2000);
    yield put(actions.verifyTwoFactorFailure({ error, isLoggedIn: null }));
    if (error.statusCode === 400 && error.messages.length && error.messages[0].code === "MaximumOtpTryOverflow")
      response = { data: { redirect: true } }
  }
  callback && callback(response && response.data);
};

export function* getAccount(action) {
  let response = {};
    response = yield call(new AuthProxy().getAccount);
    if (response.data && response.data.ok) {
      const info = { userId: response.data.userId, tenantId: response.data.tenantId };
      localStorage.setItem("userInfo", CryptoJS.AES.encrypt(JSON.stringify(info), "netfinans"));
      yield put(actions.getAccountSuccess(response.data));
      action.callback && action.callback(response.data);
    }
    else {
      yield put(actions.getAccountFailure({ error: response.message }));
      localStorage.clear();
      window.location.pathname = '/auth';
    }
};

export function* logout(action) {
  let token = localStorage.getItem('token');
  let response;
  if (token) {
    try {
      response = yield call(new AuthProxy().logout, action.payload);
      // if ((response && response.statusCode === 200) || (response && response.error === "")) {
      localStorage.clear();
      yield call(sleep, 1500);
      yield put(actions.logoutSuccess({ logoutStatus: response.statusCode || response }));
      window.location.pathname = '/auth';
      // }
    }
    catch (error) {
      yield put(actions.logoutFailure({ error, isLoggedIn: false }));
      // notification.error({ message: (response.error && response.error.message) || (response.data && response.data.exceptions && response.data.exceptions[0]) || i18n.t('msg.unknownError') });
    }
  }
  else {
    localStorage.clear();
    notification.error({ message: i18n.t('msg.loggedOut') });
    setTimeout(() => {
      window.location.pathname = '/auth';
    }, 4000);
  }
};

export function* forgotPassword({ payload, callback }) {
  try {
    yield call(new AuthProxy().forgotPassword, payload);
    Modal.success({
      title: i18n.t("msg.success"),
      content: i18n.t('msg.isExistSuccessfullySent'),
      centered: true,
      okText: i18n.t('btn.ok'),
      onOk: callback
    })
  }
  catch (e) { }
}

export function* resetPassword({ payload, callback }) {
  yield call(sleep, 1000);
  let response;
  try {
    response = yield call(new AuthProxy().resetPassword, payload);
    yield put(actions.resetPasswordSuccess(response));
  }
  catch (error) {
    yield put(actions.resetPasswordFailure({ error }));
  }
  callback(response);
}

export function* doBlockedUserToActive({ payload }) {
  yield call(sleep, 1000);
  try {
    const response = yield call(new AuthProxy().doBlockedUserToActive, payload);
    yield put(actions.doBlockedUserToActiveSuccess(response));
  }
  catch (error) {
    yield put(actions.doBlockedUserToActiveFailure({ error }));
  }
}

export function* resendOtp({ payload, callback }) {
  yield call(sleep, 500);
  try {
    const response = yield call(new AuthProxy().resendOtp, payload);
    yield put(actions.resendOtpSuccess(response));
    callback();
  }
  catch (error) {
    yield put(actions.resendOtpFailure({ error }));
  }
}