import React, { Component } from 'react';
import i18n from 'plugins/i18n';
import { Modal, Button } from 'antd';
import ReactSVG from 'react-svg';
import icons from 'assets/img/icons';
import { connect } from 'react-redux';

class HomeModal extends Component {
  state = { visible: false }

  render() {
    const { visible } = this.state;
    const { module, available } = this.props;
    return (
      <div>
        <Modal {...{
          footer: null,
          visible,
          onCancel: () => this.setState({ visible: false }),
          centered: true,
          width: 644,
          destroyOnClose: true,
          closeIcon: <ReactSVG src={icons.cancel} />,
          title: i18n.t('lbl.willBeAvailableSoonHeader', { module: module.name }),
          className: 'home-modal'
        }}
        >
          <p dangerouslySetInnerHTML={{ __html: i18n.t(`descriptions.willBeAvailableSoonModal.${module.path}`) }} />
          {
            available && module.url &&
            <Button className="btn-click-buy mt-20" onClick={() => window.open(module.url)}>
              {i18n.t('btn.clickToBuy')} <ReactSVG src={icons.rightArrow} className="svg ml-20" />
            </Button>
          }
        </Modal>
        <div className="pointer" onClick={() => this.setState({ visible: true })}>

          {
            available ?
              <p>{i18n.t('lbl.clickForProductInformation')}</p>
              :
              <>
                <p>{i18n.t('lbl.moduleInactive', { module: module.name })}</p>
                <p>{i18n.t('lbl.willBeAvailableSoon')}</p>
              </>
          }
        </div>
      </div >
    );
  };
};

export default connect((common) => common)(HomeModal);