import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from 'antd';
import actions from 'store/redux/common';


class ImgUpload extends Component {
  state = { src: null, crop: { unit: "%", width: 30, aspect: 1 / 1 }, croppedImageUrl: null };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          // console.error("Canvas is empty");
          return;
        }
        blob.name = 'asd.jpg';
        window.URL.revokeObjectURL(this.fileUrl);
        this.blob = canvas.toDataURL();
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onSubmit = () => {
    this.props.upload(this.blob, (response) => {
      this.props.onChange && this.props.onChange(response.value);
      this.setState({ src: null });
    });
  };

  render() {
    const { src, crop } = this.state;
    const { label, value, className } = this.props;
    return (
      <>
        <h4>{label}</h4>
        <div className={`image-upload ${className ? className : ''}`}>
          <i className="fas fa-camera" onClick={() => this.input.click()} />
          <input type="file" accept="image/x-png,image/jpeg" onChange={this.onSelectFile} ref={el => this.input = el} />
          <div className="image-overlay" style={{ backgroundImage: `url(${value})` }} />
          <Modal
            centered
            visible={!!src}
            onOk={this.onSubmit}
            onCancel={() => this.setState({ src: null })}
            okButtonProps={{ className: '#save', icon: 'save' }}
            cancelButtonProps={{ className: '#cancel', icon: 'close' }}
          >
            {src &&
              <ReactCrop
                src={src}
                crop={crop}
                onComplete={this.onCropComplete}
                onImageLoaded={this.onImageLoaded}
                onChange={this.onCropChange}
              />
            }
          </Modal>
        </div>
      </>
    );
  };
};

const mapDispatchToProps = (dispatch) => ({
  upload: (data, callback) => dispatch(actions.uploadImg(data, callback))
});
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ImgUpload);