import { CommRateModel } from "models/netahsilat";

export const commRatesLayoutData = () => {
  let layoutData = [];

  for (let i = 1; i < 13; i++) {
    layoutData.push(new CommRateModel({
      installment: i
    }))

  }
  return layoutData;
}