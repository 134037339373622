export default {
  path: '/register',
  name: 'register',
  component: require('components/Register/Layout').default,
  routes: [
    {
      path: '/test',
      name: 'test',
      component: require('components/Register/Views/Test').default,
    }
  ]
};