export class TenantsDefinitionModel {
  constructor(data = null) {
    this.id = (data && data.id) || undefined;
    this.status = (data && data.status) || 1;
    this.name = (data && data.name) || "";
    this.taxNumber = (data && data.taxNumber) || "";
    this.taxOffice = (data && data.taxOffice) || '';
    this.gsm = (data && data.gsm) || '';
    this.phone = (data && data.phone) || '';
    this.erpTypeId = (data && data.erpTypeId) || null;
    this.logType = (data && data.logType) || null;
    this.canUseErpService = (data && data.canUseErpService) || false;
    this.canUsePortal = (data && data.canUsePortal) || false;
    this.canUseMobile = (data && data.canUseMobile) || false;
    this.parentTenantId = (data && data.parentTenantId) || null;
    this.logoUrl = (data && data.logoUrl) || '';
    this.code = (data && data.code) || '';
    this.masterCode = (data && data.masterCode) || '';
    this.tenantModuleIds = (data && data.tenantModuleIds) || [];
    this.address = (data && data.address) || '';
    this.cityId = (data && data.cityId) || undefined;
    this.districtId = (data && data.districtId) || undefined;
    this.maxTenant = (data && data.maxTenant) || null;
    this.level = (data && data.level) || 3;
    this.erpTypeIds = (data && data.erpTypeIds) || [];
    this.tenantPackageIds = (data && data.tenantPackageIds) || null;
    this.tenantMasterDataGuid = (data && data.tenantMasterDataGuid) || undefined;
  };
};

export class TenantsModuleInfoModel {
  constructor(data = null) {
    this.tenantModuleId = (data && data.tenantModuleId) || undefined;
    this.moduleId = (data && data.moduleId) || undefined;
    this.moduleName = (data && data.moduleName) || '';
    this.extraFields = (data && data.extraFields) || [];
    this.moduleDefinition = (data && data.moduleDefinition) || '';
  }
}

export class TenantsUserModel {
  constructor(data = null) {
    this.name = data?.name || '';
    this.surname = data?.surname || '';
    this.email = data?.email || '';
    this.gsm = data?.gsm || '';
    this.description = data?.description || '';
    this.roleIds = data?.roleIds || [];
    this.isUserTypeAdmin = data?.isUserTypeAdmin || false;
    this.isActive = data?.isActive || false;
    this.modules = data?.modules || [];
  }
}

export class TenantsLicenceModel {
  constructor(data = null) {
    this.id = data?.id;
    this.licencePackageId = data?.licencePackageId;
    this.isActive = data?.isActive || false;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.monthlyTotalAmount = data?.monthlyTotalAmount;
    this.yearlyTotalAmount = data?.yearlyTotalAmount;
    this.useMonthly = data?.useMonthly || false;
    this.useYearly = data?.useYearly || false;
    this.moduleLicencePackages = data?.moduleLicencePackages || [];
  }
}