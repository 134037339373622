import React, { Component } from 'react';
import { Button, Card, Tag, Row, Col, List, Upload, Icon, Modal, Spin, Popconfirm, Badge } from 'antd';
import { documentApproveStatuses, moduleTypesEnum } from 'lib/constants';
import i18n from 'plugins/i18n';
import { collectAuthorizedData, prepareToAuthorizedFileDownload } from 'helpers';
import endpoints from 'config/endpoints';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import { Select } from './NetbankaComponents';

class DocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: props.downloadUrl ? [{
        uid: props.tenantDocumentType + props.name,
        name: props.linkName,
        status: 'done',
        response: '{ status: "done"}',
        url: props.downloadUrl,
      }] : [],
      uploading: false,
      changedStatus: null,
      generalProcessing: false,
      confirmVisible: false
    }
  }

  onSampleDocumentDownload = () => {
    const { sampleFileDownloadUrl } = this.props;
    window.open(prepareToAuthorizedFileDownload(sampleFileDownloadUrl), "_blank");
  }

  onChange = ({ file }) => {
    switch (file.status) {
      case "uploading": this.setState({ fileList: [file], uploading: true, generalProcessing: true });
        break;

      case "done":
        this.setState({ fileList: [{ ...file, url: file.response.data }], uploading: false, changedStatus: 2, generalProcessing: false });
        break;

      case "error":
        this.setState({ fileList: [], uploading: false, changedStatus: 1, generalProcessing: false });
        Modal.error({
          title: i18n.t('msg.documentUploadError'),
          content: file.response.errors && file.response.errors.length && file.response.errors[0].message,
          onOk: () => this.setState({ fileList: [], uploading: false })
        });
        break;

      default:
        break;
    }
  }

  onDownload = file => {
    const { admin, tenantId } = this.props
    let url = prepareToAuthorizedFileDownload(file.url);
    if (admin) url = `${url}&tenantId=${tenantId}`;
    window.open(url, "_blank");
  }

  onRemove = () => {
    const { tenantDocumentType, deleteDocument, admin, tenantId } = this.props
    this.setState({ generalProcessing: true, confirmVisible: false });
    let parameters = { module: moduleTypesEnum.NTH, type: tenantDocumentType };
    if (admin) parameters = { ...parameters, tenantId };

    deleteDocument(parameters, () =>
      this.setState({ fileList: [], changedStatus: 1, generalProcessing: false })
    );
  }

  onStatusChange = status => {
    const { onApproveStatusChange, tenantDocumentType } = this.props
    this.setState({ changedStatus: status });
    onApproveStatusChange({ type: tenantDocumentType, status })
  }


  render() {
    const { title, name, approveStatus, attentionLength, tenantDocumentType, isDeletable, isFileUploadability, admin, documentStatusesLookup, tenantId } = this.props;
    const { fileList, uploading, changedStatus, generalProcessing, confirmVisible } = this.state;
    const authorizationInfo = collectAuthorizedData();
    const action = admin ? `${endpoints.tenant.adminDocumentUpload}?tenantId=${tenantId}` : endpoints.tenant.documentUpload;

    return (
      <Card {...{
        title: <div>
          {title}
          {
            !admin &&
            <Tag className={`document-status ${documentApproveStatuses[changedStatus || approveStatus]}`}>
              {i18n.t(`lbl.${documentApproveStatuses[changedStatus || approveStatus]}`)}
              {generalProcessing && <Spin indicator={<Icon type="loading" spin />} />}
            </Tag>
          }
        </div >,
        className: `document-upload ${documentApproveStatuses[changedStatus || approveStatus]}`,
        extra:
          admin ?
            <Select
              label={i18n.t('lbl.processStatus')}
              value={changedStatus || approveStatus}
              options={documentStatusesLookup.data}
              optVal="id"
              optName={({ id }) => <Badge className={`document-status ${documentApproveStatuses[id]}`} status=" " text={i18n.t(`lbl.${documentApproveStatuses[id]}`)} />}
              onChange={this.onStatusChange}
              className="document-status-select #approvedStatus"
              allowClear={false}
            />
            :
            <Button onClick={this.onSampleDocumentDownload} size="small" type="link" icon="file-text"> {i18n.t('btn.downloadSampleDocument')}</Button>

      }
      }>
        <Row type="flex" align="middle" justify="center" gutter={[40, 40]}>
          <Col xs={24} sm={5}>
            <div className="upload-wrapper">
              <Popconfirm
                visible={confirmVisible}
                placement="bottom"
                onCancel={() => this.setState({ confirmVisible: false })}
                onConfirm={this.onRemove}
                title={i18n.t('msg.areYouSureToDelete')}
                okText={i18n.t('btn.delete')}
                cancelText={i18n.t('btn.cancel')}
                overlayClassName="document-popconfirm"
              >
                <Upload {...{
                  fileList,
                  action,
                  name: "file",
                  headers: {
                    Authorization: `Bearer ${authorizationInfo.token}`,
                    userId: authorizationInfo.userId,
                    tenantId: authorizationInfo.tenantId,
                  },
                  data: { type: tenantDocumentType, moduleType: moduleTypesEnum.NTH },
                  onDownload: this.onDownload,
                  onChange: this.onChange,
                  onRemove: () => this.setState({ confirmVisible: true }),
                  onPreview: this.onDownload,
                  accept: ".pdf,.doc,.docx,jpg,.jpeg,.png",
                  disabled: (!isDeletable || uploading),
                }}>
                  <Button disabled={(!isFileUploadability && fileList.length > 0) || (fileList.length > 0)}>
                    <Icon type="upload" /> {i18n.t('btn.uploadDocument')}
                  </Button>
                </Upload>
              </Popconfirm>
            </div>
          </Col>
          <Col xs={24} sm={19}>
            <List
              className="mt-10 mb-10"
              size="small"
              dataSource={Array.from(Array(attentionLength), (_, i) => `attentions.${name}.attention${1 + i}`)}
              split={false}
              renderItem={attention => <List.Item>{i18n.t(attention)}</List.Item>}
            />
          </Col>
        </Row>
      </Card >
    )
  }
}
const mapDispatchToProps = (dispatch, { admin }) => ({
  deleteDocument: (parameters, callback) => dispatch(actions.delete({ baseUrl: admin ? endpoints.tenant.adminDocuments : endpoints.tenant.documents, url: '', key: "documents", parameters, options: { errors: { showMessage: true } } }, callback)),
});
export default connect(null, mapDispatchToProps)(DocumentUpload);
