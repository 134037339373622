export const GET_ALL_ADMIN = "GET_ALL_ADMIN";
export const GET_ALL_ADMIN_SUCCESS = "GET_ALL_ADMIN_SUCCESS";
export const GET_ALL_ADMIN_FAILURE = "GET_ALL_ADMIN_FAILURE";
export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const POST_ADMIN = "POST_ADMIN";
export const POST_ADMIN_SUCCESS = "POST_ADMIN_SUCCESS";
export const POST_ADMIN_FAILURE = "POST_ADMIN_FAILURE";
export const PUT_ADMIN = "PUT_ADMIN";
export const PUT_ADMIN_SUCCESS = "PUT_ADMIN_SUCCESS";
export const PUT_ADMIN_FAILURE = "PUT_ADMIN_FAILURE";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
export const PATCH_ADMIN = "PATCH_ADMIN";
export const PATCH_ADMIN_SUCCESS = "PATCH_ADMIN_SUCCESS";
export const PATCH_ADMIN_FAILURE = "PATCH_ADMIN_FAILURE";
export const GET_FILTER_ADMIN = "GET_FILTER_ADMIN";
export const GET_FILTER_ADMIN_SUCCESS = "GET_FILTER_ADMIN_SUCCESS";
export const GET_FILTER_ADMIN_FAILURE = "GET_FILTER_ADMIN_FAILURE";
export const CLEAR_STATE_ADMIN = "CLEAR_STATE_ADMIN";
export const CLEAR_FILTER_ADMIN = "CLEAR_FILTER_ADMIN";
export const GET_WITH_POST_ADMIN = "GET_WITH_POST_ADMIN";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_ADMIN, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_ADMIN_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_ADMIN_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_ADMIN, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_ADMIN_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_ADMIN_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_ADMIN, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_ADMIN_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_ADMIN_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_ADMIN, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_ADMIN_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_ADMIN_FAILURE, payload })
  },

  getWithPost: (payload, callback) => {
    return ({ type: GET_WITH_POST_ADMIN, payload, callback })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_ADMIN, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_ADMIN_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_ADMIN_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_ADMIN, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_ADMIN_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_ADMIN_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_ADMIN, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_ADMIN_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_ADMIN_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key, options) => {
    return ({ type: CLEAR_STATE_ADMIN, key, options })
  },
  clearFilter: (key) => {
    return ({ type: CLEAR_FILTER_ADMIN, key })
  }
  //#endregion
}

export default actions;