import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Tooltip, Modal, notification, Checkbox, Icon } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import actions from 'store/actions/admin';
import { GeneralRuleModel, RuleItemModel, GeneralRuleVoucherTypeModel, ReproductionRuleModel } from 'models';
import { DataTable, Loading, CheckAccessRight } from 'components/UIComponents'
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { regexTypes, httpMethods, regexFilters } from 'lib/constants';

class Rule extends Component {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.state = {
      loading: true,
      visibleRuleItem: false,
      visibleVoucherType: false,
      currentCodes: [],
      data: props.reproductionRule ? new ReproductionRuleModel() : new GeneralRuleModel(),
      InitialState: props.reproductionRule ? new ReproductionRuleModel() : new GeneralRuleModel(),
      currentRuleItem: new RuleItemModel(),
      currentVoucherType: new GeneralRuleVoucherTypeModel(),
      indexRuleItem: null,
      indexVoucherType: null,
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.generalRules.single.data && (props.reproductionRule ? !state.data.generalRuleId : !state.data.id)) {
      const data = props.reproductionRule ? new ReproductionRuleModel(props.generalRules.single.data) : new GeneralRuleModel(props.generalRules.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.generalRules.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    this.props.getRegexFields();
    this.props.getBankAccounts();

    if (this.id) this.props.getGeneralRules(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  updateItem = (key, value, type) => {
    let { state } = this;
    state[`current${type}`][key] = value;
    if (type === 'RuleItem' && key === 'regexType') {
      state.currentRuleItem.regexFilter = null;
      state.currentRuleItem.regexPattern = '';
    };
    this.setState({ ...state });
  };

  save = (e) => {
    e.preventDefault();
    const { data, InitialState } = this.state;
    const { reproductionRule, tenantId } = this.props
    const _data = reproductionRule ? new ReproductionRuleModel(_.cloneDeep({ ...data, ruleItems: data.generalRuleItems })) : new GeneralRuleModel(_.cloneDeep(data));
    _data.priority = _data.priority || null;

    const parameters = tenantId && { TenantId: tenantId }
    const method = (this.id && !_data.generalRuleId) ? httpMethods.put : httpMethods.post;
    const dataIsValid = this.validateData(_data, InitialState);

    if (dataIsValid) {
      if (!reproductionRule && !_.isEqual(_data, InitialState) && !!this.id) {
        const confirmModal = Modal.confirm();

        confirmModal.update({
          title: i18n.t('msg.ruleIsUpdated'),
          content: i18n.t('msg.updateWarning'),
          okText: i18n.t('btn.update'),
          cancelText: i18n.t('btn.newRule'),
          okButtonProps: {
            onClick: () => {
              //for update
              confirmModal.update({ okButtonProps: { loading: true } })
              this.saveData(_data, method, () => confirmModal.destroy(), parameters);
            }
          },
          cancelButtonProps: {
            type: 'default',
            onClick: () => {
              //for new rule
              delete _data.id;
              confirmModal.update({ cancelButtonProps: { loading: true } })
              this.saveData(_data, undefined, () => confirmModal.destroy(), parameters)
            }
          },
        })
      }
      else {
        this.saveData(_data, method, undefined, parameters);
      }
    }
  };

  validateData = (data, originalData) => {
    const { form, reproductionRule } = this.props;
    let isValid = false;
    form.validateFields(error => {
      if (!error && (!data.setManuelPriority || (!!data.setManuelPriority && data.priority))) {
        if (data.generalRuleItems.length <= 0)
          notification.error({ message: i18n.t('msg.noRuleItems') });
        else if (data.generalRuleItems.length === 1 && data.generalRuleItems.find(x => x.regexType === regexTypes.Bank))
          notification.error({ message: i18n.t('msg.addTypeCode1WithBank') });
        else if (data.generalRuleItems.length === 1 && data.generalRuleItems.find(x => x.regexType === regexTypes.TypeCode1))
          notification.error({ message: i18n.t('msg.addBankWithTypeCode1') });
        else if (reproductionRule && _.isEqual(data, originalData))
          notification.error({ message: i18n.t('msg.customRuleIsEqualToGeneralRule') });
        else
          isValid = true;
      }
    })
    return isValid;
  }

  saveData = (_data, method = httpMethods.post, callback, parameters) => {
    const { dialogCloseRequest } = this.props;
    const { data } = this.state;
    this.props.saveData(_data, method, (response) => {
      callback && callback();
      if (response)
        this.setState({ InitialState: data }, () => { dialogCloseRequest({ runGetData: true }); });
      else
        this.setState({ saving: false });
    }, parameters);
  }

  saveRuleItem = (e) => {
    let { data, currentRuleItem, indexRuleItem } = this.state;
    if (currentRuleItem.regexType === null || currentRuleItem.regexFilter === null || currentRuleItem.regexPattern.toString().trim() === '') {
      notification.error({ message: i18n.t('msg.noRuleItem') });
    }
    else if ((currentRuleItem.regexType === regexTypes.TypeCode1 || currentRuleItem.regexType === regexTypes.TypeCode2) && !data.generalRuleItems.find(x => x.regexType === regexTypes.Bank)) {
      notification.error({ message: i18n.t('msg.noBankRule') });
    }
    else {
      indexRuleItem !== null && indexRuleItem !== undefined ? data.generalRuleItems[indexRuleItem] = currentRuleItem : data.generalRuleItems.push(currentRuleItem);
      this.setState({ visibleRuleItem: false, data });
    }
  };

  saveVoucherType = (e) => {
    let { data, currentVoucherType, indexVoucherType } = this.state;
    let { erpTypes, voucherTypes } = this.props;
    currentVoucherType.erpTypeName = erpTypes.data && erpTypes.data.length && erpTypes.data.find(x => x.id === currentVoucherType.erpTypeId).name;
    currentVoucherType.voucherTypeName = voucherTypes.data && voucherTypes.data.length && voucherTypes.data.find(x => x.Id === currentVoucherType.voucherTypeId).Name;
    indexVoucherType !== null && indexVoucherType !== undefined ? data.generalRuleVoucherTypes[indexVoucherType] = currentVoucherType : data.generalRuleVoucherTypes.push(currentVoucherType);
    this.setState({ visibleVoucherType: false, data });
  };

  deleteRuleItem = (indexRuleItem) => {
    const { data } = this.state;
    const _item = data.generalRuleItems[indexRuleItem];
    if (_item.regexType === regexTypes.Bank) {
      const _bankAccount = data.generalRuleItems.find(x => x.regexType === regexTypes.BankaHesap);
      const _contactName = data.generalRuleItems.find(x => x.regexType === regexTypes.IlgiliKisi);
      const _typeCode1 = data.generalRuleItems.find(x => x.regexType === regexTypes.TypeCode1);
      const _typeCode2 = data.generalRuleItems.find(x => x.regexType === regexTypes.TypeCode2);

      if (_bankAccount)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_bankAccount), 1);
      if (_contactName)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_contactName), 1);
      if (_typeCode1)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_typeCode1), 1);
      if (_typeCode2)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_typeCode2), 1);

      notification.warning({ message: i18n.t('msg.bankDeleteRuleItemInfo'), duration: 20 });
    }
    data.generalRuleItems.splice(data.generalRuleItems.indexOf(_item), 1);
    this.setState({ data });
  };

  deleteVoucherType = (indexVoucherType) => {
    let { data } = this.state;
    let _item = data.generalRuleVoucherTypes[indexVoucherType];
    data.generalRuleVoucherTypes.splice(data.generalRuleVoucherTypes.indexOf(_item), 1);
    this.setState({ data })
  };

  renderButtons = (row, i, type) => {
    return (
      <Button.Group className="action-buttons">
        <Tooltip placement="bottom" title={i18n.t('btn.delete')}>
          <Button className="#delete" icon="delete" size="small" onClick={() => this[`delete${type}`](i)} />
        </Tooltip>
        <Tooltip placement="bottom" title={i18n.t('btn.edit')}>
          <Button className="#edit" icon="edit" size="small" onClick={() => this.setState({ [`current${type}`]: row, [`visible${type}`]: true, [`index${type}`]: i })} />
        </Tooltip>
      </Button.Group>
    );
  };

  getName = (row) => {
    let obj;
    if (row.regexType === regexTypes.Bank) {
      obj = this.props.banks.data && this.props.banks.data.find(x => x.bankEftCode === row.regexPattern.toString());
    }
    else if (row.regexType === regexTypes.BankaHesap) {
      obj = this.props.bankAccounts.data && this.props.bankAccounts.data.find(x => x.id.toString() === row.regexPattern.toString());
    }

    return obj ? row.regexType === regexTypes.Bank ? <React.Fragment><img src={obj.logoSmall} className="bank-icon" alt="" /> {obj.name}</React.Fragment> : obj.name : row.regexPattern;
  }

  renderInput = (type) => {
    let { currentRuleItem, data } = this.state;
    const { type1, type2, bankAccounts, banks } = this.props
    let bank = data.generalRuleItems.find(x => x.regexType === regexTypes.Bank);
    let input = <Input className={`#${currentRuleItem.regexPattern}`} label={i18n.t('lbl.value')} value={currentRuleItem.regexPattern} onChange={(e) => this.setState({ currentRuleItem: { ...currentRuleItem, regexPattern: e } })} />;
    let fields = {
      [regexTypes.TypeCode1]: <Select className="#typeCode1" label={i18n.t('lbl.value')} options={bank ? type1.data && type1.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal="code" optName="code" value={currentRuleItem.regexPattern} onChange={(e) => this.setState({ currentRuleItem: { ...currentRuleItem, regexPattern: e } })} />,
      [regexTypes.TypeCode2]: <Select className="#typeCode2" label={i18n.t('lbl.value')} options={bank ? type2.data && type2.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal="code" optName="code" value={currentRuleItem.regexPattern} onChange={(e) => this.setState({ currentRuleItem: { ...currentRuleItem, regexPattern: e } })} />,
      [regexTypes.Bank]: <Select className="#bank" label={i18n.t('lbl.value')} options={banks.data} optVal="bankEftCode" optName={(bank) => <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment>} value={currentRuleItem.regexPattern} onChange={(e) => this.setState({ currentRuleItem: { ...currentRuleItem, regexPattern: e } })} />,
      [regexTypes.BankaHesap]: <Select className="#BankaHesap" label={i18n.t('lbl.value')} options={bank ? bankAccounts.data && bankAccounts.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal={(x) => x.id.toString()} optName="name" value={currentRuleItem.regexPattern} onChange={(e) => this.setState({ currentRuleItem: { ...currentRuleItem, regexPattern: e } })} />,
      [regexTypes.Desc]: input,
      [regexTypes.Iban]: input,
      [regexTypes.Tckn]: input,
      [regexTypes.Vkn]: input,
      [regexTypes.Tutar]: input,
      [regexTypes.IlgiliKisi]: input
    };
    return (fields[type]);
  };

  customValidator = (rule = null, value = null, callback) => {
    let { incomingCategoryId, outgoingCategoryId } = this.state.data
    if (incomingCategoryId && outgoingCategoryId) callback(true);
    else callback();
  }

  renderDialogButtons = checkAccessRights => {
    let { generalRules, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={generalRules.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={generalRules.single.loading} form="generalRules" htmlType="submit" className="success #save" icon="save" loading={generalRules.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      generalRules.single.saving,
      generalRules.single.loading
    )
  }

  getRegexFieldsFromData = () => {
    const { regexFields } = this.props;
    const { data } = this.state
    let _regexFields = regexFields.data && regexFields.data.length > 0 ?
      regexFields.data.map(x => {
        return !data.generalRuleItems.find(y => y.regexType === x.regexType) ?
          x
          :
          { ...x, disabled: true }
      })
      :
      [];


    //Detect to disabled fields from another fields
    _regexFields = data.generalRuleItems.find(x => x.regexType === regexTypes.Bank) ?
      _regexFields
      :
      _regexFields.map(x => {
        return (x.regexType !== regexTypes.TypeCode1 && x.regexType !== regexTypes.TypeCode2 && x.regexType !== regexTypes.BankaHesap) ?
          x
          :
          { ...x, disabled: true }
      });

    return _regexFields
  }

  getRegexDesc = ({ regexType }) => {
    const { regexFields } = this.props
    return regexFields.data.length > 0 && regexFields.data.find(x => x.regexType === regexType).description
  }

  render() {
    const { loading, visibleRuleItem, visibleVoucherType, data, currentRuleItem, currentVoucherType } = this.state;
    const { transferStatus, voucherTypes, incomingCategories, outgoingCategories, regexFields, erpTypes, form, dialogCloseRequest, generalRules, reproductionRule } = this.props;
    const { getFieldDecorator } = form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    const _regexFields = this.getRegexFieldsFromData();
    const regexFiltersOptions = regexFields.data ? regexFields.data.find(x => x.regexType === currentRuleItem.regexType) ? regexFields.data.find(x => x.regexType === currentRuleItem.regexType).regexFilters.map(x => ({ value: x, name: i18n.t(`lbl.${regexFilters[x]}`) })) : [] : [];

    const columnsRules = [
      { render: (row, i) => this.renderButtons(row, i, 'RuleItem'), checkField: false },
      { key: 'regexType', checkField: false, render: this.getRegexDesc },
      { key: 'regexFilter', render: (row) => i18n.t(`lbl.${regexFilters[row.regexFilter]}`) },
      { key: 'regexPattern', render: (row) => this.getName(row) },
    ];
    const columnsVoucherTypes = [
      { render: (row, i) => this.renderButtons(row, i, 'VoucherType'), checkField: false },
      { key: 'erpTypeName' },
      { key: 'voucherTypeName' },
    ];

    const _erpTypes = erpTypes.data && erpTypes.data.length > 0 ? (data.generalRuleVoucherTypes && data.generalRuleVoucherTypes.length) ? erpTypes.data.map(x => { return data.generalRuleVoucherTypes.find(y => y.erpTypeId === x.id) ? { ...x, disabled: true } : x }) : erpTypes.data : [];
    const _voucherTypes = voucherTypes.data && voucherTypes.data.length > 0 ? (currentVoucherType && currentVoucherType.erpTypeId) ? voucherTypes.data.filter(x => x.ErpTypeId === currentVoucherType.erpTypeId) : voucherTypes.data : [];

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...generalRules, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="generalRules">
              <Row gutter={20}>
                {
                  reproductionRule &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Select
                        disabled
                        label={i18n.t('lbl.voucherType')}
                        value={data.voucherTypeId}
                        options={_voucherTypes}
                        optVal="Id"
                        optName="Name"
                        onChange={(e) => this.update('voucherTypeId', !isNaN(e) ? e.toString() : null)}
                        className="#voucherType"
                      />
                    </Form.Item>
                  </Col>
                }
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('incomingCategoryId', {
                      rules: [{ required: !data.incomingCategoryId && !data.outgoingCategoryId, message: i18n.t('msg.requiredfield') }],
                      initialValue: data.incomingCategoryId
                    })(
                      <Select
                        label={`* ${i18n.t('lbl.incomingCategory')}`}
                        options={incomingCategories.data || []}
                        optVal="Id"
                        optName="Name"
                        onChange={(e) => this.update('incomingCategoryId', e)}
                        className="#incomingCategoryId"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('outgoingCategoryId', {
                      rules: [{ required: !data.incomingCategoryId && !data.outgoingCategoryId, message: i18n.t('msg.requiredfield') }],
                      initialValue: data.outgoingCategoryId
                    })(
                      <Select
                        label={`* ${i18n.t('lbl.outgoingCategory')}`}
                        options={outgoingCategories.data || []}
                        optVal="Id"
                        optName="Name"
                        onChange={(e) => this.update('outgoingCategoryId', e)}
                        className="#outgoingCategoryId"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.transferStatus')}
                      value={data.transferStatus}
                      options={transferStatus.data}
                      optVal="id"
                      optName={(x) => i18n.t(`status.${x.name}`)}
                      onChange={(e) => this.update('transferStatus', e)}
                      className="#transferStatus"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Row gutter={20}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        validateStatus={!!data.setManuelPriority && !data.priority ? 'error' : 'success'}
                        help={!!data.setManuelPriority && !data.priority && i18n.t('msg.required')}
                      >
                        <Input disabled={!data.setManuelPriority} type="number" label={i18n.t('lbl.point')} value={data.priority} onChange={(e) => this.update('priority', e)} className="#point" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <Checkbox className="#setManuelPriority" checked={!!data.setManuelPriority} onChange={(e) => this.update('setManuelPriority', e.target.checked)}>
                          {i18n.t('lbl.enterRulePoint')}
                          <Tooltip title={i18n.t('msg.whileIfUncheckedCalculatedRulePoint')}>
                            <Icon className="icon inner-question" type="question-circle" />
                          </Tooltip>
                        </Checkbox>

                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={24} className="mt-30">
                  <DataTable
                    noHeader
                    data={data.generalRuleItems}
                    columns={columnsRules}
                    pagination={false}
                    showNoData={false}
                    title={i18n.t('lbl.conditions')}
                    toggle={false}
                    className="full-width"
                    newButton={() => this.setState({ visibleRuleItem: true, currentRuleItem: new RuleItemModel(), indexRuleItem: null })}
                  />
                </Col>
                {
                  !reproductionRule &&
                  <Col xs={24} sm={24} className="mt-40">
                    <DataTable
                      noHeader
                      data={data.generalRuleVoucherTypes}
                      columns={columnsVoucherTypes}
                      pagination={false}
                      showNoData={false}
                      title={i18n.t('lbl.voucherTypes')}
                      toggle={false}
                      className="full-width"
                      newButton={() => this.setState({ visibleVoucherType: true, currentVoucherType: new GeneralRuleVoucherTypeModel(), indexVoucherType: null })}
                    />
                  </Col>
                }
              </Row>
            </Form>

          </Loading>
        </CheckAccessRight>

        {/* RULE ITEM MODAL  */}
        <Modal
          title={i18n.t('lbl.condition')}
          visible={visibleRuleItem}
          onCancel={() => this.setState({ visibleRuleItem: false })}
          onOk={this.saveRuleItem}
          okButtonProps={{ className: '#saveRuleItem', icon: 'save' }}
          cancelButtonProps={{ className: '#cancel', icon: 'close' }}
          okText={i18n.t('btn.save')}
          cancelText={i18n.t('btn.cancel')}
          destroyOnClose
        >
          <Form>
            <Row gutter={8}>
              <Col span={8}>
                <Select
                  className="#domain"
                  label={i18n.t('lbl.domain')}
                  value={currentRuleItem.regexType}
                  options={_regexFields}
                  optVal="regexType"
                  optName="description"
                  onChange={(e) => this.updateItem('regexType', e, 'RuleItem')}
                />
              </Col>
              <Col span={8}>
                <Select
                  className="#operator"
                  value={currentRuleItem.regexFilter}
                  label={i18n.t('lbl.operator')}
                  options={regexFiltersOptions}
                  optVal="value"
                  optName="name"
                  onChange={(e) => this.setState({ currentRuleItem: { ...currentRuleItem, regexFilter: e } })}
                />
              </Col>
              <Col span={8}>
                {this.renderInput(currentRuleItem.regexType)}
              </Col>
            </Row>
          </Form>
        </Modal>

        {/* VOUCHER TYPE MODAL  */}
        <Modal
          title={i18n.t('lbl.condition')}
          visible={visibleVoucherType}
          onCancel={() => this.setState({ visibleVoucherType: false })}
          onOk={this.saveVoucherType}
          okButtonProps={{ className: '#saveVoucherType', icon: 'save' }}
          cancelButtonProps={{ className: '#cancel', icon: 'close' }}
          okText={i18n.t('btn.save')}
          cancelText={i18n.t('btn.cancel')}
        >
          <Form>
            <Row gutter={10}>
              <Col span={12}>
                <Select
                  className="#erpType"
                  label={i18n.t('lbl.erpType')}
                  value={currentVoucherType.erpTypeId}
                  options={_erpTypes}
                  optVal="id"
                  optName="name"
                  onChange={(e) => this.updateItem('erpTypeId', e, 'VoucherType')}
                />
              </Col>
              <Col span={12}>
                <Select
                  className="#voucherType"
                  label={i18n.t('lbl.voucherType')}
                  value={currentVoucherType.voucherTypeId}
                  options={_voucherTypes}
                  optVal="Id"
                  optName="Name"
                  onChange={(e) => this.updateItem('voucherTypeId', e, 'VoucherType')}
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  generalRules: admin.generalRules,
  incomingCategories: admin.filter.incomingCategories,
  outgoingCategories: admin.filter.outgoingCategories,
  erpTypes: admin.filter.erpTypes,
  transferStatus: admin.filter.transferStatus,
  voucherTypes: admin.filter.voucherTypes,
  regexFields: admin.filter.regexFields,
  banks: admin.filter.banks,
  type1: admin.filter.typeCodes1,
  type2: admin.filter.typeCodes2,
  bankAccounts: admin.filter.bankAccounts,
});
const mapDispatchToProps = (dispatch, { getUrl, saveUrl }) => ({
  getGeneralRules: (id) => dispatch(actions.get({ url: getUrl || endpoints.nte.adminGeneralRules, key: 'generalRules', id })),
  saveData: (data, method, callback, parameters) => dispatch(actions[method]({ url: saveUrl || endpoints.nte.adminGeneralRules, key: 'generalRules', data, parameters, options: { showErrorResponse: true } }, callback)),
  getRegexFields: () => dispatch(actions.getFilter({ url: endpoints.lookups.regexFields, key: 'regexFields' })),
  getBankAccounts: () => dispatch(actions.getFilter({ url: endpoints.tenant.bankAccountsGetBankAccountsByBankEftCode, key: 'bankAccounts' })),
  clearState: () => dispatch(actions.clearState("generalRules"))
});
const RuleForm = Form.create()(Rule);
export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);