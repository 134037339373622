import React from 'react'
import { Layout } from "antd";
import { Switch, Route, withRouter, BrowserRouter, Redirect } from "react-router-dom";
import authRoutes from "plugins/routes/auth";


const Content = () => {
  return (
    <Layout.Content className="auth-content">
      <BrowserRouter>
        <div className="auth-content__inner-content">
          <Switch className="qsdas">
            {authRoutes.routes.map(route => (
              <Route path={`${authRoutes.path}${route.path}`} render={(props) => <route.component {...props} />} key={route.name} />
            ))}
            <Redirect from="/auth" to="/auth/sign-in" />
          </Switch>
        </div>
      </BrowserRouter>
    </Layout.Content>
  )
}

export default withRouter(Content);
