export const GET_ALL_POSRAPOR = "GET_ALL_POSRAPOR";
export const GET_ALL_POSRAPOR_SUCCESS = "GET_ALL_POSRAPOR_SUCCESS";
export const GET_ALL_POSRAPOR_FAILURE = "GET_ALL_POSRAPOR_FAILURE";
export const GET_POSRAPOR = "GET_POSRAPOR";
export const GET_POSRAPOR_SUCCESS = "GET_POSRAPOR_SUCCESS";
export const GET_POSRAPOR_FAILURE = "GET_POSRAPOR_FAILURE";
export const POST_POSRAPOR = "POST_POSRAPOR";
export const POST_POSRAPOR_SUCCESS = "POST_POSRAPOR_SUCCESS";
export const POST_POSRAPOR_FAILURE = "POST_POSRAPOR_FAILURE";
export const PUT_POSRAPOR = "PUT_POSRAPOR";
export const PUT_POSRAPOR_SUCCESS = "PUT_POSRAPOR_SUCCESS";
export const PUT_POSRAPOR_FAILURE = "PUT_POSRAPOR_FAILURE";
export const DELETE_POSRAPOR = "DELETE_POSRAPOR";
export const DELETE_POSRAPOR_SUCCESS = "DELETE_POSRAPOR_SUCCESS";
export const DELETE_POSRAPOR_FAILURE = "DELETE_POSRAPOR_FAILURE";
export const PATCH_POSRAPOR = "PATCH_POSRAPOR";
export const PATCH_POSRAPOR_SUCCESS = "PATCH_POSRAPOR_SUCCESS";
export const PATCH_POSRAPOR_FAILURE = "PATCH_POSRAPOR_FAILURE";
export const GET_FILTER_POSRAPOR = "GET_FILTER_POSRAPOR";
export const GET_FILTER_POSRAPOR_SUCCESS = "GET_FILTER_POSRAPOR_SUCCESS";
export const GET_FILTER_POSRAPOR_FAILURE = "GET_FILTER_POSRAPOR_FAILURE";
export const CLEAR_STATE_POSRAPOR = "CLEAR_STATE_POSRAPOR";
export const GET_WITH_POST_POSRAPOR = "GET_WITH_POST_POSRAPOR";


const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_POSRAPOR, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_POSRAPOR_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_POSRAPOR_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_POSRAPOR, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_POSRAPOR_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_POSRAPOR_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_POSRAPOR, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_POSRAPOR_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_POSRAPOR_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_POSRAPOR, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_POSRAPOR_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_POSRAPOR_FAILURE, payload })
  },

  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_POSRAPOR, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_POSRAPOR, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_POSRAPOR_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_POSRAPOR_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_POSRAPOR, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_POSRAPOR_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_POSRAPOR_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_POSRAPOR, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_POSRAPOR_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_POSRAPOR_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key) => {
    return ({ type: CLEAR_STATE_POSRAPOR, key })
  }
  //#endregion
}

export default actions;