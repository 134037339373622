import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import _ from 'lodash';
import { Input, Select, DatePicker } from 'components/UIComponents/NetbankaComponents';
import { BalancesModel } from 'models';
import actions from 'store/actions/netekstre';
import { Loading } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from 'lib';
import i18n from 'plugins/i18n';

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new BalancesModel(),
      InitialState: new BalancesModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.balancesDetail.single.data && !state.data.id) {
      const data = new BalancesModel({ ...props.balancesDetail.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.balancesDetail.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getBalances(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    let { balancesDetail, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={balancesDetail.single.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>,
      balancesDetail.single.saving,
      balancesDetail.single.loading
    )
  }

  render() {
    let { loading, data } = this.state;
    let { tenantBanks } = this.props;
    this.renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Form onSubmit={this.save} id="BankAccountBalances">
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    value={data.bankEftCode}
                    className="#bank"
                    options={tenantBanks.data}
                    optVal="bankEftCode"
                    optName={(bankData) => utils.netekstre.getBankName({ bankData })}
                    label={i18n.t('lbl.bankName')}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#branchNumber" label={i18n.t('lbl.branchNumber')} value={data.branchNumber} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#branchName" label={i18n.t('lbl.branchName')} value={data.branchName} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#accountNumber" label={i18n.t('lbl.accountNumber')} value={data.accountNumber} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#bankAccountName" label={i18n.t('lbl.accountName')} value={data.bankAccountName} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#suffix" label={i18n.t('lbl.suffix')} value={data.suffix} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker className="#lastTransactionDate" label={i18n.t('lbl.lastTransactionDate')} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#balance" label={i18n.t('lbl.balance')} value={data.balance} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#blockageAmount" label={i18n.t('lbl.blockageAmount')} value={data.blockageAmount} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#creditLimit" label={i18n.t('lbl.creditLimit')} value={data.creditLimit} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#availableBalanceWithCredit" label={i18n.t('lbl.availableBalanceWithCredit')} value={data.availableBalanceWithCredit} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#currentBalance" label={i18n.t('lbl.currentBalance')} value={data.currentBalance} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#currencyCode" label={i18n.t('lbl.currencyCode')} value={data.currencyCode} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input className="#iban" label={i18n.t('lbl.iban')} value={data.iban} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  tenantBanks: common.filter.tenantBanks,
  balancesDetail: netekstre.balancesDetail,
});
const mapDispatchToProps = (dispatch) => ({
  getBalances: (id) => dispatch(actions.get({ url: endpoints.nte.bankAccountBalances, key: 'balancesDetail', id })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.bankAccountBalances, key: 'balancesDetail', data }, callback)),
  clearState: () => dispatch(actions.clearState("balancesDetail"))
});
const BalanceForm = Form.create()(Balance);
export default connect(mapStateToProps, mapDispatchToProps)(BalanceForm);