import React from 'react';
import _ from 'lodash';
import config from 'config'
import i18n from 'plugins/i18n'
import { chartTypes } from 'models';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import Payment from 'payment';

export function formatCurrency({ data = null, currencyCode = undefined, withColor = undefined, withSign = true, isCumulative = false, withHtml = true, withFraction = true }) {
  if (data === null || data === undefined) {
    return '';
  } else {
    let _data = data.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".").split(',');
    if (withHtml) {
      return (
        <span className={withColor ? data === 0 ? '' : _data[0].charAt(0) === '-' ? 'text-red' : 'text-green' : ''}>
          {`${withSign ? (data === 0 ? '' : _data[0].charAt(0) !== '-' ? '+' : '') : ''}${_data[0]}`}
          <span className="decimal">
            {`${withFraction ? `,${_data[1]}` : ''} ${isCumulative ? i18n.t('lbl.cumulative') : ''} ${currencyCode || 'TRY'}`}
            {/* {`,${_data[1]} ${code ? currencies.find(x => x.code === code).symbol : ''}`} */}
          </span>
        </span>
      );
    } else {
      return (
        `${withSign ? (data === 0 ? '' : _data[0].charAt(0) !== '-' ? '+' : '') : ''}${_data[0]},${_data[1]} ${isCumulative ? i18n.t('lbl.cumulative') : ''} ${currencyCode || 'TRY'}`
      );
    }
  }
};

export function formatChartData({ series, labels, options, type }) {
  const tryParse = data => {
    try { return JSON.parse(data) }
    catch (e) { return data };
  }

  const convertFormatDataIsRequired = data => {
    return typeof data === "string"
      ? tryParse(data)
      : _.cloneDeep(data);
  }


  if (series && labels) {
    return {
      labels: convertFormatDataIsRequired(labels),
      datasets: series.map((x, i) => {
        const colorCodes = typeof convertFormatDataIsRequired(x.colorCodes) === "string" ? [x.colorCodes] : convertFormatDataIsRequired(x.colorCodes);
        const data = type === chartTypes.stackedBarChart ? x.datas : x.data;
        return {
          label: x.name,
          baseColor: colorCodes && colorCodes.length ? colorCodes[0] : null,
          backgroundColor: (chart) => {
            if (type === chartTypes.barChart || type === chartTypes.pieChart) {
              const gradients = colorCodes.map((color, y) => {
                let gradientStroke = chart.chart.ctx.createLinearGradient(0, 300, 0, 0);
                gradientStroke.addColorStop(0, color);
                gradientStroke.addColorStop(1, LightenDarkenColor(color, 60));
                return gradientStroke
              })
              return gradients
            }
            if (type === chartTypes.stackedBarChart) {
              let gradientStroke = chart.chart.ctx.createLinearGradient(0, 250, 0, 0);
              gradientStroke.addColorStop(0, colorCodes[0]);
              gradientStroke.addColorStop(1, LightenDarkenColor(colorCodes[0], x.name === 'Belirsiz' ? 25 : 80));

              return new Array(data.length).fill(gradientStroke)
            }
            else {
              return { backgroundColor: colorCodes || '#fff' }
            }
          },
          fill: false,
          borderColor: (chart) => {
            if (type === chartTypes.lineChart) {
              let gradientStroke = chart.chart.ctx.createLinearGradient(0, 250, 0, 0);
              gradientStroke.addColorStop(0, i ? '#ffe3e8' : '#e6f9d5');
              gradientStroke.addColorStop(1, i ? '#fe0000' : '#6ee400');
              return gradientStroke
            }
          },
          pointBorderColor: (chart) => {
            if (type === chartTypes.lineChart) {
              let gradientStroke = chart.chart.ctx.createLinearGradient(0, 250, 0, 0);
              gradientStroke.addColorStop(0, i ? '#ffe3e8' : '#e6f9d5');
              gradientStroke.addColorStop(1, i ? '#fe0000' : '#6ee400');
              return gradientStroke
            }
          },
          pointBackgroundColor: (chart) => {
            if (type === chartTypes.lineChart) {
              let gradientStroke = chart.chart.ctx.createLinearGradient(0, 250, 0, 0);
              gradientStroke.addColorStop(0, i ? '#ffe3e8' : '#e6f9d5');
              gradientStroke.addColorStop(1, i ? '#fe0000' : '#6ee400');
              return gradientStroke
            }
          },
          data: data && convertFormatDataIsRequired(data).map(y => {
            y = typeof y === 'object' ? convertFieldsToCamelize(y) : y
            if (options && options.positive) {
              y = y.value ? y.value.replace(/-/g, '') : y.replace(/-/g, '');
            }
            return y.value ? parseFloat(y.value.replace(/\./g, '').replace(/,/g, '.')) : parseFloat(y.replace(/\./g, '').replace(/,/g, '.'));
          })
        }
      }),
    };
  } else {
    return {};
  }
};

export function formatDate(date, toISO, toDate, isLt, withHour = true, useAbsoluteTime = false) {
  if (!date || date === '0001-01-01T00:00:00+00:00') {
    return '';
  }
  if (typeof date !== 'string') date = date.toISOString();
  else {
    date = moment(date);
    if (toISO) {
      if (useAbsoluteTime)
        return date.format('YYYY-MM-DD[T]HH:mm:ss[.00Z]');
      else if (isLt)
        return date.endOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.00Z]')
      else
        return date.startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.00Z]')
    }
    else if (toDate)
      return date.format(`DD.MM.YYYY${useAbsoluteTime ? ' HH:mm' : ''}`);
    else
      return date.format(`DD.MM.YYYY${withHour ? ' HH:mm' : ''}`);
  }
};

export function formatIBAN(iban) {
  return iban ? removeSpaces(iban).replace(/(.{4})/g, "$1 ") : '';
};

export function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;


    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  }
  catch (e) {
    console.log(e)
  }
}

export function shortFormatMoney(amount, digits = 2) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "B" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (amount >= si[i].value) {
      break;
    }
  }
  return (amount / si[i].value).toFixed(digits).replace(rx, "$1") + ' ' + si[i].symbol;
}

export function toCamelCase(str) {
  return str.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};

export function compareFields(field, data, originData) {
  let modifiedFields = [];
  if (field) {
    if (originData) {
      // eslint-disable-next-line
      data[field].map((item, index) => {
        let originItem = originData.single.data[field][index];
        if (!_.isEqual(item, originItem)) {
          if (item.checked !== null && item.checked !== undefined) {
            item.modelState = item.checked && !originItem.checked ? 1 : 3
            if (item.checked && originItem.checked && item.defaultValue !== originItem.defaultValue) {
              item.modelState = 2;
            }
          } else if (item.dynamicPropertyId !== undefined) {
            if (item.dynamicPropertyId !== 0) {
              if (item.modelState === 0) {
                item.modelState = 2
              } else if (item.modelState === null || item.modelState === undefined) {
                item.modelState = 1;
              }
            }
          }
          modifiedFields.push(item);
        }

      })
    } else {
      if (data[field][0]) {
        if (data[field][0].checked !== null && data[field][0].checked !== undefined) {
          data[field].filter(x => x.checked).map(item => {
            item.modelState = 1;
            return modifiedFields.push(item);
          })
        } else {
          // eslint-disable-next-line
          data[field].map(item => {
            if (item.modelState !== undefined || item.checked) {
              item.modelState = 1;
              return modifiedFields.push(item);
            } else if (item.dynamicPropertyId !== 0 && item.required !== undefined) {
              item.modelState = 1;
              return modifiedFields.push(item);
            }
          }
          )
        }
      }
    }
  } else {
    if (originData) {
      if (!_.isEqual(data, originData)) {
        data.modelState = 2;
      }
    } else {
      data.modelState = 1;
    }
    modifiedFields = data;
  }
  return modifiedFields;
}

export function isTckn({ value = null, callback }) {
  let isValid = value ? value.length === 11 && isTcknValidator(value) : true;
  return isValid ? callback() : callback(true);
}

export const isTcknValidator = (a) => {
  if (a.substr(0, 1) === 0 || a.length !== 11) {
    return false;
  }
  var i = 9, md = '', mc = '', digit, mr = '';
  // eslint-disable-next-line
  while (digit = a.charAt(--i)) {
    i % 2 === 0 ? md += digit : mc += digit;
  }
  // eslint-disable-next-line
  if (((eval(md.split('').join('+')) * 7) - eval(mc.split('').join('+'))) % 10 !== parseInt(a.substr(9, 1), 10)) {
    return false;
  }
  for (let c = 0; c <= 9; c++) {
    mr += a.charAt(c);
  }
  // eslint-disable-next-line
  if (eval(mr.split('').join('+')) % 10 !== parseInt(a.substr(10, 1), 10)) {
    return false;
  }
  return true;
};

export const isVknValidator = (a) => {

  let v1 = 0;
  let v2 = 0;
  let v3 = 0;
  let v4 = 0;
  let v5 = 0;
  let v6 = 0;
  let v7 = 0;
  let v8 = 0;
  let v9 = 0;
  let v11 = 0;
  let v22 = 0;
  let v33 = 0;
  let v44 = 0;
  let v55 = 0;
  let v66 = 0;
  let v77 = 0;
  let v88 = 0;
  let v99 = 0;
  let v_last_digit = 0;
  let total = 0;

  if (a.length === 10) {
    v1 = (Number(a.charAt(0)) + 9) % 10;
    v2 = (Number(a.charAt(1)) + 8) % 10;
    v3 = (Number(a.charAt(2)) + 7) % 10;
    v4 = (Number(a.charAt(3)) + 6) % 10;
    v5 = (Number(a.charAt(4)) + 5) % 10;
    v6 = (Number(a.charAt(5)) + 4) % 10;
    v7 = (Number(a.charAt(6)) + 3) % 10;
    v8 = (Number(a.charAt(7)) + 2) % 10;
    v9 = (Number(a.charAt(8)) + 1) % 10;
    v_last_digit = Number(a.charAt(9));

    v11 = (v1 * 512) % 9;
    v22 = (v2 * 256) % 9;
    v33 = (v3 * 128) % 9;
    v44 = (v4 * 64) % 9;
    v55 = (v5 * 32) % 9;
    v66 = (v6 * 16) % 9;
    v77 = (v7 * 8) % 9;
    v88 = (v8 * 4) % 9;
    v99 = (v9 * 2) % 9;

    if (v1 !== 0 && v11 === 0) v11 = 9;
    if (v2 !== 0 && v22 === 0) v22 = 9;
    if (v3 !== 0 && v33 === 0) v33 = 9;
    if (v4 !== 0 && v44 === 0) v44 = 9;
    if (v5 !== 0 && v55 === 0) v55 = 9;
    if (v6 !== 0 && v66 === 0) v66 = 9;
    if (v7 !== 0 && v77 === 0) v77 = 9;
    if (v8 !== 0 && v88 === 0) v88 = 9;
    if (v9 !== 0 && v99 === 0) v99 = 9;
    total = v11 + v22 + v33 + v44 + v55 + v66 + v77 + v88 + v99;

    if (total % 10 === 0) total = 0;
    else total = (10 - (total % 10));
    if (total === v_last_digit) {
      return true;
    } else return false;

  } else return false;

};

export function dataUriToBlob(dataURI) {
  // serialize the base64/URLEncoded data
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // parse the mime type
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // construct a Blob of the image data
  var array = [];
  for (var i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i));
  }
  return new Blob(
    [new Uint8Array(array)],
    { type: mimeString }
  );
}

export function isTest() {
  return config.environments.includes(window.location.hostname);
}

export function removeParametersFromODataFilter(filter, removeParameters) {
  if (filter.and && filter.and.length) {
    removeParameters.map(param =>
      filter.and.find(x => x.or && x.or.length && x.or.find(y => delete y[param]))
    )
  }
  return filter;
}

export function LightenDarkenColor(col, amt) {

  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

export function menuIndicatorTracker() {
  setTimeout(() => {
    // seçili eleman görünür durumda mı bunu default  false olarak set ediyoruz
    let hasElement = false;

    // menü altındaki tüm li elementleri getiriyoruz
    const subElements = document.getElementsByClassName("ant-menu-root")[0] && document.getElementsByClassName("ant-menu-root")[0].getElementsByTagName("li");
    let index = 0;

    if (subElements && subElements.length > 0) {


      // tüm elementlar içierisinde dönüyoruz
      for (let subElement of subElements) {

        // elementin parent ının class ını alıyoruz. eğer parent class hidden ise menü ekranda görünmüyor demektir ve
        // bu elementi atlıyoruz. görünen bir element ise index i 1 arttırıyoruz

        //parent parent kontrolü yap
        const subElementClass = subElement.parentNode.className;
        const subSubElementClass = subElement.parentNode.parentNode.parentNode.className;

        if ((subSubElementClass.indexOf("ant-menu-hidden") === -1) && subElementClass.indexOf("ant-menu-hidden") === -1) {

          // parent class ve parentın parent classı içerisinde ant-menu-hidden class ı bulunmuyor yani ekranda görünüyor
          // bu yüzden indexi arttıyoruz
          index++;
          const elementClass = subElement.className;

          // ilgili element in class ı selected ise seçili elemanı tespit ettik demektir. bu tespit edildikten sonra
          // döngüden çıkıyoruz
          if (elementClass.indexOf("ant-menu-item-selected") > -1) {

            // seçili eleman görünür durumda olduğunu set ediyoruz
            hasElement = true;
            break;
          }
        }
      }
    }

    const parentElementClassOfSelectedItem = document.getElementsByClassName("ant-menu-item-selected")[0] && document.getElementsByClassName("ant-menu-item-selected")[0].parentElement.getAttribute("class");

    const parentParentElementClassOfSelectedItem = document.getElementsByClassName("ant-menu-item-selected")[0] && document.getElementsByClassName("ant-menu-item-selected")[0].parentElement.parentElement.parentElement.getAttribute("class");
    if (parentParentElementClassOfSelectedItem && parentElementClassOfSelectedItem.indexOf("ant-menu-hidden") === -1 && parentParentElementClassOfSelectedItem.indexOf("ant-menu-hidden") === -1) {
      document.documentElement.style.setProperty('--margin-top-indicator', `${(48 * (index - 1))}px`);
    }

    // eğer seçili bir element yoksa veya seçili element accordion içerisinde kaldıysa indicator ı gizliyoruz
    document.documentElement.style.setProperty('--indicator-width', hasElement ? "calc(100% - 30px)" : "0px");
    document.documentElement.style.setProperty('--indicator-after-before', hasElement ? "30px" : "0px");
  }, 360)
}

export function phoneNormalizer(value) {
  const inAcceptedCharacters = ['+', '(', ')', ' '];
  inAcceptedCharacters.forEach(char =>
    value = value?.split(char).join('')
  )
  if (value?.startsWith('9')) value = value.substring(1);
  if (value?.startsWith('0')) value = value.substring(1);

  return value;
}

export function ibanNormalizer(value) {
  return value.split(' ').join('');
}

export function convertFieldsToCamelize(data) {
  if (data) {
    var newO, origKey, newKey, value
    if (data instanceof Array) {
      return data.map(function (value) {
        if (typeof value === "object") {
          value = convertFieldsToCamelize(value)
        }
        return value
      })
    } else {
      newO = {}
      for (origKey in data) {
        if (data.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = data[origKey]
          if (value instanceof Array || (value !== null && value !== undefined && value.constructor && value.constructor === Object)) {
            value = convertFieldsToCamelize(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }
  else return null
}

export function collectAuthorizedData() {
  const token = localStorage.getItem('token');
  let info = localStorage.getItem('userInfo');
  info = info ? JSON.parse(CryptoJS.AES.decrypt(info, "netfinans").toString(CryptoJS.enc.Utf8)) : {};
  return { token, ...info }
}

export function prepareToAuthorizedFileDownload(downloadUrl) {
  const data = collectAuthorizedData();
  return `${downloadUrl}&t=${data.token}&uid=${data.userId}&tid=${data.tenantId}`
}

export function preparePatchData(data, defaultFields = []) {
  const patchData = _.map(_.filter(_.keys(data), x => x !== 'id' && x !== 'index'), x => ({ op: 'replace', path: `/${x}`, value: data[x] }));
  const id = data.id;
  const additionalDefaultData = defaultFields.map(x => additionalDefaultData[x] = data[x]);

  return patchData.length ? { patchData, id, ...additionalDefaultData } : false;
}

export function convertArrayToEnum(data, layout = ['id', 'name']) {
  return Object.fromEntries(data.map(x => ([x[layout[0]], x[layout[1]]])))
}

export function swapObject(obj) {
  if (obj) return Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({ [b]: a })))
}

export function moneyNormalizer(data) {
  return data > 999999 ? shortFormatMoney(data).replace(/\s+/g, '') : formatMoney(data);
}

export function calcVAT({ amount = 0, vatRate = 1, currencyCode = 'TRY', prefix, withCurrency = true, toFormatMoney = true }) {
  amount = parseFloat(amount?.toString()?.replace(',', '.'));
  let result = amount + (amount * vatRate / 100);
  if (toFormatMoney) result = moneyNormalizer(result)
  if (withCurrency) result = `${result} ${currencyCode}`;
  if (prefix) result = `${prefix} ${result}`;
  return result
}

export function convertBoolean(data) { return data === "true" || data === true }

export function cloneDeep(data) { return JSON.parse(JSON.stringify(data)) }

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);
  if (clearValue.length >= 3)
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;

  return clearValue;
}

export function formatCreditCardNumber(value) {
  if (!value)
    return value;

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function classNames(classNamesList = []) {
  return classNamesList.filter(x => x !== false && x !== undefined && x !== null && x !== '' && x !== 'false').join(' ').trim()
}

export function maskCardNumber(value) {
  return `${value.substr(0, 6)}${new Array(value.length - 10).fill('*').join('')}${value.substr(12, 16)}`
};

export const removeSpaces = value => value.replace(/\s/g, '') || '';

export const removeTilde = value => value?.replace('~', '');

export const bulkParseFloat = data => {
  Object.keys(data).forEach(x => {
    if (typeof data[x] === "string" && !isNaN(data[x].replace(',', '.')))
      data[x] = parseFloat(data[x].replace(',', '.'));
  })
  return data;
}