import React from 'react';
import { Icon } from 'antd';
import i18n from 'plugins/i18n';



const Loading = ({ loading = true, children, style, Wrapper, size = '', className, wrapperClassName = '' }) => {

  if (typeof Wrapper === "boolean") throw new Error(i18n.t('dev.message.loadingComponentWrapperType'))

  const Loader = () => (
    <div className={`loading ${size} ${className}`}>
      <Icon type="loading" style={style} spin />
    </div>
  );

  const renderChildren = () => {
    if (children) return children;
    else throw new Error(i18n.t('dev.message.loadingComponentChildren'));
  }

  return (
    loading
      ? Wrapper
        ? <Wrapper className={wrapperClassName}> <Loader /> </Wrapper>
        : <Loader />
      : renderChildren()
  );
}

export default Loading;