import React, { PureComponent } from 'react';
import { InputNumber, Tooltip } from 'antd';

class FloatingInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value !== 0 || props.value !== undefined ? props.value : 0,
      isFocused: ''
    };
  };

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props).indexOf('value') !== -1 && this.props.value !== prevProps.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    };
  };

  onChange = (value) => {
    this.setState({ value }, () => {
      this.props.onChange && this.props.onChange(value);
    });
  };

  onFocus = () => {
    this.setState({ isFocused: true }, () => {
      this.props.onFocus && this.props.onFocus(this.state.value);
    });
  };

  onBlur = () => {
    this.setState({ isFocused: false }, () => {
      this.props.onBlur && this.props.onBlur(this.state.value);
    });
  };

  render() {
    const { value, isFocused } = this.state;
    const { disabled, type, className, info, label, maxLength, formatter, prefix } = this.props;
    return (
      <div className={`nb nb-input ${!!disabled ? 'disabled' : ''} ${type || ''}`}>
        {prefix && <div className="prefix">{prefix}</div>}
        <InputNumber {...this.props}
          value={value}
          onChange={(e) => this.onChange(e)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className={`${value !== null && value !== undefined && value.toString() !== '' && 'is-filled'} ${className || ''}`}
        />
        {info &&
          <Tooltip title={info}>
            <i className="far fa-question-circle form-tooltip" />
          </Tooltip>
        }
        <label className={((value !== null && value !== undefined && value.toString() !== '') || isFocused || formatter) ? 'focused' : ''}>{label || ''}</label>
        <span className="character-length">{maxLength && `${value.length}/${maxLength}`}</span>
      </div>
    );
  };
};

export default FloatingInput;