import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { PosListModel } from 'models/posrapor';
import actions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';
import { posTypes, vendorTypes } from 'lib/constants';

class PosList extends Component {
  constructor(props) {
    super(props);
    this.state = {

      loading: true,
      data: new PosListModel(),
      InitialState: new PosListModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.posLists.single.data && !state.data.id) {
      const data = new PosListModel({ ...props.posLists.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.posLists.single.saving) {
      props.onDialogClose({ ...state.InitialState }, { ...state.data })
    }
    return null;
  };

  componentDidMount() {
    const { getPosList } = this.props
    getPosList(this.id);
  };

  componentWillUnmount() {
    this.props.clearState();
    this.setState({ data: new PosListModel({}) })
  }

  update = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  renderDialogButtons = checkAccessRights => {
    let { posLists, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={posLists.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={posLists.single.loading} form="posList" htmlType="submit" className="success #save" icon="save" loading={posLists.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      posLists.single.saving,
      posLists.single.loading
    )
  }

  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData, bulkEdit, dataTableSelectedItems } = this.props;
    const data = _.cloneDeep(this.state.data);
    form.validateFields(error => {
      if (!error) {
        if (bulkEdit) {
          data.ids = dataTableSelectedItems;
          delete data.id;
        }

        saveData(data, bulkEdit && endpoints.posrapor.posListBulkEdit, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };


  onSearch = e => {
    const _this = this;
    if (e && e !== '' && e.length > 2) {
      const filter = { filter: { or: { name: { contains: e }, code: { contains: e } }, } }
      _this.props.getCurrentAccounts(filter);
    }
  }

  render() {
    const { banks, dialogCloseRequest, posLists, currentAccounts } = this.props;
    const { loading, data } = this.state;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...posLists, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="posList">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      value={data.bankEftCode}
                      className="#bankEftCode"
                      disabled
                      label={i18n.t('posrapor.bankName')}
                      options={banks.data}
                      optVal="eftCode"
                      optName={(bankData) => utils.common.getBankName({ bankData })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input value={data.companyName} className="#companyName" disabled label={i18n.t('posrapor.merchantName')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input value={data.terminalId} className="#terminalId" disabled label={i18n.t('posrapor.terminalNo')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input value={data.virtualPosId} className="#virtualPosId" disabled label={i18n.t('posrapor.virtualPosId')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input value={data.erpPaymentSetCode} className="#erpPaymentSetCode" label={i18n.t('posrapor.erpPaymentSetCode')} onChange={(e) => this.update('erpPaymentSetCode', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input value={data.erpPosCode} className="#erpPosCode" label={i18n.t('posrapor.erpPosCode')} onChange={(e) => this.update('erpPosCode', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input value={data.erpTenantCode} className="#erpTenantCode" label={i18n.t('posrapor.erpTenantCode')} onChange={(e) => this.update('erpTenantCode', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      options={vendorTypes}
                      optVal="id"
                      optName="name"
                      lang="lbl"
                      value={data.vendorType}
                      className="#vendorType"
                      label={i18n.t('posrapor.vendorType')}
                      onChange={(e) => this.update('vendorType', e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      loading={currentAccounts.loading}
                      showSearch
                      onSearch={this.onSearch}
                      className="#currentAccountCode"
                      label={i18n.t('posrapor.currentAccountCode')}
                      options={currentAccounts.data}
                      optVal="Id"
                      optName={(data) => `${data.Code} ${data.Name}`}
                      value={data.currentAccountCode}
                      onChange={(e) => this.update('currentAccountCode', e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      options={posTypes}
                      optVal="id"
                      optName="name"
                      lang="lbl"
                      value={data.posType}
                      className="#posType"
                      label={i18n.t('posrapor.posType')}
                      onChange={(e) => this.update('posType', e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor, common }) => ({
  posLists: posrapor.posLists,
  banks: posrapor.filter.banks,
  dataTableSelectedItems: common.dataTableSelectedItems,
  currentAccounts: posrapor.filter.currentAccounts,
});
const mapDispatchToProps = (dispatch) => ({
  getPosList: (id, callback) => dispatch(actions.get({ url: endpoints.posrapor.posList, key: 'posLists', id }, callback)),
  saveData: (data, endpoint, callback) => dispatch(actions.put({ url: endpoint || endpoints.posrapor.posList, key: 'posLists', data }, callback)),
  getCurrentAccounts: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.lookups.currentAccounts, key: 'currentAccounts' })),
  clearState: () => dispatch(actions.clearState("posLists"))
});
const PosListForm = Form.create()(PosList);
export default connect(mapStateToProps, mapDispatchToProps)(PosListForm);
