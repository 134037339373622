import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import endpoints from 'config/endpoints';
import { ActionButtons, DataTable, Filter, FadeIn, CheckAccessRight } from 'components/UIComponents';
import { defaultFilters, baseUrls } from 'lib/constants';
import utils from 'lib';
import i18n from 'plugins/i18n';
import Parameter from './Detail';
import { Modal } from "antd";
import queryString from "query-string";


class BankParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.common.TenantBanks(),
      queryStringData: (props.location && props.location.search) ? queryString.parse(props.location.search.substring(1).split('?').join('&')) : undefined
    };
  }

  componentDidMount() {
    const { queryStringData } = this.state;
    const { getLookupTenantBanks } = this.props;

    if (queryStringData) this.showReturnMessageFromExternalLink(queryStringData)

    this.getTenantBanks();
    getLookupTenantBanks({ orderBy: ['name asc'] });

    utils.common.checkAccessToUpdateOrDelete("TenantBanks");
  };

  //For AnadolubankRedirect
  showReturnMessageFromExternalLink = queryStringData => {
    const { data, code, error } = queryStringData;
    const { callbackAnadoluBank } = this.props
    if (!error) {
      callbackAnadoluBank({ data, code }, () => {
        Modal.success({
          title: i18n.t('msg.refreshTokenSuccess'),
          content: i18n.t('msg.refreshTokenSuccessDesc'),
          okText: i18n.t('btn.ok')
        })
      })
    }
    else {
      Modal.error({
        title: i18n.t('msg.errorRefreshTokenForAnadolubank'),
        content: i18n.t('msg.bankReturnedCode') + error,
        okText: i18n.t('btn.ok')
      })
    }
  }

  getTenantBanks = () => {
    let { filter } = this.state;
    this.props.getTenantBanks(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({
      filter
    }, this.getTenantBanks);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getTenantBanks);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getTenantBanks);
  }

  renderActionButtons = row => {
    return <ActionButtons url={"TenantBanks"} state="tenantBanks" editUrl item={row} hasDelete getDatas={this.getTenantBanks} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    const { tenantBanks, lookupTenantBanks } = this.props;

    let filters = [
      { label: i18n.t('lbl.bankName'), type: 'select', options: lookupTenantBanks.data, value: 'id', name: (bankData) => utils.common.getBankName({ bankData }), keys: ['Id'], showAll: true, uniqueKey: '#bank', multiple: true, guid: true }
    ];
    let columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.bankName'), key: 'Bank/Name', render: ({ Bank }) => utils.common.getBankName({ data: Bank, listKeys: 'lookupTenantBanks', dataField: 'EftCode', listField: 'bankEftCode' }), checkField: false }
    ];
    return (
      <div className={`page-content`}>
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...tenantBanks, single: tenantBanks.list } }} >
            <Filter filters={filters} onFilter={this.onFilter} />
            <DataTable
              wrappedComponentRef={el => this.datatable = el}
              access={"TenantBanks"}
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={tenantBanks.list.data}
              loading={tenantBanks.list.loading}
              title={i18n.t('route.common.tenantBanks')}
              getData={this.getTenantBanks}
              count={tenantBanks.list.count}
              Component={Parameter}
              dialogTitle={i18n.t('lbl.tenantBank')}
              deleteOptions={{ stateKey: "tenantBanks", url: "TenantBanks", baseUrl: baseUrls.tenant }}
            />
          </CheckAccessRight>
        </FadeIn >
      </div>
    );
  }
}

const mapStateToProps = ({ common }) => ({
  lookupTenantBanks: common.filter.lookupTenantBanks,
  tenantBanks: common.tenantBanks,
});

const mapDispatchToProps = (dispatch) => ({
  getTenantBanks: (filter, callback) => dispatch(actions.getAll({ filter, url: endpoints.tenant.tenantBanks, key: 'tenantBanks', isNewODataStructure: true }, callback)),
  getLookupTenantBanks: (filter, parameters) => dispatch(actions.getFilter({ filter, parameters, url: endpoints.lookups.tenantBanks, key: 'lookupTenantBanks' })),
  callbackAnadoluBank: (parameters, callback) => dispatch(actions.get({ parameters, url: endpoints.nte.anadoluBankCallback }, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankParameters);
