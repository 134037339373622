import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select, DatePicker, InputNumber } from 'components/UIComponents/NetbankaComponents';
import { ReportLineModel } from 'models';
import actions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import { Loading } from 'components/UIComponents';
import _ from 'lodash';
import utils from 'lib';
import i18n from 'plugins/i18n';

class ReportLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new ReportLineModel(),
      InitialState: new ReportLineModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.reportLines.single.data && !state.data.id) {
      const data = new ReportLineModel({ ...props.reportLines.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.reportLines.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getReportLine(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = checkAccessRights => {
    let { reportLines, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={reportLines.single.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>,
      reportLines.single.saving,
      reportLines.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { renderDialogButtons } = this;
    const { banks } = this.props;

    renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.transactionDate} label={i18n.t('posrapor.transactionDate')} className="#transactionDate" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    value={data.bankEftCode}
                    className="#bank"
                    label={i18n.t('posrapor.posBank')}
                    options={banks.data}
                    optVal="eftCode"
                    optName={(bankData) => utils.posrapor.getBankName({ bankData })}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.pureAmount} className="#pureAmount" label={i18n.t('posrapor.pureAmount')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.netAmount} className="#netAmount" label={i18n.t('posrapor.netAmount')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.serviceCommisionRate} className="#serviceCommisionRate" label={i18n.t('posrapor.serviceCommisionRate')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.commisionAmount} className="#commisionAmount" label={i18n.t('posrapor.commisionAmount')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <InputNumber disabled value={data.installmentCount} className="#installmentCount" label={i18n.t('posrapor.installmentCount')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.cardNumber} className="#cardNumber" label={i18n.t('posrapor.cardNumber')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.merchantId} className="#merchantId" label={i18n.t('posrapor.merchant')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.terminalId} className="#terminalId" label={i18n.t('posrapor.terminalNo')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.posId} className="#posId" label={i18n.t('posrapor.pos')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.authCode} className="#authCode" label={i18n.t('lbl.authCode')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker disabled value={data.commitDate} className="#commitDate" label={i18n.t('lbl.commitDate')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.companyName} className="#companyName" label={i18n.t('lbl.companyName')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor }) => ({
  reportLines: posrapor.reportLines,
  banks: posrapor.filter.banks,
});
const mapDispatchToProps = (dispatch) => ({
  getReportLine: (id, callback) => dispatch(actions.get({ url: endpoints.posrapor.reportLine, key: 'reportLines', id }, callback)),
  clearState: () => dispatch(actions.clearState("reportLines"))
});
const ReportLineForm = Form.create()(ReportLine);
export default connect(mapStateToProps, mapDispatchToProps)(ReportLineForm);