export default {
  path: '/kredi',
  name: 'kredi',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: require('components/Modules/OnlineKredi/Home/Home').default,
      tokenCheck: false,
    },
    {
      path: '/applications',
      name: 'applications',
      component: require('components/Modules/OnlineKredi/Application/List').default,
      tokenCheck: true,
    },
    {
      path: '/apply-credit',
      name: 'applyCredit',
      component: require('components/Modules/OnlineKredi/ApplyCredit').default,
      tokenCheck: true,
    },
    {
      path: '/application-result/:id',
      name: 'applicationResult',
      component: require('components/Modules/OnlineKredi/ApplyCredit/ResultApplication').default,
      tokenCheck: true,
      visible: false
    },
    {
      path: '/application-continuation/:id',
      name: 'applicationContinuation',
      component: require('components/Modules/OnlineKredi/ApplyCredit/ApplicationContinuation').default,
      tokenCheck: false,
      visible: false
    },
    {
      path: '/application-continuation-2/:id',
      name: 'applicationContinuation2',
      component: require('components/Modules/OnlineKredi/ApplyCredit/ApplicationContinuation2').default,
      tokenCheck: false,
      visible: false
    },
  ]
};