export default {
  path: '/cek',
  name: 'cek',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/cheque/:id?',
      name: 'payroll',
      visible: false,
      component: require('components/Modules/Cek/Payrolls/Detail').default,
    },
    {
      path: '/parameters',
      name: 'parameters',
      component: require('components/Modules/Cek/Parameters/List').default,
    },
    {
      path: '/cheques',
      name: 'createCheque',
      component: require('components/Modules/Cek/Cheques/List').default,
    },
    {
      path: '/payrolls',
      name: 'chequeList',
      component: require('components/Modules/Cek/Payrolls/List').default,
    },
    {
      path: '/reporting',
      name: 'reporting',
      component: require('components/Modules/Cek/Reporting/List').default,
    },
  ]
};