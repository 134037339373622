import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { TypeCodeModel } from 'models';
import { typeCodeLevels, httpMethods } from 'lib/constants';
import actions from 'store/actions/admin';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import utils from 'lib';

class TypeCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new TypeCodeModel(),
      InitialState: new TypeCodeModel(),
    };
    this.id = props.id;
  };

  componentDidMount() {
    this.props.getTenantBanks({ orderBy: ['name asc'] });
    if (this.id) this.props.getTypeCode(this.id);
    else this.setState({ loading: false });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.typeCodes.single.data && !state.data.id) {
      const data = new TypeCodeModel(props.typeCodes.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.typeCodes.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;

    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, (response) => {
          if (response) {
            this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
          }
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { typeCodes, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={typeCodes.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={typeCodes.single.loading} form="typeCode" htmlType="submit" className="success #save" icon="save" loading={typeCodes.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      typeCodes.single.saving,
      typeCodes.single.loading
    )
  }

  render() {
    const { loading, data } = this.state;
    const { tenantBanks, typeCodes, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...typeCodes, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="typeCode">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('code', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.code
                    })(
                      <Input className="#code" label={i18n.t('lbl.typeCode')} onChange={(e) => this.update('code', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: false, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#name" label={i18n.t('lbl.description')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bank')}
                        options={tenantBanks.data || []}
                        optVal="bankEftCode"
                        optName={(bankData) => utils.admin.getBankName({ bankData })}
                        onChange={(e) => this.update('bankEftCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('level', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.level
                    })(
                      <Select
                        className="#typeCodeType"
                        label={i18n.t('lbl.typeCodeType')}
                        options={typeCodeLevels}
                        optVal="id"
                        optName="name"
                        onChange={(e) => this.update('level', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ admin }) => ({
  typeCodes: admin.typeCodes,
  tenantBanks: admin.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getTypeCode: (id, callback) => dispatch(actions.get({ url: endpoints.nte.typeCodes, key: "typeCodes", id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.typeCodes, key: 'typeCodes', data }, callback)),
  getTenantBanks: (filter) => dispatch(actions.getAll({ url: endpoints.lookups.tenantBanks, key: 'tenantBanks', filter })),
  clearState: () => dispatch(actions.clearState("typeCodes")),
});
const TypeCodeForm = Form.create()(TypeCode);
export default connect(mapStateToProps, mapDispatchToProps)(TypeCodeForm);