import React from 'react';
import ReactSVG from 'react-svg';
import Logo from 'assets/img/logo.svg'
import { connect } from "react-redux";

const LoggingOut = ({ loggingOut }) =>
  <div className={`fadeOut ${loggingOut ? 'out' : ''}`}>
    <ReactSVG src={Logo} />
  </div>

const mapStateToProps = ({ auth }) => ({ ...auth })
export default connect(mapStateToProps)(LoggingOut);
