import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import { DataTable, ActionButtons, Description } from 'components/UIComponents';
import FieldDefinition from './Details';
import endpoints from 'config/endpoints';
import { defaultFilters, dialogTypes, baseUrls } from 'lib/constants';
import i18n from 'plugins/i18n';
import utils from 'lib';

class FieldDefinitions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.netekstre.FieldDefinitions(),
    };
  };

  componentDidMount() {
    this.getDefinitions();
    utils.common.checkAccessToUpdateOrDelete('PropertyConfigs');
  };

  getDefinitions = () => {
    let { filter } = this.state;
    this.props.getDefinitions(filter);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getDefinitions();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, this.getDefinitions);
  }

  operationName = ({ OperationType }) => {
    switch (OperationType) {
      case 1: return i18n.t('lbl.textInput');
      case 2: return i18n.t('lbl.list');
      case 3: return i18n.t('lbl.automaticRule');
      default: return '';
    }
  };

  render() {
    let { fieldDefinitions, auth } = this.props;
    let access = auth.data.accessRights.find(x => x.endPoint === 'PropertyConfigs' && x.method === 'POST');
    let columns = [
      { render: (row) => <ActionButtons url="PropertyConfigs" editUrl="openDialog" item={row} hasDelete getDatas={this.getDefinitions} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('lbl.title'), key: 'Name' },
      { label: i18n.t('lbl.source'), key: 'SourceField' },
      { label: i18n.t('lbl.target'), key: 'TargetNames' },
      { label: i18n.t('lbl.process'), key: 'OperationType', render: this.operationName },
      { label: i18n.t('lbl.formulaPattern'), key: 'Pattern', render: ({ Pattern }) => <Description text={Pattern} /> },
    ];

    return (
      <div className="page-content">
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          access="PropertyConfigs"
          history={this.props.history}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          columns={columns}
          data={fieldDefinitions.list.data}
          loading={fieldDefinitions.list.loading}
          title={i18n.t('route.netekstre.fieldDefinitions')}
          count={fieldDefinitions.list.count}
          newButton={access && "openDialog"}
          Component={FieldDefinition}
          dialogTitle={i18n.t('lbl.fieldDefinition')}
          dialogType={dialogTypes.Modal}
          getData={this.getDefinitions}
          deleteOptions={{ stateKey: "fieldDefinitions", url: "PropertyConfigs", baseUrl: baseUrls.nte }}
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth }) => ({
  auth,
  fieldDefinitions: netekstre.fieldDefinitions,
});

const mapDispatchToProps = (dispatch) => ({
  getDefinitions: (filter) => dispatch(actions.getAll({ filter, url: endpoints.nte.propertyConfigs, key: 'fieldDefinitions', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(FieldDefinitions);