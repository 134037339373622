import React, { Component, Suspense, lazy } from 'react';
import { Row, Col, Tabs, Card, Spin, Icon } from 'antd';
import i18n from 'plugins/i18n';
import ReactSVG from 'react-svg';
import { Loading } from 'components/UIComponents';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import utils from 'lib';

const ResultPage = lazy(() => import('./components/Result'))

const pageOptions = {
  creditCard: {
    icon: require('assets/img/icons/credit-card.svg'),
    component: lazy(() => import('./components/CreditCard'))
  },
  emailOrder: {
    icon: require('assets/img/icons/email.svg'),
    component: lazy(() => import('./components/EmailOrder'))
  },
  smsOrder: {
    icon: require('assets/img/icons/sms.svg'),
    component: lazy(() => import('./components/SmsOrder'))
  },
  qrOrder: {
    icon: require('assets/img/icons/qr.svg'),
    component: lazy(() => import('./components/QrOrder'))
  }
}
class Pay extends Component {
  constructor(props) {
    super(props);
    this.userId = props.history.location.state?.userId;
    this.state = { currentActiveKey: props.history.location.state?.tab || 'creditCard', resultScreen: false, mountKey: v4(), threeDResult: null };
  }

  componentDidMount() {
    utils.netahsilat.getPaymentTabs();
    utils.netahsilat.getLanguageTypes();
    utils.netahsilat.getCurrencies();
    utils.netahsilat.getFrequencyTypes();
    utils.netahsilat.getPaymentSets();
  }


  getPayPage = (props) => React.createElement(pageOptions[props.type].component, { ...props, userId: this.userId });

  onTabChange = key => this.setState({ currentActiveKey: key })

  toggleResultScreen = (isShow, mountKey, threeDResult) => {
    this.setState({ resultScreen: isShow, mountKey, threeDResult })
  }

  render() {
    const { activeTabKey, pay, paymentGet, history } = this.props;
    const { currentActiveKey, resultScreen, mountKey, threeDResult } = this.state;
    const defaultActiveKey = activeTabKey || currentActiveKey;
    const PayPage = this.getPayPage;

    const TabTitle = ({ type }) => <div className="tab-title__inline"><ReactSVG src={pageOptions[type].icon} /> <span>{i18n.t('lbl.payWith', { type: i18n.t(`lbl.${type}`) })}</span></div>

    return (
      <React.Fragment key={mountKey}>
        {
          resultScreen ?
            <Suspense fallback={<Loading />}>
              <ResultPage {...{ history, toggleResultScreen: this.toggleResultScreen, threeDResult }} />
            </Suspense>
            :
            <div className="page-content">
              <Spin spinning={pay.saving} indicator={<Icon type="loading" />}>
                <Card className="pt-30 pl-45 pr-45">
                  <Row gutter={20}>
                    <Col xs={24}>
                      <div>
                        {currentActiveKey === "creditCard"
                          ? <Loading size="sm" loading={paymentGet.loading}>
                            <div dangerouslySetInnerHTML={{ __html: paymentGet.data?.paymentParameters?.paymentFormTopDescription }} />
                          </Loading>
                          : i18n.t('Credibly enhance global e-markets via competitive e-commerce. Compellingly whiteboard business infrastructures vis-a-vis top-line supply chains. Holisticly supply strategic e-business after extensible e-business. ')
                        }
                      </div>
                    </Col>

                    <Col xs={24} className="mt-60">
                      <Tabs defaultActiveKey={defaultActiveKey} activeKey={currentActiveKey} size="default" className="full-width-tabs green" onChange={this.onTabChange}>

                        <Tabs.TabPane key="creditCard" tab={<TabTitle type='creditCard' />}>
                          <Suspense fallback={<Loading />}>
                            <PayPage {...{ type: 'creditCard', toggleResultScreen: this.toggleResultScreen }} />
                          </Suspense>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="email" tab={<TabTitle type='emailOrder' />}>
                          <Suspense fallback={<Loading />}>
                            <PayPage {...{ type: 'emailOrder' }} />
                          </Suspense>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="sms" tab={<TabTitle type='smsOrder' />}>
                          <Suspense fallback={<Loading />}>
                            <PayPage {...{ type: 'smsOrder' }} />
                          </Suspense>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="qr" tab={<TabTitle type='qrOrder' />}>
                          <Suspense fallback={<Loading />}>
                            <PayPage {...{ type: 'qrOrder' }} />
                          </Suspense>
                        </Tabs.TabPane>

                      </Tabs>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </div>
        }
      </React.Fragment>
    )
  }
}
const mapStateToProps = ({ netahsilat }) => ({
  pay: netahsilat.pay.single,
  paymentGet: netahsilat.payment.get.single
});
export default connect(mapStateToProps)(Pay);
