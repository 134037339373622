export * from './dbs';
export * from './tos';
export * from './cek';
export * from './enums';
export * from './kredi';
export * from './common';
export * from './posrapor';
export * from './netahsilat';
export * from './admin';

export class RuleModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.voucherTypeCode = data && data.voucherTypeCode ? data.voucherTypeCode : '';
    this.voucherTypeId = data && data.voucherTypeId ? data.voucherTypeId : '';
    // this.expenseCenter = data && data.expenseCenter ? data.expenseCenter : '';
    // this.departmentNumber = data && data.departmentNumber ? data.departmentNumber : '';
    this.accountPlanCode = data && data.accountPlanCode ? data.accountPlanCode : '';
    this.bankAccountCode = data && data.bankAccountCode ? data.bankAccountCode : '';
    this.incomingCategoryId = data && data.incomingCategoryId ? data.incomingCategoryId : undefined;
    // this.divisionNumber = data && data.divisionNumber ? data.divisionNumber : '';
    this.currentAccountCode = data && data.currentAccountCode ? data.currentAccountCode : '';
    // this.safeCode = data && data.safeCode ? data.safeCode : '';
    this.priority = data && data.priority !== null ? data.priority : 1;
    this.outgoingCategoryId = data && data.outgoingCategoryId ? data.outgoingCategoryId : undefined;
    this.ruleItems = data && data.ruleItems ? data.ruleItems : [];
    this.ruleType = data && data.ruleType ? data.ruleType : 2;
    this.name = data && data.name ? data.name : '';
    this.categoryId = data && data.categoryId ? data.categoryId : 1;
    this.newAccountPlan = data && data.newAccountPlan ? data.newAccountPlan : 0;
    this.tenantId = data && data.tenantId ? data.tenantId : 0;
    this.transferStatus = data && data.transferStatus ? data.transferStatus : undefined;
    this.rulePropertyConfigs = data && data.rulePropertyConfigs ? data.rulePropertyConfigs : [];
    this.setManuelPriority = data && data.setManuelPriority ? data.setManuelPriority : null;
  };
};

export class RuleItemModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.isRequired = data && data.isRequired ? data.isRequired : true;
    this.regexFilter = data && data.regexFilter ? data.regexFilter : null;
    this.regexType = data && data.regexType ? data.regexType : null;
    this.regexPattern = data && data.regexPattern ? data.regexPattern : '';
  };
};

export class TransactionModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.transactionDate = data && data.transactionDate ? data.transactionDate : null;
    this.amount = data && data.amount ? data.amount : 0;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : '';
    this.currencyCode = data && data.currencyCode ? data.currencyCode : '';
    this.typeCode1 = data && data.typeCode1 ? data.typeCode1 : '';
    this.iban = data && data.iban ? data.iban : '';
    // this.divisionNumber = data && data.divisionNumber ? data.divisionNumber : '';
    // this.departmentNumber = data && data.departmentNumber ? data.departmentNumber : '';
    this.typeCode2 = data && data.typeCode2 ? data.typeCode2 : '';
    this.bankAccountName = data && data.bankAccountName ? data.bankAccountName : '';
    this.bankAccountAccountNumber = data && data.bankAccountAccountNumber ? data.bankAccountAccountNumber : '';
    this.suffix = data && data.suffix ? data.suffix : '';
    this.tcknNumber = data && data.tcknNumber ? data.tcknNumber : '';
    this.taxNumber = data && data.taxNumber ? data.taxNumber : '';
    this.accountPlanCode = data && data.accountPlanCode ? data.accountPlanCode : undefined;
    this.transactionDynamicProperties = data && data.transactionDynamicProperties ? data.transactionDynamicProperties : [];
    this.transferStatus = data && data.transferStatus ? data.transferStatus : '';
    // this.safeCode = data && data.safeCode ? data.safeCode : '';
    // this.expenseCenter = data && data.expenseCenter ? data.expenseCenter : '';
    this.bankAccountCode = data && data.bankAccountCode ? data.bankAccountCode : '';
    this.description1 = data && data.description1 ? data.description1 : '';
    this.erpCode = data && data.erpCode ? data.erpCode : '';
    this.voucherTypeCode = data && data.voucherTypeCode ? data.voucherTypeCode : undefined;
    this.voucherTypeId = data && data.voucherTypeId ? data.voucherTypeId : undefined;
    this.currentAccountCode = data && data.currentAccountCode ? data.currentAccountCode : null;
    this.currentAccountName = data && data.currentAccountName ? data.currentAccountName : null;
    this.currentAccountId = data && data.currentAccountId ? data.currentAccountId : null;
    this.tempId = data && data.tempId ? data.tempId : undefined;
    this.categoryId = data && data.categoryId ? data.categoryId : undefined;
    this.categoryName = data && data.categoryName ? data.categoryName : '';
    this.documentId = data && data.documentId ? data.documentId : '';
    this.bankAccount = data && data.bankAccount ? data.bankAccount : { Name: '' }
    this.contactName = data && data.contactName ? data.contactName : null;
    this.currentAccounts = data && data.currentAccounts ? data.currentAccounts : [];
    this.transferStatusId = data && data.transferStatusId ? data.transferStatusId : undefined;
  };
};
export class CurrentAccountModel {
  constructor(data = null) {
    this.currentAccountType = data && data.currentAccountType ? data.currentAccountType : null;
    this.code = data && data.code ? data.code : '';
    this.title = data && data.title ? data.title : '';
    this.email = data && data.email ? data.email : '';
    this.name = data && data.name ? data.name : '';
    this.phone = data && data.phone ? data.phone : '';
    this.gsm = data && data.gsm ? data.gsm : '';
    this.onlineJobNumber = data && data.onlineJobNumber ? data.onlineJobNumber : '';
    this.taxOffice = data && data.taxOffice ? data.taxOffice : '';
    this.taxNumber = data && data.taxNumber ? data.taxNumber : '';
    this.tcknNumber = data && data.tcknNumber ? data.tcknNumber : '';
    this.ibans = data && data.ibans ? data.ibans : [];
    this.bankAccountNumbers = data && data.bankAccountNumbers ? data.bankAccountNumbers : [];
    this.customerRepresentatives = data && data.customerRepresentatives ? data.customerRepresentatives : [];
    this.id = data && data.id ? data.id : undefined;
  };
};

export class TenantBankModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.merchantCode = data && data.merchantCode ? data.merchantCode : '';
    this.roleIds = data && data.roleIds ? data.roleIds : [];
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : '';
    this.modules = data && data.modules ? data.modules : [];
  };
};


export class BankAccountModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.bankName = data && data.bankName ? data.bankName : '';
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : '';
    this.accountNumber = data && data.accountNumber ? data.accountNumber : '';
    this.branchNumber = data && data.branchNumber ? data.branchNumber : '';
    this.branchName = data && data.branchName ? data.branchName : '';
    this.name = data && data.name ? data.name : '';
    this.erpCode = data && data.erpCode ? data.erpCode : '';
    this.suffix = data && data.suffix ? data.suffix : '';
    this.iban = data && data.iban ? data.iban : '';
    this.currencyCode = data && data.currencyCode ? data.currencyCode : 'TRY';
    this.ibans = data && data.ibans ? data.ibans : [];
    this.isActive = data && data.isActive ? data.isActive : false;
    this.transactionTransferType = data && data.transactionTransferType ? data.transactionTransferType : 0;
    this.roleIds = data && data.roleIds ? data.roleIds : [];
    this.modules = data && data.modules ? data.modules : [];
    this.criticalBalanceLimit = data && data.criticalBalanceLimit ? data.criticalBalanceLimit : null; // Bu field BE hazır olduğunda değiştirilebilir!
    this.tenantBankGuid = data && data.tenantBankGuid ? data.tenantBankGuid : null;
  };
};
export class BankAccountInfosModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.bankAccountId = data && data.bankAccountId ? data.bankAccountId : undefined;
    this.lastSuccessfulRequestDate = data && data.lastSuccessfulRequestDate ? data.lastSuccessfulRequestDate : null;
    this.lastSuccessfulTransactionCount = data && data.lastSuccessfulTransactionCount ? data.lastSuccessfulTransactionCount : 0;
    this.transactionTransferType = data && data.transactionTransferType ? data.transactionTransferType : null;
    this.balance = data && data.balance ? data.balance : null;
    this.freeRate = data && data.freeRate ? data.freeRate : 0;
    this.criticalBalanceLimit = data && data.criticalBalanceLimit ? data.criticalBalanceLimit : null;

  };
};

export class CategoryModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.categoryType = data && data.categoryType ? data.categoryType : undefined;
    this.categoryCode = data && data.categoryCode ? data.categoryCode : '';
    this.tenantId = data && data.tenantId ? data.tenantId : undefined;
    this.roles = data && data.roles ? data.roles : [];
  };
};

export class DynamicFieldModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : null;
    this.title = data && data.title ? data.title : '';
    this.code = data && data.code ? data.code : '';
  };
};

export class FieldDefinitionModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.sourceField = data && data.sourceField ? data.sourceField : '';
    this.sourceFieldDescription = data && data.sourceFieldDescription ? data.sourceFieldDescription : '';
    this.operationType = data && data.operationType ? data.operationType : undefined;
    this.targets = data && data.targets ? data.targets : [];
    this.targetNames = data && data.targetNames ? data.targetNames : '';
    this.canHaveDefaultValue = data && data.canHaveDefaultValue ? data.canHaveDefaultValue : '';
    this.pattern = data && data.pattern ? data.pattern : '';
    this.currentAccounts = data && data.currentAccounts ? data.currentAccounts : [];
  };
};

export class UserModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.surname = data && data.surname ? data.surname : '';
    this.email = data && data.email ? data.email : '';
    this.phone = data && data.phone ? data.phone : '';
    this.gsm = data && data.gsm ? data.gsm : '';
    this.description = data && data.description ? data.description : '';
    this.roles = data && data.roles ? data.roles : [];
    this.userType = data && data.userType ? data.userType : false;
    this.isActive = data && data.isActive ? data.isActive : false;
    this.modules = data && data.modules ? data.modules : [];
  };
};

export class UserGroupModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : null;
    this.bankAccountModels = data && data.bankAccountModels ? data.bankAccountModels : [];
    this.name = data && data.name ? data.name : '';
    this.roleCategoryModels = data && data.roleCategoryModels ? data.roleCategoryModels : [];
    this.tenantAccessRightModels = data && data.tenantAccessRightModels ? data.tenantAccessRightModels : [];
    this.tenantBankModels = data && data.tenantBankModels ? data.tenantBankModels : [];
    this.transactionCategoryModels = data && data.transactionCategoryModels ? data.transactionCategoryModels : [];
    // this.moduleRoleModels = data && data.moduleRoleModels ? data.moduleRoleModels : [];
    this.rights = data && data.rights ? data.rights : [];
  };
};

export class NotificationModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.description = data && data.description ? data.description : '';
    this.notificationCategories = data && data.notificationCategories ? data.notificationCategories.filter(x => x.id) : [];
    this.notificationRoles = data && data.notificationRoles ? data.notificationRoles : [];
    this.notificationUsers = data && data.notificationUsers ? data.notificationUsers : [];
    this.templateId = data && data.templateId ? data.templateId : null;
    this.notificationType = data && data.notificationType ? data.notificationType : 1;
    this.transactionCategories = data && data.transactionCategories ? data.transactionCategories : [];
    this.notifyToCurrentAccount = data && data.notifyToCurrentAccount ? data.notifyToCurrentAccount : false;
    this.notifyToCustomerRepresentative = data && data.notifyToCustomerRepresentative ? data.notifyToCustomerRepresentative : false;
    this.minAmount = data && data.minAmount ? data.minAmount : "";
    this.maxAmount = data && data.maxAmount ? data.maxAmount : "";
    this.currentAccountMatchInquiry = data && data.currentAccountMatchInquiry ? data.currentAccountMatchInquiry : 0;
  };
};

export class ListModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.sourceField = data && data.sourceField ? data.sourceField : '';
    this.targets = data && data.targets ? data.targets : [];
    this.pattern = data && data.pattern ? data.pattern : '';
    this.items = data && data.items ? data.items : [];
  };
};

export class TextInputModel {
  constructor(data = null) {
    this.canHaveDefaultValue = data && data.canHaveDefaultValue ? data.canHaveDefaultValue : true;
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.operationType = data && data.operationType ? data.operationType : 0;
    this.pattern = data && data.pattern ? data.pattern : '';
    this.sourceField = data && data.sourceField ? data.sourceField : '';
    this.sourceFieldDescription = data && data.sourceFieldDescription ? data.sourceFieldDescription : '';
    this.targetNames = data && data.targetNames ? data.targetNames : '';
    this.targets = data && data.targets ? data.targets : [];
    this.totalRowCount = data && data.totalRowCount ? data.totalRowCount : 0;
  };
};

export class AutomaticRuleModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.pattern = data && data.pattern ? data.pattern : '';
    this.sourceField = data && data.sourceField ? data.sourceField : '';
    this.targets = data && data.targets ? data.targets : [];
  };
};

export class TableSettingModel {
  constructor(data = null) {
    this.id = data?.id;
    this.userId = data && data.userId ? data.userId : 0;
    this.pageSizes = data && data.pageSizes ? data.pageSizes : 10;
    this.fields = data && data.fields ? data.fields : '';
    this.controllerPath = data && data.controllerPath ? data.controllerPath : '';
  };
};
export class CreateTestDataModel {
  constructor(data = null) {
    // this.id = data && data.id ? data.id : 0;
    this.accounts = data && data.accounts ? data.accounts : [];
    this.categoryId = data && data.categoryId ? data.categoryId : null;
    this.amount = data && data.amount ? data.amount : "";
    this.description1 = data && data.description1 ? data.description1 : "";

  };
};

export class PackageModel {
  constructor(data = null) {
    this.id = data?.id;
    this.name = data?.name || '';
    this.code = data?.code || '';
    this.isActive = data?.isActive || false;
    this.moduleIds = data?.moduleIds || [];
    this.moduleLicencePackages = data?.moduleLicencePackages || [];
    this.monthlyMaintenanceAmount = data?.monthlyMaintenanceAmount;
    this.monthlyMaintenanceVATRate = data?.monthlyMaintenanceVATRate;
    this.monthlyTotalPackageAmount = data?.monthlyTotalPackageAmount;
    this.yearlyMaintenanceAmount = data?.yearlyMaintenanceAmount;
    this.yearlyMaintenanceVATRate = data?.yearlyMaintenanceVATRate;
    this.yearlyTotalPackageAmount = data?.yearlyTotalPackageAmount;
    this.useMonthly = data?.useMonthly || false;
    this.useYearly = data?.useYearly || false;
  };
};

export class TenantJobModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.jobId = data && data.jobId ? data.jobId : undefined;
    this.cron = data && data.cron ? data.cron : "";
    this.tenantJobParameters = data && data.tenantJobParameters ? data.tenantJobParameters : [];
    this.isActive = data && data.isActive ? data.isActive : false;
  };
};

export class VoucherTypeModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.code = data && data.code ? data.code : '';
    this.name = data && data.name ? data.name : '';
    this.erpTypeId = data && data.erpTypeId ? data.erpTypeId : null;
    this.erpTypeKey = data && data.erpTypeKey ? data.erpTypeKey : null;
  };
};
export class GeneralCategoriesModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : 0;
    this.name = data && data.name ? data.name : '';
    this.categoryType = data && data.categoryType ? data.categoryType : '';
    this.categoryCode = data && data.categoryCode ? data.categoryCode : '';
  };
};
export class TypeCodeModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.code = data && data.code ? data.code : '';
    this.name = data && data.name ? data.name : '';
    this.level = data && data.level ? data.level : '';
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : '';
  };
};

export class ErpTypeModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.name = data && data.name ? data.name : '';
    this.description = data && data.description ? data.description : '';
  };
};

export class GeneralRuleModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.incomingCategoryId = data && data.incomingCategoryId ? data.incomingCategoryId : undefined;
    this.outgoingCategoryId = data && data.outgoingCategoryId ? data.outgoingCategoryId : undefined;
    this.incomingCategory = data && data.incomingCategory ? data.incomingCategory : undefined;
    this.outgoingCategory = data && data.outgoingCategory ? data.outgoingCategory : undefined;
    this.generalRuleItems = data && data.generalRuleItems ? data.generalRuleItems : [];
    this.name = data && data.name ? data.name : '';
    this.generalRuleVoucherTypes = data && data.generalRuleVoucherTypes ? data.generalRuleVoucherTypes : [];
    this.transferStatus = data && data.transferStatus ? data.transferStatus : undefined;
    this.priority = data && data.priority !== null ? data.priority : 1;
    this.setManuelPriority = data && data.setManuelPriority ? data.setManuelPriority : null;
  };
};

export class ReproductionRuleModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : 0;
    this.generalRuleId = data && data.generalRuleId ? data.generalRuleId : undefined;
    this.incomingCategoryId = data && data.incomingCategoryId ? data.incomingCategoryId : undefined;
    this.outgoingCategoryId = data && data.outgoingCategoryId ? data.outgoingCategoryId : undefined;
    this.generalRuleItems = data && data.ruleItems ? data.ruleItems : [];
    this.ruleItems = data && data.ruleItems ? data.ruleItems : [];
    this.name = data && data.name ? data.name : '';
    this.transferStatus = data && data.transferStatus ? data.transferStatus : undefined;
    this.priority = data && data.priority !== null ? data.priority : 1;
    this.createdDate = data && data.createdDate ? data.createdDate : null;
    this.updatedDate = data && data.updatedDate ? data.updatedDate : null;
    this.deleted = data && data.deleted ? data.deleted : false;
    this.incomingCategoryName = data && data.incomingCategoryName ? data.incomingCategoryName : '';
    this.outgoingCategoryName = data && data.outgoingCategoryName ? data.outgoingCategoryName : '';
    this.voucherTypeCode = data && data.voucherTypeCode ? data.voucherTypeCode : '';
    this.voucherTypeId = data && data.voucherTypeId ? data.voucherTypeId : 0;
    this.accountPlanCode = data && data.accountPlanCode ? data.accountPlanCode : '';
    this.currentAccountCode = data && data.currentAccountCode ? data.currentAccountCode : '';
    this.bankAccountCode = data && data.bankAccountCode ? data.bankAccountCode : '';
    this.ruleType = data && data.ruleType ? data.ruleType : null;
    this.dynamicSearches = data && data.dynamicSearches ? data.dynamicSearches : null;
    this.newAccountPlan = data && data.newAccountPlan ? data.newAccountPlan : 0;
    this.divisionNumber = data && data.divisionNumber ? data.divisionNumber : null;
    this.departmentNumber = data && data.departmentNumber ? data.departmentNumber : null;
    this.expenseCenter = data && data.expenseCenter ? data.expenseCenter : null;
    this.safeCode = data && data.safeCode ? data.safeCode : null;
    this.rulePropertyConfigs = data && data.rulePropertyConfigs ? data.rulePropertyConfigs : null;
    this.setManuelPriority = data && data.setManuelPriority ? data.setManuelPriority : null;
  };
};

export class GeneralRuleVoucherTypeModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.voucherTypeId = data && data.voucherTypeId ? data.voucherTypeId : undefined;
    this.erpTypeId = data && data.erpTypeId ? data.erpTypeId : undefined;
    this.voucherTypeName = data && data.voucherTypeName ? data.voucherTypeName : '';
    this.erpTypeName = data && data.erpTypeName ? data.erpTypeName : '';
  };
};

export class SyncRuleJobsModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.tenantId = data && data.tenantId ? data.tenantId : null;
    this.firstDate = data && data.firstDate ? data.firstDate : '';
    this.lastDate = data && data.lastDate ? data.lastDate : '';
    this.getCompletedTransaction = data && data.getCompletedTransaction ? data.getCompletedTransaction : false;
    this.jobRulesModel = data && data.jobRulesModel ? data.jobRulesModel : [];
    this.syncRuleStatus = data && data.syncRuleStatus ? data.syncRuleStatus : null;
  };
};

export class BalancesModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.bankAccountId = data && data.bankAccountId ? data.bankAccountId : undefined;
    this.bankEftCode = data && data.bankEftCode ? data.bankEftCode : '';
    this.bankName = data && data.bankName ? data.bankName : null;
    this.branchNumber = data && data.branchNumber ? data.branchNumber : null;
    this.branchName = data && data.branchName ? data.branchName : null;
    this.tenantName = data && data.tenantName ? data.tenantName : null;
    this.accountNumber = data && data.accountNumber ? data.accountNumber : [];
    this.suffix = data && data.suffix ? data.suffix : '';
    this.bankAccountName = data && data.bankAccountName ? data.bankAccountName : null;
    this.iban = data && data.iban ? data.iban : 0;
    this.currencyCode = data && data.currencyCode ? data.currencyCode : '';
    this.balance = data && data.balance ? data.balance : '';
    this.blockageAmount = data && data.blockageAmount ? data.blockageAmount : '';
    this.creditLimit = data && data.creditLimit ? data.creditLimit : '';
    this.availableBalanceWithCredit = data && data.availableBalanceWithCredit ? data.availableBalanceWithCredit : '';
    this.currentBalance = data && data.currentBalance ? data.currentBalance : '';
    this.lastTransactionDate = data && data.lastTransactionDate ? data.lastTransactionDate : '';
  };
};

export class MatchingConditionModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.dynamicPropertyId = data && data.dynamicPropertyId ? data.dynamicPropertyId : 0;
    this.currentAccountSelectedItem = data && data.currentAccountSelectedItem ? data.currentAccountSelectedItem : '';
  };
};

export class GeneralRulesModel {
  constructor(data = null) {
    this.id = data && data.id ? data.id : undefined;
    this.createdDate = data && data.createdDate ? data.createdDate : undefined;
    this.voucherTypeCode = data && data.voucherTypeCode ? data.voucherTypeCode : '';
    this.incomingCategoryId = data && data.incomingCategoryId ? data.incomingCategoryId : undefined;
    this.outgoingCategoryId = data && data.outgoingCategoryId ? data.outgoingCategoryId : undefined;
    this.generalRuleItems = data && data.generalRuleItems ? data.generalRuleItems : [];
    this.name = data && data.name ? data.name : '';
    this.transferStatus = data && data.transferStatus ? data.transferStatus : undefined;
    this.items = data && data.items ? data.items : [];
    this.ruleType = data && data.ruleType ? data.ruleType : 1;
    this.name = data && data.name ? data.name : '';
    this.deleted = data && data.deleted ? data.deleted : '';
    this.enabled = data && data.enabled ? data.enabled : '';
    this.name = data && data.name ? data.name : '';
    this.voucherTypes = data && data.voucherTypes ? data.voucherTypes : [];
    this.priority = data && data.priority ? data.priority : '';
    this.categoryId = data && data.categoryId ? data.categoryId : 1;
    this.accountPlanCode = data && data.accountPlanCode ? data.accountPlanCode : '';
  };
};

export class CustomerModel {
  constructor(data = null) {
    this.id = data?.id;
    this.name = data?.name;
    this.surname = data?.surname;
    this.userCode = data?.userCode;
    this.isActive = data?.isActive || false;
    this.customerRepresentativeId = data?.customerRepresentativeId;
    this.email = data?.email;
    this.phone = data?.phone;
    this.countryCode = data?.countryCode;
    this.passportNo = data?.passportNo;
    this.tckn = data?.tckn;
    this.isForeignNational = data?.isForeignNational || false;
    this.isVerifiedEmail = data?.isVerifiedEmail || false;
    this.isVerifiedPhone = data?.isVerifiedPhone || false;
  };
};