
import React from "react";
import { modules } from 'temp';
import { Card } from "./";
import { useSelector } from "react-redux";
import routes from "plugins/routes";
import _ from 'lodash'

function ModuleCards() {
  const auth = useSelector(({ auth }) => ({ ...auth }));
  const accessRights = auth.data ? auth.data.accessRights : [];
  const userModuleIds = auth.data ? auth.data.modules.map(x => x.name) : [];
  modules.map(x => x.userHasModule = userModuleIds.includes(x.beName));

  const getDefaultRoute = (currentModule) => {
    const _routes = routes.find(x => x.name === currentModule);
    const _subRoutes = _routes.routes || [];
    let dr = "";
    if (accessRights.length) {
      dr = _subRoutes
        .filter(x => x.visible !== false)
        .find(x =>
          !x.access
          ||
          accessRights
            .find(y =>
              typeof x.access === "string" ?
                y.endPoint === x.access
                :
                x.access.includes(y.endPoint)
            )
        )
    }
    return dr;
  }

  return (
    _.orderBy(modules, ['isActive', 'userHasModule', 'priority'], ['desc', 'desc', 'asc'])
      .map(mdl => {
        return <Card {...{ ...mdl, hasModule: userModuleIds.includes(mdl.beName) && !!getDefaultRoute(mdl.path) }} key={mdl.name} />
      }
      )
  )
}


export default ModuleCards;
