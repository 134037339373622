import axios from 'axios';
import buildQuery from 'odata-query';
import React from 'react';
import { Modal, Statistic, notification } from "antd";
import moment from 'moment';
import _ from 'lodash'
import endpoints from 'config/endpoints';
import CryptoJS from 'crypto-js';
import i18n from 'plugins/i18n';
import { ErrorModel } from 'models';
import { isTest } from 'helpers';

let token = localStorage.getItem('token');
if (token) axios.defaults.headers.Authorization = `Bearer ${token}`; let info = localStorage.getItem('userInfo');
if (info) {
  info = JSON.parse(CryptoJS.AES.decrypt(info, "netfinans").toString(CryptoJS.enc.Utf8));
  if (info.userId) axios.defaults.headers.userId = info.userId;
  if (info.tenantId) axios.defaults.headers.tenantId = info.tenantId;
}

axios.defaults.headers.common['Content-Type'] = 'application/json';

const confirm = Modal.confirm;
const Countdown = Statistic.Countdown;
window.logoutTimeout = null;

export class Proxy {
  constructor(parameters = {}, customParams = {}) {
    this.parameters = parameters;
    this.customParams = customParams;
    this.expirationTime = localStorage.getItem('expirationTime');
    this.timeoutTime = this.expirationTime ? (this.expirationTime - 1) * 60000 : 172800000;
  }

  getODataString(parameters) {
    return buildQuery(parameters);
  };

  getParameterString(obj, prefix) {

    var str = [], p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.getParameterString(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  };

  logout = () => {
    localStorage.clear();
    window.location.pathname = '/auth';
  }

  onOk = () => {
    let blur = document.getElementById('blur');
    Modal.destroyAll();
    blur && blur.classList.remove('blur');
    this.parameters = {};
    this.submit('get', endpoints.auth.getTokenInfo, null, true);
  }

  timeout = () => {
    clearTimeout(window.logoutTimeout);
    window.logoutTimeout = setTimeout(() => {
      let blurOverlay = document.getElementById('blur-overlay');
      blurOverlay && blurOverlay.classList.remove('active');
      Modal.destroyAll();
      confirm({
        centered: true,
        className: 'logout-modal',
        wrapClassName: 'wrapped',
        title: 'Uyarı',
        content: <span> İşlem yapmamanız durumunda <Countdown onFinish={this.logout} value={moment().add("seconds", 60)} format="ss" /> saniye sonra oturumunuz sonlandıralacaktır.</span>,
        cancelButtonProps: { className: 'd-none' },
        onOk: this.onOk,

      });
    }, this.timeoutTime || 9999999999);
  }

  submit(requestType, url, data = null, isAuth, headers = {}, options = { errors: { showMessage: false, showNotification: true, hideCodeMessage: false } }) {
    return new Promise((resolve, reject) => {
      if (url === endpoints.auth.login) headers['accept'] = '*/*';
      let str = this.getODataString(this.parameters);
      str = !_.isEqual(this.customParams, {}) ? `${str === '' ? '?' : `${str}&`}${this.getParameterString(this.customParams)}` : str;
      axios[requestType](url + (str !== '' ? `${str}` : ''), data, { headers })
        .then(response => {
          !isAuth && this.timeout();
          resolve({ data: response.data, statusCode: response.status });
        })
        .catch(error => {
          isTest() && console.log("dev env error: ", error)
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.clear();
              window.location.pathname = '/auth';
            }
            else {
              const _error = new ErrorModel(error, error.response);
              errorHandling({ response: _error, ...options.errors })
              reject({ ..._error })
            }
          }
          else {
            const _error = new ErrorModel(error, null);
            errorHandling({ response: _error, ...options.errors })
            reject({ ..._error })
          }
        });

    });
  };

  /* ---------------------------- @GLOBAL PROXIES --------------------------- */
  getAll = (payload) => {
    let endpoint = payload.url, headers = {};
    if (payload.id) endpoint += `/${payload.id}`;
    if (payload.headers) { headers = payload.headers; delete payload.headers };
    return this.submit('get', `${endpoint}`, undefined, undefined, headers, payload.options);
  }

  getSingle = (payload) => {
    let endpoint = payload.url, headers = {};
    if (payload.id) endpoint += `/${payload.id}`;
    if (payload.headers) { headers = payload.headers; delete payload.headers }
    return this.submit('get', `${endpoint}`, undefined, undefined, headers, payload.options);
  }

  post = (payload) => {
    let endpoint = payload.url, headers = {};
    if (payload.id) endpoint += `/${payload.id}`;
    if (payload.headers) { headers = payload.headers; delete payload.headers }
    return this.submit('post', `${endpoint}`, payload.data, undefined, headers, payload.options);
  }

  put = (payload) => {
    let endpoint = payload.url, headers = {};
    if (payload.id || payload.Id) endpoint += `/${payload.id || payload.Id}`;
    if (payload.headers) { headers = payload.headers; delete payload.headers }
    return this.submit('put', `${endpoint}`, payload.data, undefined, headers, payload.options);
  }

  patch = (payload) => {
    let endpoint = payload.url, headers = {};
    if (payload.id || payload.Id) endpoint += `/${payload.id || payload.Id}`;
    headers = { 'Content-Type': 'application/json-patch+json' };
    if (payload.headers) { headers = { ...headers, ...payload.headers }; delete payload.headers }
    return this.submit('patch', `${endpoint}`, payload.data, undefined, headers, payload.options);
  }

  delete = (payload) => {
    let endpoint = payload.url;
    if (payload.id) endpoint += `/${payload.id}`;
    else if (payload.Id) endpoint += `/${payload.Id}`;
    return this.submit('delete', `${endpoint}`, undefined, undefined, undefined, payload.options);
  }
}


const errorHandling = ({ response, showNotification = true, notificationType = "modal", showMessage, hideCodeMessage }) => {
  if (response.statusCode !== 403 && showNotification) {
    let messageBody = '';
    response.messages.forEach((err, i) => {
      const messageItem = (showMessage || err.code === "NotAcceptable" || err.code === "MustToBeInPassword")
        ? hideCodeMessage
          ? err.message
          : `${i18n.t(`msg.${err.code}`, { data: err.data })} - ${err.message}`
        : i18n.t(`msg.${err.code}`, { data: err.data });
      if (response.messages.length > 1)
        messageBody = `${messageBody} <p class="mb-10"> <b>${i + 1}.</b> ${messageItem}<p>`;
      else
        messageBody = messageItem;
    })

    if (notificationType === "notify") {
      notification.error({ message: <div dangerouslySetInnerHTML={{ __html: messageBody }} /> })
    }
    else if (notificationType === "modal") {
      Modal.error({
        title: response.messages.length > 1 ? i18n.t("msg.sorrySomeErrorsOccurred") : i18n.t("msg.sorryAnErrorOccurred"),
        content: <div dangerouslySetInnerHTML={{ __html: messageBody }} />,
        centered: true,
        okText: i18n.t('btn.ok')
      })
    }
  }
}

export default Proxy;