import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import authActions from 'store/actions/auth';
import { formatCurrency, formatDate, formatIBAN } from 'helpers';
import { DataTable, Filter, ItemsCard, CurrencyRates } from 'components/UIComponents';
import { Button, Tooltip, Divider, Switch, Popconfirm, Popover, Icon } from 'antd';
import endpoints from 'config/endpoints';
import { uniq, keys, sortBy, uniqBy, groupBy } from 'lodash'
import i18n from 'plugins/i18n';
import { defaultFilters } from "lib/constants";
import utils from 'lib';
import Balance from './Detail';

class Balances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: defaultFilters.netekstre.Balances(),
      currentBank: null,
      parameters: {
        GetMultiTenant: props.multiTenantBalances,
        IsCumulative: props.cumulativeBalances,
      }
    };
  };

  componentDidMount() {
    const { multiTenantBalances, cumulativeBalances } = this.props;
    this.getBalances();
    utils.common.getTenantBanks();
    utils.common.getBankAccounts();
    utils.common.getCurrencies();
    this.props.getAccountsByCurrency({ getMultiTenant: multiTenantBalances }, { isCumulative: cumulativeBalances });
    utils.common.checkAccessToUpdateOrDelete('BankAccountBalances');
  };

  onSwitchChange = (e, key) => {
    const { parameters } = this.state;

    if (!e) this.filterRef.deleteFields([4]);
    else this.getBalances(e, key);

    if (key === "getMultiTenant") {
      this.props.toggleData({ key: 'multiTenantBalances', data: e });
      this.props.getAccountsByCurrency({ getMultiTenant: e }, { isCumulative: this.props.cumulativeBalances });
      parameters.GetMultiTenant = e;
    }
    else if (key === "isCumulative") {
      this.props.toggleData({ key: 'cumulativeBalances', data: e });
      this.props.getAccountsByCurrency({ getMultiTenant: this.props.multiTenantBalances }, { isCumulative: e });
      parameters.IsCumulative = e;
    }
    this.setState({ parameters });
  }

  getBalances = (e, key) => {
    const { filter } = this.state;
    const { multiTenantBalances, cumulativeBalances } = this.props;
    const _getMultiTenant = (key === 'getMultiTenant' && e) ? e : multiTenantBalances;
    const _isCumulative = (key === 'isCumulative' && e) ? e : cumulativeBalances;
    this.props.getBalances(filter, { getMultiTenant: _getMultiTenant }, { isCumulative: _isCumulative });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.setState({ filter }, () => {
      this.datatable.pageUpdate(1);
      this.getBalances();
    });
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, () => {
      this.getBalances();
    });
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({
      filter
    }, () => this.getBalances());
  }

  onSelect = (value) => this.setState({ currentBank: value });


  getButton = row => {
    const isCurrentTenant = row.tenantId === this.props.auth.data.tenantId
    return (
      isCurrentTenant ?
        <Tooltip placement="bottom" title={i18n.t('btn.accountTransactions')}>
          <Button className="table-button" disabled={!isCurrentTenant} icon="credit-card" size="small" onClick={() => this.onBalanceClick(row)} />
        </Tooltip>
        :
        <Popconfirm title={i18n.t('msg.changingTenant')} onConfirm={() => this.onBalanceClick(row, true)} okText={i18n.t('btn.continue')} cancelText={i18n.t('btn.cancel')}>
          <Button className="table-button" icon="credit-card" size="small" />
        </Popconfirm>
    )
  }

  onBalanceClick = (row, requiredChangeTenant) => {
    const { changeTenant, getAccount, history } = this.props;
    if (requiredChangeTenant)
      changeTenant(row.tenantId, () =>
        getAccount(() =>
          history.push({ pathname: '/netekstre/transactions', state: row })
        )
      );
    else
      history.push({ pathname: '/netekstre/transactions', state: row })
  };


  getBalanceTypeData = (row, field) => {
    if (row[field] === null)
      return (
        <Tooltip placement="bottomRight" title={i18n.t('msg.bankNotSharingThisData')}> 
          <div>-</div>
        </Tooltip>
      )
    return formatCurrency({ data: row[field], currencyCode: row.currencyCode, withColor: true })
  }

  render() {
    const { currentBank, parameters, filter } = this.state;
    const { balances, tenantBanks, bankAccounts, currencies, currencyBalances, auth, multiTenantBalances, cumulativeBalances } = this.props;
    const cumulativeCurrencies = currencyBalances.single.data ? uniq(currencyBalances.single.data.map(x => x.currencyCode)) : [];
    const _accounts = bankAccounts.data ? (currentBank && currentBank.length) ? bankAccounts.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : bankAccounts.data : [];
    const filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'bankEftCode', name: (bankData) => utils.netekstre.getBankName({ bankData }), keys: ['bankEftCode'], showAll: true, callback: this.onSelect, clear: ['1'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.accountName'), type: 'select', search: true, options: _accounts, value: 'id', name: 'name', keys: ['id'], uniqueKey: '#accountName', multiple: true, guid: true },
      { label: i18n.t('lbl.currencyCode'), type: 'select', options: currencies.data, value: 'currencyCode', name: 'currencyName', keys: ['currencycode'], uniqueKey: '#currencyCode', multiple: true },
      { label: i18n.t('lbl.search'), type: 'input', placeholder: i18n.t('lbl.search'), keys: ['accountNumber', 'branchNumber', 'iban', 'branchName'], contains: true, uniqueKey: '#search' }
    ];
    const columns = [
      { render: this.getButton, toggle: false, sort: false, notIncluded: true, key: 'id' },
      { label: i18n.t('lbl.bank'), key: 'bankName', render: (row) => utils.common.getBankName({ data: row, listField: 'bankEftCode', listKeys: 'tenantBanks' }) },
      { label: i18n.t('lbl.branchNumber'), key: 'branchNumber' },
      { label: i18n.t('lbl.branchName'), key: 'branchName' },
      { label: i18n.t('lbl.accountNumber'), key: 'accountNumber' },
      { label: i18n.t('lbl.suffix'), key: 'suffix' },
      { label: i18n.t('lbl.accountName'), key: 'bankAccountName' },
      { label: i18n.t('lbl.balance'), key: 'balance', render: row => this.getBalanceTypeData(row, 'balance'), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.blockageAmount'), key: 'blockageAmount', render: row => this.getBalanceTypeData(row, 'blockageAmount'), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.creditLimit'), key: 'creditLimit', render: row => this.getBalanceTypeData(row, 'creditLimit'), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.availableBalanceWithCredit'), key: 'availableBalanceWithCredit', render: row => this.getBalanceTypeData(row, 'availableBalanceWithCredit'), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.currentBalance'), key: 'currentBalance', render: row => this.getBalanceTypeData(row, 'currentBalance'), tdClass: 'text-right', thClass: 'text-right' },
      { label: i18n.t('lbl.lastTransactionDate'), key: 'lastTransactionDate', render: (row) => formatDate(row.lastTransactionDate) },
      { label: i18n.t('lbl.lastRequestDate'), key: 'lastSuccessfulRequestString' },
      { label: i18n.t('lbl.transactionCount'), key: 'lastSuccessfulTransactionCount', tdClass: 'text-center' },
      { label: i18n.t('lbl.iban'), key: 'iban', render: (row) => formatIBAN(row.iban) },
    ];

    let arr = [];
    if (cumulativeBalances && balances.single.data && balances.single.data.length) balances.single.data.map(x => x.exBalance && arr.push(...keys(x.exBalance)));
    arr = sortBy(uniqBy(arr))
    arr.forEach(x => {
      columns.insert(7, {
        checkField: false,
        tdClass: 'text-right',
        sort: false,
        label: i18n.t(`lbl.cumulativeBalance`, { currency: x.toUpperCase() }),
        key: x,
        render: ({ exBalance }) => (
          formatCurrency({ data: exBalance[keys(exBalance).find(y => x === y)], currencyCode: x.toUpperCase(), withColor: true }) || '-'
        )
      })
    });

    multiTenantBalances && columns.insert(1, { label: i18n.t('lbl.tenantName'), key: 'tenantName' })
    multiTenantBalances && filters.push({ label: i18n.t('lbl.tenantName'), type: 'select', options: auth.data.tenants, value: 'id', name: 'name', keys: ['tenantId'], uniqueKey: '#tenantName', multiple: true, guid: true })


    return (
      <div className="page-content">
        <Divider className="mb-30 mt-0">
          <Popover overlayClassName="cog-content" placement="left" content={
            <>
              {
                auth.data && auth.data.tenants && auth.data.tenants.filter(x => x.id !== auth.data.tenantId).length > 0 &&
                <small>
                  {i18n.t('btn.allFirm')}
                  <Switch className="custom-small" checked={multiTenantBalances} onChange={(e) => this.onSwitchChange(e, 'getMultiTenant')} />
                </small>
              }
              <small>
                {i18n.t('btn.allAssets')}
                <Switch className="custom-small" checked={cumulativeBalances} onChange={(e) => this.onSwitchChange(e, 'isCumulative')} />
              </small>
            </>
          }>
            <Icon className="home-cog page-cog" type="setting" />
          </Popover>
        </Divider>
        <CurrencyRates />
        <Filter filters={filters} onFilter={this.onFilter} wrappedComponentRef={el => this.filterRef = el} />
        <DataTable
          wrappedComponentRef={el => this.datatable = el}
          className="mb-20"
          onPageChange={this.onPageChange}
          access="BankAccountBalances"
          history={this.props.history}
          onSort={this.onSort}
          columns={columns}
          data={balances.single.data}
          getData={this.getBalances}
          loading={balances.single.loading}
          excel={{ url: "ExportBankAccountBalances", parameters, filter: { filter: filter.filter, orderBy: filter.orderBy }, module: "nte" }}
          title={i18n.t('route.netekstre.balances')}
          count={balances.single.count}
          Component={Balance}
          dialogTitle={i18n.t('lbl.balance')}
        />
        <ItemsCard
          tenants={auth.data.tenants}
          multiTenant={multiTenantBalances}
          isCumulative={cumulativeBalances}
          title={i18n.t('lbl.currencyBalances')}
          data={currencyBalances.single.data}
          items={currencyBalances.single.data ? groupBy(currencyBalances.single.data, 'bankName') : {}}
          keys={cumulativeCurrencies}
          loading={currencyBalances.single.loading}
          label={(row) => formatCurrency({ data: row.balance, currencyCode: row.currencyCode, withColor: true })}
          img="bankName"
        />
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  auth,
  multiTenantBalances: common.multiTenantBalances,
  cumulativeBalances: common.cumulativeBalances,
  tenantBanks: common.filter.tenantBanks,
  bankAccounts: common.filter.bankAccounts,
  currencies: common.filter.currencies,

  balances: netekstre.balances,
  currencyBalances: netekstre.currencyBalances,
});
const mapDispatchToProps = (dispatch) => ({
  getAccount: (callback) => dispatch(authActions.getAccount(callback)),
  changeTenant: (id, callback) => dispatch(commonActions.changeTenant(id, callback)),
  getBalances: (filter, data, parameters) => dispatch(netekstreActions.getWithPost({ url: endpoints.nte.bankAccountBalances, key: 'balances', data, filter, parameters })),
  getAccountsByCurrency: (data, parameters) => dispatch(netekstreActions.getWithPost({ url: endpoints.nte.bankAccountBalancesGetCumulative, key: 'currencyBalances', data, parameters })),
  updateFilter: (fields, filter, key) => dispatch(commonActions.updateFilter({ fields, filter, key })),
  toggleData: (data) => dispatch(commonActions.toggleData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Balances);