import React, { Component } from 'react';
import endpoints from 'config/endpoints';
import { connect } from 'react-redux';
import actions from 'store/actions/netahsilat';
import i18n from 'plugins/i18n';
import { Card, Switch, Icon, Button, Modal } from 'antd';
import { Loading } from 'components/UIComponents';
import FieldsTable from "./FieldsTable";
import { convertBoolean } from 'helpers';
import ReactQuill from 'react-quill';
import { textEditorOptions } from 'lib/constants';


class PaymentParameters extends Component {
  state = { data: {}, filled: false }

  static getDerivedStateFromProps({ paymentParameters }, { filled }) {
    if (paymentParameters.data?.length && !filled) {
      const data = {};
      paymentParameters.data[0].values.forEach(x => { data[x.key] = { value: x.value, controlType: x.controlType } })
      return { data, filled: true };
    }
    return null;
  };

  componentDidMount() {
    this.props.getPaymentParameters({ filter: { name: 'PaymentPagesSetting' } });
  }

  update = (key, value) => {
    const { data } = this.state;
    data[key].value = value;
    this.setState({ data });
  };

  save = () => {
    const { data } = this.state;
    data.PaymentFormTopDescription = { value: this.PaymentFormTopDescription?.state.value, controlType: 8 };
    data.AgreementText = { value: this.AgreementText?.state.value, controlType: 8 };
    const _data = [{
      name: 'PaymentPagesSetting',
      values: Object.keys(data).map(key => ({ key, value: data[key].value?.toString(), controlType: data[key].controlType }))
    }]

    this.props.saveData(_data, (response) => {
      if (response) Modal.success({ title: i18n.t('msg.successful'), content: i18n.t('msg.successfullySavedPaymentParameters') })
    });
  }

  render() {
    const { paymentParameters } = this.props;
    const { data } = this.state;

    // this.PaymentFormTopDescription?.state.value;

    return (
      <div className="page-content">
        <Card bordered={false} className="no-border" title={<span>{i18n.t('lbl.paymentSettings')}</span>}>
          <Loading loading={paymentParameters.loading}>
            <div className="pr-35 pl-35">
              <FieldsTable {...{
                data,
                onChange: this.update,
                className: 'mb-40',
                columns: [
                  i18n.t('lbl.fieldName'),
                  i18n.t('lbl.isDisplay'),
                  i18n.t('lbl.isRequired')
                ],
                rows: [
                  { label: i18n.t('lbl.tckn'), displayKey: 'IsShowTCKN', requiredKey: 'IsForcedTCKN' },
                  { label: i18n.t('lbl.description'), displayKey: 'IsShowDescription', requiredKey: 'IsForcedDescription' },
                  { label: i18n.t('lbl.phone'), displayKey: 'IsShowPhone', requiredKey: 'IsForcedPhone' },
                  { label: i18n.t('lbl.email'), displayKey: 'IsShowEmail', requiredKey: 'IsForcedEmail' },
                  { label: i18n.t('lbl.virtualKeyboard'), displayKey: 'IsShowVirtualKeyboard' },
                ]
              }} />

              <div className="mb-40">
                <h4 className="text-semibold">{i18n.t('lbl.paymentPageDescription')}</h4>
                <Switch
                  checkedChildren={<Icon type="eye" />}
                  unCheckedChildren={<Icon type="eye-invisible" />}
                  checked={convertBoolean(data.IsShowPaymentForm?.value)}
                  onChange={checked => this.update('IsShowPaymentForm', checked)}
                />
                <span className="ml-10" >{i18n.t('lbl.isDisplayPaymentForm')}</span>
                <ReactQuill
                  className="mt-15"
                  ref={el => this.PaymentFormTopDescription = el}
                  modules={textEditorOptions}
                  defaultValue={data.PaymentFormTopDescription?.value}
                />
              </div>


              <div className="mb-40">
                <h4 className="text-semibold">{i18n.t('lbl.paymentPageAgreement')}</h4>
                <Switch
                  checkedChildren={<Icon type="eye" />}
                  unCheckedChildren={<Icon type="eye-invisible" />}
                  checked={convertBoolean(data.IsShowAgreement?.value)}
                  onChange={checked => this.update('IsShowAgreement', checked)}
                />
                <span className="ml-10" >{i18n.t('lbl.isDisplayPaymentForm')}</span>


                <Switch
                  className="ml-20"
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={convertBoolean(data.IsForcedAgreement?.value)}
                  onChange={checked => this.update('IsForcedAgreement', checked)}
                />
                <span className="ml-10" >{i18n.t('lbl.required')}</span>

                <ReactQuill
                  className="mt-15"
                  ref={el => this.AgreementText = el}
                  modules={textEditorOptions}
                  defaultValue={data.AgreementText?.value}
                />
              </div>

              <div className="pull-right">
                <Button icon="save" className="success #save btn-collapse" loading={paymentParameters.saving} onClick={this.save}>
                  {i18n.t('btn.save')}
                </Button>
              </div>

            </div>
          </Loading>
        </Card>
      </div>
    )
  }
}
const mapStateToProps = ({ netahsilat }) => ({
  paymentParameters: netahsilat.paymentParameters.single
});
const mapDispatchToProps = (dispatch) => ({
  saveData: (data, callback) => dispatch(actions.post({ data, url: endpoints.netahsilat.webSiteParameterCreateOrUpdate, key: 'paymentParameters' }, callback)),
  getPaymentParameters: (filter) => dispatch(actions.get({ filter, url: endpoints.netahsilat.webSiteParameter, key: 'paymentParameters', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentParameters);
