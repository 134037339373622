export const GET_ALL_NETEKSTRE = "GET_ALL_NETEKSTRE";
export const GET_ALL_NETEKSTRE_SUCCESS = "GET_ALL_NETEKSTRE_SUCCESS";
export const GET_ALL_NETEKSTRE_FAILURE = "GET_ALL_NETEKSTRE_FAILURE";
export const GET_NETEKSTRE = "GET_NETEKSTRE";
export const GET_NETEKSTRE_SUCCESS = "GET_NETEKSTRE_SUCCESS";
export const GET_NETEKSTRE_FAILURE = "GET_NETEKSTRE_FAILURE";
export const POST_NETEKSTRE = "POST_NETEKSTRE";
export const POST_NETEKSTRE_SUCCESS = "POST_NETEKSTRE_SUCCESS";
export const POST_NETEKSTRE_FAILURE = "POST_NETEKSTRE_FAILURE";
export const PUT_NETEKSTRE = "PUT_NETEKSTRE";
export const PUT_NETEKSTRE_SUCCESS = "PUT_NETEKSTRE_SUCCESS";
export const PUT_NETEKSTRE_FAILURE = "PUT_NETEKSTRE_FAILURE";
export const DELETE_NETEKSTRE = "DELETE_NETEKSTRE";
export const DELETE_NETEKSTRE_SUCCESS = "DELETE_NETEKSTRE_SUCCESS";
export const DELETE_NETEKSTRE_FAILURE = "DELETE_NETEKSTRE_FAILURE";
export const PATCH_NETEKSTRE = "PATCH_NETEKSTRE";
export const PATCH_NETEKSTRE_SUCCESS = "PATCH_NETEKSTRE_SUCCESS";
export const PATCH_NETEKSTRE_FAILURE = "PATCH_NETEKSTRE_FAILURE";
export const GET_FILTER_NETEKSTRE = "GET_FILTER_NETEKSTRE";
export const GET_FILTER_NETEKSTRE_SUCCESS = "GET_FILTER_NETEKSTRE_SUCCESS";
export const GET_FILTER_NETEKSTRE_FAILURE = "GET_FILTER_NETEKSTRE_FAILURE";
export const CLEAR_STATE_NETEKSTRE = "CLEAR_STATE_NETEKSTRE";
export const GET_WITH_POST_NETEKSTRE = "GET_WITH_POST_NETEKSTRE";

const actions = {
  //#region [GET]
  getAll: (payload, callback) => {
    return ({ type: GET_ALL_NETEKSTRE, payload, callback })
  },
  getAllSuccess: (payload) => {
    return ({ type: GET_ALL_NETEKSTRE_SUCCESS, payload })
  },
  getAllFailure: (payload) => {
    return ({ type: GET_ALL_NETEKSTRE_FAILURE, payload })
  },
  get: (payload, callback) => {
    return ({ type: GET_NETEKSTRE, payload, callback })
  },
  getSuccess: (payload) => {
    return ({ type: GET_NETEKSTRE_SUCCESS, payload })
  },
  getFailure: (payload) => {
    return ({ type: GET_NETEKSTRE_FAILURE, payload })
  },
  getFilter: (payload, callback) => {
    return ({ type: GET_FILTER_NETEKSTRE, payload, callback })
  },
  getFilterSuccess: (payload, callback) => {
    return ({ type: GET_FILTER_NETEKSTRE_SUCCESS, payload, callback })
  },
  getFilterFailure: (payload, callback) => {
    return ({ type: GET_FILTER_NETEKSTRE_FAILURE, payload, callback })
  },
  //#endregion

  //#region [POST]
  post: (payload, callback) => {
    return ({ type: POST_NETEKSTRE, payload, callback })
  },
  postSuccess: (payload) => {
    return ({ type: POST_NETEKSTRE_SUCCESS, payload })
  },
  postFailure: (payload) => {
    return ({ type: POST_NETEKSTRE_FAILURE, payload })
  },

  getWithPost: (payload) => {
    return ({ type: GET_WITH_POST_NETEKSTRE, payload })
  },
  //#endregion

  //#region [PUT]
  put: (payload, callback) => {
    return ({ type: PUT_NETEKSTRE, payload, callback })
  },
  putSuccess: (payload) => {
    return ({ type: PUT_NETEKSTRE_SUCCESS, payload })
  },
  putFailure: (payload) => {
    return ({ type: PUT_NETEKSTRE_FAILURE, payload })
  },
  //#endregion

  //#region [DELETE]
  delete: (payload, callback) => {
    return ({ type: DELETE_NETEKSTRE, payload, callback })
  },
  deleteSuccess: (payload) => {
    return ({ type: DELETE_NETEKSTRE_SUCCESS, payload })
  },
  deleteFailure: (payload) => {
    return ({ type: DELETE_NETEKSTRE_FAILURE, payload })
  },
  //#endregion

  //#region [PATCH]
  patch: (payload, callback) => {
    return ({ type: PATCH_NETEKSTRE, payload, callback })
  },
  patchSuccess: (payload) => {
    return ({ type: PATCH_NETEKSTRE_SUCCESS, payload })
  },
  patchFailure: (payload) => {
    return ({ type: PATCH_NETEKSTRE_FAILURE, payload })
  },
  //#endregion

  //#region [CUSTOM]
  clearState: (key, clearList) => {
    return ({ type: CLEAR_STATE_NETEKSTRE, key, clearList })
  },
  //#endregion
}

export default actions;