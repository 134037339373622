import React from 'react';
import { Tooltip as AntTooltip } from 'antd';

const Tooltip = ({ maxLength, children, title, placement }) => {
  return (
    title.length > maxLength ?
      <AntTooltip placement={placement} title={title}>
        {children}
      </AntTooltip>
      :
      children
  )
}

export default Tooltip;