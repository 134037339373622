import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Divider } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { CreatePaymentModel } from 'models';
import actions from 'store/actions/tos';
// import netekstreActions from 'store/actions/netekstre';
import endpoints from 'config/endpoints';
import { Loading } from 'components/UIComponents';
import _ from 'lodash';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import AddNewAccount from './AddNewAccount'

class CreatePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new CreatePaymentModel(),
      initialState: new CreatePaymentModel(),
      addNewAccount: false
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.orderDetails.single.data && !state.data.Id) {
      const data = new CreatePaymentModel({ ...props.orderDetails.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.orderDetails.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    this.getRecipientAccounts();
    if (this.id) this.props.getOrderDetails(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  getRecipientAccounts = () => {
    let filter = { expand: 'Bank' }
    this.props.getRecipientAccounts(filter);
  };

  update = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  onSearch = e => {
    const _this = this;
    const filter = { filter: { or: { Name: { contains: e }, AccountNumber: { contains: e }, BranchName: { contains: e }, BranchNumber: { contains: e }, 'Bank/Name': { contains: e }, 'Bank/EftCode': { contains: e } } } }
    if (e && e !== '' && e.length > 2) _this.props.getRecipientAccounts(filter);
    else _this.props.getRecipientAccounts()
  }

  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, saveData, form } = this.props;
    const data = _.cloneDeep(this.state.data)


    form.validateFields(error => {
      if (!error) {
        data.Amount = parseFloat(data.Amount);
        const method = this.id ? httpMethods.put : httpMethods.post;

        if (this.addNewAccountForm && this.state.addNewAccount) {
          this.addNewAccountForm.save()
            .then(id => {
              data.OpponentAccountId = id;
              saveData(data, method, () => {
                this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
              })
            })
        }
        else {
          saveData(data, method, () => {
            this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
          })
        }
      }
    });
  };

  renderDialogButtons = () => {
    let { orderDetails, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={orderDetails.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={orderDetails.single.loading} form="orderDetails" htmlType="submit" className="success #save" icon="save" loading={orderDetails.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      orderDetails.single.saving,
      orderDetails.single.loading
    )
  }

  getRecipientAccountName = account => {
    return account ? `${account.Name || ''}, ${account.AccountNumber || ''}, ${account.Bank ? account.Bank.Name : ''}, ${account.BranchName || ''}, ${account.BranchNumber || ''}` : ''
  }

  render() {
    const { loading, data, addNewAccount } = this.state;
    const { recipientAccounts, form } = this.props;
    const { getFieldDecorator } = form;
    this.renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={loading}>
          <Divider>{i18n.t('lbl.customerBankAccountDetails')}</Divider>
          {
            addNewAccount &&
            <Row gutter={20} className="fadeInComponent">
              <Col xs={24} md={24} className="text-right">
                <Form.Item>
                  <Button onClick={() => this.setState({ addNewAccount: false })} icon="arrow-left">Var olan hesaplardan seç</Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <AddNewAccount
                  wrappedComponentRef={el => this.addNewAccountForm = el}
                />
              </Col>
            </Row>
          }
          <Form onSubmit={this.save} id="orderDetails">
            {
              !addNewAccount &&
              <Row className="fadeInComponent">
                <Col xs={24} sm={24}>
                  <Form.Item>
                    {getFieldDecorator('OpponentAccountId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.OpponentAccountId
                    })(
                      <Select
                        loading={recipientAccounts.loading}
                        className="#customerBankAccount"
                        label={i18n.t('lbl.customerBankAccount')}
                        options={recipientAccounts.data}
                        optVal="Id"
                        showSearch
                        onSearch={this.onSearch}
                        optName={this.getRecipientAccountName}
                        onChange={(e) => this.update('OpponentAccountId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} className="text-right">
                  <Button onClick={() => this.setState({ addNewAccount: true })} icon="arrow-right">Yeni Hesap Ekle</Button>
                </Col>
              </Row>
            }

            <Divider>{i18n.t('lbl.paymentDetails')}</Divider>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('Amount', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.Amount.toString()
                  })(
                    <Input type="amount" className="#Amount" label={i18n.t('lbl.amount')} onChange={(e) => this.update('Amount', e)} />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  {getFieldDecorator('Description', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.Description
                  })(
                    <Input className="#Description" label={i18n.t('lbl.description')} onChange={(e) => this.update('Description', e)} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ tos }) => ({
  orderDetails: tos.orderDetails,
  recipientAccounts: tos.filter.recipientAccounts
});
const mapDispatchToProps = (dispatch) => ({
  getOrderDetails: (id, callback) => dispatch(actions.get({ url: endpoints.tos.tosOrderDetail, key: 'orderDetails', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.tos.tosOrderDetail, key: 'orderDetails', data }, callback)),
  getRecipientAccounts: (filter) => dispatch(actions.getFilter({ filter: { ...filter, expand: 'Bank' }, url: endpoints.tos.recipientAccounts, key: 'recipientAccounts', isNewODataStructure: true })),
  clearState: () => dispatch(actions.clearState("orderDetails"))
});
const CreatePaymentForm = Form.create()(CreatePayment);
export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentForm);