import React from 'react'
import { SignatoryInfo, TenantInfo, ContractInfo, PasswordSettings } from "./";
import { FadeIn } from 'components/UIComponents';
import Result from './Result';

const ContentViewer = ({ step, onChange, parameters }) => {
  const stepComponents = {
    0: SignatoryInfo,
    1: TenantInfo,
    2: ContractInfo,
    3: PasswordSettings,
    
    4: Result
  }

  const Component = stepComponents[step];
  return (
    <FadeIn>
      <Component {...{ onChange, parameters }} />
    </FadeIn>
  )
}

export default ContentViewer;
