import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/dbs';
import { ActionButtons, DataTable, Filter, FadeIn, CheckAccessRight } from 'components/UIComponents';
import { defaultFilters } from "lib/constants";
import endpoints from 'config/endpoints';
import { formatDate, formatCurrency } from "helpers";
import utils from 'lib';
import i18n from 'plugins/i18n';
import Detail from './DeleteDetail';

class InstructionLists extends Component {
  state = {
    filter: defaultFilters.dbs.InstructionLists()
  };

  componentDidMount() {
    utils.dbs.getTenantBanks('DbsAccounts')
    this.getInstructionLists();
    this.props.getOrderStatus({ filter: { OperationStatusTypeId: { eq: 2 } } });
    utils.common.checkAccessToUpdateOrDelete('DbsOrder')
  };

  getInstructionLists = () => {
    const { filter } = this.state;
    this.props.getInstructionLists(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getInstructionLists);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getInstructionLists);
  }

  onFilter = (newFilter) => {
    const { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);

    this.setState({ filter }, this.getInstructionLists);
  };

  render() {
    const { instructionLists, tenantBanks, orderStatus } = this.props;
    const filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: tenantBanks.data, value: 'Code', name: (bankData) => utils.dbs.getBankName({ bankData }), keys: ['BankCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('dbs.orderStatus'), type: 'select', search: true, options: orderStatus.data, value: 'Id', name: 'Name', keys: ['OperationStatusId'], uniqueKey: '#fileStatus', multiple: true },
      { label: i18n.t('lbl.description'), type: 'input', keys: ['Description'], contains: true, uniqueKey: '#description' },
      { label: i18n.t('dbs.orderCreatedDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['InsertedTime'], uniqueKey: '#createdDate', allowClear: true },
      { label: i18n.t('dbs.orderCreatedDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['InsertedTime'], uniqueKey: '#createdDate', allowClear: true },
      { label: i18n.t('dbs.amount', { add: i18n.t('lbl.min') }), type: 'number', operator: 'ge', keys: ['TotalAmount'], uniqueKey: '#minAmount' },
      { label: i18n.t('dbs.amount', { add: i18n.t('lbl.max') }), type: 'number', operator: 'le', keys: ['TotalAmount'], uniqueKey: '#maxAmount' },
    ];
    const columns = [
      { render: (row) => <ActionButtons url="DbsOrder" editUrl item={row} hasDelete getDatas={this.getInstructionLists} openDialog={this.datatable && this.datatable.openDialog} />, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('dbs.bankName'), key: 'BankCode', render: (data) => utils.dbs.getBankName({ data }) },
      { label: i18n.t('dbs.orderCreatedDate'), key: 'InsertedTime', render: (row) => formatDate(row.InsertedTime, undefined, undefined, undefined, false) },
      { label: i18n.t('dbs.orderStatus'), key: 'OperationStatus/Name', checkField: false },
      { label: i18n.t('dbs.piece'), key: 'TotalCount' },
      { label: i18n.t('dbs.amount'), key: 'TotalAmount', render: ({ TotalAmount, CurrencyCode }) => formatCurrency({ data: TotalAmount, currencyCode: CurrencyCode, withSign: false }) },
      { label: i18n.t('dbs.description'), key: 'Description' },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...instructionLists, single: instructionLists.list } }}>
            <Filter filters={filters} onFilter={this.onFilter} />
            <DataTable
              count={instructionLists.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="DbsOrder"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={instructionLists.list.data}
              loading={instructionLists.list.loading}
              title={i18n.t('route.dbs.instructionLists')}
              getData={this.getInstructionLists}
              Component={Detail}
              dialogTitle={i18n.t('lbl.order')}
              usePage="dbs/order"
              deleteOptions={{ stateKey: "instructionLists", url: "DbsOrder" }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ dbs }) => ({
  instructionLists: dbs.instructionLists,
  tenantBanks: dbs.filter.tenantBanks,
  orderStatus: dbs.filter.orderStatus,
});
const mapDispatchToProps = (dispatch) => ({
  getInstructionLists: (filter) => dispatch(actions.getAll({ filter, url: endpoints.dbs.dbsOrder, key: 'instructionLists' })),
  getOrderStatus: (filter) => dispatch(actions.getFilter({ filter, url: endpoints.dbs.operationStatus, key: 'orderStatus', isNewODataStructure: true })),
});
export default connect(mapStateToProps, mapDispatchToProps)(InstructionLists);