import React from 'react'
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/dbs';
import _ from 'lodash';


export default class DbsUtils {

  static getBankName({ data = null, dataField = 'BankCode', listKeys = 'tenantBanks', listField = 'Code', bankData = null }) {
    let list, bank;
    if (!bankData) {
      list = _.get(store.getState().dbs.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === _.get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.LogoSmall} className="bank-icon" alt="" /> {bank.Name}</> : '';
  }

  static getTenantCurrencies() {
    const filter = {
      filter: { AccountCurrencies: { any: { TenantId: { type: 'guid', value: store.getState().auth.data.tenantId } } } }
    }
    dispatchers.getTenantCurrencies(filter);
  }

  static getCurrencies() {
    dispatchers.getTenantCurrencies();
  }

  static getTenantBanks(field, parameters) {
    const filter = {
      orderBy: ['Name asc'],
      filter: parameters ? undefined : field ? { [field]: { any: { TenantId: { type: 'guid', value: store.getState().auth.data.tenantId } } } } : undefined
    }
    dispatchers.getTenantBanks(filter, parameters);
  }

  static getDbsBanks(field, parameters) {
    const filter = {
      orderBy: ['Name asc'],
      filter: parameters ? undefined : field ? { [field]: { any: { TenantId: { type: 'guid', value: store.getState().auth.data.tenantId } } } } : undefined
    }
    dispatchers.getDbsBanks(filter, parameters);
  }

  static clearFilter(key) {
    dispatchers.clearFilter(key);
  }

  static clearState(key) {
    dispatchers.clearState(key);
  }

}

const dispatchers = {
  getTenantCurrencies: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.dbs.tenantCurrencies, key: 'tenantCurrencies', isNewODataStructure: true })),
  getTenantBanks: (filter, parameters) => store.dispatch(actions.getFilter({ filter, url: endpoints.dbs.bank, key: 'tenantBanks', isNewODataStructure: true, parameters })),
  getDbsBanks: (filter, parameters) => store.dispatch(actions.getFilter({ filter, url: endpoints.dbs.dbsBanks, key: 'dbsBanks', isNewODataStructure: true, parameters })),
  clearFilter: (key) => store.dispatch(actions.clearFilter(key)),
  clearState: (key) => store.dispatch(actions.clearState(key))
}
