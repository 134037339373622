import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Tooltip, Modal, notification, Checkbox, Icon } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { GeneralRulesModel, GeneralRuleVoucherTypeModel, ReproductionRuleModel } from 'models';
import { DataTable, Loading, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import { regexTypes, categoryTypes as categoryTypesEnum, regexFilters } from 'lib/constants';
import i18n from 'plugins/i18n';

class Rule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      visible: false,
      data: props.reproductionRule ? new ReproductionRuleModel() : new GeneralRulesModel(),
      InitialState: props.reproductionRule ? new ReproductionRuleModel() : new GeneralRulesModel(),
      currentCodes: [],
      currentItem: {
        regexType: null,
        regexFilter: null,
        regexPattern: ''
      },
      index: null,
      planCodes: [],
      accountCodes: [],
      confirmModal: null
    };
    this.id = props.id;
    this.searchTimeoutCurrentCode = null;
    this.searchTimeoutPlanCode = null;
  };

  componentDidMount() {
    this.props.getCategoryTypes({ filter: { or: [{ ParentId: { ne: null } }, { CategoryType: 0 }] } });
    this.props.getRegexFields();
    this.props.getBankAccounts();

    if (this.id) this.props.getRules(this.id, (response) => {
      if (response) {
        if (response.accountPlanCode && response.accountPlanCode !== '') this.planCodeSearch(response.accountPlanCode, true);
        if (response.currentAccountCode && response.currentAccountCode !== '') this.accountCodeSearch(response.currentAccountCode, true);
      }
    })
    else {
      if (!this.props.reproductionRule)
        this.props.getDynamicProperties((response) => {
          this.setState({ loading: false });
          if (response) this.update('rulePropertyConfigs', response);
        });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.rules.single.data && (props.reproductionRule ? !state.data.generalRuleId : !state.data.id)) { //! change
      const data = props.reproductionRule ? new ReproductionRuleModel(props.rules.single.data) : new GeneralRulesModel({ ...props.rules.single.data });
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.rules.single.saving === false && props.rules.single.error && !props.dialogClosing) {
      const { confirmModal } = state;
      confirmModal.update({ cancelButtonProps: { disabled: false }, okButtonProps: { loading: false, disabled: true }, cancelText: i18n.t('btn.close'), })
      return { confirmModal }
    }
    if (props.dialogClosing && !props.rules.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  planCodeSearch = (e) => {
    this.props.getPlanCodes(e, (response) => {
      this.setState({ planCodes: response })
    });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  update = (key, value) => {
    const { data } = this.state;

    if (!value && (key === 'currentAccountCode' || key === 'accountPlanCode')) value = '';

    this.setState({ data: { ...data, [key]: value } });
    if (key === 'currentAccountCode' && !value) this.accountCodeSearch('');
    if (key === 'accountPlanCode' && !value) this.planCodeSearch('');
  };

  updateItem = (key, value) => {
    const { currentItem } = this.state;
    currentItem[key] = value;
    if (key === 'regexType') {
      currentItem.regexFilter = null;
      currentItem.regexPattern = '';
    };
    this.setState({ currentItem });
  };

  save = (e) => {
    e.preventDefault();
    const { data, InitialState } = this.state;
    const { reproductionRule } = this.props;
    let { confirmModal } = this.state;
    let _data = reproductionRule ? new ReproductionRuleModel(_.cloneDeep({ ...data, ruleItems: data.generalRuleItems })) : new GeneralRulesModel(_.cloneDeep(data));

    const dataIsValid = this.validateData(_data, InitialState);

    if (dataIsValid) {
      _data = this.prepareDataToSave(_data);

      confirmModal = Modal.confirm();
      confirmModal.update({
        title: i18n.t('msg.ruleIsUpdated'),
        content: i18n.t('msg.updateWarning'),
        okText: i18n.t('btn.continue'),
        cancelText: i18n.t('btn.deactivate'),
        maskClosable: true,
        okButtonProps: {
          onClick: () => {
            delete _data.id;
            confirmModal.update({ cancelButtonProps: { disabled: true }, okButtonProps: { loading: true } })
            this.saveData(_data, () => confirmModal.destroy())
          }
        },
        cancelButtonProps: {
          type: 'default',
          onClick: () => {
            delete _data.id;
            const id = [_data.generalRuleId];
            confirmModal.update({ cancelButtonProps: { loading: true }, okButtonProps: { disabled: true } });
            this.saveData(_data, () => {
              this.props.singleToggleData(id, () => confirmModal.destroy())
            })
          }
        }
      })
      this.setState({ confirmModal })
    }
  };

  validateData = (data, originalData) => {
    const { form, reproductionRule } = this.props;
    let isValid = false;
    form.validateFields(error => {
      if (!error && (!data.setManuelPriority || (!!data.setManuelPriority && data.priority))) {
        if (data.generalRuleItems.length <= 0)
          notification.error({ message: i18n.t('msg.noRuleItems') });
        else if (reproductionRule && _.isEqual(data, originalData))
          notification.error({ message: i18n.t('msg.customRuleIsEqualToGeneralRule') });
        else
          isValid = true;
      }
    })
    return isValid;
  }

  prepareDataToSave = (_data) => {
    const { planCodes } = this.state;
    if (_data.accountPlanCode !== '' && !planCodes.find(x => x.Code === _data.accountPlanCode).id)
      _data.newAccountPlan = 1;
    _data.priority = _data.priority || null;
    return _data;
  }

  saveData = (_data, callback) => {
    const { dialogCloseRequest } = this.props;
    const { data } = this.state;
    this.props.saveData(_data, (response) => {
      callback && callback();
      if (response)
        this.setState({ InitialState: data }, () => { dialogCloseRequest({ runGetData: true }); });
    });
  }


  saveItem = () => {
    const { data, currentItem, index } = this.state;
    if (currentItem.regexType === null || currentItem.regexFilter === null || currentItem.regexPattern.toString().trim() === '') {
      notification.error({ message: i18n.t('msg.noRuleItem') });
    }
    else if ((currentItem.regexType === regexTypes.TypeCode1 || currentItem.regexType === regexTypes.TypeCode2) && !data.generalRuleItems.find(x => x.regexType === regexTypes.Bank)) {
      notification.error({ message: i18n.t('msg.noBankRule') });
    }
    else {
      index !== null && index !== undefined ? data.generalRuleItems[index] = currentItem : data.generalRuleItems.push(currentItem);
      this.setState({ visible: false, data });
    }
  }

  deleteRuleItem = (index) => {
    const { data } = this.state;
    const _item = data.generalRuleItems[index];
    if (_item.regexType === regexTypes.Bank) {
      const _bankAccount = data.generalRuleItems.find(x => x.regexType === regexTypes.BankaHesap);
      const _contactName = data.generalRuleItems.find(x => x.regexType === regexTypes.IlgiliKisi);
      const _typeCode1 = data.generalRuleItems.find(x => x.regexType === regexTypes.TypeCode1);
      const _typeCode2 = data.generalRuleItems.find(x => x.regexType === regexTypes.TypeCode2);

      if (_bankAccount)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_bankAccount), 1);
      if (_contactName)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_contactName), 1);
      if (_typeCode1)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_typeCode1), 1);
      if (_typeCode2)
        data.generalRuleItems.splice(data.generalRuleItems.indexOf(_typeCode2), 1);

      notification.warning({ message: i18n.t('msg.bankDeleteRuleItemInfo'), duration: 20 });
    }
    data.generalRuleItems.splice(data.generalRuleItems.indexOf(_item), 1);
    this.setState({ data });
  };

  renderButtons = (row, i) => {
    return (
      <Button.Group className="action-buttons">
        <Tooltip placement="bottom" title={i18n.t('btn.delete')}>
          <Button className="#delete" icon="delete" size="small" onClick={() => this.deleteRuleItem(i)} />
        </Tooltip>
        <Tooltip placement="bottom" title={i18n.t('btn.edit')}>
          <Button className="#edit" icon="edit" size="small" onClick={() => this.setState({ currentItem: row, visible: true, index: i })} />
        </Tooltip>
      </Button.Group>
    );
  };

  getName = ({ regexType, regexPattern }) => {
    const { tenantBanks, bankAccounts } = this.props
    let obj;

    if (regexType === regexTypes.Bank)
      obj = tenantBanks.data && tenantBanks.data.find(x => x.bankEftCode === regexPattern.toString());
    else if (regexType === regexTypes.BankaHesap)
      obj = bankAccounts.data && bankAccounts.data.find(x => x.id.toString() === regexPattern.toString());

    return obj ? regexType === regexTypes.Bank ? <React.Fragment><img src={obj.logoSmall} className="bank-icon" alt="" /> {obj.name}</React.Fragment> : obj.name : regexPattern;
  }

  propertyUpdate = (type, value, index) => {
    const { data } = this.state;
    data.rulePropertyConfigs[index][type] = value;
    this.setState({ data })
  }

  renderInput = (type) => {
    const { currentItem, data } = this.state;
    const { type1, type2, tenantBanks, bankAccounts } = this.props

    const bank = data.generalRuleItems.find(x => x.regexType === regexTypes.Bank);
    const input = <Input className={`#${currentItem.regexPattern}`} label={i18n.t('lbl.value')} value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />;

    const fields = {
      [regexTypes.TypeCode1]: <Select className="#typeCode1" label={i18n.t('lbl.value')} options={bank ? type1.data && type1.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal="code" optName="code" value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.TypeCode2]: <Select className="#typeCode2" label={i18n.t('lbl.value')} options={bank ? type2.data && type2.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal="code" optName="code" value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.Bank]: <Select className="#bank" label={i18n.t('lbl.value')} options={tenantBanks.data} optVal="bankEftCode" optName={(bank) => <React.Fragment><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</React.Fragment>} value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.BankaHesap]: <Select className="#BankaHesap" label={i18n.t('lbl.value')} options={bank ? bankAccounts.data && bankAccounts.data.filter(x => x.bankEftCode === bank.regexPattern) : []} optVal={(x) => x.id.toString()} optName="name" value={currentItem.regexPattern} onChange={(e) => this.setState({ currentItem: { ...currentItem, regexPattern: e } })} />,
      [regexTypes.Desc]: input,
      [regexTypes.Iban]: input,
      [regexTypes.Tckn]: input,
      [regexTypes.Vkn]: input,
      [regexTypes.Tutar]: input,
      [regexTypes.IlgiliKisi]: input
    };
    return (fields[type]);
  };

  generateRegexFields = (regexFields = []) => {
    const { data } = this.state;
    let _regexFields = []
    _regexFields = regexFields.map(x => {
      const ruleItem = data.generalRuleItems.find(y => y.regexType === x.regexType);
      if (ruleItem) {
        if (ruleItem.regexType === regexTypes.Tutar && data.generalRuleItems.filter(x => x.regexType === regexTypes.Tutar).length < 2)
          return x;
        else
          return { ...x, disabled: true }
      }
      else
        return x
    });

    if (!data.generalRuleItems.find(x => x.regexType === regexTypes.Bank)) {
      _regexFields = _regexFields.map(x => {
        const hasTypeCodeOrBankAccount = x.regexType !== regexTypes.TypeCode1 && x.regexType !== regexTypes.TypeCode2 && x.regexType !== regexTypes.BankaHesap && x.regexType !== regexTypes.IlgiliKisi;
        return hasTypeCodeOrBankAccount ? x : { ...x, disabled: true }
      });
    }
    return _regexFields;
  }

  generateRegexFilterOptions = (regexFieldsData = []) => {
    let regexFiltersOptions = [];
    const { currentItem } = this.state;

    const regexFiltersData = regexFieldsData.find(x => x.regexType === currentItem.regexType);
    const regexFilterItems = (regexFiltersData && regexFiltersData.regexFilters) ? regexFiltersData.regexFilters : []
    regexFiltersOptions = regexFilterItems.map(x => ({ value: x, name: i18n.t(`lbl.${regexFilters[x]}`) }));

    return regexFiltersOptions;
  }

  renderDialogButtons = checkAccessRights => {
    const { rules, dialogCloseRequest, renderDialogButtons, reproductionRule } = this.props;
    renderDialogButtons(
      !reproductionRule ?
        <div>
          <Button disabled={rules.single.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
        </div>
        :
        checkAccessRights
        ||
        <div>
          <Button disabled={rules.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
          <Button disabled={rules.single.loading} form="rule" htmlType="submit" className="success #save" icon="save" loading={rules.single.saving}>{i18n.t('btn.save')}</Button>
        </div>,
      rules.single.saving,
      rules.single.loading
    )
  }

  render() {
    const { loading, visible, data, currentItem, planCodes, accountCodes } = this.state;
    const { transferStatus, voucherTypes, categoryTypes, regexFields, rules, dialogCloseRequest, reproductionRule } = this.props;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    const _regexFields = this.generateRegexFields(regexFields.data);
    const regexFiltersOptions = this.generateRegexFilterOptions(regexFields.data);

    let columns = reproductionRule ? [{ render: (row, i) => this.renderButtons(row, i), checkField: false, }] : [];
    columns.push(
      { key: 'regexType', render: (row) => this.props.regexFields.data.length > 0 && this.props.regexFields.data.find(x => x.regexType === row.regexType).description },
      { key: 'regexFilter', render: (row) => i18n.t(`lbl.${regexFilters[row.regexFilter]}`) },
      { key: 'regexPattern', render: (row) => this.getName(row) },
    );

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...rules, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={!!(loading || planCodes.loading || accountCodes.loading)} className="loading-card">
            <Form onSubmit={this.save} id="rule">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      label={i18n.t('lbl.voucherType')}
                      value={data.voucherTypeId}
                      options={voucherTypes.data}
                      optVal="id"
                      optName="name"
                      disabled={!reproductionRule}
                      onChange={reproductionRule ? (e) => this.update('voucherTypeId', (e)) : null}
                      className="#voucherType"
                    />
                  </Form.Item>
                </Col>
                {
                  reproductionRule &&
                  <>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <Select
                          label={i18n.t('lbl.accountPlanCode')}
                          value={data.accountPlanCode}
                          options={planCodes}
                          optVal="Code"
                          optName={(e) => `${e ? e.Code + ' ' + e.Name || e.Title : ''}`}
                          showSearch
                          onSearch={this.planCodeSearch}
                          onChange={(e) => this.update('accountPlanCode', e)}
                          className="#accountPlanCode"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <Input label={i18n.t('lbl.bankAccountCode')} value={data.bankAccountCode} onChange={(e) => this.update('bankAccountCode', e)} className="#bankAccountCode" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <Select
                          label={i18n.t('lbl.currentAccount')}
                          options={accountCodes}
                          optVal="Code"
                          optName={(e) => `${e ? e.Code + ' ' + e.Title || e.Name : ''}`}
                          value={data.currentAccountCode}
                          showSearch
                          onSearch={this.accountCodeSearch}
                          onChange={(e) => this.update('currentAccountCode', e)}
                          className="#currentAccountCode"
                        />
                      </Form.Item>
                    </Col>
                  </>
                }
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      disabled={!reproductionRule}
                      label={i18n.t('lbl.incomingCategory')}
                      value={data.incomingCategoryId}
                      options={categoryTypes.data ? categoryTypes.data.filter(x => x.CategoryType === categoryTypesEnum.IncomingTransaction || x.CategoryType === 0) : []}
                      optVal="Id"
                      optName="Name"
                      onChange={reproductionRule ? (e) => this.update('incomingCategoryId', e) : null}
                      className="#incomingCategory"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      disabled={!reproductionRule}
                      label={i18n.t('lbl.outgoingCategory')}
                      value={data.outgoingCategoryId}
                      options={categoryTypes.data ? categoryTypes.data.filter(x => x.CategoryType === categoryTypesEnum.OutgoingTransaction || x.CategoryType === 0) : []}
                      optVal="Id"
                      optName="Name"
                      onChange={reproductionRule ? (e) => this.update('outgoingCategoryId', e) : null}
                      className="#outgoingCategory"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      disabled={!reproductionRule}
                      label={i18n.t('lbl.transferStatus')}
                      value={data.transferStatus}
                      options={transferStatus.data}
                      optVal="id"
                      optName={(x) => i18n.t(`status.${x.name}`)}
                      onChange={reproductionRule ? (e) => this.update('transferStatus', e) : null}
                      className="#transferStatus"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Row gutter={20}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        validateStatus={!!data.setManuelPriority && !data.priority ? 'error' : 'success'}
                        help={!!data.setManuelPriority && !data.priority && i18n.t('msg.required')}
                      >
                        <Input disabled={!reproductionRule || !data.setManuelPriority} type="number" label={i18n.t('lbl.point')} value={data.priority} onChange={reproductionRule ? (e) => this.update('priority', e) : null} className="#point" />
                      </Form.Item>
                    </Col>
                    {
                      reproductionRule &&
                      <Col xs={24} sm={12}>
                        <Form.Item>
                          <Checkbox className="#setManuelPriority" checked={!!data.setManuelPriority} onChange={(e) => this.update('setManuelPriority', e.target.checked)}>
                            {i18n.t('lbl.enterRulePoint')}
                            <Tooltip title={i18n.t('msg.whileIfUncheckedCalculatedRulePoint')}>
                              <Icon className="icon inner-question" type="question-circle" />
                            </Tooltip>
                          </Checkbox>

                        </Form.Item>
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>

              <Row className="mb-20 mt-20">
                <DataTable
                  noHeader
                  data={data.generalRuleItems}
                  columns={columns}
                  pagination={false}
                  showNoData={false}
                  title={i18n.t('lbl.conditions')}
                  toggle={false}
                  className="full-width"
                  newButton={reproductionRule ? () => this.setState({ visible: true, currentItem: new GeneralRuleVoucherTypeModel(), index: null }) : null}
                />
              </Row>
            </Form>
          </Loading>
          <Modal
            title={i18n.t('lbl.condition')}
            visible={visible}
            onCancel={() => this.setState({ visible: false })}
            onOk={this.saveItem}
            okButtonProps={{ className: '#save', icon: 'save' }}
            cancelButtonProps={{ className: '#cancel', icon: 'close' }}
            okText={i18n.t('btn.save')}
            cancelText={i18n.t('btn.cancel')}
          >
            <Form>
              <Row gutter={8}>
                <Col span={8}>
                  <Select
                    className="#domain"
                    label={i18n.t('lbl.domain')}
                    value={currentItem.regexType}
                    options={_regexFields}
                    optVal="regexType"
                    optName="description"
                    onChange={(e) => this.updateItem('regexType', e)}
                  />
                </Col>
                <Col span={8}>
                  <Select
                    className="#operator"
                    value={currentItem.regexFilter}
                    label={i18n.t('lbl.operator')}
                    options={regexFiltersOptions}
                    optVal="value"
                    optName="name"
                    onChange={(e) => this.setState({ currentItem: { ...currentItem, regexFilter: e } })}
                  />
                </Col>
                <Col span={8}>
                  {this.renderInput(currentItem.regexType)}
                </Col>
              </Row>
            </Form>
          </Modal>
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  bankAccounts: common.filter.bankAccounts,
  tenantBanks: common.filter.tenantBanks,

  rules: netekstre.rules,
  voucherTypes: netekstre.filter.voucherTypes,
  categoryTypes: netekstre.filter.categoryTypes,
  regexFields: netekstre.filter.regexFields,
  type1: netekstre.filter.typeCodes1,
  type2: netekstre.filter.typeCodes2,
  transferStatus: netekstre.filter.transferStatus,
});
const mapDispatchToProps = (dispatch, { getUrl, saveUrl }) => ({
  getPlanCodes: (data, callback) => dispatch(commonActions.getPlanCodes(data, callback)),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
  getBankAccounts: () => dispatch(commonActions.getFilter({ url: endpoints.tenant.bankAccountsGetBankAccountsByBankEftCode, key: 'bankAccounts' })),

  getRules: (id, callback) => dispatch(netekstreActions.get({ url: getUrl || endpoints.nte.generalRules, key: 'rules', id }, callback)),
  saveData: (data, callback) => dispatch(netekstreActions.post({ url: saveUrl || endpoints.nte.rules, key: 'rules', data }, callback)),
  getCategoryTypes: (filter) => dispatch(netekstreActions.getFilter({ url: endpoints.nte.tenantCategories, key: 'categoryTypes', filter, isNewODataStructure: true })),
  getRegexFields: () => dispatch(netekstreActions.getFilter({ url: endpoints.lookups.regexFields, key: 'regexFields' })),
  getDynamicProperties: (callback) => dispatch(netekstreActions.getAll({ url: endpoints.lookups.propertyConfigs }, callback)),
  singleToggleData: (data, callback) => dispatch(netekstreActions.post({ data, url: endpoints.nte.generalRulesDisableOrEnable }, callback)),
  clearState: () => dispatch(netekstreActions.clearState("rules"))

});
const RuleForm = Form.create()(Rule);
export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);

