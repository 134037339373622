import React, { Component } from 'react';
import { Upload, notification, Icon, Modal, Button } from 'antd';
import config from 'config';
import CryptoJS from 'crypto-js';
import i18n from 'plugins/i18n';

class NBUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      responseMessages: [],
      visible: false,
      fileUrl: null
    };
  };

  downloadErrorsExcel = () => {
    const { fileUrl } = this.state;
    const token = localStorage.getItem('token');
    let info = localStorage.getItem('userInfo');
    info = info ? JSON.parse(CryptoJS.AES.decrypt(info, "netfinans").toString(CryptoJS.enc.Utf8)) : {};
    window.open(`${fileUrl}&t=${token}&uid=${info.userId}&tid=${info.tenantId}`, '_blank');
  }

  onChange = (info) => {
    let { action, processing } = this.props;
    let { responseMessages, visible, fileUrl } = this.state;

    if (info.file.status === "error") {
      let response = info.file.response;
      let statusCode = response && response.data && response.data[0] && response.data[0].statusCode && response.data[0].statusCode;
      if (statusCode === 404) {
        notification.error({ message: response.data[0].message || i18n.t('msg.unknownError') });
      }
    }
    else if (info.file.status === "uploading") {
      if (processing) {
        processing(true);
      }
    }
    else if (info.file.status === "done") {
      if (processing) {
        processing(false);
      }
      let { response } = info.file;

      if (response.fileUrl) {
        fileUrl = response.fileUrl
      }

      if (response && response.returns && response.returns.length > 0) {
        let successCount = 0;

        // eslint-disable-next-line
        response.returns.map(item => {
          if (item.statusCode === 200) {
            successCount = successCount + 1;
          }
          else if (item.statusCode === 302 || item.statusCode === 404 || item.statusCode === 500 || item.statusCode === 406) {
            return responseMessages.push({
              statusCode: item.statusCode,
              message: item.message
            })
          }
        })

        if (successCount !== 0) {
          responseMessages.unshift({
            statusCode: 200,
            message: `${successCount} ${action === 'ImportsCurrentAccounts' ? i18n.t('lbl.currentAccountAddedSuccessful') : action === "ImportsBankAccountTransactions" ? i18n.t('lbl.updatedBankAccountTransactions') : i18n.t('lbl.bankAddedSuccessful')
              }`
          })
        }
        visible = true;
      }
      this.setState({ responseMessages, visible, fileUrl })
    }
  }



  render() {
    let { content, action, name, getData, headers, baseUrl } = this.props;
    let { responseMessages, visible, fileUrl } = this.state;
    const _action = `${config[baseUrl] || config.netekstreUrl}${action}`
    return (
      <React.Fragment>
        <Upload
          name={name}
          action={_action}
          headers={{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            ...headers
          }}
          onChange={this.onChange}
          showUploadList={false}
          accept=".xlsx,.xls"

        >
          {content}
        </Upload>
        <Modal
          width="40%"
          className="excel-return-modal"
          title={i18n.t('msg.excelResponseStatus')}
          visible={visible}
          footer={
            fileUrl ?
              <Button type="primary" htmlType="button" onClick={this.downloadErrorsExcel}>{i18n.t('btn.downloadErrorsExcel')}</Button>
              :
              null
          }

          onCancel={() => { this.setState({ visible: false, responseMessages: [] }); getData() }}
        >
          {
            responseMessages.map((item, index) =>
              <p key={index}>
                <Icon
                  style={{ color: item.statusCode === 200 ? '#52c41a' : item.statusCode === 302 ? '#faad14' : '#f5222d' }}
                  type={item.statusCode === 200 ? "check-circle" : item.statusCode === 302 ? "exclamation-circle" : "close-circle"}
                  theme="filled"
                  className="mr-5" />
                {item.message}
              </p>
            )
          }
        </Modal>
      </React.Fragment>
    );
  };
};

export default NBUpload;