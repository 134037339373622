import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/posrapor';
import endpoints from 'config/endpoints';
import { defaultFilters, baseUrls } from 'lib/constants';
import utils from "lib";
import i18n from 'plugins/i18n';
import { ActionButtons, DataTable, Filter, FadeIn, CheckAccessRight, Description, Loading } from 'components/UIComponents';
import BankFileType from './Detail';
import { Button } from 'antd';

const DynamicPropertyHeaderModal = lazy(() => import('./components/DynamicPropertyHeaderModal'))

class BankFileTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: defaultFilters.posrapor.BankFileType()
    }
  }

  componentDidMount() {
    utils.posrapor.getBanks();
    this.getBankFileType();
    utils.posrapor.getBankFileTypeLookup();
    utils.common.checkAccessToUpdateOrDelete("BankFileType");
  }

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getBankFileType);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getBankFileType);
  }

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getBankFileType);
  };

  getBankFileType = () => {
    const { filter } = this.state;
    this.props.getBankFileType(filter);
  };

  renderActionButton = row => (
    <ActionButtons
      url="BankFileType"
      editUrl
      hasDelete
      item={row}
      getDatas={this.getBankFileType}
      openDialog={this.datatable && this.datatable.openDialog}
    />
  )

  renderDynamicPropertyHeader = ({ DynamicPropertyHeader }) => {
    return (
      <div className="flex align-center justify-space-b">
        <Description text={DynamicPropertyHeader} tooltipMessage={i18n.t('lbl.clickTheThreeDots')} />
        <Button onClick={e => this.dynamicPropertyHeaderModal?.open(e, DynamicPropertyHeader)} className="#info info"><i className="fas fa-ellipsis-h"></i></Button>
      </div>
    )
  }

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getBankFileType);
  };

  render() {
    const { banks, bankFileType, bankFileTypes } = this.props;
    let filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: banks.data, value: 'eftCode', name: (bankData) => utils.common.getBankName({ bankData }), keys: ['Bank/EftCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('posrapor.definition'), keys: ['description'], type: 'select', options: bankFileTypes.data, name: "description", value: "description", search: true, multiple: true },
    ];
    const columns = [
      { render: this.renderActionButton, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('posrapor.bankName'), key: 'Bank/Name', render: (data) => utils.posrapor.getBankName({ data, dataField: 'Bank.EftCode' }), checkField: false },
      { label: i18n.t('posrapor.definition'), key: 'Description' },
      { label: i18n.t('posrapor.headerColumns'), key: 'HeaderColumns', render: ({ HeaderColumns }) => <Description text={HeaderColumns} /> },
      { label: i18n.t('posrapor.dynamicPropertyHeader'), key: 'DynamicPropertyHeader', render: this.renderDynamicPropertyHeader },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...bankFileType, single: bankFileType.list } }}>
            <Filter filters={filters} onFilter={this.onFilter} />
            <DataTable
              count={bankFileType.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="BankFileType"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={bankFileType.list.data}
              loading={bankFileType.list.loading}
              title={i18n.t('route.posrapor.bankFileType')}
              getData={this.getBankFileType}
              Component={BankFileType}
              dialogTitle={i18n.t('route.posrapor.bankFileType')}
              excel={{ url: "BankFileType", module: "posrapor" }}
              fromExcel={{ url: "BankFileType", baseUrl: baseUrls.posrapor }}
              deleteOptions={{ stateKey: "bankFileType", url: "BankFileType", baseUrl: baseUrls.posrapor }}
              newButton="openDialog"
            />
          </CheckAccessRight>
          <Suspense fallback={<Loading />}>
            <DynamicPropertyHeaderModal ref={el => this.dynamicPropertyHeaderModal = el} />
          </Suspense>
        </FadeIn>
      </div>
    );
  };
}

const mapStateToProps = ({ posrapor }) => ({
  banks: posrapor.filter.banks,
  bankFileType: posrapor.bankFileType,
  bankFileTypes: posrapor.filter.bankFileTypes,
});
const mapDispatchToProps = (dispatch) => ({
  getBankFileType: (filter) => dispatch(actions.getAll({ filter, url: endpoints.posrapor.bankFileType, key: 'bankFileType' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(BankFileTypes);
