import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from "store/actions/common";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { Loading } from 'components/UIComponents';
import { shortFormatMoney } from 'helpers';
import { currencySymbols } from 'lib/constants';

const Dbs = ({ details, getDetails, loading }) => {

  useEffect(() => {
    getDetails()
  }, [getDetails])

  return (
    <div>
      <div className="module-card__inner">
        <Loading loading={loading}>
          {
            details ?
              <>
                <p>{i18n.t('lbl.incomingPayment')}: <span><span className="unit"> {currencySymbols[details.CurrencyCode]}</span>{shortFormatMoney(details.IncomingPayment)}</span></p>
                <p>{i18n.t('lbl.remainingPayment')}: <span><span className="unit"> {currencySymbols[details.CurrencyCode]}</span>{shortFormatMoney(details.RemainingPayment)}</span></p>
                <p>{i18n.t('lbl.pendingPayment')}: <span><span className="unit"> {currencySymbols[details.CurrencyCode]}</span>{shortFormatMoney(details.PendingPayment)}</span></p>
              </>
              :
              <p>{i18n.t('msg.dataFetchError')}</p>
          }
        </Loading>
      </div>
    </div>
  );
};

const mapStateToProps = ({ common }) => ({
  details: common.homeDetailsDbs.single.data && common.homeDetailsDbs.single.data.length ? common.homeDetailsDbs.single.data[0] : [],
  loading: common.homeDetailsDbs.single.loading,
  ...common
});
const mapDispatchToProps = (dispatch) => ({
  getDetails: () => dispatch(actions.get({ key: "homeDetailsDbs", url: endpoints.dbs.home })),
})
export default connect(mapStateToProps, mapDispatchToProps)(Dbs);