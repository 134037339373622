import React, { useEffect } from 'react';
import ReactSVG from 'react-svg';
import CheckIcon from 'assets/img/icons/check-inst.svg'
import DeferralIcon from 'assets/img/icons/deferral.svg'
import { formatMoney } from 'helpers';
import { Divider, Skeleton } from 'antd';
import i18n from 'plugins/i18n';
import { useSelector } from 'react-redux';
import { currencySymbols } from 'lib/constants';


const InstallmentBox = ({ logo, currencyId, installment, amount, isActive, onClick, useSkeleton, paymentDeferral, plusInstallment, processCommRate, tenantPosId }) => {

  const totalAmount = parseFloat(amount + amount * (processCommRate / 100)).toFixed(2);
  const amountPerInstallment = parseFloat(totalAmount / installment).toFixed(2);
  const currencies = useSelector(({ netahsilat }) => netahsilat.filter.currencies?.data);
  const currency = currencies.find(x => x.id === currencyId)?.name;

  useEffect(() => {
    logo?.installmentCellColor && document.documentElement.style.setProperty('--installment-box-active-border-color', logo.installmentCellColor);
    logo?.installmentCellColor && document.documentElement.style.setProperty('--installment-box-hover-border-color', logo.installmentCellColor + '40');
    logo?.installmentCellColor && document.documentElement.style.setProperty('--installment-box-text-color', logo.colorCode);

    return () => {
      document.documentElement.style.removeProperty('--installment-box-active-border-color');
      document.documentElement.style.removeProperty('--installment-box-hover-border-color');
      document.documentElement.style.removeProperty('--installment-box-text-color');
    }
  }, [logo])

  return (
    <Skeleton paragraph={false} active={useSkeleton?.loading || false} loading={useSkeleton ? !useSkeleton.isLoaded : false} className="installment-box__skeleton">
      <div className={`installment-box__wrapper ${isActive ? 'active' : ''}`} onClick={() => onClick({ installment, tenantPosId })}>

        <div className={`active-box-suffix ${isActive ? 'checked' : ''}`}>
          <ReactSVG src={CheckIcon} />
        </div>

        <p className="installment_count">
          {`${installment === 1 ? i18n.t('lbl.singlePayment') : i18n.t('lbl.installment', { installment })}`}
        </p>

        {
          installment > 1 &&
          <p className="calc_amount">
            {installment} x {formatMoney(amountPerInstallment)} <span>{currencySymbols[currency]}</span>
          </p>
        }

        <p className="amount" >
          <span>{currencySymbols[currency]}</span> {formatMoney(totalAmount)}
        </p>

        {
          (plusInstallment || paymentDeferral) ?
            <div className="plugins">

              {
                plusInstallment
                  ? <div>+{plusInstallment}</div>
                  : null
              }

              {
                plusInstallment && paymentDeferral
                  ? <Divider type="vertical" />
                  : null
              }

              {
                paymentDeferral
                  ? <div><ReactSVG src={DeferralIcon} /> {paymentDeferral}</div>
                  : null
              }
            </div>
            : null
        }
      </div>
    </Skeleton>
  )
}

export default InstallmentBox;
