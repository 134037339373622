import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Tabs } from 'antd';
import { PosModel } from 'models';
import { httpMethods } from 'lib/constants';
import actions from 'store/actions/netahsilat';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import utils from 'lib';
import { Loading } from 'components/UIComponents';

const PosInfo = lazy(() => import('./components/Detail.PosInfo'));
const PosParameters = lazy(() => import('./components/Detail.PosParameters'));

class Pos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new PosModel(),
      InitialState: new PosModel(),
    }
    this.id = props.id;
  };

  componentDidMount() {
    const { getPos } = this.props;
    if (this.id) getPos(this.id);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.poses.data && !state.data.id) {
      const data = new PosModel(props.poses.data);
      props.getPosLogos(data.posId);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data) }
    }

    if (props.currencies.data.length && !state.data.currencyDefinitionId)
      return { data: { ...state.data, currencyDefinitionId: props.currencies.data?.find(x => x.name === 'TRY')?.id } }

    if (props.threeDSecureTypes.data.length && !state.data.threeDSecureTypeId)
      return { data: { ...state.data, threeDSecureTypeId: props.threeDSecureTypes.data?.find(x => x.name === 'Tam Doğrulama')?.id } }

    if (props.dialogClosing && !props.poses.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
    utils.netahsilat.clearFilter('posParameters');
    utils.netahsilat.clearFilter('posLogos');
  }

  update = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data }, () => {
      if (key === "posId") {
        data.name = this.props.posesFilter.data?.find(x => x.id === value)?.name;
        this.props.getPosLogos(value, response => this.update('logoId', response?.find(x => x.isDefault)?.id))
        if (this.posParameters) this.posParameters.getPosParameters()
      }
    })
  }

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    const { data } = this.state;
    if (this.posParameters) {
      data.posParameters = this.posParameters.state.parameters;
    }
    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, () => {
          this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
        });
      }
    });
  };

  getPosLogos = posId => {
    this.props.getPosLogos(posId);
  }

  renderDialogButtons = () => {
    const { poses, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={poses.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={poses.loading} form="poses" htmlType="submit" className="success #save" icon="save" loading={poses.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      poses.saving,
      poses.loading
    )
  }

  render() {
    const data = Object.assign({}, this.state.data);
    const { form } = this.props;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <Form onSubmit={this.save} id="poses">
          <Tabs defaultActiveKey="posInfo">
            <Tabs.TabPane key="posInfo" tab={i18n.t(`lbl.posInfo`)}>
              <Suspense fallback={<Loading />}>
                <PosInfo {...{ update: this.update, data, getFieldDecorator: form.getFieldDecorator, }} />
              </Suspense>
            </Tabs.TabPane>

            <Tabs.TabPane key="posParameters" tab={i18n.t(`lbl.posParameters`)} disabled={!data.posId}>
              <Suspense fallback={<Loading />}>
                <PosParameters {...{ update: this.update, data, getFieldDecorator: form.getFieldDecorator, ref: el => this.posParameters = el }} />
              </Suspense>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </div>
    );
  };
};


const mapStateToProps = ({ netahsilat }) => ({
  poses: netahsilat.poses.single,
  currencies: netahsilat.filter.currencies,
  threeDSecureTypes: netahsilat.filter.threeDSecureTypes,
  posesFilter: netahsilat.filter.poses,
});
const mapDispatchToProps = (dispatch) => ({
  getPos: (id) => dispatch(actions.get({ url: endpoints.netahsilat.tenantPos, key: 'poses', id })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.netahsilat.tenantPos, key: 'poses', data }, callback)),
  getPosParameters: (id) => dispatch(actions.getFilter({ url: endpoints.netahsilat.posParameters, key: 'posParameters', id })),
  getPosLogos: (id, callback) => dispatch(actions.getFilter({ url: endpoints.netahsilat.posLogos, key: 'posLogos', id }, callback)),
  clearState: () => dispatch(actions.clearState("poses"))
});
const PosForm = Form.create()(Pos);
export default connect(mapStateToProps, mapDispatchToProps)(PosForm);