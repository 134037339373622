import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/netahsilat';
import { defaultFilters } from 'lib/constants';
import { DataTable, ActionButtons, FadeIn, Filter, Loading } from "components/UIComponents";
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { Checkbox, Tooltip, Icon, Button, Tag } from 'antd';
import utils from 'lib';
import { formatMoney, formatDate } from 'helpers';
import TransactionDetail from "./Detail";
import PaymentDetail from "../Payment/Detail";

const CancelOrResend = lazy(() => import('./components/CancelOrResend'));

class NetlinkTransaction extends Component {
  state = {
    filter: defaultFilters.netahsilat.NetLinkTransaction(),
    component: TransactionDetail,
    dialogTitle: i18n.t('lbl.netlinkTransaction'),
    isLoadedPaymentDetailsUtilsData: false
  }

  componentDidMount() {
    this.getPageData();
    utils.netahsilat.getCurrencies();
    utils.netahsilat.getNetLinkTransactionStatuses();
    utils.netahsilat.getNetlinkTypes();
  };

  getNetLinkTransaction = () => {
    const { filter } = this.state;
    this.props.getNetLinkTransaction(filter);
  };

  getPageData = () => {
    this.getNetLinkTransaction();
    this.getNetLinkTransactionCalc();
  };

  getNetLinkTransactionCalc = () => {
    const transform = [{
      filter: {
        ...this.state.filter.filter,
        or: [{ Status: 1 }, { Status: 2 }, { Status: 4 }, { Status: 5 }]
      }
    }, {
      groupBy: {
        properties: ['Status'],
        transform: [{
          aggregate: {
            PureAmount: { with: 'sum', as: 'Total' },
          }
        }]
      }
    }]
    this.props.getNetLinkTransactionCalc({ transform });
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getPageData);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, orderBy: [`${key} ${type}`] } }, this.getPageData);
  };

  resetComponent = () => {
    setTimeout(() => this.setState({ component: TransactionDetail, dialogTitle: i18n.t('lbl.netlinkTransaction') }), 250)
  }

  loadPaymentDetailsUtilsData = () => {
    if (!this.state.isLoadedPaymentDetailsUtilsData) {
      utils.netahsilat.getTransactionStatuses();
      utils.netahsilat.getTransactionTypes();
      this.setState({ isLoadedPaymentDetailsUtilsData: true })
    }
  }

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getPageData);
  }

  calcTotal = data => ({
    Status: "total",
    PureAmount: data.reduce((a, b) => a + b.PureAmount, 0),
    CurrencyId: data[0]?.CurrencyId,
    disableOnRowClick: true,
    isTotalRow: true
  })

  getCalculatedData = () => {
    const { netlinkTransactionsCalc } = this.props;
    const types = [{ type: 'successful', tagColor: 'green' }, { type: 'pending', tagColor: 'orange' }, { type: 'unsuccessful', tagColor: 'red' }, { type: 'requestSent', tagColor: 'blue' }];
    const data = {
      successful: netlinkTransactionsCalc.data.find(x => x.Status === 1)?.Total,
      pending: netlinkTransactionsCalc.data.find(x => x.Status === 4)?.Total,
      unsuccessful: netlinkTransactionsCalc.data.find(x => x.Status === 2)?.Total,
      requestSent: netlinkTransactionsCalc.data.find(x => x.Status === 5)?.Total,
    };

    const Loading = ({ children }) => netlinkTransactionsCalc.loading ? <Icon type="loading" /> : children;

    const getTag = ({ type, label, tagData, currency = 'TRY', tagColor }) => (
      <Tag key={type} className={tagColor || type}>{i18n.t(`lbl.${label || type}`)} : <Loading>{formatMoney(tagData || data[type])} {currency}</Loading></Tag>
    );

    return (
      <div className="card-title-tags" >
        {types.map(getTag)}
        <Tooltip placement="right" title={i18n.t('lbl.generalTotals')} >
          <Icon type="info-circle" style={{ color: 'rgba(0,0,0,0.25)' }} />
        </Tooltip >
      </div >
    );
  }

  additionalButtons = ({ Id, VPosTransactions, Status }) => {
    const { accessRights, history } = this.props;

    const onCancelOrResend = (e, type) => {
      e.stopPropagation();
      this.cancelOrResend.openModal(type, Id)
    }

    const paymentDetail = e => {
      e.stopPropagation();
      if (VPosTransactions?.length === 1) {
        this.loadPaymentDetailsUtilsData();
        this.setState({ component: PaymentDetail, dialogTitle: i18n.t('lbl.paymentInfo') }, () => {
          this.datatable.openDialog(false, VPosTransactions[0].Id, { isPaymentDetail: true, netlinkPage: this });
        })
      }
      else if (VPosTransactions?.length > 1) {
        history.push({ pathname: '/netahsilat/payment', state: { NetLinkTransactionId: Id } })
      }
    }

    const getTitle = type => {
      if (Status !== 5 && Status !== 1 && Status !== 2) return i18n.t('msg.onlyRequestSentUsable')  // 5: İstek Gönderildi
      else return i18n.t(`btn.${type}`)
    }

    return (
      Id ?
        <>
          {
            accessRights.find(x => x.endPoint === 'NetLinkTransaction' && x.method === 'GET') &&
            <Tooltip placement="bottom" title={i18n.t('btn.transactionDetail')}>
              <Button className="#transactionDetail" size="small">
                <Icon type="file-search" />
              </Button>
            </Tooltip>
          }
          {
            accessRights.find(x => x.endPoint === 'NetLinkTransaction/Cancel' && x.method === 'GET') &&
            (Status !== 1 && Status !== 2) &&
            <Tooltip placement="bottom" title={() => <div className="text-center">{getTitle('cancellation')}</div>} overlayClassName="text-center">
              <Button disabled={Status !== 5} className="#cancel" size="small" onClick={e => onCancelOrResend(e, 'cancellation')}>
                <Icon type="close-circle" />
              </Button>
            </Tooltip>
          }
          {
            accessRights.find(x => x.endPoint === 'NetLinkTransaction' && x.method === 'PUT') &&
            (Status !== 1 && Status !== 2) &&
            <Tooltip placement="bottom" title={() => <div className="text-center">{getTitle('resend')}</div>} overlayClassName="text-center">
              <Button disabled={Status !== 5} className="#resend" size="small" onClick={e => onCancelOrResend(e, 'resend')}>
                <Icon type="redo" />
              </Button>
            </Tooltip>
          }
          {
            (accessRights.find(x => x.endPoint === 'Transaction' && x.method === 'GET') && (Status === 1 || Status === 2)) &&
            <Tooltip placement="bottom" title={() => <div className="text-center">{getTitle('paymentDetail')}</div>} overlayClassName="text-center">
              <Button className="#paymentDetail" size="small" onClick={paymentDetail}>
                <Icon type="container" />
              </Button>
            </Tooltip>
          }
        </>
        :
        <Tooltip placement="bottom" title={i18n.t('lbl.pageTotal')}>
          <Button className="#pageTotal" size="small" onClick={e => e.stopPropagation()}>
            <Icon type="info-circle" />
          </Button>
        </Tooltip>
    )
  }

  getCurrencyName = (row) => this.props.currencies.data.find(x => x.id === row.CurrencyId)?.name || '';

  getNetLinkSendTypeName = (row) => this.props.netlinkTypes.data.find(x => x.id === row.PayLinkSendType)?.name || '';

  getNetLinkTransactionStatusName = (row) => {
    const netlinkTransactionStatuses = [...this.props.netlinkTransactionStatuses.data, { id: "total", name: i18n.t('lbl.total') }];
    return netlinkTransactionStatuses.find(x => x.id === row.Status)?.name || '';
  }

  render() {
    const { netlinkTransactions, netlinkTransactionStatuses, netlinkTypes } = this.props;
    const { dialogTitle, component } = this.state;
    const filters = [
      { label: i18n.t('lbl.sentDate', { add: i18n.t('lbl.start') }), type: 'date', date: 'ge', keys: ['RequestDate'], uniqueKey: '#requestDateStart', disabled: '1' },
      { label: i18n.t('lbl.sentDate', { add: i18n.t('lbl.end') }), type: 'date', date: 'le', keys: ['RequestDate'], uniqueKey: '#requestDateEnd', disabled: '0' },
      { label: i18n.t('lbl.operationStatus'), type: 'select', options: netlinkTransactionStatuses.data, value: 'id', name: 'name', keys: ['Status'], showAll: true, uniqueKey: '#transactionStatus', multiple: true, loading: netlinkTransactionStatuses.loading },
      { label: i18n.t('lbl.sendType'), type: 'select', options: netlinkTypes.data, value: 'id', name: 'name', keys: ['PayLinkSendType'], showAll: true, uniqueKey: '#payLinkSendType', multiple: true, loading: netlinkTypes.loading },
    ];
    const columns = [
      {
        render: (row) =>
          <ActionButtons
            url="NetLinkTransaction"
            item={row}
            getDatas={this.getPageData}
            openDialog={this.datatable?.openDialog}
            additionalButtons={() => this.additionalButtons(row)}
          />,
        toggle: false, sort: false, notIncluded: true, key: 'Id'
      },
      { label: i18n.t('lbl.operationStatus'), key: 'Status', render: this.getNetLinkTransactionStatusName },
      { label: i18n.t('lbl.sendType'), key: 'PayLinkSendType', render: this.getNetLinkSendTypeName },
      { label: i18n.t('lbl.amount'), key: 'PureAmount', render: row => <>{formatMoney(row.PureAmount)} {this.getCurrencyName(row)}</> },
      { label: i18n.t('lbl.isActive'), key: 'IsActive', render: (row) => <Checkbox checked={row.IsActive} />, thClass: 'text-center', tdClass: 'text-center' },
      { label: i18n.t('lbl.lastPaymentDate'), key: 'ExpireDate', render: row => formatDate(row.ExpireDate, null, null, null, false), thClass: 'text-center', tdClass: 'text-center' },
    ];

    const data = [...netlinkTransactions.list.data, this.calcTotal(netlinkTransactions.list.data)];

    return (
      <div className="page-content">
        <FadeIn>
          <Filter filters={filters} onFilter={this.onFilter} />
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            access="NetLinkTransaction"
            history={this.props.history}
            newButton="openDialog"
            onPageChange={this.onPageChange}
            onSort={this.onSort}
            columns={columns}
            data={data}
            loading={netlinkTransactions.list.loading}
            title={i18n.t('route.netahsilat.netlinkTransactions')}
            getData={this.getPageData}
            count={netlinkTransactions.list.count}
            excel={{ url: "ExportNetlinkTransaction", module: "nth" }}
            CustomButton={this.getCalculatedData}
            dialogTitle={dialogTitle}
            Component={component}
            withTotal
          />
          <Suspense fallback={<Loading />}>
            <CancelOrResend wrappedComponentRef={el => this.cancelOrResend = el} getData={this.getPageData} />
          </Suspense>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ netahsilat }) => ({
  netlinkTransactions: netahsilat.netlinkTransactions,
  netlinkTransactionStatuses: netahsilat.filter.netlinkTransactionStatuses,
  currencies: netahsilat.filter.currencies,
  netlinkTransactionsCalc: netahsilat.netlinkTransactionsCalc.list,
  netlinkTypes: netahsilat.filter.netlinkTypes,
});
const mapDispatchToProps = (dispatch) => ({
  getNetLinkTransaction: (filter) => dispatch(actions.getAll({ filter, url: endpoints.netahsilat.netlinkTransaction, key: 'netlinkTransactions', isNewODataStructure: true })),
  getNetLinkTransactionCalc: (filter) => dispatch(actions.getAll({ filter, url: endpoints.netahsilat.netlinkTransaction, key: 'netlinkTransactionsCalc' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(NetlinkTransaction);