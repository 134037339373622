import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Tabs, Checkbox, notification, Button, Radio } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import _ from 'lodash';
import { NotificationModel } from 'models';
import { compareFields } from 'helpers';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { Loading, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new NotificationModel(),
      InitialState: new NotificationModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.notifications.single.data && !state.data.id) {
      let data = _.cloneDeep(props.notifications.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.notifications.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    this.props.getTemplates();
    this.props.getTransactionCategories({ filter: { or: [{ ParentId: { ne: null } }, { CategoryType: 0 }] } });


    if (this.id) {
      this.props.getNotifications(this.id);
    }
    else {
      this.setState({ loading: false });
      this.props.getRoles((response) => this.setCallbackData('notificationRoles', 'roleId', response));
      this.props.getUsers((response) => this.setCallbackData('notificationUsers', 'userId', response));
      this.props.getTemplates();
    }

  };

  componentWillUnmount() {
    this.props.clearState();
  }

  updateData = (field, e, index) => {
    let { data } = this.state;
    data[field][index].checked = e.target.checked;
    this.setState({ data });
  };

  updateCheckboxData = (field, id) => {
    let { data } = this.state;
    let item = data[field].find(x => x === id);
    item ? data[field].splice(data[field].indexOf(id), 1) : data[field].push(id);
    this.setState({ data });
  }

  updateCheckboxCheckAll = (field, val) => {
    let { data } = this.state;
    val ? this.props[field].data.map(x => data[field].push(x.Id)) : data[field] = []
    this.setState({ data });
  };


  checkAll = (field, val) => {
    let { data } = this.state;
    data[field].map(x => x.checked = val);
    this.setState({ data });
  };

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  validateAmount = (key) => {
    let { data } = this.state;
    data.maxAmount = data.maxAmount ? parseInt(data.maxAmount) : data.maxAmount;
    data.minAmount = data.minAmount ? parseInt(data.minAmount) : data.minAmount;
    if (key === "minAmount") {
      data.minAmount = data.minAmount ? data.maxAmount ? data.minAmount >= data.maxAmount ? data.maxAmount - 1 : data.minAmount : data.minAmount : ""
    }
    if (key === "maxAmount") {
      data.maxAmount = data.maxAmount ? data.minAmount ? data.maxAmount <= data.minAmount ? data.minAmount + 1 : data.maxAmount : data.maxAmount : ""
    }
    this.setState({ data });
  }

  setCallbackData = (field, prop, values, isNewODataStructure = false) => {
    let { data } = this.state;
    if (isNewODataStructure)
      data[field] = values.value.map(x => { x[prop] = x.Id; delete x.Id; return x; });
    else
      data[field] = values.map(x => { x[prop] = x.id; delete x.id; return x; });
    this.setState({ data });
  }

  save = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let { notifications, dialogCloseRequest } = this.props;
    let _data = _.cloneDeep(data);
    this.props.form.validateFields(error => {
      if (!error) {
        _data.notificationRoles = compareFields('notificationRoles', _data, this.id ? notifications : null);
        _data.notificationUsers = compareFields('notificationUsers', _data, this.id ? notifications : null);
        _data.minAmount = data.minAmount === "" ? 0 : data.minAmount;
        _data.maxAmount = data.maxAmount === "" ? 0 : data.maxAmount;

        if (!this.id) {
          if (_data.transactionCategories.length === 0)
            notification.error({ message: i18n.t('msg.requiredCategory') });

          else if (!_data.notifyToCurrentAccount && (_data.notificationRoles.length === 0 && _data.notificationUsers.length === 0))
            notification.error({ message: i18n.t('msg.requiredRoleOrUserOrNotify') });

          else {
            let method = this.id ? httpMethods.put : httpMethods.post;
            this.props.saveData(_data, method, () => {
              this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
            });
          }
        }
        else {
          if (!_data.notifyToCurrentAccount && (data.notificationRoles.every(x => x.checked === false) && data.notificationUsers.every(x => x.checked === false))) {
            notification.error({ message: i18n.t('msg.requiredRoleOrUserOrNotify') });
          }
          else {
            let method = this.id ? httpMethods.put : httpMethods.post;
            this.props.saveData(_data, method, (response) => {
              this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
            });
          }
        }
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { notifications, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={notifications.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={notifications.single.loading} form="notification" htmlType="submit" className="success #save" icon="save" loading={notifications.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      notifications.single.saving,
      notifications.single.loading
    )
  }

  render() {
    const { templates, notifications, dialogCloseRequest, transactionCategories } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, data } = this.state;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...notifications, renderDialogButtons, dialogCloseRequest }}>

          <Loading loading={loading}>
            <Form onSubmit={this.save} id="notification">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.description
                    })(
                      <Input className="#description" label={i18n.t('lbl.name')} onChange={(e) => this.update('description', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('templateId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.templateId
                    })(
                      <Select
                        className="#templateId"
                        options={templates.data}
                        optVal="id"
                        optName="name"
                        label={i18n.t('lbl.template')} a
                        allowClear={false}
                        onChange={(e) => this.update('templateId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20} className="mb-20">
                <Col xs={24} lg={24}>
                  <Tabs type="card">
                    <Tabs.TabPane tab={i18n.t('lbl.movementCategories')} key="1">
                      <React.Fragment>
                        <Row>
                          <Checkbox checked={transactionCategories.data.map(x => x.Id).every(x => data.transactionCategories.find(y => y === x))} onChange={(e) => this.updateCheckboxCheckAll('transactionCategories', e.target.checked)}>{i18n.t('lbl.all')}</Checkbox>
                        </Row>
                        {
                          transactionCategories.data &&
                          transactionCategories.data.map((x, i) => (
                            <Row key={i}>
                              <Checkbox className={`#transactionCategory${i}`} checked={!!data.transactionCategories.find(t => t === x.Id)} onChange={(e) => this.updateCheckboxData("transactionCategories", x.Id, e)}>
                                {x.Name}
                              </Checkbox>
                            </Row>
                          ))
                        }
                      </React.Fragment>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t('lbl.roles')} key="2">
                      <React.Fragment>
                        <Row>
                          <Checkbox checked={data.notificationRoles.find(x => !x.checked) ? false : true} onChange={(e) => this.checkAll('notificationRoles', e.target.checked)}>{i18n.t('lbl.all')}</Checkbox>
                        </Row>
                        {
                          data.notificationRoles &&
                          data.notificationRoles.map((x, i) => (
                            <Row key={i}>
                              <Checkbox className={`#notificationRole${i}`} checked={x.checked} onChange={(e) => this.updateData("notificationRoles", e, i)}>
                                {this.id ? x.roleName : x.name}
                              </Checkbox>
                            </Row>
                          ))
                        }
                      </React.Fragment>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t('lbl.users')} key="3">
                      <React.Fragment>
                        <Row>
                          <Checkbox checked={data.notificationUsers.find(x => !x.checked) ? false : true} onChange={(e) => this.checkAll('notificationUsers', e.target.checked)}>{i18n.t('lbl.all')}</Checkbox>
                        </Row>
                        {
                          data.notificationUsers &&
                          data.notificationUsers.map((x, i) => (
                            <Row key={i}>
                              <Checkbox className={`#notificationUser${i}`} checked={x.checked} onChange={(e) => this.updateData('notificationUsers', e, i)}>
                                {this.id ? x.fullName : x.name}
                              </Checkbox>
                            </Row>
                          ))
                        }
                      </React.Fragment>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t('lbl.amount')} key="4">
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item>
                            <Input type="amount" value={data.minAmount} onBlur={() => this.validateAmount("minAmount")} className="#minAmount" label={i18n.t('lbl.minAmount')} onChange={(e) => this.update('minAmount', e)} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item>
                            <Input type="amount" value={data.maxAmount} onBlur={() => this.validateAmount("maxAmount")} className="#maxAmount" label={i18n.t('lbl.maxAmount')} onChange={(e) => this.update('maxAmount', e)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={i18n.t('lbl.currentAccount')} key="5">
                      <Form.Item>
                        <Radio.Group className="vertical" onChange={(e) => this.update('currentAccountMatchInquiry', e.target.value)} value={data.currentAccountMatchInquiry}>
                          <Radio value={0}>{i18n.t('lbl.noCheckCurrentAccount')}</Radio>
                          <Radio value={1}>{i18n.t('lbl.currentAccountSingleMatch')}</Radio>
                          <Radio value={2}>{i18n.t('lbl.currentAccountNotMatch')}</Radio>
                          <Radio value={3}>{i18n.t('lbl.currentAccountMultiMatch')}</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {
                        (data.currentAccountMatchInquiry === 0 || data.currentAccountMatchInquiry === 1) &&
                        < Form.Item className="mb-0">
                          <Checkbox checked={data.notifyToCurrentAccount} className={`#notifyToCurrentAccount`} onChange={(e) => this.update('notifyToCurrentAccount', e.target.checked)}>{i18n.t(`lbl.notifyToCurrentAccount`)}</Checkbox>
                        </Form.Item>
                      }
                      {
                        (data.currentAccountMatchInquiry === 1 || data.currentAccountMatchInquiry === 3) &&
                        < Form.Item>
                          <Checkbox checked={data.notifyToCustomerRepresentative} className={`#notifyToCustomerRepresentative`} onChange={(e) => this.update('notifyToCustomerRepresentative', e.target.checked)}>{i18n.t(`lbl.notifyToCustomerRepresentative`)}</Checkbox>
                        </Form.Item>
                      }
                    </Tabs.TabPane>

                  </Tabs>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div >
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  roles: common.filter.roles,
  users: common.filter.users,

  notifications: netekstre.notifications,
  transactionCategories: netekstre.filter.transactionCategories,
  templates: netekstre.filter.templates,
});
const mapDispatchToProps = (dispatch) => ({
  getRoles: (callback) => dispatch(commonActions.getFilter({ url: endpoints.lookups.roles, key: 'roles' }, callback)),
  getUsers: (callback) => dispatch(commonActions.getFilter({ url: endpoints.lookups.users, key: 'user' }, callback)),

  getNotifications: (id, callback) => dispatch(netekstreActions.get({ url: endpoints.tenant.notifications, key: 'notifications', id }, callback)),
  saveData: (data, method, callback) => dispatch(netekstreActions[method]({ url: endpoints.tenant.notifications, key: 'notifications', data }, callback)),
  getTemplates: () => dispatch(netekstreActions.getFilter({ url: endpoints.tenant.notificationsGetBankAccountTransactionTemplates, key: 'templates' })),
  getTransactionCategories: (filter) => dispatch(netekstreActions.getFilter({ url: endpoints.nte.tenantCategories, key: 'transactionCategories', isNewODataStructure: true, filter })),
  clearState: () => dispatch(netekstreActions.clearState("notifications"))
});
const NotificationForm = Form.create()(Notification);
export default connect(mapStateToProps, mapDispatchToProps)(NotificationForm);