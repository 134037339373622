import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Progress, Modal } from 'antd';
import actions from 'store/actions/netekstre';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';

class UsedInfo extends Component {
  state = {
    renderFields: ["user", "account", "transaction"],
    visible: false
  }

  openModal = () => {
    this.setState({ visible: true })
    this.props.getTenantLimit();
  }

  getLabels = () => {
    const { tenantLimits } = this.props;
    if (tenantLimits.single.data) {
      const { renderFields } = this.state;
      return renderFields.map(field =>
        <React.Fragment key={Math.random()}>
          {
            tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Count`] && tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Limit`] &&
            <p className="bold mb-16">{i18n.t(`lbl.groupTenant${String.prototype.capitalize(field)}Count`)}</p>
          }
          <p className="bold mb-16">{i18n.t(`lbl.${field}Count`)}</p>
        </React.Fragment>
      )
    }
  }

  getProgressBarData = () => {
    const { tenantLimits } = this.props;
    if (tenantLimits.single.data) {
      const { renderFields } = this.state;
      return renderFields.map(field =>
        <React.Fragment key={Math.random()}>
          {
            tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Count`] && tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Limit`] &&
            this.renderStatus(tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Count`], tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Limit`])
          }
          {this.renderStatus(tenantLimits.single.data[`${field}Count`], tenantLimits.single.data[`${field}Limit`])}
        </React.Fragment>
      )
    }
  }

  renderStatus = (used, limit = 100) => {
    let _used, _limit, _percent, _status;
    _used = used ? used : 0;
    _limit = limit ? limit : 0;
    _percent = _limit ? Math.ceil((100 * _used / _limit)) : 0;
    _status = _percent > 70 ? 'almost-full' : _percent > 50 ? 'warning-text' : '';
    return (
      <Progress
        className={`company-progress mb-14 ${_status}`}
        percent={_limit ? Math.ceil((100 * _used / _limit)) : 0}
        status="active"
        showInfo={!(limit === undefined || limit === null)}
      />
    )
  }

  getLabelData = () => {
    let { tenantLimits } = this.props;
    if (tenantLimits.single.data) {
      const formatThousand = Number.prototype.formatThousand;
      const { renderFields } = this.state;
      return renderFields.map(field =>
        <React.Fragment key={Math.random()}>
          {
            tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Count`] && tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Limit`] &&
            <p className="mb-16">
              {
                `${formatThousand(tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Count`])} ${tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Limit`] ? `/ ${formatThousand(tenantLimits.single.data[`groupTenant${String.prototype.capitalize(field)}Limit`])}` : ''}`
              }
            </p>
          }
          <p className="mb-16">
            {
              `${formatThousand(tenantLimits.single.data[`${field}Count`])} ${tenantLimits.single.data[`${field}Limit`] ? `/ ${formatThousand(tenantLimits.single.data[`${field}Limit`])}` : ''}`
            }
          </p>
        </React.Fragment>
      )
    }
  }


  render() {
    const { visible } = this.state
    return (
      <>
        <span onClick={this.openModal}>
          {i18n.t('lbl.usedInfo')}
        </span>

        <Modal
          title={i18n.t('lbl.usedInfo')}
          visible={visible}
          footer={null}
          width={750}
          onCancel={() => this.setState({ visible: false })}
        >
          <div className="page-content">
            <Row gutter={20}>
              <Col xs={12} sm={7}>
                {this.getLabels()}
              </Col>
              <Col xs={12} sm={9} >
                {this.getLabelData()}
              </Col>
              <Col xs={0} sm={8} >
                {this.getProgressBarData()}
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({ tenantLimits: netekstre.tenantLimits });
const mapDispatchToProps = (dispatch) => ({
  getTenantLimit: () => dispatch(actions.get({ url: endpoints.tenant.getTenantLimit, key: 'tenantLimits', })),
});
export default connect(mapStateToProps, mapDispatchToProps)(UsedInfo);