import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Radio, Switch, Popover, Icon } from 'antd';
import actions from 'store/actions/common';
import { Summary, LineChart, PieChartContainer, BarChart } from 'components/UIComponents/Dashboard';
import endpoints from 'config/endpoints';
import { CurrencyRates } from "components/UIComponents";
import i18n from 'plugins/i18n';
import { defaults } from "react-chartjs-2";
import { summaryCardTypes } from 'models';
import { httpMethods } from 'lib/constants';

defaults.global.defaultFontFamily = "Yellix";
defaults.global.defaultFontSize = 14;


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyCode: null,
    };
  };

  componentDidMount() {
    this.props.getBankCurrencies({ getMultiTenant: this.state.multiTenant });
    this.props.getCurrencies();
    this.props.getTenantBanks(this.props.multiTenantDashboard);
  };

  changeCurrency = (e) => {
    this.setState({ currencyCode: e.target.value });
  };

  onChangeSwitch = (e, key) => {
    if (key === "getMultiTenant") {
      this.props.toggleData({ key: 'multiTenantDashboard', data: e });
      this.props.getTenantBanks(e);
      this.props.getBankCurrencies({ getMultiTenant: e });
    }
    else if (key === "isCumulative") {
      this.props.toggleData({ key: 'cumulativeDashboard', data: e });
    }
    else if (key === "includedInternalTransfer") {
      this.props.toggleData({ key: 'includedInternalTransfer', data: e });
    }
  }

  render() {
    let { filterCurrencies, currencies, multiTenantDashboard, cumulativeDashboard, includedInternalTransfer } = this.props;
    let { currencyCode } = this.state;
    let { data } = this.props.auth;
    let defaultCurrency = data ? (data.systemParameters ? JSON.parse(data.systemParameters).DefaultCurrency : null) : null;
    let hasAccessRights = data.accessRights.filter(x => ["IncomingOutgoingTotals", "BankOutgoingTotals", "BankIncomingTotals", "TotalBalances", "BankAccountsBalances"].includes(x.endPoint)).length !== 0;
    let _currencyCode = currencyCode || defaultCurrency;

    return (
      data.accessRights.length > 0 &&
      <div className={`page-content ${!hasAccessRights ? 'flex-center' : ''}`}>
        {
          hasAccessRights ?
            <>
              <Divider className="mb-50">
                <Radio.Group className="pull-right" buttonStyle="solid" value={currencyCode || defaultCurrency} onChange={this.changeCurrency}>
                  {currencies.single.data && currencies.single.data.filter(x => x).map(x => (
                    <Radio.Button className={`#${x}`} key={x} value={x}>
                      {cumulativeDashboard ? `${i18n.t('lbl.cumulative')} ${x}` : x}
                    </Radio.Button>
                  ))}
                </Radio.Group>
                <Popover overlayClassName="cog-content" placement="left" content={
                  <>
                    {
                      data &&
                      data.tenants &&
                      data.tenants.filter(x => x.id !== data.tenantId).length > 0 &&
                      <small>
                        {i18n.t('btn.allFirm')}
                        <Switch className="custom-small" checked={multiTenantDashboard} onChange={(e) => this.onChangeSwitch(e, 'getMultiTenant')} />
                      </small>
                    }
                    <small>
                      {i18n.t('btn.cumulative')}
                      <Switch className="custom-small" checked={cumulativeDashboard} onChange={(e) => this.onChangeSwitch(e, 'isCumulative')} />
                    </small>
                    <small>
                      {i18n.t('btn.includedInternalTransfer')}
                      <Switch className="custom-small" checked={includedInternalTransfer} onChange={(e) => this.onChangeSwitch(e, 'includedInternalTransfer')} />
                    </small>
                  </>
                }>
                  <Icon className="home-cog" type="setting" />
                </Popover>
              </Divider>
              <CurrencyRates />
              <Summary {...{
                multiTenant: multiTenantDashboard,
                isCumulative: cumulativeDashboard,
                currencyCode: _currencyCode,
                currencies: filterCurrencies.data,
                endpoint: endpoints.nte.totalBalances,
                typeSets: [summaryCardTypes.cashAsset, summaryCardTypes.overdraftAccountBalance, summaryCardTypes.netAsset],
                method: httpMethods.getWithPost
              }} />
              <BarChart {...{
                multiTenant: multiTenantDashboard,
                isCumulative: cumulativeDashboard,
                currencyCode: _currencyCode,
                currencies: filterCurrencies.data,
                endpoint: endpoints.nte.bankAccountsBalances,
                method: httpMethods.getWithPost,
                title: i18n.t('lbl.accountBalance', { isCumulative: cumulativeDashboard ? i18n.t('lbl.cumulative') : '' }),
                accessRight: { endpoint: "BankAccountsBalances", method: "POST" }
              }} />
              <PieChartContainer
                {...{
                  multiTenant: multiTenantDashboard,
                  currencyCode: _currencyCode,
                  includedInternalTransfer,
                  isCumulative: cumulativeDashboard,
                  title: i18n.t('lbl.bankBasedDistribution', { isCumulative: cumulativeDashboard ? i18n.t('lbl.cumulative') : '' }),
                  range: {
                    ranges: [
                      { value: 1, uniqueName: '#yesterday', title: i18n.t('lbl.yesterday') },
                      { value: 0, uniqueName: '#today', title: i18n.t('lbl.today') },
                      { value: 7, uniqueName: '#last7Day', title: i18n.t('lbl.last7day') },
                      { value: 30, uniqueName: '#last30Day', title: i18n.t('lbl.last30day') },
                    ],
                    defaultSelected: 30
                  },
                  charts: [
                    {
                      chartName: "incoming",
                      currencyCode,
                      onLineClick: this.onLineClick,
                      title: i18n.t('lbl.incoming'),
                      accessRight: { endpoint: "BankIncomingTotals", method: "POST" },
                      type: "incomingTotals",
                      method: httpMethods.getWithPost,
                      endpoint: endpoints.nte.bankIncomingTotals,
                    },
                    {
                      chartName: "outgoing",
                      currencyCode,
                      onLineClick: this.onLineClick,
                      title: i18n.t('lbl.outgoing'),
                      accessRight: { endpoint: "BankOutgoingTotals", method: "POST" },
                      type: "outgoingTotals",
                      method: httpMethods.getWithPost,
                      endpoint: endpoints.nte.bankOutGoingTotals,
                    }
                  ]
                }}
              />
              <LineChart {...{
                multiTenant: multiTenantDashboard,
                currencyCode: _currencyCode,
                includedInternalTransfer,
                isCumulative: cumulativeDashboard,
                endpoint: endpoints.nte.incomingOutgoingTotals,
                method: httpMethods.getWithPost,
                title: i18n.t('lbl.totalTransferInOut', { isCumulative: cumulativeDashboard ? i18n.t('lbl.cumulative') : '' }),
                accessRight: { endpoint: "IncomingOutgoingTotals", method: "POST" },
              }} />
            </>
            :
            <div className="no-access-right">
              <i className="fas fa-exclamation-triangle"></i>
              {i18n.t('msg.noAccessRightDashboard')}
            </div>
        }
      </div>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  currencies: common.currencies,
  filterCurrencies: common.filter.currencies,
  multiTenantDashboard: common.multiTenantDashboard,
  cumulativeDashboard: common.cumulativeDashboard,
  includedInternalTransfer: common.includedInternalTransfer,
  i18nextLng: common.i18nextLng
});
const mapDispatchToProps = (dispatch) => ({
  getBankCurrencies: (data) => dispatch(actions.getWithPost({ url: endpoints.tenant.bankAccountsGetCurrencies, key: 'currencies', data })),
  getCurrencies: (callback) => dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies' }, callback)),
  getTenantBanks: (multiTenant) => dispatch(actions.getFilter({ url: multiTenant ? endpoints.tenant.banks : endpoints.lookups.banks, key: 'tenantBanks' })),
  toggleData: (data) => dispatch(actions.toggleData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);