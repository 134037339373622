import i18n from "plugins/i18n";

export const lineChart = ({ multiTenant }) => {
  return {
    maintainAspectRatio: false,
    chartArea: {
      backgroundColor: '#ffffff'
    },
    hover: {
      onHover: function (e, v) { this.ctx.canvas.style.cursor = v[0] && multiTenant ? "pointer" : "default" },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let str = tooltipItem.yLabel.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          str += str.indexOf(',') !== -1 ? '' : ',00';
          let strs = str.split(',');
          if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
          return strs.join(',');
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            let str = label.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            str += str.indexOf(',') !== -1 ? '' : ',00';
            let strs = str.split(',');
            if (strs[1].length === 1) strs[1] = `${strs[1]}0`;
            return strs.join(',');
          }
        }
      }],
      xAxes: [{
        gridLines: {
          drawBorder: false,
          display: true,
          color: "transparent",
          zeroLineColor: "#eee",
          zeroLineWidth: 1
        },
        ticks: {
          callback: function (label) {
            return i18n.t(`lbl.${label.toLowerCase()}`)
          }
        }
      }]
    }
  }
};