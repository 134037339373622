import React from 'react';
import { classNames } from 'helpers';

const Label = ({ color, children, className, size = "", onClick, nextGen = false, title = false, description = false, error = undefined }) => {
  return (
    <label
      onClick={onClick}
      style={color ? { color } : null}
      className={classNames([
        className,
        size,
        nextGen && 'nextGen',
        title && 'title',
        description && 'description',
        error !== undefined
          ? `error-label ${!!error ? 'error-active' : ''}`
          : false
      ])}
    >
      {children}
    </label>
  )
}

export default Label;
