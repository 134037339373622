import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Modal, Tooltip, Checkbox } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { TenantJobModel } from 'models';
import { httpMethods } from 'lib/constants';
import actions from 'store/actions/admin';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { DataTable, Loading, CheckAccessRight } from 'components/UIComponents';


class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new TenantJobModel(),
      InitialState: new TenantJobModel(),
      parametersModalVisible: false,
      currentItem: { key: '', value: '' }
    }
    this.id = props.id;
  };

  componentDidMount() {
    const { endpoint, getTenantJobs, getJobLookup, tenantId } = this.props;
    const parameters = tenantId && { TenantId: tenantId };
    if (this.id) getTenantJobs(this.id, parameters, endpoint);
    else this.setState({ loading: false });
    getJobLookup();
  };


  static getDerivedStateFromProps(props, state) {
    if (props.jobs.single.data && !state.data.id) {
      const data = new TenantJobModel(props.jobs.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.jobs.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  updateParameter = (key, value) => {
    let { state } = this;
    state.currentItem[key] = value;
    this.setState({ ...state });
  };

  deleteParameter = (i) => {
    let { data } = this.state;
    data.tenantJobParameters.splice(data.tenantJobParameters.indexOf(data.tenantJobParameters[i]), 1);
    this.setState({ data });
  };

  saveParameter = (e) => {
    let { data, currentItem, itemIndex } = this.state;
    itemIndex !== null && itemIndex !== undefined ? data.tenantJobParameters[itemIndex] = currentItem : data.tenantJobParameters.push(currentItem);
    this.setState({ parametersModalVisible: false, data });
  };

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest, endpoint, tenantId } = this.props;

    form.validateFields(error => {
      if (!error) {
        const method = this.id ? httpMethods.put : httpMethods.post;
        const parameters = tenantId && { TenantId: tenantId }
        saveData(this.state.data, method, endpoint, parameters, () => {
          this.setState({ InitialState: this.state.data }, () => dialogCloseRequest({ runGetData: true }))
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { jobs, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={jobs.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={jobs.single.loading} form="jobs" htmlType="submit" className="success #save" icon="save" loading={jobs.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      jobs.single.saving,
      jobs.single.loading
    )
  }

  renderButtons = (row, i, type) => {
    return (
      <Button.Group className="action-buttons">
        <Tooltip placement="bottom" title={i18n.t('btn.delete')}>
          <Button className="#delete" icon="delete" size="small" onClick={() => this.deleteParameter(i)} />
        </Tooltip>
        <Tooltip placement="bottom" title={i18n.t('btn.edit')}>
          <Button className="#edit" icon="edit" size="small" onClick={() => this.setState({ currentItem: row, parametersModalVisible: true, itemIndex: i })} />
        </Tooltip>
      </Button.Group>
    );
  };

  render() {
    const { loading, data, parametersModalVisible, currentItem } = this.state;
    const { jobLookups, dialogCloseRequest, jobs } = this.props
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    const parametersColumns = [
      { render: (row, i) => this.renderButtons(row, i), checkField: false },
      { label: i18n.t('lbl.key'), key: 'key' },
      { label: i18n.t('lbl.value'), key: 'value' }
    ]
    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...jobs, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="jobs">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('jobId', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.jobId
                    })(
                      <Select
                        className="#jobId"
                        label={i18n.t('lbl.jobName')}
                        options={jobLookups.data}
                        optName="name"
                        optVal="id"
                        onChange={(e) => this.update('jobId', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('cron', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.cron
                    })(
                      <Input className="#cron" label={`* ${i18n.t('lbl.cron')}`} onChange={(e) => this.update('cron', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('isActive', {
                      initialValue: data.isActive,
                      valuePropName: 'checked'
                    })(
                      <Checkbox className="#isActive" onChange={(e) => this.update('isActive', e.target.checked)}>{i18n.t('lbl.isActive')}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24}>
                  <DataTable
                    noHeader
                    data={data.tenantJobParameters}
                    columns={parametersColumns}
                    pagination={false}
                    title={i18n.t('lbl.parameters')}
                    toggle={false}
                    className="full-width mt-30"
                    newButton={() => this.setState({ parametersModalVisible: true, currentItem: new TenantJobModel(), itemIndex: null })}
                  />
                </Col>
              </Row>
            </Form>
          </Loading>

          <Modal
            title={i18n.t('lbl.parameter')}
            visible={parametersModalVisible}
            onCancel={() => this.setState({ parametersModalVisible: false })}
            onOk={this.saveParameter}
            okButtonProps={{ className: '#save', icon: 'save' }}
            cancelButtonProps={{ className: '#cancel', icon: 'close' }}
            okText={i18n.t('btn.save')}
            cancelText={i18n.t('btn.cancel')}
          >
            <Form>
              <Row gutter={10}>
                <Col span={12}>
                  <Input value={currentItem.key} className="#key" label={i18n.t('lbl.key')} onChange={(e) => this.updateParameter('key', e)} />
                </Col>
                <Col span={12}>
                  <Input value={currentItem.value} className="#value" label={i18n.t('lbl.value')} onChange={(e) => this.updateParameter('value', e)} />
                </Col>
              </Row>
            </Form>
          </Modal>
        </CheckAccessRight>
      </div>
    );
  };
};


const mapStateToProps = ({ admin }) => ({
  jobs: admin.jobs,
  jobLookups: admin.filter.jobs
});
const mapDispatchToProps = (dispatch) => ({
  getTenantJobs: (id, parameters, endpoint) => dispatch(actions.get({ url: endpoint || endpoints.job.tenantJob, parameters, key: 'jobs', id })),
  saveData: (data, method, endpoint, parameters, callback) => dispatch(actions[method]({ url: endpoint || endpoints.job.tenantJob, parameters, key: 'jobs', data }, callback)),
  getJobLookup: () => dispatch(actions.getFilter({ url: endpoints.job.jobLookup, key: "jobs" })),
  clearState: () => dispatch(actions.clearState("jobs"))
});
const JobForm = Form.create()(Job);
export default connect(mapStateToProps, mapDispatchToProps)(JobForm);