import React, { Component } from 'react';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { Form, Button, Input as AntInput, List, notification, Checkbox } from 'antd';
import { ListModel } from 'models';
import { httpMethods } from 'lib/constants';
import { connect } from "react-redux";
import actions from 'store/actions/netekstre';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import _ from 'lodash';

const operationType = 2;

class FieldList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new ListModel(_.cloneDeep(props.fieldDefinitions.single.data)),
    }
  };

  componentDidMount() {
    this.props.getSourceFields();
    this.props.getTargetFields(operationType);
  };


  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  itemsAdd = () => {
    let { data } = this.state;
    if (data.items.length > 0 && data.items.indexOf("") !== -1) {
      notification.error({ message: i18n.t('msg.pleaseFillOther') })
    }
    else {
      data.items.push("");
      this.setState({ data })
    }
  };

  targetUpdate = (index, key, value) => {
    let { data } = this.state;
    data.targets[index][key] = value;
    this.setState({ data });
  };

  itemsUpdate = (index, value) => {
    let { data } = this.state;
    data.items[index] = value;
    this.setState({ data });
  };

  itemsDelete = (index) => {
    let { data } = this.state;
    data.items.splice(index, 1);
    this.setState({ data });
  }

  save = () => {
    return new Promise((resolve) => {
      let { data } = this.state;
      let _data = _.cloneDeep(data);
      this.props.form.validateFields(error => {
        if (!error) {
          if (_data.items.length === 0 || _data.items.indexOf("") !== -1) {
            notification.error({ message: i18n.t('msg.addAtLeastOne') })
          }
          else {
            _data.operationType = operationType;
            let method = data.id ? httpMethods.put : httpMethods.post;
            this.props.saveData(_data, method, (response) => {
              response && resolve();
            });
          }
        }
      });
    })

  };

  render() {
    let { data } = this.state;
    let { getFieldDecorator } = this.props.form;
    let { targetFields } = this.props;
    return (
      <div>
        <Form>
          <Form.Item>
            {
              getFieldDecorator('name', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.name
              })(
                <Input className="#listName" onChange={(e) => this.update('name', e)} label={i18n.t("lbl.title")} />
              )
            }
          </Form.Item>
          <Form.Item>
            {
              getFieldDecorator('targets', {
                rules: [{ required: true, message: i18n.t("msg.required") }],
                initialValue: data.targets[0] ? data.targets[0].name : ''
              })(
                <Select className="#listTargets" options={targetFields.data} label={i18n.t("lbl.target")} optName="description" optVal="name" onChange={(e) => this.update('targets', [{ name: e, dynamicPropertyId: e ? targetFields.data.find(x => x.name === e).dynamicPropertyId : null, index: 1, modelState: 2 }])} />
              )
            }
          </Form.Item>
          <Form.Item>
            <h3 className="ml-10">{i18n.t('lbl.list')}</h3>
            <List bordered size="small">
              {data.items.map((x, i) =>
                <List.Item actions={[<Button shape="circle" icon="delete" size="small" onClick={() => this.itemsDelete(i)} />]} key={i}>
                  <AntInput className={`#listPattern${i}`} size="small" value={x} onChange={(e) => this.itemsUpdate(i, e.target.value)} />
                </List.Item>
              )}
              <List.Item>
                <div className="full-width text-center">
                  <Button className="#listAddPattern" shape="circle" icon="plus" size="small" onClick={this.itemsAdd} />
                </div>
              </List.Item>
            </List>
          </Form.Item>
          <Checkbox
            checked={data.targets[0] ? data.targets[0].required : false}
            onChange={(e) => this.targetUpdate(0, 'required', e.target.checked)}
            disabled={!data.targets[0]}
          >
            {i18n.t('lbl.required')}
          </Checkbox>
        </Form>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({
  sourceFields: netekstre.filter.sourceFields,
  targetFields: netekstre.filter.targetFields,
  fieldDefinitions: netekstre.fieldDefinitions
});
const mapDispatchToProps = (dispatch) => ({
  getSourceFields: () => dispatch(actions.getFilter({ url: endpoints.nte.propertyConfigsGetSourceFields, key: 'sourceFields' })),
  getTargetFields: (operationType) => dispatch(actions.getFilter({ url: endpoints.nte.propertyConfigsGetTargetFields(operationType), key: 'targetFields' })),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.propertyConfigs, data }, callback)),
  clearState: () => dispatch(actions.clearState("fieldDefinitions")),
});
const ListForm = Form.create()(FieldList);
export default connect(mapStateToProps, mapDispatchToProps)(ListForm);