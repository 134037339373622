import React, { Component } from 'react';
import { Divider, Radio, Icon, Tooltip } from 'antd';
import { DatePicker } from './NetbankaComponents';
import moment from 'moment'
import { connect } from 'react-redux';
import i18n from 'plugins/i18n';

class Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    }
    this.callbackTimeout = null;
  }

  updateFields = (key, value, parameter) => {
    let { fields } = this.state;
    const callbackType = parameter.withCallback ? 'withCallback' : parameter.callback ? 'callback' : null;
    fields[key] = value;
    this.setState({ fields }, () => {
      clearTimeout(this.callbackTimeout);
      if (parameter && (parameter[callbackType])) this.callbackTimeout = setTimeout(() => {
        parameter[callbackType](value, parameter.options && parameter.options.find(x => x.value === value))
      }, parameter.callbackTimeout || 1000);
      else this.callbackTimeout = setTimeout(() => {
        this.props.onChange(key, value);
      });
    });
  }

  render() {
    let { orientation, parameters } = this.props;
    let { fields } = this.state;
    return (
      <div className="parameter">
        <Divider orientation={orientation || undefined}>
          {
            parameters && parameters.length > 0 && parameters.map((parameter, i) =>
              <div className="parameter-inner" key={i}>
                {
                  parameter.type === "radio" &&
                  <Radio.Group
                    key={i}
                    value={fields[parameter.key] || parameter.defaultValue}
                    buttonStyle="solid"
                    defaultValue={parameter.options[0].value}
                    onChange={(e) => this.updateFields(parameter.key, e.target.value, parameter)}
                  >
                    {
                      parameter.options && parameter.options.length > 0 && parameter.options.map((option, j) =>
                        <Radio.Button key={j} value={option.value}>
                          {i18n.t(`lbl.${option.name}`)}
                          {
                            option.description &&
                            <Tooltip placement="bottom" title={i18n.t(`msg.${option.description}`)} className="ml-5 half-opacity">
                              <Icon type="info-circle" />
                            </Tooltip>
                          }
                        </Radio.Button>
                      )
                    }
                  </Radio.Group>
                }
                {
                  parameter.type === "date" &&
                  <DatePicker
                    allowClear={parameter.allowClear !== false}
                    label={parameter.label}
                    value={fields[parameter.key] || parameter.defaultValue}
                    onChange={(e) => this.updateFields(parameter.key, moment(e).format('YYYY-MM-DD[T00:00:00]', parameter))}
                    format="DD/MM/YYYY"
                    time={false}
                    className={`${parameter.uniqueKey}`}
                    mode={parameter.mode}
                    date={parameter.date}
                    hidePlaceholder
                  />
                }
              </div>
            )
          }
        </Divider>
      </div>
    );
  };
};
const mapStateToProps = ({ common }) => ({ common });
export default connect(mapStateToProps)(Parameter);