export const serviceTypes = [
  { id: 1, name: 'ftp' },
  { id: 2, name: 'sftp' },
  { id: 3, name: 'ws' },
]

export const matchStatus = [
  { value: 'ne', name: 'matched' },
  { value: 'eq', name: 'unmatched' }
]

export function isStartsGuid(string) {
  const isStartsGuidRegex = /^[0-9a-f]{8}$/i;
  return isStartsGuidRegex.test(string.substr(0, 8))
} 

export function isEditableOrder(orderStatusId){
  const notEditableIdList = [14,15,16,17];
  return !notEditableIdList.includes(orderStatusId);
}

export function isEditableInvoice(invoiceStatusId){
  const notEditableIdList = [2,3,5,6,7,8,9,10,11];
  return !notEditableIdList.includes(invoiceStatusId);
}