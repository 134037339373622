import React from 'react';
import { Modal, Tooltip } from 'antd';
import 'chartjs-plugin-labels';
import { formatCurrency } from 'helpers';
import Fade from 'react-reveal/Fade';


const PieChartModal = ({ visible, modalData, currencyCode, setVisible }) => {
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      title={<div className="text-center">{modalData.title}</div>}
      key={Math.random()}
    >
      <Fade bottom cascade>
        <div className="statement-container">
          {
            modalData.data.map((x, i) => (
              <div className="statement-row" key={i}>
                <div>
                  <Tooltip placement="left" title={x.tenantName} >
                    {x.tenantName}
                  </Tooltip>
                </div>
                <div>
                  {x.data === '--' ? x.data : modalData.type === 'amount' ? formatCurrency({ data: x.data, currencyCode, withColor: true }) : `${x.data} Adet`}
                </div>
              </div>
            ))
          }
        </div>
      </Fade>
    </Modal>
  )
}
export default PieChartModal