import React, { Component } from 'react';
import { Input } from 'components/UIComponents/NetbankaComponents';
import i18n from 'plugins/i18n';
import { Form } from 'antd';
import { currencySymbols } from 'lib/constants';

export default class BigAmount extends Component {

  onChange = val => {
    const { fieldName, onChange } = this.props
    onChange(fieldName || 'amount', val && parseFloat(val.replace(',', '.')));
  }

  render() {
    const { getFieldDecorator, label, currency, fieldName, required } = this.props;
    return (
      <div className="big-amount">
        <div className="big-amount__label">{label || i18n.t('lbl.amount')}</div>
        <Form.Item>
          {getFieldDecorator(fieldName || 'amount', {
            rules: [
              { required, message: i18n.t('msg.required') },
              { validator: (rule, value, callback) => value === "0" || value.startsWith('0') ? callback(true) : callback(), message: i18n.t('msg.noAcceptZero') },
              { validator: (rule, value, callback) => value[value.length - 1] === (".") || value[value.length - 1] === (",") ? callback(true) : callback(), message: i18n.t('msg.notEndDotOrComma') },
              { validator: (rule, value, callback) => value && parseFloat(value.toString().replace(',', '.')) < 0 ? callback(true) : callback(), message: i18n.t('msg.cannotBeLessThan0') },
              { validator: (rule, value, callback) => value === "-" || value === "+" ? callback(true) : callback(), message: i18n.t('msg.signErrorNull') },
              { validator: (rule, value, callback) => (value.length > 1 && value.includes("-")) || (value.length > 1 && value.includes("+")) ? callback(true) : callback(), message: i18n.t('msg.signError') }
            ],
            initialValue: ''
          })(
            <Input placeholder="0,00" type="amount" className="#amount" prefix={currencySymbols[currency]} onChange={this.onChange} />
          )}
        </Form.Item>
      </div>
    )
  }
}
