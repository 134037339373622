import React, { Component } from 'react';
import { Tabs, Button } from 'antd';
import { List, TextInput, AutomaticRule } from './components';
import _ from 'lodash';
import i18n from 'plugins/i18n';
import { FieldDefinitionModel } from 'models';
import { connect } from 'react-redux';
import actions from 'store/actions/netekstre';
import endpoints from 'config/endpoints';
import { Loading, CheckAccessRight } from 'components/UIComponents';

class FieldDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new FieldDefinitionModel(),
      InitialState: new FieldDefinitionModel(),
      current: "3",
      currentTabName: {
        1: 'textInput',
        2: 'list',
        3: 'automaticRule',
      },
      isDisabled: false,
    };
    this.id = props.id;
  };

  componentDidMount() {
    if (this.id) this.props.getDefinitions(this.id);
    else this.setState({ loading: false });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fieldDefinitions.single.data && !state.data.id) {
      const data = new FieldDefinitionModel(props.fieldDefinitions.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false, current: data.operationType ? data.operationType.toString() : state.current, isDisabled: true }
    }
    if (props.dialogClosing) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  renderDialogButtons = checkAccessRights => {
    let { fieldDefinitions, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={fieldDefinitions.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={fieldDefinitions.single.loading} form="dynamicFields" htmlType="button" className="success #save" onClick={this.onSave} icon="save" loading={fieldDefinitions.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      fieldDefinitions.single.saving,
      fieldDefinitions.single.loading
    )
  }

  onSave = () => {
    const { current, currentTabName } = this.state;
    this[currentTabName[current]].save()
      .then(() => this.setState({ InitialState: this.state.data }, () => { this.props.dialogCloseRequest({ runGetData: true }); }));
  }

  render() {
    const { current, isDisabled, loading } = this.state;
    const { dialogCloseRequest, fieldDefinitions } = this.props;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <CheckAccessRight {...{ ...fieldDefinitions, renderDialogButtons, dialogCloseRequest }}>
        <Loading loading={loading}>
          <Tabs type="card" onChange={(e) => this.setState({ current: e })} activeKey={current}>
            <Tabs.TabPane disabled={isDisabled && current !== "3"} className="#autoRule" tab={i18n.t("lbl.automaticRule")} key="3">
              <AutomaticRule wrappedComponentRef={el => this.automaticRule = el} />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={isDisabled && current !== "1"} className="#textInput" tab={i18n.t("lbl.textInput")} key="1">
              <TextInput wrappedComponentRef={el => this.textInput = el} />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={isDisabled && current !== "2"} className="#list" tab={i18n.t("lbl.list")} key="2">
              <List wrappedComponentRef={el => this.list = el} />
            </Tabs.TabPane>
          </Tabs>
        </Loading>
      </CheckAccessRight>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({
  fieldDefinitions: netekstre.fieldDefinitions,
});

const mapDispatchToProps = (dispatch) => ({
  getDefinitions: (id, callback) => dispatch(actions.get({ id, url: endpoints.nte.propertyConfigs, key: 'fieldDefinitions' }, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FieldDescription);