import React, { Component, Suspense, lazy } from 'react';
import { Card, Tabs } from 'antd';
import i18n from 'plugins/i18n';
import { Loading } from 'components/UIComponents';
import { commRatesLayoutData } from 'lib/constants';
import { connect } from 'react-redux';
import endpoints from 'config/endpoints';
import actions from 'store/actions/netahsilat';
import commonActions from 'store/actions/common';
import { CommRateModel } from 'models';
import _ from 'lodash'

const CommissionRates = lazy(() => import('./components/CommissionRates'));
const PlusInstallment = lazy(() => import('./components/PlusInstallment'));
const DefaultPos = lazy(() => import('./components/DefaultPos'));

class Commissions extends Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.hasCommRate = props.location.state?.HasCommRate;
    this.isDefaultPos = props.location.state?.IsDefaultPos;

    if (this.hasCommRate === undefined) props.history.push('/netahsilat/pos')
    this.state = {
      current: 'commRates',
      data: this.hasCommRate ? [] : commRatesLayoutData()
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.commRates.data.length && props.commRates.loading === false)
      return { data: _.cloneDeep(props.commRates.data.map(x => new CommRateModel(x))) }

    return null;
  };

  componentDidMount() {
    if (this.hasCommRate) this.props.getCommRates(this.id);
  }

  componentWillUnmount() {
    this.props.clearDatatableBulkValues();
    this.props.clearState();
  }

  onTabChange = (key, data, parentTenantId) => {
    this.setState({ current: key })
    // const { id, parentTenantId: parentId } = this.state;
    // if (id || data) this.setState({ current: key, id: data || id, parentTenantId: parentTenantId || parentId })
  }

  render() {
    const { current, data } = this.state;
    const { commRates, history } = this.props;
    return (
      <div className="page-content">
        <Card title={<span>{i18n.t('lbl.posCommission')}</span>} bodyStyle={{ padding: '0 0 20px 0' }}>

          <Tabs activeKey={current} className="no-hover" onChange={this.onTabChange} tabBarStyle={{ padding: '0 20px' }}>

            <Tabs.TabPane key="commRates" tab={i18n.t(`lbl.commRates`)}>
              <Suspense fallback={<Loading />}>
                <CommissionRates {...{ onTabChange: this.onTabChange, data, loading: commRates.loading, history, hasCommRate: this.hasCommRate }} />
              </Suspense>
            </Tabs.TabPane>

            <Tabs.TabPane key="plusInstallment" tab={i18n.t(`lbl.instalmentDeferralAndPlusInstallment`)}>
              <Suspense fallback={<Loading />}>
                <PlusInstallment {...{ onTabChange: this.onTabChange, data, tenantPosId: this.id, loading: commRates.loading, history, hasCommRate: this.hasCommRate, isDefaultPos: this.isDefaultPos }} />
              </Suspense>
            </Tabs.TabPane>

            {
              this.isDefaultPos &&
              <Tabs.TabPane key="defaultPos" tab={i18n.t(`lbl.defaultPos`)}>
                <Suspense fallback={<Loading />}>
                  <DefaultPos {...{ onTabChange: this.onTabChange, tenantPosId: this.id, history }} />
                </Suspense>
              </Tabs.TabPane>
            }

          </Tabs>
        </Card>
      </div>
    )
  }
}
const mapStateToProps = ({ netahsilat }) => ({
  commRates: netahsilat.commRates.list
});
const mapDispatchToProps = (dispatch) => ({
  getCommRates: (id) => dispatch(actions.getAll({ id, url: endpoints.netahsilat.posCommRates, key: 'commRates' })),
  clearState: () => dispatch(actions.clearState("commRates", true)),
  clearDatatableBulkValues: () => dispatch(commonActions.clearDatatableBulkValues()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Commissions);
