import React, { Component } from 'react';
import { Card, Row, Col, Skeleton, Empty, Switch, Tooltip } from 'antd';
import { formatCurrency } from 'helpers';
import _ from 'lodash';
import DataTable from './Datatable';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import i18n from 'plugins/i18n';

class ItemsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCard: true,
      flipped: false,
      cardData: [],
      key: null,
      type: null,
    };
  };

  setCardData = (rows, bank) => {
    let { cardData } = this.state;
    cardData[bank] = rows;
    rows.length > 0 && this[bank].toggle();
    this.setState({ cardData });
  }

  onSort = (key, type) => {
    this.setState({ key, type })
  };

  onCurrencyClick = (items, y, x) => {
    let _tenants = _.cloneDeep(this.props.tenants)
    this.setCardData(
      _tenants.sort((a, b) => a.name.localeCompare(b.name)).map(({ name, id }, i) =>
        <div key={i}>
          <Tooltip placement="left" title={name}>
            <span className="tenant-name">{name}</span>
          </Tooltip>
          <span className="ml-5 mr-5">:</span>
          {
            items[x].find(x => x.tenantId === id && x.currencyCode === y) ?
              formatCurrency({ data: items[x].find(x => x.tenantId === id && x.currencyCode === y).balance, currencyCode: y, withColor: true })
              :
              '--'
          }
        </div>
      ),
      x
    )
  }

  onTotalCurrencyClick = (items, currency) => {
    let _tenants = _.cloneDeep(this.props.tenants)
    this.setCardData(
      _tenants.sort((a, b) => a.name.localeCompare(b.name)).map(({ name, id }, i) =>
        <div key={i}>
          <Tooltip placement="left" title={name}>
            <span className="tenant-name">{name}</span>
          </Tooltip>
          <span className="ml-5 mr-5">:</span>
          {
            formatCurrency(
              {
                data: Object
                  .keys(items)
                  .map(item =>
                    items[item]
                      .filter(i => (i.currencyCode === currency) && (i) && (i.tenantId === id)) &&
                    items[item]
                      .filter(i =>
                        (i.currencyCode === currency) && (i) && (i.tenantId === id))
                      .reduce((a, b) => a + b.balance, 0))
                  .reduce((a, b) => a + b, 0),
                currencyCode: currency,
                withColor: true
              })
          }
        </div>
      ),
      'total'
    )
  }

  getData = () => {
    let { items } = this.props;
    let data = Object.keys(items).map(x => {
      let _balance = { bankName: x };
      items[x].forEach(y => {
        _balance[y.currencyCode] = _balance[y.currencyCode] || 0;
        _balance[y.currencyCode] += y.balance;
      });
      return _balance;
    });
    return data;
  }

  getSubItemsLength = (items, keys) => {
    let maxLengthItems = 0, maxLengthData = keys.length;
    _.keys(items).map(x => maxLengthItems = _.uniqBy(items[x], "tenantId").length > maxLengthItems ? _.uniqBy(items[x], "tenantId").length : maxLengthItems)
    const maxLength = maxLengthData >= maxLengthItems ? (maxLengthData * 24) : ((maxLengthItems * 20) / 2);
    document.documentElement.style.setProperty('--balance-content-max-height', `${maxLength}px`)
  }

  render() {
    const { isCard, key, type, cardData } = this.state;
    const { title, loading, keys, items, multiTenant, isCumulative } = this.props;
    let columns = [
      { label: '', key: 'bankName' },
      ...keys.map(x => {
        return {
          label: isCumulative ? i18n.t('lbl.cumulativeBalance', { currency: x }) : x,
          key: x,
          tdClass: "text-right",
          thClass: "text-center",
          render: (row) => row[x] !== null && row[x] !== undefined ? formatCurrency({ data: row[x], currencyCode: x, withColor: true }) : '-'
        };
      }),
    ];

    let data = this.getData();
    if (key && type) {
      data = _.sortBy(data, key)
      type === 'desc' && data.reverse();
    }


    let total = { bankName: isCumulative ? `${i18n.t('lbl.cumulative')} ${i18n.t('lbl.total')}` : i18n.t('lbl.total') };
    // eslint-disable-next-line
    keys.map(x => {
      total[x] = items ? Object.keys(items).map(y => items[y].filter(z => z.currencyCode === x).reduce((a, b) => a + b.balance, 0)).reduce((a, b) => a + b, 0) : 0
    });
    data.push(total);
    this.getSubItemsLength(items, keys);
    // this.getSubItemsLength(items, keys);

    return (
      <Card className={`cumulative-card ${isCard ? '' : 'no-padding'}`} title={
        <React.Fragment>
          <span>{title || ''}</span>
          <Switch checkedChildren={i18n.t('lbl.card')} unCheckedChildren={i18n.t('lbl.table')} checked={isCard} className="pull-right" onChange={(isCard) => this.setState({ isCard })} />
        </React.Fragment>
      }>
        {isCard ?
          <Skeleton loading={loading} active>
            {items && Object.keys(items).length ?
              <Row gutter={20}>
                {Object.keys(items).sort((a, b) => a.localeCompare(b)).map((x, i) => (
                  <Col className="mb-20" key={i} xs={24} sm={12} md={8} lg={8} xl={6}>
                    <Flippy
                      flipOnHover={false}
                      flipDirection="horizontal"
                      ref={(r) => this[x] = r}
                    >
                      <FrontSide>
                        <Card className="balance-card text-center" onClick={(e) => e.stopPropagation()}>
                          <img src={items[x][0].bankImage} alt="" />
                          <div className="balance-content">
                            {keys.map(y => (
                              multiTenant
                                ?
                                items[x].find(x => x.currencyCode === y) ?
                                  <h2 key={y} className="clickable-currency" onClick={() => this.onCurrencyClick(items, y, x)}>
                                    {formatCurrency({ data: items[x].filter(x => x.currencyCode === y).reduce((a, b) => a + b.balance, 0), currencyCode: y, withColor: true })}
                                  </h2>
                                  :
                                  <h2 key={y} onClick={(e) => e.stopPropagation()}>
                                    -
                                  </h2>
                                :
                                <h2 key={y}>
                                  {
                                    items[x].find(x => x.currencyCode === y) ?
                                      formatCurrency({ data: items[x].filter(x => x.currencyCode === y).reduce((a, b) => a + b.balance, 0), currencyCode: y, withColor: true })
                                      :
                                      '-'
                                  }
                                </h2>
                            ))}
                          </div>
                        </Card>
                      </FrontSide>
                      <BackSide>
                        <Card className="balance-card text-center pointer">
                          <img src={items[x][0].bankImage} alt="" />
                          <div className="balance-content text-left">
                            {cardData[x] && cardData[x].map(c => c)}
                          </div>
                        </Card>
                      </BackSide>
                    </Flippy>
                  </Col>
                ))}
                <Col className="mb-20" xs={24} sm={12} md={8} lg={8} xl={6}>
                  <Flippy
                    flipOnHover={false}
                    flipDirection="horizontal"
                    ref={(r) => this.total = r}
                  >
                    <FrontSide>
                      <Card className="balance-card total text-center" onClick={(e) => e.stopPropagation()}>
                        <h1>{i18n.t('lbl.total').toUpperCase()}</h1>
                        <div className="balance-content">
                          {keys.map(currency => (

                            multiTenant
                              ?
                              items ?
                                <h2 key={currency} className="clickable-currency"
                                  onClick={() => this.onTotalCurrencyClick(items, currency)}
                                >
                                  {formatCurrency({
                                    data: Object
                                      .keys(items)
                                      .map(y =>
                                        items[y]
                                          .filter(z => (z.currencyCode === currency) && (z))
                                          .reduce((a, b) => a + b.balance, 0)
                                      )
                                      .reduce((a, b) => a + b, 0),
                                    currencyCode: currency,
                                    withColor: true
                                  })
                                  }
                                </h2>
                                :
                                <h2 key={currency}>
                                  '-'
                                </h2>
                              :
                              <h2 key={currency}>
                                {
                                  items ?
                                    formatCurrency({
                                      data: Object
                                        .keys(items)
                                        .map(y =>
                                          items[y]
                                            .filter(z => (z.currencyCode === currency) && (z))
                                            .reduce((a, b) => a + b.balance, 0)
                                        )
                                        .reduce((a, b) => a + b, 0),
                                      currencyCode: currency,
                                      withColor: true
                                    })
                                    :
                                    formatCurrency({ data: 0, currencyCode: currency, withColor: true })
                                }
                              </h2>

                          ))}
                        </div>
                      </Card>
                    </FrontSide>
                    <BackSide>
                      <Card className="balance-card total text-center">
                        <h1>{i18n.t('lbl.total').toUpperCase()}</h1>
                        <div className="balance-content">
                          {cardData.total && cardData.total.map(c => c)}
                        </div>
                      </Card>
                    </BackSide>
                  </Flippy>
                </Col>
              </Row>
              :
              <Empty />
            }
          </Skeleton>
          :
          <DataTable
            withTotal
            checkFields={false}
            onSort={this.onSort}
            pagination={false}
            toggle={false}
            columns={columns}
            data={data}
          />
        }
      </Card>
    );
  };
};

export default ItemsCard;