import React from 'react'
import { store } from 'store/redux/createStore';
import endpoints from 'config/endpoints';
import actions from 'store/actions/admin';
import { get } from 'lodash';
import { categoryTypes } from './constants';

export default class AdminUtils {

  static getCurrencies() {
    dispatchers.getCurrencies({ orderBy: ['name asc'] });
  }

  static getBankName({ data = null, dataField = 'bankEftCode', listKeys = 'tenantBanks', listField = 'eftCode', bankData = null }) {
    let list, bank;
    if (!bankData) {
      list = get(store.getState().admin.filter, `${listKeys}.data`);
      bank = list.find(x => x[listField] === get(data, dataField))
    }
    else
      bank = bankData;

    return bank ? <><img src={bank.logoSmall} className="bank-icon" alt="" /> {bank.name}</> : '';
  }

  static getTenantBanks() {
    dispatchers.getTenantBanks({ orderBy: ['name asc'] });
  }

  static getBanks() {
    dispatchers.getBanks({ orderBy: ['name asc'] });
  }

  static getTransferStatus() {
    dispatchers.getTransferStatus();
  }

  static getVoucherTypeCodes() {
    dispatchers.getVoucherTypeCodes();
  }

  static getErpTypes() {
    dispatchers.getErpTypes();
  }

  static getCategories(type) {
    let filter = { filter: { or: [{ ParentId: { ne: null }, CategoryType: categoryTypes[`${type}Transaction`] }, { CategoryType: 0 }] } };
    dispatchers.getCategories(`${type.toLowerCase()}Categories`, filter);
  }

  static getGeneralCategories(type) {
    let filter = { filter: { or: [{ CategoryType: categoryTypes[`${type}Transaction`] }, { CategoryType: 0 }] } };
    dispatchers.getGeneralCategories(`${type.toLowerCase()}Categories`, filter);
  }

  static getTypeCodes(level) {
    let filter = { filter: { level } }
    dispatchers.getTypeCodes(filter);
  }

  static getTenantLevels({ level, convertEnum = false }) {
    const filter = { filter: { level } }
    dispatchers.getTenantLevels(filter, convertEnum);
  }

  static clearFilter(key) {
    dispatchers.clearFilter(key);
  }

  static clearState(key, options) {
    dispatchers.clearState(key, options);
  }
}


const dispatchers = {
  getCurrencies: (filter) => store.dispatch(actions.getFilter({ url: endpoints.tenant.currencies, key: 'currencies', filter })),
  getTenantBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.tenantBanks, key: 'tenantBanks', filter })),
  getBanks: (filter) => store.dispatch(actions.getFilter({ url: endpoints.lookups.banks, key: 'banks', filter })),
  getTransferStatus: () => store.dispatch(actions.getFilter({ url: endpoints.nte.transferStatusGetRuleTransferStatus, key: 'transferStatus' })),
  getVoucherTypeCodes: () => store.dispatch(actions.getFilter({ url: endpoints.tenant.voucherTypes, key: 'voucherTypes', isNewODataStructure: true })),
  getCategories: (key, filter) => store.dispatch(actions.getFilter({ url: endpoints.tenant.categories, key, filter, isNewODataStructure: true })),
  getGeneralCategories: (key, filter) => store.dispatch(actions.getFilter({ url: endpoints.nte.tenantCategories, key, filter, isNewODataStructure: true })),
  getTypeCodes: (filter) => store.dispatch(actions.getFilter({ filter, url: endpoints.lookups.typeCodes, key: `typeCodes${filter.filter.level}` })),
  getErpTypes: () => store.dispatch(actions.getFilter({ url: endpoints.lookups.erpTypes, key: 'erpTypes' })),
  getTenantLevels: (filter, convertEnum) => store.dispatch(actions.getFilter({ url: endpoints.lookups.tenantLevels, key: 'tenantLevels', filter, convertEnum })),
  clearFilter: (key) => store.dispatch(actions.clearFilter(key)),
  clearState: (key, options) => store.dispatch(actions.clearState(key, options))
}
