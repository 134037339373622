import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Checkbox } from 'antd';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import { BankAccountModel, BankAccountInfosModel } from 'models';
import commonActions from 'store/actions/common';
import netekstreActions from 'store/actions/netekstre';
import { Loading, FadeIn, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { httpMethods } from 'lib/constants';
import utils from 'lib';

class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new BankAccountModel(),
      bankAccountsInfos: new BankAccountInfosModel(),
      InitialState: null,
      userHasNetekstreModule: props.modules.find(x => x.name === "NTE")
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.bankAccounts.single.data && props.bankAccounts.single.saving === null && !state.data.id) {
      const data = new BankAccountModel(props.bankAccounts.single.data);
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.bankAccountsInfos.single.data && !state.bankAccountsInfos.id) {
      const data = new BankAccountInfosModel(props.bankAccountsInfos.single.data);
      return { bankAccountsInfos: _.cloneDeep(data) }
    }
    if (props.dialogClosing && !props.bankAccountsInfos.single.saving) {
      props.onDialogClose({ ...state.InitialState }, { ...state.data })
    }
    return null;
  };

  componentDidMount() {
    const { userHasNetekstreModule } = this.state
    this.getRoles();

    if (this.id) {
      this.props.getBankAccount(this.id, response => {
        if (userHasNetekstreModule) this.props.getBankAccountInfos(response.id)
        this.getTenantBankModules(response.tenantBankGuid);
      });
    }
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearStateBankAccounts();
    this.props.clearStateBankAccountInfos();
    utils.common.clearFilter('tenantBankModules');
  }

  getTenantBankModules = tenantBankGuid => {
    this.props.getTenantBankModules(tenantBankGuid)
  }

  getRoles = () => {
    let { filter } = { filter: { deleted: false } }
    this.props.getRoles(filter);
  }

  update = (key, value) => {
    let { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  updateBankAccountInfo = (key, value) => {
    let { bankAccountsInfos } = this.state;
    bankAccountsInfos[key] = value;
    this.setState({ bankAccountsInfos });
  };

  getCurrencies = () => {
    this.props.getCurrencies();
  };

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest, saveBankAccountsInfosData } = this.props;
    const { data, bankAccountsInfos, userHasNetekstreModule } = this.state
    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, (response) => {
          if (response) {
            if (userHasNetekstreModule) {
              bankAccountsInfos.bankAccountId = response.data;
              saveBankAccountsInfosData(bankAccountsInfos, method, () => {
                this.setState({ InitialState: data }, () => { dialogCloseRequest({ runGetData: true }); })
              })
            }
            else {
              this.setState({ InitialState: data }, () => { dialogCloseRequest({ runGetData: true }); })
            }
          }
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { bankAccounts, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={bankAccounts.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={bankAccounts.single.loading} form="bankAccounts" htmlType="submit" className="success #save" icon="save" loading={bankAccounts.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      bankAccounts.single.saving,
      bankAccounts.single.loading
    )
  }

  render() {
    const { bankAccounts, tenantBanks, transactionTransferTypes, currencies, roles, dialogCloseRequest, tenantBankModules } = this.props;
    const { loading, data, bankAccountsInfos, userHasNetekstreModule } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;

    renderDialogButtons();

    return (
      <FadeIn className="page-content">
        <CheckAccessRight {...{ ...bankAccounts, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="bankAccounts">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('bankEftCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.bankEftCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bank')}
                        options={tenantBanks.data}
                        optVal="bankEftCode"
                        optName={(bankData) => utils.common.getBankName({ bankData })}
                        onChange={(e) => {
                          this.update('bankEftCode', e);
                          if (e) this.getTenantBankModules(tenantBanks.data.find(x => x.bankEftCode === e).id)
                          else utils.common.clearFilter('tenantBankModules')
                        }}
                        disabled={!!this.id}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#branchNumber" label={i18n.t('lbl.branchNumber')} value={data.branchNumber} onChange={(e) => this.update('branchNumber', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#branchName" label={i18n.t('lbl.branchName')} value={data.branchName} onChange={(e) => this.update('branchName', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('accountNumber', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.accountNumber
                    })(
                      <Input className="#accountNumber" label={i18n.t('lbl.accountNumber')} onChange={(e) => this.update('accountNumber', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('accountName', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.name
                    })(
                      <Input className="#accountName" label={i18n.t('lbl.accountName')} onChange={(e) => this.update('name', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#suffix" label={i18n.t('lbl.suffix')} value={data.suffix} onChange={(e) => this.update('suffix', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('iban', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.iban
                    })(
                      <Input className="#iban" label={i18n.t('lbl.iban')} type="iban" onChange={(e) => this.update('iban', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#erpCode" label={i18n.t('lbl.erpCode')} value={data.erpCode} onChange={(e) => this.update('erpCode', e)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Select
                      className="#currencyCode"
                      label={i18n.t('lbl.currencyCode')}
                      value={data.currencyCode}
                      options={currencies.data}
                      optVal="currencyCode"
                      optName="currencyName"
                      allowClear={false}
                      onChange={(e) => this.update('currencyCode', e)}
                    />
                  </Form.Item>
                </Col>
                {
                  userHasNetekstreModule &&
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Select
                        className="#transactionTransferType"
                        label={i18n.t('lbl.transactionTransferType')}
                        value={bankAccountsInfos.transactionTransferType}
                        options={transactionTransferTypes.data}
                        optVal="id"
                        optName="description"
                        onChange={(e) => { this.updateBankAccountInfo('transactionTransferType', e); this.update('transactionTransferType', e) }}
                      />
                    </Form.Item>
                  </Col>
                }
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('roleIds', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.roleIds
                    })(
                      <Select
                        mode="multiple"
                        className="#roleIds"
                        label={i18n.t('lbl.roles')}
                        options={roles.data || []}
                        optName="name"
                        optVal="id"
                        onChange={(e) => this.update('roleIds', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('modules', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.modules
                    })(
                      <Select
                        loading={tenantBankModules.loading}
                        mode="multiple"
                        className="#modules"
                        label={i18n.t('lbl.modules')}
                        options={tenantBankModules.data}
                        optName="name"
                        optVal="name"
                        onChange={(e) => this.update('modules', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Input className="#criticalBalanceLimit" label={i18n.t('lbl.criticalBalanceLimit')} value={data.criticalBalanceLimit} onChange={(e) => { this.updateBankAccountInfo('criticalBalanceLimit', e); this.update('criticalBalanceLimit', e) }} />
                    {/* criticalBalanceLimit => Bu alan BE tarafı eklediğinde değiştirilebilir. */}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Checkbox className="#isActive" checked={data.isActive} onChange={({ target }) => this.update('isActive', target.checked)}>{i18n.t('lbl.active')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </FadeIn>
    );
  };
};

const mapStateToProps = ({ netekstre, common, auth }) => ({
  modules: auth.data.modules,

  transactionTransferTypes: netekstre.filter.transactionTransferTypes,
  bankAccountsInfos: netekstre.bankAccountsInfos,

  tenantBankModules: common.filter.tenantBankModules,
  bankAccounts: common.bankAccounts,
  tenantBanks: common.filter.tenantBanks,
  currencies: common.filter.currencies,
  roles: common.filter.roles
});
const mapDispatchToProps = (dispatch) => ({
  getTenantBankModules: (id) => dispatch(commonActions.getFilter({ url: endpoints.lookups.tenantBankModules, key: 'tenantBankModules', id })),
  getBankAccount: (id, callback) => dispatch(commonActions.get({ url: endpoints.tenant.bankAccounts, key: 'bankAccounts', id }, callback)),
  saveData: (data, method, callback) => dispatch(commonActions[method]({ url: endpoints.tenant.bankAccounts, key: 'bankAccounts', data }, callback)),

  getBankAccountInfos: (id, callback) => dispatch(netekstreActions.get({ url: endpoints.nte.bankAccountInfosGetByBankAccountId, key: 'bankAccountsInfos', id }, callback)),
  saveBankAccountsInfosData: (data, method, callback) => dispatch(netekstreActions[method]({ url: endpoints.nte.bankAccountInfos, key: 'bankAccountsInfos', data }, callback)),

  getRoles: (filter) => dispatch(commonActions.getFilter({ url: endpoints.lookups.roles, key: 'roles', filter })),
  clearStateBankAccounts: () => dispatch(commonActions.clearState("bankAccounts")),
  clearStateBankAccountInfos: () => dispatch(netekstreActions.clearState("bankAccountsInfos"))
});
const BankAccountForm = Form.create()(BankAccount);
export default connect(mapStateToProps, mapDispatchToProps)(BankAccountForm);