import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckAccessRight, Loading } from "components/UIComponents";
import { Button, Col, Form, Row } from "antd";
import { ChequeModel } from "models";
import i18n from "plugins/i18n";
import actions from "store/actions/cek";
import endpoints from "config/endpoints";
import { httpMethods } from "lib/constants";
import _ from 'lodash';
import { DatePicker, Input, Select } from "components/UIComponents/NetbankaComponents";
import utils from "lib";
import { isTcknValidator, isVknValidator } from 'helpers';


class Cheque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new ChequeModel(),
      initialState: new ChequeModel()
    };
    this.id = props.id;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.cheques.single.data && !state.data.Id) {
      const data = new ChequeModel({ ...props.cheques.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.cheques.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    this.props.getCurrencies();
    utils.common.getTenantBanks();
    if (this.id) this.props.getCheques(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  customValidator = (rule = null, value = null, callback) => {
    const { taxNumber } = this.state.data;

    if (taxNumber.length === 11 && isTcknValidator(taxNumber)) callback();
    else if (taxNumber.length === 10 && isVknValidator(taxNumber)) callback();
    else callback(true);
  }

  update = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data });
  };

  save = (e) => {
    e.preventDefault();
    const { dialogCloseRequest, form, saveData } = this.props;
    form.validateFields(error => {
      if (!error) {
        const data = _.cloneDeep(this.state.data);
        data.Amount = parseInt(data.Amount);
        const method = this.id ? httpMethods.put : httpMethods.post;
        saveData(data, method, () => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = () => {
    const { cheques, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={cheques.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={cheques.single.loading} form="creatingCheque" htmlType="submit" className="success #save" icon="save" loading={cheques.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      cheques.single.saving,
      cheques.single.loading
    )
  }


  render() {
    const { loading, data } = this.state;
    const { chequeBanksCek, chequeBanksCommon, chequeCurrencies, cheques, dialogCloseRequest } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { renderDialogButtons } = this;
    const tenantBanks = _.intersectionWith(chequeBanksCek.data, chequeBanksCommon.data, ({ Code }, { bankEftCode }) => bankEftCode === Code);
    renderDialogButtons();

    return (
      <div className="page-content">
        <CheckAccessRight {...{ ...cheques, renderDialogButtons, dialogCloseRequest }}>
          <Loading loading={loading}>
            <Form onSubmit={this.save} id="creatingCheque">
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('BankCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.BankCode
                    })(
                      <Select
                        className="#bank"
                        label={i18n.t('lbl.bankName')}
                        options={tenantBanks}
                        optVal="Code"
                        optName={(bankData) => utils.tos.getBankName({ bankData })}
                        onChange={(e) => this.update('BankCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeUserName', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ChequeUserName
                    })(
                      <Input className="#ChequeUserName" label={i18n.t('lbl.name')} onChange={(e) => this.update('ChequeUserName', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeUserSurname', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ChequeUserSurname
                    })(
                      <Input className="#ChequeUserSurname" label={i18n.t('lbl.surname')} onChange={(e) => this.update('ChequeUserSurname', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeUserVkn', {
                      rules: [
                        { required: !data.ChequeUserTckn, message: i18n.t('msg.required') },],
                      initialValue: data.ChequeUserVkn
                    })(
                      <Input className="#ChequeUserVkn" label={i18n.t('lbl.taxNumber')} onChange={(e) => this.update('ChequeUserVkn', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeUserTckn', {
                      rules: [{ required: !data.ChequeUserVkn, message: i18n.t('msg.required') }],
                      initialValue: data.ChequeUserTckn
                    })(
                      <Input className="#ChequeUserTckn" label={i18n.t('lbl.tc')} onChange={(e) => this.update('ChequeUserTckn', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('MICR', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.MICR
                    })(
                      <Input className="#MICR" label={i18n.t('lbl.MICR')} onChange={(e) => this.update('MICR', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ChequeCode
                    })(
                      <Input className="#ChequeCode" label={i18n.t('lbl.chequeNumber')} onChange={(e) => this.update('ChequeCode', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('City', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.City
                    })(
                      <Input className="#City" label={i18n.t('lbl.kesideCity')} onChange={(e) => this.update('City', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('ChequeDate', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.ChequeDate
                    })(
                      <DatePicker label={i18n.t('lbl.kesideDate')} className="#ChequeDate" onChange={e => this.update('ChequeDate', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('Amount', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.Amount.toString()
                    })(
                      <Input className="#Amount" label={i18n.t('lbl.amount')} onChange={(e) => this.update('Amount', e)} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('CurrencyCode', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.CurrencyCode
                    })(
                      <Select
                        loading={chequeCurrencies.loading}
                        className="#CurrencyCode"
                        label={i18n.t('lbl.currencyCode')}
                        options={chequeCurrencies.data}
                        optVal="Code"
                        optName="Name"
                        allowClear={false}
                        onChange={(e) => this.update('CurrencyCode', e)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    {getFieldDecorator('Description', {
                      rules: [{ required: true, message: i18n.t('msg.required') }],
                      initialValue: data.Description
                    })(
                      <Input className="#Description" label={i18n.t('lbl.description')} onChange={(e) => this.update('Description', e)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Loading>
        </CheckAccessRight>
      </div>
    );
  }
}

const mapStateToProps = ({ cek, common }) => ({
  cheques: cek.cheques,
  chequeBanksCek: cek.filter.tenantBanks,
  chequeCurrencies: cek.filter.currencies,
  chequeBanksCommon: common.filter.tenantBanks
});

const mapDispatchToProps = (dispatch) => ({
  getCheques: (id, callback) => dispatch(actions.get({ url: endpoints.cek.cheques, key: 'cheques', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.cek.cheques, key: 'cheques', data }, callback)),
  getCurrencies: (id, callback) => dispatch(actions.getFilter({ url: endpoints.cek.tenantCurrencies, key: 'currencies', id }, callback)),
  clearState: () => dispatch(actions.clearState("cheques"))
});
const ChequeForm = Form.create()(Cheque);
export default connect(mapStateToProps, mapDispatchToProps)(ChequeForm);