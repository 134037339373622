import { isTest } from 'helpers'

export default {
  path: '/netekstre',
  name: 'netekstre',
  component: require('components/Modules/Layout').default,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: require('components/Modules/NetEkstre/Dashboard').default,
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: require('components/Modules/NetEkstre/Transactions/List').default,
      access: 'BankAccountTransactions'
    },
    {
      path: '/transaction/split/:id?',
      name: 'transactions',
      component: require('components/Modules/NetEkstre/Transactions/Split').default,
      access: 'BankAccountTransactions',
      visible: false,
    },
    {
      path: '/balances',
      name: 'balances',
      component: require('components/Modules/NetEkstre/Balances/List').default,
      access: 'BankAccountBalances',
    },
    {
      path: '/definitions',
      name: 'definitions',
      component: require('components/Modules/NetEkstre/Definitions').default,
      access: ['TenantBankParameterGetByEftCode', 'Rules', 'Notifications', 'TenantCategories', 'DynamicProperties', 'PropertyConfigs', 'CurrentAccountDynamicProperties'], // [0]. index BankParameters olacak
      routes: [
        {
          path: '/bankparameters',
          name: 'bankParameters',
          access: 'TenantBankParameterGetByEftCode', // BankParameters
          component: require('components/Modules/NetEkstre/Definitions/BankParameters/List').default,
        },
        {
          path: '/rules',
          name: 'rules',
          access: ['Rules', 'DynamicProperties', 'PropertyConfigs'],
          component: require('components/Modules/NetEkstre/Definitions/Rules').default,
          routes: [
            {
              path: '/general',
              name: 'generalRules',
              access: 'Rules',
              component: require('components/Modules/NetEkstre/Definitions/RuleDefinitions/Rules/GeneralRules/List').default,
            },
            {
              path: '/custom',
              name: 'customRules',
              access: 'Rules',
              component: require('components/Modules/NetEkstre/Definitions/RuleDefinitions/Rules/CustomRules/List').default,
            },
            {
              path: '/dynamic-fields',
              name: 'dynamicFields',
              access: 'DynamicProperties',
              component: require('components/Modules/NetEkstre/Definitions/RuleDefinitions/DynamicFields/List').default,
            },
            {
              path: '/field-definitions',
              name: 'fieldDefinitions',
              access: 'PropertyConfigs',
              component: require('components/Modules/NetEkstre/Definitions/RuleDefinitions/FieldDefinition/List').default,
            },
            {
              path: '/matching-conditions',
              name: 'matchingConditions',
              // access: 'CurrentAccountDynamicProperties',
              component: require('components/Modules/NetEkstre/Definitions/RuleDefinitions/MatchingConditions/List').default,
            },
          ]
        },
        {
          path: '/categories',
          name: 'categories',
          access: 'TenantCategories',
          component: require('components/Modules/NetEkstre/Definitions/Categories').default,
          routes:[
            {
              path: '/general',
              name: 'generalCategories',
              access: 'TenantCategories',
              component: require('components/Modules/NetEkstre/Definitions/Categories/GeneralCategories/List').default,
            },
            {
              path: '/custom',
              name: 'customCategories',
              access: 'TenantCategories',
              component: require('components/Modules/NetEkstre/Definitions/Categories/CustomCategories/List').default,
            },
          ]
        },
        {
          path: '/notifications',
          name: 'notifications',
          access: 'Notifications',
          component: require('components/Modules/NetEkstre/Definitions/Notifications/List').default,
        },
        {
          path: '/create-data',
          name: 'createTestData',
          visible: isTest(),
          component: require('components/Modules/NetEkstre/Definitions/CreateTestData').default,
        },
      ]
    },
    {
      path: '/reports',
      name: 'reports',
      access: ['BalanceReports'],
      component: require('components/Modules/NetEkstre/Reports').default,
      routes: [
        {
          path: '/balances',
          name: 'reportBalances',
          access: 'BalanceReports',
          component: require('components/Modules/NetEkstre/Reports/BalanceReports').default,
        },
        {
          path: '/average',
          name: 'reportAverage',
          access: 'BalanceReports',
          component: require('components/Modules/NetEkstre/Reports/AvgReports').default,
        },
      ]
    },
  ]
};