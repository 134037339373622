import React from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/common';
import i18n from 'plugins/i18n';


const blurTime = 300000;
class Blur extends React.Component {
  location = window.location.pathname.substring(1).split('/')[0];
  constructor(props) {
    super(props);
    this.state = {
      blur: false,
    }
    this.blurTimeOut = null;
  }
  componentDidMount() {
    this.blur();
    document.addEventListener("keydown", this.blur);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.blur);
  }

  blur = () => {
    const _this = this;
    if (!this.props.blur && this.location !== "auth" && this.location !== "register") {
      if (_this.state.blur) _this.setState({ blur: false })
      document.onmousemove = () => {
        let blurOverlayElm = document.getElementById('blur-overlay');
        let blurElm = document.getElementById('blur');
        clearTimeout(this.blurTimeOut);
        this.blurTimeOut = setTimeout(() => {
          _this.setState({ blur: true });
        }, blurTime);
        blurOverlayElm.classList.remove('active');
        blurElm.classList.remove('blur');
      }
    }
  }

  render() {
    const { blur } = this.state;
    const { children, toggleBlur } = this.props;
    return (
      <>
        <>
          <div id="blur-overlay" className={`blur-overlay ${blur ? 'active' : ''} `}
            onMouseMove={() => { this.blur(); toggleBlur(blur) }}
          >
            <h2>{i18n.t('msg.screenSaverMode')}</h2>
          </div>
          <div id="blur" className={`app ${blur ? 'blur' : ''}`} >
            {children}
          </div>
        </>
      </>
    );
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleBlur: (data) => dispatch(actions.toggleData({ key: "blur", data }))
});
export default connect(null, mapDispatchToProps)(Blur);