import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, notification, Modal, Icon } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CreateTestDataModel } from 'models';
import actions from 'store/actions/netekstre';
import { Input, Select } from 'components/UIComponents/NetbankaComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import utils from 'lib';

class CreateTestData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new CreateTestDataModel({ description1: i18n.t('lbl.thisIsATestData') }),
      index: null,
      visible: false,
      responseMessages: []
    };
  };

  componentDidMount() {
    this.props.getCategoryTypes({ filter: { or: [{ parentId: { ne: null } }, { CategoryType: 0 }] } });
    utils.common.getTenantBanks()
    utils.common.getBankAccounts();
  };

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let _data = new CreateTestDataModel(_.cloneDeep(data));
    _data.amount = parseFloat(_data.amount.replace(',', '.'))
    this.props.form.validateFields(error => {
      if (!error) {
        this.props.saveData(_data, (response) => {
          if (response) this.collectResponse(response)
          else notification.success({ message: i18n.t('lbl.unknownError') });
        });
      }
    });
  };

  collectResponse = (response) => {
    let { responseMessages } = this.state;
    if (response && response.length > 0) {
      let successCount = 0;
      // eslint-disable-next-line
      response.map(item => {
        if (item.statusCode === 200) {
          successCount = successCount + 1;
        }
        else if (item.statusCode === 302 || item.statusCode === 404 || item.statusCode === 500) {
          return responseMessages.push({
            statusCode: item.statusCode,
            message: item.message
          })
        }
      })

      if (successCount !== 0) {
        responseMessages.unshift({
          statusCode: 200,
          message: `${successCount} ${i18n.t('msg.dataCreatedSuccessful')}`
        })
      }
      this.setState({ responseMessages, visible: true })
    }
  }

  render() {
    let { data, visible, responseMessages } = this.state;
    let { movementCategories, tenantBanks, bankAccounts, testData } = this.props;
    let { getFieldDecorator } = this.props.form;
    let _bankAccounts = data.tenantBanks && data.tenantBanks.length > 0 ? bankAccounts.data.filter(x => data.tenantBanks.find(y => y === x.bankEftCode)) : [];
    return (
      <div className="page-content">
        <Card title={
          <React.Fragment>
            {i18n.t(`routes.createData`)}
          </React.Fragment>
        }>
          <Form onSubmit={this.save}>
            <Row gutter={20}>
              <Col xs={24} sm={8}>
                <Form.Item>
                  {getFieldDecorator('tenantBanks', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.tenantBanks
                  })(
                    <Select
                      mode="multiple"
                      label={i18n.t('lbl.bank')}
                      options={tenantBanks.data}
                      optVal="bankEftCode"
                      optName={(bankData) => utils.netekstre.getBankName({ bankData })}
                      onChange={(e) => this.update('tenantBanks', e)}
                      className="#tenantBanks"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item>
                  {getFieldDecorator('accounts', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.accounts
                  })(
                    <Select
                      mode="multiple"
                      label={i18n.t('lbl.bankAccounts')}
                      options={_bankAccounts}
                      optVal="id"
                      optName="name"
                      onChange={(e) => this.update('accounts', e)}
                      className="#accounts"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item>
                  {getFieldDecorator('categoryId', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.categoryId
                  })(
                    <Select
                      label={i18n.t('lbl.movementCategories')}
                      options={movementCategories.data}
                      optVal="Id"
                      optName="Name"
                      onChange={(e) => this.update('categoryId', e)}
                      className="#categoryId"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item>
                  {getFieldDecorator('amount', {
                    rules: [
                      { required: true, message: i18n.t('msg.required') },
                      { validator: (rule, value, callback) => value === "0" ? callback(true) : callback(), message: i18n.t('msg.noAcceptZero') },
                      { validator: (rule, value, callback) => value[value.length - 1] === (".") || value[value.length - 1] === (",") ? callback(true) : callback(), message: i18n.t('msg.notEndDotOrComma') },
                      { validator: (rule, value, callback) => value.includes("-") || value.includes("+") ? callback(true) : callback(), message: i18n.t('msg.signError') }
                    ],
                    initialValue: data.amount.toString()
                  })(
                    <Input type="amount" label={i18n.t('lbl.transactionAmount')} onChange={(e) => this.update('amount', e)} className="#amount" />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={16}>
                <Form.Item>
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: i18n.t('msg.required') }],
                    initialValue: data.description1
                  })(
                    <Input label={i18n.t('lbl.description')} onChange={(e) => this.update('description1', e)} className="#description1" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24} className="text-right">
                <Button className="error mr-10 #cancel btn-collapse" icon="close" onClick={() => this.props.history.push(`/dashboard`)}>{i18n.t('btn.cancel')}</Button>
                <Button htmlType="submit" className="success #save btn-collapse" icon="save" loading={testData.single.saving}>{i18n.t('btn.save')}</Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Modal
          title={i18n.t('lbl.testDataStatus')}
          visible={visible}
          footer={null}
          onCancel={() => this.setState({ visible: false, responseMessages: [] })}
        >
          {
            responseMessages.map((item, index) =>
              <p key={index}>
                <Icon
                  style={{ color: item.statusCode === 200 ? '#52c41a' : item.statusCode === 302 ? '#faad14' : '#f5222d' }}
                  type={item.statusCode === 200 ? "check-circle" : item.statusCode === 302 ? "exclamation-circle" : "close-circle"}
                  theme="filled"
                  className="mr-5" />
                {item.message}
              </p>
            )
          }
        </Modal>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, common }) => ({
  tenantBanks: common.filter.tenantBanks,
  bankAccounts: common.filter.bankAccounts,

  testData: netekstre.testData,
  movementCategories: netekstre.filter.movementCategories,
});
const mapDispatchToProps = (dispatch) => ({
  saveData: (data, callback) => dispatch(actions.post({ url: endpoints.nte.bankAccountTransactionCreators, key: 'testData', data }, callback)),
  getCategoryTypes: (filter) => dispatch(actions.getFilter({ url: endpoints.nte.tenantCategories, key: 'movementCategories', filter, isNewODataStructure: true })),
});
const CreateTestDataForm = Form.create()(CreateTestData);
export default connect(mapStateToProps, mapDispatchToProps)(CreateTestDataForm);