import React, { useState } from 'react';
import { Typography, FormElements } from 'components/UIComponents';
import { Form } from 'antd';
import i18n from 'plugins/i18n';


const Full = ({ text, title, acceptText, onSubmit, form, id }) => {
  const [checked, setChecked] = useState(false);
  const [checkValid, setCheckValid] = useState(false)

  const onCheck = (e) => {
    if (!checkValid) setCheckValid(true);
    e.preventDefault();
    form.validateFields(error => {
      if (!error && checked) {
        onSubmit({ acceptance: checked, id });
      }
    })
  }



  return (
    <Form onSubmit={onCheck} id="contract">
      <div className="agreement-viewer full">
        <div className="content">
          <div className="viewer-container">
            <Typography.H size={3} className="mt-15">
              {title}
            </Typography.H>
            <Typography.P className="mt-30" size="normal" __html={text} />
          </div>
        </div>
        <div className="acceptance">
          <Form.Item help={(checkValid ? checked : true) ? "" : i18n.t('msg.required')} validateStatus={(checkValid ? checked : true) ? "success" : "error"}>
            <FormElements.CheckBox
              checked={checked}
              label={acceptText}
              onChange={({ target }) => setChecked(target.checked)}
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default Form.create()(Full);