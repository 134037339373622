import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import state from 'store/states/netekstre';
import krediState from './krediState'
import reducers from 'store/reducers/netekstre';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getAllNetekstre: ['payload', 'callback', 'isNewODataStructure'],
  getAllNetekstreSuccess: ['payload', 'isNewODataStructure'],
  getAllNetekstreFailure: ['payload'],
  getNetekstre: ['payload', 'callback'],
  getNetekstreSuccess: ['payload', 'isNewODataStructure'],
  getNetekstreFailure: ['payload'],
  postNetekstre: ['payload', 'callback'],
  postNetekstreSuccess: ['payload'],
  postNetekstreFailure: ['payload'],
  putNetekstre: ['payload', 'callback'],
  putNetekstreSuccess: ['payload'],
  putNetekstreFailure: ['payload'],
  deleteNetekstre: ['payload', 'callback'],
  deleteNetekstreSuccess: ['payload'],
  deleteNetekstreFailure: ['payload'],
  patchNetekstre: ['payload', 'callback'],
  patchNetekstreSuccess: ['payload'],
  patchNetekstreFailure: ['payload'],
  clearStateNetekstre: ['key', 'clearList'],
  getWithPostNetekstre: ['payload'],
  getWithPostNetekstreSuccess: ['payload'],
  getWithPostNetekstreFailure: ['payload'],
  getFilterNetekstre: ['payload', 'isNewODataStructure'],
  getFilterNetekstreSuccess: ['payload', 'isNewODataStructure'],
  getFilterNetekstreFailure: ['payload'],
});
export const NetekstreTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({ ...state, kredi: krediState });


export const getAll = (state, action) => {
  const data = reducers.getAll(state, action)
  return state.merge(data);
};

export const getAllSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getAllFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const get = (state, action) => {
  const data = reducers.get(state, action)
  return state.merge(data);
};

export const getSuccess = (state, action) => {
  const data = reducers.getSuccess(state, action)
  return state.merge(data);
};

export const getFailure = (state, action) => {
  const data = reducers.getFailure(state, action)
  return state.merge(data);
};

export const clearState = (state, action) => {
  const data = reducers.clearState(state, action)
  return state.merge(data)
}


export const post = (state, action) => {
  const data = reducers.post(state, action)
  return state.merge(data);
};

export const postSuccess = (state, action) => {
  const data = reducers.postSuccess(state, action)
  return state.merge(data);
};

export const postFailure = (state, action) => {
  const data = reducers.postFailure(state, action)
  return state.merge(data);
};



export const put = (state, action) => {
  const data = reducers.put(state, action)
  return state.merge(data);
};

export const putSuccess = (state, action) => {
  const data = reducers.putSuccess(state, action)
  return state.merge(data);
};

export const putFailure = (state, action) => {
  const data = reducers.putFailure(state, action)
  return state.merge(data);
};



export const _delete = (state, action) => {
  const data = reducers.delete(state, action)
  return state.merge(data);
};

export const deleteSuccess = (state, action) => {
  const data = reducers.deleteSuccess(state, action)
  return state.merge(data);
};

export const deleteFailure = (state, action) => {
  const data = reducers.deleteFailure(state, action)
  return state.merge(data);
};



export const patch = (state, action) => {
  const data = reducers.patch(state, action)
  return state.merge(data);
};

export const patchSuccess = (state, action) => {
  const data = reducers.patchSuccess(state, action)
  return state.merge(data);
};

export const patchFailure = (state, action) => {
  const data = reducers.patchFailure(state, action)
  return state.merge(data);
};



export const getWithPost = (state, action) => {
  const data = reducers.getWithPost(state, action)
  return state.merge(data);
};

export const getWithPostSuccess = (state, action) => {
  const data = reducers.getAllSuccess(state, action)
  return state.merge(data);
};

export const getWithPostFailure = (state, action) => {
  const data = reducers.getAllFailure(state, action)
  return state.merge(data);
};

export const getFilter = (state, action) => {
  const data = reducers.getFilter(state,action)
  return state.merge(data);
};

export const getFilterSuccess = (state, action) => {
  const data = reducers.getFilterSuccess(state,action)
  return state.merge(data);
};

export const getFilterFailure = (state, action) => {
  const data = reducers.getFilterFailure(state,action)
  return state.merge(data);
};



/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_NETEKSTRE]: getAll,
  [Types.GET_ALL_NETEKSTRE_SUCCESS]: getAllSuccess,
  [Types.GET_ALL_NETEKSTRE_FAILURE]: getAllFailure,
  [Types.GET_NETEKSTRE]: get,
  [Types.GET_NETEKSTRE_SUCCESS]: getSuccess,
  [Types.GET_NETEKSTRE_FAILURE]: getFailure,
  [Types.POST_NETEKSTRE]: post,
  [Types.POST_NETEKSTRE_SUCCESS]: postSuccess,
  [Types.POST_NETEKSTRE_FAILURE]: postFailure,
  [Types.PUT_NETEKSTRE]: put,
  [Types.PUT_NETEKSTRE_SUCCESS]: putSuccess,
  [Types.PUT_NETEKSTRE_FAILURE]: putFailure,
  [Types.DELETE_NETEKSTRE]: _delete,
  [Types.DELETE_NETEKSTRE_SUCCESS]: deleteSuccess,
  [Types.DELETE_NETEKSTRE_FAILURE]: deleteFailure,
  [Types.PATCH_NETEKSTRE]: patch,
  [Types.PATCH_NETEKSTRE_SUCCESS]: patchSuccess,
  [Types.PATCH_NETEKSTRE_FAILURE]: patchFailure,
  [Types.CLEAR_STATE_NETEKSTRE]: clearState,
  [Types.GET_WITH_POST_NETEKSTRE]: getWithPost,
  [Types.GET_WITH_POST_NETEKSTRE_SUCCESS]: getWithPostSuccess,
  [Types.GET_WITH_POST_NETEKSTRE_FAILURE]: getWithPostFailure,
  [Types.GET_FILTER_NETEKSTRE]: getFilter,
  [Types.GET_FILTER_NETEKSTRE_SUCCESS]: getFilterSuccess,
  [Types.GET_FILTER_NETEKSTRE_FAILURE]: getFilterFailure,
});