import React from 'react'
import ReactSVG from 'react-svg';
import { Typography } from '..';
import DeferralIcon from 'assets/img/icons/deferral2.svg'
import i18n from 'plugins/i18n';


const InstallmentInfoBanner = ({ className = '', showDeferral = false, showPlusInstallment = false }) => (
  <div className={`installment-info__banner ${className}`}>
    {
      showPlusInstallment &&
      <div><span>+2</span> <Typography.Label nextGen>{i18n.t('descriptions.installmentInfo.plusInstallment')}</Typography.Label> </div>
    }
    {
      showDeferral &&
      <div><span><ReactSVG src={DeferralIcon} /></span> <Typography.Label nextGen>{i18n.t('descriptions.installmentInfo.paymentDeferral')}</Typography.Label> </div>
    }
  </div>
)
export default InstallmentInfoBanner;
