import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

let store = undefined;

export default (rootReducer, rootSaga) => {
  const middleware = [];
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();
  const logger = createLogger({
    // predicate: (getState, action) => action.type.includes('DATATABLE'), //for datatable development 
    // collapsed: (getState, action) => !action.type.includes('DATATABLE'),

  });

  /* If you want to use Logger  */
  const useLogger = !true;

  middleware.push(sagaMiddleware);


  /* Add logger to local and development environments */
  if (window.location.hostname.split('.')[0].trim() !== "portal") {
    if (useLogger) {
      middleware.push(logger);
    }
  }

  enhancers.push(applyMiddleware(...middleware));
  if (window?.__REDUX_DEVTOOLS_EXTENSION__) enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  store = createStore(rootReducer, compose(...enhancers));



  sagaMiddleware.run(rootSaga);

  return store;
};

export { store };
