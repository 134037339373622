import React, { Component, Suspense, lazy } from 'react'
import { Tabs, Card } from 'antd';
import { Loading } from 'components/UIComponents';
import i18n from 'plugins/i18n';

const TenantInfo = lazy(() => import('./components/TenantInfo'));
const Documents = lazy(() => import('./components/Documents'));

class Tenant extends Component {
  render() {
    return (
      <Tabs defaultActiveKey="main">
        <Tabs.TabPane tab={i18n.t('lbl.tenantInfo')} key="tenantInfo">
          <Suspense fallback={<Loading Wrapper={Card} />}>
            <TenantInfo />
          </Suspense>
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n.t('lbl.documents')} key="documents">
          <Suspense fallback={<Loading Wrapper={Card} />}>
            <Documents />
          </Suspense>
        </Tabs.TabPane>
      </Tabs>
    )
  }
}

export default Tenant;
