import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/posrapor';
import { ActionButtons, DataTable, Filter, FadeIn, CheckAccessRight } from 'components/UIComponents';
import endpoints from 'config/endpoints';
import utils from "lib";
import i18n from 'plugins/i18n';
import { defaultFilters } from 'lib/constants';
import PosList from "./Detail";
import { Button } from 'antd';

class PosLists extends Component {
  state = {
    filter: defaultFilters.posrapor.PosLists(),
    bulkEdit: false
  };

  componentDidMount() {
    utils.posrapor.getBanks();
    utils.posrapor.getCompanyNames();
    this.getPosLists();
    utils.common.checkAccessToUpdateOrDelete('BankDailyReportPos')
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getPosLists);
  };

  getPosLists = () => {
    const { filter } = this.state;
    this.props.getPosLists(filter);
  };

  onSort = (key, type) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getPosLists);
  };

  onPageChange = (skip, top) => {
    const { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getPosLists);
  }

  customButtons = () => (
    this.props.hasAccessRightToUpdateOrDeleteCurrentPage &&
    <Button className="pull-right mr-10" size="small" icon="edit" onClick={() => this.setState({ bulkEdit: !this.state.bulkEdit })}> {i18n.t('btn.bulkEdit')} </Button>
  );

  renderActionButton = row => (
    <ActionButtons
      url="BankDailyReportPos"
      editUrl
      item={row}
      getDatas={this.getPosLists}
      openDialog={this.datatable && this.datatable.openDialog}
    />
  )

  render() {
    const { filter } = this.state;
    const { posLists, banks, companyNames } = this.props;
    const { bulkEdit } = this.state
    let filters = [
      { label: i18n.t('lbl.bank'), type: 'select', options: banks.data, value: 'eftCode', name: (bankData) => utils.common.getBankName({ bankData }), keys: ['PosRaporAccount/Bank/EftCode'], showAll: true, uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.search'), type: 'input', keys: ['IBAN', 'MerchantId', 'TerminalId', 'VirtualPosId', 'ErpPaymentSetCode', 'ErpPosCode', 'ErpTenantCode'], contains: true, uniqueKey: '#search' },
      { label: i18n.t('posrapor.merchantName'), keys: ['CompanyName'], type: 'select', options: companyNames.data, name: "companyName", value: "companyName", search: true },
    ];
    const columns = [
      { render: this.renderActionButton, toggle: false, sort: false, notIncluded: true, key: 'Id' },
      { label: i18n.t('posrapor.bankName'), key: 'PosRaporAccount/Bank/Name', render: (data) => utils.posrapor.getBankName({ data, dataField: 'PosRaporAccount.Bank.EftCode' }), checkField: false },
      { label: i18n.t('posrapor.merchant'), key: 'MerchantId' },
      { label: i18n.t('posrapor.merchantName'), key: 'CompanyName', },
      { label: i18n.t('posrapor.terminalNo'), key: 'TerminalId' },
      { label: i18n.t('posrapor.posNo'), key: 'VirtualPosId' },
      { label: i18n.t('posrapor.branch'), key: 'Branch' },
      { label: i18n.t('posrapor.accountNumber'), key: 'AccountNumber' },
      { label: i18n.t('posrapor.currencyType'), key: 'Currencies/Code', checkField: false },
      { label: i18n.t('posrapor.iban'), key: 'IBAN' },
      { label: i18n.t('posrapor.erpPaymentSetCode'), key: 'ErpPaymentSetCode' },
      { label: i18n.t('posrapor.erpPosCode'), key: 'ErpPosCode' },
      { label: i18n.t('posrapor.erpTenantCode'), key: 'ErpTenantCode' },
      { label: i18n.t('posrapor.posType'), key: 'posType' },
      { label: i18n.t('posrapor.vendorType'), key: 'vendorType' },
      { label: i18n.t('posrapor.currentAccountCode'), key: 'currentAccountCode' },
    ];

    return (
      <div className="page-content">
        <FadeIn>
          <CheckAccessRight {...{ ...{ ...posLists, single: posLists.list } }}>
            <Filter filters={filters} onFilter={this.onFilter} />
            <DataTable
              count={posLists.list.count}
              wrappedComponentRef={el => this.datatable = el}
              access="BankDailyReportPos"
              history={this.props.history}
              onPageChange={this.onPageChange}
              onSort={this.onSort}
              columns={columns}
              data={posLists.list.data}
              loading={posLists.list.loading}
              title={i18n.t('route.posrapor.posLists')}
              getData={this.getPosLists}
              Component={PosList}
              dialogTitle={i18n.t('posrapor.pos')}
              deleteOptions={{ stateKey: "posLists", url: "BankDailyReportPos" }}
              excel={{ url: "ExportBankDailyReportPos", module: "posrapor", filter: { filter: filter.filter, expand: filter.expand, orderBy: filter.orderBy } } }
              CustomButton={this.customButtons}
              checkbox={bulkEdit}
              checkAll={bulkEdit}
              dialogOptions={{ bulkEdit }}
            />
          </CheckAccessRight>
        </FadeIn>
      </div>
    );
  };
};

const mapStateToProps = ({ posrapor, common }) => ({
  posLists: posrapor.posLists,
  banks: posrapor.filter.banks,
  companyNames: posrapor.filter.companyNames,
  hasAccessRightToUpdateOrDeleteCurrentPage: common.hasAccessRightToUpdateOrDeleteCurrentPage
});
const mapDispatchToProps = (dispatch) => ({
  getPosLists: (filter) => dispatch(actions.getAll({ filter, url: endpoints.posrapor.posList, key: 'posLists' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PosLists);