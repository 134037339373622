import React, { Component } from 'react';
import { Button, Modal, Icon, DatePicker, Row, Col, Checkbox, notification, Form, Tooltip } from 'antd';
import actions from 'store/actions/netekstre';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash'
import { formatDate } from 'helpers';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import GlobalLoading from './GlobalLoading'
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { SyncRuleJobsModel } from 'models';
import utils from 'lib';

class SynchronizeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      data: new SyncRuleJobsModel(),
      date: null,
      loading: false,
    }
  };

  onChangeRange = (date) => {
    let { data } = this.state;
    data.firstDate = date[0];
    data.lastDate = date[1];
    this.setState({ data })
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let _data = _.cloneDeep(data)
    let { selectedItems, auth } = this.props;
    _data.tenantId = auth.data.tenantId;
    selectedItems.map(item =>
      _data.jobRulesModel.push({ ruleId: item })
    )
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({ loading: true });
        _data.firstDate = formatDate(data.firstDate, true, false, false);
        _data.lastDate = formatDate(data.lastDate, true, false, true);
        this.props.postModal(_data, (response) => {
          if (response) {
            this.setState({ loading: false, modalVisible: false });
            this.props.onStartSync(false);
          }
          else {
            this.setState({ loading: false, modalVisible: false });
            notification.error({ message: i18n.t('msg.unknownError') });
          }
          utils.common.clearSelectedItems();
        });
      }
    })
  }

  render() {
    let { modalVisible, date, loading } = this.state;
    let { selectedItems, selectAll, disabled, currentModule } = this.props;
    let { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Tooltip placement="top" title={i18n.t('msg.maxRule')} visible={selectedItems.length > 5}>
          <Button
            loading={loading}
            onClick={() => this.setState({ modalVisible: true })}
            disabled={(selectedItems.length === 0 && !selectAll) || loading || disabled || selectedItems.length > 5}
            className="pull-right mr-10 #synchronize btn-collapse"
            type={!((selectedItems.length === 0 && !selectAll) || loading || disabled || selectedItems.length > 5) ? 'default' : undefined}
            size="small"
          >
            {!loading && <Icon type="swap" />}
            {i18n.t('btn.syncRule')}
          </Button>
        </Tooltip>
        <Modal
          title={i18n.t('lbl.syncRule')}
          visible={modalVisible}
          onOk={this.save}
          okButtonProps={{ className: '#okModal', icon: 'save' }}
          cancelButtonProps={{ className: '#cancelModal', icon: 'close' }}
          okText={i18n.t('btn.synchronize')}
          cancelText={i18n.t('btn.cancel')}
          onCancel={() => { this.setState({ modalVisible: false }) }}
          className={currentModule}
        >
          {
            loading ?
              <GlobalLoading />
              :
              <Form>
                <Row gutter={20}>
                  <Col xs={24} md={13}>
                    <Form.Item>
                      {getFieldDecorator('date', {
                        rules: [{ type: 'array', required: true, message: i18n.t('msg.required') }],
                        initialValue: date
                      })(
                        <DatePicker.RangePicker
                          disabledDate={(e) => moment().subtract(1, 'months') > e || e > moment().endOf('day')}
                          className="#datePicker"
                          locale={locale}
                          dropdownClassName="modal-calendar"
                          onChange={(date) => this.onChangeRange(date.map(x => moment.utc(x).format()))}
                          format={'DD.MM.YYYY'}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11}>
                    <Form.Item>
                      <Checkbox className="#includeCompleted" onChange={(e) => this.update('getCompletedTransaction', e.target.checked)}>
                        {i18n.t('lbl.includeCompleted')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
          }
        </Modal>
      </React.Fragment>
    );
  };
};

const mapStateToProps = ({ auth, common }) => ({
  auth,
  currentModule: common.currentModule
});
const mapDispatchToProps = (dispatch) => ({
  postModal: (data, callback) => dispatch(actions.post({ url: endpoints.nte.syncRuleJobs, data }, callback))
})
const SynchronizeModalForm = Form.create()(SynchronizeModal);
export default connect(mapStateToProps, mapDispatchToProps)(SynchronizeModalForm);
