import React, { Component } from 'react';
import _ from 'lodash';
import { Select as AntSelect, Icon, Spin } from 'antd';
import i18n from 'plugins/i18n';
import icons from 'assets/img/icons';
import ReactSVG from 'react-svg';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? (props.mode === ('tags' || 'multiple')) ? props.value.map(x => x.toString()) : props.value.toString() : undefined,
      isFocused: false,
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      if (props.mode === 'tags' || props.mode === 'multiple') {
        return {
          value: (props.options && props.value && props.options.length > 0) ? props.value.map(x => x.toString()) : undefined,
        }
      }
      else {
        return {
          value: (props.value && props.options && props.options.length > 0) ? props.value.toString() : undefined,
        }
      }
    }
    else {
      return null;
    }
  };

  handleChange = (e) => {
    let difference = _.concat(_.difference(e, this.state.value), _.difference(this.state.value, e));
    this.setState({ value: !e ? e : typeof e !== 'object' ? e.toString() : e }, () => {
      if ((this.props.mode === 'tags' || this.props.mode === 'multiple')) {
        if (typeof this.props.options[0][this.props.optVal] === 'number') {
          this.props.onChange && this.props.onChange(e.map(x => parseInt(x)), difference);
        }
        else {
          this.props.onChange && this.props.onChange(e, difference);
        }
      }
      else {
        if (this.props.options[0] && typeof this.props.options[0][this.props.optVal] === 'number') {
          this.props.onChange && this.props.onChange(parseInt(e), difference);
        }
        else {
          this.props.onChange && this.props.onChange(e, difference);
        }
      }
    });
  };

  render() {
    let { value } = this.state;
    let { label, className, id, disabled, required, optName, allowClear, optVal, options, showSearch, onSearch, onFocus, mode, lang, loading, maxTagCount, prefix } = this.props;
    let _options = options ? typeof optName === 'string' && optName !== '' ? _.cloneDeep(options).sort((a, b) => a[optName].localeCompare(b[optName])) : options : [];
    const _prefix = typeof prefix === "string" ? <ReactSVG src={icons[prefix]} /> : prefix;
    return (
      <div className={`${mode === 'tags' || mode === 'multiple' ? 'nb-tag' : ''} ${_prefix ? 'with-prefix' : ''} custom-select__wrapper`}>
        <AntSelect
          suffixIcon={loading ? <Spin indicator={<Icon type="loading" />} /> : <Icon type="caret-down" />}
          loading={loading}
          id={id}
          maxTagCount={maxTagCount || 1}
          value={value}
          showSearch={showSearch}
          onSearch={onSearch}
          onChange={this.handleChange}
          onFocus={() => { this.setState({ isFocused: true }); onFocus && onFocus() }}
          onBlur={() => this.setState({ isFocused: false })}
          mode={mode ? mode : 'default'}
          className={`${(value !== null && value !== undefined && value !== '' && value.length) && 'is-filled'} ${className || ''}`}
          optionFilterProp="children"
          disabled={!!disabled}
          allowClear={allowClear === false ? false : true}
          required={!!required}
          dropdownClassName="custom-select__dropdown"
        >
          {
            !loading &&
            _options.map((item, i) => (
              <AntSelect.Option disabled={item.disabled} className={`#select${i + 1}`} key={Math.random()} value={optVal ? typeof optVal === 'string' ? (mode === 'tags' || mode === 'multiple') ? (item[optVal] !== null && item[optVal] !== undefined) ? item[optVal].toString() : '' : item[optVal].toString() : optVal(item) : item}>
                {typeof optName === 'string' ? lang ? i18n.t(`${lang}.${item[optName]}`) : item[optName] : optName === null ? item : optName(item)}
              </AntSelect.Option>
            ))}
        </AntSelect>
        <label className={(this.state.isFocused || (value && value.toString() !== '')) ? 'focused' : ''}>{label || ''}</label>
        {_prefix}
      </div>
    );
  };
};

export default Select;