import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import actions from 'store/actions/netekstre';
import { Input } from 'components/UIComponents/NetbankaComponents';
import endpoints from 'config/endpoints';
import i18n from 'plugins/i18n';
import { DynamicFieldModel } from 'models';
import _ from 'lodash';
import { httpMethods } from 'lib/constants';
import { CheckAccessRight } from 'components/UIComponents';

class DynamicField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: new DynamicFieldModel(),
      InitialState: new DynamicFieldModel()
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.dynamicFields.single.data && !state.data.id) {
      const data = new DynamicFieldModel({ ...props.dynamicFields.single.data })
      return { data: _.cloneDeep(data), InitialState: _.cloneDeep(data), loading: false }
    }
    if (props.dialogClosing && !props.dynamicFields.single.saving) props.onDialogClose({ ...state.InitialState }, { ...state.data })
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getDynamicField(this.id);
    else this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  update = (key, value) => {
    let { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  save = (e) => {
    e.preventDefault();
    const { form, saveData, dialogCloseRequest } = this.props;
    form.validateFields(error => {
      if (!error) {
        let method = this.id ? httpMethods.put : httpMethods.post;
        saveData(this.state.data, method, (response) => {
          this.setState({ InitialState: this.state.data }, () => { dialogCloseRequest({ runGetData: true }); })
        });
      }
    });
  };

  renderDialogButtons = checkAccessRights => {
    let { dynamicFields, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      checkAccessRights
      ||
      <div>
        <Button disabled={dynamicFields.single.loading} className="error mr-10 #cancel" icon="close" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.cancel')}</Button>
        <Button disabled={dynamicFields.single.loading} form="dynamicFields" htmlType="submit" className="success #save" icon="save" loading={dynamicFields.single.saving}>{i18n.t('btn.save')}</Button>
      </div>,
      dynamicFields.single.saving,
      dynamicFields.single.loading
    )
  }

  render() {
    const { data } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { dynamicFields, dialogCloseRequest } = this.props;
    const { renderDialogButtons } = this;
    renderDialogButtons();

    return (
      <CheckAccessRight {...{ ...dynamicFields, renderDialogButtons, dialogCloseRequest }}>
        <Form onSubmit={this.save} id="dynamicFields">
          <Form.Item>
            {getFieldDecorator('title',
              {
                rules: [{ required: true, message: i18n.t('msg.required') }],
                initialValue: data.title
              }
            )(
              <Input className="#title" label={i18n.t('lbl.title')} onChange={(e) => this.update('title', e)} />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('code',
              {
                rules: [{ required: true, message: i18n.t('msg.required') }],
                initialValue: data.code
              }
            )(
              <Input className="#code" label={i18n.t('lbl.code')} onChange={(e) => this.update('code', e)} />
            )}
          </Form.Item>
        </Form>
      </CheckAccessRight>
    );
  };
};

const mapStateToProps = ({ netekstre }) => ({
  dynamicFields: netekstre.dynamicFields,
});
const mapDispatchToProps = (dispatch) => ({
  getDynamicField: (id, callback) => dispatch(actions.get({ url: endpoints.nte.dynamicProperties, key: 'dynamicFields', id }, callback)),
  saveData: (data, method, callback) => dispatch(actions[method]({ url: endpoints.nte.dynamicProperties, key: 'dynamicFields', data }, callback)),
  clearState: () => dispatch(actions.clearState("dynamicFields")),
});
const DynamicFieldForm = Form.create()(DynamicField);
export default connect(mapStateToProps, mapDispatchToProps)(DynamicFieldForm);