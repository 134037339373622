import { projectTypes } from "lib/constants";
const { endpoints, projectType, parameters } = window.config;
const moduleNames = {
  nte: "nte",
  tenant: "tenant",
  dbs: "dbs",
  psr: "psr",
  rates: "rates",
  auth: "auth",
  info: "info",
  job: "job",
  kredi: "okr",
  tos: "tos",
  cek: "cek",
  nth: "nth"
}

const env = "prodtest";
const withKong = !true;
// const host = "https://api.ecozum.com"
const host = "http://192.168.54.5:8000"

const getDevelopmentUrl = moduleName => {
  return `${host}/${env}/${moduleName}/`
}

export default {
  ratesUrl: process.env.NODE_ENV === 'development' ? `${host}/rates/` : endpoints.rates.api,
  nteUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.nte): `https://${env}_nteapi.ecozum.com/api/` : endpoints.nte.api,
  tenantUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.tenant): `https://${env}_tntapi.ecozum.com/api/` : endpoints.tenant.api,
  dbsUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.dbs): `https://${env}_dbsapi.ecozum.com/` : endpoints.dbs.api,
  posraporUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.psr): `https://${env}_posraporapi.ecozum.com/api/` : endpoints.posrapor.api,
  jobUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.job): `https://${env}_jobapi.ecozum.com/api/`  : endpoints.job.api,
  authUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.auth): `https://${env}_authapi.ecozum.com/api/`  : endpoints.auth.api,
  infoUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.info): `https://${env}_infoapi.ecozum.com/api/` : endpoints.info.api,
  krediUrl: process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.kredi): `https://${env}_okrapi.ecozum.com/api/` : endpoints.kredi.api,
  tosUrl:  process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.tos): `https://${env}_tosapi.ecozum.com/` : endpoints.tos.api,
  cekUrl:  process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.cek): `https://${env}_cekapi.ecozum.com/` : endpoints.cek.api,
  nthUrl:  process.env.NODE_ENV === 'development' ? withKong ? getDevelopmentUrl(moduleNames.nth): `https://${env}_nthapi.ecozum.com/api/` : endpoints.nth.api,


  socketUrltenant: process.env.NODE_ENV === 'development' ? `wss://${env}_tenantapi.ecozum.com/ws` : endpoints.tenant.socket,
  // socketUrltenant: process.env.NODE_ENV === 'development' ? `wss://192.168.54.5:8000/test/tenant/ws` : endpoints.tenant.socket,
  socketUrlnte: process.env.NODE_ENV === 'development' ? `wss://${env}_nteapi.ecozum.com/ws` : endpoints.nte.socket,
  socketUrldbs: process.env.NODE_ENV === 'development' ? `wss://${env}dbsapi.ecozum.com/ws` : endpoints.dbs.socket,
  socketUrlposrapor: process.env.NODE_ENV === 'development' ? `wss://${env}_posraporapi.ecozum.com/ws` : endpoints.posrapor.socket,
  socketUrlnth: process.env.NODE_ENV === 'development' ? `wss://${env}_nthapi.ecozum.com/ws` : endpoints.nth.socket,



  // userUrl: process.env.NODE_ENV === 'development' ? `http://${env}_userapi.ecozum.com/api/` : endpoints.user.api,


  lookups: 'Lookups/',
  lookup: 'Lookup/',
  projectType: process.env.NODE_ENV === 'development' ? projectTypes.netfinans : projectType,
  notificationsUpdateInterval: process.env.NODE_ENV === 'development' ? 300000 : parameters.notificationsUpdateInterval,
  excelExportLimit: process.env.NODE_ENV === 'development' ? 7999 : parameters.excelExportLimit,
  multiPersonEmailLimit: process.env.NODE_ENV === 'development' ? 10 : parameters.multiPersonEmailLimit,
  compressCount: process.env.NODE_ENV === 'development' ? 5 : parameters.compressCount,
  environments: [
    'testportal.netfinans.com',
    'devportal.netfinans.com',
    'demoportal.netekstre.com',
    'uatportal.netfinans.com',
    'erpdemoportal.ecozum.com',
    'predevportal.netfinans.com',
    'localhost'
  ]
};