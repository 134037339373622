import React, { useEffect } from 'react'
import routes from 'plugins/routes'
import { withRouter, NavLink } from "react-router-dom";
import ReactSVG from "react-svg";
import Logo from "assets/img/logo_white.svg";
import LogoType from "assets/img/logo-type.svg";
import { modules } from "temp";
import { moduleColors, menuIndicatorTracker } from "helpers";
import { Flip } from 'react-reveal';
import { Menu } from "antd";
import icons from 'assets/img/icons'
import i18n from 'plugins/i18n';
import { useSelector } from 'react-redux';
import { Loading } from 'components/UIComponents';
import { getKey } from 'lib/constants/defaultFilters';

const ModuleMenu = ({ location, collapsed, onMobileClick, history }) => {


  const currentModule = location.pathname.substr(1).split('/')[0];
  const _routes = routes.find(x => x.name === currentModule);
  const subRoutes = _routes.routes ? _routes.routes.filter(({ visible }) => visible !== false) : [];
  const moduleName = modules.find(x => x.path === currentModule) ? modules.find(x => x.path === currentModule).name : '';
  const auth = useSelector(({ auth }) => ({ ...auth }));
  const accessRights = auth.data ? auth.data.accessRights : [];
  // const token = auth.data ? auth.data.token : null;
  // const tokenLocal = localStorage.getItem("token")
  // const defaultRoute = subRoutes.filter(x => (tokenLocal && x.tokenCheck === true) || (!tokenLocal && !x.tokenCheck))[0] && subRoutes.filter(x => (tokenLocal && x.tokenCheck === true) || (!tokenLocal && !x.tokenCheck))[0].path.substr(1);
  // const defaultRoute = "dashboard";

  const onMenuClick = (item) => {
    item && history.push(item.key);
    menuIndicatorTracker();
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--margin-top-indicator', '0px');
    document.documentElement.style.setProperty('--indicator-width', "calc(100% - 30px)");
    document.documentElement.style.setProperty('--indicator-after-before', "30px");
  }, [])

  const getSelectedKeys = () => {
    const routes = `/${getKey()}`;
    onMenuClick();
    return routes;
  };

  const getDefaultOpenKeys = () => {
    let routes = getKey(), keys = [];
    routes = routes.split('/').map(x => '/' + x)
    if (routes.length > 2) {
      routes.splice(-1, 1);
      let path = routes[0];
      routes.splice(1).forEach(x => {
        keys.push(path += x)
      })
    }
    else {
      keys = [routes.join('')]
    }
    return keys
  }

  const hasAccessRight = route =>
    !route.access || (
      Array.isArray(route.access) &&
      accessRights.some(z => route.access.includes(z.endPoint))
    ) || (
      typeof route.access === 'string' &&
      accessRights.find(z => z.endPoint === route.access && z.method === 'GET')
    )

  return (
    <div className="module-menu" onClick={onMobileClick}>
      <NavLink to="/home">
        <div className="module-menu__header">
          <ReactSVG className="fadeInComponent" src={collapsed ? LogoType : Logo} />
          {
            !collapsed && auth.data &&
            <h2 className="flip-2-hor-bottom-2" style={{ color: moduleColors[currentModule] }}>
              <Flip left cascade>
                {moduleName}
              </Flip>
            </h2>
          }
        </div>
      </NavLink>
      <div className="module-menu__menu fadeInComponent">
        <Loading loading={auth.fetching}>
          {
            auth.data &&
            <Menu
              defaultSelectedKeys={getSelectedKeys()}
              defaultOpenKeys={getDefaultOpenKeys()}
              mode="inline"
              theme="dark"
              inlineIndent={20}
              overflowedIndicator={<div>a</div>}
              onClick={(item) => onMenuClick(item)}
              onOpenChange={() => onMenuClick()}
            >
              {
                // subRoutes.filter(x => (token && x.tokenCheck === true) || (!token && !x.tokenCheck)).map((route, xIndex) =>
                subRoutes.filter(x => x.visible !== false).map((route, xIndex) =>
                  route.routes && route.routes.length > 0 ?
                    hasAccessRight(route) &&
                    <Menu.SubMenu
                      key={`${_routes.path}${route.path}`}
                      className={`tile x-${xIndex}`}
                      title={
                        <span>
                          <ReactSVG className={`module-menu__icon ${collapsed ? 'collapsed' : ''}`}
                            src={icons[route.name]} />
                          <span>{i18n.t(`routes.${route.name}`)}</span>
                        </span>
                      }
                    >
                      {
                        hasAccessRight(route) ?
                          route.routes.filter(x => x.visible !== false).map((subRoute, yIndex) =>
                            subRoute.routes && subRoute.routes.length > 0 ?
                              (
                                !subRoute.access ||
                                (
                                  Array.isArray(subRoute.access) &&
                                  accessRights.some(z => subRoute.access.includes(z.endPoint))
                                ) || (
                                  typeof subRoute.access === 'string' &&
                                  accessRights.find(z => z.endPoint === subRoute.access && z.method === 'GET'))
                              ) &&
                              <Menu.SubMenu
                                key={`${_routes.path}${route.path}${subRoute.path}`}
                                className={`tile x-${xIndex}-${yIndex}`}
                                title={
                                  <span>
                                    <ReactSVG style={collapsed ? { color: 'red' } : null} className="module-menu__icon"
                                      src={icons[subRoute.name]} />
                                    <span>{i18n.t(`routes.${subRoute.name}`)}</span>
                                  </span>
                                }
                              >
                                {
                                  subRoute.routes.filter(x => x.visible !== false).map((nestedRoute, zIndex) =>
                                    (!nestedRoute.access || accessRights.find(t => t.endPoint === nestedRoute.access && t.method === 'GET')) &&
                                    <Menu.Item
                                      key={`${_routes.path}${route.path}${subRoute.path}${nestedRoute.path}`}
                                      className={`tile x-${xIndex}-${yIndex}-${zIndex}`}
                                    >
                                      <ReactSVG className="module-menu__icon" src={icons[nestedRoute.name]} />
                                      <span>
                                        {i18n.t(`routes.${nestedRoute.name}`)}
                                      </span>
                                    </Menu.Item>
                                  )
                                }
                              </Menu.SubMenu>
                              :
                              (!subRoute.access || accessRights.find(z => z.endPoint === subRoute.access && z.method === 'GET')) &&
                              accessRights.length > 0 &&
                              <Menu.Item
                                key={`${_routes.path}${route.path}${subRoute.path}`}
                                className={`tile x-${xIndex}-${yIndex}`}
                              >
                                <ReactSVG className="module-menu__icon" src={icons[subRoute.name]} />
                                <span>
                                  {i18n.t(`routes.${subRoute.name}`)}
                                </span>
                              </Menu.Item>
                          )
                          :
                          null
                      }
                    </Menu.SubMenu>
                    :
                    (
                      !route.access ||
                      (Array.isArray(route.access) && accessRights.some(z => route.access.includes(z.endPoint))) ||
                      (typeof route.access === 'string' && accessRights.find(z => z.endPoint === route.access))) &&
                    <Menu.Item key={`${_routes.path}${route.path}`} className={`tile x-${xIndex}`}>
                      <ReactSVG className={`module-menu__icon ${collapsed ? 'collapsed' : ''}`} src={icons[route.name]} />
                      <span>
                        {i18n.t(`routes.${route.name}`)}
                      </span>
                    </Menu.Item>
                )
              }
              <div className="indicator"></div>
            </Menu>
          }
        </Loading>
      </div>
    </div>
  )
}

export default withRouter(ModuleMenu);
