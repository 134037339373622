import React, { Component } from 'react';
import { convertBoolean } from 'helpers';
import { Switch, Icon } from 'antd';

class FieldsTable extends Component {

  getSwitch = (x, type) => {
    if (x[`${type}Key`]) {
      const { data, onChange } = this.props;
      const options = { checkedChildrenIcon: type === 'display' ? 'eye' : 'check', unCheckedChildrenIcon: type === 'display' ? 'eye-invisible' : 'close' }
      return <Switch
        checkedChildren={<Icon type={options.checkedChildrenIcon} />}
        unCheckedChildren={<Icon type={options.unCheckedChildrenIcon} />}
        checked={convertBoolean(data[x[`${type}Key`]]?.value)}
        onChange={checked => onChange(x[`${type}Key`], checked)}
      />
    }
    return null;
  }

  render() {
    const { rows, columns, className = '' } = this.props;
    return (
      <table className={`fields-table ${className}`}>
        <thead>
          <tr>
            {columns?.map(x => <th key={x}>{x}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            rows?.map(x =>
              <tr key={x.label}>
                <td>{x.label}</td>
                <td className="text-center"> {this.getSwitch(x, 'display')} </td>
                <td className="text-center"> {this.getSwitch(x, 'required')} </td>
              </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}
export default FieldsTable;
