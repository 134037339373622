import React, { Component } from 'react';
import { connect } from 'react-redux';
import netekstreActions from 'store/actions/netekstre';
import commonActions from 'store/actions/common';
import { DataTable, Filter, Description, SynchronizeModal, ActionButtons, CheckAccessRight } from 'components/UIComponents';
import _ from 'lodash';
import endpoints from 'config/endpoints';
import { defaultFilters, regexTypes } from 'lib/constants';
import i18n from 'plugins/i18n';
import utils from 'lib';
import Rule from './Detail';

const PageAccesses = {
  Rules: "Rules",
  AdminRules: "AdminRules"
}

class CustomRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startSync: false,
      loading: false,
      filter: defaultFilters.netekstre.CustomRules(),
      planCodes: [],
      accountCodes: [],
      currentBank: null,
      getDataTrigger: undefined,
      access: PageAccesses.Rules
    };
    this.searchTimeout = null;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.getDataTrigger && props.getDataTrigger !== state.getDataTrigger) {
      const parameters = { TenantId: props.getDataTrigger.id };
      const filter = state.filter;
      props.getRules(filter, parameters, endpoints.nte.adminRules);
      return { getDataTrigger: props.getDataTrigger, filter };
    }
    return null
  }

  componentDidMount() {
    const { getDataTrigger } = this.props;
    let access;
    if ((getDataTrigger === undefined || (getDataTrigger !== null))) {
      this.getRules();
      access = PageAccesses.Rules;
    }
    else {
      access = PageAccesses.AdminRules;
    }

    utils.common.checkAccessToUpdateOrDelete(access);
    this.setState({ access })


    utils.common.getTenantBanks();
    utils.netekstre.getTransferStatus()
    utils.netekstre.getVoucherTypeCodes();
    utils.netekstre.getCategories('Incoming', 1)
    utils.netekstre.getCategories('Outgoing', 2)
    utils.netekstre.getTypeCodes(1);
    utils.netekstre.getTypeCodes(2);

    let key = 'netekstre/definitions/rules/custom';

    let accountCodeFilter = this.props.currentFilter[key] && this.props.currentFilter[key].filter && this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.currentAccountCode)) ? this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.currentAccountCode)).or.find(x => x.currentAccountCode).currentAccountCode.contains : undefined;
    accountCodeFilter && this.accountCodeSearch(accountCodeFilter);

    let planCodeFilter = this.props.currentFilter[key] && this.props.currentFilter[key].filter && this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.accountPlanCode)) ? this.props.currentFilter[key].filter.and.find(x => x.or.find(y => y.accountPlanCode)).or.find(x => x.accountPlanCode).accountPlanCode.contains : undefined;
    planCodeFilter && this.planCodeSearch(planCodeFilter);
  };

  componentWillUnmount() {
    utils.common.clearSelectedItems();
  }

  getRules = () => {
    const { getDataTrigger } = this.props;
    let { filter } = this.state;
    if (getDataTrigger)
      this.props.getRules(filter, { TenantId: getDataTrigger.id }, endpoints.nte.adminRules);
    else
      this.props.getRules(filter);
  };

  onFilter = (newFilter) => {
    let { filter } = this.state;
    filter.filter = { ...filter.filter, ...newFilter };
    filter.skip = 0;
    this.datatable.pageUpdate(1);
    this.setState({ filter }, this.getRules);
  };

  onSort = (key, type) => {
    let { filter } = this.state;
    this.setState({
      filter: { ...filter, orderBy: [`${key} ${type}`] }
    }, this.getRules);
  };

  onPageChange = (skip, top) => {
    let { filter } = this.state;
    filter.skip = skip;
    filter.top = top;
    this.setState({ filter }, this.getRules);
  }

  onSelect = (value) => {
    this.setState({ currentBank: value });
  };

  onStartSync = (val) => {
    this.setState({ startSync: val }, () => {
      // this.getTransactions();
    });
  };


  getBankName = ({ RuleItems }) => {
    const ruleItem = RuleItems.find(({ RegexType }) => RegexType === regexTypes.Bank);
    return utils.common.getBankName({ data: ruleItem, listKeys: 'tenantBanks', dataField: 'RegexPattern', listField: 'bankEftCode' })
  };


  getTypeCodeName = (RuleItems, i) => {
    const ruleItem = RuleItems.find(({ RegexType }) => RegexType === regexTypes[`TypeCode${i}`]);
    if (ruleItem && ruleItem.RegexPattern) return ruleItem.RegexPattern;
    return '';
  }

  accountCodeSearch = (e) => {
    this.props.getAccountCodes(e, (response) => {
      this.setState({ accountCodes: response })
    });
  };

  planCodeSearch = (e) => {
    this.props.getPlanCodes(e, (response) => {
      this.setState({ planCodes: response })
    });
  };

  accountCodeClean = () => {
    this.props.getAccountCodes('');
  };

  planCodeClean = () => {
    this.props.getPlanCodes('');
  };

  onCallback = (e, type) => {
    if (!e) this.setState({ [type]: [] });
  };

  customButtons = ({ selectedItems, selectAll }) => (
    <React.Fragment>
      {(this.state.startSync && this.props.accessRights && this.props.accessRights.find(x => x.endPoint === 'SyncRuleJobs' && x.method === 'POST')) &&
        <SynchronizeModal selectedItems={[...selectedItems]} selectAll={selectAll} filter={this.state.filter.filter} onStartSync={this.onStartSync} />
      }
    </React.Fragment>
  );


  renderActionButtons = row => {
    const { access } = this.state;
    return <ActionButtons url={access} editUrl item={row} hasDelete getDatas={this.getRules} openDialog={this.datatable && this.datatable.openDialog} />
  }

  render() {
    let { startSync, accountCodes, planCodes, currentBank, filter, access } = this.state;
    let { customRules, voucherTypes, tenantBanks, incomingCategories, outgoingCategories, transferStatus, accessRights, type1, type2, showFilter, className, datatableTitle, datatableCollapsed, getDataTrigger, hasParent } = this.props;
    let _type1 = type1 && type1.data ? (currentBank && currentBank.length) ? type1.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type1.data : [];
    let _type2 = type2 && type2.data ? (currentBank && currentBank.length) ? type2.data.filter(x => currentBank.find(y => y === x.bankEftCode)) : type2.data : [];
    _type1 = _.uniqBy(_type1, 'code');
    _type2 = _.uniqBy(_type2, 'code');

    let filters = [
      { label: i18n.t('lbl.bank'), options: tenantBanks.data, type: 'select', value: 'bankEftCode', name: (bankData) => utils.netekstre.getBankName({ bankData }), keys: ['RuleItems'], any: 'RegexPattern', showAll: true, callback: this.onSelect, clear: ['2', '3'], uniqueKey: '#bank', multiple: true },
      { label: i18n.t('lbl.exampleDescription'), type: 'input', keys: ['name'], contains: true, uniqueKey: '#exampleDescription' },
      { label: i18n.t('lbl.typeOne'), options: _type1, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['ruleItems'], any: 'regexPattern', any2: ['regexType', regexTypes.TypeCode1], uniqueKey: '#typeOne', multiple: true },
      { label: i18n.t('lbl.typeTwo'), options: _type2, type: 'select', showAll: true, value: 'code', name: 'code', keys: ['ruleItems'], any: 'regexPattern', any2: ['regexType', regexTypes.TypeCode2], uniqueKey: '#typeTwo', multiple: true },
      { label: i18n.t('lbl.accountPlanCode'), options: planCodes, type: 'select', contains: true, search: true, onSearch: this.planCodeSearch, onFocus: this.planCodeClean, value: 'Code', name: (e) => `${e ? e.Code + ' ' + e.Name || e.Title : ''}`, keys: ['muhPlanCode'], callback: (e) => this.onCallback(e, 'planCodes'), uniqueKey: '#accountPlanCode' },
      { label: i18n.t('lbl.voucherType'), type: 'select', options: voucherTypes.data, keys: ['VoucherType/Code'], value: 'code', name: 'name', uniqueKey: '#voucherType', multiple: true },
      { label: i18n.t('lbl.currentAccount'), options: accountCodes, type: 'select', contains: true, search: true, onSearch: this.accountCodeSearch, onFocus: this.accountCodeClean, value: 'Code', name: (e) => `${e ? e.Code + ' ' + e.Title || e.Name : ''}`, keys: ['currentAccountCode'], callback: (e) => this.onCallback(e, 'accountCodes'), uniqueKey: '#currentAccountCode' },
      { label: i18n.t('lbl.bankAccountCode'), type: 'input', keys: ['BankAccountCode'], contains: true, uniqueKey: '#bankAccountCode' },
      { label: i18n.t('lbl.incomingCategory'), type: 'select', options: incomingCategories.data, keys: ['IncomingCategoryId'], value: 'Id', name: 'Name', uniqueKey: '#incomingCategory', multiple: true, guid: true },
      { label: i18n.t('lbl.outgoingCategory'), type: 'select', options: outgoingCategories.data, keys: ['OutgoingCategoryId'], value: 'Id', name: 'Name', uniqueKey: '#outgoingCategory', multiple: true, guid: true },
      { label: i18n.t('lbl.transferStatus'), options: transferStatus.data, type: 'select', value: 'id', name: 'name', lang: 'status', keys: ['transferStatus'], showAll: true, toggle: false, uniqueKey: '#transferStatus', multiple: true },
      { label: i18n.t('lbl.point'), type: 'number', operator: 'eq',  keys: ['Priority'], uniqueKey: '#point' },
    ];
    let columns = [
      { render: this.renderActionButtons, toggle: false, sort: false, notIncluded: true, key: 'Id', checkField: false, },
      { label: i18n.t('lbl.exampleDescription'), key: 'Name', render: ({ Name }) => <Description text={Name} /> },
      { label: i18n.t('lbl.bank'), key: 'Bank', checkField: false, render: this.getBankName, sort: false },
      { label: i18n.t('lbl.typeOne'), key: 'idOne', checkField: false, render: ({ RuleItems }) => this.getTypeCodeName(RuleItems, 1), sort: false },
      { label: i18n.t('lbl.typeTwo'), key: 'idTwo', checkField: false, render: ({ RuleItems }) => this.getTypeCodeName(RuleItems, 2), sort: false },
      { label: i18n.t('lbl.incomingCategory'), key: 'IncomingCategory/Name', checkField: false },
      { label: i18n.t('lbl.outgoingCategory'), key: 'OutgoingCategory/Name', checkField: false },
      { label: i18n.t('lbl.point'), key: 'Priority' },
      { label: i18n.t('lbl.voucherType'), key: 'VoucherType/Code', checkField: false },
      { label: i18n.t('lbl.accountPlanCode'), key: 'MuhPlanCode' },
      { label: i18n.t('lbl.currentAccountCode'), key: 'CurrentAccountCode' },
      { label: i18n.t('lbl.bankAccountCode'), key: 'BankAccountCode' },
    ];

    return (
      <div className={`page-content ${className || ''}`}>
        <CheckAccessRight {...{ ...customRules, hasParent: getDataTrigger !== undefined }}>
          {
            showFilter !== false &&
            <Filter filters={filters} onFilter={this.onFilter} />
          }
          <DataTable
            wrappedComponentRef={el => this.datatable = el}
            onPageChange={this.onPageChange}
            access={access}
            history={this.props.history}
            newButton="openDialog"
            onSort={this.onSort}
            columns={columns}
            data={customRules.list.data}
            startSync={this.onStartSync}
            syncStarted={startSync}
            loading={customRules.list.loading}
            title={datatableTitle || i18n.t('route.netekstre.customRules')}
            checkbox={startSync && accessRights && accessRights.find(x => x.endPoint === 'SyncRuleJobs' && x.method === 'POST')}
            CustomButton={this.customButtons}
            count={customRules.list.count}
            dialogTitle={i18n.t('lbl.customRule')}
            getData={this.getRules}
            Component={Rule}
            deleteOptions={{ stateKey: "customRules", url: access, }}
            excel={{ url: getDataTrigger ? 'AdminExportRules' : 'ExportRules', filter: { filter: filter.filter }, module: "nte", parameters: getDataTrigger && { TenantId: getDataTrigger.id }}}
            collapsed={datatableCollapsed}
            hasParent={hasParent}
            dialogOptions={{
              endpoint: getDataTrigger && endpoints.nte.adminRules,
              tenantId: getDataTrigger && getDataTrigger.id
            }}
          />
        </CheckAccessRight>
      </div>
    );
  };
};

const mapStateToProps = ({ netekstre, auth, common }) => ({
  accessRights: auth.data.accessRights,

  currentFilter: common.currentFilter,
  tenantBanks: common.filter.tenantBanks,

  customRules: netekstre.customRules,
  incomingCategories: netekstre.filter.incomingCategories,
  outgoingCategories: netekstre.filter.outgoingCategories,
  voucherTypes: netekstre.filter.voucherTypes,
  transferStatus: netekstre.filter.transferStatus,
  type1: netekstre.filter.typeCodes1,
  type2: netekstre.filter.typeCodes2,
});
const mapDispatchToProps = (dispatch) => ({
  getRules: (filter, parameters, endpoint) => dispatch(netekstreActions.getAll({ filter, parameters, url: endpoint || endpoints.nte.rules, key: 'customRules', isNewODataStructure: true })),
  getPlanCodes: (data, callback) => dispatch(commonActions.getPlanCodes(data, callback)),
  getAccountCodes: (data, callback) => dispatch(commonActions.getAccountCodes(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomRules);