import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'antd';
import { DatePicker, Input, Select } from 'components/UIComponents/NetbankaComponents';
import { ReportingModel } from 'models/tos';
import actions from 'store/actions/tos';
import endpoints from 'config/endpoints';
import { Loading } from 'components/UIComponents';
import utils from 'lib';
import i18n from 'plugins/i18n';

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new ReportingModel(),
    };
    this.id = props.id;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.reporting.data && !state.data.Id) {
      const data = new ReportingModel({ ...props.reporting.data })
      return { data }
    }
    if (props.dialogClosing) props.onDialogClose(null, null)
    return null;
  };

  componentDidMount() {
    if (this.id) this.props.getReporting(this.id);
  };

  componentWillUnmount() {
    this.props.clearState();
  }

  renderDialogButtons = () => {
    let { reporting, dialogCloseRequest, renderDialogButtons } = this.props;
    renderDialogButtons(
      <div>
        <Button disabled={reporting.loading} className="mr-10 #cancel btn-centered" icon="rollback" onClick={() => dialogCloseRequest({ visible: false })}>{i18n.t('btn.goBack')}</Button>
      </div>
    )
  }

  render() {
    const { data } = this.state;
    const { tenantBanks, reporting } = this.props;
    this.renderDialogButtons();

    return (
      <div className="page-content">
        <Loading loading={reporting.loading}>
          <Form>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Select
                    disabled
                    value={data.BankCode}
                    className="#bank"
                    options={tenantBanks.data}
                    optVal="Code"
                    optName={(bankData) => utils.tos.getBankName({ bankData })}
                    label={i18n.t('lbl.bankName')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <DatePicker value={data.InsertedTime} label={i18n.t('tos.insertedTime')} className="#InsertedTime" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.CurrencyCode} className="#CurrencyCode" label={i18n.t('tos.currencyCode')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.CurrentAccountId} className="#CurrentAccountId" label={i18n.t('tos.currentAccountId')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.OperationStatusMessage} className="#OperationStatusMessage" label={i18n.t('lbl.orderStatus')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.AccountNumber} className="#AccountNumber" label={i18n.t('tos.accountNumber')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Input disabled value={data.Description} className="#Description" label={i18n.t('tos.description')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loading>
      </div>
    );
  };
};

const mapStateToProps = ({ tos }) => ({
  reporting: tos.reportings.single,
  tenantBanks: tos.filter.tenantBanks,
});
const mapDispatchToProps = (dispatch) => ({
  getReporting: (id, callback) => dispatch(actions.get({ url: endpoints.tos.reporting, key: 'reportings', id }, callback)),
  clearState: () => dispatch(actions.clearState("reportings"))
});
export default connect(mapStateToProps, mapDispatchToProps)(Reporting);