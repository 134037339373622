import React, { Component } from 'react';
import { Input, Tooltip, Icon } from 'antd';

class NBInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: ''
    };
  };

  handleChange = (e) => {

    if (!this.props.maxLength || (this.props.maxLength && e.length < this.props.maxLength + 1)) {
      this.props.onChange && this.props.onChange(e);
    }
  };


  render() {
    let { label, type, value, className, id, disabled, required, autoFocus, info, maxLength, minLength, step, max, min, suffix, prefix, onClick, onBlur } = this.props;
    return (
      <div className={`nb nb-input ${!!disabled ? 'disabled' : ''}`}>
        <Input.Password
          ref={el => this[id] = el}
          id={id ? id.toString() : undefined}
          type={type}
          value={value}
          onChange={(e) => this.handleChange(type === 'iban' ? e.target.value.replace(/ /g, '').toUpperCase() : e.target.value)}
          required={!!required}
          disabled={!!disabled}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => { if (onBlur) onBlur(); this.setState({ isFocused: false }) }}
          autoFocus={!!autoFocus}
          maxLength={maxLength || 512}
          minLength={minLength || null}
          className={`${value !== null && value !== undefined && value.toString() !== '' && 'is-filled'} ${className || ''}`}
          step={step}
          max={max}
          min={min}
          suffix={suffix}
          prefix={prefix}
          onClick={onClick}
        />
        {info &&
          <Tooltip title={info}>
            <Icon type="question-circle" className="form-tooltip" />
          </Tooltip>
        }
        <label className={(this.state.isFocused || (value && value.toString() !== '')) ? 'focused' : ''}>{label || ''}</label>
      </div>
    );
  };
};

export default NBInput;