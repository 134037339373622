export default {
  toolbar: [
    [{ 'header': [] }, { 'font': [] }, { 'size': [] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'video', 'code'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
}